// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Container_container__ITA5v {
    min-height: 73vh;
    max-width: 100%;
    height: 100vh; /*calc(100vh - 163px);*/
    padding: 1em 2em 3em;
    margin: 0 auto;
    overflow: auto;
    background-color: #ffe7e7;
}

.Container_container__ITA5v.Container_dark__Qqrls {
    background-color: #1e1e1e;
}
`, "",{"version":3,"sources":["webpack://./src/components/layout/Container.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,aAAa,EAAE,uBAAuB;IACtC,oBAAoB;IACpB,cAAc;IACd,cAAc;IACd,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".container {\n    min-height: 73vh;\n    max-width: 100%;\n    height: 100vh; /*calc(100vh - 163px);*/\n    padding: 1em 2em 3em;\n    margin: 0 auto;\n    overflow: auto;\n    background-color: #ffe7e7;\n}\n\n.container.dark {\n    background-color: #1e1e1e;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Container_container__ITA5v`,
	"dark": `Container_dark__Qqrls`
};
export default ___CSS_LOADER_EXPORT___;
