import api from ".";

export const duplicidadeCFOP = async (empresaId, cfop) => {
    try {
        const { data } = await api.get("/cfop/duplicidade/", {
            params: { empresaId, cfop },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};
