import api from "./";

/**
 * retorna situações por campo
 * @param {*} empresaId id da empresa ou padrão 1
 * @param {*} campo true para o campo informado
 * @returns
 */
export const situacoesByCampo = async (empresaId = 1, campo) => {
    try {
        const { data } = await api.get("/serviceSituacao", { params: { empresaId, campo } });
        return data;
    } catch (error) {
        return error;
    }
};

/**
 * retorna todas as situações
 * @param {*} empresaId id da empresa ou padrão 1
 * @returns
 */
export const situacoes = async (empresaId = 1) => {
    try {
        const { data } = await api.get("/serviceSituacoes", { params: { empresaId } });
        return data;
    } catch (error) {
        return error;
    }
};
