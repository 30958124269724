import api from ".";

export const operacao = async (uuid) => {
    try {
        const { data } = await api.get(`/operacao/editar/${uuid}`);
        return data;
    } catch (error) {
        return error;
    }
};
export const retornaOperacoes = async (entrada, saida, industria, balanco, manutencao, servico) => {
    try {
        const { data } = await api.get("/serviceOPERACAO", {
            params: { entrada, saida, industria, balanco, manutencao, servico },
        });
        return data;
    } catch (error) {
        return error;
    }
};
export const retornaOperacaoParametros = async (operacaoId, empresaId) => {
    if (!operacaoId || !empresaId) return [];

    try {
        const { data } = await api.get("/operacaoParametro/operacaoParametros", {
            params: { operacaoId, empresaId },
        });
        return data;
    } catch (error) {
        return error;
    }
};

// transformação
export const listarTransformacao = (start, size, filters, filtersMode, globalFilter, sorting, operacaoParametroId) =>
    api.get("/operacaoParametro/listarTransformacao", {
        params: { start, size, filters, filtersMode, globalFilter, sorting, operacaoParametroId },
    });
export const CreateOrEditTransformacao = async (objeto) => api.post(`operacaoParametro/CreateOrEditTransformacao`, objeto);
export const obterTransformacao = async (empresaId) => {
    try {
        const { data } = await api.get("/operacaoParametro/obterTransformacao", {
            params: { empresaId },
        });
        return data;
    } catch (error) {
        return error;
    }
};
export const duplicidadeTransformacao = async (destinoOperacaoId, operacaoParametroId, uuid) => {
    try {
        const { data } = await api.get("/operacaoParametro/duplicidadeTransformacao/", {
            params: { destinoOperacaoId, operacaoParametroId, uuid },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};
export const deletarTransformacao = (uuid) => api.post("/operacaoParametro/deletarTransformacao", { uuid });

// documento
export const listarDocumentos = (start, size, filters, filtersMode, globalFilter, sorting, operacaoParametroId) =>
    api.get("/operacaoParametro/listarDocumentos", {
        params: { start, size, filters, filtersMode, globalFilter, sorting, operacaoParametroId },
    });
export const CreateOrEditDocumento = async (objeto) => api.post(`operacaoParametro/CreateOrEditDocumento`, objeto);
export const obterDocumentos = async (empresaId) => {
    try {
        const { data } = await api.get("/operacaoParametro/obterDocumentos", {
            params: { empresaId },
        });
        return data;
    } catch (error) {
        return error;
    }
};
export const duplicidadeDocumento = async (documentoId, operacaoParametroId, uuid) => {
    try {
        const { data } = await api.get("/operacaoParametro/duplicidadeDocumento/", {
            params: { documentoId, operacaoParametroId, uuid },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};
export const deletarDocumento = (uuid) => api.post("/operacaoParametro/deletarDocumento", { uuid });
