import { useState, useEffect, useCallback, useContext, useMemo } from "react";
import { Context } from "../../../context/UserContext";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import "moment/locale/pt-br";

import { Stack, Button, Box, Tooltip, IconButton } from "@mui/material";
import {
    Delete,
    Edit,
    Print,
    AddShoppingCart,
    RemoveShoppingCart,
    ShoppingCart,
    ShoppingCartCheckout,
} from "@mui/icons-material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

import { ThemeProvider } from "@mui/material";
/* tema customizado*/
import tableThemeCustom from "../../form/tableThemeCustom";

//Import Material React Table Translations
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import DataList from "../../form/DataList";
import Input from "../../form/Input";
import Select from "../../form/Select";
import Checkbox from "../../form/Checkbox";

import formStyles from "../../form/Form.module.css";

/* api */
import * as DataListAPI from "../../../api/dataList";
import * as EmpresaAPI from "../../../api/empresa";
import * as TabelaAPI from "../../../api/tabela";
import * as OperacaoAPI from "../../../api/operacao";
import * as SituacaoAPI from "../../../api/situacao";

function Form({
    token,
    handleSubmit,
    formData,
    btnText,
    caminhoVoltar,
    OptionsDocumento,
    OptionsForma,
    OptionsPrazo,
    EnumCancelarMovimento,
}) {
    const [dados, setDados] = useState(formData || {});
    const { parametro } = useContext(Context);

    //data and fetching state
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [rowCountTransformacao, setRowCountTransformacao] = useState(0);

    //table state
    const [columnFilterFns, setColumnFiltersMode] = useState(
        localStorage.columnFilterFns.length > 2 ? JSON.parse(localStorage.columnFilterFns) : []
    );
    const [columnFilters, setColumnFilters] = useState(
        localStorage.columnFilters.length > 2 ? JSON.parse(localStorage.columnFilters) : []
    );
    const [globalFilter, setGlobalFilter] = useState(
        localStorage.globalFilter.length > 2 ? JSON.parse(localStorage.globalFilter) : ""
    );
    const [sorting, setSorting] = useState(localStorage.sorting.length > 2 ? JSON.parse(localStorage.sorting) : []);
    const [pagination, setPagination] = useState({
        pageIndex: JSON.parse(localStorage.pagination).pageIndex || 0,
        pageSize: JSON.parse(localStorage.pagination).pageSize || 5,
    });

    //abas
    const [tabEnabled, setTabEnabled] = useState({});
    const [abaAtiva, setAbaAtiva] = useState(0);
    const [gridAtiva, setGridAtiva] = useState("parametros");

    /* identifica a aba atual */
    const handleSelect = (index) => {
        setAbaAtiva(index);

        // Definir qual grid será atualizada com base na aba ativa
        switch (index) {
            case 0:
                setGridAtiva("parametros");
                break;
            case 1:
                setGridAtiva("entrada");
                break;
            case 2:
                setGridAtiva("saida");
                break;
            case 3:
                setGridAtiva("servico");
                break;
            case 4:
                setGridAtiva("industria");
                break;
            case 5:
                setGridAtiva("manutencao");
                break;
            case 5:
                setGridAtiva("balanco");
                break;
            case 5:
                setGridAtiva("documento");
                break;
            default:
                setGridAtiva("documento");
        }
    };

    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();
    const urlParams = new URLSearchParams(window.location.search);

    let operacaoParametroId = dados.id;
    //para produto vazio preenche valores pré definidos
    if (!dados.empresaId) {
        dados.empresaId = localStorage.getItem("empresaId");
        dados.ativo = true;
        dados.cancelarMovimento = "Manter";
    }

    //set datalist
    const [CFOP, setCFOP] = useState(formData?.CFOP);
    const [OptionsCFOP, setOptionsCFOP] = useState([]);

    //options dos selects
    const [OptionsTRF, setTRF] = useState([]); //centros de custos de transferencias entre contas
    const [OptionsCCI, setCCI] = useState([]); //centro de custos da industria
    const [OptionsCCR, setCCR] = useState([]); //centro de custos de receitas
    const [OptionsCCP, setCCP] = useState([]); //centros de custos de despesas
    const [OptionsCCSER, setCCSER] = useState([]); //centros de custos de locação de imoveis ou serviço

    const [OptionsOperacaoEntrada, setOptionsOperacaoEntrada] = useState([]);
    const [OptionsOperacao, setOptionsOperacao] = useState([]);

    const [OptionsSituacaoAlmoxarifado, setOptionsSituacaoAlmoxarifado] = useState([]);
    const [OptionsSituacaoMovimento, setOptionsSituacaoMovimento] = useState([]);
    const [OptionsSituacaoLancamento, setOptionsSituacaoLancamento] = useState([]);
    const [OptionsSituacaoServico, setOptionsSituacaoServico] = useState([]);

    //variáveis das grids
    const [operacaoParametrosDocumentos, setOperacaoParametrosDocumentos] = useState([]);
    const [operacaoTransformacoes, setTransformacoes] = useState([]);

    const [novoDocumento, setNovoDocumento] = useState({
        id: null,
        uuid: null,

        empresaId: dados.empresaId,
        operacaoParametroId: dados.id,
        documentoId: null,

        ativo: true,
        funcao: "Adicionar",
    });
    const [novaTransformacao, setNovaTransformacao] = useState({
        id: null,
        uuid: null,
        ativo: true,
        funcao: "Adicionar",

        //campos
        transformaMovimento: false,
        destinoAlteraEstoque: false,
        destinoEstoque: 0,
        destinoEstoqueBloqueado: false,
        destinoConferencia: false,
        destinoGeraFinanceiro: false,
        destinoContabil: false,
        //campos com vinculos
        operacaoParametroId: dados.id,
        destinoCentroCustoId: null,
        destinoOperacaoId: null,
        destinoSituacaoId: null,
        destinoAlmoxarifadoSituacaoId: null,
        destinoLancamentoSituacaoId: null,
        //tabelas vinculadas
    });

    //Atualiza o Datalist
    const consultaDatalist = async (empresaId, tabela, id, descricao, condicao, filtro) => {
        const result = await DataListAPI.dataList(empresaId, tabela, id, descricao, condicao, filtro);
        return result;
    };
    const consultaOperacao = async (entrada, saida, industria, balanco, manutencao) => {
        const data = await OperacaoAPI.retornaOperacoes(entrada, saida, industria, balanco, manutencao);
        setOptionsOperacao(data);
    };
    const consultaSituacao = async (empresaId, campo) => {
        const data = await SituacaoAPI.situacoesByCampo(empresaId, campo);

        // Mapeamento dos campos para suas respectivas funções
        const actionMap = {
            almoxarifado: setOptionsSituacaoAlmoxarifado,
            movimento: setOptionsSituacaoMovimento,
            //financeiro: setOptionsSituacaoFinanceiro,
            lancamento: setOptionsSituacaoLancamento,
            //estoque: setOptionsSituacaoEstoque,
            servico: setOptionsSituacaoServico,
            locacao: setOptionsSituacaoServico,
            // Adicione mais campos e funções conforme necessário
        };

        // Verifica se existe uma função associada ao campo
        if (actionMap[campo]) {
            actionMap[campo](data); // Chama a função correspondente passando os dados
        } else {
            console.warn(`Campo desconhecido: ${campo}`); // Caso não exista, exibe um aviso
        }
    };

    //consulta backend
    const consultaCC = useCallback(
        async (
            descricao = null,
            tipo = null,
            combustivel = null,
            manutencao = null,
            industria = null,
            option = null,
            servico = null
        ) => {
            const dado = await EmpresaAPI.centroCustos(
                dados.empresaId,
                descricao,
                tipo,
                combustivel,
                manutencao,
                industria,
                servico
            );

            if (tipo === "Receber") {
                setCCR(dado);
            }
            if (tipo === "Pagar") {
                setCCP(dado);
            }
            if (tipo === "Ambos") {
                setTRF(dado);
            }
            if (industria === true) {
                setCCI(dado);
            }
            if (servico === true) {
                setCCSER(dado);
            }

            return dado;
        },
        [dados.empresaId, setCCR, setCCP, setTRF, setCCI, setCCSER]
    );

    useEffect(() => {
        setDados(formData || {});

        const empresaOuGlobal = parametro.tabelasComuns ? 1 : dados.empresaId;

        // Lista fixa de chamadas
        const tiposPadrao = ["movimento", "almoxarifado", "lancamento"];

        // Adiciona tipos condicionais
        if (dados?.Operacao?.sigla === "LIM") tiposPadrao.push("locacao");
        if (["SER", "OSER"].includes(dados?.Operacao?.sigla)) tiposPadrao.push("servico");

        // Executa as chamadas dinamicamente
        tiposPadrao.forEach((tipo) => consultaSituacao(empresaOuGlobal, tipo));

        consultaCC(null, "Pagar");
        consultaCC(null, "Receber");
        consultaCC(null, null, null, null, null, null, true); //obtem os serviços
        consultaCC(null, null, null, null, true, null, null); //industria
    }, [formData, parametro]);

    useEffect(() => {
        if (dados.Operacao) {
            setTabEnabled({
                parametros: true,
                entrada: dados?.Operacao.entrada,
                saida: dados?.Operacao.saida,
                servico: dados?.Operacao.servico,
                industria: dados?.Operacao.industria,
                manutencao: dados?.Operacao.manutencao,
                balanco: dados?.Operacao.balanco,
                documento: true,
            });

            consultaOperacao(
                dados?.Operacao.entrada,
                dados?.Operacao.saida,
                dados?.Operacao.industria,
                dados?.Operacao.balanco,
                dados?.Operacao.manutencao,
                dados?.Operacao.servico
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dados.Operacao]);

    useEffect(() => {
        if (dados.geraFinanceiro === false) {
            setDados((prev) => ({
                ...prev,
                lancamentoSituacaoId: null,
            }));
        }
        if (dados.transformaMovimento === false) {
            setDados((prev) => ({
                ...prev,
                destinoOperacaoId: null,
                destinoSituacaoId: null,
                destinoAlmoxarifadoSituacaoId: null,
            }));
        }
        if (dados.destinoGeraFinanceiro === false) {
            setDados((prev) => ({
                ...prev,
                destinoLancamentoSituacaoId: null,
            }));
        }
    }, [dados.geraFinanceiro, dados.transformaMovimento, dados.destinoGeraFinanceiro]);
    //gridAtiva
    useEffect(() => {
        if (gridAtiva) {
            // Chame aqui o fetchData() com a gridAtiva
            fetchData(gridAtiva);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        columnFilterFns,
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
        operacaoParametroId,
        dados.parceiroId,
        gridAtiva,
    ]);

    useEffect(() => {
        dados.id && setTransformacoes((prev) => ({ ...prev, operacaoParametroId: dados.id }));
        dados.id && setOperacaoParametrosDocumentos((prev) => ({ ...prev, operacaoParametroId: dados.id }));
    }, [dados.id]);
    const fetchData = async (grid) => {
        if (!operacaoParametrosDocumentos?.length || !operacaoTransformacoes?.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }

        if (grid === "parametros") {
            try {
                await OperacaoAPI.listarTransformacao(
                    pagination.pageIndex * pagination.pageSize,
                    pagination.pageSize,
                    JSON.stringify(columnFilters ?? []),
                    JSON.stringify(columnFilterFns ?? []),
                    globalFilter ?? "",
                    JSON.stringify(sorting ?? []),
                    operacaoParametroId
                ).then((response) => {
                    setTransformacoes(response.data.data);
                    //setRowCountTransformacao(response.data.meta.totalRowCount);
                });
            } catch (error) {
                setIsError(true);
                console.error(error);
                return;
            }
        }
        if (grid === "documento") {
            try {
                await OperacaoAPI.listarDocumentos(
                    pagination.pageIndex * pagination.pageSize,
                    pagination.pageSize,
                    JSON.stringify(columnFilters ?? []),
                    JSON.stringify(columnFilterFns ?? []),
                    globalFilter ?? "",
                    JSON.stringify(sorting ?? []),
                    operacaoParametroId
                ).then((response) => {
                    setOperacaoParametrosDocumentos(response.data.data);
                    //setRowCount(response.data.meta.totalRowCount);
                });
            } catch (error) {
                setIsError(true);
                console.error(error);
                return;
            }
        }

        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
    };

    //transformações
    //documentos
    const limparTranformacao = () => {
        setNovaTransformacao({
            id: null,
            uuid: null,
            ativo: true,
            funcao: "Adicionar",

            //campos
            transformaMovimento: false,
            destinoAlteraEstoque: false,
            destinoEstoque: 0,
            destinoEstoqueBloqueado: false,
            destinoConferencia: false,
            destinoGeraFinanceiro: false,
            destinoContabil: false,
            //campos com vinculos
            operacaoParametroId: dados.id,
            destinoCentroCustoId: null,
            destinoOperacaoId: null,
            destinoSituacaoId: null,
            destinoAlmoxarifadoSituacaoId: null,
            destinoLancamentoSituacaoId: null,
            //tabelas vinculadas
        });
    };
    const handleTransformacao = async () => {
        if (novaTransformacao.funcao.toLowerCase() === "editar") {
            if (novaTransformacao.uuid) {
                try {
                    //verificar duplicidade
                    const result = await OperacaoAPI.duplicidadeTransformacao(
                        novaTransformacao.destinoOperacaoId,
                        novaTransformacao.operacaoParametroId,
                        novaTransformacao.uuid
                    );

                    if (result) {
                        setFlashMessage("Registo em duplicidade!", "warning");
                        handleItem(result, null, "Editar");
                        return;
                    }

                    await CreateOrEditTransformacao(novaTransformacao);
                } catch (error) {
                    console.error(error);
                }
            } else {
                setTransformacoes([...operacaoTransformacoes, novaTransformacao]);
            }
        } else if (novaTransformacao.funcao.toLowerCase() === "cancelar") {
            try {
                const response = await OperacaoAPI.deletarTransformacao(novaTransformacao.uuid);
                setFlashMessage(response.data.message, response.data.type);
            } catch (error) {
                console.error(error);
                setFlashMessage(error.response.data.message, error.response.data.type);
            }
        } else {
            //cadastrar

            //incluir o numero do movimento ao objeto
            novaTransformacao.operacaoParametroId = dados.id;

            try {
                //verificar duplicidade
                const result = await OperacaoAPI.duplicidadeTransformacao(
                    novaTransformacao.destinoOperacaoId,
                    novaTransformacao.operacaoParametroId
                );

                if (result && novaTransformacao.funcao.toLowerCase() === "adicionar") {
                    setFlashMessage("Registo em duplicidade!", "warning");
                    handleItemTransformacao(result, null, "Editar");
                    return;
                }

                const row = await CreateOrEditTransformacao(novaTransformacao);
                //no backend informa algum bloqueio
                if (row.status === 202) {
                    return;
                }
            } catch (error) {
                console.error(error);
            }
        }

        limparTranformacao();
        await fetchData("parametros");
    };
    const CreateOrEditTransformacao = async (novaTransformacao) => {
        try {
            const result = await OperacaoAPI.CreateOrEditTransformacao(novaTransformacao);
            setFlashMessage(result.data.message, result.data.type);
            return result;
        } catch (error) {
            console.error(error);
            if (error.response && error.response.status === 422) {
                // Tratar o erro 422, que é específico da validação no servidor
                setFlashMessage("Erro de validação: " + error.response.data.message, "error");
            } else {
                // Tratar outros erros de rede ou servidor
                setFlashMessage("Ocorreu um erro ao processar a solicitação.", "error");
            }
        }
    };
    const handleItemTransformacao = async (originalRow, row, funcao) => {
        // Define os dados para o novo objeto
        const novoItemData = {
            funcao: funcao,
            index: row,

            id: originalRow.id,
            uuid: originalRow.uuid,
            ativo: originalRow.ativo,

            //campos
            transformaMovimento: originalRow.transformaMovimento,
            destinoAlteraEstoque: originalRow.destinoAlteraEstoque,
            destinoEstoque: originalRow.destinoEstoque,
            destinoEstoqueBloqueado: originalRow.destinoEstoqueBloqueado,
            destinoConferencia: originalRow.destinoConferencia,
            destinoGeraFinanceiro: originalRow.destinoGeraFinanceiro,
            destinoContabil: originalRow.destinoContabil,
            //campos com vinculos
            operacaoParametroId: dados.id,
            destinoCentroCustoId: originalRow.destinoCentroCustoId,
            destinoOperacaoId: originalRow.destinoOperacaoId,
            destinoSituacaoId: originalRow.destinoSituacaoId,
            destinoAlmoxarifadoSituacaoId: originalRow.destinoAlmoxarifadoSituacaoId,
            destinoLancamentoSituacaoId: originalRow.destinoLancamentoSituacaoId,
        };

        // Atualize o estado do novo produto
        setNovaTransformacao(novoItemData);
    };

    //documentos
    const limparDocumento = () => {
        setNovoDocumento({
            uuid: null,
            empresaId: dados.empresaId,
            documentoId: null,
            operacaoParametroId: dados.id,

            ativo: true,
            funcao: "Adicionar",
        });
    };
    const handleDocumento = async () => {
        if (novoDocumento.funcao.toLowerCase() === "editar") {
            if (novoDocumento.uuid) {
                try {
                    //verificar duplicidade
                    const result = await OperacaoAPI.duplicidadeDocumento(
                        novoDocumento.documentoId,
                        novoDocumento.operacaoParametroId,
                        novoDocumento.uuid
                    );

                    if (result) {
                        setFlashMessage("Registo em duplicidade!", "warning");
                        handleItem(result, null, "Editar");
                        return;
                    }

                    await CreateOrEditDocumento(novoDocumento);
                } catch (error) {
                    console.error(error);
                }
            } else {
                setOperacaoParametrosDocumentos([...operacaoParametrosDocumentos, novoDocumento]);
            }
        } else if (novoDocumento.funcao.toLowerCase() === "cancelar") {
            try {
                const response = await OperacaoAPI.deletarDocumento(novoDocumento.uuid);
                setFlashMessage(response.data.message, response.data.type);
            } catch (error) {
                console.error(error);
                setFlashMessage(error.response.data.message, error.response.data.type);
            }
        } else {
            //cadastrar

            //incluir o numero do movimento ao objeto
            novoDocumento.operacaoParametroId = dados.id;

            try {
                //verificar duplicidade
                const result = await OperacaoAPI.duplicidadeDocumento(
                    novoDocumento.documentoId,
                    novoDocumento.operacaoParametroId
                );

                if (result && novoDocumento.funcao.toLowerCase() === "adicionar") {
                    setFlashMessage("Registo em duplicidade!", "warning");
                    handleItem(result, null, "Adicionar");
                    return;
                }

                await CreateOrEditDocumento(novoDocumento);
            } catch (error) {
                console.error(error);
            }
        }

        limparDocumento();
        await fetchData("documento");
    };
    const CreateOrEditDocumento = async (novoDocumento) => {
        try {
            const result = await OperacaoAPI.CreateOrEditDocumento(novoDocumento);

            setFlashMessage(result.data.message, result.data.type);

            //deve atualizar a grid
            // dados.id && fetchData("itens", novoDocumento);
        } catch (error) {
            console.error(error);
            if (error.response && error.response.status === 422) {
                // Tratar o erro 422, que é específico da validação no servidor
                setFlashMessage("Erro de validação: " + error.response.data.message, "error");
            } else {
                // Tratar outros erros de rede ou servidor
                setFlashMessage("Ocorreu um erro ao processar a solicitação.", "error");
            }
        }
    };
    const handleItem = async (originalRow, row, funcao) => {
        // Define os dados para o novo produto
        const novoDocumentoData = {
            id: originalRow.id,
            uuid: originalRow.uuid,
            documentoId: originalRow.documentoId,
            operacaoParametroId: originalRow.operacaoParametroId,
            ativo: originalRow.ativo,

            funcao: funcao,
            index: row,
        };

        // Atualize o estado do novo produto
        setNovoDocumento(novoDocumentoData);
    };

    async function handleChange(e) {
        const { name, value, checked, type } = e.target;
        const camposBooleanos = [
            "ativo",
            //parametros
            "alteraEstoque",
            "transformaMovimento",

            //transformação destino
            "destinoEstoqueBloqueado",
            "destinoConferencia",
            "destinoAlteraEstoque",
            "destinoGeraFinanceiro",
            "destinoContabil",

            //entradas
            "estoqueBloqueado",
            "conferencia",
            "contabil",

            "geraFinanceiro",
            "materiaPrimaEstocaGraos",
            "refugoMateriaPrimaGeraFinanceiro",
            "tabelasComuns",
            "msgCoresDefault",
            "calculaJuros",
        ];

        //configura os checkbox
        if (camposBooleanos.includes(name)) {
            setDados({
                ...dados,
                [name]: checked,
            });
        } else if (name.startsWith("novaTransformacao.")) {
            setNovaTransformacao({ ...novaTransformacao, [name.split(".")[1]]: type === "checkbox" ? checked : value });
        } else if (name.startsWith("novoDocumento.")) {
            setNovoDocumento({ ...novoDocumento, [name.split(".")[1]]: type === "checkbox" ? checked : value });
        } else if (name.startsWith("CFOP.")) {
            setCFOP({ ...CFOP, [name.split(".")[1]]: value });

            const result = await consultaDatalist(dados.empresaId, "CFOP", "id", "descricao", "", value);
            setOptionsCFOP(result);

            if (document.querySelector(`datalist option[value="${value}"]`)?.dataset.id) {
                setDados({
                    ...dados,
                    cfopId: document.querySelector(`datalist option[value="${value}"]`)?.dataset.id,
                });
            }
        } else {
            setDados({
                ...dados,
                [name]: value,
            });
        }
    }
    async function handleOnBlur(e) {}
    async function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();
        handleSubmit(dados);
    };

    //ajustta o tottal de registros na grid
    useEffect(() => {
        if (operacaoTransformacoes) {
            setRowCountTransformacao(operacaoTransformacoes.length);
        }
        if (operacaoParametrosDocumentos) {
            setRowCount(operacaoParametrosDocumentos.length);
        }
    }, [operacaoTransformacoes, operacaoParametrosDocumentos]);
    //criar a grid
    const columnsTransformacao = useMemo(
        () => [
            {
                id: "transformar",
                header: `Operações disponíveis para transformar`,
                columns: [
                    {
                        accessorKey: "OperacaoDestino.descricao",
                        header: "Operação",
                        muiTableBodyCellProps: ({ row }) => ({
                            onClick: () => {},
                        }),
                    },
                    {
                        accessorKey: "SituacaoDestino.descricao",
                        header: "Situação",
                        muiTableBodyCellProps: {
                            align: "left",
                        },
                    },
                ],
            },

            //column definitions...
        ],
        [] //getCommonEditTextFieldProps
    );
    const columnsDocumento = useMemo(
        () => [
            {
                id: "documento",
                header: `Documentos modelo para relatórios`,
                columns: [
                    {
                        accessorKey: "Documento.descricao",
                        header: "Documento modelo",
                        muiTableBodyCellProps: ({ row }) => ({
                            onClick: () => {},
                        }),
                    },
                    {
                        accessorKey: "Documento.caminho",
                        header: "Caminho",
                        muiTableBodyCellProps: {
                            align: "left",
                        },
                    },
                    {
                        accessorKey: "Documento.funcao",
                        header: "Função",
                        muiTableBodyCellProps: {
                            align: "left",
                        },
                    },
                    {
                        accessorKey: "Documento.tipo",
                        header: "Tipo",
                        muiTableBodyCellProps: {
                            align: "left",
                        },
                    },
                ],
            },

            //column definitions...
        ],
        [] //getCommonEditTextFieldProps
    );
    const theme = tableThemeCustom();
    return (
        <div>
            <Tabs selectedIndex={abaAtiva} onSelect={handleSelect}>
                <form onSubmit={submit}>
                    <h1>{`Parâmetros ${dados?.Operacao?.descricao + " - " + dados?.Operacao?.sigla}`}</h1>
                    <TabList>
                        <Tab disabled={!tabEnabled.parametros} title={"Parâmetros de transformação da Operação"}>
                            Parâmetros
                        </Tab>
                        <Tab disabled={!tabEnabled.entrada} title={"Parâmetros de operações de Entradas"}>
                            Entradas
                        </Tab>
                        <Tab disabled={!tabEnabled.saida} title={"Parâmetros de operações de Saídas"}>
                            Saídas
                        </Tab>
                        <Tab disabled={!tabEnabled.servico} title={"Parâmetros de operações de  Serviço"}>
                            Serviço
                        </Tab>
                        <Tab disabled={!tabEnabled.industria} title={"Parâmetros de operações de Indústria"}>
                            Indústria
                        </Tab>
                        <Tab disabled={!tabEnabled.manutencao} title={"Parâmetros de operações de Manutenção"}>
                            Manutenção
                        </Tab>
                        <Tab disabled={!tabEnabled.balanco} title={"Parâmetros da operação de balanço"}>
                            Balanço
                        </Tab>
                        <Tab
                            disabled={!tabEnabled.documento}
                            title={"Parâmetros dos modelos de relatórios vinculados a operação"}
                        >
                            Documento Modelo
                        </Tab>
                    </TabList>
                    <TabPanel>
                        <div className={formStyles.stiloPadrao}>
                            <h4>Parâmetros da Operação na digitação</h4>
                            <div>
                                <div>
                                    <Checkbox
                                        title="Operação atualiza o financeiro"
                                        text="Gera Fianceiro"
                                        name="geraFinanceiro"
                                        type="checkbox"
                                        handleOnChange={handleChange}
                                        value={dados.geraFinanceiro}
                                        checked={dados.geraFinanceiro === true ? "checked" : ""}
                                    />
                                    <Select
                                        name="lancamentoSituacaoId"
                                        title="Define a situação padrão do lançamento"
                                        text="Situação Lançamento"
                                        inputWidth="170px"
                                        options={OptionsSituacaoLancamento}
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={dados.geraFinanceiro === false ? null : dados.lancamentoSituacaoId}
                                        isEnum={false}
                                        notOptNull={false}
                                    />
                                    <Checkbox
                                        title="Operação contábil"
                                        text="Contábil"
                                        name="contabil"
                                        type="checkbox"
                                        handleOnChange={handleChange}
                                        value={dados.contabil}
                                        checked={dados.contabil === true ? "checked" : ""}
                                    />
                                    <DataList
                                        text="CFOP Descrição"
                                        title={"Vincula o CFOP a operação"}
                                        name="CFOP.descricao"
                                        placeholder={"Digite mais de 3 caracteres para pesquisar"}
                                        inputWidth="300px"
                                        filteredOptions={OptionsCFOP}
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={CFOP?.descricao}
                                        required={false}
                                    />
                                    <Select
                                        name="cancelarMovimento"
                                        title="A opção é para quando cancelar um movimento e o mesmo tiver lançamento parcialmente baixado o restante do saldo do lançamento será MANTIDO ou LIQUIDADO"
                                        text="Cancelar Movimento"
                                        inputWidth="150px"
                                        options={EnumCancelarMovimento}
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={dados.cancelarMovimento}
                                        isEnum={true}
                                        notOptNull={true}
                                    />
                                </div>
                            </div>
                            <h4>Parâmetros da Operação na Transformação</h4>
                            <div>
                                <div
                                    style={{
                                        //display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        title="Permite transformar o movimento"
                                        text="Transformar"
                                        name="novaTransformacao.transformaMovimento"
                                        type="checkbox"
                                        handleOnChange={handleChange}
                                        value={novaTransformacao.transformaMovimento}
                                        checked={novaTransformacao.transformaMovimento === true ? "checked" : ""}
                                    />
                                    <Select
                                        title="Operação de destino ao transformar"
                                        text="Operação Destino"
                                        name="novaTransformacao.destinoOperacaoId"
                                        options={OptionsOperacao}
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novaTransformacao.destinoOperacaoId}
                                        isEnum={false}
                                        notOptNull={false}
                                        required={false}
                                        inputWidth={"170px"}
                                        disabled={!novaTransformacao.transformaMovimento}
                                    />
                                    <Select
                                        name="novaTransformacao.destinoSituacaoId"
                                        title="Define a situação padrão do movimento destino"
                                        text="Situação Destino"
                                        inputWidth="170px"
                                        options={OptionsSituacaoMovimento}
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novaTransformacao.destinoSituacaoId}
                                        isEnum={false}
                                        notOptNull={false}
                                        disabled={!novaTransformacao.transformaMovimento}
                                    />
                                    <Select
                                        name="novaTransformacao.destinoCentroCustoId"
                                        title="Define o centro de custo padrão do movimento destino"
                                        text="Centro de Custo Destino"
                                        inputWidth="200px"
                                        options={OptionsCCP}
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novaTransformacao.destinoCentroCustoId}
                                        isEnum={false}
                                        notOptNull={false}
                                        disabled={!novaTransformacao.transformaMovimento}
                                    />
                                    <Checkbox
                                        title="operação de destino altera estoque"
                                        text="Altera Estoque"
                                        name="novaTransformacao.destinoAlteraEstoque"
                                        type="checkbox"
                                        handleOnChange={handleChange}
                                        disabled={!novaTransformacao.transformaMovimento}
                                        value={
                                            !novaTransformacao.transformaMovimento
                                                ? false
                                                : novaTransformacao.destinoAlteraEstoque
                                        }
                                        checked={novaTransformacao.destinoAlteraEstoque === true ? "checked" : ""}
                                    />
                                    <Input
                                        text="Estoque Destino"
                                        type="range"
                                        name="novaTransformacao.destinoEstoque"
                                        inputWidth="100px"
                                        title="configura o valor que a operação altera o estoque, -1 subtrai, 0 não altera e 1 acrescenta"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        disabled={!novaTransformacao.transformaMovimento}
                                        value={!novaTransformacao.transformaMovimento ? 0 : novaTransformacao.destinoEstoque}
                                        textAlign="center"
                                        min={-1}
                                        max={1}
                                    />
                                    <Checkbox
                                        title="Estoque bloqueado na operação de destino"
                                        text="Estoque Bloqueado"
                                        name="novaTransformacao.destinoEstoqueBloqueado"
                                        type="checkbox"
                                        handleOnChange={handleChange}
                                        value={novaTransformacao.destinoEstoqueBloqueado}
                                        checked={novaTransformacao.destinoEstoqueBloqueado === true ? "checked" : ""}
                                    />
                                    <Checkbox
                                        title="Obriga conferência na operação de destino"
                                        text="Conferência"
                                        name="novaTransformacao.destinoConferencia"
                                        type="checkbox"
                                        handleOnChange={handleChange}
                                        value={novaTransformacao.destinoConferencia}
                                        checked={novaTransformacao.destinoConferencia === true ? "checked" : ""}
                                    />
                                    <Select
                                        name="novaTransformacao.destinoAlmoxarifadoSituacaoId"
                                        title="Define a situação de entrada no almoxarifado para a operação de destino"
                                        text="Situação Alm Destino"
                                        inputWidth="170px"
                                        options={OptionsSituacaoAlmoxarifado}
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novaTransformacao.destinoAlmoxarifadoSituacaoId}
                                        isEnum={false}
                                        notOptNull={false}
                                        disabled={!novaTransformacao.transformaMovimento}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        title="gera o financeiro na operação de destino"
                                        text="Gera Financeiro"
                                        name="novaTransformacao.destinoGeraFinanceiro"
                                        type="checkbox"
                                        handleOnChange={handleChange}
                                        value={novaTransformacao.destinoGeraFinanceiro}
                                        checked={novaTransformacao.destinoGeraFinanceiro === true ? "checked" : ""}
                                    />
                                    <Select
                                        name="novaTransformacao.destinoLancamentoSituacaoId"
                                        title="Define a situação padrão do lançamento destino"
                                        text="Situação Lançamento"
                                        inputWidth="170px"
                                        options={OptionsSituacaoLancamento}
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novaTransformacao.destinoLancamentoSituacaoId}
                                        isEnum={false}
                                        notOptNull={false}
                                        disabled={!novaTransformacao.destinoGeraFinanceiro}
                                    />
                                    <Checkbox
                                        title="operação de destino contábil"
                                        text="Contábil"
                                        name="novaTransformacao.destinoContabil"
                                        type="checkbox"
                                        handleOnChange={handleChange}
                                        value={novaTransformacao.destinoContabil}
                                        checked={novaTransformacao.destinoContabil === true ? "checked" : ""}
                                    />
                                    <Button
                                        variant="contained"
                                        color="inherit"
                                        onClick={() => {
                                            limparTranformacao(true);
                                        }}
                                        style={{
                                            marginLeft: "10px",
                                            minWidth: "80px",
                                            fontSize: "14px",
                                            "@media (max-width: 600px)": {
                                                fontSize: "12px", // Ajusta o tamanho da fonte em telas menores
                                                padding: "8px 12px", // Ajusta o padding para manter o botão visível
                                            },
                                        }}
                                    >
                                        Limpar
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color={novaTransformacao.funcao === "cancelar" ? "error" : "info"}
                                        onClick={() => {
                                            handleTransformacao(novaTransformacao);
                                        }}
                                        disabled={novaTransformacao.funcao !== "cancelar" && !novaTransformacao.destinoOperacaoId}
                                        style={{
                                            marginLeft: "10px",
                                            minWidth: "80px",
                                            fontSize: "14px",
                                            "@media (max-width: 600px)": {
                                                fontSize: "12px", // Ajusta o tamanho da fonte em telas menores
                                                padding: "8px 12px", // Ajusta o padding para manter o botão visível
                                            },
                                        }}
                                    >
                                        {novaTransformacao.funcao}
                                    </Button>
                                </div>
                            </div>
                            <div>
                                <ThemeProvider theme={theme}>
                                    <MaterialReactTable
                                        displayColumnDefOptions={{
                                            "mrt-row-actions": {
                                                muiTableHeadCellProps: {
                                                    align: "center",
                                                },
                                                size: 100,
                                            },
                                            "mrt-row-numbers": {
                                                enableColumnOrdering: true, //turn on some features that are usually off
                                                enableResizing: true,
                                                muiTableHeadCellProps: {
                                                    sx: {
                                                        fontSize: "1.2rem",
                                                    },
                                                },
                                            },
                                            "mrt-row-select": {
                                                enableColumnActions: true,
                                                enableHiding: true,
                                                size: 50,
                                            },
                                        }}
                                        enableColumnFilterModes
                                        enableGlobalFilterModes
                                        //enableColumnResizing
                                        enableColumnOrdering
                                        enableEditing
                                        renderRowActions={({ row, table }) => (
                                            <Box sx={{ display: "flex", gap: "1rem" }}>
                                                <Tooltip arrow placement="top" title={"Editar"}>
                                                    <span>
                                                        {" "}
                                                        <IconButton
                                                            onClick={() => {
                                                                handleItemTransformacao(row.original, row.index, "Editar");
                                                            }}
                                                            disabled={localStorage.getItem("editar") === "true" ? false : true}
                                                        >
                                                            {<Edit />}
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                                <Tooltip arrow placement="right" title="Cancelar">
                                                    <span>
                                                        <IconButton
                                                            color="error"
                                                            onClick={() =>
                                                                handleItemTransformacao(row.original, row.id, "Cancelar")
                                                            }
                                                            disabled={localStorage.getItem("editar") === "true" ? false : true}
                                                        >
                                                            <Delete />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                            </Box>
                                        )}
                                        columns={columnsTransformacao}
                                        data={operacaoTransformacoes}
                                        rowCount={rowCountTransformacao}
                                        //enableRowSelection
                                        getRowId={(row) => row.uuid}
                                        //manualFiltering
                                        manualPagination
                                        manualSorting
                                        muiToolbarAlertBannerProps={
                                            isError
                                                ? {
                                                      color: "error",
                                                      children: "Error loading data",
                                                  }
                                                : undefined
                                        }
                                        onColumnFiltersChange={setColumnFilters}
                                        onColumnFilterFnsChange={setColumnFiltersMode}
                                        onGlobalFilterChange={setGlobalFilter}
                                        onPaginationChange={setPagination}
                                        onSortingChange={setSorting}
                                        initialState={{
                                            showColumnFilters: true,
                                            density: "compact",
                                            //columnPinning: { left: ["mrt-row-select"] },
                                        }}
                                        state={{
                                            columnFilterFns,
                                            columnFilters,
                                            globalFilter,
                                            isLoading,
                                            pagination,
                                            showAlertBanner: isError,
                                            showProgressBars: isRefetching,
                                            sorting,
                                        }}
                                        //positionToolbarDropZone={"top"}
                                        positionExpandColumn={"first"}
                                        positionActionsColumn={"first"}
                                        positionToolbarAlertBanner="bottom"
                                        localization={MRT_Localization_PT_BR}
                                    />
                                </ThemeProvider>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className={formStyles.stiloPadrao}>
                            <h4>Parâmetros de movimentações de entradas</h4>
                            <div>
                                <Select
                                    name="situacaoId"
                                    title="Define a situação padrão do movimento"
                                    text="Situação Movimento"
                                    inputWidth="170px"
                                    options={OptionsSituacaoMovimento}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.situacaoId}
                                    isEnum={false}
                                    notOptNull={false}
                                />
                                <Select
                                    name="centroCustoId"
                                    title={"Define o centro de custo padrão para o movimento"}
                                    text="Centro Custo"
                                    inputWidth="200px"
                                    options={OptionsCCP}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.centroCustoId}
                                    isEnum={false}
                                    notOptNull={false}
                                />
                            </div>
                            <div>
                                <Checkbox
                                    title="Informa que o movimento altera o estoque"
                                    text="Altera Estoque"
                                    name="alteraEstoque"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    value={dados.alteraEstoque}
                                    checked={dados.alteraEstoque === true ? "checked" : ""}
                                />
                                <Input
                                    text="Estoque"
                                    type="range"
                                    name="estoque"
                                    inputWidth="100px"
                                    title="configura o valor que a operação altera o estqoue, -1 subtrai, 0 não altera e 1 acrescenta"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    disabled={!dados.alteraEstoque}
                                    value={!dados.alteraEstoque ? 0 : dados.estoque}
                                    textAlign="center"
                                    min={-1}
                                    max={1}
                                />
                                <Select
                                    name="situacaoIdAlmoxarifado"
                                    title="Define a situação padrão do movimento"
                                    text="Situação Almoxarifado"
                                    inputWidth="170px"
                                    options={OptionsSituacaoAlmoxarifado}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={!dados.alteraEstoque ? null : dados.situacaoIdAlmoxarifado}
                                    isEnum={false}
                                    notOptNull={false}
                                />
                                <Checkbox
                                    title="Estoque bloqueado"
                                    text="Estqoue Bloqueado"
                                    name="estoqueBloqueado"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    value={dados.estoqueBloqueado}
                                    checked={dados.estoqueBloqueado === true ? "checked" : ""}
                                />
                                <Checkbox
                                    title="Obriga conferência"
                                    text="Conferencia"
                                    name="conferencia"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    value={dados.conferencia}
                                    checked={dados.conferencia === true ? "checked" : ""}
                                />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className={formStyles.stiloPadrao}>
                            <h4>Parâmetros de movimentações de saídas</h4>
                            <div>
                                <Select
                                    name="situacaoId"
                                    title="Define a situação padrão do movimento"
                                    text="Situação Movimento"
                                    inputWidth="170px"
                                    options={OptionsSituacaoMovimento}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.situacaoId}
                                    isEnum={false}
                                    notOptNull={false}
                                />
                                <Select
                                    name="centroCustoId"
                                    title={"Define o centro de custo padrão para o movimento"}
                                    text="Centro Custo"
                                    inputWidth="200px"
                                    options={OptionsCCR}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.centroCustoId}
                                    isEnum={false}
                                    notOptNull={false}
                                />
                            </div>
                            <div>
                                <Checkbox
                                    title="Informa que o movimento altera o estoque"
                                    text="Altera Estoque"
                                    name="alteraEstoque"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    value={dados.alteraEstoque}
                                    checked={dados.alteraEstoque === true ? "checked" : ""}
                                />
                                <Input
                                    text="Estoque"
                                    type="range"
                                    name="estoque"
                                    inputWidth="100px"
                                    title="configura o valor que a operação altera o estqoue, -1 subtrai, 0 não altera e 1 acrescenta"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    disabled={!dados.alteraEstoque}
                                    value={!dados.alteraEstoque ? 0 : dados.estoque}
                                    textAlign="center"
                                    min={-1}
                                    max={1}
                                />
                                <Select
                                    name="situacaoIdAlmoxarifado"
                                    title="Define a situação padrão do movimento"
                                    text="Situação Almoxarifado"
                                    inputWidth="170px"
                                    options={OptionsSituacaoAlmoxarifado}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={!dados.alteraEstoque ? null : dados.situacaoIdAlmoxarifado}
                                    isEnum={false}
                                    notOptNull={false}
                                />
                                <Checkbox
                                    title="Estoque bloqueado"
                                    text="Estqoue Bloqueado"
                                    name="estoqueBloqueado"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    value={dados.estoqueBloqueado}
                                    checked={dados.estoqueBloqueado === true ? "checked" : ""}
                                />
                                <Checkbox
                                    title="Obriga conferência"
                                    text="Conferencia"
                                    name="conferencia"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    value={dados.conferencia}
                                    checked={dados.conferencia === true ? "checked" : ""}
                                />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className={formStyles.stiloPadrao}>
                            <h4>Parâmetros de movimentações de serviço</h4>
                            <div>
                                <Select
                                    name="situacaoId"
                                    title="Define a situação padrão do movimento"
                                    text="Situação Movimento"
                                    inputWidth="170px"
                                    options={OptionsSituacaoServico}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.situacaoId}
                                    isEnum={false}
                                    notOptNull={false}
                                />
                                <Select
                                    name="centroCustoId"
                                    title={"Define o centro de custo padrão para serviços"}
                                    text="Centro Custo"
                                    inputWidth="200px"
                                    options={OptionsCCSER}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.centroCustoId}
                                    isEnum={false}
                                    notOptNull={false}
                                />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className={formStyles.stiloPadrao}>
                            <h4>Parâmetros para o modulo de indústria</h4>

                            <div>
                                <Checkbox
                                    title="Informa se a entrada de matéria-prima estoca no almoxarifado de grãos"
                                    text="MP Est Grãos"
                                    name="materiaPrimaEstocaGraos"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    value={dados.materiaPrimaEstocaGraos}
                                    checked={dados.materiaPrimaEstocaGraos === true ? "checked" : ""}
                                />
                                <Select
                                    name="centroCustoMateriaPrimaId"
                                    title={"Define o centro de custo padrão para entrada de matéria-prima"}
                                    text="Centro Custo MP"
                                    options={OptionsCCI}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.centroCustoMateriaPrimaId}
                                    isEnum={false}
                                    notOptNull={false}
                                />
                                <Checkbox
                                    title="Informa se o refugo vai gerar valor a receber no modulo financeiro"
                                    text="Refugo MP gera financeiro"
                                    name="refugoMateriaPrimaGeraFinanceiro"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    value={dados.refugoMateriaPrimaGeraFinanceiro}
                                    checked={dados.refugoMateriaPrimaGeraFinanceiro === true ? "checked" : ""}
                                />
                                <Select
                                    name="centroCustoRefugoMateriaPrimaId"
                                    title={"Define o centro de custo padrão para o refugo da matéria-prima"}
                                    text="Centro Custo Refugo"
                                    options={OptionsCCI}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.centroCustoRefugoMateriaPrimaId}
                                    isEnum={false}
                                    notOptNull={false}
                                />
                            </div>
                            <div>
                                {" "}
                                <Checkbox
                                    title="Informa se a empresa utiliza plantões na produção"
                                    text="Plantão"
                                    name="plantao"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    value={dados.plantao}
                                    checked={dados.plantao === true ? "checked" : ""}
                                />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className={formStyles.stiloPadrao}>
                            <h4>Parâmetros de Comuns</h4>
                            <div>
                                <Select
                                    name="centroCustoTransferencia"
                                    title={"Define o centro de custo padrão para os transferências entre contas"}
                                    text="Centro Custo Transferências"
                                    inputWidth="200px"
                                    options={OptionsTRF}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.centroCustoTransferencia}
                                    isEnum={false}
                                    notOptNull={false}
                                />
                                <Select
                                    name="transferenciaForma"
                                    title={"Define a forma padrão para os transferências entre contas"}
                                    text="Forma Transferências"
                                    inputWidth="200px"
                                    options={OptionsForma}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.transferenciaForma}
                                    isEnum={false}
                                    notOptNull={false}
                                />
                                <Select
                                    name="transferenciaPrazo"
                                    title={"Define o prazo padrão para os transferências entre contas"}
                                    text="Prazo Transferências"
                                    inputWidth="200px"
                                    options={OptionsPrazo}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.transferenciaPrazo}
                                    isEnum={false}
                                    notOptNull={false}
                                />
                                <Checkbox
                                    title="Permite calcular juros e multa"
                                    text="Calcula Juros"
                                    name="calculaJuros"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    value={dados.calculaJuros}
                                    checked={dados.calculaJuros === true ? "checked" : ""}
                                />
                            </div>
                            <div>
                                <Select
                                    name="centroCustoComissaoId"
                                    title={"Define o centro de custo padrão para as comissões"}
                                    text="Centro Custo Comissão"
                                    inputWidth="200px"
                                    options={OptionsCCP}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.centroCustoComissaoId}
                                    isEnum={false}
                                    notOptNull={false}
                                />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className={formStyles.stiloPadrao}>
                            <h4>Parâmetros do balanço</h4>
                            <div></div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className={formStyles.stiloPadrao}>
                            <h4>Documentos modelo vinculados à operação</h4>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Select
                                    name="novoDocumento.documentoId"
                                    title={`escolha o documento modelo para o relatório`}
                                    text="Modelo"
                                    inputWidth="100"
                                    options={OptionsDocumento}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoDocumento.documentoId}
                                    isEnum={false}
                                    notOptNull={false}
                                />
                                <Checkbox
                                    title="Ativo quando marcado"
                                    text="Ativo"
                                    name="novoDocumento.ativo"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoDocumento.ativo}
                                    checked={novoDocumento.ativo === true ? "checked" : ""}
                                />
                                <Button
                                    variant="contained"
                                    color="inherit"
                                    onClick={() => {
                                        limparDocumento(true);
                                    }}
                                    style={{
                                        marginLeft: "10px",
                                        minWidth: "80px",
                                        fontSize: "14px",
                                        "@media (max-width: 600px)": {
                                            fontSize: "12px", // Ajusta o tamanho da fonte em telas menores
                                            padding: "8px 12px", // Ajusta o padding para manter o botão visível
                                        },
                                    }}
                                >
                                    Limpar
                                </Button>
                                <Button
                                    variant="contained"
                                    color={novoDocumento.funcao === "cancelar" ? "error" : "info"}
                                    onClick={() => {
                                        handleDocumento(novoDocumento);
                                    }}
                                    disabled={novoDocumento.funcao !== "cancelar" && !novoDocumento.documentoId}
                                    style={{
                                        marginLeft: "10px",
                                        minWidth: "80px",
                                        fontSize: "14px",
                                        "@media (max-width: 600px)": {
                                            fontSize: "12px", // Ajusta o tamanho da fonte em telas menores
                                            padding: "8px 12px", // Ajusta o padding para manter o botão visível
                                        },
                                    }}
                                >
                                    {novoDocumento.funcao}
                                </Button>
                            </div>
                            <div>
                                <ThemeProvider theme={theme}>
                                    <MaterialReactTable
                                        displayColumnDefOptions={{
                                            "mrt-row-actions": {
                                                muiTableHeadCellProps: {
                                                    align: "center",
                                                },
                                                size: 100,
                                            },
                                            "mrt-row-numbers": {
                                                enableColumnOrdering: true, //turn on some features that are usually off
                                                enableResizing: true,
                                                muiTableHeadCellProps: {
                                                    sx: {
                                                        fontSize: "1.2rem",
                                                    },
                                                },
                                            },
                                            "mrt-row-select": {
                                                enableColumnActions: true,
                                                enableHiding: true,
                                                size: 50,
                                            },
                                        }}
                                        enableColumnFilterModes
                                        enableGlobalFilterModes
                                        //enableColumnResizing
                                        enableColumnOrdering
                                        enableEditing
                                        renderRowActions={({ row, table }) => (
                                            <Box sx={{ display: "flex", gap: "1rem" }}>
                                                <Tooltip arrow placement="top" title={"Editar"}>
                                                    <span>
                                                        {" "}
                                                        <IconButton
                                                            onClick={() => {
                                                                handleItem(row.original, row.index, "Editar");
                                                            }}
                                                            disabled={localStorage.getItem("editar") === "true" ? false : true}
                                                        >
                                                            {<Edit />}
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                                <Tooltip arrow placement="right" title="Cancelar">
                                                    <span>
                                                        <IconButton
                                                            color="error"
                                                            onClick={() => handleItem(row.original, row.id, "Cancelar")}
                                                            disabled={localStorage.getItem("editar") === "true" ? false : true}
                                                        >
                                                            <Delete />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                            </Box>
                                        )}
                                        columns={columnsDocumento}
                                        data={operacaoParametrosDocumentos}
                                        rowCount={rowCount}
                                        enableRowSelection
                                        getRowId={(row) => row.uuid}
                                        //manualFiltering
                                        manualPagination
                                        manualSorting
                                        muiToolbarAlertBannerProps={
                                            isError
                                                ? {
                                                      color: "error",
                                                      children: "Error loading data",
                                                  }
                                                : undefined
                                        }
                                        onColumnFiltersChange={setColumnFilters}
                                        onColumnFilterFnsChange={setColumnFiltersMode}
                                        onGlobalFilterChange={setGlobalFilter}
                                        onPaginationChange={setPagination}
                                        onSortingChange={setSorting}
                                        initialState={{
                                            showColumnFilters: true,
                                            density: "compact",
                                            //columnPinning: { left: ["mrt-row-select"] },
                                        }}
                                        state={{
                                            columnFilterFns,
                                            columnFilters,
                                            globalFilter,
                                            isLoading,
                                            pagination,
                                            showAlertBanner: isError,
                                            showProgressBars: isRefetching,
                                            sorting,
                                        }}
                                        //positionToolbarDropZone={"top"}
                                        positionExpandColumn={"first"}
                                        positionActionsColumn={"first"}
                                        positionToolbarAlertBanner="bottom"
                                        localization={MRT_Localization_PT_BR}
                                    />
                                </ThemeProvider>
                            </div>
                        </div>
                    </TabPanel>
                    <div>
                        <Stack direction="row" spacing={1}>
                            <Tooltip arrow placement="top-start" title="Voltar">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() =>
                                        navigate(caminhoVoltar, {
                                            ul: urlParams.get("ul"),
                                            li: urlParams.get("li"),
                                            tipo: urlParams.get("tipo"),
                                        })
                                    }
                                >
                                    Voltar
                                </Button>
                            </Tooltip>
                            <Button
                                type="submit"
                                variant="contained"
                                color="success"
                                //disabled={}
                            >
                                {btnText}
                            </Button>
                        </Stack>
                    </div>
                </form>
            </Tabs>
        </div>
    );
}
export default Form;
