import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";

import { useNavigateParams } from "../../../hooks/useNavigateParams";
import Form from "./Form";
import styles from "../../form/Form.module.css";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";

import CRUDsAPI from "../../../api/CRUDs";

import { Context } from "../../../context/UserContext";

const Edit = () => {
    const { uuid } = useParams();
    const { parametro } = useContext(Context);
    const [uf, setUf] = useState({});
    const [token] = useState(localStorage.getItem("token")) || "";
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams(); //const navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            return navigate("/Auth", { replace: true });
        }

        CRUDsAPI.uf
            .consulta(uuid)
            .then((response) => {
                setUf(response.data);
            })
            .catch((error) => {
                setFlashMessage(error.message, "error");
                return error;
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, uuid]);

    async function editUf(uf) {
        let msgType = "success";

        uf.updatedUser = localStorage.getItem("pessoaId");

        const formData = new FormData();

        const ufFormData = Object.keys(uf).forEach((key) => {
            if (key === "bandeira") {
                for (let i = 0; i < uf[key]?.length; i++) {
                    formData.append(`bandeira`, uf[key][i]);
                }
            } else {
                formData.append(key, uf[key]);
            }
        });

        ufFormData !== undefined && formData.append("bandeira", ufFormData);

        const data = await CRUDsAPI.uf
            .editarComFormData(formData)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                msgType = "error";
                console.error(error);
                return error.response.data;
            });

        setFlashMessage(data.message, msgType);

        if (msgType !== "error") {
            return navigate("/Uf");
        }
    }

    return (
        <section className={`${styles.form_container} ${styles[parametro.tema]}`}>
            {uf.uuid ? (
                <Form token={token} handleSubmit={editUf} formData={uf} btnText={"Atualizar"} caminhoVoltar={`/Uf`} />
            ) : (
                <p> "Não Existe Estados"</p>
            )}
        </section>
    );
};

export default Edit;
