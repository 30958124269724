import { createTheme } from "@mui/material";

const tableTheme = (globalTheme) =>
    createTheme(
        {
            palette: {
                mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
                primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
                info: {
                    main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
                },
                background: {
                    default:
                        globalTheme.palette.mode === "light"
                            ? "rgb(255,231,231)" //random light yellow color for the background in light mode
                            : "#1e1e1e", //pure black table in dark mode for fun
                },
            },
            typography: {
                button: {
                    textTransform: "none", //customize typography styles for all buttons in table by default
                    fontSize: "1.2rem",
                },
            },
            components: {
                MuiTooltip: {
                    styleOverrides: {
                        tooltip: {
                            fontSize: "1.1rem", //override to make tooltip font size larger
                        },
                    },
                },
                MuiSwitch: {
                    styleOverrides: {
                        thumb: {
                            color: "pink", //change the color of the switch thumb in the columns show/hide menu to pink
                        },
                    },
                },
            },
        },
        [globalTheme]
    );

export default tableTheme;
