import React, { useEffect, useMemo, useState, useContext } from "react";
import { Context } from "../../../context/UserContext";

import { useNavigateParams } from "../../../hooks/useNavigateParams";
import { MaterialReactTable, useMaterialReactTable, MRT_ExpandAllButton } from "material-react-table";

import { ThemeProvider, Stack } from "@mui/material";
/* tema customizado*/
import tableThemeCustom from "../../form/tableThemeCustom";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";

//Import Material React Table Translations
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";
import CustomDialog from "../../layout/CustomDialog";

import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { Delete, Print } from "@mui/icons-material";
import tratar from "../../../utils/retornaTratamentoURL";
import CrudsAPI from "../../../api/CRUDs";

/* util */
import { obterPermissoesUsuario } from "../../../utils/funcoes";

const Index = () => {
    const [dados, setDados] = useState([]);
    const [token] = useState(localStorage.getItem("token")) || "";
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();
    const urlParams = new URLSearchParams(window.location.search);

    const ul = urlParams.get("ul") || 3;
    const li = urlParams.get("li") || 53;
    const tipo = urlParams.get("tipo");
    const operacaoUUID = urlParams.get("operacao");
    const uuid = urlParams.get("uuid");
    const btnText = urlParams.get("btnText") || "Cadastrar";

    const params = new URLSearchParams({
        ul: ul,
        li: li,
        tipo: tipo,
        operacao: operacaoUUID,
        uuid: uuid,
        btnText: btnText,
    });

    const { parametro } = useContext(Context);
    /* Obtem a paginação padrão */
    useEffect(() => {
        if (parametro && parametro.paginacao) {
            setPagination({
                pageIndex: pagination.pageIndex || 0,
                pageSize: parametro.paginacao,
            });
        }
    }, [parametro]);

    //data and fetching state
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    //tratar filtros se vier pela navbar
    if (localStorage.path.replace(/["]/g, "") !== window.location.pathname) {
        tratar.LimparFiltros();
        localStorage.setItem("path", JSON.stringify(window.location.pathname));
    }

    //table state
    const [columnFilterFns, setColumnFiltersMode] = useState(
        localStorage.columnFilterFns.length > 2 ? JSON.parse(localStorage.columnFilterFns) : []
    );

    const [columnFilters, setColumnFilters] = useState(
        localStorage.columnFilters.length > 2 ? JSON.parse(localStorage.columnFilters) : []
    );

    const [globalFilter, setGlobalFilter] = useState(
        localStorage.globalFilter.length > 2 ? JSON.parse(localStorage.globalFilter) : ""
    );

    const [sorting, setSorting] = useState(localStorage.sorting.length > 2 ? JSON.parse(localStorage.sorting) : []);

    const [pagination, setPagination] = useState({
        pageIndex: JSON.parse(localStorage.pagination).pageIndex || 0,
        pageSize: JSON.parse(localStorage.pagination).pageSize || 5,
    });

    const fetchData = async () => {
        if (!dados.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }

        try {
            const { data } = await CrudsAPI.balancete.listar(
                pagination.pageIndex * pagination.pageSize,
                pagination.pageSize,
                JSON.stringify(columnFilters ?? []),
                JSON.stringify(columnFilterFns ?? []),
                globalFilter ?? "",
                JSON.stringify(sorting ?? [])
            );
            setDados(data.data);
            setRowCount(data.meta.totalRowCount);
        } catch (error) {
            setIsError(true);
            console.error(error);
            return;
        }
        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
    };

    useEffect(() => {
        if (!token) {
            return navigate("/Auth", { replace: true });
        }

        //funcao para setar localStorage
        localStorage.setItem(
            "pagination",
            JSON.stringify({
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
            })
        );
        localStorage.setItem("columnFilterFns", JSON.stringify(columnFilterFns));
        localStorage.setItem("columnFilters", JSON.stringify(columnFilters));
        localStorage.setItem("globalFilter", JSON.stringify(globalFilter));
        localStorage.setItem("sorting", JSON.stringify(sorting));

        //permissões do usuario
        obterPermissoesUsuario(localStorage.getItem("pessoaId"), localStorage.getItem("empresaId"), ul, li);

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, columnFilterFns, columnFilters, globalFilter, pagination.pageIndex, pagination.pageSize, sorting]);

    const getMonthName = (monthNumber) => {
        const months = [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
        ];
        return months[monthNumber - 1];
    };

    const columns = useMemo(
        () => [
            {
                id: "Balancete",
                header: "Balancete Financeiro",
                columns: [
                    {
                        accessorKey: "ano",
                        header: "Ano",
                        muiTableBodyCellProps: ({ row }) => ({
                            onClick: () => {},
                        }),
                        columnFilterModeOptions: ["fuzzy"],
                    },
                    {
                        accessorKey: "valorReceber",
                        header: "Lançamento Receber",
                        columnFilterModeOptions: [], //remove individualmente as opções
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valorReceber === null
                                ? ""
                                : parseFloat(row.valorReceber)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "valorPagar",
                        header: "Lançamento Pagar",
                        columnFilterModeOptions: [], //remove individualmente as opções
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valorPagar === null
                                ? ""
                                : parseFloat(row.valorPagar)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "totalRecebido",
                        header: "Valor Recebido",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.totalRecebido === null
                                ? ""
                                : parseFloat(row.totalRecebido)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "totalPago",
                        header: "Valor Pago",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.totalPago === null
                                ? ""
                                : parseFloat(row.totalPago)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "resultado",
                        header: "Recebido - Pago",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.resultado === null
                                ? ""
                                : parseFloat(row.resultado)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "resultadoPercentual",
                        header: "Reebido/Pago %",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.resultadoPercentual === null
                                ? ""
                                : parseFloat(row.resultadoPercentual)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }) + " %",
                    },
                ],
            },

            //column definitions...
        ],
        [] //getCommonEditTextFieldProps
    );

    /* modo tema */
    const theme = tableThemeCustom();

    return (
        <div>
            <ThemeProvider theme={theme}>
                <MaterialReactTable
                    displayColumnDefOptions={{
                        "mrt-row-actions": {
                            muiTableHeadCellProps: {
                                align: "center",
                            },
                            size: 100,
                        },
                        "mrt-row-numbers": {
                            enableColumnOrdering: true, //turn on some features that are usually off
                            enableResizing: true,
                            muiTableHeadCellProps: {
                                sx: {
                                    fontSize: "1.2rem",
                                },
                            },
                        },
                        "mrt-row-select": {
                            enableColumnActions: true,
                            enableHiding: true,
                            size: 50,
                        },
                    }}
                    //enableColumnFilterModes
                    enableGlobalFilterModes
                    //enableColumnResizing
                    enableColumnOrdering
                    enableEditing
                    renderRowActions={({ row, table }) => {
                        const handleImprimir = async (obj) => {
                            const urlSearchParams = new URLSearchParams({
                                start: `${pagination.pageIndex * pagination.pageSize}`,
                                size: `${pagination.pageSize}`,
                                filters: JSON.stringify(columnFilters ?? []),
                                filtersMode: JSON.stringify(columnFilterFns ?? []),
                                globalFilter: globalFilter ?? "",
                                sorting: JSON.stringify(sorting ?? []),
                                tipo: urlParams.get("tipo") ?? "",
                            });

                            const response = await CrudsAPI.balancete.imprimir(urlSearchParams, obj);

                            // Cria um Blob a partir dos dados do arraybuffer
                            const pdfBlob = new Blob([response.data], {
                                type: "application/pdf",
                            });

                            // Cria um URL temporário para o Blob
                            const pdfUrl = URL.createObjectURL(pdfBlob);

                            // Use o URL do PDF para abrir em uma nova janela ou realizar outras ações
                            window.open(pdfUrl);
                        };
                        return (
                            <Box sx={{ display: "flex", gap: "1rem" }}>
                                <Tooltip arrow placement="right-end" title="Imprimir">
                                    <span>
                                        {" "}
                                        <IconButton
                                            onClick={() => handleImprimir(row.original)}
                                            disabled={localStorage.getItem("imprimir") === "true" ? false : true}
                                        >
                                            <Print />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </Box>
                        );
                    }}
                    renderDetailPanel={({ row }) => (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                gap: "10px", // Espaçamento entre os elementos
                                padding: "10px", // Espaçamento interno da caixa
                            }}
                        >
                            {row.original.mesesBaixa !== null &&
                                row.original.mesesBaixa.length > 0 &&
                                row.original.mesesBaixa.map((mesBaixa, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: "100%",
                                            gap: "20px",
                                            backgroundColor: "#353535", // Cor de fundo para melhor visualização
                                            padding: "5px 50px", // Espaçamento interno
                                            borderRadius: "5px", // Bordas arredondadas
                                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Sombra para destaque
                                        }}
                                    >
                                        <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                                            <span>{getMonthName(mesBaixa.mes)}</span>
                                        </Box>
                                        <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                                            <span>
                                                Recebido{" "}
                                                {mesBaixa.totalValorBaixaReceber.toLocaleString("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                })}
                                            </span>
                                        </Box>
                                        <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                                            <span>
                                                Pago{" "}
                                                {mesBaixa.totalValorBaixaPagar.toLocaleString("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                })}
                                            </span>
                                        </Box>
                                    </Box>
                                ))}
                        </Box>
                    )}
                    columns={columns}
                    data={dados}
                    //enableRowSelection
                    getRowId={(row) => row.uuid}
                    manualFiltering
                    manualPagination
                    manualSorting
                    muiToolbarAlertBannerProps={
                        isError
                            ? {
                                  color: "error",
                                  children: "Error loading data",
                              }
                            : undefined
                    }
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setColumnFiltersMode}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    rowCount={rowCount}
                    initialState={{
                        showColumnFilters: true,
                        density: "compact",
                        columnPinning: { left: ["mrt-row-select"] },
                    }}
                    state={{
                        columnFilterFns,
                        columnFilters,
                        globalFilter,
                        isLoading,
                        pagination,
                        showAlertBanner: isError,
                        showProgressBars: isRefetching,
                        sorting,
                    }}
                    positionToolbarDropZone={"top"}
                    positionExpandColumn={"first"}
                    positionActionsColumn={"first"}
                    positionToolbarAlertBanner="bottom"
                    localization={MRT_Localization_PT_BR}
                />
            </ThemeProvider>
        </div>
    );
};
export default Index;
