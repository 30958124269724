import { createContext } from "react";
import { Provider } from "react-redux";
import store from "../store";
import useAuth from "../hooks/useAuth";
const Context = createContext();

function UserProvider({ children }) {
    const { login, logout, authenticated, permissaoUsuario, parametro } = useAuth();

    return (
        <Provider store={store}>
            <Context.Provider
                value={{
                    login,
                    logout,
                    permissaoUsuario,
                    authenticated,
                    parametro,
                }}
            >
                {children}
            </Context.Provider>
        </Provider>
    );
}

export { Context, UserProvider };
