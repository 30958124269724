import api from "./";

export const listarChavesPIX = (start, size, filters, filtersMode, globalFilter, sorting, contaId) =>
    api.get("/conta/listarChavesPIX", {
        params: {
            start,
            size,
            filters,
            filtersMode,
            globalFilter,
            sorting,
            contaId,
        },
    });

export const createChavePIX = async (novaChave) => {
    try {
        const { data } = await api.post("/conta/createChavePIX", novaChave);
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};

export const duplicidadeChavePIX = async (contaId, descricao) => {
    try {
        const { data } = await api.get("/conta/duplicidadeChavePIX/", {
            params: { contaId, descricao },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};

export const deletarChavePIX = (uuid) => api.post("/conta/deletarChavePIX", { uuid });
