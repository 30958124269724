import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import { Stack, Button } from "@mui/material";

import Select from "../../form/Select";
import SelectEmpresa from "../../form/SelectEmpresa";
import SelectUsuario from "../../form/SelectUsuario";
import Checkbox from "../../form/Checkbox";

//utils
import * as EmpresaAPI from "../../../api/empresa";
import * as UsuarioAPI from "../../../api/usuario";
import * as TabelaAPI from "../../../api/tabela";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, OptionsEmpresa }) {
    const [dados, setDados] = useState(formData || "");
    const [empresas, setEmpresa] = useState(OptionsEmpresa || []);
    const [usuarios, setUsuario] = useState([]);
    const [menus, setMenus] = useState([]);
    const [menuSubs, setMenuSub] = useState([]);
    const navigate = useNavigateParams();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    const [disabledUsuarioCheckbox, setIsDisabledUsuario] = useState(false);
    const [disabledCheckbox, setIsDisabled] = useState(false);

    //variáveis comuns
    const empresaId = localStorage.getItem("empresaId");
    const usuariosPermitidos = ["8", "13"];
    const pessoaId = localStorage.getItem("pessoaId");

    if (!dados.empresaId) {
        dados.empresaId = empresaId;
        dados.ativo = true;
        dados.visualizar = true;
    }
    if (!dados.pessoaId) {
        dados.pessoaId = document.getElementById("pessoaId")?.value;
    }
    //carregar API's Externas
    const consultaEmpresa = async () => {
        const result = await EmpresaAPI.empresa(empresaId);
        setEmpresa(Array.isArray(result) ? result : []);
    };
    const consultaUsuario = async (pessoaId, empresaId) => {
        const result = await UsuarioAPI.usuario(pessoaId, empresaId);
        setUsuario(Array.isArray(result) ? result : []);
    };
    const consultaMenu = async () => {
        const result = await TabelaAPI.menu("");
        setMenus(Array.isArray(result) ? result : []);
        if (btnText.toLowerCase() === "cadastrar") {
            setDados((prev) => ({
                ...prev,
                menuId: result[0].id,
            }));
        }
    };
    const consultaMenuSub = async (menuId, descricao) => {
        const result = await TabelaAPI.menuSub(menuId, descricao);
        setMenuSub(Array.isArray(result) ? result : []);
        if (btnText.toLowerCase() === "cadastrar") {
            setDados((prev) => ({
                ...prev,
                menuSubId: result[0].id,
            }));
        }
    };
    const atualizaMenuSub = async (id) => {
        const result = await TabelaAPI.tabelaById("", "menuSub", id);

        setDados((prev) => ({
            ...prev,
            menuSub: result,
        }));
    };
    const buscaUsuarioPermitido = async (empresaId, pessoaId) => {
        if (!empresaId || !pessoaId) {
            return null;
        }
        const result = await UsuarioAPI.buscaUsuarioPermitido(empresaId, pessoaId);
        if (dados.menuSubId === "3") {
            setIsDisabledUsuario(result ? false : true);
        } else {
            setIsDisabledUsuario(true);
        }
    };
    useEffect(() => {
        setDados(formData || {});
        consultaEmpresa(formData?.Empresa);
        consultaMenu(formData?.Menu?.descricao);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);
    useEffect(() => {
        setIsDisabled(parseInt(dados.menuSubId) === 54 && !usuariosPermitidos.includes(pessoaId)); //menu de ajuda

        if (dados.menuSubId) {
            //atualiza o menuSub
            atualizaMenuSub(dados.menuSubId);
            buscaUsuarioPermitido(dados.empresaId, dados.pessoaId);
        }
    }, [dados.menuSubId, dados.pessoaId, dados.empresaId]);
    useEffect(() => {
        if (dados.empresaId) {
            consultaUsuario(null, dados.empresaId);
        }
        if (dados.menuId) {
            consultaMenuSub(dados.menuId);
        }
    }, [dados.empresaId, dados.menuId]);
    useEffect(() => {
        const menu = dados.menuSub;
        const campos = [
            "incluir",
            "editar",
            "imprimir",
            "cancelar",
            "baixar",
            "cancelarBaixa",
            "transformar",
            "custos",
            "usuario",
        ];

        campos.forEach((campo) => {
            if (menu?.[campo] === false) {
                dados[campo] = false;
            }
        });
    }, [dados.menuSub]);

    async function handleChange(e) {
        const { name, value, checked, type } = e.target;
        if (name === "menuSubId") {
            //atualizar o menuSub
        }
        setDados({ ...dados, [name]: type === "checkbox" ? checked : value });
    }
    async function handleOnBlur(e) {}
    async function handleOnFocus(e) {}

    const submit = async (e) => {
        e.preventDefault();

        if (!dados.empresaId && document.getElementById("empresaId").value !== undefined) {
            dados.empresaId = parseInt(document.getElementById("empresaId").value);
        }
        if (!dados.pessoaId && document.getElementById("pessoaId").value !== undefined) {
            dados.pessoaId = parseInt(document.getElementById("pessoaId").value);
        }
        if (!dados.menuId && document.getElementById("menuId").value !== undefined) {
            dados.menuId = parseInt(document.getElementById("menuId").value);
        }
        if (!dados.menuSubId && document.getElementById("menuSubId").value !== undefined) {
            dados.menuSubId = parseInt(document.getElementById("menuSubId").value);
        }
        if (parseInt(dados.menuSubId) === 54) {
            //54 é o menu de ajuda caso a opção esteja marcada será desmarcada
            if (usuariosPermitidos.includes(pessoaId)) {
                setDados((prev) => ({
                    ...prev,
                    baixar: false,
                    cancelar: false,
                    cancelarBaixa: false,
                    custos: false,
                    editar: false,
                    incluir: false,
                    usuario: false,
                }));
            }
        }
        handleSubmit(dados);
    };

    return (
        <form onSubmit={submit}>
            <h1>Gestão de Permissões</h1>
            <div>
                <SelectEmpresa
                    text="Selecione a Empresa"
                    name="empresaId"
                    options={empresas}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.empresaId}
                    notOptNull={true}
                    required
                />
                <SelectUsuario
                    text="Selecione o Usuário"
                    name="pessoaId"
                    options={usuarios}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.pessoaId || ""}
                    notOptNull
                />
                <Select
                    text="Selecione o Menu"
                    name="menuId"
                    options={menus}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.menuId || ""}
                    isEnum={false}
                />
                <Select
                    text="Selecione o SubMenu"
                    name="menuSubId"
                    options={menuSubs}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.menuSubId || ""}
                    isEnum={false}
                />
            </div>
            <div>
                <Checkbox
                    title="Quando marcado a permissão esta ATIVO"
                    text="Ativo"
                    name="ativo"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.ativo}
                    checked={dados.ativo === true ? "checked" : ""}
                />
                <Checkbox
                    title="Quando marcado permitirá VISUALIZAR"
                    text="Visualizar"
                    name="visualizar"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.visualizar}
                    checked={dados.visualizar === true ? "checked" : ""}
                />

                <Checkbox
                    title="Quando marcado permitirá INCLUIR"
                    text="Incluir"
                    name="incluir"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.incluir}
                    checked={dados.incluir}
                    disabled={!dados.menuSub?.incluir || disabledCheckbox}
                />

                <Checkbox
                    title="Quando marcado permitirá EDITAR"
                    text="Editar"
                    name="editar"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.editar}
                    checked={dados.editar}
                    disabled={!dados.menuSub?.editar || disabledCheckbox}
                />
                <Checkbox
                    title="Quando marcado permitirá IMPRIMIR"
                    text="Imprimir"
                    name="imprimir"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.imprimir}
                    checked={dados.imprimir}
                    disabled={!dados.menuSub?.imprimir}
                />
                <Checkbox
                    title="Quando marcado permitirá CANCELAR"
                    text="Cancelar"
                    name="cancelar"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.cancelar}
                    checked={dados.cancelar}
                    disabled={!dados.menuSub?.cancelar || disabledCheckbox}
                />
                <Checkbox
                    title="Quando marcado permitirá BAIXAR, funciona apenas no modulo FINANCEIRO"
                    text="Baixar"
                    name="baixar"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.baixar}
                    checked={dados.baixar}
                    disabled={!dados.menuSub?.baixar}
                />
                <Checkbox
                    title="Quando marcado permitirá CANCELAR BAIXAR, funciona apenas no modulo FINANCEIRO"
                    text="Cancelar Baixa"
                    name="cancelarBaixa"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.cancelarBaixa}
                    checked={dados.cancelarBaixa}
                    disabled={!dados.menuSub?.cancelarBaixa}
                />
                <Checkbox
                    title="Quando marcado permitirá TRANSFORMAR"
                    text="Transformar"
                    name="transformar"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.transformar}
                    checked={dados.transformar}
                    disabled={!dados.menuSub?.transformar}
                />
                <Checkbox
                    title="Quando marcado permitirá visualizar custos"
                    text="Custos"
                    name="custos"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.custos}
                    checked={dados.custos}
                    disabled={!dados.menuSub?.custos}
                />

                <Checkbox
                    title="Permite cadastrar ou editar usuário"
                    text="Usuário"
                    name="usuario"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.usuario}
                    checked={dados.usuario}
                    disabled={!dados.menuSub?.usuario || disabledUsuarioCheckbox}
                />
            </div>
            <div>
                <Stack direction="row" spacing={1}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                            navigate(caminhoVoltar, {
                                state: {
                                    ul: urlParams.get("ul"),
                                    li: urlParams.get("li"),
                                    tipo: urlParams.get("tipo"),
                                },
                            })
                        }
                    >
                        Voltar
                    </Button>
                    <Button type="submit" variant="contained" color="success">
                        {btnText}
                    </Button>
                </Stack>
            </div>
        </form>
    );
}

export default Form;
