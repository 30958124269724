import { useState, useEffect } from "react";
import "react-tabs/style/react-tabs.css";
import "moment/locale/pt-br";

import { Stack, Button } from "@mui/material";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import Input from "../../form/Input";
import Checkbox from "../../form/Checkbox";

import * as BancoAPI from "../../../api/banco";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar }) {
    const [dados, setDados] = useState(formData || {});
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();

    useEffect(() => {
        setDados(formData || {});
    }, [formData]);

    function handleChange(e) {
        if (e.target.name === "ativo" || e.target.name === "febraban") {
            setDados({ ...dados, [e.target.name]: e.target.checked });
        } else {
            setDados({ ...dados, [e.target.name]: e.target.value });
        }
    }

    async function handleOnBlur(e) {
        //verifica duplicidade
        if (e.target.name === "code" && btnText === "Cadastrar") {
            const existeBanco = await BancoAPI.verificacao(
                document.getElementById("code").value,
                document.getElementById("descricao").value
            );

            if (existeBanco.length > 0) {
                setFlashMessage("Registro em duplicidade!", "warning");
                return navigate(`/Bancos/CreateOrEdit`, {
                    uuid: existeBanco[0].uuid,
                });
            } else {
                //verifica na api dos bancos
                fetch(`https://brasilapi.com.br/api/banks/v1/${document.getElementById("code").value}`)
                    .then((response) => response.json())
                    .then((data) => {
                        setDados({
                            ...dados,
                            descricao: data.fullName,
                            fullName: data.fullName,
                            name: data.name,
                            ispb: data.ispb,
                        });
                    })
                    .catch((error) => {
                        console.error(error);
                        return;
                    });
            }
        }
    }
    function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();
        handleSubmit(dados);
    };

    return (
        <form onSubmit={submit}>
            <h1>Bancos</h1>
            <div>
                <Input
                    text="Código"
                    type="text"
                    name="code"
                    placeholder="Código"
                    title="Digite o código do banco"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.code || ""}
                    required
                />
                <Input
                    text="Banco"
                    type="text"
                    name="descricao"
                    placeholder="Nome do banco"
                    title="Digite o nome do banco"
                    inputWidth="350px"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.descricao || ""}
                    required
                />
            </div>
            <div>
                <Input
                    text="ISPB"
                    type="text"
                    name="ispb"
                    placeholder="ISPB"
                    title=" Identificador de Sistema de Pagamentos Brasileiro"
                    inputWidth="100"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.ispb || ""}
                />
                <Input
                    text="Nome"
                    type="text"
                    name="name"
                    placeholder="Nome do banco"
                    title="Digite o nome do banco"
                    inputWidth="350px"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.name || ""}
                />
                <Checkbox
                    title="Informa se o banco é filiado a febraban"
                    text="Febraban"
                    name="febraban"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.febraban}
                    checked={dados.febraban === true ? "checked" : ""}
                />
            </div>
            <div>
                <Stack direction="row" spacing={1}>
                    <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                        Voltar
                    </Button>
                    <Button type="submit" variant="contained" color="success">
                        {btnText}
                    </Button>
                </Stack>
            </div>
        </form>
    );
}
export default Form;
