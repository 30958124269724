import { useState, useEffect } from "react";
import "react-tabs/style/react-tabs.css";
import "moment/locale/pt-br";

import { Stack, Button } from "@mui/material";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import CrudsAPI from "../../../api/CRUDs";

import Input from "../../form/Input";
import Checkbox from "../../form/Checkbox";

/* utils */

function Form({ handleSubmit, formData, btnText, caminhoVoltar }) {
    const [dados, setDados] = useState({});
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();

    //define o estado inicial do objeto
    if (!dados.ativo) {
        dados.ativo = true;
    }

    useEffect(() => {
        setDados(formData || {});
    }, [formData]);

    function handleChange(e) {
        const { name, value, checked, type } = e.target;
        setDados({ ...dados, [name]: type === "checkbox" ? checked : value });
    }

    async function handleOnBlur(e) {
        const { name, value, checked, type } = e.target;

        //verifica duplicidade
        if (name === "descricao" && btnText.toLowerCase() === "cadastrar") {
            const data = await CrudsAPI.pais.duplicidade(value);

            if (data?.data?.uuid) {
                setFlashMessage("Registro em duplicidade!", "warning");
                return navigate(`/Pais/CreateOrEdit`, {
                    uuid: data.data.uuid,
                    btnText: "Atualizar",
                });
            }
        }
    }
    function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();

        handleSubmit(dados);
    };

    return (
        <form onSubmit={submit}>
            <h1>País</h1>
            <div>
                <Input
                    text="Código"
                    type="text"
                    name="codigo"
                    placeholder="Código"
                    title="Digite o código do país"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.codigo || ""}
                    required={true}
                />
                <Input
                    text="Sigla"
                    type="text"
                    name="sigla"
                    placeholder="Sigla"
                    title="Digite a sigla do país"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.sigla || ""}
                    required={true}
                />
                <Input
                    text="Nome"
                    type="text"
                    name="descricao"
                    placeholder="nome"
                    title="Digite o nome do país"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.descricao || ""}
                    required={true}
                />
                <Input
                    text="Nacionalidade"
                    type="text"
                    name="nacionalidade"
                    placeholder="Nacionalidade"
                    title="Digite o nome da Anilox"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.nacionalidade || ""}
                    required={true}
                />
                <Checkbox
                    title="Ativa quando marcado"
                    text="Ativo"
                    name="ativo"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.ativo}
                    checked={dados.ativo === true ? "checked" : ""}
                />
            </div>
            <div>
                <Stack direction="row" spacing={1}>
                    <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                        Voltar
                    </Button>
                    <Button type="submit" variant="contained" color="success">
                        {btnText}
                    </Button>
                </Stack>
            </div>
        </form>
    );
}
export default Form;
