import { useState, useEffect } from "react";
import "react-tabs/style/react-tabs.css";
import "moment/locale/pt-br";

import { Stack, Button } from "@mui/material";

/* hooks */
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import Input from "../../form/Input";
import InputNumber from "../../form/InputNumber";
import TextArea from "../../form/TextArea";
import Checkbox from "../../form/Checkbox";
import SelectEmpresa from "../../form/SelectEmpresa";

/* utils */
import { formatarNumero, formatarNumeroDecimal } from "../../../utils/masks";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, OptionsEmpresa, EnumTipo }) {
    const [dados, setDados] = useState(formData || {});
    const navigate = useNavigateParams();

    //define o estado inicial do objeto
    if (!dados.empresaId) {
        dados.empresaId = localStorage.getItem("empresaId");
        dados.ativo = true;
    }

    useEffect(() => {
        setDados(formData || {});
    }, [formData]);

    function handleChange(e) {
        if (e.target.name === "ativo") {
            setDados({ ...dados, [e.target.name]: e.target.checked });
        } else if (e.target.name === "aliquota" || e.target.name === "vidaUtil" || e.target.name === "depreciacao") {
            const valorFormatado = formatarNumeroDecimal(e.target.value);
            setDados({
                ...dados,
                [e.target.name]: valorFormatado,
            });
        } else {
            setDados({ ...dados, [e.target.name]: e.target.value });
        }
    }
    async function handleOnBlur(e) {}
    function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();

        handleSubmit(dados);
    };

    return (
        <form onSubmit={submit}>
            <h1>NCM - Nomenclatura Comum do Mercosul</h1>
            <div>
                <SelectEmpresa
                    text="Selecione a Empresa"
                    name="empresaId"
                    options={OptionsEmpresa}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.empresaId || ""}
                    required
                    notOptNull={true}
                />
                <Input
                    text="NCM"
                    type="text"
                    name="ncm"
                    placeholder="NCM"
                    title="Digite o NCM"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.ncm || ""}
                />
                <Input
                    text="NCM Formatado"
                    type="text"
                    name="ncmFormatado"
                    placeholder="NCM Formatado"
                    title="Digite o NCM Formatado"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.ncmFormatado || ""}
                />
            </div>
            <div>
                <TextArea
                    text="Descrição"
                    type="text"
                    name="descricao"
                    placeholder="nome do CFOP"
                    title="Digite o nome do CFOP"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.descricao || ""}
                    required
                    textAlign="left"
                    linhas="4"
                    colunas="122"
                />
            </div>
            <div>
                <InputNumber
                    text="Vida Útil"
                    type="text"
                    name="vidaUtil"
                    placeholder="Vida Útil"
                    inputWidth="100px"
                    title="digite Vida Útil"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.vidaUtil ? formatarNumero(dados.vidaUtil) : ""}
                />
                <InputNumber
                    text="Depreciação"
                    type="text"
                    name="depreciacao"
                    placeholder="Aliquota"
                    inputWidth="100px"
                    title="digite o deprecição do NCM"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.depreciacao ? formatarNumero(dados.depreciacao) : ""}
                />
                <Checkbox
                    title="Quando marcado a forma de pagamento ou recebimento esta ATIVO"
                    text="Ativo"
                    name="ativo"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.ativo}
                    checked={dados.ativo === true ? "checked" : ""}
                />
            </div>
            <div>
                <Stack direction="row" spacing={1}>
                    <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                        Voltar
                    </Button>
                    <Button type="submit" variant="contained" color="success">
                        {btnText}
                    </Button>
                </Stack>
            </div>
        </form>
    );
}
export default Form;
