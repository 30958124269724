const mask = (str) => {
    let v = "";
    if (str) {
        v = str.replace(/\D/g, "");

        if (v.length) {
            v = v.substring(0, 8); // limita em 8 números
            v = v.replace(/^(\d{2})(\d)/, "$1.$2");
            v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2-$3");
        }
    }

    return v;
};

export default mask;
