import { Link } from "react-router-dom";
import styles from "./Navbar.module.css";

/* contexts */
import { Context } from "../../context/UserContext";
import { useContext } from "react";
const { v4: uuidv4 } = require("uuid");
function Navbar() {
    const { authenticated, logout, permissaoUsuario } = useContext(Context);

    let groups = {};
    if (permissaoUsuario.length !== undefined) {
        //const pArray = Object.entries(permissaoUsuario);
        //https://stackoverflow.com/questions/21776389/javascript-object-grouping
        groups = permissaoUsuario?.reduce((groups, item) => {
            const group = groups[item.Menu.descricao] || [];
            group.push(item);
            //groups[item.menuId] = group;
            groups[item.Menu.descricao] = group;
            return groups;
        }, {});
    }
    return (
        <nav className={styles.navbar} id="menu">
            <ul>
                {authenticated ? (
                    <>
                        {permissaoUsuario.length !== undefined &&
                            Object.keys(groups).map((key, index, array) => (
                                <li key={uuidv4()}>
                                    {" "}
                                    <a href={"/#"} key={uuidv4()}>
                                        {key}
                                    </a>
                                    <ul key={uuidv4()}>
                                        {groups[key]?.map((pm) => (
                                            <li key={pm.menuSub.uuid}>
                                                <Link
                                                    to={{
                                                        pathname: pm.menuSub.url,
                                                        search: `?ul=${pm.menuId}&li=${pm.menuSubId}&tipo=${
                                                            pm.menuSub.tipo || ""
                                                        }&operacao=${pm.menuSub?.Operacao?.uuid || ""}`,
                                                    }}
                                                    title={pm.menuSub.title}
                                                >
                                                    {pm.menuSub.descricao}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        <li>
                            {" "}
                            <Link to="/" onClick={logout} title="click para sair do Sistema">
                                {" "}
                                Sair
                            </Link>
                        </li>
                    </>
                ) : (
                    <>
                        <li>
                            {" "}
                            <Link to="/Auth" title="Click para acessar o sistema">
                                {" "}
                                Acessar
                            </Link>
                        </li>
                    </>
                )}
            </ul>
        </nav>
    );
}

export default Navbar;
