import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Context } from "../../../context/UserContext";
import Form from "./Form";
import styles from "../../form/Form.module.css";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";

import * as BancoAPI from "../../../api/banco";

const CreateOrEdit = () => {
    const [dados, setDados] = useState({});
    const [token] = useState(localStorage.getItem("token")) || "";
    const { parametro } = useContext(Context);
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);

    const uuid = urlParams.get("uuid");

    let btnText = "";
    !uuid ? (btnText = "Cadastrar") : (btnText = "Atualizar");

    useEffect(() => {
        if (!token) {
            navigate("/Auth", { replace: true });
        }
    }, [token, navigate]);

    useEffect(() => {
        if (uuid) {
            //consulta a API
            BancoAPI.consulta(uuid)
                .then((response) => {
                    setDados(response.data);
                })
                .catch((error) => {
                    console.error(error);
                    setFlashMessage(error.response.data.message, error.response.data.type);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid]);

    async function managerDados(dados) {
        let urlNavigate = "";

        try {
            let response;

            if (uuid) {
                response = await BancoAPI.editar(dados);
            } else {
                response = await BancoAPI.cadastrar(dados);

                if (response.status === 202) {
                    setDados(response.data);
                    response.data.message = "Registro em duplicidade!";
                    response.data.type = "warning";
                    urlNavigate = `/Bancos/CreateOrEdit?uuid=${response.data.uuid}`;
                } else {
                    urlNavigate = "/Bancos";
                }
            }

            setFlashMessage(response.data.message, response.data.type);

            setTimeout(() => {
                if (response.data.type === "success") {
                    navigate("/Bancos");
                } else {
                    navigate(urlNavigate);
                }
            }, parametro.msgTempo * 1000);
        } catch (error) {
            console.error(error);
            setFlashMessage(error.response.data.message, error.response.data.type);
        }
    }

    // async function managerBanco(dados) {
    //     let msgType = "success";

    //     if (uuid) {
    //         dados.updatedUser = parseInt(localStorage.getItem("pessoaId"));

    //         try {
    //             const { data } = await BancoAPI.editar(dados);
    //             setFlashMessage(data.message, msgType);
    //         } catch (error) {
    //             msgType = "error";
    //             console.error(error);
    //             setFlashMessage(error.response.data, msgType);
    //         }

    //         if (msgType !== "error") {
    //             return navigate("/Bancos");
    //         }
    //     } else {
    //         dados.createdUser = parseInt(localStorage.getItem("pessoaId"));
    //         dados.updatedUser = parseInt(localStorage.getItem("pessoaId"));

    //         try {
    //             const { data, status } = await BancoAPI.cadastrar(dados);
    //             if (status === 202) {
    //                 setDados(data);
    //                 setFlashMessage(`Banco ${data.descricao} já cadastrada! Você será redirecionado para o modo de edição.`, msgType);
    //                 return navigate(`/Bancos/CreateOrEdit?uuid=${data.uuid}`);
    //             }
    //         } catch (error) {
    //             msgType = "error";
    //             console.error(error);
    //             setFlashMessage(error.response.data, msgType);
    //         }

    //         if (msgType !== "error") {
    //             return navigate("/Bancos");
    //         }
    //     }
    // }

    return (
        <section className={`${styles.form_container} ${styles[parametro.tema]}`}>
            <Form token={token} handleSubmit={managerDados} formData={dados} btnText={btnText} caminhoVoltar={`/Bancos`} />
        </section>
    );
};
export default CreateOrEdit;
