// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Message_top__PiidQ {
    position: fixed; /* Adicione esta linha para que a mensagem fique fixa */
    top: 20px; /* Ajuste conforme necessário */
    left: 50%;
    transform: translateX(-50%);
    max-width: 60%;
    padding: 1em;
    border: 1px solid #000;
    margin: 1.2em auto 0;
    text-align: center;
    border-radius: 5px;
    z-index: 9999; /* Adicione um z-index alto para sobrepor outros elementos */
    background-color: rgba(0, 0, 0, 0.8); /* Exemplo de cor de fundo */
    color: white; /* Exemplo de cor de texto */
}

.Message_center__uPRMP {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 60%;
    padding: 1em;
    border: 1px solid #000;
    text-align: center;
    border-radius: 5px;
    z-index: 9999; /* Adicione um z-index alto aqui também */
    background-color: rgba(0, 0, 0, 0.8); /* Exemplo de cor de fundo */
    color: white; /* Exemplo de cor de texto */
}
`, "",{"version":3,"sources":["webpack://./src/components/layout/Message.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe,EAAE,uDAAuD;IACxE,SAAS,EAAE,+BAA+B;IAC1C,SAAS;IACT,2BAA2B;IAC3B,cAAc;IACd,YAAY;IACZ,sBAAsB;IACtB,oBAAoB;IACpB,kBAAkB;IAClB,kBAAkB;IAClB,aAAa,EAAE,4DAA4D;IAC3E,oCAAoC,EAAE,4BAA4B;IAClE,YAAY,EAAE,4BAA4B;AAC9C;;AAEA;IACI,eAAe;IACf,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,cAAc;IACd,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,kBAAkB;IAClB,aAAa,EAAE,yCAAyC;IACxD,oCAAoC,EAAE,4BAA4B;IAClE,YAAY,EAAE,4BAA4B;AAC9C","sourcesContent":[".top {\n    position: fixed; /* Adicione esta linha para que a mensagem fique fixa */\n    top: 20px; /* Ajuste conforme necessário */\n    left: 50%;\n    transform: translateX(-50%);\n    max-width: 60%;\n    padding: 1em;\n    border: 1px solid #000;\n    margin: 1.2em auto 0;\n    text-align: center;\n    border-radius: 5px;\n    z-index: 9999; /* Adicione um z-index alto para sobrepor outros elementos */\n    background-color: rgba(0, 0, 0, 0.8); /* Exemplo de cor de fundo */\n    color: white; /* Exemplo de cor de texto */\n}\n\n.center {\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    max-width: 60%;\n    padding: 1em;\n    border: 1px solid #000;\n    text-align: center;\n    border-radius: 5px;\n    z-index: 9999; /* Adicione um z-index alto aqui também */\n    background-color: rgba(0, 0, 0, 0.8); /* Exemplo de cor de fundo */\n    color: white; /* Exemplo de cor de texto */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"top": `Message_top__PiidQ`,
	"center": `Message_center__uPRMP`
};
export default ___CSS_LOADER_EXPORT___;
