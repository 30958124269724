import { combineReducers } from "redux";
import selectedUUIDsReducer from "./selectedUUIDsReducer";
import userReducer from "./userReducer";

const rootReducer = combineReducers({
    selectedUUIDs: selectedUUIDsReducer,
    user: userReducer,
    // Outros reducers aqui
});

export default rootReducer;
