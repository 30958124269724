import { useState, useEffect } from "react";
import "react-tabs/style/react-tabs.css";
import "moment/locale/pt-br";

import { Stack, Button } from "@mui/material";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import SelectEmpresa from "../../form/SelectEmpresa";
import DataList from "../../form/DataList";
import Checkbox from "../../form/Checkbox";

import * as TabelaAPI from "../../../api/tabela";
import * as DataListAPI from "../../../api/dataList";

function Form({ handleSubmit, formData, btnText, caminhoVoltar, OptionsEmpresa }) {
    const [dados, setDados] = useState({});

    //set datalist
    const [OptionsPessoa, setOptionsPessoa] = useState([]);
    const [pessoa, setPessoa] = useState(formData?.Pessoa || {});

    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();
    const urlParams = new URLSearchParams(window.location.search);

    //obtem o array de empresas vinculadas ou coloca a empresaId no array
    const empresaId = localStorage.getItem("empresaId");
    const empresaIds = (() => {
        const ids = localStorage.getItem("empresaIds");
        if (ids) {
            try {
                const parsedIds = JSON.parse(ids);
                return Array.isArray(parsedIds) && parsedIds.length > 0 ? parsedIds : [empresaId];
            } catch (error) {
                console.warn("Erro ao analisar empresaIds, usando empresaId:", error);
                return [empresaId];
            }
        }
        return [empresaId];
    })();

    const ul = urlParams.get("ul");
    const li = urlParams.get("li");
    const tipo = urlParams.get("tipo");
    const operacaoUUID = urlParams.get("operacao");
    const uuid = urlParams.get("uuid");

    const params = new URLSearchParams({
        ul: ul,
        li: li,
        tipo: tipo,
        operacao: operacaoUUID,
        uuid: uuid,
    });
    //define o estado inicial do objeto
    if (!dados.ativo) {
        dados.ativo = true;
        dados.empresaId = empresaId || localStorage.getItem("empresaId");
    }

    //Atualiza o Datalist
    const consultaDatalist = async (empresaId, tabela, id, descricao, condicao, filtro) => {
        let result = await DataListAPI.dataList(empresaId, tabela, id, descricao, condicao, filtro);
        return result;
    };

    useEffect(() => {
        setDados(formData || {});
        setPessoa(formData?.Pessoa || {});
    }, [formData]);

    async function handleChange(e) {
        const { name, value, checked, type } = e.target;

        const camposBooleanos = ["ativo"];

        if (camposBooleanos.includes(name)) {
            setDados({ ...dados, [name]: checked });
        } else if (name.includes("Pessoa.")) {
            setPessoa({
                ...pessoa,
                [name.split(".")[1]]: value,
            });

            //consulta datalist
            const result = await consultaDatalist(dados.empresaId, "Pessoa", "id", "descricao", tipo, value);
            setOptionsPessoa(result);

            const selectedId = document.querySelector(`datalist option[value="${value}"]`)?.dataset.id;

            setDados((prevState) => ({
                ...prevState,
                pessoaId: selectedId ?? null,
            }));
        } else {
            setDados({ ...dados, [name]: value });
        }
    }

    async function handleOnBlur(e) {
        //verifica duplicidade
        if (e.target.name === "descricao" && btnText === "Cadastrar") {
            const duplicidade = await TabelaAPI.duplicidadeDescricao(
                document.getElementById("empresaId").value,
                "Anilox",
                e.target.value
            );

            if (duplicidade?.uuid) {
                setFlashMessage("Registro em duplicidade!", "warning");
                return navigate(`/Anilox/CreateOrEdit`, {
                    uuid: duplicidade.uuid,
                });
            }
        }
    }
    function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();

        handleSubmit(dados);
    };

    return (
        <form onSubmit={submit}>
            <h1>Usuário com permissão de gerenciar usuário</h1>
            <div>
                <SelectEmpresa
                    text="Selecione a Empresa"
                    name="empresaId"
                    options={OptionsEmpresa}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.empresaId || ""}
                    required
                    notOptNull={true}
                />
                <DataList
                    text="Selecione o usuário"
                    name="Pessoa.descricao"
                    inputWidth="600px"
                    filteredOptions={OptionsPessoa}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={pessoa.descricao}
                />
                <Checkbox
                    title="Ativa quando marcado"
                    text="Ativo"
                    name="ativo"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.ativo}
                    checked={dados.ativo === true ? "checked" : ""}
                />
            </div>
            <div>
                <Stack direction="row" spacing={1}>
                    <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                        Voltar
                    </Button>
                    <Button type="submit" variant="contained" color="success">
                        {btnText}
                    </Button>
                </Stack>
            </div>
        </form>
    );
}
export default Form;
