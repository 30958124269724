import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Context } from "../../../context/UserContext";
import Form from "./Form";
import styles from "../../form/Form.module.css";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";

import * as VerificadoresAPI from "../../../api/verificadores";
import CRUDsAPI from "../../../api/CRUDs";

const CreateOrEdit = () => {
    const [dados, setDados] = useState({});
    const [token] = useState(localStorage.getItem("token")) || "";
    const { parametro } = useContext(Context);
    const [ufs, setUfs] = useState([]);
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);

    const uuid = urlParams.get("uuid");
    let btnText = "";
    !uuid ? (btnText = "Cadastrar") : (btnText = "Atualizar");

    //carregar API's Externas
    const consultaUF = async () => {
        let uf = await VerificadoresAPI.uf("");
        setUfs(uf);
    };
    useEffect(() => {
        if (!token) {
            return navigate("/Auth", { replace: true });
        }

        consultaUF();

        if (uuid) {
            CRUDsAPI.municipio
                .consulta(uuid)
                .then((response) => {
                    setDados(response.data);
                })
                .catch((error) => {
                    console.error(error);
                    setFlashMessage(error.response.data.message, error.response.data.type);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, uuid]);

    async function managerDados(dados) {
        let urlNavigate = "";

        try {
            let response;

            const formData = new FormData();

            const municipioFormData = Object.keys(dados).forEach((key) => {
                if (key === "bandeira") {
                    for (let i = 0; i < dados[key]?.length; i++) {
                        formData.append(`bandeira`, dados[key][i]);
                    }
                } else {
                    formData.append(key, dados[key]);
                }
            });

            !municipioFormData && formData.append("bandeira", municipioFormData);

            if (uuid) {
                response = await CRUDsAPI.municipio.editarComFormData(formData);
            } else {
                response = await CRUDsAPI.municipio.cadastrarComFormData(formData);

                if (response.status === 202) {
                    setDados(response.data);
                    response.data.message = "Registro em duplicidade!";
                    response.data.type = "warning";
                    urlNavigate = `/Municipio/CreateOrEdit?uuid=${response.data.uuid}`;
                } else {
                    urlNavigate = "/Municipio";
                }
            }

            setFlashMessage(response.data.message, response.data.type);

            setTimeout(() => {
                if (response.data.type === "success") {
                    navigate("/Municipio");
                } else {
                    navigate(urlNavigate);
                }
            }, parametro.msgTempo * 1000);
        } catch (error) {
            console.error(error);
            setFlashMessage(error.response.data.message, error.response.data.type);
        }
    }

    return (
        <section className={`${styles.form_container} ${styles[parametro.tema]}`}>
            <Form
                token={token}
                handleSubmit={managerDados}
                formData={dados}
                btnText={btnText}
                ufs={ufs}
                caminhoVoltar={`/Municipio`}
            />
        </section>
    );
};
export default CreateOrEdit;
