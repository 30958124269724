import api from ".";

// export const operacao = async (uuid) => {
//     try {
//         const { data } = await api.get(`/operacao/editar/${uuid}`);
//         return data;
//     } catch (error) {
//         return error;
//     }
// };

export const buscaCotacaoByData = async (data) => {
    try {
        const response = await api.get("/buscaCotacaoByData", {
            params: { data },
        });

        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
};
