import api from "./";

export const retornaEmbalagem = async (embalagemId) => {
    try {
        const { data } = await api.get("/embalagem/retornaEmbalagemById", { params: { embalagemId } });
        return data;
    } catch (error) {
        return error;
    }
};

// produtoEmbalagens
/**
 * Recebe uma novaEmbalagem e faz Create, Update or Delete, na tabela de produtoEmbalagens
 * @param {*} novaEmbalagem
 * @returns
 */
export const managerProdutoEmbalagem = async (novaEmbalagem) => {
    try {
        const { data } = await api.post("/embalagem/managerProdutoEmbalagem", novaEmbalagem);
        return data;
    } catch (error) {
        return error;
    }
};

export const listarEmbalagens = (start, size, filters, filtersMode, globalFilter, sorting, produtoId) =>
    api.get("/embalagem/listarEmbalagens/", {
        params: { start, size, filters, filtersMode, globalFilter, sorting, produtoId },
    });
export const deletarEmbalagem = async (novaEmbalagem) => {
    try {
        const { data } = await api.post("/embalagem/deletarEmbalagem", novaEmbalagem);
        return data;
    } catch (error) {
        return error;
    }
};
export const duplicidadeCodigobarras = async (empresaId, codigoBarras) => {
    try {
        const { data } = await api.get("/embalagem/duplicidadeCodigoBarras/", {
            params: { empresaId, codigoBarras },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};
