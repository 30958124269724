import React, { useEffect, useMemo, useState, useContext, useCallback } from "react";
import { Context } from "../../../context/UserContext";

import { useNavigateParams } from "../../../hooks/useNavigateParams";
import MaterialReactTable from "material-react-table";
import { ThemeProvider } from "@mui/material";
/* tema customizado*/
import tableThemeCustom from "../../form/tableThemeCustom";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";

//Import Material React Table Translations
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";
import CustomDialog from "../../layout/CustomDialog";

import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";

import tratar from "../../../utils/retornaTratamentoURL";
import CRUDsAPI from "../../../api/CRUDs";

/* util */
import { obterPermissoesUsuario } from "../../../utils/funcoes";

const Index = () => {
    const [parametros, setParametros] = useState([]);
    const [token] = useState(localStorage.getItem("token")) || "";
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();

    const urlParams = new URLSearchParams(window.location.search);
    const ul = urlParams.get("ul") || 1;
    const li = urlParams.get("li") || 51;

    const { parametro } = useContext(Context);
    /* Obtem a paginação padrão */
    useEffect(() => {
        if (parametro && parametro.paginacao) {
            setPagination({
                pageIndex: 0,
                pageSize: parametro.paginacao,
            });
        }
    }, [parametro]);

    //dialog customizado
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});

    //data and fetching state
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    //tratar filtros se vier pela navbar
    if (localStorage.path.replace(/["]/g, "") !== window.location.pathname) {
        tratar.LimparFiltros();
        localStorage.setItem("path", JSON.stringify(window.location.pathname));
    }

    //table state
    const [columnFilterFns, setColumnFiltersMode] = useState(
        localStorage.columnFilterFns.length > 2 ? JSON.parse(localStorage.columnFilterFns) : []
    );

    const [columnFilters, setColumnFilters] = useState(
        localStorage.columnFilters.length > 2 ? JSON.parse(localStorage.columnFilters) : []
    );

    const [globalFilter, setGlobalFilter] = useState(
        localStorage.globalFilter.length > 2 ? JSON.parse(localStorage.globalFilter) : ""
    );

    const [sorting, setSorting] = useState(localStorage.sorting.length > 2 ? JSON.parse(localStorage.globalFilter) : []);

    const [pagination, setPagination] = useState({
        pageIndex: JSON.parse(localStorage.pagination).pageIndex || 0,
        pageSize: JSON.parse(localStorage.pagination).pageSize || 10,
    });
    /*
    const fetchData = async () => {
        if (!parametros.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }

        try {
            CRUDsAPI.parametro
                .listar(
                    pagination.pageIndex * pagination.pageSize,
                    pagination.pageSize,
                    JSON.stringify(columnFilters ?? []),
                    JSON.stringify(columnFilterFns ?? []),
                    globalFilter ?? "",
                    JSON.stringify(sorting ?? [])
                )
                .then((response) => {
                    setParametros(response.data.data);
                    setRowCount(response.data.meta.totalRowCount);
                });
        } catch (error) {
            setIsError(true);
            console.error(error);
            return;
        }
        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
    };
*/
    const fetchData = useCallback(async () => {
        if (!parametros.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }

        try {
            const response = await CRUDsAPI.operacaoParametro.listar(
                pagination.pageIndex * pagination.pageSize,
                pagination.pageSize,
                JSON.stringify(columnFilters ?? []),
                JSON.stringify(columnFilterFns ?? []),
                globalFilter ?? "",
                JSON.stringify(sorting ?? [])
            );

            setParametros(response.data.data);
            setRowCount(response.data.meta.totalRowCount);
        } catch (error) {
            setIsError(true);
            console.error(error);
            return;
        }
        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
    }, [parametros.length, pagination, columnFilters, columnFilterFns, globalFilter, sorting]);

    //funções do dialog
    const handleOpenDialog = (row) => {
        setOpenDialog(true);
        setSelectedRow(row);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmDialog = async (row) => {
        // Verifique se há uma linha selecionada
        let msgType = "success";
        if (selectedRow) {
            try {
                const response = await CRUDsAPI.operacaoParametro.deletar(selectedRow);

                setFlashMessage(response.data.message, msgType);

                // Após a exclusão, chame a função fetchData() novamente para atualizar as unidades
                fetchData();
            } catch (error) {
                msgType = "error";
                console.error(error);
                setFlashMessage(error.response.data.message, msgType);
            }
        }
        setOpenDialog(false);
    };

    useEffect(() => {
        if (!token) {
            navigate("/Auth", { replace: true });
        }
    }, [token, navigate]);

    useEffect(() => {
        //funcao para setar localStorage
        localStorage.setItem(
            "pagination",
            JSON.stringify({
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
            })
        );
        localStorage.setItem("columnFilterFns", JSON.stringify(columnFilterFns));
        localStorage.setItem("columnFilters", JSON.stringify(columnFilters));
        localStorage.setItem("globalFilter", JSON.stringify(globalFilter));
        localStorage.setItem("sorting", JSON.stringify(sorting));

        //permissões do usuario
        obterPermissoesUsuario(localStorage.getItem("pessoaId"), localStorage.getItem("empresaId"), ul, li);

        fetchData();
    }, [
        token,
        columnFilterFns,
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
        ul,
        li,
        fetchData,
    ]);

    const columns = useMemo(
        () => [
            {
                id: "ParametrosOperacao",
                header: `Paramêtros das Operações`,
                columns: [
                    {
                        accessorKey: "Operacao.descricao",
                        header: "Operação",
                    },
                    {
                        accessorKey: "Operacao.sigla",
                        header: "Sigla",
                    },
                ],
            },

            //column definitions...
        ],
        [] //getCommonEditTextFieldProps
    );

    /* modo tema */
    const theme = tableThemeCustom();

    return (
        <div>
            <div>
                {openDialog && (
                    <CustomDialog
                        title={`Delete parametro!`}
                        content="Você tem certeza?"
                        onClose={handleCloseDialog}
                        onConfirm={handleConfirmDialog}
                    />
                )}
            </div>
            <ThemeProvider theme={theme}>
                <MaterialReactTable
                    enableColumnFilterModes
                    enableGlobalFilterModes
                    enableColumnResizing
                    enableColumnOrdering
                    enableEditing
                    enableGrouping
                    enablePinning
                    enableRowActions
                    enableRowSelection
                    //enableRowNumbers
                    displayColumnDefOptions={{
                        "mrt-row-actions": {
                            muiTableHeadCellProps: {
                                align: "center",
                            },
                            size: 50,
                        },
                        "mrt-row-numbers": {
                            enableColumnOrdering: true, //turn on some features that are usually off
                            enableResizing: true,
                            muiTableHeadCellProps: {
                                sx: {
                                    fontSize: "1.2rem",
                                },
                            },
                        },
                        "mrt-row-select": {
                            enableColumnActions: true,
                            enableHiding: true,
                            size: 10,
                        },
                    }}
                    renderRowActions={({ row, table }) => (
                        // <Box sx={{ display: "flex", gap: "1rem" }}>
                        <Box
                            sx={{
                                display: "flex",
                                flexWrap: "nowrap",
                                gap: "1px",
                            }}
                        >
                            <Tooltip arrow placement="top-start" title="Editar">
                                <span>
                                    {" "}
                                    <IconButton
                                        onClick={() =>
                                            navigate("/ParametroOperacoes/CreateOrEdit", {
                                                uuid: row.id,
                                                ul: urlParams.get("ul"),
                                                li: urlParams.get("li"),
                                            })
                                        }
                                        disabled={JSON.parse(localStorage.getItem("editar")) === false}
                                    >
                                        <Edit />
                                    </IconButton>
                                </span>
                            </Tooltip>

                            <Tooltip arrow placement="top-start" title="Deletar">
                                <span>
                                    <IconButton
                                        color="error"
                                        onClick={() => handleOpenDialog(row)}
                                        disabled={
                                            JSON.parse(localStorage.isAdmin) === true &&
                                            JSON.parse(localStorage.getItem("cancelar")) === true
                                                ? false
                                                : true
                                        }
                                    >
                                        <Delete />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Box>
                    )}
                    renderTopToolbarCustomActions={({ table }) => {
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    gap: "8px",
                                }}
                            >
                                <Tooltip arrow placement="top-end" title={`Novo Registro`}>
                                    <span>
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            disabled={
                                                JSON.parse(localStorage.isAdmin) === true &&
                                                JSON.parse(localStorage.getItem("incluir")) === true
                                                    ? false
                                                    : true
                                            }
                                            onClick={() =>
                                                navigate("/ParametroOperacoes/CreateOrEdit", {
                                                    ul: urlParams.get("ul"),
                                                    li: urlParams.get("li"),
                                                })
                                            }
                                        >
                                            Novo
                                        </Button>
                                    </span>
                                </Tooltip>
                            </div>
                        );
                    }}
                    columns={columns}
                    data={parametros}
                    getRowId={(row) => row.uuid}
                    manualFiltering
                    manualPagination
                    manualSorting
                    muiToolbarAlertBannerProps={
                        isError
                            ? {
                                  color: "error",
                                  children: "Error loading data",
                              }
                            : undefined
                    }
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setColumnFiltersMode}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    rowCount={rowCount}
                    initialState={{
                        showColumnFilters: true,
                        density: "compact",
                        //columnPinning: { left: ["mrt-row-select"] },
                    }}
                    state={{
                        columnFilterFns,
                        columnFilters,
                        globalFilter,
                        isLoading,
                        pagination,
                        showAlertBanner: isError,
                        showProgressBars: isRefetching,
                        sorting,
                    }}
                    positionToolbarDropZone={"bottom"}
                    positionExpandColumn={"first"}
                    positionActionsColumn={"first"}
                    positionToolbarAlertBanner="bottom"
                    localization={MRT_Localization_PT_BR}
                />
            </ThemeProvider>
        </div>
    );
};
export default Index;
