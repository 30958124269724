import api from "./";

// Verifica se o banco existe na base de dados
export const verificacao = async (code, descricao, apenasIdDescricao) => {
    try {
        const { data } = await api.get("/serviceBANCO", { params: { code, descricao, apenasIdDescricao } });
        return data;
    } catch (error) {
        return error;
    }
};

export const consulta = (uuid) => api.get(`/banco/editar/${uuid}`);

export const editar = (banco) => api.post("/banco/editar", banco);

export const cadastrar = (banco) => api.post("/banco/cadastrar", banco);

export const listar = (start, size, filters, filtersMode, globalFilter, sorting) =>
    api.get("/banco/listar", { params: { start, size, filters, filtersMode, globalFilter, sorting } });

export const deletar = (selectedRow) => api.get("/banco/deletar", selectedRow);
