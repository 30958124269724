import bus from "../utils/bus";
/*types =>  "success" | "error" | "info" | "warning" | "secondary" */
export default function useFlashMessage() {
    function setFlashMessage(msgText, msgType, msgTempo, msgPosition) {
        bus.emit("flash", {
            msgText: msgText,
            msgType: msgType,
            msgTempo: msgTempo ? msgTempo : 3,
            msgPosition: msgPosition ? msgPosition : "center",
        });
    }

    return { setFlashMessage };
}
