import { useState, useEffect } from "react";
import "react-tabs/style/react-tabs.css";
import "moment/locale/pt-br";

import { Stack, Button } from "@mui/material";

/* hooks */
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import formStyles from "../../form/Form.module.css";
import Input from "../../form/Input";
import SelectEmpresa from "../../form/SelectEmpresa";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, OptionsEmpresa }) {
    const [dados, setDados] = useState({});
    const [preview, setPreview] = useState([]);

    const navigate = useNavigateParams();

    useEffect(() => {
        setDados(formData || {});
    }, [formData]);

    async function onFileChange(e) {
        setPreview(Array.from(e.target.files));
        setDados({ ...dados, assinatura: [...e.target.files] });
    }

    const submit = (e) => {
        e.preventDefault();

        handleSubmit(dados);
    };

    return (
        <form onSubmit={submit}>
            <h1>Assinatura</h1>
            <div className={formStyles.preview_bandeira}>
                <SelectEmpresa
                    text="Selecione a Empresa"
                    name="empresaId"
                    options={OptionsEmpresa}
                    value={dados.empresaId}
                    notOptNull={true}
                />
                <Input
                    text="Nome Principal"
                    type="text"
                    name="nomePrincipal"
                    placeholder=""
                    title="Nome da Pessoa"
                    inputWidth="350px"
                    value={dados.nomePrincipal}
                    readonly={true}
                />
            </div>
            <div className={formStyles.preview_bandeira}>
                <div>
                    <Input
                        text="Assinatura da Pessoa"
                        type="file"
                        name="assinatura"
                        handleOnChange={onFileChange}
                        multiple={false}
                        inputWidth="350px"
                    />
                </div>
                {preview.length > 0
                    ? preview.map((image, index) => (
                          <img
                              src={URL.createObjectURL(image)}
                              alt={dados.assinatura}
                              key={`${dados.assinatura}+${index}`}
                              style={{
                                  borderColor: "black",
                                  border: "solid 0.05em",
                              }}
                              width={180}
                              height={60}
                          />
                      ))
                    : dados.assinatura && (
                          <img
                              src={process.env.REACT_APP_API + "imagens/" + dados.assinatura}
                              alt={dados.uuid}
                              style={{
                                  borderColor: "black",
                                  border: "solid 0.05em",
                              }}
                              width={180}
                              height={60}
                          />
                      )}
            </div>
            <div>
                <Stack direction="row" spacing={1}>
                    <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                        Voltar
                    </Button>
                    <Button type="submit" variant="contained" color="success">
                        {btnText}
                    </Button>
                </Stack>
            </div>
        </form>
    );
}
export default Form;
