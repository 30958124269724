import api from ".";

export const buscaCotacaoByData = async (data) => {
    try {
        const response = await api.get("/buscaCotacaoByData", {
            params: { data },
        });

        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
};
