// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    padding: 0;
    margin: 0;
    font-family: Helvetica;
    box-sizing: border-box;
}

h1 {
    color: #16479d;
    margin-bottom: 1em;
    font-size: 2.2em;
}

.bold {
    font-weight: bold;
    color: #16479d;
}

/*
body {
    display: flex;
    margin: 3px;
    flex-wrap: wrap;
    flex: 1 1 100vw;
}

header,
nav {
    display: flex;
    background-color: #3b5998;
    margin: 3px;
}

#openMenu,
#closeMenu {
    border: none;
    background-color: none;
    font-size: 30px;
    font-weight: bold;
    padding: 0px 5px 0px 5px;
    cursor: pointer;
    display: none;
}

@media only screen and (max-width: 923px) {
    header {
        order: 0;
    }
    main {
        order: 1;
    }
    aside {
        order: 2;
        flex: 1 1 100vw;
    }
    footer {
        order: 3;
    }
}

@media only screen and (max-width: 717px) {
    #openMenu,
    #closeMenu {
        display: block;
    }

    #openMenu {
        margin-left: auto;
    }

    #closeMenu {
        position: fixed;
        right: 15px;
        top: 15px;
    }

    nav {
        position: fixed;
        flex-direction: column;
        width: 100vw;
        background-color: rgba(255, 255, 255, 0.9);
        align-items: center;
        justify-content: center;
        margin: 0;
        display: none;
        opacity: 0;
        transition: 0.3s;
    }
}
*/

`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,SAAS;IACT,sBAAsB;IACtB,sBAAsB;AAC1B;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,cAAc;AAClB;;AAEA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;CAuEC","sourcesContent":["* {\n    padding: 0;\n    margin: 0;\n    font-family: Helvetica;\n    box-sizing: border-box;\n}\n\nh1 {\n    color: #16479d;\n    margin-bottom: 1em;\n    font-size: 2.2em;\n}\n\n.bold {\n    font-weight: bold;\n    color: #16479d;\n}\n\n/*\nbody {\n    display: flex;\n    margin: 3px;\n    flex-wrap: wrap;\n    flex: 1 1 100vw;\n}\n\nheader,\nnav {\n    display: flex;\n    background-color: #3b5998;\n    margin: 3px;\n}\n\n#openMenu,\n#closeMenu {\n    border: none;\n    background-color: none;\n    font-size: 30px;\n    font-weight: bold;\n    padding: 0px 5px 0px 5px;\n    cursor: pointer;\n    display: none;\n}\n\n@media only screen and (max-width: 923px) {\n    header {\n        order: 0;\n    }\n    main {\n        order: 1;\n    }\n    aside {\n        order: 2;\n        flex: 1 1 100vw;\n    }\n    footer {\n        order: 3;\n    }\n}\n\n@media only screen and (max-width: 717px) {\n    #openMenu,\n    #closeMenu {\n        display: block;\n    }\n\n    #openMenu {\n        margin-left: auto;\n    }\n\n    #closeMenu {\n        position: fixed;\n        right: 15px;\n        top: 15px;\n    }\n\n    nav {\n        position: fixed;\n        flex-direction: column;\n        width: 100vw;\n        background-color: rgba(255, 255, 255, 0.9);\n        align-items: center;\n        justify-content: center;\n        margin: 0;\n        display: none;\n        opacity: 0;\n        transition: 0.3s;\n    }\n}\n*/\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
