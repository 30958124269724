import api from "./";

// Verifica se o cnpj existe
export const cnpj = async (consultaCNPJ) => {
    try {
        const { data } = await api.get("/serviceCNPJ", { params: { consultaCNPJ } });
        return data;
    } catch (error) {
        return error;
    }
};

export const cep = async (CEP) => {
    try {
        const { data } = await api.get("/serviceCEP", { params: { CEP } });
        return data;
    } catch (error) {
        return error;
    }
};

export const municipio = async (ufId, uf, descricao) => {
    try {
        const { data } = await api.get("/serviceMUNICIPIO", { params: { ufId, uf, descricao } });
        return data;
    } catch (error) {
        return error;
    }
};

export const uf = async (estado) => {
    try {
        const { data } = await api.get("/serviceUF", { params: { estado } });
        return data;
    } catch (error) {
        return error;
    }
};
