import { useState, useEffect } from "react";
import "react-tabs/style/react-tabs.css";
import "moment/locale/pt-br";

import { Stack, Button } from "@mui/material";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import * as TabelaAPI from "../../../api/tabela";

import Input from "../../form/Input";
import InputNumber from "../../form/InputNumber";
import Checkbox from "../../form/Checkbox";
import SelectEmpresa from "../../form/SelectEmpresa";
import Select from "../../form/Select";

/* utils */
import { formatarNumero, formatarNumeroDecimal } from "../../../utils/masks";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, OptionsEmpresa }) {
    const [dados, setDados] = useState({});
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();

    //define o estado inicial do objeto
    if (!dados.empresaId) {
        dados.empresaId = 1; //empresa padrão
        dados.ativo = true;
    }

    //Enums
    const [EnumTipo, setEnumTipo] = useState([]);

    const consultaEnum = async (table, field, filter) => {
        const result = await TabelaAPI.enums(table, field, filter);
        if (table === "Documento") {
            field === "tipo" && setEnumTipo(result ? result : []);
        }
    };

    useEffect(() => {
        setDados(formData || {});

        //carrega enum
        consultaEnum("Documento", "tipo", null);
    }, [formData]);

    function handleChange(e) {
        const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;

        setDados({ ...dados, [e.target.name]: value });
    }

    async function handleOnBlur(e) {
        //verifica duplicidade
        if (e.target.name === "descricao" && btnText === "Cadastrar") {
            const duplicidade = await TabelaAPI.duplicidadeDescricao(
                document.getElementById("empresaId").value,
                "Documento",
                e.target.value
            );

            if (duplicidade?.uuid) {
                setFlashMessage("Registro em duplicidade!", "warning");
                return navigate(`/Documento/CreateOrEdit`, {
                    uuid: duplicidade.uuid,
                });
            }
        }
    }
    function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();
        if (!dados.empresaId) {
            dados.empresaId = parseInt(document.getElementById("empresaId").value);
        }
        if (!dados.tipo) {
            const selectElement = document.getElementById("tipo");
            dados.tipo = selectElement.value;
        }

        handleSubmit(dados);
    };

    return (
        <form onSubmit={submit}>
            <h1>Documento</h1>
            <div>
                <SelectEmpresa
                    text="Selecione a Empresa"
                    name="empresaId"
                    inputWidth="250px"
                    options={OptionsEmpresa}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.empresaId || ""}
                    required
                    notOptNull={true}
                />
                <Input
                    text="Descrição"
                    type="text"
                    name="descricao"
                    title="Digite o nome do documento"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.descricao || ""}
                    required={true}
                />
                <Input
                    text="Caminho"
                    type="text"
                    name="caminho"
                    title="digite o caminho onde fica o documento"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.caminho || null}
                    required={true}
                />
                <Input
                    text="Função"
                    type="text"
                    name="funcao"
                    title="digite a função que o relatório vai executar"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.funcao || null}
                    required={true}
                />
                <Select
                    name="tipo"
                    title={"Define o tipo do documento"}
                    text="Tipo"
                    options={EnumTipo}
                    handleOnChange={handleChange}
                    value={dados.tipo}
                    isEnum={true}
                    notOptNull={true}
                />

                <Checkbox
                    title="Ativa quando marcado"
                    text="Ativo"
                    name="ativo"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.ativo}
                    checked={dados.ativo === true ? "checked" : ""}
                />
            </div>
            <div>
                <Stack direction="row" spacing={1}>
                    <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                        Voltar
                    </Button>
                    <Button type="submit" variant="contained" color="success">
                        {btnText}
                    </Button>
                </Stack>
            </div>
        </form>
    );
}
export default Form;
