// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_footer__AcWGP {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #cccccc;
    color: #444;
    border-top: 1px solid #666;
    border-bottom: 1px solid #666;
}
`, "",{"version":3,"sources":["webpack://./src/components/layout/Footer.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,yBAAyB;IACzB,WAAW;IACX,0BAA0B;IAC1B,6BAA6B;AACjC","sourcesContent":[".footer {\n    height: 50px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: #cccccc;\n    color: #444;\n    border-top: 1px solid #666;\n    border-bottom: 1px solid #666;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `Footer_footer__AcWGP`
};
export default ___CSS_LOADER_EXPORT___;
