import { useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { useNavigateParams } from "../../../hooks/useNavigateParams";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";

import * as CCL_API from "../../../api/centroCustoLimite";

const Duplicar = () => {
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();
    const location = useLocation();

    // Acesse os valores desejados do estado usando o hook useSelector
    const selectedUUIDs = useSelector((state) => state.selectedUUIDs);

    const managerDuplicar = useCallback(async () => {
        const urlParams = new URLSearchParams(location.search);
        try {
            const { data, status } = await CCL_API.Duplicar(selectedUUIDs);

            setFlashMessage(data.message, status === 200 ? "success" : "error");

            if (status === 200) {
                navigate("/centroCustoLimites", {
                    ul: urlParams.get("ul"),
                    li: urlParams.get("li"),
                    tipo: urlParams.get("tipo"),
                });
            }
        } catch (error) {
            // Trate o erro aqui se necessário
            setFlashMessage("Erro ao duplicar: " + error.message, "error");
        }
    }, [selectedUUIDs, location.search, navigate, setFlashMessage]); // Adicione as dependências necessárias

    useEffect(() => {
        managerDuplicar(selectedUUIDs);
    }, [managerDuplicar, selectedUUIDs]);
};
export default Duplicar;
