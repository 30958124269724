import React, { useEffect, useState, useContext, useCallback } from "react";

import { Context } from "../../../context/UserContext";
//import { useNavigateParams } from "../../../hooks/useNavigateParams";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import Form from "./Form";
import styles from "../../form/Form.module.css";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";

//utils
import * as DataListAPI from "../../../api/dataList";
import * as EmpresaAPI from "../../../api/empresa";
import CrudsAPI from "../../../api/CRUDs";
import { adicionaMesAdataAnoMes } from "../../../utils/financeiro";

const CreateOrEdit = () => {
    const [dados, setDados] = useState({});
    const [token] = useState(localStorage.getItem("token")) || "";
    const { parametro } = useContext(Context);
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams(); //useNavigate();
    const urlParams = new URLSearchParams(window.location.search);

    const pathname = "/CentroCustoLimites";
    const ul = urlParams.get("ul");
    const li = urlParams.get("li");
    const tipo = urlParams.get("tipo");
    let uuid = urlParams.get("uuid"); //const uuid = urlParams.get("uuid");
    const btnText = urlParams.get("btnText") === "Duplicar" ? "Duplicar" : uuid ? "Atualizar" : "Cadastrar";
    const operacaoUUID = urlParams.get("operacao");

    const params = new URLSearchParams({
        ul: ul,
        li: li,
        tipo: tipo,
        operacao: operacaoUUID,
        uuid: uuid,
        btnText: btnText,
    });

    if (!dados) {
        dados.empresaId = localStorage.getItem("empresaId");
        dados.ativo = true;
    }
    //enums e tabelas
    const [OptionsEmpresa, setEmpresa] = useState([]);
    const [OptionsCentroCustos, setCC] = useState([]);

    //carregar API's Externas
    const consultaEmpresa = useCallback(async () => {
        const result = await EmpresaAPI.empresa(localStorage.getItem("empresaId"));
        setEmpresa(result);
    }, []); // Adicione dependências aqui, se necessário

    const consultaCC = useCallback(async () => {
        const result = await DataListAPI.dataList(dados.empresaId, "centroCusto", "id", "descricao", tipo, "");
        setCC(result);
    }, [dados.empresaId, tipo]); // Adicione dependências aqui, se necessário

    useEffect(() => {
        if (!token) {
            navigate("/Auth", { replace: true });
        }
    }, [token, navigate]);

    useEffect(() => {
        consultaCC();
        consultaEmpresa();

        if (uuid && uuid !== "null") {
            //consulta a API
            CrudsAPI.centroCustoLimite
                .consulta(uuid)
                .then((response) => {
                    if (btnText === "Duplicar") {
                        const novoMes = adicionaMesAdataAnoMes(response.data.mes, 1);

                        const objeto = {
                            empresaId: response.data.empresaId,
                            centroCustoId: response.data.centroCustoId,
                            centroCusto: {
                                descricao: response.data.centroCusto.descricao,
                            },
                            mes: novoMes,
                            limite: response.data.limite,
                            ativo: true,
                        };

                        setDados(objeto);
                    } else {
                        setDados(response.data);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    setFlashMessage(error.response.data.message, error.response.data.type);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid, btnText]);

    async function managerObjeto(objeto) {
        let urlNavigate = pathname;
        uuid = btnText === "Duplicar" ? null : uuid;

        try {
            let response;

            if (uuid && uuid !== "null") {
                response = await CrudsAPI.centroCustoLimite.editar(objeto);
            } else {
                response = await CrudsAPI.centroCustoLimite.cadastrar(objeto);

                if (response.status === 202) {
                    setDados(response.data);
                    setCC(response.data.centroCusto.descricao);
                    response.data.message = "Registro em duplicidade!";
                    response.data.type = "warning";
                    urlNavigate = pathname + "/CreateOrEdit";
                    params.set("uuid", response.data.uuid);
                }
            }

            setFlashMessage(response.data.message, response.data.type);
            setTimeout(() => {
                navigate(urlNavigate, params);
            }, parametro.msgTempo * 1000);
        } catch (error) {
            console.error(error);
            setFlashMessage(error.response?.data?.message || "Erro desconhecido", "error");
        }
    }

    return (
        <section className={`${styles.form_container} ${styles[parametro.tema]}`}>
            <Form
                handleSubmit={managerObjeto}
                formData={dados}
                OptionsEmpresa={OptionsEmpresa}
                OptionsCentroCustos={OptionsCentroCustos}
                caminhoVoltar={pathname}
            />
        </section>
    );
};
export default CreateOrEdit;
