import React, { useEffect, useState, useContext } from "react";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import { Context } from "../../../context/UserContext";
import Form from "./Form";
import styles from "../../form/Form.module.css";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";

import * as EmpresaAPI from "../../../api/empresa";
import CrudsAPI from "../../../api/CRUDs";

const CreateOrEdit = () => {
    const [dados, setDados] = useState({});
    const [token] = useState(localStorage.getItem("token")) || "";
    const { parametro } = useContext(Context);
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();
    const urlParams = new URLSearchParams(window.location.search);

    const pathname = "/Equipes";
    const ul = urlParams.get("ul");
    const li = urlParams.get("li");
    const tipo = urlParams.get("tipo");
    const operacaoUUID = urlParams.get("operacao");
    const uuid = urlParams.get("uuid");
    const btnText = urlParams.get("btnText");

    const params = new URLSearchParams({
        ul: ul,
        li: li,
        tipo: tipo,
        operacao: operacaoUUID,
        uuid: uuid,
        btnText: btnText,
    });

    //enums e tabelas
    const [OptionsEmpresa, setEmpresa] = useState([]);

    //carregar API's Externas
    const consultaEmpresa = async () => {
        let dados = await EmpresaAPI.empresa(localStorage.getItem("empresaId"));
        setEmpresa(dados);
    };

    useEffect(() => {
        if (!token) {
            navigate("/Auth", { replace: true });
        }
    }, [token, navigate]);

    useEffect(() => {
        consultaEmpresa();
        if (uuid && uuid !== "null") {
            CrudsAPI.equipe
                .consulta(uuid)
                .then((response) => {
                    setDados(response.data);
                })
                .catch((error) => {
                    console.error(error);
                    setFlashMessage(error.response.data.message, error.response.data.type);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid]);

    async function managerDados(dados) {
        let urlNavigate = pathname;
        try {
            let response;

            if (uuid && uuid !== "null") {
                response = await CrudsAPI.equipe.editar(dados);
            } else {
                response = await CrudsAPI.equipe.cadastrar(dados);

                if (response.status === 202) {
                    setDados(response.data);
                    response.data.message = "Registro em duplicidade!";
                    response.data.type = "warning";
                    urlNavigate = pathname + "/CreateOrEdit";
                    params.set("uuid", response.data.uuid);
                }
            }

            setFlashMessage(response.data.message, response.data.type);

            setTimeout(() => {
                navigate(urlNavigate, params);
            }, parametro.msgTempo * 1000);
        } catch (error) {
            console.error(error);
            setFlashMessage(error.response.data.message, error.response.data.type);
        }
    }

    return (
        <section className={`${styles.form_container} ${styles[parametro.tema]}`}>
            <Form
                token={token}
                handleSubmit={managerDados}
                formData={dados}
                btnText={btnText}
                OptionsEmpresa={OptionsEmpresa}
                caminhoVoltar={pathname}
            />
        </section>
    );
};
export default CreateOrEdit;
