import { useState, useEffect } from "react";
import "react-tabs/style/react-tabs.css";
import "moment/locale/pt-br";

import { Stack, Button } from "@mui/material";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";
import * as TabelaAPI from "../../../api/tabela";

import Input from "../../form/Input";
import InputNumber from "../../form/InputNumber";
import Checkbox from "../../form/Checkbox";
import SelectEmpresa from "../../form/SelectEmpresa";

/* utils */
import { formatarNumero, formatarNumeroDecimal } from "../../../utils/masks";

// import * as FinanceiroAPI from "../../../api/financeiro";

function Form({ handleSubmit, formData, btnText, caminhoVoltar, OptionsEmpresa }) {
    const [unidade, setUnidade] = useState(formData || {});
    const navigate = useNavigateParams();
    const { setFlashMessage } = useFlashMessage();
    //const [preview, setPreview] = useState([]);
    useEffect(() => {
        setUnidade(formData || {});
    }, [formData]);

    function handleChange(e) {
        if (
            e.target.name === "ativo" ||
            e.target.name === "entrada" ||
            e.target.name === "saida" ||
            e.target.name === "pesavel" ||
            e.target.name === "industria"
        ) {
            setUnidade({ ...unidade, [e.target.name]: e.target.checked });
        } else if (e.target.name === "tipo") {
            setUnidade({
                ...unidade,
                [e.target.name]: e.target.options[e.target.selectedIndex].text,
            });
        } else if (e.target.name === "fator") {
            const valorFormatado = formatarNumeroDecimal(e.target.value);
            setUnidade({
                ...unidade,
                [e.target.name]: valorFormatado,
            });
        } else {
            setUnidade({ ...unidade, [e.target.name]: e.target.value });
        }

        //
    }

    async function handleOnBlur(e) {
        //verifica duplicidade
        if (e.target.name === "descricao" && btnText === "Cadastrar") {
            const duplicidade = await TabelaAPI.duplicidadeDescricao(
                document.getElementById("empresaId").value,
                "Unidades",
                e.target.value
            );

            if (duplicidade?.uuid) {
                setFlashMessage("Registro em duplicidade!", "warning");
                return navigate(`/Unidades/CreateOrEdit`, {
                    uuid: duplicidade.uuid,
                });
            }
        }
    }
    function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();
        if (!unidade.empresaId) {
            unidade.empresaId = parseInt(localStorage.getItem("empresaId"));
        }

        handleSubmit(unidade);
    };

    return (
        <form onSubmit={submit}>
            <h1>Unidades de medidas</h1>
            <div>
                <SelectEmpresa
                    text="Selecione a Empresa"
                    name="empresaId"
                    options={OptionsEmpresa}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={unidade.empresaId || ""}
                    notOptNull={true}
                    required
                />
                <Input
                    text="Descricao"
                    type="text"
                    name="descricao"
                    placeholder="nome do unidade"
                    title="Digite o nome do unidade de medida"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={unidade.descricao || ""}
                    required
                />
                <Input
                    text="Código"
                    type="text"
                    name="codigo"
                    placeholder="código da unidade"
                    title="Digite o código da unidade"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={unidade.codigo || ""}
                />
                {/* <Input
                    text="Fator"
                    type="number"
                    name="fator"
                    placeholder="Fator"
                    inputWidth="100px"
                    title="informe o fator de multiplicação da uidade"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={unidade.fator || ""}
                /> */}
                <InputNumber
                    text="Fator"
                    type="text"
                    name="fator"
                    placeholder="Fator"
                    inputWidth="100px"
                    title="digite o fator da unidade"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={unidade.fator ? formatarNumero(unidade.fator) : ""}
                />
            </div>
            <div>
                <Input
                    text="Título"
                    type="text"
                    name="titulo"
                    placeholder="titulos"
                    inputWidth="500px"
                    title="O título facilita em alguns processos, pois vai explicar ao usuário o funcionamento da rotina"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={unidade.titulo || ""}
                    min={1}
                />
            </div>
            <div>
                <Checkbox
                    title="Informa que precisa ser pesado no movimento"
                    text="Pesável"
                    name="pesavel"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={unidade.pesavel}
                    checked={unidade.pesavel === true ? "checked" : ""}
                />
                <Checkbox
                    title="Informa que a uidade será utilizada nos movimentos de entrada"
                    text="Entrada"
                    name="entrada"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={unidade.entrada}
                    checked={unidade.entrada === true ? "checked" : ""}
                />
                <Checkbox
                    title="Informa que a unidade será utilizada nos movimentos de saída"
                    text="Saída"
                    name="saida"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={unidade.saida}
                    checked={unidade.saida === true ? "checked" : ""}
                />
                <Checkbox
                    title="Informa que a unidade será utilizada nos processos de industrialização"
                    text="Indústria"
                    name="industria"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={unidade.industria}
                    checked={unidade.industria === true ? "checked" : ""}
                />
                <Checkbox
                    title="Quando marcado a forma de pagamento ou recebimento esta ATIVO"
                    text="Ativo"
                    name="ativo"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={unidade.ativo}
                    checked={unidade.ativo === true ? "checked" : ""}
                />
            </div>
            <div>
                <Stack direction="row" spacing={1}>
                    <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                        Voltar
                    </Button>
                    <Button type="submit" variant="contained" color="success">
                        {btnText}
                    </Button>
                </Stack>
            </div>
        </form>
    );
}
export default Form;
