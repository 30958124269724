import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "react-tabs/style/react-tabs.css";

import { Stack, Button, Tooltip } from "@mui/material";

/* hooks */
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import Input from "../../form/Input";

function FormEditarNF({ token, handleSubmit, formData, caminhoVoltar }) {
    const [dados, setDados] = useState(formData || []);

    const navigate = useNavigateParams();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    const [novoDados, setNovoDados] = useState({
        empresaId: localStorage.getItem("empresaId"),
        ativo: true,
    });

    useEffect(() => {
        const fetchData = async () => {
            setDados(formData);
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    async function handleChange(e) {
        const { name, value, checked, type } = e.target;

        setNovoDados((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    }

    const submit = (e) => {
        e.preventDefault();

        handleSubmit(dados, novoDados);
    };

    return (
        <form onSubmit={submit}>
            <h1>Inserir nome do arquivo no PDF nos itens do inventário</h1>
            <div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "1rem",
                    }}
                >
                    <Input
                        text="Nome do arquivo"
                        type="text"
                        name="descricao"
                        placeholder="PDF"
                        inputWidth="250px"
                        title="Cole o nome do arquivo do pdf que será reaproveitado para os itens do inventário"
                        handleOnChange={handleChange}
                        value={dados.descricao}
                        required={true}
                    />
                </div>
            </div>
            <div>
                <Stack direction="row" spacing={1}>
                    <Tooltip arrow placement="top-start" title="Voltar">
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() =>
                                navigate(caminhoVoltar, {
                                    uuid: urlParams.get("uuid"),
                                    ul: urlParams.get("ul"),
                                    li: urlParams.get("li"),
                                    tipo: urlParams.get("tipo"),
                                })
                            }
                        >
                            Voltar
                        </Button>
                    </Tooltip>
                    <Tooltip arrow placement="top-start" title="Confirma a nota fiscal para os itens selecionados">
                        <Button type="submit" variant="contained" color="success">
                            {"SALVAR"}
                        </Button>
                    </Tooltip>
                </Stack>
            </div>
        </form>
    );
}
export default FormEditarNF;
