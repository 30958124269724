import { useState, useEffect, useContext, useMemo } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

/* contexts */
import { Context } from "../../../context/UserContext";

import moment from "moment";
import "moment/locale/pt-br";

import { Stack, Button, Box, Tooltip, IconButton } from "@mui/material";
import { Delete, Edit, Print } from "@mui/icons-material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

import { ThemeProvider } from "@mui/material";
/* tema customizado*/
import tableThemeCustom from "../../form/tableThemeCustom";

//Import Material React Table Translations
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import Input from "../../form/Input";
import InputNumber from "../../form/InputNumber";
import DataList from "../../form/DataList";
import Select from "../../form/Select";
import Checkbox from "../../form/Checkbox";
import TextArea from "../../form/TextArea";

import formStyles from "../../form/Form.module.css";

/* utils */
import { formatarNumero, formatarNumeroDecimal, limitarTexto, maskDecimal } from "../../../utils/masks";
import { retornaDataHora, retornaData } from "../../../utils/retornaDataFormatada";

import * as DataListAPI from "../../../api/dataList";
import * as EmpresaAPI from "../../../api/empresa";
import * as FinanceiroAPI from "../../../api/financeiro";
import * as OperacaoAPI from "../../../api/operacao";
import * as PessoaAPI from "../../../api/pessoa";
import * as TabelaAPI from "../../../api/tabela";
import * as MovimentoAPI from "../../../api/movimento";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar }) {
    const [dados, setDados] = useState(formData || {});
    const [itens, setItens] = useState([]); //itens da grid
    const [itemLancamentos, setItemLancamentos] = useState([]);
    const [itemAditivos, setItemAditivos] = useState([]);

    const [operacao, setOperacao] = useState({});
    const [parametroPessoa, setParametroPessoa] = useState({});

    const [lancamentos, setLancamentos] = useState([]);

    /* dados para o movimento */
    const [Produto, setProduto] = useState({});
    //const [Embalagem, setEmbalagem] = useState({});
    const [OptionsProdutos, setOptionsProdutos] = useState([]);
    const [OptionsEmbalagem, setOptionsEmbalagem] = useState([]);

    /* button enable ou disabled */
    const [isDisabled, setIsDisabled] = useState(false);

    /* Todas as operações*/
    const [novoProduto, setNovoProduto] = useState({
        uuid: "",

        movimentoId: dados.id,
        empresaId: dados.empresaId,
        tabelaPrecoId: "",
        produtoId: "",
        embalagemId: "",

        Empresa: {},
        Produto: {},
        Embalagem: {},
        movimentoItems: {},

        custo: "",
        custoCalculado: "",
        quantidade: "",
        valorUnitario: "",
        descontoPercentual: "",
        descontoReal: "",
        valorTotal: "",

        ativo: true,
        funcao: "adicionar",
    });

    /* OPERACAO LIM */
    const [novoAditivo, setNovoAditivo] = useState({
        uuid: "",

        movimentoId: dados.id,

        tipo: "", //define o tipo de termo
        data: "", //define a data inicial do termo aditivo
        valorAnterior: null,
        percentual: 0, //define o percentual de reajuste
        valorReajustado: 0,
        quantidade: 0,
        observacao: "",

        ativo: true,
        funcao: "adicionar",
    });

    const consultaEmbalagemByProdutoId = async (empresaId, produtoId) => {
        const result = await TabelaAPI.embalagemByProdutoId(empresaId, produtoId);

        if (result && result.request && result.request.status === 404) {
            setFlashMessage(result.response.data.message, "error");
        } else {
            setOptionsEmbalagem(result);
        }

        return result;
    };

    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();

    //obtem os parametros da empresa
    const { parametro } = useContext(Context);

    const urlParams = new URLSearchParams(window.location.search);
    const ul = urlParams.get("ul");
    const li = urlParams.get("li");
    const tipo = urlParams.get("tipo");
    const operacaoUUID = urlParams.get("operacao");

    const uuid = urlParams.get("uuid");

    const params = new URLSearchParams({
        ul: ul,
        li: li,
        tipo: tipo,
        operacao: operacaoUUID,
        uuid: uuid,
    });
    let colaborador = "";
    let parceiroTipo = "Cliente";
    let lancamentoTipo = "Receber";
    let lancamentoSituacaoId = "1"; //representa o id da situaçao para tabela de lançamento

    let textoHeaderGrid = "Movimento";

    //para lancamento
    let diasEntreParcelas = 0;
    let lancamentoComissaoTipo = "Pagar";

    if (operacao.saida === true) {
        colaborador = "Vendedor";
        if (!dados.centroCustoId) {
            dados.centroCustoId = parametro.centroCustoPDV;
        }
    } else if (operacao.entrada === true) {
        colaborador = "Comprador";
        parceiroTipo = "Fornecedor";
        lancamentoTipo = "Pagar";
        if (!dados.centroCustoId) {
            dados.centroCustoId = parametro.centroCustoPDC;
        }
    } else if (operacao.servico === true) {
        colaborador = "Corretor";
        parceiroTipo = "Locatário";
        lancamentoTipo = "Receber";
        textoHeaderGrid = "Movimento de Locação de Imóveis";

        if (!dados.centroCustoId) {
            dados.centroCustoId = parametro.centroCustoLIM;
        }
        if (!dados.entrada) {
            dados.entrada = moment().startOf("day").format("YYYY-MM-DD 00:00");
        }
        if (!dados.saida) {
            dados.saida = moment().startOf("day").format("YYYY-MM-DD 00:00");
        }
    }

    //para produto vazio preenche valores pré definidos
    if (!dados.empresaId) {
        dados.empresaId = localStorage.getItem("empresaId");
        dados.ativo = true;

        dados.createdAt = moment().format("YYYY-MM-DD HH:mm:ss");
        dados.updatedAt = moment().format("YYYY-MM-DD HH:mm:ss");
        dados.data = moment().format("YYYY-MM-DD 00:00:00");
    }

    // Funções
    async function retornaOperacao(uuid) {
        try {
            const resultado = await OperacaoAPI.operacao(uuid);

            setOperacao(resultado);
            setDados((prev) => ({ ...prev, operacaoId: resultado.id }));
            return resultado;
        } catch (erro) {
            // Lide com o erro
            console.error(erro);
        }
    }
    async function retornaParametroPessoa() {
        try {
            const resultado = await PessoaAPI.parametroPessoa(localStorage.getItem("pessoaId"));
            if (!resultado) {
                return;
            }
            setParametroPessoa(resultado);
            setDados((prev) => ({
                ...prev,
                colaboradorId: resultado.id,
            }));
            setColaborador({
                ...Colaborador,
                descricao: resultado.descricao,
            });
            return resultado;
        } catch (erro) {
            // Lide com o erro
            console.error(erro);
        }
    }
    async function retornaLancamentos(pessoaId, tipo) {
        try {
            const result = await FinanceiroAPI.lancamentosEmAberto(pessoaId, tipo);
            setLancamentos(result);
        } catch (erro) {
            console.error(erro);
        }
    }
    async function retornaParcelas(
        totalParcelas,
        primeiraDataVencimento,
        dadosLancamento,
        credito,
        fixaDiaVencimento,
        diasEntreParcelas,
        valorOriginal,
        valor,
        diaVencimento
    ) {
        try {
            const result = await FinanceiroAPI.calcularParcelas(
                totalParcelas,
                primeiraDataVencimento,
                dadosLancamento,
                credito,
                fixaDiaVencimento,
                diasEntreParcelas,
                valorOriginal,
                valor,
                diaVencimento
            );
            return result;
        } catch (erro) {
            console.error(erro);
        }
    }
    async function retornaTabelaByDescricao(empresaId, tabela, descricao) {
        try {
            const result = await TabelaAPI.TabelaByDescricao(empresaId, tabela, descricao);

            if (result && operacao?.sigla === "LIM") {
                lancamentoSituacaoId = result.id; // id da situação para a descrição Aberto
            }
        } catch (error) {
            console.error("Erro ao buscar a tabela por descrição:", error);
        }
    }

    //abas
    const [tabEnabled, setTabEnabled] = useState({
        cabeçalho: true,
        financeiro: true,
        itens: true,
        lancamento: true,
        observacao: true,
        aditivo: false,
    });

    //set datalist
    const [Colaborador, setColaborador] = useState(formData?.Colaborador);
    const [OptionsColaborador, setOptionsColaborador] = useState([]);

    const [Parceiro, setParceiro] = useState(formData?.Parceiro);
    const [OptionsParceiro, setOptionsParceiro] = useState([]);

    const [ParceiroVinculado, setParceiroVinculado] = useState(formData?.ParceiroVinculado);
    const [OptionsParceiroVinculado, setOptionsParceiroVinculado] = useState([]);

    const [Administrador, setAdministrador] = useState(formData?.Administrador);
    const [OptionsAdministrador, setOptionsAdministrador] = useState([]);

    //data and fetching state
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [rowCountFinanceiro, setRowCountFinanceiro] = useState(0);
    const [rowCountLancamento, setRowCountLancamento] = useState(0);
    const [rowCountAditivo, setRowCountAditivo] = useState(0);

    //table state
    const [columnFilterFns, setColumnFiltersMode] = useState(
        localStorage.columnFilterFns.length > 2 ? JSON.parse(localStorage.columnFilterFns) : []
    );
    const [columnFilters, setColumnFilters] = useState(
        localStorage.columnFilters.length > 2 ? JSON.parse(localStorage.columnFilters) : []
    );
    const [globalFilter, setGlobalFilter] = useState(
        localStorage.globalFilter.length > 2 ? JSON.parse(localStorage.globalFilter) : ""
    );
    const [sorting, setSorting] = useState(localStorage.sorting.length > 2 ? JSON.parse(localStorage.sorting) : []);
    const [pagination, setPagination] = useState({
        pageIndex: JSON.parse(localStorage.pagination).pageIndex || 0,
        pageSize: JSON.parse(localStorage.pagination).pageSize || 5,
    });

    const movimentoId = dados.id ? dados.id : null;

    //Atualiza o Datalist
    const consultaDatalist = async (empresaId, tabela, id, descricao, condicao, filtro, operacao, funcionarioId) => {
        const result = await DataListAPI.dataList(empresaId, tabela, id, descricao, condicao, filtro, operacao, funcionarioId);
        return result;
    };
    const consultaDatalistColaborador = async (empresaId, filtro, ativo, tipo) => {
        const result = await DataListAPI.dataListColaborador(empresaId, filtro, ativo, tipo);
        return result;
    };
    /*
    const consultaDatalistParceiro = async (empresaId, filtro, locador, locatario, fiador, funcionarioId, administrador) => {
        const result = await DataListAPI.dataListParceiroImovel(
            empresaId,
            filtro,
            locador,
            locatario,
            fiador,
            funcionarioId,
            administrador
        );
        return result;
    };
    */
    const consultaDatalistParceiroImoveis = async (
        empresaId,
        filtro,
        locador,
        locatario,
        fiador,
        funcionarioId,
        administrador
    ) => {
        const result = await DataListAPI.dataListParceiroImovel(
            empresaId,
            filtro,
            locador,
            locatario,
            fiador,
            funcionarioId,
            administrador
        );
        return result;
    };
    const consultaDatalistProduto = async (empresaId, tabelaPrecoId, filtro) => {
        const result = await DataListAPI.dataListTabelaPrecoItemProduto(empresaId, tabelaPrecoId, filtro);
        return result;
    };

    //Atualiza Selects
    const [OptionsSituacao, setOptionsSituacao] = useState([]);
    const [OptionsCC, setCC] = useState([]); //centro de custo
    const [OptionsForma, setForma] = useState([]);
    const [OptionsPrazo, setPrazo] = useState([]);
    const [OptionsConta, setConta] = useState([]);
    const [OptionsTabelaPreco, setTabelaPreco] = useState([]);

    //Enums
    const [enumCondominio, setEnumCondominio] = useState([]);
    const [enumDiaVencimento, setEnumDiaVencimento] = useState([]);
    const [enumLocacao, setEnumLocacao] = useState([]);
    const [enumIPTU, setEnumIPTU] = useState([]);
    const [enumContrato, setEnumContrato] = useState([]);
    const [enumEnergia, setEnumEnergia] = useState([]);
    const [enumAgua, setEnumAgua] = useState([]);

    const [enumTipoAditivo, setEnumTipoAditivo] = useState([]);

    const consultaEnum = async (table, field, filter) => {
        const result = await TabelaAPI.enums(table, field, filter);

        const movimentosFields = {
            condominio: setEnumCondominio,
            diaVencimento: setEnumDiaVencimento,
            locacao: setEnumLocacao,
            iptu: setEnumIPTU,
            contrato: setEnumContrato,
            energia: setEnumEnergia,
            agua: setEnumAgua,
        };

        const aditivosFields = {
            tipo: setEnumTipoAditivo,
        };

        if (table === "Movimentos" && movimentosFields[field]) {
            movimentosFields[field](result);
        } else if (table === "movimentoAditivos" && aditivosFields[field]) {
            aditivosFields[field](result);
        }
    };

    // Estado para rastrear a aba ativa
    const [abaAtiva, setAbaAtiva] = useState(0);
    const [gridAtiva, setGridAtiva] = useState("financeiro");

    /* identifica a aba atual */
    const handleSelect = (index) => {
        setAbaAtiva(index);

        // Definir qual grid será atualizada com base na aba ativa
        switch (index) {
            case 0:
                setGridAtiva("cabecalho");
                break;
            case 1:
                setGridAtiva("financeiro");
                break;
            case 2:
                setGridAtiva("movimento");
                break;
            case 3:
                setGridAtiva("lancamento");
                break;
            case 4:
                setGridAtiva("observacao");
                break;
            case 5:
                setGridAtiva("aditivo");
                break;
            default:
                setGridAtiva("financeiro");
        }
    };

    //Consultas no backend
    const consultaSituacao = async (empresaId, campo) => {
        try {
            const result = await FinanceiroAPI.situacao(empresaId, campo);

            setOptionsSituacao(result);

            // Mapeamento de operacao.sigla para descrições de situação
            const operacaoSituacoes = {
                LIM: "Orçamento",
                // Adicione aqui outras siglas e suas descrições correspondentes
                PDC: "Orçamento",
            };

            if (!dados.situacaoId && operacao.sigla in operacaoSituacoes) {
                const descricaoSituacao = operacaoSituacoes[operacao.sigla];

                // Encontra a situação correspondente à descrição mapeada
                const situacaoEncontrada = result.find((item) => item.descricao === descricaoSituacao);

                if (situacaoEncontrada) {
                    dados.situacaoId = situacaoEncontrada.id; // Define o id da situação correspondente
                } else {
                    console.warn(`Situação com descrição "${descricaoSituacao}" não encontrada.`);
                }
            }

            return result;
        } catch (error) {
            console.error("Erro ao consultar situação:", error);
        }
    };

    const consultaCC = async () => {
        const result = await EmpresaAPI.centroCustos(dados.empresaId, "", tipo === "Entrada" ? "Pagar" : "Receber");
        setCC(result);
        return result;
    };
    const consultaForma = async (tipo, credito, empresaId, descricao, dependente, nivel, formaId) => {
        const result = await FinanceiroAPI.forma(tipo, credito, empresaId, descricao, dependente, nivel, formaId);
        setForma(result);
        return result;
    };
    const consultaPrazo = async (tipo, formaId, nivel, prazoId, empresaId = dados.empresaId, prazoMedio) => {
        const result = await FinanceiroAPI.prazo(tipo, formaId, nivel, prazoId, empresaId, prazoMedio);

        setPrazo(result);
        return result;
    };
    const consultaConta = async () => {
        const result = await TabelaAPI.tabela(dados.empresaId, "Conta", "descricao");
        setConta(result);
    };
    const consultaTabelaPreco = async (empresaId, pessoaId, operacaoId, entrada, liberada, locacao, saida, servico) => {
        const result = await TabelaAPI.precoByPessoaId(
            empresaId,
            pessoaId,
            operacaoId,
            entrada,
            liberada,
            locacao,
            saida,
            servico
        );
        return result;
    };
    const consultaParceiro = async (empresaId = dados.empresaId, pessoaId) => {
        const result = await PessoaAPI.financeiro(empresaId, pessoaId);
        return result;
    };

    const atualizaDadosPorParceiro = async (parceiroId) => {
        const resultado = await consultaParceiro(parceiroId);

        await consultaForma(tipo === "Entrada" ? "Pagar" : "Receber");
        await consultaPrazo(
            tipo === "Entrada" ? "Pagar" : "Receber",
            tipo === "Entrada" ? resultado.formaFornecedorId : resultado.formaClienteId,
            tipo === "Entrada" ? resultado.prazoFornecedor?.nivelVenda : resultado.prazoCliente?.nivelVenda,
            "",
            dados.empresaId,
            tipo === "Entrada" ? resultado.prazoFornecedor?.prazoMedio : resultado.prazoCliente?.prazoMedio
        );

        //ao escolher o parceiro atualiza as tabelas de preços liberadas
        const result = await consultaTabelaPreco(dados.empresaId, parceiroId);
        setTabelaPreco(result);

        retornaLancamentos(parceiroId, lancamentoTipo);

        setDados((prevState) => ({
            ...prevState,
            parceiroId: parceiroId,
            formaId: tipo === "Entrada" ? resultado.formaFornecedorId : resultado.formaClienteId,
            prazoId: tipo === "Entrada" ? resultado.prazoFornecedorId : resultado.prazoClienteId,
            contaId: tipo === "Entrada" ? resultado.contaFornecedorId : resultado.contaClienteId,
        }));
    };
    const atualizaTabelasVinculadasParceiro = async (parceiroId) => {
        const resultado = await PessoaAPI.pessoaById(parceiroId);

        const nivel = tipo === "Entrada" ? resultado?.formaFornecedor.nivelVenda : resultado?.formaCliente.nivelVenda;
        await consultaForma(null, null, dados.empresaId, null, false, nivel, null);
        await consultaPrazo(
            tipo === "Entrada" ? "Pagar" : "Receber",
            tipo === "Entrada" ? resultado.formaFornecedorId : resultado.formaClienteId,
            tipo === "Entrada" ? resultado.prazoFornecedor?.nivelVenda : resultado.prazoCliente?.nivelVenda,
            "",
            dados.empresaId,
            tipo === "Entrada" ? resultado.prazoFornecedor?.prazoMedio : resultado.prazoCliente?.prazoMedio
        );

        //ao escolher o parceiro atualiza as tabelas de preços disponíveis
        const result = await consultaTabelaPreco(dados.empresaId, parceiroId);
        setTabelaPreco(result);

        retornaLancamentos(parceiroId, lancamentoTipo);
    };

    //Condições de Venda do produto no Movimento
    const consultaCondicaoVenda = async (empresaId, tabelaPrecoId, produtoId, pessoaId) => {
        try {
            const result = await MovimentoAPI.condicaoVenda(empresaId, tabelaPrecoId, produtoId, pessoaId);

            if (result && result.length > 0 && result[0].tabelaPrecoItems && result[0].tabelaPrecoItems.length > 0) {
                const { preco } = result[0].tabelaPrecoItems[0]; // Desestruturação para obter o preço

                const novoProduto = {
                    produtoId: produtoId,
                    valorUnitario: preco, // Atualiza o valor com o preço retornado
                };

                /* Locação de Imóvel - LIM*/
                if (operacao.sigla === "LIM") {
                    const res = await FinanceiroAPI.buscaPrazoById(dados.prazoId);
                    const parcela = res.parcela;
                    diasEntreParcelas = res.parcela;
                    novoProduto.quantidade = parcela;
                    novoProduto.valorTotal = (parcela * preco).toFixed(2);
                }

                setNovoProduto((prev) => ({
                    ...prev,
                    ...novoProduto,
                }));
            } else {
                console.warn("Dados inesperados ou incompletos recebidos:", result);
            }

            return result;
        } catch (error) {
            console.error("Erro ao consultar a condição de venda:", error);
            // Dependendo da lógica do seu sistema, você pode querer retornar um valor padrão ou tomar uma ação específica em caso de erro.
            return null;
        }
    };

    //atualiza a quantidade para locação de imóvel
    useEffect(() => {
        const fetchPrazoLIM = async () => {
            try {
                const res = await FinanceiroAPI.buscaPrazoById(dados.prazoId);

                const parcela = res.parcela;
                novoProduto.quantidade = parcela;
                const valorTotal = (parcela * novoProduto.valorUnitario).toFixed(2);

                const dataSaida = dados.dataSaida ? moment(dados.dataSaida).format("YYYY-MM-DD HH:mm:ss") : null;
                const entrada =
                    dados.locacao === "Comercial" || dados.locacao === "Residencial"
                        ? moment(dados.entrada).startOf("day").format("YYYY-MM-DD 00:00:00")
                        : moment(dados.entrada).format("YYYY-MM-DD HH:mm:ss");
                const saida =
                    dados.locacao === "Comercial" || dados.locacao === "Residencial"
                        ? moment(dados.entrada).add(parcela, "months").startOf("day").format("YYYY-MM-DD 00:00:00")
                        : moment(dados.saida).format("YYYY-MM-DD HH:mm:ss");

                setDados((prev) => ({
                    ...prev,
                    dataSaida: dataSaida,
                    entrada: entrada,
                    saida: saida,
                }));

                setNovoProduto((prev) => ({
                    ...prev,
                    quantidade: parcela,
                    valorTotal: valorTotal,
                }));

                // Faça algo com o resultado 'res'
            } catch (error) {
                console.error("Erro ao buscar prazo:", error);
            }
        };

        operacao.sigla === "LIM" && fetchPrazoLIM();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dados.prazoId]);

    useEffect(() => {
        // Atualizar tabelas vinculadas ao parceiro
        // prazo, forma, etc
        dados.parceiroId && atualizaTabelasVinculadasParceiro(dados.parceiroId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dados.parceiroId]);

    useEffect(() => {
        if (itemLancamentos.length > 0) {
            setDados((prev) => ({
                ...prev, // Mantém as outras propriedades do objeto
                Lancamentos: itemLancamentos, // Atualiza os lançamentos
            }));
        }
    }, [itemLancamentos]); // Executa o efeito quando `itemLancamentos` é atualizado

    useEffect(() => {
        let valor = "Locatário";
        if (dados.solar === true) {
            valor = "Locador";

            setDados((prev) => ({
                ...prev,
                energia: valor,
            }));
        }
    }, [dados.solar]); // Executa o efeito quando `itemLancamentos` é atualizado

    useEffect(() => {
        if (novoAditivo.tipo && novoAditivo.funcao === "adicionar") {
            // Verifica cada item em itemAditivos
            itemAditivos.forEach((item) => {
                // Comparar as datas e o tipo
                if (novoAditivo.data <= item.data && novoAditivo.tipo === item.tipo) {
                    // Adicione a lógica que você deseja aqui
                    setFlashMessage(
                        `Último Termo Aditivo de ${novoAditivo.tipo} foi ${moment(item.data).format(
                            "DD/MM/YYYY"
                        )}, a data tem que ser superior ao último reajuste!`,
                        "warning"
                    );
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [novoAditivo.data]);

    //Tratamento por situacao
    useEffect(() => {
        if (operacao.sigla === "LIM") {
            if (dados.situacaoId === "2" || dados.situacaoId === "9") {
                setIsDisabled(false);
                setDados((prev) => ({
                    ...prev,
                    assinado: false,
                    verificarSituacao: dados.situacaoId, // Adiciona o array ao estado
                }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dados.situacaoId]);

    useEffect(() => {
        //modifica o campo para a select de situacao
        if (operacao.sigla === "PDC") {
            retornaTabelaByDescricao(parametro.tabelasComuns === true ? 1 : dados.empresaId, "Situacao", "Aberto");
            consultaSituacao(parametro.tabelasComuns === true ? 1 : dados.empresaId, "movimento");

            if (!dados.id) {
                dados.situacaoId = 3;
                dados.provisao = true;
            }

            setTabEnabled((prev) => ({ ...prev, aditivo: true }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operacao]);

    useEffect(() => {
        setDados(formData || {});
        setParceiro(formData?.Parceiro || {});
        setParceiroVinculado(formData?.ParceiroVinculado || {});
        setAdministrador(formData?.Administrador || {});
        setItens(formData?.movimentoItems || []);

        consultaCC();
        retornaOperacao(operacaoUUID);
        retornaParametroPessoa();
        consultaConta();
        /* ENUM */
        consultaEnum("Movimentos", "condominio", null);
        consultaEnum("Movimentos", "diaVencimento", null);
        consultaEnum("Movimentos", "locacao", null);
        consultaEnum("Movimentos", "iptu", null);
        consultaEnum("Movimentos", "contrato", null);
        consultaEnum("Movimentos", "energia", null);
        consultaEnum("Movimentos", "agua", null);

        consultaEnum("movimentoAditivos", "tipo", null);

        //conta os registros e faz a paginação
        setRowCount(formData?.movimentoItems?.length);
        setRowCountLancamento(formData?.Lancamentos?.length);
        setRowCountFinanceiro(formData?.Lancamentos?.length);
        setRowCountAditivo(formData?.movimentoAdivitos?.length);

        //bloquea as alterações
        if (formData.assinado) {
            setIsDisabled(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    useEffect(() => {
        if (gridAtiva) {
            // Chame aqui o fetchData() com a gridAtiva
            fetchData(gridAtiva);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        columnFilterFns,
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
        movimentoId,
        dados.parceiroId,
        gridAtiva,
    ]);

    const fetchData = async (grid) => {
        if (!itens.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }
        if (dados.id) {
            if (grid === "financeiro") {
                try {
                    MovimentoAPI.listarFinanceiro(
                        pagination.pageIndex * pagination.pageSize,
                        pagination.pageSize,
                        JSON.stringify(columnFilters ?? []),
                        JSON.stringify(columnFilterFns ?? []),
                        globalFilter ?? "",
                        JSON.stringify(sorting ?? []),
                        dados.parceiroId ?? "",
                        lancamentoSituacaoId ?? 1
                    ).then((response) => {
                        setLancamentos(response.data.data);
                        setRowCountFinanceiro(response.data.meta.totalRowCount);
                    });
                } catch (error) {
                    setIsError(true);
                    console.error(error);
                    return;
                }
            }
            if (grid === "produto") {
                try {
                    MovimentoAPI.listarProdutos(
                        pagination.pageIndex * pagination.pageSize,
                        pagination.pageSize,
                        JSON.stringify(columnFilters ?? []),
                        JSON.stringify(columnFilterFns ?? []),
                        globalFilter ?? "",
                        JSON.stringify(sorting ?? []),
                        movimentoId ?? ""
                    ).then((response) => {
                        setItens(response.data.data);
                        setRowCount(response.data.meta.totalRowCount);
                    });
                } catch (error) {
                    setIsError(true);
                    console.error(error);
                    return;
                }
            }
            if (grid === "lancamento") {
                try {
                    MovimentoAPI.listarLancamentos(
                        pagination.pageIndex * pagination.pageSize,
                        pagination.pageSize,
                        JSON.stringify(columnFilters ?? []),
                        JSON.stringify(columnFilterFns ?? []),
                        globalFilter ?? "",
                        JSON.stringify(sorting ?? []),
                        movimentoId ?? ""
                    ).then((response) => {
                        setItemLancamentos(response.data.data);
                        setRowCountLancamento(response.data.meta.totalRowCount);
                    });
                } catch (error) {
                    setIsError(true);
                    console.error(error);
                    return;
                }
            }
            if (grid === "aditivo") {
                try {
                    MovimentoAPI.listarAditivos(
                        pagination.pageIndex * pagination.pageSize,
                        pagination.pageSize,
                        JSON.stringify(columnFilters ?? []),
                        JSON.stringify(columnFilterFns ?? []),
                        globalFilter ?? "",
                        JSON.stringify(sorting ?? []),
                        movimentoId ?? ""
                    ).then((response) => {
                        setItemAditivos(response.data.data);
                        setRowCountAditivo(response.data.meta.totalRowCount);
                    });
                } catch (error) {
                    setIsError(true);
                    console.error(error);
                    return;
                }
            }
        }

        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
    };

    /*Tabela de movimentos de produtos  movimentoItem */
    const limparProduto = () => {
        setNovoProduto({
            empresaId: dados.empresaId,
            embalagemId: null,
            preco: null,
            precoUnitario: null,
            ativo: true,
            fator: 1,
            funcao: "adicionar",
        });
        setProduto({
            id: null,
            descricao: null,
        });
        setOptionsEmbalagem([]);
    };
    const handleProduto = async () => {
        if (novoProduto.funcao === "editar") {
            if (novoProduto.uuid) {
                CreateOrEditProduto(novoProduto);
            } else {
                setItens((prevItens) => {
                    const novosItens = [...prevItens];
                    novosItens[novoProduto.index] = {
                        ...prevItens[novoProduto.index],
                        ...novoProduto,
                    };
                    return novosItens;
                });
                setRowCount(itens.length);

                //caso locação de imoveis
                if (operacao.sigla === "LIM") {
                    setItemLancamentos([]);
                    atualizaLancamentosLIM(novoProduto);
                }
            }
        } else if (novoProduto.funcao === "deletar") {
            if (novoProduto.uuid) {
                try {
                    const response = await MovimentoAPI.deletarProduto(novoProduto.uuid);
                    setFlashMessage(response.data.message, response.data.type);
                } catch (error) {
                    console.error(error);
                    setFlashMessage(error.response.data.message, error.response.data.type);
                }
            } else {
                setItens((prevItens) => {
                    const novosItens = [...prevItens];
                    novosItens.splice(novoProduto.index, 1);
                    return novosItens;
                });
            }
        } else {
            //cadastrar
            if (dados.id) {
                //incluir o numero do movimento ao objeto
                novoProduto.movimentoId = dados.id;

                //verificar duplicidade
                const result = await MovimentoAPI.duplicidadeProduto(
                    novoProduto.empresaId,
                    novoProduto.movimentoId,
                    novoProduto.produtoId,
                    novoProduto.embalagemId
                );

                if (result && novoProduto.funcao === "adicionar") {
                    setFlashMessage("Registo em duplicidade!", "warning");
                    handleEditar(result);
                    return;
                }

                CreateOrEditProduto(novoProduto);
            } else {
                const novoItemArray = [
                    ...itens,
                    {
                        uuid: novoProduto.uuid,
                        empresaId: novoProduto.empresaId || dados.empresaId,
                        tabelaPrecoId: novoProduto.tabelaPrecoId,
                        produtoId: novoProduto.produtoId,
                        embalagemId: novoProduto.embalagemId,

                        descricao: novoProduto.descricao,
                        fator: novoProduto.fator,
                        custo: novoProduto.custo,
                        valorUnitario: novoProduto.valorUnitario,
                        quantidade: novoProduto.quantidade,
                        valorTotal: novoProduto.valorTotal,

                        ativo: novoProduto.ativo,

                        Embalagem: novoProduto.Embalagem,
                        Empresa: novoProduto.Empresa,
                        Produto: novoProduto.Produto,
                    },
                ];

                setItens(novoItemArray);
                setRowCount(novoItemArray.length);

                setDados((prev) => ({
                    ...prev, // Mantém as outras propriedades do objeto
                    movimentoItems: novoItemArray, // Adiciona o novo array ou atualiza o existente
                }));

                //caso locação de imoveis
                if (operacao.sigla === "LIM") {
                    atualizaLancamentosLIM(novoProduto);
                }
            }
        }

        limparProduto();

        fetchData("produto");
    };
    const handleEditar = async (originalRow, row) => {
        // Define os dados para o novo produto
        const novoProdutoData = {
            uuid: originalRow.uuid,
            Produto: originalRow.Produto,
            Embalagem: originalRow.Embalagem,
            Empresa: originalRow.Empresa,

            empresaId: originalRow.empresaId,
            embalagemId: originalRow.embalagemId,
            movimentoId: originalRow.movimentoId,

            fator: originalRow.fator,

            tabelaPrecoId: originalRow.tabelaPrecoId,
            produtoId: originalRow.produtoId,
            descricao: originalRow.Produto.descricao,

            valorUnitario: originalRow.valorUnitario,
            quantidade: originalRow.quantidade,
            valorTotal: originalRow.valorTotal,

            ativo: originalRow.ativo,
            funcao: "editar",
            index: row,
        };

        // Atualize o estado do novo produto
        setNovoProduto(novoProdutoData);

        // Atualize o estado do Produto com a descrição
        setProduto({ ...Produto, descricao: originalRow.descricao });

        //Atualiza o estado da Embalagem
        setOptionsEmbalagem([originalRow.Embalagem]);

        return;
    };
    const handleDelete = (originalRow, row) => {
        const dado = {
            uuid: originalRow.uuid,
            Produto: originalRow.Produto,
            Embalagem: originalRow.Embalagem,
            Empresa: originalRow.Empresa,

            empresaId: originalRow.empresaId,
            embalagemId: originalRow.embalagemId,
            movimentoId: originalRow.movimentoId,

            fator: originalRow.fator,

            tabelaPrecoId: originalRow.tabelaPrecoId,
            produtoId: originalRow.produtoId,
            descricao: originalRow.Produto.descricao,

            valorUnitario: originalRow.valorUnitario,
            quantidade: originalRow.quantidade,
            valorTotal: originalRow.valorTotal,

            ativo: originalRow.ativo,
            funcao: "deletar",
            index: row,
        };

        // Atualize o estado
        setNovoProduto(dado);
    };
    const CreateOrEditProduto = async (novoProduto) => {
        try {
            const result = await MovimentoAPI.managerProduto(novoProduto);
            setFlashMessage(result.message, result.type);
        } catch (error) {
            console.error(error);
            if (error.response && error.response.status === 422) {
                // Tratar o erro 422, que é específico da validação no servidor
                setFlashMessage("Erro de validação: " + error.response.data.message, "error");
            } else {
                // Tratar outros erros de rede ou servidor
                setFlashMessage("Ocorreu um erro ao processar a solicitação.", "error");
            }
        }
    };

    //Termo Aditivo
    const handleAditivo = async () => {
        if (dados.id) {
            //incluir o numero do movimento ao objeto
            novoAditivo.movimentoId = dados.id;

            //ajusta o formato da data
            const data = moment(novoAditivo.data).format("YYYY-MM-DD");
            novoAditivo.data = data;
        } else {
            //não pode reajustar nem prorrogar se o movimento não existe
            return;
        }

        if (novoAditivo.funcao === "editar") {
            if (novoAditivo.uuid) {
                await CreateOrEditAditivo(novoAditivo);
            } else {
                setItemAditivos((prevItens) => {
                    const novosItens = [...prevItens];
                    novosItens[novoAditivo.index] = {
                        ...prevItens[novoAditivo.index],
                        ...novoAditivo,
                    };
                    return novosItens;
                });
                setRowCountAditivo(itemAditivos.length);

                //caso locação de imoveis
                if (operacao.sigla === "LIM") {
                }
            }
        } else if (novoAditivo.funcao === "deletar") {
            if (novoAditivo.uuid) {
                try {
                    const response = await MovimentoAPI.deletarAditivo(novoAditivo.uuid);
                    setFlashMessage(response.data.message, response.data.type);
                } catch (error) {
                    console.error(error);
                    setFlashMessage(error.response.data.message, error.response.data.type);
                }
            } else {
                setItemAditivos((prevItens) => {
                    const novosItens = [...prevItens];
                    novosItens.splice(novoAditivo.index, 1);
                    return novosItens;
                });
            }
        } else {
            //cadastrar

            //verificar duplicidade
            const result = await MovimentoAPI.duplicidadeAditivo(novoAditivo.movimentoId, novoAditivo.tipo, novoAditivo.data);

            if (result) {
                setFlashMessage("Registo em duplicidade!", "warning");
                handleAditivoEditar(result);
                return;
            } else {
                await CreateOrEditAditivo(novoAditivo);
            }
        }

        limparAditivo();
        fetchData("aditivo");
    };
    const CreateOrEditAditivo = async (novoAditivo) => {
        // Força o valorAnterior no contrato
        novoAditivo.valorAnterior = itemAditivos[0]?.valorReajustado || itens[0]?.valorUnitario;

        try {
            const result = await MovimentoAPI.managerAditivo(novoAditivo);

            const msgText = result.message ? result.message : result.response.data.message;
            const msgType = result.type ? result.type : result.response.data.type;

            setFlashMessage(msgText, msgType);

            return result;
        } catch (error) {
            console.error(error);
            if (error.response && error.response.status === 422) {
                // Tratar o erro 422, que é específico da validação no servidor
                setFlashMessage("Erro de validação: " + error.response.data.message, "error");
            } else {
                // Tratar outros erros de rede ou servidor
                setFlashMessage("Ocorreu um erro ao processar a solicitação.", "error");
            }
        }
    };
    const handleAditivoEditar = async (originalRow, row) => {
        // Define os dados
        const novoAditivoData = {
            id: originalRow.id,
            uuid: originalRow.uuid,
            movimentoId: originalRow.movimentoId,
            tipo: originalRow.tipo,
            data: originalRow.data,
            valorAnterior: originalRow.valorAnterior,
            percentual: originalRow.percentual,
            valorReajustado: originalRow.valorReajustado,
            quantidade: originalRow.quantidade,
            observacao: originalRow.observacao,

            ativo: originalRow.ativo,
            funcao: "editar",
            index: row,
        };

        // Atualize o estado do novo produto
        setNovoAditivo(novoAditivoData);

        return;
    };
    const handleAditivoDelete = (originalRow, row) => {
        const dado = {
            uuid: originalRow.uuid,
            movimentoId: originalRow.contaId,
            tipo: originalRow.tipo,
            data: originalRow.data,
            valorAnterior: originalRow.valorAnterior,
            percentual: originalRow.percentual,
            valorReajustado: originalRow.valorReajustado,
            quantidade: originalRow.quantidade,
            observacao: originalRow.observacao,

            ativo: originalRow.ativo,
            funcao: "deletar",
            index: row,
        };

        // Atualize o estado
        setNovoAditivo(dado);
    };
    const limparAditivo = () => {
        setNovoAditivo({
            movimentoId: dados.id,
            diaVencimento: dados.diaVencimento,
            tipo: null,
            data: null,
            percentual: null,
            valorReajustado: null,
            quantidade: null,
            observacao: null,

            ativo: true,
            funcao: "adicionar",
        });
    };
    // const handleAditivoImprimir = async (originalRow, row) => {
    //     const urlSearchParams = new URLSearchParams({
    //         start: `${pagination.pageIndex * pagination.pageSize}`,
    //         size: `${pagination.pageSize}`,
    //         filters: JSON.stringify(columnFilters ?? []),
    //         filtersMode: JSON.stringify(columnFilterFns ?? []),
    //         globalFilter: globalFilter ?? "",
    //         sorting: JSON.stringify(sorting ?? []),
    //         tipo: urlParams.get("tipo") ?? "",
    //     });

    //     const response = await MovimentoAPI.imprimirAditivo(urlSearchParams, originalRow);

    //     // Cria um Blob a partir dos dados do arraybuffer
    //     const pdfBlob = new Blob([response.data], {
    //         type: "application/pdf",
    //     });

    //     // Cria um URL temporário para o Blob
    //     const pdfUrl = URL.createObjectURL(pdfBlob);

    //     // Use o URL do PDF para abrir em uma nova janela ou realizar outras ações
    //     window.open(pdfUrl);
    // };
    //Atualiza os lançamentos para registro ainda não gravado no banco
    /**
     * utilizado em lançamentos não gravados no banco de dados
     * @param {*} novoProduto
     */
    const atualizaLancamentosLIM = async (novoProduto) => {
        let contador = 0;
        //inseri os lançamentos
        const result = await retornaParcelas(
            novoProduto.quantidade,
            dados.data,
            novoProduto.valorUnitario, //dados do lancamento
            false,
            true,
            diasEntreParcelas,
            novoProduto.valorTotal,
            novoProduto.valorUnitario,
            dados.diaVencimento
        );

        // Adiciona o campo empresaId a cada parcela
        const resultWithEmpresaId = result.map((parcela) => ({
            ...parcela,
            uuid: null, //remove o uuid
            empresaId: dados.empresaId,
            pessoaId: dados.parceiroId,
            formaId: dados.formaId,
            prazoId: dados.prazoId,
            centroCustoId: dados.centroCustoId,
            contaId: dados.contaId,
            colaboradorId: dados.colaboradorId,
            historico: `Parcela ${parcela.parcelas}, do Contrato de Locação Nº `,
            tipo: lancamentoTipo,
            situacaoId: lancamentoSituacaoId,
            dataLancamento: dados.data,
            parcela: parcela.parcela,
            provisao: dados.provisao,
            aluguel: true, //campo para facilitar o processo reverso
        }));
        //acrescenta os registros
        contador = resultWithEmpresaId.length;

        // Atualiza os lançamentos com o novo array que inclui empresaId
        setItemLancamentos(resultWithEmpresaId);

        /* Calcula a comissão do corretor */
        if (dados.geraComissao && novoProduto.Produto.comissao > 0) {
            const valorDaComissao = (novoProduto.valorUnitario * novoProduto.Produto.comissao) / 100;
            const valorTotalComissao = valorDaComissao * novoProduto.quantidade;

            const resultComissao = await retornaParcelas(
                novoProduto.quantidade,
                dados.data,
                valorDaComissao, //dados do lancamento
                false,
                true,
                diasEntreParcelas,
                valorTotalComissao,
                valorDaComissao,
                dados.diaVencimento
            );

            // Adiciona o campo empresaId a cada parcela
            const resultComissaoWithEmpresaId = resultComissao.map((parcela) => ({
                ...parcela,
                uuid: null, //remove o uuid
                empresaId: dados.empresaId,
                pessoaId: dados.colaboradorId,
                formaId: dados.formaId,
                prazoId: dados.prazoId,
                centroCustoId: parametro.centroCustoComissaoId, //obeter padrao
                contaId: dados.contaId,
                colaboradorId: dados.colaboradorId,
                historico: `Comissão, parcela ${parcela.parcelas} do Contrato de Locação Nº `,
                tipo: "Pagar",
                situacaoId: lancamentoSituacaoId,
                dataLancamento: dados.data,
                parcela: parcela.parcela,
                provisao: dados.provisao,
                comissao: true, //campo para facilitar o processo reverso
            }));
            setItemLancamentos((prev) => [
                ...prev, // Mantém as parcelas anteriores
                ...resultComissaoWithEmpresaId,
            ]);
        }

        // Inserir a caução, se houver
        if (dados.caucao > 0) {
            setItemLancamentos((prev) => [
                ...prev, // Mantém as parcelas anteriores
                {
                    empresaId: dados.empresaId,
                    pessoaId: dados.parceiroId,
                    formaId: dados.formaId,
                    prazoId: dados.prazoId,
                    centroCustoId: dados.centroCustoId,
                    contaId: dados.contaId,
                    colaboradorId: dados.colaboradorId,
                    historico: "Caução do Contrato de Locação Nº ",
                    tipo: lancamentoTipo,
                    situacaoId: lancamentoSituacaoId,
                    dataLancamento: dados.data,
                    parcela: "1",
                    parcelas: "1/1",

                    dataVencimento: dados.data,
                    valorOriginal: dados.caucao,
                    valor: dados.caucao,
                    valorAberto: dados.caucao,
                    caucao: true, //campo para facilitar o processo reverso
                },
            ]);

            contador++;
        }

        // Inserir a comissão, se houver
        if (dados.valorComissao > 0) {
            setItemLancamentos((prev) => [
                ...prev, // Mantém as parcelas anteriores
                {
                    empresaId: dados.empresaId,
                    pessoaId: dados.colaboradorId,
                    formaId: dados.formaId,
                    prazoId: dados.prazoId,
                    centroCustoId: dados.centroCustoId,
                    contaId: dados.contaId,
                    colaboradorId: dados.colaboradorId,
                    historico: "Comissão do Contrato de Locação Nº ",
                    tipo: lancamentoComissaoTipo,
                    situacaoId: lancamentoSituacaoId,
                    dataLancamento: dados.data,
                    parcela: "1",
                    parcelas: "1/1",

                    dataVencimento: dados.data,
                    valorOriginal: dados.valorComissao,
                    valor: dados.valorComissao,
                    valorAberto: dados.valorComissao,
                    comissao: true, //campo para facilitar o processo reverso
                },
            ]);

            contador++;
        }

        setRowCountLancamento(contador);
    };

    /**
     *
     * @param {*} campo Campo facilitador boolean comissao, caucao, aluguel
     * @param {*} novoValor
     */
    const atualizarValorPorCampoFacilitar = (campo, novoValor) => {
        const updatedLancamentos = itemLancamentos.map((item) => {
            // Verifica se o histórico contém a frase específica (Comissão ou Caução)
            if (item[campo] === true) {
                // Verifica se o valor foi modificado
                if (item.valor !== parseFloat(novoValor)) {
                    return {
                        ...item, // Copia o restante das propriedades do objeto
                        valor: formatarNumeroDecimal(novoValor), // Atualiza o valor
                        valorAberto: formatarNumeroDecimal(novoValor),
                        valorOriginal: formatarNumeroDecimal(novoValor),
                    };
                }
            }
            return item; // Retorna o item inalterado se não for encontrado ou não modificado
        });

        // Atualiza o estado de itemLancamentos com o array atualizado
        setItemLancamentos(updatedLancamentos);
        setRowCountLancamento(updatedLancamentos.length);
    };

    async function handleChange(e) {
        const { name, value, checked, type } = e.target;
        const camposBooleanos = ["assinado", "ativo", "geraComissao", "orcamento", "provisao", "solar", "mobiliado"];
        const camposMonetarios = ["valorComissao", "percentualComissao", "caucao", "multa", "juros"];

        if (camposBooleanos.includes(name)) {
            let zeraComissao = {};
            if (name === "geraComissao" && checked === false) {
                zeraComissao = { percentualComissao: 0 };
            }
            setDados({ ...dados, [name]: checked, ...zeraComissao });
        } else if (name.startsWith("Colaborador")) {
            setColaborador({
                ...Colaborador,
                [name.split(".")[1]]: value,
            });
            if (name.startsWith("Colaborador.descricao")) {
                //carrega o datalist

                const result = await consultaDatalistColaborador(dados.empresaId, value, true, operacao.tipoColaborador);
                setOptionsColaborador(result);
                const selectedId = document.querySelector(`datalist option[value="${value}"]`)?.dataset.id;

                setDados({
                    ...dados,
                    colaboradorId: selectedId ?? null,
                });
            }
        } else if (name.startsWith("Administrador.")) {
            setAdministrador({
                ...Administrador,
                [name.split(".")[1]]: value,
            });

            if (name === "Administrador.descricao") {
                //carrega o datalist

                const result = await consultaDatalistParceiroImoveis(dados.empresaId, value, null, null, null, null, true);
                setOptionsAdministrador(result);

                const administradorId = document.querySelector(`datalist option[value="${e.target.value}"]`)?.dataset.id;

                setDados((prev) => ({ ...prev, administradorId: administradorId ?? null }));
            }
        } else if (name.startsWith("Parceiro.")) {
            setParceiro({
                ...Parceiro,
                [name.split(".")[1]]: value,
            });

            if (name === "Parceiro.descricao") {
                //carrega o datalist
                const result = await consultaDatalistParceiroImoveis(
                    dados.empresaId,
                    value,
                    null,
                    true,
                    null,
                    dados.colaboradorId,
                    null
                );

                setOptionsParceiro(result);

                const parceiroId = document.querySelector(`datalist option[value="${value}"]`)?.dataset.id;

                await atualizaDadosPorParceiro(parceiroId);

                setDados((prev) => ({ ...prev, parceiroId: parceiroId ?? null }));
            }
        } else if (name.startsWith("ParceiroVinculado.")) {
            setParceiroVinculado({
                ...ParceiroVinculado,
                [name.split(".")[1]]: value,
            });

            if (name.startsWith("ParceiroVinculado.descricao")) {
                //carrega o datalist

                const result = await consultaDatalistParceiroImoveis(
                    dados.empresaId,
                    value,
                    null,
                    null,
                    true,
                    dados.colaboradorId,
                    null
                );
                setOptionsParceiroVinculado(result);

                const parceiroId = document.querySelector(`datalist option[value="${value}"]`)?.dataset.id;
                setDados((prev) => ({ ...prev, parceiroVinculadoId: parceiroId ?? null }));
            }
        } else if (name.startsWith("novoProduto.")) {
            // Desestruturar o campo
            const campo = name.split(".")[1];

            // Verifica se é um checkbox e define o valor correspondente
            let valor = type === "checkbox" ? checked : value;

            if (campo === "tabelaPrecoId") {
                setNovoProduto((prev) => ({ ...prev, [campo]: valor }));
            } else if (campo === "descricao") {
                // Carrega o datalist
                setNovoProduto((prev) => ({ ...prev, [campo]: valor }));

                const result = await consultaDatalistProduto(dados.empresaId, novoProduto.tabelaPrecoId, value);
                setOptionsProdutos(result);

                const produtoId = document.querySelector(`datalist option[value="${value}"]`)?.dataset.id;

                if (produtoId) {
                    const tabelaPrecoItem = await MovimentoAPI.retornaTabelaPrecoItem(novoProduto.tabelaPrecoId, produtoId);

                    consultaCondicaoVenda(dados.empresaId, novoProduto.tabelaPrecoId, produtoId, dados.pessoaId);
                    consultaEmbalagemByProdutoId(dados.empresaId, produtoId);

                    //obter a Embalagem e o Fator
                    if (operacao.sigla === "LIM") {
                        setNovoProduto((prev) => ({
                            ...prev,
                            produtoId: produtoId,
                            Produto: tabelaPrecoItem.Produto,
                            Embalagem: tabelaPrecoItem.Embalagem,
                            Empresa: tabelaPrecoItem.Empresa,
                            movimentoItems: tabelaPrecoItem,
                            embalagemId: tabelaPrecoItem.embalagemId,
                            fator: tabelaPrecoItem.fator,
                            empresaId: dados.empresaId,
                        }));
                    } else {
                        setNovoProduto((prev) => ({
                            ...prev,
                            produtoId: produtoId,
                            Produto: Produto,
                            movimentoItems: tabelaPrecoItem,
                        }));
                    }
                }
            } else if (campo === "valorUnitario" || campo === "valorTotal" || campo === "quantidade") {
                const valorFormatado = formatarNumeroDecimal(value);

                if (campo === "valorUnitario") {
                    const valorTotal = (novoProduto.quantidade * valorFormatado).toFixed(2);
                    novoProduto.valorTotal = valorTotal;
                } else if (campo === "quantidade") {
                    const valorTotal = (valorFormatado * novoProduto.valorUnitario).toFixed(2);
                    novoProduto.valorTotal = valorTotal;
                } else if (campo === "valorTotal") {
                    const valorUnitario = (valorFormatado / novoProduto.quantidade).toFixed(2);
                    novoProduto.valorUnitario = valorUnitario;
                }

                setNovoProduto((prev) => ({ ...prev, [campo]: valorFormatado }));
            } else {
                setNovoProduto((prev) => ({ ...prev, [campo]: valor }));
            }
        } else if (name.startsWith("novoAditivo.")) {
            const camposNumerico = ["percentual", "quantidade", "valorReajustado"];
            // Desestruturar o campo
            const campo = name.split(".")[1];

            // Verifica se é um checkbox e define o valor correspondente
            let valor = type === "checkbox" ? checked : value;

            if (operacao.sigla === "LIM") {
                // Força o valorAnterior no contrato
                novoAditivo.valorAnterior = itemAditivos[0]?.valorReajustado || itens[0]?.valorUnitario;
                novoAditivo.valorReajustado = itemAditivos[0]?.valorReajustado || itens[0]?.valorUnitario;

                //caso PRORROGAR a data não pode ser menor que a saida
                if (campo === "tipo" && valor === "Prorrogar") {
                    novoAditivo.data = moment(dados.saida).startOf("day").format("YYYY-MM-DD 00:00:00");
                } else if (campo === "tipo" && valor === "Reajuste") {
                    novoAditivo.data = moment().startOf("day").format("YYYY-MM-DD 00:00:00");
                    novoAditivo.quantidade = null;
                }
            }

            // verifica se é um campo numerico para formatar
            if (camposNumerico.includes(campo)) {
                const valorFormatado = formatarNumeroDecimal(valor);

                //calculo do reajuste
                if (campo === "percentual") {
                    const percentual = formatarNumeroDecimal(valor) / 100 + 1;
                    const val = itens[0]?.valorUnitario ? percentual * itens[0]?.valorUnitario : null;
                    setNovoAditivo((prev) => ({ ...prev, valorReajustado: parseFloat(val).toFixed(2) }));
                }

                if (campo === "valorReajustado") {
                    const valorReajustado = formatarNumeroDecimal(valor);
                    const val = itens[0]?.valorUnitario ? (valorReajustado / itens[0]?.valorUnitario - 1) * 100 : null;
                    setNovoAditivo((prev) => ({ ...prev, percentual: parseFloat(val).toFixed(2) }));
                }

                setNovoAditivo((prev) => ({ ...prev, [campo]: valorFormatado, valorAnterior: itens[0]?.valorUnitario }));
            } else {
                setNovoAditivo((prev) => ({ ...prev, [campo]: valor }));
            }
        } else if (camposMonetarios.includes(name)) {
            const valorFormatado = formatarNumeroDecimal(value);
            setDados({
                ...dados,
                [name]: valorFormatado,
            });
        } else if (name === "situacaoId") {
            if (operacao.sigla === "PDC") {
                setDados({ ...dados, [name]: value, provisao: value === "3" ? true : false });
            } else {
                setDados({ ...dados, [name]: value });
            }
        } else {
            setDados({ ...dados, [name]: value });
        }
    }
    async function handleOnBlur(e) {
        const { name, value, checked, type } = e.target;

        const camposMonetarios = ["valorComissao", "percentualComissao", "caucao", "multa", "juros"];

        if (name.startsWith("Colaborador")) {
            if (!dados.colaboradorId) {
                const arr = value.split(") ");
                if (arr[1]) {
                    const result = await consultaDatalist(
                        dados.empresaId,
                        "Pessoa",
                        "id",
                        "descricao",
                        tipo === "Entrada" ? "compras" : "vendas",
                        arr[1]
                    );
                    setOptionsColaborador(result);
                }

                setDados({
                    ...dados,
                    colaboradorId: document.querySelector(`datalist option[value="${value}"]`)?.dataset.id,
                });
            }
        } else if (name.startsWith("Parceiro.descricao")) {
            if (!isNaN(parseInt(dados.parceiroId))) {
                await atualizaDadosPorParceiro(dados.parceiroId);
            }
        } else if (camposMonetarios.includes(name)) {
            //verifica se comissao ou caucao foi alterado
            if (operacao.sigla === "LIM") {
                if (name === "valorComissao") {
                    atualizarValorPorCampoFacilitar("comissao", value);
                } else if (name === "caucao") {
                    atualizarValorPorCampoFacilitar("caucao", value);
                }
            }
        }
    }
    function handleOnFocus(e) {
        const { name, value, checked, type } = e.target;
        if (name === "Parceiro.descricao") {
            //envia a sigla da operacao para obter o contador da operacao
            dados.operacaoSigla = operacao.sigla.toLowerCase();

            if (!dados.situacaoId) {
                const selectElement = document.getElementById("situacaoId");
                dados.situacaoId = selectElement.value;
            }
        }
    }

    const submit = (e) => {
        e.preventDefault();
        let mensagem = "Movimento precisa de um item incluso!"; //consulta produto => required:true
        //particularidades por operacao
        if (operacao.sigla === "LIM") {
            //verifica se a situação é valida
            if (dados.verificarSituacao && dados.verificarSituacao !== dados.situacaoId) {
                setFlashMessage("Contrato Assinado só pode ser Cancelado ou Devolvido!", "warning");
                return;
            }

            if (dados.movimentoItems.length === 0) {
                mensagem = "Não existe imóvel no contrato!";
            }
        }

        if (dados.movimentoItems.length === 0) {
            setFlashMessage(mensagem, "warning");
            return;
        }

        handleSubmit(dados);
    };

    /* código da grid */
    /* caso inclua colunas nas grids deve alterar o controller na variavel colunasPermitidas */
    const columnsFinanceiro = useMemo(
        () => [
            {
                id: "financeiro",
                header: "Lançamentos em aberto",
                columns: [
                    {
                        accessorKey: "parcelas",
                        header: "Parcelas",
                        muiTableBodyCellProps: {
                            align: "center",
                        },
                    },
                    {
                        accessorFn: (row) => new Date(row),
                        accessorKey: "dataVencimento",
                        header: "Vencimento",
                        Header: ({ column }) => <em>{column.columnDef.header}</em>,
                        muiFilterTextFieldProps: {
                            sx: {
                                minWidth: "350px",
                            },
                        },
                        filterVariant: "date",
                        sortingFn: "date",
                        columnFilterModeOptions: [
                            "fuzzy",
                            "equals",
                            "between",
                            "betweenInclusive",
                            "greaterThanOrEqualTo",
                            "lessThanOrEqualTo",
                        ],
                        muiTableBodyCellProps: ({ row }) => ({
                            align: "center",
                            onClick: () => {},
                        }),
                        muiTableHeadCellFilterTextFieldProps: {
                            type: "date",
                            InputLabelProps: {
                                shrink: true,
                            },
                            sx: {
                                minWidth: "200px",
                            },
                        },
                        Cell: ({ renderedCellValue, row }) => {
                            return (
                                <span>
                                    {row.original.dataVencimento
                                        ? moment.utc(row.original.dataVencimento).format("DD/MM/YYYY")
                                        : null}
                                </span>
                            );
                        },
                    },
                    {
                        accessorKey: "valor",
                        header: "Valor",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valor === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.valor)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "valorBaixado",
                        header: "Baixado",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valorBaixado === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.valorBaixado)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "valorAberto",
                        header: "Aberto",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valorAberto === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.valorAberto)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorFn: (row) => new Date(row),
                        accessorKey: "dataLancamento",
                        header: "Data",
                        Header: ({ column }) => <em>{column.columnDef.header}</em>,
                        muiFilterTextFieldProps: {
                            sx: {
                                minWidth: "350px",
                            },
                        },
                        filterVariant: "date",
                        sortingFn: "date",
                        columnFilterModeOptions: [
                            "fuzzy",
                            "equals",
                            "between",
                            "betweenInclusive",
                            "greaterThanOrEqualTo",
                            "lessThanOrEqualTo",
                        ],
                        muiTableBodyCellProps: ({ row }) => ({
                            align: "center",
                            onClick: () => {},
                        }),
                        muiTableHeadCellFilterTextFieldProps: {
                            type: "date",
                            InputLabelProps: {
                                shrink: true,
                            },
                            sx: {
                                minWidth: "200px",
                            },
                        },
                        Cell: ({ renderedCellValue, row }) => {
                            return (
                                <span>
                                    {row.original.dataLancamento
                                        ? moment.utc(row.original.dataLancamento).format("DD/MM/YYYY")
                                        : null}
                                </span>
                            );
                        },
                    },
                    {
                        accessorKey: "valorOriginal",
                        header: "Valor Total",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valorOriginal === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.valorOriginal)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "Empresa.nomeFantasia",
                        id: "Empresa.nomeFantasia",
                        header: "Empresa",
                        //you can access a cell in many callback column definition options like this
                        muiTableBodyCellProps: ({ cell }) => ({
                            onClick: () => {},
                        }),
                    },
                    {
                        accessorKey: "documento",
                        header: "Documento",
                        muiTableBodyCellProps: {
                            align: "center",
                        },
                    },
                ],
            },

            //column definitions...
        ],
        [] //getCommonEditTextFieldProps
    );
    const columns = useMemo(
        () => [
            {
                id: "produto",
                header: textoHeaderGrid,
                columns: [
                    {
                        accessorKey: "Produto.descricao",
                        id: "Produto.descricao",
                        header: "Descrição",
                        //you can access a cell in many callback column definition options like this
                        muiTableBodyCellProps: ({ cell }) => ({
                            onClick: () => {},
                        }),
                    },
                    {
                        accessorKey: "valorUnitario",
                        header: "Aluguel",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valorUnitario === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.valorUnitario)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "quantidade",
                        header: "Meses",
                        muiTableBodyCellProps: {
                            align: "center",
                        },
                    },
                    {
                        accessorKey: "valorTotal",
                        header: "Valor Total",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valorTotal === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.valorTotal)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                ],
            },

            //column definitions...
        ],
        [textoHeaderGrid] //getCommonEditTextFieldProps
    );
    const columnsLancamento = useMemo(
        () => [
            {
                id: "lancamento",
                header: "Lançamentos da Locação de Imóveis",
                columns: [
                    {
                        accessorKey: "parcelas",
                        header: "Parcelas",
                        muiTableBodyCellProps: {
                            align: "center",
                        },
                    },
                    {
                        accessorFn: (row) => new Date(row),
                        accessorKey: "dataVencimento",
                        header: "Vencimento",
                        Header: ({ column }) => <em>{column.columnDef.header}</em>,
                        muiFilterTextFieldProps: {
                            sx: {
                                minWidth: "350px",
                            },
                        },
                        filterVariant: "date",
                        sortingFn: "date",
                        columnFilterModeOptions: [
                            "fuzzy",
                            "equals",
                            "between",
                            "betweenInclusive",
                            "greaterThanOrEqualTo",
                            "lessThanOrEqualTo",
                        ],
                        muiTableBodyCellProps: ({ row }) => ({
                            align: "center",
                            onClick: () => {},
                        }),
                        muiTableHeadCellFilterTextFieldProps: {
                            type: "date",
                            InputLabelProps: {
                                shrink: true,
                            },
                            sx: {
                                minWidth: "200px",
                            },
                        },
                        Cell: ({ renderedCellValue, row }) => {
                            return (
                                <span>
                                    {row.original.dataVencimento
                                        ? moment.utc(row.original.dataVencimento).format("DD/MM/YYYY")
                                        : null}
                                </span>
                            );
                        },
                    },
                    {
                        accessorKey: "valor",
                        header: "Aluguel",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valor === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.valor)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "valorBaixado",
                        header: "Pago",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valor === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.valorBaixado)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "valorAberto",
                        header: "Aberto",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valor === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.valorAberto)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "tipo",
                        header: "Tipo",
                        muiTableBodyCellProps: {
                            align: "center",
                        },
                    },
                    {
                        accessorKey: "documento",
                        id: "documento",
                        header: "Documento",
                        //you can access a cell in many callback column definition options like this
                        muiTableBodyCellProps: ({ cell }) => ({
                            onClick: () => {},
                        }),
                    },
                    {
                        accessorKey: "historico",
                        header: "Historico",
                    },
                ],
            },

            //column definitions...
        ],
        [] //getCommonEditTextFieldProps
    );
    const columnsAditivo = useMemo(
        () => [
            {
                id: "aditivo",
                header: textoHeaderGrid,
                columns: [
                    {
                        accessorKey: "tipo",
                        id: "tipo",
                        header: "Tipo",
                        //you can access a cell in many callback column definition options like this
                        muiTableBodyCellProps: ({ cell }) => ({
                            onClick: () => {},
                        }),
                    },
                    {
                        accessorFn: (row) => new Date(row),
                        accessorKey: "data",
                        header: "Data",
                        Header: ({ column }) => <em>{column.columnDef.header}</em>,
                        muiFilterTextFieldProps: {
                            sx: {
                                minWidth: "350px",
                            },
                        },
                        filterVariant: "date",
                        sortingFn: "date",
                        columnFilterModeOptions: [
                            "fuzzy",
                            "equals",
                            "between",
                            "betweenInclusive",
                            "greaterThanOrEqualTo",
                            "lessThanOrEqualTo",
                        ],
                        muiTableBodyCellProps: ({ row }) => ({
                            align: "center",
                            onClick: () => {},
                        }),
                        muiTableHeadCellFilterTextFieldProps: {
                            type: "date",
                            InputLabelProps: {
                                shrink: false,
                            },
                            sx: {
                                minWidth: "200px",
                            },
                        },
                        Cell: ({ renderedCellValue, row }) => {
                            return (
                                <span>
                                    {row.original.data ? moment.utc(row.original.data).startOf("day").format("DD/MM/YYYY") : null}
                                </span>
                            );
                        },
                    },
                    {
                        accessorKey: "valorAnterior",
                        header: "Valor Anterior",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valorAnterior === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.valorAnterior)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "percentual",
                        header: "Percentual",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.percentual === null
                                ? ""
                                : parseFloat(row.percentual)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }) + " %",
                    },
                    {
                        accessorKey: "valorReajustado",
                        header: "Aluguel",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valorReajustado === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.valorReajustado)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "quantidade",
                        header: "Meses",
                        muiTableBodyCellProps: {
                            align: "center",
                        },
                    },
                ],
            },

            //column definitions...
        ],
        [textoHeaderGrid] //getCommonEditTextFieldProps
    );

    /* modo tema */
    const theme = tableThemeCustom();

    return (
        <div>
            <Tabs selectedIndex={abaAtiva} onSelect={handleSelect}>
                <form onSubmit={submit}>
                    <h1>{operacao.descricao + " - " + operacao.sigla}</h1>
                    {/* Formulário para PEDIDO DE COMPRA */}
                    {operacao.sigla === "PDC" && (
                        <>
                            <TabList>
                                <Tab disabled={!tabEnabled.cabeçalho} title={"Cabeçalho do Pedido de Compras"}>
                                    Cabeçalho
                                </Tab>
                                <Tab disabled={!tabEnabled.financeiro} title={"Informações financeiras do parceiro"}>
                                    Financeiro
                                </Tab>
                                <Tab disabled={!tabEnabled.itens} title={"Itens incluídos no pedido de compras"}>
                                    Itens
                                </Tab>
                            </TabList>
                            <TabPanel>
                                <div>
                                    <Input
                                        text="Data Cadastro"
                                        type="datetime-local"
                                        name="createdAt"
                                        placeholder="última atualização"
                                        title="Data de inserção do cadastro no sistema"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={
                                            (dados.createdAt && retornaDataHora(dados.createdAt)) ||
                                            moment().format("YYYY-MM-DD HH:mm:ss")
                                        }
                                        readonly={true}
                                        inputWidth={"150px"}
                                    />
                                    <Input
                                        text="Data Atualização"
                                        type="datetime-local"
                                        name="updatedAt"
                                        placeholder="última atualização"
                                        title="Data da última atualização dos dados"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={
                                            (dados.updatedAt && retornaDataHora(dados.updatedAt)) ||
                                            moment().format("YYYY-MM-DD HH:mm:ss")
                                        }
                                        readonly={true}
                                        inputWidth={"150px"}
                                    />
                                    <Input
                                        text="Data"
                                        type="date"
                                        name="data"
                                        placeholder="Data"
                                        title="Data do pedido de compra"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={(dados.data && retornaData(dados.data)) || retornaData(moment())}
                                    />
                                    {btnText !== "Cadastrar" ?? (
                                        <Input
                                            text="Número"
                                            type="text"
                                            name="numero"
                                            inputWidth="100px"
                                            placeholder="Número"
                                            title={`Número do ${operacao.descricao}`}
                                            handleOnChange={handleChange}
                                            handleOnFocus={handleOnFocus}
                                            handleOnBlur={handleOnBlur}
                                            value={dados.numero || ""}
                                            required={false}
                                            readonly={true}
                                        />
                                    )}
                                    <Select
                                        name="situacaoId"
                                        title="Define a situação do movimento"
                                        text="Situação"
                                        inputWidth="170px"
                                        options={OptionsSituacao}
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={dados.situacaoId}
                                        isEnum={false}
                                        notOptNull={true}
                                    />
                                    <DataList
                                        text={colaborador}
                                        name="Colaborador.descricao"
                                        placeholder={`Pesquise o ${colaborador}`}
                                        inputWidth="250px"
                                        filteredOptions={OptionsColaborador}
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={Colaborador?.descricao || ""}
                                        required={true}
                                        readonly={
                                            !(
                                                parametroPessoa.pessoaColaborador?.gerenteCompras ||
                                                parametroPessoa.pessoaColaborador?.supervisorCompras
                                            )
                                        }
                                    />
                                    <Checkbox
                                        title="Provisão no financeiro"
                                        text="Provisão"
                                        name="provisao"
                                        type="checkbox"
                                        handleOnChange={handleChange}
                                        value={dados.provisao}
                                        checked={dados.provisao === true ? "checked" : ""}
                                    />
                                    <Select
                                        name="centroCustoId"
                                        title={`Define o centro de custo do ${operacao.descricao}`}
                                        text="Centro de Custo"
                                        inputWidth="200px"
                                        options={OptionsCC}
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={dados.centroCustoId}
                                        isEnum={false}
                                        notOptNull={false}
                                        disabled={true}
                                    />
                                </div>
                                <div>
                                    <DataList
                                        text={"Parceiro"}
                                        name="Parceiro.descricao"
                                        placeholder={
                                            dados.colaboradorId > 0 ? `Pesquise o ${parceiroTipo}` : `Escolha um ${colaborador}`
                                        }
                                        inputWidth="310px"
                                        filteredOptions={OptionsParceiro}
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={Parceiro?.descricao || ""}
                                        required={true}
                                        //readonly={parseInt(dados.colaboradorId) > 0 ? false : true}
                                    />
                                    <Select
                                        name="formaId"
                                        title={`Define a forma do ${operacao.descricao}`}
                                        text="Forma"
                                        inputWidth="200px"
                                        options={OptionsForma}
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={dados.formaId}
                                        isEnum={false}
                                        notOptNull={false}
                                        disabled={btnText === "Baixar"}
                                    />
                                    <Select
                                        name="prazoId"
                                        title={`Define o prazo do ${operacao.descricao}`}
                                        text="Prazo"
                                        inputWidth="200px"
                                        options={OptionsPrazo}
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={dados.prazoId}
                                        isEnum={false}
                                        notOptNull={false}
                                        disabled={btnText === "Baixar"}
                                    />
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div>
                                    <Input
                                        text="Total Aberto"
                                        name="totalAberto"
                                        type="text"
                                        title="Valor total dos lançamentos em aberto"
                                        value={lancamentos[0]?.totalAberto ? maskDecimal(lancamentos[0]?.totalAberto) : "0,00"}
                                        readonly={true}
                                        inputWidth={"150px"}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginLeft: "0.7rem",
                                    }}
                                >
                                    <table className={formStyles.table}>
                                        <thead>
                                            <tr>
                                                <th className={formStyles.th} align="left">
                                                    Empresa
                                                </th>
                                                <th className={formStyles.th}>Documento</th>
                                                <th className={formStyles.th}>Data</th>
                                                <th className={formStyles.th}>Situação</th>
                                                <th className={formStyles.th}>Vencimento</th>
                                                <th className={formStyles.th}>Valor</th>
                                                <th className={formStyles.th}>Parcela</th>
                                                <th className={formStyles.th}>Aberto</th>
                                            </tr>
                                        </thead>
                                        <tbody className={formStyles.tbody}>
                                            <>
                                                {Object.keys(lancamentos).map((key, index, array) => (
                                                    <tr className={formStyles.tr} key={lancamentos[key]?.uuid || index}>
                                                        <td
                                                            className={formStyles.td}
                                                            style={{
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            {limitarTexto(lancamentos[key]?.Empresa?.nomeFantasia, 17)}
                                                        </td>
                                                        <td className={formStyles.td}>{lancamentos[key]?.documento}</td>
                                                        <td className={formStyles.td}>
                                                            {moment(lancamentos[key]?.dataLancamento).format("DD/MM/YYYY")}
                                                        </td>
                                                        <td className={formStyles.td}>{lancamentos[key]?.situacao}</td>
                                                        <td className={formStyles.td}>
                                                            {moment(lancamentos[key]?.dataVencimento).format("DD/MM/YYYY")}
                                                        </td>
                                                        <td className={formStyles.td}>
                                                            {maskDecimal(lancamentos[key]?.valorOriginal)}
                                                        </td>
                                                        <td className={formStyles.td}>{lancamentos[key]?.parcelas}</td>
                                                        <td className={formStyles.td}>
                                                            {maskDecimal(lancamentos[key]?.valorAberto)}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </>
                                        </tbody>
                                    </table>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div>
                                    <Select
                                        name="tabelaPrecoId"
                                        title={`escolha a tabela de preço do ${operacao.descricao}`}
                                        text="Tabela de Preço"
                                        inputWidth="200px"
                                        options={OptionsTabelaPreco}
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novoProduto.tabelaPrecoId}
                                        isEnum={false}
                                        notOptNull={false}
                                        disabled={btnText === "Baixar"}
                                    />
                                    <DataList
                                        text="Produto"
                                        name="Produto.descricao"
                                        placeholder={"Pesquise o Produto"}
                                        inputWidth="500px"
                                        filteredOptions={OptionsProdutos}
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={Produto?.descricao || ""}
                                        required={false}
                                        readonly={Boolean(!parseInt(novoProduto.tabelaPrecoId))}
                                    />

                                    <InputNumber
                                        text={"Valor"}
                                        type="text"
                                        name="valor"
                                        placeholder="Valor R$"
                                        inputWidth="100px"
                                        title="digite o valor da venda"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novoProduto.valor ? formatarNumero(novoProduto.valor) : ""}
                                        readonly={btnText === "Baixar"}
                                    />
                                    <Select
                                        name="novoProduto.embalagemId"
                                        title={"Escolha a embalagem do produto"}
                                        text="Embalagem"
                                        options={OptionsEmbalagem}
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novoProduto.embalagemId}
                                        isEnum={false}
                                        notOptNull={true}
                                    />
                                    <InputNumber
                                        text={"Valor Unitário"}
                                        type="text"
                                        name="valor"
                                        placeholder="Valor R$"
                                        inputWidth="100px"
                                        title="digite o valor da venda"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novoProduto.valor ? formatarNumero(novoProduto.valor) : ""}
                                        readonly={btnText === "Baixar"}
                                    />
                                </div>

                                <div>
                                    <InputNumber
                                        text={"Quantidade"}
                                        type="text"
                                        name="valor"
                                        placeholder="Valor R$"
                                        inputWidth="100px"
                                        title="digite o valor da venda"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novoProduto.valor ? formatarNumero(novoProduto.valor) : ""}
                                        readonly={btnText === "Baixar"}
                                    />
                                    <InputNumber
                                        text={"Valor"}
                                        type="text"
                                        name="valor"
                                        placeholder="Valor R$"
                                        inputWidth="100px"
                                        title="digite o valor da venda"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novoProduto.valor ? formatarNumero(novoProduto.valor) : ""}
                                        readonly={btnText === "Baixar"}
                                    />
                                    <InputNumber
                                        text={"Desc %"}
                                        type="text"
                                        name="valor"
                                        placeholder="Valor R$"
                                        inputWidth="100px"
                                        title="digite o valor da venda"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novoProduto.valor ? formatarNumero(novoProduto.valor) : ""}
                                        readonly={btnText === "Baixar"}
                                    />
                                    <InputNumber
                                        text={"Desc R$"}
                                        type="text"
                                        name="valor"
                                        placeholder="Valor R$"
                                        inputWidth="100px"
                                        title="digite o valor da venda"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novoProduto.valor ? formatarNumero(novoProduto.valor) : ""}
                                        readonly={btnText === "Baixar"}
                                    />
                                    <InputNumber
                                        text={"Total"}
                                        type="text"
                                        name="valor"
                                        placeholder="Valor R$"
                                        inputWidth="100px"
                                        title="digite o valor da venda"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novoProduto.valor ? formatarNumero(novoProduto.valor) : ""}
                                        readonly={btnText === "Baixar"}
                                    />
                                </div>
                            </TabPanel>
                        </>
                    )}
                    {/* Formulário para PEDIDO DE VENDA EXTERNA */}
                    {operacao.sigla === "PVE" && (
                        <>
                            <TabList>
                                <Tab disabled={!tabEnabled.cabeçalho} title={"Cabeçalho do Pedido de Vendas"}>
                                    Cabeçalho
                                </Tab>
                                <Tab disabled={!tabEnabled.financeiro} title={"Informações financeiras do parceiro"}>
                                    Financeiro
                                </Tab>
                                <Tab disabled={!tabEnabled.itens} title={"Itens incluídos no pedido de vendas"}>
                                    Itens
                                </Tab>
                            </TabList>
                            <TabPanel>
                                <div>
                                    <Input
                                        text="Data Cadastro"
                                        type="datetime-local"
                                        name="createdAt"
                                        placeholder="última atualização"
                                        title="Data de inserção do cadastro no sistema"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={
                                            (dados.createdAt && retornaDataHora(dados.createdAt)) ||
                                            moment().format("YYYY-MM-DD HH:mm:ss")
                                        }
                                        readonly={true}
                                        inputWidth={"150px"}
                                    />
                                    <Input
                                        text="Data Atualização"
                                        type="datetime-local"
                                        name="updatedAt"
                                        placeholder="última atualização"
                                        title="Data da última atualização dos dados"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={
                                            (dados.updatedAt && retornaDataHora(dados.updatedAt)) ||
                                            moment().format("YYYY-MM-DD HH:mm:ss")
                                        }
                                        readonly={true}
                                        inputWidth={"150px"}
                                    />
                                    <Input
                                        text="Data"
                                        type="date"
                                        name="data"
                                        placeholder="Data"
                                        title="Data do pedido de venda"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={(dados.data && retornaData(dados.data)) || retornaData(moment())}
                                    />

                                    {btnText !== "Cadastrar" ?? (
                                        <Input
                                            text="Número"
                                            type="text"
                                            name="numero"
                                            inputWidth="100px"
                                            placeholder="Número"
                                            title={`Número do ${operacao.descricao}`}
                                            handleOnChange={handleChange}
                                            handleOnFocus={handleOnFocus}
                                            handleOnBlur={handleOnBlur}
                                            value={dados.numero || ""}
                                            required={false}
                                            readonly={true}
                                        />
                                    )}
                                    <DataList
                                        text={colaborador}
                                        name="Colaborador.descricao"
                                        placeholder={`Pesquise o ${colaborador}`}
                                        inputWidth="250px"
                                        filteredOptions={OptionsColaborador}
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={Colaborador?.descricao || ""}
                                        required={true}
                                        readonly={
                                            !(
                                                parametroPessoa.pessoaColaborador?.gerenteVendas ||
                                                parametroPessoa.pessoaColaborador?.supervisorVendas
                                            )
                                        }
                                    />
                                    <Checkbox
                                        title="Orçamento não gera provisão no financeiro"
                                        text="Orçamento"
                                        name="orcamento"
                                        type="checkbox"
                                        handleOnChange={handleChange}
                                        value={dados.orcamento}
                                        checked={dados.orcamento === true ? "checked" : ""}
                                    />
                                    <Select
                                        name="centroCustoId"
                                        title={`Define o centro de custo do ${operacao.descricao}`}
                                        text="Centro de Custo"
                                        inputWidth="200px"
                                        options={OptionsCC}
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={dados.centroCustoId}
                                        isEnum={false}
                                        notOptNull={false}
                                        disabled={true}
                                    />
                                </div>
                                <div>
                                    <DataList
                                        text={"Parceiro"}
                                        name="Parceiro.descricao"
                                        placeholder={
                                            dados.colaboradorId > 0 ? `Pesquise o ${parceiroTipo}` : `Escolha um ${colaborador}`
                                        }
                                        inputWidth="310px"
                                        filteredOptions={OptionsParceiro}
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={Parceiro?.descricao || ""}
                                        required={true}
                                        //readonly={parseInt(dados.colaboradorId) > 0 ? false : true}
                                    />
                                    <Select
                                        name="formaId"
                                        title={`Define a forma do ${operacao.descricao}`}
                                        text="Forma"
                                        inputWidth="200px"
                                        options={OptionsForma}
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={dados.formaId}
                                        isEnum={false}
                                        notOptNull={false}
                                        disabled={btnText === "Baixar"}
                                    />
                                    <Select
                                        name="prazoId"
                                        title={`Define o prazo do ${operacao.descricao}`}
                                        text="Prazo"
                                        inputWidth="200px"
                                        options={OptionsPrazo}
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={dados.prazoId}
                                        isEnum={false}
                                        notOptNull={false}
                                        disabled={btnText === "Baixar"}
                                    />
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div>
                                    <Input
                                        text="Total Aberto"
                                        name="totalAberto"
                                        type="text"
                                        title="Valor total dos lançamentos em aberto"
                                        value={lancamentos[0]?.totalAberto ? maskDecimal(lancamentos[0]?.totalAberto) : "0,00"}
                                        readonly={true}
                                        inputWidth={"150px"}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginLeft: "0.7rem",
                                    }}
                                >
                                    <table className={formStyles.table}>
                                        <thead>
                                            <tr>
                                                <th className={formStyles.th} align="left">
                                                    Empresa
                                                </th>
                                                <th className={formStyles.th}>Documento</th>
                                                <th className={formStyles.th}>Data</th>
                                                <th className={formStyles.th}>Situação</th>
                                                <th className={formStyles.th}>Vencimento</th>
                                                <th className={formStyles.th}>Valor</th>
                                                <th className={formStyles.th}>Parcela</th>
                                                <th className={formStyles.th}>Aberto</th>
                                            </tr>
                                        </thead>
                                        <tbody className={formStyles.tbody}>
                                            <>
                                                {Object.keys(lancamentos).map((key, index, array) => (
                                                    <tr className={formStyles.tr} key={lancamentos[key]?.uuid || index}>
                                                        <td
                                                            className={formStyles.td}
                                                            style={{
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            {limitarTexto(lancamentos[key]?.Empresa?.nomeFantasia, 17)}
                                                        </td>
                                                        <td className={formStyles.td}>{lancamentos[key]?.documento}</td>
                                                        <td className={formStyles.td}>
                                                            {moment(lancamentos[key]?.dataLancamento).format("DD/MM/YYYY")}
                                                        </td>
                                                        <td className={formStyles.td}>{lancamentos[key]?.situacao}</td>
                                                        <td className={formStyles.td}>
                                                            {moment(lancamentos[key]?.dataVencimento).format("DD/MM/YYYY")}
                                                        </td>
                                                        <td className={formStyles.td}>
                                                            {maskDecimal(lancamentos[key]?.valorOriginal)}
                                                        </td>
                                                        <td className={formStyles.td}>{lancamentos[key]?.parcelas}</td>
                                                        <td className={formStyles.td}>
                                                            {maskDecimal(lancamentos[key]?.valorAberto)}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </>
                                        </tbody>
                                    </table>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div>
                                    <Select
                                        name="tabelaPrecoId"
                                        title={`escolha a tabela de preço do ${operacao.descricao}`}
                                        text="Tabela de Preço"
                                        inputWidth="200px"
                                        options={OptionsTabelaPreco}
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novoProduto.tabelaPrecoId}
                                        isEnum={false}
                                        notOptNull={false}
                                        disabled={btnText === "Baixar"}
                                    />
                                    <DataList
                                        text="Produto"
                                        name="Produto.descricao"
                                        placeholder={"Pesquise o Produto"}
                                        inputWidth="500px"
                                        filteredOptions={OptionsProdutos}
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={Produto?.descricao || ""}
                                        required={false}
                                        readonly={Boolean(!parseInt(novoProduto.tabelaPrecoId))}
                                    />

                                    <InputNumber
                                        text={"Valor"}
                                        type="text"
                                        name="valor"
                                        placeholder="Valor R$"
                                        inputWidth="100px"
                                        title="digite o valor da venda"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novoProduto.valor ? formatarNumero(novoProduto.valor) : ""}
                                        readonly={btnText === "Baixar"}
                                    />
                                    <Select
                                        name="novoProduto.embalagemId"
                                        title={"Escolha a embalagem do produto"}
                                        text="Embalagem"
                                        options={OptionsEmbalagem}
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novoProduto.embalagemId}
                                        isEnum={false}
                                        notOptNull={true}
                                    />
                                    <InputNumber
                                        text={"Valor Unitário"}
                                        type="text"
                                        name="valor"
                                        placeholder="Valor R$"
                                        inputWidth="100px"
                                        title="digite o valor da venda"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novoProduto.valor ? formatarNumero(novoProduto.valor) : ""}
                                        readonly={btnText === "Baixar"}
                                    />
                                </div>

                                <div>
                                    <InputNumber
                                        text={"Quantidade"}
                                        type="text"
                                        name="valor"
                                        placeholder="Valor R$"
                                        inputWidth="100px"
                                        title="digite o valor da venda"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novoProduto.valor ? formatarNumero(novoProduto.valor) : ""}
                                        readonly={btnText === "Baixar"}
                                    />
                                    <InputNumber
                                        text={"Valor"}
                                        type="text"
                                        name="valor"
                                        placeholder="Valor R$"
                                        inputWidth="100px"
                                        title="digite o valor da venda"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novoProduto.valor ? formatarNumero(novoProduto.valor) : ""}
                                        readonly={btnText === "Baixar"}
                                    />
                                    <InputNumber
                                        text={"Desc %"}
                                        type="text"
                                        name="valor"
                                        placeholder="Valor R$"
                                        inputWidth="100px"
                                        title="digite o valor da venda"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novoProduto.valor ? formatarNumero(novoProduto.valor) : ""}
                                        readonly={btnText === "Baixar"}
                                    />
                                    <InputNumber
                                        text={"Desc R$"}
                                        type="text"
                                        name="valor"
                                        placeholder="Valor R$"
                                        inputWidth="100px"
                                        title="digite o valor da venda"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novoProduto.valor ? formatarNumero(novoProduto.valor) : ""}
                                        readonly={btnText === "Baixar"}
                                    />
                                    <InputNumber
                                        text={"Total"}
                                        type="text"
                                        name="valor"
                                        placeholder="Valor R$"
                                        inputWidth="100px"
                                        title="digite o valor da venda"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novoProduto.valor ? formatarNumero(novoProduto.valor) : ""}
                                        readonly={btnText === "Baixar"}
                                    />
                                </div>
                            </TabPanel>
                        </>
                    )}
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "1rem",
                            flexWrap: "wrap", // Permite que os itens quebrem a linha quando necessário
                            justifyContent: "space-between", // Distribui os itens igualmente
                        }}
                    >
                        <Stack direction="row" spacing={1}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() =>
                                    navigate(caminhoVoltar, {
                                        ul: ul,
                                        li: li,
                                        tipo: tipo,
                                        operacao: operacaoUUID,
                                    })
                                }
                                style={{
                                    marginLeft: "10px",
                                    minWidth: "120px",
                                    fontSize: "14px",
                                    "@media (max-width: 600px)": {
                                        fontSize: "12px", // Ajusta o tamanho da fonte em telas menores
                                        padding: "8px 12px", // Ajusta o padding para manter o botão visível
                                    },
                                }}
                            >
                                Voltar
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="success"
                                style={{
                                    marginLeft: "10px",
                                    minWidth: "120px",
                                    fontSize: "14px",
                                    "@media (max-width: 600px)": {
                                        fontSize: "12px", // Ajusta o tamanho da fonte em telas menores
                                        padding: "8px 12px", // Ajusta o padding para manter o botão visível
                                    },
                                }}
                                disabled={isDisabled}
                            >
                                {btnText}
                            </Button>
                        </Stack>
                    </div>
                </form>
            </Tabs>
        </div>
    );
}
export default Form;
