import api from "./";

export const forma = async (
    tipo = undefined,
    credito = undefined,
    empresaId = undefined,
    descricao = undefined,
    dependente = undefined,
    nivel,
    formaId = undefined
) => {
    try {
        const { data } = await api.get("/serviceFORMA", {
            params: { tipo, credito, empresaId, descricao, dependente, nivel, formaId },
        });
        return data;
    } catch (error) {
        return error;
    }
};

/**
 * Função para obter do backend os prazos.
 * @param {string} token - Para validar a permissão de acessar o backend.
 * @param {string} tipo - Define se é Pagar ou Receber.
 * @param {string} formaId - Obtem os prazos pelo nivelVenda da forma vinculada.
 * @param {string} prazoId - Retorna o prazo especifico pelo id.
 * @param {string} empresaId - Retorna os prazos da empresa.
 * @param {string} prazoMedio - Retorna todos os prazos que a média de dias seja igual o menor que o valor passado.
 * @returns {arrya} array.
 * @comentario Servi para manter os prazos especificados no cadastro da pessoa
 */
export const prazo = async (tipo = "", formaId = "", nivel = "", prazoId = "", empresaId = "", prazoMedio) => {
    try {
        const { data } = await api.get("/servicePRAZO", { params: { tipo, formaId, nivel, prazoId, empresaId, prazoMedio } });
        return data;
    } catch (error) {
        return error;
    }
};

export const lancamentosEmAberto = async (pessoaId, tipo) => {
    try {
        const { data } = await api.get("/serviceLancamentosEmAberto", { params: { pessoaId, tipo } });
        return data;
    } catch (error) {
        return error;
    }
};
/**
 *
 * @param {*} empresaId id da empresa ou padrão 1
 * @param {*} campo true para o campo informado
 * @returns
 */
export const situacao = async (empresaId = 1, campo) => {
    try {
        const { data } = await api.get("/serviceSituacao", { params: { empresaId, campo } });
        return data;
    } catch (error) {
        return error;
    }
};

export const calcularParcelas = async (
    totalParcelas,
    primeiraDataVencimento,
    dadosLancamento,
    credito = false,
    fixaDiaVencimento = false,
    diasEntreParcelas,
    valorOriginal,
    valor,
    diaVencimento
) => {
    try {
        const { data } = await api.get("/serviceCalcularParcelas", {
            params: {
                totalParcelas,
                primeiraDataVencimento,
                dadosLancamento,
                credito,
                fixaDiaVencimento,
                diasEntreParcelas,
                valorOriginal,
                valor,
                diaVencimento,
            },
        });
        return data;
    } catch (error) {
        return error;
    }
};
