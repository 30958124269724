import { useState, useEffect, useContext, useMemo } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

/* contexts */
import { Context } from "../../../context/UserContext";

import moment from "moment";
import "moment/locale/pt-br";

import { Stack, Button, Box, Tooltip, IconButton } from "@mui/material";
import {
    Delete,
    Edit,
    Print,
    AddShoppingCart,
    RemoveShoppingCart,
    ShoppingCart,
    ShoppingCartCheckout,
} from "@mui/icons-material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

import { ThemeProvider } from "@mui/material";
/* tema customizado*/
import tableThemeCustom from "../../form/tableThemeCustom";

//Import Material React Table Translations
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import Input from "../../form/Input";
import InputNumber from "../../form/InputNumber";
import DataList from "../../form/DataList";
import Select from "../../form/Select";
import Checkbox from "../../form/Checkbox";

/* utils */
import { formatarNumero, formatarNumeroDecimal, limitarTexto, maskCEP, maskDecimal } from "../../../utils/masks";
import { retornaDataHora, retornaData } from "../../../utils/retornaDataFormatada";

import * as DataListAPI from "../../../api/dataList";
import * as FinanceiroAPI from "../../../api/financeiro";
import * as VerificadoresAPI from "../../../api/verificadores";
import * as PessoaAPI from "../../../api/pessoa";
import * as TabelaAPI from "../../../api/tabela";
import * as MovimentoAPI from "../../../api/movimentoEntradas";
import * as EmbalagemAPI from "../../../api/embalagem";
import * as TributacaoAPI from "../../../api/tributacao";
import * as AlmoxarifadoAPI from "../../../api/almoxarifado";
import * as OperacaoAPI from "../../../api/operacao";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, operacao, OptionsSituacao, OptionsCentroCusto }) {
    const [dados, setDados] = useState(formData || {});

    //itens das grids
    const [itens, setItens] = useState([]);
    const [itemLancamentos, setItemLancamentos] = useState([]);
    const [itensProdutos, setItensProdutos] = useState([]);

    const [parametroPessoa, setParametroPessoa] = useState({});
    const [lancamentos, setLancamentos] = useState([]);

    /* dados para o movimento */
    const [Produto, setProduto] = useState({});
    const [Embalagem, setEmbalagem] = useState({});
    const [OptionsProdutos, setOptionsProdutos] = useState([]);
    const [OptionsEmbalagem, setOptionsEmbalagem] = useState([]);

    const [OptionsAlmoxarifados, setOptionsAlmoxarifado] = useState([]);

    const [operacaoParametros, setOperacaoParametros] = useState({});

    /* button enable ou disabled */
    const [isDisabled, setIsDisabled] = useState(false);

    /* Todas as operações*/
    const [novoProduto, setNovoProduto] = useState({
        id: null,
        uuid: null,

        movimentoId: dados.id,
        empresaId: dados.empresaId,
        tabelaPrecoId: null,
        produtoId: null,
        embalagemId: null,

        Empresa: {},
        Produto: {},
        Embalagem: {},
        movimentoItems: {},

        //herança da tabelaPrecoITem
        precoUnitario: Number(0).toFixed(2),
        preco: Number(0).toFixed(2),

        custo: Number(0).toFixed(2),
        custoCalculado: Number(0).toFixed(2),
        quantidade: Number(1).toFixed(2),
        valorUnitario: Number(0).toFixed(2),
        descontoPercentual: Number(0).toFixed(2),
        descontoValor: Number(0).toFixed(2),
        valorTotal: Number(0).toFixed(2),

        //estoque
        almoxarifadoId: dados.almoxarifadoId ? dados.almoxarifadoId : null,

        ativo: true,
        funcao: "adicionar",
    });

    const consultaEmbalagemByProdutoId = async (empresaId, produtoId) => {
        const result = await MovimentoAPI.retornaEmbalagensByProdutoId(empresaId, produtoId);

        if (Array.isArray(result) && result.length === 0) {
            // Exibe uma mensagem de erro se o array estiver vazio
            setFlashMessage("Nenhuma embalagem encontrada para o produto.", "error");
        } else {
            // Extrai a propriedade Embalagem de cada item no array e define as opções de embalagem
            const embalagens = result.map((item) => item.Embalagem);

            setOptionsEmbalagem(embalagens);
        }

        return result;
    };
    const consultaFator = async (empresaId = dados.empresaId, embalagemId) => {
        const result = await TabelaAPI.fatorByEmbalagemId(empresaId, embalagemId);
        return result;
    };

    const consultaAlmoxarifados = async (empresaId = dados.empresaId) => {
        const result = await AlmoxarifadoAPI.almoxarifados(empresaId);
        setOptionsAlmoxarifado(result);
        return result;
    };

    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();

    //obtem os parametros da empresa
    const { parametro } = useContext(Context);

    const urlParams = new URLSearchParams(window.location.search);
    const ul = urlParams.get("ul");
    const li = urlParams.get("li");
    const tipo = urlParams.get("tipo");
    const operacaoUUID = urlParams.get("operacao");

    let colaborador = "";
    let parceiroTipo = "Fornecedor";
    let lancamentoTipo = "Receber";
    const financeiroTipo = "Pagar"; //para consultas de prazo e forma
    let lancamentoSituacaoId = 1; //para lancamentos em aberto depois melhoro o codigo
    let textoHeaderGrid = "Movimento";

    //para lancamento
    let diasEntreParcelas = 0;
    let lancamentoComissaoTipo = "Pagar";

    if (operacao.entrada === true) {
        colaborador = "Comprador";
        parceiroTipo = "Fornecedor";
        lancamentoTipo = "Pagar";
        if (!dados.centroCustoId) {
            dados.centroCustoId = parametro.centroCustoPDC;
        }
    }

    //para produto vazio preenche valores pré definidos
    const empresaArmazenada = JSON.parse(localStorage.getItem("Empresa"));

    if (!dados.empresaId) {
        dados.empresaId = localStorage.getItem("empresaId");
        dados.ativo = true;

        dados.createdAt = moment().format("YYYY-MM-DD HH:mm:ss");
        dados.updatedAt = moment().format("YYYY-MM-DD HH:mm:ss");
        dados.data = moment().format("YYYY-MM-DD 00:00:00");
    }

    async function retornaOperacaoParametros(operacaoId, empresaId) {
        try {
            const data = await OperacaoAPI.retornaOperacaoParametros(operacaoId, empresaId);
            setOperacaoParametros(data);
            return data;
        } catch (error) {
            console.error(error);
        }
    }
    async function retornaParametroPessoa() {
        try {
            const resultado = await PessoaAPI.parametroPessoa(localStorage.getItem("pessoaId"));
            if (!resultado) {
                return;
            }
            setParametroPessoa(resultado);
            setDados((prev) => ({
                ...prev,
                colaboradorId: resultado.id,
            }));
            setColaborador({
                ...Colaborador,
                descricao: resultado.descricao,
            });
            return resultado;
        } catch (erro) {
            // Lide com o erro
            console.error(erro);
        }
    }
    async function retornaLancamentos(pessoaId, tipo) {
        try {
            const result = await FinanceiroAPI.lancamentosEmAberto(pessoaId, tipo);
            setLancamentos(result);
        } catch (erro) {
            console.error(erro);
        }
    }
    async function retornaParcelas(
        totalParcelas,
        primeiraDataVencimento,
        dadosLancamento,
        credito,
        fixaDiaVencimento,
        diasEntreParcelas,
        valorOriginal,
        valor,
        diaVencimento
    ) {
        try {
            const result = await FinanceiroAPI.calcularParcelas(
                totalParcelas,
                primeiraDataVencimento,
                dadosLancamento,
                credito,
                fixaDiaVencimento,
                diasEntreParcelas,
                valorOriginal,
                valor,
                diaVencimento
            );
            return result;
        } catch (erro) {
            console.error(erro);
        }
    }
    //função para calcular valores do pedido
    const calculaValores = (produto, campo) => {
        let { quantidade, valorUnitario, descontoPercentual, descontoValor, valorTotal, fator } = produto;
        let pesoBruto = 0,
            pesoLiquido = 0,
            volume = 0;

        // Verifica se produto.Produto está definido e extrai valores
        if (produto.Produto) {
            pesoBruto = parseFloat(produto.Produto.pesoBruto) || 0;
            pesoLiquido = parseFloat(produto.Produto.pesoLiquido) || 0;
            volume = parseFloat(produto.Produto.volume) || 0;
        }

        // Converte os valores para números com valores padrão
        quantidade = parseFloat(quantidade) || 1;
        valorUnitario = parseFloat(valorUnitario) || 0;
        descontoPercentual = parseFloat(descontoPercentual) || 0;
        descontoValor = parseFloat(descontoValor) || 0;
        valorTotal = parseFloat(valorTotal) || 0;
        fator = parseFloat(fator) || 0;

        // Calcula peso bruto, peso líquido e volume
        produto.pesoBruto = quantidade * fator * pesoBruto;
        produto.pesoLiquido = quantidade * fator * pesoLiquido;
        produto.volume = quantidade * fator * volume;

        // Atualiza descontoValor com base no descontoPercentual se aplicável
        if (campo === "valorUnitario" || campo === "descontoPercentual" || campo === "quantidade") {
            descontoValor = (quantidade * valorUnitario * (descontoPercentual / 100)).toFixed(2);
            produto.descontoValor = descontoValor;
        }

        // Atualiza valores dependendo do campo alterado
        switch (campo) {
            case "quantidade":
            case "valorUnitario":
                produto.valorTotal = (quantidade * valorUnitario - descontoValor).toFixed(2);
                break;
            case "descontoPercentual":
                produto.valorTotal = (quantidade * valorUnitario - descontoValor).toFixed(2);
                break;
            case "descontoValor":
                produto.descontoPercentual = ((descontoValor / (quantidade * valorUnitario)) * 100).toFixed(2);
                produto.valorTotal = (quantidade * valorUnitario - descontoValor).toFixed(2);
                break;
            default:
                produto.valorUnitario = quantidade ? (valorTotal / quantidade).toFixed(2) : 0;
        }

        return produto; // Retorna o produto atualizado para o estado
    };

    //abas
    const [tabEnabled, setTabEnabled] = useState({
        cabeçalho: true,
        financeiro: true,
        itens: true,
        lancamento: true,
        observacao: true,
        aditivo: false,
    });

    //set datalist
    const [Colaborador, setColaborador] = useState(formData?.Colaborador);
    const [OptionsColaborador, setOptionsColaborador] = useState([]);

    const [Parceiro, setParceiro] = useState(formData?.Parceiro);
    const [OptionsParceiro, setOptionsParceiro] = useState([]);

    const [ParceiroVinculado, setParceiroVinculado] = useState(formData?.ParceiroVinculado);
    const [OptionsParceiroVinculado, setOptionsParceiroVinculado] = useState([]);

    //data and fetching state
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);

    const [rowCount, setRowCount] = useState(0);
    const [rowCountFinanceiro, setRowCountFinanceiro] = useState(0);
    const [rowCountItens, setRowCountItens] = useState(0);
    const [rowCountLancamento, setRowCountLancamento] = useState(0);
    const [rowCountProduto, setRowCountProduto] = useState(0);

    //table state
    const [columnFilterFns, setColumnFiltersMode] = useState(
        localStorage.columnFilterFns.length > 2 ? JSON.parse(localStorage.columnFilterFns) : []
    );
    const [columnFilters, setColumnFilters] = useState(
        localStorage.columnFilters.length > 2 ? JSON.parse(localStorage.columnFilters) : []
    );
    const [globalFilter, setGlobalFilter] = useState(
        localStorage.globalFilter.length > 2 ? JSON.parse(localStorage.globalFilter) : ""
    );
    const [sorting, setSorting] = useState(localStorage.sorting.length > 2 ? JSON.parse(localStorage.sorting) : []);
    const [pagination, setPagination] = useState({
        pageIndex: JSON.parse(localStorage.pagination).pageIndex || 0,
        pageSize: JSON.parse(localStorage.pagination).pageSize || 5,
    });

    const movimentoId = dados.id ? dados.id : null;

    //Atualiza o Datalist
    const consultaDatalist = async (empresaId, tabela, id, descricao, condicao, filtro, operacao, funcionarioId) => {
        const result = await DataListAPI.dataList(empresaId, tabela, id, descricao, condicao, filtro, operacao, funcionarioId);
        return result;
    };
    const consultaDatalistColaborador = async (empresaId, filtro, ativo, tipo) => {
        const result = await DataListAPI.dataListColaborador(empresaId, filtro, ativo, tipo);
        return result;
    };

    const consultaDatalistParceiroImoveis = async (
        empresaId,
        filtro,
        locador,
        locatario,
        fiador,
        funcionarioId,
        administrador
    ) => {
        const result = await DataListAPI.dataListParceiroImovel(
            empresaId,
            filtro,
            locador,
            locatario,
            fiador,
            funcionarioId,
            administrador
        );
        return result;
    };
    const consultaDatalistProduto = async (empresaId, tabelaPrecoId, filtro) => {
        const result = await DataListAPI.dataListTabelaPrecoItemProduto(empresaId, tabelaPrecoId, filtro);
        return result;
    };
    const consultaTributacao = async (ncmId) => {
        const tributacao = await TributacaoAPI.tributacaoByNCM(ncmId);
        return tributacao;
    };

    //Atualiza Selects
    const [OptionsForma, setForma] = useState([]);
    const [OptionsPrazo, setPrazo] = useState([]);
    const [OptionsConta, setConta] = useState([]);
    const [OptionsTabelaPreco, setTabelaPreco] = useState([]);
    //endreços
    const [OptionsUF, setUfs] = useState([]);
    const [OptionsMunicipios, setMUNICIPIO] = useState([]);

    //Enums
    // const consultaEnum = async (table, field, filter) => {
    //     const result = await TabelaAPI.enums(table, field, filter);

    //     const movimentosFields = {
    //         condominio: setEnumCondominio,
    //         diaVencimento: setEnumDiaVencimento,
    //         locacao: setEnumLocacao,
    //         iptu: setEnumIPTU,
    //         contrato: setEnumContrato,
    //         energia: setEnumEnergia,
    //         agua: setEnumAgua,
    //     };

    //     const aditivosFields = {
    //         tipo: setEnumTipoAditivo,
    //     };

    //     if (table === "Movimentos" && movimentosFields[field]) {
    //         movimentosFields[field](result);
    //     } else if (table === "movimentoAditivos" && aditivosFields[field]) {
    //         aditivosFields[field](result);
    //     }
    // };

    // Estado para rastrear a aba ativa
    const [abaAtiva, setAbaAtiva] = useState(0);
    const [gridAtiva, setGridAtiva] = useState("financeiro");

    const consultaUF = async () => {
        let uf = await VerificadoresAPI.uf("");
        setUfs(uf);
    };
    const consultaMUNICIPIO = async (ufId, uf, descricao) => {
        let municipio = await VerificadoresAPI.municipio(ufId, uf, descricao);
        setMUNICIPIO(municipio);
    };

    /* identifica a aba atual */
    const handleSelect = (index) => {
        setAbaAtiva(index);

        // Definir qual grid será atualizada com base na aba ativa
        switch (index) {
            case 0:
                setGridAtiva("cabecalho");
                break;
            case 1:
                setGridAtiva("financeiro");
                break;
            case 2:
                setGridAtiva("itens");
                break;
            case 3:
                setGridAtiva("lancamento");
                break;
            case 4:
                setGridAtiva("observacao");
                break;
            case 5:
                setGridAtiva("aditivo");
                break;
            default:
                setGridAtiva("itens");
        }
    };

    //Consultas no backend
    const consultaForma = async (tipo, credito, empresaId, descricao, dependente, nivel, formaId) => {
        const result = await FinanceiroAPI.forma(tipo, credito, empresaId, descricao, dependente, nivel, formaId);
        setForma(result);
        return result;
    };
    const consultaPrazo = async (tipo, formaId, nivel, prazoId, empresaId, prazoMedio) => {
        const result = await FinanceiroAPI.prazo(tipo, formaId, nivel, prazoId, empresaId, prazoMedio);
        setPrazo(result);
        return result;
    };
    const consultaConta = async () => {
        const result = await TabelaAPI.tabela(dados.empresaId, "Conta", "descricao");
        setConta(result);
    };
    const consultaTabelaPreco = async (empresaId, pessoaId, operacaoId, entrada, liberada, locacao, saida, servico) => {
        const result = await TabelaAPI.precoByPessoaId(
            empresaId,
            pessoaId,
            operacaoId,
            entrada,
            liberada,
            locacao,
            saida,
            servico
        );
        return result;
    };
    const consultaParceiro = async (empresaId = dados.empresaId, pessoaId) => {
        const result = await PessoaAPI.financeiro(empresaId, pessoaId);
        return result;
    };

    const atualizaDadosPorParceiro = async (parceiroId) => {
        try {
            const resultado = await consultaParceiro(parceiroId);

            if (resultado) {
                // Atualiza os dados do endereço principal
                if (resultado.pessoaEnderecos) {
                    const enderecoPrincipal = resultado.pessoaEnderecos.find((endereco) => endereco.principal === true);

                    if (enderecoPrincipal) {
                        setDados((prevState) => ({
                            ...prevState,
                            remetenteCEP: enderecoPrincipal.cep || "",
                            remetenteBairro: enderecoPrincipal.bairro || "",
                            remetenteLogradouro: enderecoPrincipal.logradouro || "",
                            remetenteNumero: enderecoPrincipal.numero || "",
                            remetenteComplemento: enderecoPrincipal.complemento || "",
                            remetenteUfId: enderecoPrincipal.ufId || "",
                            remetenteMunicipioId: enderecoPrincipal.municipioId || "",
                        }));
                    }
                }

                // Consulta forma de pagamento, se houver nível de venda
                if (resultado.formaFornecedor?.nivelVenda) {
                    await consultaForma(null, null, dados.empresaId, null, null, resultado.formaFornecedor.nivelVenda, null);
                }

                // Consulta prazo de pagamento
                await consultaPrazo(
                    tipo === "Entrada" ? "Pagar" : "Receber",
                    tipo === "Entrada" ? resultado.formaFornecedorId : resultado.formaClienteId,
                    tipo === "Entrada" ? resultado.prazoFornecedor?.nivelVenda : resultado.prazoCliente?.nivelVenda,
                    "",
                    dados.empresaId,
                    tipo === "Entrada" ? resultado.prazoFornecedor?.prazoMedio : resultado.prazoCliente?.prazoMedio
                );

                // Atualiza as tabelas de preços liberadas e de entradas
                const result = await consultaTabelaPreco(dados.empresaId, parceiroId, false, true, true, false, false, false);
                setTabelaPreco(result);

                // Atualiza os dados principais do parceiro
                setDados((prevState) => ({
                    ...prevState,
                    Operacao: operacao,
                    parceiroId: parceiroId,
                    formaId: tipo === "Entrada" ? resultado.formaFornecedorId : resultado.formaClienteId,
                    prazoId: tipo === "Entrada" ? resultado.prazoFornecedorId : resultado.prazoClienteId,
                    contaId: tipo === "Entrada" ? resultado.contaFornecedorId : resultado.contaClienteId,
                }));
            }
        } catch (error) {
            console.error("Erro ao atualizar dados do parceiro:", error);
        }
    };

    const atualizaTabelasVinculadasParceiro = async (parceiroId) => {
        const resultado = await consultaParceiro(parceiroId);

        await consultaForma(
            tipo === "Entrada" ? "Pagar" : "Receber",
            null,
            dados.empresaId,
            null,
            null,
            resultado.formaFornecedor?.nivelVenda,
            null
        );
        await consultaPrazo(
            tipo === "Entrada" ? "Pagar" : "Receber",
            tipo === "Entrada" ? resultado.formaFornecedorId : resultado.formaClienteId,
            tipo === "Entrada" ? resultado.prazoFornecedor?.nivelVenda : resultado.prazoCliente?.nivelVenda,
            "",
            dados.empresaId,
            tipo === "Entrada" ? resultado.prazoFornecedor?.prazoMedio : resultado.prazoCliente?.prazoMedio
        );

        //ao escolher o parceiro atualiza as tabelas de preços disponíveis
        const result = await consultaTabelaPreco(dados.empresaId, parceiroId, operacao.id);
        setTabelaPreco(result);

        retornaLancamentos(parceiroId, lancamentoTipo);
    };
    const atualizaProdutosByTabelaPrecoId = async (tabelaPrecoId) => {
        if (!tabelaPrecoId) {
            return null;
        }
        // Retorna a tabela de preços para aplicar ao pedido
        const resultado = await MovimentoAPI.retornaTabelaPreco(tabelaPrecoId);
        if (resultado) {
            // Atualiza o preço dos itens do pedido carregado e configura novos produtos
            const itensComValor = resultado.tabelaPrecoItens.map((item) => {
                const itemExistente = itens.find((i) => i.produtoId === item.produtoId);

                return {
                    ...item,
                    pedido: itemExistente ? itemExistente.pedido : false,
                    id: itemExistente ? itemExistente.id : null,

                    uuid: itemExistente ? itemExistente.uuid : null, // Manter o UUID original ao editar
                    movimentoId: itemExistente ? itemExistente.movimentoId : dados.id,

                    uuidTabela: item.uuid,
                    embalagemIdTabela: item.embalagemId,

                    // Configura valores
                    fator: Number(itemExistente ? itemExistente.fator : item.fator).toFixed(2),

                    precoUnitario: Number(item.precoUnitario).toFixed(2),
                    preco:
                        itemExistente && itemExistente.fator != null
                            ? Number(itemExistente.fator * item.precoUnitario).toFixed(2)
                            : Number(item.precoUnitario).toFixed(2),
                    quantidade:
                        itemExistente && itemExistente.quantidade != null
                            ? Number(itemExistente.quantidade).toFixed(2)
                            : item.quantidade != null
                            ? Number(item.quantidade).toFixed(2)
                            : "",
                    valorUnitario: Number(itemExistente ? itemExistente.valorUnitario : item.precoUnitario).toFixed(2),
                    descontoPercentual:
                        itemExistente && itemExistente.descontoPercentual != null
                            ? Number(itemExistente.descontoPercentual).toFixed(2)
                            : item.descontoPercentual != null
                            ? Number(item.descontoPercentual).toFixed(2)
                            : "",
                    descontoValor:
                        itemExistente && itemExistente.descontoValor != null
                            ? Number(itemExistente.descontoValor).toFixed(2)
                            : item.descontoValor != null
                            ? Number(item.descontoValor).toFixed(2)
                            : "",

                    valorTotal: Number(itemExistente ? itemExistente.valorTotal : item.fator * item.precoUnitario).toFixed(2),

                    embalagemId: itemExistente ? itemExistente.embalagemId : item.embalagemId,
                    Embalagem: itemExistente ? itemExistente.Embalagem : item.Embalagem,
                };
            });

            // Defina os itens atualizados na grid de edição
            setItensProdutos(itensComValor);
            setRowCountProduto(itensComValor.length);
        }
    };

    const consultaEmbalagembyId = async (embalagemId) => {
        const data = await EmbalagemAPI.retornaEmbalagem(embalagemId);

        setEmbalagem(data);
        return data;
    };

    useEffect(() => {
        const fetchOperacaoParametros = async () => {
            if (operacao?.id && parametro?.empresaId) {
                await retornaOperacaoParametros(operacao.id, parametro.empresaId);
            }
        };

        fetchOperacaoParametros();
    }, [operacao, parametro]);

    useEffect(() => {
        // Atualizar tabelas vinculadas ao parceiro
        // prazo, forma, etc
        dados.parceiroId && atualizaTabelasVinculadasParceiro(dados.parceiroId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dados.parceiroId]);

    // useEffect(() => {
    //     if (itemLancamentos.length > 0) {
    //         setDados((prev) => ({
    //             ...prev, // Mantém as outras propriedades do objeto
    //             Lancamentos: itemLancamentos, // Atualiza os lançamentos
    //         }));
    //     }
    // }, [itemLancamentos]); // Executa o efeito quando `itemLancamentos` é atualizado

    useEffect(() => {
        setNovoProduto((prev) => ({
            ...prev,
            almoxarifadoId: dados.almoxarifadoId,
        }));
    }, [dados.almoxarifadoId]);
    useEffect(() => {
        setDados(formData || {});
        setParceiro(formData?.Parceiro || {});
        setParceiroVinculado(formData?.ParceiroVinculado || {});

        if (formData.movimentoItems) {
            const itensComPedido = (formData.movimentoItems || []).map((item) => ({
                ...item,
                pedido: true,
            }));

            setItens(itensComPedido);
            setItensProdutos(itensComPedido); // quando do banco
            setRowCountProduto(itensComPedido.length);
        }

        retornaParametroPessoa();
        consultaConta();
        consultaAlmoxarifados(dados.empresaId);

        consultaUF();

        //conta os registros e faz a paginação
        setRowCount(formData?.movimentoItems?.length);
        setRowCountLancamento(formData?.Lancamentos?.length);
        setRowCountFinanceiro(formData?.Lancamentos?.length);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    useEffect(() => {
        consultaMUNICIPIO(dados.remetenteUF, null, null);
    }, [dados.remetenteUF]);

    // Grava dados do endereço da empresa
    useEffect(() => {
        if (!empresaArmazenada && Object.keys(empresaArmazenada).length > 0) {
            // Verifica se o objeto não está vazio
            setDados((prevState) => ({
                ...prevState,
                destinatarioCEP: empresaArmazenada.cep || "",
                destinatarioBairro: empresaArmazenada.bairro || "",
                destinatarioLogradouro: empresaArmazenada.logradouro || "",
                destinatarioNumero: empresaArmazenada.numero || "",
                destinatarioComplemento: empresaArmazenada.complemento || "",
                destinatarioUfId: empresaArmazenada.ufId || "",
                destinatarioMunicipioId: empresaArmazenada.municipioId || "",
            }));
        }
    }, [empresaArmazenada]);

    //gridAtiva
    useEffect(() => {
        if (gridAtiva) {
            // Chame aqui o fetchData() com a gridAtiva
            //fetchData(gridAtiva);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        columnFilterFns,
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
        movimentoId,
        dados.parceiroId,
        gridAtiva,
    ]);
    //tabelaPrecoId alterar lista de produtos
    useEffect(() => {
        if (novoProduto.tabelaPrecoId) {
            atualizaProdutosByTabelaPrecoId(novoProduto.tabelaPrecoId);
        } else {
            setItensProdutos([]);
            limparProduto();
        }
    }, [novoProduto.tabelaPrecoId]);

    useEffect(() => {
        atualizaProdutosByTabelaPrecoId(novoProduto.tabelaPrecoId);
    }, [itens]);

    const fetchData = async (grid, Produto) => {
        // Função auxiliar para aplicar filtros e ordenação
        const applyLocalFiltersAndSorting = (data) => {
            // Aplica os filtros e ordenações locais
            let result = data;

            // Exemplo de aplicação de filtro global
            if (globalFilter) {
                result = result.filter((item) => item.name.includes(globalFilter));
            }

            // Aplicar ordenação, filtro de colunas, etc., conforme necessário
            // result.sort(...), result.filter(...), etc.

            return result;
        };

        if (!itensProdutos.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }
        let filteredData = [];
        if (dados.id) {
            if (grid === "financeiro") {
                try {
                    MovimentoAPI.listarFinanceiro(
                        pagination.pageIndex * pagination.pageSize,
                        pagination.pageSize,
                        JSON.stringify(columnFilters ?? []),
                        JSON.stringify(columnFilterFns ?? []),
                        globalFilter ?? "",
                        JSON.stringify(sorting ?? []),
                        dados.parceiroId ?? "",
                        lancamentoSituacaoId ?? 1
                    ).then((response) => {
                        setLancamentos(response.data.data);
                        setRowCountFinanceiro(response.data.meta.totalRowCount);
                    });
                } catch (error) {
                    setIsError(true);
                    console.error(error);
                    return;
                }
            }
            if (grid === "itens") {
                //consulta remote data fetch
                try {
                    MovimentoAPI.listarProdutosPedido(dados.id).then((response) => {
                        setItens(response.data.data);
                        atualizaProdutosByTabelaPrecoId(Produto.tabelaPrecoId);
                        //setRowCount(response.data.meta.totalRowCount);
                    });
                } catch (error) {
                    setIsError(true);
                    console.error(error);
                    return;
                }
                // try {
                //     MovimentoAPI.listarProdutos(
                //         pagination.pageIndex * pagination.pageSize,
                //         pagination.pageSize,
                //         JSON.stringify(columnFilters ?? []),
                //         JSON.stringify(columnFilterFns ?? []),
                //         globalFilter ?? "",
                //         JSON.stringify(sorting ?? []),
                //         movimentoId ?? ""
                //     ).then((response) => {
                //         setItens(response.data.data);
                //         setRowCount(response.data.meta.totalRowCount);
                //     });
                // } catch (error) {
                //     setIsError(true);
                //     console.error(error);
                //     return;
                // }
            }
            if (grid === "lancamento") {
                try {
                    MovimentoAPI.listarLancamentos(
                        pagination.pageIndex * pagination.pageSize,
                        pagination.pageSize,
                        JSON.stringify(columnFilters ?? []),
                        JSON.stringify(columnFilterFns ?? []),
                        globalFilter ?? "",
                        JSON.stringify(sorting ?? []),
                        movimentoId ?? ""
                    ).then((response) => {
                        setItemLancamentos(response.data.data);
                        setRowCountLancamento(response.data.meta.totalRowCount);
                    });
                } catch (error) {
                    setIsError(true);
                    console.error(error);
                    return;
                }
            }
        } else {
            if (grid === "itens") {
                filteredData = applyLocalFiltersAndSorting(itensProdutos);

                // Setar os itens atualizados na grid
                setItensProdutos(filteredData);
                setRowCountProduto(filteredData.length);
            }
        }

        filteredData = applyLocalFiltersAndSorting(itensProdutos);

        // Setar os itens atualizados na grid
        //setItensProdutos(filteredData);
        //setRowCountProduto(filteredData.length);

        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
    };

    /*Tabela de movimentos de produtos  movimentoItem */
    const managerProduto = (novoProduto) => {
        const novoItemArray = itensProdutos.map(
            (item) =>
                item.uuidTabela === novoProduto.uuidTabela
                    ? {
                          ...item,
                          pedido: novoProduto.pedido, // inclui o produto no pedido
                          id: novoProduto.id,
                          uuid: novoProduto.uuid,

                          ncmId: novoProduto.Produto.ncmId, //obtem o ncm padrão
                          empresaId: novoProduto.empresaId || dados.empresaId,
                          tabelaPrecoId: novoProduto.tabelaPrecoId,
                          produtoId: novoProduto.produtoId,
                          embalagemId: novoProduto.embalagemId,
                          almoxarifadoId: novoProduto.almoxarifadoId,

                          descricao: novoProduto.descricao,

                          fator: novoProduto.fator,

                          custo: novoProduto.custo || novoProduto.valorUnitario,
                          custoCalculado: novoProduto.custoCalculado || novoProduto.valorUnitario,

                          quantidade: novoProduto.quantidade,
                          valorUnitario: novoProduto.valorUnitario,
                          descontoPercentual: novoProduto.descontoPercentual,
                          descontoValor: novoProduto.descontoValor,
                          valorTotal: novoProduto.valorTotal,

                          pesoBruto: novoProduto.pesoBruto,
                          pesoLiquido: novoProduto.pesoLiquido,
                          //pesoReal: novoProduto.pesoLiquido,
                          volume: novoProduto.volume,

                          ativo: novoProduto.ativo,
                          Embalagem: novoProduto.Embalagem,
                          Empresa: novoProduto.Empresa,
                          Produto: novoProduto.Produto,

                          //tributação impostos
                          tributacaoId: novoProduto.tributacaoId,
                      }
                    : item // mantém os itens que não correspondem ao uuidTabela
        );
        setItens(novoItemArray);
        // setItensProdutos(novoItemArray);

        setDados((prev) => ({
            ...prev, // Mantém as outras propriedades do objeto
            movimentoItems: novoItemArray, // Adiciona o novo array ou atualiza o existente
        }));
    };
    const limparProduto = (manterTabela = false) => {
        setNovoProduto({
            uuid: null,
            movimentoId: dados.id,
            empresaId: dados.empresaId,
            tabelaPrecoId: manterTabela ? novoProduto.tabelaPrecoId : null,
            embalagemId: null,

            Produto: {},
            Embalagem: {},

            preco: null,
            precoUnitario: null,

            //estoque
            almoxarifadoId: dados.almoxarifadoId ? dados.almoxarifadoId : novoProduto.almoxarifadoId,

            ativo: true,
            fator: 1,
            funcao: "adicionar",
        });
        setProduto({});
        setOptionsEmbalagem([]);
    };
    const handleProduto = async () => {
        if (novoProduto.funcao === "editar") {
            if (novoProduto.uuid) {
                CreateOrEditProduto(novoProduto);
            } else {
                managerProduto(novoProduto);
            }
        } else if (novoProduto.funcao === "deletar") {
            if (novoProduto.uuid) {
                try {
                    const response = await MovimentoAPI.deletarProduto(novoProduto.uuid);
                    setFlashMessage(response.data.message, response.data.type);
                } catch (error) {
                    console.error(error);
                    setFlashMessage(error.response.data.message, error.response.data.type);
                }
            } else {
                setItens((prevItens) => {
                    const novosItens = [...prevItens];
                    novosItens.splice(novoProduto.index, 1);
                    return novosItens;
                });
            }
        } else {
            //cadastrar
            if (dados.id) {
                //incluir o numero do movimento ao objeto
                novoProduto.movimentoId = dados.id;

                //verificar duplicidade
                const result = await MovimentoAPI.duplicidadeProduto(
                    novoProduto.empresaId,
                    novoProduto.movimentoId,
                    novoProduto.produtoId
                    //novoProduto.embalagemId
                );

                if (result && novoProduto.funcao === "adicionar") {
                    setFlashMessage("Registo em duplicidade!", "warning");
                    handleItem(result, null, "adicionar");
                    return;
                }

                CreateOrEditProduto(novoProduto);
            } else {
                managerProduto(novoProduto);
            }
        }

        limparProduto(true);
    };
    const handleItem = async (originalRow, row, funcao) => {
        //atualizaProdutosByTabelaPrecoId(originalRow.tabelaPrecoId);
        await consultaEmbalagemByProdutoId(originalRow.empresaId, originalRow.produtoId);
        //const t = await consultaTributacao(originalRow.Produto.ncmId);
        const tributacoes = originalRow.Produto.NCM.tributacoes;

        // Define os dados para o novo produto
        const novoProdutoData = {
            pedido: funcao === "cancelar" ? false : true,

            id: originalRow.id,
            uuid: originalRow.uuid,
            uuidTabela: originalRow.uuidTabela, //uuid tabela de preços
            Produto: originalRow.Produto,
            Embalagem: originalRow.Embalagem,
            Empresa: originalRow.Empresa,

            empresaId: originalRow.empresaId,
            embalagemId: funcao === "cancelar" ? originalRow.embalagemIdTabela : originalRow.embalagemId,
            movimentoId: originalRow.movimentoId,

            fator: originalRow.fator || Embalagem.Unidade.fator,

            ncmId: originalRow.Produto.ncmId, //obtem o ncm padrão
            tabelaPrecoId: originalRow.tabelaPrecoId,
            produtoId: originalRow.produtoId,
            descricao: originalRow.Produto.descricao,

            precoUnitario: originalRow.precoUnitario || Number(originalRow.valorUnitario / originalRow.fator).toFixed(2),
            preco: originalRow.precoUnitario
                ? Number(originalRow.precoUnitario * originalRow.fator).toFixed(2)
                : Number(originalRow.valorUnitario).toFixed(2),

            quantidade: originalRow.quantidade,
            valorUnitario: originalRow.valorUnitario,
            descontoPercentual: originalRow.descontoPercentual,
            descontoValor: originalRow.descontoValor,
            valorTotal: originalRow.valorTotal,

            pesoBruto: Number(originalRow.fator * originalRow.Produto.pesoBruto).toFixed(2),
            pesoLiquido: Number(originalRow.fator * originalRow.Produto.pesoLiquido).toFixed(2),
            //pesoReal: Number(originalRow.fator * originalRow.Produto.pesoLiquido).toFixed(2),
            volume: Number(originalRow.fator * originalRow.quantidade * originalRow.Produto.volume),

            //estoqcagem do item
            almoxarifadoId: originalRow.almoxarifadoId || originalRow.Produto.almoxarifadoId,

            //tributacao e impostos
            tributacaoId: originalRow.tributacaoId || tributacoes?.id,

            ativo: originalRow.ativo,

            funcao: funcao,
            index: row,
        };

        // Atualize o estado do novo produto
        setNovoProduto(novoProdutoData);

        // Atualize o estado do Produto com a descrição
        setProduto({ ...Produto, descricao: originalRow.Produto.descricao });
    };

    const CreateOrEditProduto = async (novoProduto) => {
        try {
            const result = await MovimentoAPI.managerProduto(novoProduto);
            setFlashMessage(result.message, result.type);

            //deve atualizar a grid
            dados.id && fetchData("itens", novoProduto);
        } catch (error) {
            console.error(error);
            if (error.response && error.response.status === 422) {
                // Tratar o erro 422, que é específico da validação no servidor
                setFlashMessage("Erro de validação: " + error.response.data.message, "error");
            } else {
                // Tratar outros erros de rede ou servidor
                setFlashMessage("Ocorreu um erro ao processar a solicitação.", "error");
            }
        }
    };

    async function handleChange(e) {
        const { name, value, checked, type } = e.target;
        const camposBooleanos = ["assinado", "ativo", "geraComissao", "orcamento", "provisao", "solar", "mobiliado"];
        const camposMonetarios = ["valorComissao", "percentualComissao", "caucao", "multa", "juros"];

        if (camposBooleanos.includes(name)) {
            let zeraComissao = {};
            if (name === "geraComissao" && checked === false) {
                zeraComissao = { percentualComissao: 0 };
            }
            setDados({ ...dados, [name]: checked, ...zeraComissao });
        } else if (camposMonetarios.includes(name)) {
            const valorFormatado = formatarNumeroDecimal(value);
            setDados({
                ...dados,
                [name]: valorFormatado,
            });
        } else if (name === "situacaoId") {
            if (operacao.sigla === "PDC") {
                setDados({ ...dados, [name]: value, provisao: value === "3" ? true : false });
            } else {
                setDados({ ...dados, [name]: value });
            }
        } else if (name.startsWith("Colaborador")) {
            setColaborador({
                ...Colaborador,
                [name.split(".")[1]]: value,
            });
            if (name.startsWith("Colaborador.descricao")) {
                //carrega o datalist
                if (value?.length > 0) {
                    let result = await consultaDatalistColaborador(dados.empresaId, value, true, operacao.tipoColaborador);
                    setOptionsColaborador(result);
                }

                setDados({
                    ...dados,
                    colaboradorId: document.querySelector(`datalist option[value="${value}"]`)?.dataset.id,
                });
            }
        } else if (name.startsWith("Parceiro.")) {
            setParceiro({
                ...Parceiro,
                [name.split(".")[1]]: value,
            });

            if (name === "Parceiro.descricao") {
                //carrega o datalist
                const result = await consultaDatalist(dados.empresaId, "Pessoa", "id", "descricao", "Pagar", value);

                setOptionsParceiro(result);

                const parceiroId = document.querySelector(`datalist option[value="${value}"]`)?.dataset.id;

                await atualizaDadosPorParceiro(parceiroId);

                setDados((prev) => ({ ...prev, parceiroId: parceiroId ?? null }));
            }
        } else if (name.startsWith("ParceiroVinculado.")) {
            setParceiroVinculado({
                ...ParceiroVinculado,
                [name.split(".")[1]]: value,
            });

            if (name.startsWith("ParceiroVinculado.descricao")) {
                //carrega o datalist

                const result = await consultaDatalistParceiroImoveis(
                    dados.empresaId,
                    value,
                    null,
                    null,
                    true,
                    dados.colaboradorId,
                    null
                );
                setOptionsParceiroVinculado(result);

                const selectedId = document.querySelector(`datalist option[value="${value}"]`)?.dataset.id;
                setDados((prev) => ({ ...prev, parceiroVinculadoId: selectedId ?? null }));
            }
        } else if (name.startsWith("novoProduto.")) {
            // Desestruturar o campo
            const campo = name.split(".")[1];

            // Verifica se é um checkbox e define o valor correspondente
            let valor = type === "checkbox" ? checked : value;

            if (campo === "tabelaPrecoId") {
                setNovoProduto((prev) => ({ ...prev, [campo]: valor }));
            } else if (campo === "embalagemId") {
                const fator = await consultaFator(dados.empresaId, valor);
                const embalagem = await consultaEmbalagembyId(valor);

                let quantidade = parseFloat(novoProduto.quantidade || 1).toFixed(2);
                let valorUnitario = parseFloat(fator.fator * novoProduto.precoUnitario).toFixed(2);
                let descontoPercentual = parseFloat(novoProduto.descontoPercentual || 0).toFixed(2);
                let descontoValor = parseFloat(fator.fator * quantidade * valorUnitario * (descontoPercentual / 100)).toFixed(2);
                let valorTotal = parseFloat(fator.fator * quantidade * novoProduto.precoUnitario - descontoValor).toFixed(2);

                let pesoBruto = parseFloat(fator.fator * quantidade * novoProduto.Produto.pesoBruto).toFixed(2);
                let pesoLiquido = parseFloat(fator.fator * quantidade * novoProduto.Produto.pesoLiquido).toFixed(2);
                //let pesoReal = parseFloat(fator.fator * quantidade * novoProduto.Produto.pesoLiquido).toFixed(2);
                let volume = Number(fator.fator * quantidade * novoProduto.Produto.volume);

                setNovoProduto((prev) => ({
                    ...prev,
                    [campo]: valor,
                    preco: Number(fator.fator * novoProduto.precoUnitario).toFixed(2),
                    valorUnitario: valorUnitario,
                    descontoValor: descontoValor,
                    valorTotal: valorTotal,
                    fator: fator.fator,
                    Embalagem: embalagem,

                    pesoBruto: pesoBruto,
                    pesoLiquido: pesoLiquido,
                    //pesoReal: pesoReal,
                    volume: volume,
                }));
            } else if (
                campo === "quantidade" ||
                campo === "valorUnitario" ||
                campo === "descontoPercentual" ||
                campo === "descontoValor" ||
                campo === "valorTotal"
            ) {
                const valorFormatado = formatarNumeroDecimal(value);

                setNovoProduto((prev) => {
                    // Atualiza o valor alterado e calcula novos valores
                    const novoProdutoAtualizado = calculaValores({ ...prev, [campo]: valorFormatado }, campo);
                    return novoProdutoAtualizado;
                });
            } else {
                setNovoProduto((prev) => ({ ...prev, [campo]: valor }));
            }
        } else if (name === "formaId") {
            //obtem prazo medio do parceiro
            const prazoMedio = dados.Parceiro.prazoFornecedor.prazoMedio || null;
            setDados({ ...dados, [name]: value });
            consultaPrazo("Pagar", value, "", "", dados.empresaId, prazoMedio);
        } else {
            setDados({ ...dados, [name]: value });
        }
    }
    async function handleOnBlur(e) {
        const { name, value, checked, type } = e.target;
        const camposMonetarios = ["valorComissao", "percentualComissao", "caucao", "multa", "juros"];

        if (name.startsWith("Colaborador")) {
            if (!dados.colaboradorId) {
                const arr = value.split(") ");
                if (arr[1]) {
                    let result = await consultaDatalist(
                        dados.empresaId,
                        "Pessoa",
                        "id",
                        "descricao",
                        tipo === "Entrada" ? "compras" : "vendas",
                        arr[1]
                    );
                    setOptionsColaborador(result);
                }
                const selectedId = document.querySelector(`datalist option[value="${value}"]`)?.dataset.id;
                setDados({
                    ...dados,
                    colaboradorId: selectedId ?? null,
                });
            }
        } else if (name.startsWith("Parceiro.descricao")) {
            if (!isNaN(parseInt(dados.parceiroId))) {
                await atualizaDadosPorParceiro(dados.parceiroId);
            }
        } else if (camposMonetarios.includes(name)) {
        }
    }
    function handleOnFocus(e) {
        const { name, value, checked, type } = e.target;
        if (name === "Parceiro.descricao") {
            //envia a sigla da operacao para obter o contador da operacao
            dados.operacaoSigla = operacao.sigla.toLowerCase();

            if (!dados.situacaoId) {
                const selectElement = document.getElementById("situacaoId");
                dados.situacaoId = selectElement.value;
            }
        }
    }

    const submit = (e) => {
        e.preventDefault();
        let mensagem = "";
        if (!dados.parceiroId) {
            mensagem += "Fornecedor é obrigatório! ";
        }
        if (!dados.movimentoItems) {
            mensagem += "Movimento precisa de um item incluso! ";
        }
        if (mensagem) {
            setFlashMessage(mensagem, "warning");
            return;
        }
        handleSubmit(dados);
    };

    /* código da grid */
    /* caso inclua colunas nas grids deve alterar o controller na variavel colunasPermitidas */
    const columnsFinanceiro = useMemo(
        () => [
            {
                id: "financeiro",
                header: `Situação no financeiro para o ${parceiroTipo}`,
                columns: [
                    {
                        accessorKey: "valorOriginal",
                        header: "Valor Total",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valorOriginal === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.valorOriginal)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "parcelas",
                        header: "Parcelas",
                        muiTableBodyCellProps: {
                            align: "center",
                        },
                    },
                    {
                        accessorFn: (row) => new Date(row),
                        accessorKey: "dataVencimento",
                        header: "Vencimento",
                        Header: ({ column }) => <em>{column.columnDef.header}</em>,
                        muiFilterTextFieldProps: {
                            sx: {
                                minWidth: "350px",
                            },
                        },
                        filterVariant: "date",
                        sortingFn: "date",
                        columnFilterModeOptions: [
                            "fuzzy",
                            "equals",
                            "between",
                            "betweenInclusive",
                            "greaterThanOrEqualTo",
                            "lessThanOrEqualTo",
                        ],
                        muiTableBodyCellProps: ({ row }) => ({
                            align: "center",
                            onClick: () => {},
                        }),
                        muiTableHeadCellFilterTextFieldProps: {
                            type: "date",
                            InputLabelProps: {
                                shrink: true,
                            },
                            sx: {
                                minWidth: "200px",
                            },
                        },
                        Cell: ({ renderedCellValue, row }) => {
                            return (
                                <span>
                                    {row.original.dataVencimento
                                        ? moment.utc(row.original.dataVencimento).format("DD/MM/YYYY")
                                        : null}
                                </span>
                            );
                        },
                    },
                    {
                        accessorKey: "valor",
                        header: "Valor",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valor === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.valor)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "valorBaixado",
                        header: "Baixado",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valorBaixado === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.valorBaixado)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "valorAberto",
                        header: "Aberto",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valorAberto === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.valorAberto)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorFn: (row) => new Date(row),
                        accessorKey: "dataLancamento",
                        header: "Data",
                        Header: ({ column }) => <em>{column.columnDef.header}</em>,
                        muiFilterTextFieldProps: {
                            sx: {
                                minWidth: "350px",
                            },
                        },
                        filterVariant: "date",
                        sortingFn: "date",
                        columnFilterModeOptions: [
                            "fuzzy",
                            "equals",
                            "between",
                            "betweenInclusive",
                            "greaterThanOrEqualTo",
                            "lessThanOrEqualTo",
                        ],
                        muiTableBodyCellProps: ({ row }) => ({
                            align: "center",
                            onClick: () => {},
                        }),
                        muiTableHeadCellFilterTextFieldProps: {
                            type: "date",
                            InputLabelProps: {
                                shrink: true,
                            },
                            sx: {
                                minWidth: "200px",
                            },
                        },
                        Cell: ({ renderedCellValue, row }) => {
                            return (
                                <span>
                                    {row.original.dataLancamento
                                        ? moment.utc(row.original.dataLancamento).format("DD/MM/YYYY")
                                        : null}
                                </span>
                            );
                        },
                    },
                    {
                        accessorKey: "Empresa.nomeFantasia",
                        id: "Empresa.nomeFantasia",
                        header: "Empresa",
                        //you can access a cell in many callback column definition options like this
                        muiTableBodyCellProps: ({ cell }) => ({
                            onClick: () => {},
                        }),
                    },
                    {
                        accessorKey: "documento",
                        header: "Documento",
                        muiTableBodyCellProps: {
                            align: "center",
                        },
                    },
                ],
            },

            //column definitions...
        ],
        [] //getCommonEditTextFieldProps
    );
    const columnsProdutos = useMemo(
        () => [
            {
                id: "produto",
                header: textoHeaderGrid,
                columns: [
                    {
                        accessorKey: "Produto.descricao",
                        id: "Produto.descricao",
                        header: "Descrição",
                        //you can access a cell in many callback column definition options like this
                        muiTableBodyCellProps: ({ row, cell }) => ({
                            onClick: () => {},
                        }),
                    },
                    {
                        accessorKey: "quantidade",
                        header: "Quantidade",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            !row.quantidade
                                ? ""
                                : parseFloat(row.quantidade)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "Embalagem.descricao",
                        id: "Embalagem.descricao",
                        header: "Embalagem",
                    },
                    {
                        accessorKey: "valorUnitario",
                        header: "Valor Unitário",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            !row.valorUnitario
                                ? ""
                                : "R$ " +
                                  parseFloat(row.valorUnitario)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "descontoValor",
                        header: "Desconto",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            !row.descontoValor
                                ? ""
                                : "R$ " +
                                  parseFloat(row.descontoValor)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "valorTotal",
                        header: "Valor Total",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            !row.valorTotal
                                ? ""
                                : "R$ " +
                                  parseFloat(row.valorTotal)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                ],
            },

            //column definitions...
        ],
        [itensProdutos, textoHeaderGrid] //getCommonEditTextFieldProps
    );
    const columnsLancamento = useMemo(
        () => [
            {
                id: "lancamento",
                header: "Lançamentos da Locação de Imóveis",
                columns: [
                    {
                        accessorKey: "parcelas",
                        header: "Parcelas",
                        muiTableBodyCellProps: {
                            align: "center",
                        },
                    },
                    {
                        accessorFn: (row) => new Date(row),
                        accessorKey: "dataVencimento",
                        header: "Vencimento",
                        Header: ({ column }) => <em>{column.columnDef.header}</em>,
                        muiFilterTextFieldProps: {
                            sx: {
                                minWidth: "350px",
                            },
                        },
                        filterVariant: "date",
                        sortingFn: "date",
                        columnFilterModeOptions: [
                            "fuzzy",
                            "equals",
                            "between",
                            "betweenInclusive",
                            "greaterThanOrEqualTo",
                            "lessThanOrEqualTo",
                        ],
                        muiTableBodyCellProps: ({ row }) => ({
                            align: "center",
                            onClick: () => {},
                        }),
                        muiTableHeadCellFilterTextFieldProps: {
                            type: "date",
                            InputLabelProps: {
                                shrink: true,
                            },
                            sx: {
                                minWidth: "200px",
                            },
                        },
                        Cell: ({ renderedCellValue, row }) => {
                            return (
                                <span>
                                    {row.original.dataVencimento
                                        ? moment.utc(row.original.dataVencimento).format("DD/MM/YYYY")
                                        : null}
                                </span>
                            );
                        },
                    },
                    {
                        accessorKey: "valor",
                        header: "Aluguel",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valor === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.valor)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "valorBaixado",
                        header: "Pago",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valor === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.valorBaixado)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "valorAberto",
                        header: "Aberto",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valor === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.valorAberto)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "tipo",
                        header: "Tipo",
                        muiTableBodyCellProps: {
                            align: "center",
                        },
                    },
                    {
                        accessorKey: "documento",
                        id: "documento",
                        header: "Documento",
                        //you can access a cell in many callback column definition options like this
                        muiTableBodyCellProps: ({ cell }) => ({
                            onClick: () => {},
                        }),
                    },
                    {
                        accessorKey: "historico",
                        header: "Historico",
                    },
                ],
            },

            //column definitions...
        ],
        [] //getCommonEditTextFieldProps
    );

    /* modo tema */
    const theme = tableThemeCustom();
    return (
        <div>
            <Tabs selectedIndex={abaAtiva} onSelect={handleSelect}>
                <form onSubmit={submit}>
                    <h1>{operacao.descricao + " - " + operacao.sigla}</h1>
                    {/* Formulário para COMPRAS */}
                    <TabList>
                        <Tab disabled={!tabEnabled.cabeçalho} title={"Cabeçalho do Pedido de Compras"}>
                            Cabeçalho
                        </Tab>
                        <Tab disabled={!tabEnabled.financeiro} title={"Informações financeiras do parceiro"}>
                            Financeiro
                        </Tab>
                        <Tab disabled={!tabEnabled.itens} title={"Itens incluídos no pedido de compras"}>
                            Itens
                        </Tab>
                    </TabList>
                    <TabPanel>
                        <div>
                            <Input
                                text="Data Cadastro"
                                type="datetime-local"
                                name="createdAt"
                                placeholder="última atualização"
                                title="Data de inserção do cadastro no sistema"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={
                                    (dados.createdAt && retornaDataHora(dados.createdAt)) ||
                                    moment().format("YYYY-MM-DD HH:mm:ss")
                                }
                                readonly={true}
                                inputWidth={"150px"}
                            />
                            <Input
                                text="Data Atualização"
                                type="datetime-local"
                                name="updatedAt"
                                placeholder="última atualização"
                                title="Data da última atualização dos dados"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={
                                    (dados.updatedAt && retornaDataHora(dados.updatedAt)) ||
                                    moment().format("YYYY-MM-DD HH:mm:ss")
                                }
                                readonly={true}
                                inputWidth={"150px"}
                            />
                            <Input
                                text="Data"
                                type="date"
                                name="data"
                                placeholder="Data"
                                title="Data do pedido de compra"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={(dados.data && retornaData(dados.data)) || retornaData(moment())}
                            />
                            {btnText !== "Cadastrar" && (
                                <Input
                                    text="Número"
                                    type="text"
                                    name="numero"
                                    inputWidth="100px"
                                    placeholder="Número"
                                    title={`Número do ${operacao.descricao}`}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.numero || ""}
                                    required={false}
                                    readonly={true}
                                />
                            )}
                            <Select
                                name="situacaoId"
                                title="Define a situação do movimento"
                                text="Situação"
                                inputWidth="170px"
                                options={OptionsSituacao}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.situacaoId ? dados.situacaoId : operacaoParametros?.situacaoId}
                                isEnum={false}
                                notOptNull={true}
                            />
                            <DataList
                                text={colaborador}
                                name="Colaborador.descricao"
                                placeholder={`Pesquise o ${colaborador}`}
                                inputWidth="250px"
                                filteredOptions={OptionsColaborador}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={Colaborador?.descricao || ""}
                                required={true}
                                readonly={
                                    !(
                                        parametroPessoa.pessoaColaborador?.gerenteCompras ||
                                        parametroPessoa.pessoaColaborador?.supervisorCompras
                                    )
                                }
                            />
                            {operacao.geraFinanceiro === true && (
                                <Checkbox
                                    title="Provisão no financeiro"
                                    text="Provisão"
                                    name="provisao"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    value={dados.provisao}
                                    checked={dados.provisao === true ? "checked" : ""}
                                />
                            )}
                            <Select
                                name="centroCustoId"
                                title={`Define o centro de custo do ${operacao.descricao}`}
                                text="Centro de Custo"
                                inputWidth="200px"
                                options={OptionsCentroCusto}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.centroCustoId}
                                isEnum={false}
                                notOptNull={false}
                                disabled={true}
                            />
                        </div>
                        <div>
                            <DataList
                                text={parceiroTipo}
                                name="Parceiro.descricao"
                                placeholder={dados.colaboradorId > 0 ? `Pesquise o ${parceiroTipo}` : `Escolha um ${colaborador}`}
                                title={`digite para pesquisar o ${parceiroTipo} relacionado ao comprador`}
                                inputWidth="350px"
                                filteredOptions={OptionsParceiro}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={Parceiro?.descricao || ""}
                                required={true}
                                //readonly={parseInt(dados.colaboradorId) > 0 ? false : true}
                            />
                            <Input
                                text="CEP"
                                type="text"
                                name="remetenteCEP"
                                inputWidth="100px"
                                placeholder="Número"
                                title={`CEP do ${operacao.descricao}`}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.remetenteCEP ? maskCEP(dados.remetenteCEP) : ""}
                                required={false}
                                readonly={false}
                            />
                            <Input
                                text="Logradouro"
                                type="text"
                                name="remetenteLogradouro"
                                inputWidth="250px"
                                placeholder="Logradouro"
                                title={`Logradouro do ${operacao.descricao}`}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.remetenteLogradouro ? dados.remetenteLogradouro : ""}
                                required={false}
                                readonly={false}
                            />
                            <Input
                                text="Número"
                                type="text"
                                name="remetenteNumero"
                                inputWidth="100px"
                                placeholder="Número"
                                title={`Numero do ${operacao.descricao}`}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.remetenteNumero ? dados.remetenteNumero : ""}
                                required={false}
                                readonly={false}
                            />
                            <Input
                                text="Bairro"
                                type="text"
                                name="remetenteBairro"
                                inputWidth="150px"
                                placeholder="Bairro"
                                title={`Bairro do ${operacao.descricao}`}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.remetenteBairro ? dados.remetenteBairro : ""}
                                required={false}
                                readonly={false}
                            />
                            <Input
                                text="Complemento"
                                type="text"
                                name="remetenteComplemento"
                                inputWidth="250px"
                                placeholder="Complemento"
                                title={`Complemento do ${operacao.descricao}`}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.remetenteComplemento ? dados.remetenteComplemento : ""}
                                required={false}
                                readonly={false}
                            />
                            <Select
                                name="remetenteUfId"
                                title="Estado do parceiro"
                                text="UF"
                                inputWidth="150px"
                                options={OptionsUF}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.remetenteUfId}
                                isEnum={false}
                                notOptNull={true}
                            />
                            <Select
                                name="remetenteMunicipioId"
                                title="Municipio do Parceiro"
                                text="Municipio"
                                inputWidth="170px"
                                options={OptionsMunicipios}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.remetenteMunicipioId}
                                isEnum={false}
                                notOptNull={true}
                            />
                        </div>
                        <div>
                            <Select
                                name="formaId"
                                title={`Define a forma do ${operacao.descricao}`}
                                text="Forma"
                                inputWidth="200px"
                                options={OptionsForma}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.formaId}
                                isEnum={false}
                                notOptNull={false}
                            />
                            <Select
                                name="prazoId"
                                title={`Define o prazo do ${operacao.descricao}`}
                                text="Prazo"
                                inputWidth="200px"
                                options={OptionsPrazo}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.prazoId}
                                isEnum={false}
                                notOptNull={false}
                            />
                            <Select
                                name="contaId"
                                title={`Define o conta para`}
                                text="Conta"
                                inputWidth="200px"
                                options={OptionsConta}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.contaId}
                                isEnum={false}
                                notOptNull={false}
                            />
                            <Select
                                name="almoxarifadoId"
                                title="Almoxarifado padrão para todos os produtos, caso não escolha o almoxarifado será o definido no produto"
                                text="Almoxarifado"
                                inputWidth="200px"
                                options={OptionsAlmoxarifados}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.almoxarifadoId}
                                isEnum={false}
                                notOptNull={false}
                            />
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div
                            style={{
                                margin: "1em",
                            }}
                        >
                            <div>
                                <Input
                                    text="Total Aberto"
                                    name="totalAberto"
                                    type="text"
                                    title="Valor total dos lançamentos em aberto"
                                    value={lancamentos[0]?.totalAberto ? maskDecimal(lancamentos[0]?.totalAberto) : "0,00"}
                                    readonly={true}
                                    inputWidth={"150px"}
                                />
                            </div>

                            <ThemeProvider theme={theme}>
                                <MaterialReactTable
                                    displayColumnDefOptions={{
                                        "mrt-row-actions": {
                                            muiTableHeadCellProps: {
                                                align: "center",
                                            },
                                            size: 100,
                                        },
                                        "mrt-row-numbers": {
                                            enableColumnOrdering: true, //turn on some features that are usually off
                                            enableResizing: true,
                                            muiTableHeadCellProps: {
                                                sx: {
                                                    fontSize: "1.2rem",
                                                },
                                            },
                                        },
                                        "mrt-row-select": {
                                            enableColumnActions: true,
                                            enableHiding: true,
                                            size: 50,
                                        },
                                    }}
                                    enableColumnFilterModes
                                    enableGlobalFilterModes
                                    //enableColumnResizing
                                    enableColumnOrdering
                                    enableEditing
                                    renderRowActions={({ row, table }) => (
                                        <Box sx={{ display: "flex", gap: "1rem" }}>
                                            <Tooltip arrow placement="left" title="Editar">
                                                <span>
                                                    {" "}
                                                    <IconButton
                                                        onClick={() => {
                                                            // handleEditar(row.original, row.index);
                                                        }}
                                                        disabled={localStorage.getItem("editar") === "true" ? false : true}
                                                    >
                                                        <Edit />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                            <Tooltip arrow placement="right" title="Deletar">
                                                <span>
                                                    <IconButton
                                                        color="error"
                                                        //onClick={() => handleOpenDialog(row.id)}
                                                        //onClick={() => handleDelete(row.original, row.id)}
                                                        disabled={localStorage.getItem("cancelar") === "true" ? false : true}
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </Box>
                                    )}
                                    renderTopToolbarCustomActions={() => (
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "8px",
                                            }}
                                        >
                                            <Tooltip arrow placement="top-end" title={`Novo Registro`}>
                                                <div>
                                                    {" "}
                                                    <Button
                                                        color="secondary"
                                                        onClick={() => navigate("/TabelaPrecos/CreateOrEdit")}
                                                        variant="contained"
                                                        disabled={
                                                            true
                                                            // localStorage.getItem("incluir") ===
                                                            // "true"
                                                            //     ? false
                                                            //     : true
                                                        }
                                                    >
                                                        Novo
                                                    </Button>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    )}
                                    columns={columnsFinanceiro}
                                    data={lancamentos}
                                    enableRowSelection
                                    getRowId={(row) => row.uuid}
                                    manualFiltering
                                    manualPagination
                                    manualSorting
                                    muiToolbarAlertBannerProps={
                                        isError
                                            ? {
                                                  color: "error",
                                                  children: "Error loading data",
                                              }
                                            : undefined
                                    }
                                    onColumnFiltersChange={setColumnFilters}
                                    onColumnFilterFnsChange={setColumnFiltersMode}
                                    onGlobalFilterChange={setGlobalFilter}
                                    onPaginationChange={setPagination}
                                    onSortingChange={setSorting}
                                    rowCount={rowCountFinanceiro}
                                    initialState={{
                                        showColumnFilters: true,
                                        density: "compact",
                                        //columnPinning: { left: ["mrt-row-select"] },
                                    }}
                                    state={{
                                        columnFilterFns,
                                        columnFilters,
                                        globalFilter,
                                        isLoading,
                                        pagination,
                                        showAlertBanner: isError,
                                        showProgressBars: isRefetching,
                                        sorting,
                                    }}
                                    //positionToolbarDropZone={"top"}
                                    positionExpandColumn={"first"}
                                    positionActionsColumn={"first"}
                                    positionToolbarAlertBanner="bottom"
                                    localization={MRT_Localization_PT_BR}
                                />
                            </ThemeProvider>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div
                            style={{
                                margin: "1em",
                            }}
                        >
                            <div>
                                <Select
                                    name="novoProduto.tabelaPrecoId"
                                    title={`escolha a tabela de preço do ${operacao.descricao}`}
                                    text="Tabela de Preço"
                                    inputWidth="100"
                                    options={OptionsTabelaPreco}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoProduto.tabelaPrecoId}
                                    isEnum={false}
                                    notOptNull={false}
                                />
                                <DataList
                                    text="Produto"
                                    name="Produto.descricao"
                                    placeholder={"Pesquise o Produto"}
                                    inputWidth="500px"
                                    filteredOptions={OptionsProdutos}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={Produto?.descricao || ""}
                                    required={false}
                                    readonly={true}
                                />
                                <Select
                                    name="novoProduto.embalagemId"
                                    title={"Escolha a embalagem do produto"}
                                    text="Embalagem"
                                    options={OptionsEmbalagem}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoProduto.embalagemId}
                                    isEnum={false}
                                    notOptNull={true}
                                />
                                <InputNumber
                                    text={"Preço Unitário"}
                                    type="text"
                                    name="precoUnitario"
                                    placeholder="Valor R$"
                                    inputWidth="100px"
                                    title="preço unitário do produto"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoProduto.precoUnitario ? formatarNumero(novoProduto.precoUnitario) : ""}
                                    readonly={true}
                                />
                                <InputNumber
                                    text={"Preço Tabela"}
                                    type="text"
                                    name="preco"
                                    placeholder="Valor R$"
                                    inputWidth="100px"
                                    title="digite o valor da venda"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoProduto.preco ? formatarNumero(novoProduto.preco) : ""}
                                />
                                <Select
                                    name="novoProduto.almoxarifadoId"
                                    title="Almoxarifado de destino do item"
                                    text="Almoxarifado"
                                    inputWidth="200px"
                                    options={OptionsAlmoxarifados}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoProduto.almoxarifadoId}
                                    isEnum={false}
                                    notOptNull={false}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    //marginTop: "1rem",
                                    flexWrap: "wrap", // Permite que os itens quebrem a linha quando necessário
                                    // justifyContent: "space-between", // Distribui os itens igualmente
                                }}
                            >
                                <InputNumber
                                    text={"Quantidade"}
                                    type="text"
                                    name="novoProduto.quantidade"
                                    placeholder="Quantidade"
                                    inputWidth="100px"
                                    title="digite a quantidade"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoProduto.quantidade ? formatarNumero(novoProduto.quantidade) : ""}
                                    readonly={false}
                                />
                                <InputNumber
                                    text={"Valor"}
                                    type="text"
                                    name="novoProduto.valorUnitario"
                                    placeholder="Valor R$"
                                    inputWidth="100px"
                                    title="digite o valor de compra"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoProduto.valorUnitario ? formatarNumero(novoProduto.valorUnitario) : ""}
                                    readonly={false}
                                />
                                <InputNumber
                                    text={"Desc %"}
                                    type="text"
                                    name="novoProduto.descontoPercentual"
                                    placeholder="desconto %"
                                    inputWidth="100px"
                                    title="digite o percentual de desconto"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoProduto.descontoPercentual ? formatarNumero(novoProduto.descontoPercentual) : ""}
                                    readonly={false}
                                />
                                <InputNumber
                                    text={"Desc R$"}
                                    type="text"
                                    name="novoProduto.descontoValor"
                                    placeholder="desconto em R$"
                                    inputWidth="100px"
                                    title="digite o valor do desconto em R$"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoProduto.descontoValor ? formatarNumero(novoProduto.descontoValor) : ""}
                                    readonly={false}
                                />
                                <InputNumber
                                    text={"Total"}
                                    type="text"
                                    name="novoProduto.valorTotal"
                                    placeholder="Valor R$"
                                    inputWidth="100px"
                                    title="Valor total do produto na compra"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoProduto.valorTotal ? formatarNumero(novoProduto.valorTotal) : ""}
                                    readonly={true}
                                />

                                <Button
                                    variant="contained"
                                    color="inherit"
                                    onClick={() => {
                                        limparProduto(true);
                                    }}
                                    //style={{ marginLeft: "10px" }}
                                    style={{
                                        marginLeft: "10px",
                                        minWidth: "80px",
                                        fontSize: "14px",
                                        "@media (max-width: 600px)": {
                                            fontSize: "12px", // Ajusta o tamanho da fonte em telas menores
                                            padding: "8px 12px", // Ajusta o padding para manter o botão visível
                                        },
                                    }}
                                >
                                    Limpar
                                </Button>
                                <Button
                                    variant="contained"
                                    color={novoProduto.funcao === "cancelar" ? "error" : "info"}
                                    onClick={() => {
                                        handleProduto(novoProduto);
                                    }}
                                    disabled={novoProduto.funcao !== "cancelar" && !novoProduto.quantidade}
                                    //style={{ marginLeft: "5px" }}
                                    style={{
                                        marginLeft: "10px",
                                        minWidth: "80px",
                                        fontSize: "14px",
                                        "@media (max-width: 600px)": {
                                            fontSize: "12px", // Ajusta o tamanho da fonte em telas menores
                                            padding: "8px 12px", // Ajusta o padding para manter o botão visível
                                        },
                                    }}
                                >
                                    {novoProduto.funcao}
                                </Button>
                            </div>

                            <ThemeProvider theme={theme}>
                                <MaterialReactTable
                                    displayColumnDefOptions={{
                                        "mrt-row-actions": {
                                            muiTableHeadCellProps: {
                                                align: "center",
                                            },
                                            size: 100,
                                        },
                                        "mrt-row-numbers": {
                                            enableColumnOrdering: true, //turn on some features that are usually off
                                            enableResizing: true,
                                            muiTableHeadCellProps: {
                                                sx: {
                                                    fontSize: "1.2rem",
                                                },
                                            },
                                        },
                                        "mrt-row-select": {
                                            enableColumnActions: true,
                                            enableHiding: true,
                                            size: 50,
                                        },
                                    }}
                                    enableColumnFilterModes
                                    enableGlobalFilterModes
                                    //enableColumnResizing
                                    enableColumnOrdering
                                    enableEditing
                                    renderRowActions={({ row, table }) => (
                                        <Box sx={{ display: "flex", gap: "1rem" }}>
                                            <Tooltip
                                                arrow
                                                placement="left"
                                                title={`${row.original.pedido === true ? "editar" : "adicionar"}`}
                                            >
                                                <span>
                                                    {" "}
                                                    <IconButton
                                                        onClick={() => {
                                                            handleItem(
                                                                row.original,
                                                                row.index,
                                                                row.original.pedido === true ? "editar" : "adicionar"
                                                            );
                                                        }}
                                                        disabled={
                                                            row.original.pedido === true
                                                                ? localStorage.getItem("editar") === "true"
                                                                    ? false
                                                                    : true
                                                                : localStorage.getItem("incluir") === "true"
                                                                ? false
                                                                : true
                                                        }
                                                    >
                                                        {row.original.pedido === true ? <ShoppingCart /> : <AddShoppingCart />}
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                            <Tooltip arrow placement="right" title="Cancelar">
                                                <span>
                                                    <IconButton
                                                        color="error"
                                                        onClick={() => handleItem(row.original, row.id, "cancelar")}
                                                        disabled={
                                                            row.original.pedido !== true ||
                                                            localStorage.getItem("cancelar") !== "true"
                                                        }
                                                    >
                                                        <RemoveShoppingCart />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </Box>
                                    )}
                                    renderTopToolbarCustomActions={() => (
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "8px",
                                            }}
                                        >
                                            <Tooltip arrow placement="top-end" title={`Novo Registro`}>
                                                <div>
                                                    {" "}
                                                    <Button
                                                        color="secondary"
                                                        onClick={() => navigate("/TabelaPrecos/CreateOrEdit")}
                                                        variant="contained"
                                                        disabled={
                                                            true
                                                            // localStorage.getItem("incluir") ===
                                                            // "true"
                                                            //     ? false
                                                            //     : true
                                                        }
                                                    >
                                                        Novo
                                                    </Button>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    )}
                                    columns={columnsProdutos}
                                    data={itensProdutos}
                                    rowCount={rowCountProduto}
                                    enableRowSelection
                                    getRowId={(row) => row.uuid}
                                    //manualFiltering
                                    manualPagination
                                    manualSorting
                                    muiToolbarAlertBannerProps={
                                        isError
                                            ? {
                                                  color: "error",
                                                  children: "Error loading data",
                                              }
                                            : undefined
                                    }
                                    onColumnFiltersChange={setColumnFilters}
                                    onColumnFilterFnsChange={setColumnFiltersMode}
                                    onGlobalFilterChange={setGlobalFilter}
                                    onPaginationChange={setPagination}
                                    onSortingChange={setSorting}
                                    initialState={{
                                        showColumnFilters: true,
                                        density: "compact",
                                        //columnPinning: { left: ["mrt-row-select"] },
                                    }}
                                    state={{
                                        columnFilterFns,
                                        columnFilters,
                                        globalFilter,
                                        isLoading,
                                        pagination,
                                        showAlertBanner: isError,
                                        showProgressBars: isRefetching,
                                        sorting,
                                    }}
                                    //positionToolbarDropZone={"top"}
                                    positionExpandColumn={"first"}
                                    positionActionsColumn={"first"}
                                    positionToolbarAlertBanner="bottom"
                                    localization={MRT_Localization_PT_BR}
                                />
                            </ThemeProvider>
                        </div>
                    </TabPanel>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "1rem",
                            flexWrap: "wrap", // Permite que os itens quebrem a linha quando necessário
                            justifyContent: "space-between", // Distribui os itens igualmente
                        }}
                    >
                        <Stack direction="row" spacing={1}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() =>
                                    navigate(caminhoVoltar, {
                                        ul: ul,
                                        li: li,
                                        tipo: tipo,
                                        operacao: operacaoUUID,
                                    })
                                }
                                style={{
                                    marginLeft: "10px",
                                    minWidth: "120px",
                                    fontSize: "14px",
                                    "@media (max-width: 600px)": {
                                        fontSize: "12px", // Ajusta o tamanho da fonte em telas menores
                                        padding: "8px 12px", // Ajusta o padding para manter o botão visível
                                    },
                                }}
                            >
                                Voltar
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="success"
                                style={{
                                    marginLeft: "10px",
                                    minWidth: "120px",
                                    fontSize: "14px",
                                    "@media (max-width: 600px)": {
                                        fontSize: "12px", // Ajusta o tamanho da fonte em telas menores
                                        padding: "8px 12px", // Ajusta o padding para manter o botão visível
                                    },
                                }}
                                disabled={isDisabled}
                            >
                                {btnText}
                            </Button>
                        </Stack>
                    </div>
                </form>
            </Tabs>
        </div>
    );
}
export default Form;
