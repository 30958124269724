export function buildFormData(obj, formData, parentKey = "") {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];

            if (value instanceof Array && !(value instanceof File)) {
                if (key === "images" || key === "logomarca" || key === "assinatura") {
                    for (let i = 0; i < value.length; i++) {
                        formData.append(key, value[i], value[i].name);
                    }
                } else {
                    handleArray(formData, key, value, parentKey);
                }
            } else if (value instanceof Object && !(value instanceof File)) {
                buildFormData(value, formData, `${parentKey}${key}.`);
            } else if (value instanceof File) {
                formData.append(`${parentKey}${key}`, value, value.name);
            } else {
                formData.append(`${parentKey}${key}`, value);
            }
        }
    }
}

export function handleArray(formData, key, array, parentKey) {
    array.forEach((obj, index) => {
        if (obj instanceof Object && !(obj instanceof File)) {
            buildFormData(obj, formData, `${parentKey}${key}[${index}].`);
        } else if (obj instanceof File) {
            formData.append(`${parentKey}${key}[${index}]`, obj, obj.name);
        } else {
            formData.append(`${parentKey}${key}[${index}]`, obj);
        }
    });
}

export function newBuildFormData(obj, formData, parentKey = "") {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];

            if (value instanceof Array && !(value instanceof File)) {
                newHandleArray(formData, key, value, parentKey);
            } else if (value instanceof Object && !(value instanceof File)) {
                newBuildFormData(value, formData, `${parentKey}${key}.`);
            } else if (value instanceof File) {
                // Inclui o contexto da tabela filha no caminho para identificar a origem
                const keyWithContext = `${parentKey}${key}`;
                formData.append(keyWithContext, value, value.name);
            } else {
                formData.append(`${parentKey}${key}`, value);
            }
        }
    }
}

export function newHandleArray(formData, key, array, parentKey) {
    array.forEach((obj, index) => {
        if (obj instanceof Object && !(obj instanceof File)) {
            if (["images", "logomarca", "assinatura"].includes(key)) {
                // Adiciona um caminho de contexto que inclui o índice e a chave
                const keyWithContext = `${parentKey}${key}[${index}]`;
                for (let i = 0; i < array.length; i++) {
                    formData.append(`${keyWithContext}`, array[i], array[i].name);
                }
            } else {
                newBuildFormData(obj, formData, `${parentKey}${key}[${index}].`);
            }
        } else if (obj instanceof File) {
            const keyWithContext = `${parentKey}${key}[${index}]`;
            formData.append(keyWithContext, obj, obj.name);
        } else {
            formData.append(`${parentKey}${key}[${index}]`, obj);
        }
    });
}
