import React, { useEffect, useMemo, useState, useContext } from "react";
import { Context } from "../../../context/UserContext";
import moment from "moment";

import { useDispatch } from "react-redux";
import { selectComponent, clearSelectedComponent } from "../../../store/reducers/actions"; //updateSelectedComponents

import { useNavigateParams } from "../../../hooks/useNavigateParams";
import MaterialReactTable from "material-react-table";

import { ThemeProvider } from "@mui/material";
/* tema customizado*/
import tableThemeCustom from "../../form/tableThemeCustom";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";

//Import Material React Table Translations
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";
import CustomDialog from "../../layout/CustomDialog";

import { Box, Button, IconButton, Checkbox, Tooltip } from "@mui/material";
import { Delete, Edit, Print } from "@mui/icons-material";
import tratar from "../../../utils/retornaTratamentoURL";

import CrudsAPI from "../../../api/CRUDs";
import * as TabelaAPI from "../../../api/tabela";
import * as PlantaoAPI from "../../../api/plantao";

/* util */
import { obterPermissoesUsuario } from "../../../utils/funcoes";

const Index = () => {
    const [dados, setDados] = useState([]);
    const [token] = useState(localStorage.getItem("token")) || "";
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();
    const urlParams = new URLSearchParams(window.location.search);

    const [optionsTipo, setOptionsTipo] = useState([]);

    const ul = urlParams.get("ul") || 12;
    const li = urlParams.get("li") || 41;
    const tipo = urlParams.get("tipo");

    //informação para tratamento de permissões
    //const isAdmin = JSON.parse(localStorage.getItem("isAdmin"));

    const { parametro } = useContext(Context);
    /* Obtem a paginação padrão */
    useEffect(() => {
        if (parametro && parametro.paginacao) {
            setPagination({
                pageIndex: 0,
                pageSize: parametro.paginacao,
            });
        }
    }, [parametro]);

    //dialog customizado
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});

    const dispatch = useDispatch();

    //data and fetching state
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    //tratar filtros se vier pela navbar
    if (localStorage.path.replace(/["]/g, "") !== window.location.pathname) {
        tratar.LimparFiltros();
        localStorage.setItem("path", JSON.stringify(window.location.pathname));
    }

    //table state
    const [columnFilterFns, setColumnFiltersMode] = useState(
        localStorage.columnFilterFns.length > 2 ? JSON.parse(localStorage.columnFilterFns) : []
    );

    const [columnFilters, setColumnFilters] = useState(
        localStorage.columnFilters.length > 2 ? JSON.parse(localStorage.columnFilters) : []
    );

    const [globalFilter, setGlobalFilter] = useState(
        localStorage.globalFilter.length > 2 ? JSON.parse(localStorage.globalFilter) : ""
    );

    const [sorting, setSorting] = useState(localStorage.sorting.length > 2 ? JSON.parse(localStorage.sorting) : []);

    const [pagination, setPagination] = useState({
        pageIndex: JSON.parse(localStorage.pagination).pageIndex || 0,
        pageSize: JSON.parse(localStorage.pagination).pageSize || 10,
    });

    //consultas
    const fetchData = async () => {
        if (!dados.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }

        try {
            const { data } = await CrudsAPI.plantao.listar(
                pagination.pageIndex * pagination.pageSize,
                pagination.pageSize,
                JSON.stringify(columnFilters ?? []),
                JSON.stringify(columnFilterFns ?? []),
                globalFilter ?? "",
                JSON.stringify(sorting ?? [])
            );

            setDados(data.data);
            setRowCount(data.meta.totalRowCount);
        } catch (error) {
            setIsError(true);
            console.error(error);
            return;
        }
        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
    };

    const consultaTipo = async () => {
        const result = await TabelaAPI.enums("Plantoes", "tipo");
        setOptionsTipo(result ? result : []);
    };
    //funções do dialog
    const handleOpenDialog = (row) => {
        setOpenDialog(true);
        consultaTipo();
        setSelectedRow(row);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmDialog = async () => {
        // Verifique se há uma linha selecionada
        let msgType = "success";
        if (selectedRow) {
            // Lógica de confirmação
            try {
                const { data } = await CrudsAPI.plantao.deletar(selectedRow);
                setFlashMessage(data.message, data.type);

                // Após a exclusão, chame a função fetchData() novamente para atualizar as unidades
                fetchData();
            } catch (error) {
                msgType = "error";
                console.error(error);
                setFlashMessage(error.response.data.message, msgType);
            }
        }
        setOpenDialog(false);
    };

    useEffect(() => {
        if (!token) {
            return navigate("/Auth", { replace: true });
        }

        //funcao para setar localStorage
        localStorage.setItem(
            "pagination",
            JSON.stringify({
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
            })
        );
        localStorage.setItem("columnFilterFns", JSON.stringify(columnFilterFns));
        localStorage.setItem("columnFilters", JSON.stringify(columnFilters));
        localStorage.setItem("globalFilter", JSON.stringify(globalFilter));
        localStorage.setItem("sorting", JSON.stringify(sorting));

        //permissões do usuario
        obterPermissoesUsuario(localStorage.getItem("pessoaId"), localStorage.getItem("empresaId"), ul, li);

        consultaTipo();
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, columnFilterFns, columnFilters, globalFilter, pagination.pageIndex, pagination.pageSize, sorting, ul, li]);

    const columns = useMemo(
        () => [
            {
                id: "plantao",
                header: "Plantões",
                columns: [
                    {
                        accessorFn: (row) => new Date(row),
                        accessorKey: "data",
                        header: "Data",
                        Header: ({ column }) => <em>{column.columnDef.header}</em>,
                        muiFilterTextFieldProps: {
                            sx: {
                                minWidth: "350px",
                            },
                        },
                        filterVariant: "date",
                        sortingFn: "date",
                        columnFilterModeOptions: [
                            "fuzzy",
                            "equals",
                            "between",
                            "betweenInclusive",
                            "greaterThanOrEqualTo",
                            "lessThanOrEqualTo",
                        ],
                        muiTableBodyCellProps: ({ row }) => ({
                            align: "center",
                            onClick: () => {},
                        }),
                        muiTableHeadCellFilterTextFieldProps: {
                            type: "date",
                            InputLabelProps: {
                                shrink: true,
                            },
                            sx: {
                                minWidth: "200px",
                            },
                        },
                        Cell: ({ renderedCellValue, row }) => {
                            return <span>{moment.utc(row.original.data).format("DD/MM/YYYY")}</span>;
                        },
                    },
                    {
                        accessorKey: "Equipe.descricao",
                        header: "Equipe",
                    },
                    {
                        accessorKey: "tipo",
                        header: "Tipo",
                        filterVariant: "select",
                        filterSelectOptions: optionsTipo,
                    },
                    {
                        accessorFn: (row) => new Date(row),
                        accessorKey: "inicio",
                        header: "Início",
                        Header: ({ column }) => <em>{column.columnDef.header}</em>,
                        muiFilterTextFieldProps: {
                            sx: {
                                minWidth: "350px",
                            },
                        },
                        filterVariant: "date",
                        sortingFn: "datetime",
                        columnFilterModeOptions: [
                            "fuzzy",
                            "equals",
                            "between",
                            "betweenInclusive",
                            "greaterThanOrEqualTo",
                            "lessThanOrEqualTo",
                        ],
                        muiTableBodyCellProps: ({ row }) => ({
                            align: "center",
                            onClick: () => {},
                        }),
                        muiTableHeadCellFilterTextFieldProps: {
                            type: "datetime-local",
                            InputLabelProps: {
                                shrink: true,
                            },
                            sx: {
                                minWidth: "200px",
                            },
                        },
                        Cell: ({ renderedCellValue, row }) => {
                            return <span>{moment(row.original.inicio).format("DD/MM/YYYY HH:mm")}</span>;
                        },
                    },
                    {
                        accessorFn: (row) => new Date(row),
                        accessorKey: "termino",
                        header: "Término",
                        Header: ({ column }) => <em>{column.columnDef.header}</em>,
                        muiFilterTextFieldProps: {
                            sx: {
                                minWidth: "350px",
                            },
                        },
                        filterVariant: "date",
                        sortingFn: "datetime",
                        columnFilterModeOptions: [
                            "fuzzy",
                            "equals",
                            "between",
                            "betweenInclusive",
                            "greaterThanOrEqualTo",
                            "lessThanOrEqualTo",
                        ],
                        muiTableBodyCellProps: ({ row }) => ({
                            align: "center",
                            onClick: () => {},
                        }),
                        muiTableHeadCellFilterTextFieldProps: {
                            type: "datetime-local",
                            InputLabelProps: {
                                shrink: true,
                            },
                            sx: {
                                minWidth: "200px",
                            },
                        },
                        Cell: ({ renderedCellValue, row }) => {
                            return <span>{moment(row.original.termino).format("DD/MM/YYYY HH:mm")}</span>;
                        },
                    },
                    {
                        accessorKey: "concluido",
                        header: "Concluído",
                        columnFilterModeOptions: ["equals"],
                        filterSelectOptions: [
                            { text: "Todos", value: "" },
                            { text: "Marcado", value: 1 },
                            { text: "Desmarcado", value: 0 },
                        ],
                        filterVariant: "select",
                        muiTableBodyCellProps: ({ row }) => ({
                            align: "center",
                            onClick: () => {},
                        }),
                        Cell: ({ renderedCellValue, row }) => {
                            return (
                                <Checkbox
                                    checked={row.original.concluido}
                                    sx={{
                                        "& .MuiSvgIcon-root": { fontSize: 28 },
                                    }}
                                    inputProps={{ "aria-label": "controlled" }}
                                />
                            );
                        },
                    },
                ],
            },

            //column definitions...
        ],
        [optionsTipo] //getCommonEditTextFieldProps
    );

    /* modo tema */
    const theme = tableThemeCustom();

    //função para imprimir
    const handleImprimir = async (uuids) => {
        const dadosFiltrados = uuids.length > 0 ? dados.filter((value) => uuids.includes(value.uuid)) : dados;

        const urlSearchParams = new URLSearchParams({
            start: `${pagination.pageIndex * pagination.pageSize}`,
            size: `${pagination.pageSize}`,
            filters: JSON.stringify(columnFilters ?? []),
            filtersMode: JSON.stringify(columnFilterFns ?? []),
            globalFilter: globalFilter ?? "",
            sorting: JSON.stringify(sorting ?? []),
            tipo: urlParams.get("tipo") ?? "",
        });

        const response = await PlantaoAPI.imprimirPlantao(urlSearchParams, dadosFiltrados);

        // Cria um Blob a partir dos dados do arraybuffer
        const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
        });

        // Cria um URL temporário para o Blob
        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Use o URL do PDF para abrir em uma nova janela ou realizar outras ações
        window.open(pdfUrl);
    };
    return (
        <div>
            <div>
                {openDialog && (
                    <CustomDialog
                        title="Delete Plantão!"
                        content="Você tem certeza?"
                        onClose={handleCloseDialog}
                        onConfirm={handleConfirmDialog}
                    />
                )}
            </div>
            <ThemeProvider theme={theme}>
                <MaterialReactTable
                    displayColumnDefOptions={{
                        "mrt-row-actions": {
                            muiTableHeadCellProps: {
                                align: "center",
                            },
                            size: 80,
                        },
                        "mrt-row-numbers": {
                            enableColumnOrdering: true, //turn on some features that are usually off
                            enableResizing: true,
                            muiTableHeadCellProps: {
                                sx: {
                                    fontSize: "1.2rem",
                                },
                            },
                        },
                        "mrt-row-select": {
                            enableColumnActions: true,
                            enableHiding: true,
                            size: 50,
                        },
                    }}
                    enableColumnFilterModes
                    enableGlobalFilterModes
                    //enableColumnResizing
                    enableColumnOrdering
                    enableEditing
                    renderRowActions={({ row, table }) => {
                        const handlePreparaDados = async () => {
                            handleImprimir(row.id);
                        };
                        return (
                            <Box sx={{ display: "flex", gap: "1rem" }}>
                                <Tooltip
                                    arrow
                                    placement="left"
                                    title={row.original.concluido && row.original.assinado ? "Imprimir" : "Editar"}
                                >
                                    <span>
                                        {" "}
                                        <IconButton
                                            onClick={() => {
                                                row.original.concluido && row.original.assinado
                                                    ? handlePreparaDados()
                                                    : navigate("/Plantao/CreateOrEdit", {
                                                          uuid: row.id,
                                                          ul: ul,
                                                          li: li,
                                                          tipo: tipo,
                                                          btnText: urlParams.get("btnText"),
                                                      });
                                            }}
                                            disabled={
                                                row.original.concluido && row.original.assinado
                                                    ? JSON.parse(localStorage.getItem("imprimir")) === false
                                                    : JSON.parse(localStorage.getItem("editar") === false)
                                            }
                                        >
                                            {row.original.concluido && row.original.assinado ? <Print /> : <Edit />}
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Tooltip arrow placement="right" title="Deletar">
                                    <span>
                                        <IconButton
                                            color="error"
                                            onClick={() => handleOpenDialog(row)}
                                            disabled={
                                                (row.original.concluido && row.original.assinado) ||
                                                JSON.parse(localStorage.getItem("cancelar")) === false
                                            }
                                        >
                                            <Delete />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </Box>
                        );
                    }}
                    renderTopToolbarCustomActions={({ table }) => {
                        const handlePreparaDados = async () => {
                            dispatch(clearSelectedComponent());
                            const uuids = table.getSelectedRowModel().flatRows.map((row) => row.id);
                            dispatch(selectComponent(uuids));
                            handleImprimir(uuids);
                        };
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    gap: "8px",
                                }}
                            >
                                <Tooltip arrow placement="top-end" title={`Novo Registro`}>
                                    <div>
                                        {" "}
                                        <Button
                                            color="secondary"
                                            onClick={() =>
                                                navigate("/Plantao/CreateOrEdit", {
                                                    ul: ul,
                                                    li: li,
                                                    tipo: tipo,
                                                })
                                            }
                                            variant="contained"
                                            disabled={JSON.parse(localStorage.getItem("incluir")) === false}
                                        >
                                            Novo
                                        </Button>
                                    </div>
                                </Tooltip>
                                <Tooltip arrow placement="top-end" title={`Imprimir plantões selecionados`}>
                                    <div>
                                        <Button
                                            color="info"
                                            variant="contained"
                                            disabled={
                                                JSON.parse(localStorage.getItem("imprimir")) === false ||
                                                (!table.getIsSomeRowsSelected() && !table.getIsAllPageRowsSelected())
                                            }
                                            onClick={handlePreparaDados}
                                        >
                                            Imprimir
                                        </Button>
                                    </div>
                                </Tooltip>
                            </div>
                        );
                    }}
                    columns={columns}
                    data={dados}
                    enableRowSelection
                    getRowId={(row) => row.uuid}
                    manualFiltering
                    manualPagination
                    manualSorting
                    muiToolbarAlertBannerProps={
                        isError
                            ? {
                                  color: "error",
                                  children: "Error loading data",
                              }
                            : undefined
                    }
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setColumnFiltersMode}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    rowCount={rowCount}
                    initialState={{
                        showColumnFilters: true,
                        density: "compact",
                        //columnPinning: { left: ["mrt-row-select"] },
                    }}
                    state={{
                        columnFilterFns,
                        columnFilters,
                        globalFilter,
                        isLoading,
                        pagination,
                        showAlertBanner: isError,
                        showProgressBars: isRefetching,
                        sorting,
                    }}
                    //positionToolbarDropZone={"top"}
                    positionExpandColumn={"first"}
                    positionActionsColumn={"first"}
                    positionToolbarAlertBanner="bottom"
                    localization={MRT_Localization_PT_BR}
                />
            </ThemeProvider>
        </div>
    );
};
export default Index;
