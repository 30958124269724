import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import { Context } from "../../../context/UserContext";
import Form from "./Form";
import styles from "../../form/Form.module.css";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";

import * as EmpresaAPI from "../../../api/empresa";
import * as TabelaAPI from "../../../api/tabela";
import * as FinanceiroAPI from "../../../api/financeiro";
import CRUDsAPI from "../../../api/CRUDs";

const CreateOrEdit = () => {
    const [dados, setDados] = useState({});
    const [token] = useState(localStorage.getItem("token")) || "";
    const { parametro } = useContext(Context);
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    //const [OptionsConta, setConta] = useState([]);
    const [OptionsForma, setForma] = useState([]);
    const [OptionsPrazo, setPrazo] = useState([]);
    const [OptionsEmpresa, setEmpresa] = useState([]);

    const pathname = "/Parametro";
    const ul = urlParams.get("ul");
    const li = urlParams.get("li");
    const tipo = urlParams.get("tipo");
    const operacaoUUID = urlParams.get("operacao");
    const uuid = urlParams.get("uuid");
    const btnText = urlParams.get("btnText");

    const params = new URLSearchParams({
        ul: ul,
        li: li,
        tipo: tipo,
        operacao: operacaoUUID,
        uuid: uuid,
        btnText: btnText,
    });
    //enums e tabelas
    const [EnumTipo, setEnumTipo] = useState([]);

    if (!dados.empresaId) {
        dados.empresaId = localStorage.getItem("empresaId");
    }

    //enums
    const tipoForma = async () => {
        const result = await TabelaAPI.enums("pagamentoFormas", "tipo");
        setEnumTipo(result ? result : []);
    };

    const consultaForma = async (tipo, credito, empresaId, descricao, dependente, nivel, formaId) => {
        let dado = await FinanceiroAPI.forma(tipo, credito, empresaId, descricao, dependente, nivel, formaId);
        setForma(dado);
    };
    const consultaPrazo = async (tipo, formaId, nivel, prazoId, empresaId = dados.empresaId, prazoMedio) => {
        let dado = await FinanceiroAPI.prazo(tipo, formaId, nivel, prazoId, empresaId, prazoMedio);

        setPrazo(dado);
    };
    const consultaEmpresa = async () => {
        let result = await EmpresaAPI.empresa(localStorage.getItem("empresaId"));
        setEmpresa(result);
    };

    useEffect(() => {
        if (!token) {
            return navigate("/Auth", { replace: true });
        }

        tipoForma();
        consultaEmpresa();
        consultaForma(tipo, false, dados.empresaId, null, false, null, null);
        consultaPrazo(tipo, dados.formaId, null, null, dados.empresaId, null);
        //consultaConta();

        if (uuid && uuid !== "null") {
            CRUDsAPI.parametro
                .consulta(uuid)
                .then((response) => {
                    setDados(response.data);
                })
                .catch((error) => {
                    console.error(error);
                    setFlashMessage(error.response.data.message, error.response.data.type);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, uuid]);

    async function managerDados(dados) {
        let urlNavigate = pathname;

        try {
            let response;

            if (uuid && uuid !== "null") {
                response = await CRUDsAPI.parametro.editar(dados);
            } else {
                response = await CRUDsAPI.parametro.cadastrar(dados);

                if (response.status === 202) {
                    setDados(response.data);
                    response.data.message = "Registro em duplicidade!";
                    response.data.type = "warning";
                    urlNavigate = pathname + "/CreateOrEdit";
                    params.set("uuid", response.data.uuid);
                }
            }

            setFlashMessage(response.data.message, response.data.type);

            setTimeout(() => {
                navigate(urlNavigate, params);
            }, parametro.msgTempo * 1000);
        } catch (error) {
            console.error(error);
            setFlashMessage(error.response.data.message, error.response.data.type);
        }
    }

    return (
        <section className={`${styles.form_container} ${styles[parametro.tema]}`}>
            <Form
                token={token}
                handleSubmit={managerDados}
                formData={dados}
                btnText={btnText}
                OptionsForma={OptionsForma}
                OptionsPrazo={OptionsPrazo}
                OptionsEmpresa={OptionsEmpresa}
                EnumTipo={EnumTipo}
                caminhoVoltar={pathname}
            />
        </section>
    );
};
export default CreateOrEdit;
