import React, { useEffect, useState, useContext } from "react";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import { Context } from "../../../context/UserContext";
import Form from "./Form";
import styles from "../../form/Form.module.css";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";

//utils
import * as TabelaAPI from "../../../api/tabela";
import * as VerificadoresAPI from "../../../api/verificadores";
import * as EmpresaAPI from "../../../api/empresa";
import * as FinanceiroAPI from "../../../api/financeiro";
import * as PessoaAPI from "../../../api/pessoa";

import { buildFormData } from "../../../utils/FormDataBuilder";

const onlyNumbers = (str) => str.replace(/[^0-9]/g, "");

const CreateOrEdit = () => {
    const [dados, setDados] = useState({});
    const [token] = useState(localStorage.getItem("token")) || "";
    const { parametro } = useContext(Context);
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();
    const urlParams = new URLSearchParams(window.location.search);

    const pathname = "/Pessoa";
    const ul = urlParams.get("ul");
    const li = urlParams.get("li");
    const tipo = urlParams.get("tipo");
    const operacaoUUID = urlParams.get("operacao");
    const uuid = urlParams.get("uuid");
    const btnText = urlParams.get("btnText");

    const params = new URLSearchParams({
        ul: ul,
        li: li,
        tipo: tipo,
        operacao: operacaoUUID,
        uuid: uuid,
        btnText: btnText,
    });

    //define o estado inicial do objeto
    if (!dados.empresaId) {
        dados.empresaId = localStorage.getItem("empresaId");
        dados.ativo = true;
    }

    //enums e tabelas
    //pessoa
    const [OptionsEmpresa, setEmpresa] = useState([]);
    const [EnumTipo, setEnumTipo] = useState([]);
    const [EnumVinculoFinanceiro, setEnumVinculoFinanceiro] = useState([]);
    const [OptionsFormaFornecedor, setFormaFornecedor] = useState([]);
    const [OptionsFormaCliente, setFormaCliente] = useState([]);

    //pessoaNome
    const [EnumOrigem, setEnumOrigem] = useState([]);

    //pessoaFisica
    const [EnumSexo, setEnumSexo] = useState([]);
    const [EnumEstadoCivil, setEnumEstadoCivil] = useState([]);
    const [EnumTipoSanguineo, setEnumTipoSanguineo] = useState([]);
    const [EnumCnhCategoria, setEnumCnhCategoria] = useState([]);
    const [OptionsPais, setPais] = useState([]);

    //pessoaJuridica
    const [EnumRamoAtividade, setEnumRamoAtividade] = useState([]);
    const [EnumPJTipo, setEnumPJTipo] = useState([]);

    //endereços
    const [ufs, setUfs] = useState([]);
    const [municipios, setMUNICIPIO] = useState([]);
    const [EnumTipoEndereco, setEnumTipoEndereco] = useState([]);

    //contatos
    const [EnumTipoEmail, setEnumTipoEmail] = useState([]);
    const [EnumTipoTelefone, setEnumTipoTelefone] = useState([]);

    //const [pessoaEmpresa, setDadosEmpresa] = useState([]);

    //carregar ENUMS
    const consultaEnum = async (table, field, filter) => {
        const result = await TabelaAPI.enums(table, field, filter);
        if (table === "Pessoas") {
            field === "tipo" && setEnumTipo(result ? result : []);
            field === "vinculoFinanceiro" && setEnumVinculoFinanceiro(result ? result : []);
        } else if (table === "pessoaNomes") {
            field === "origem" && setEnumOrigem(result ? result : []);
        } else if (table === "pessoaFisicas") {
            field === "sexo" && setEnumSexo(result ? result : []);
            field === "estadoCivil" && setEnumEstadoCivil(result ? result : []);
            field === "tipoSanguineo" && setEnumTipoSanguineo(result ? result : []);
            field === "cnhCategoria" && setEnumCnhCategoria(result ? result : []);
        } else if (table === "pessoaJuridicas") {
            field === "ramoAtividade" && setEnumRamoAtividade(result ? result : []);
            field === "tipo" && setEnumPJTipo(result ? result : []);
        } else if (table === "pessoaEnderecos") {
            field === "tipo" && setEnumTipoEndereco(result ? result : []);
        } else if (table === "pessoaEmails") {
            field === "tipo" && setEnumTipoEmail(result ? result : []);
        } else if (table === "pessoaTelefones") {
            field === "tipo" && setEnumTipoTelefone(result ? result : []);
        }
    };

    //carregar API's Externas
    const consultaEmpresa = async () => {
        const result = await EmpresaAPI.empresa(dados.empresaId);
        setEmpresa(result);
    };
    const consultaForma = async (tipo, credito, empresaId, descricao, dependente, nivel, formaId) => {
        const result = await FinanceiroAPI.forma(tipo, credito, empresaId, descricao, dependente, nivel, formaId);

        if (tipo === "Pagar") {
            setFormaFornecedor(result);
        } else if (tipo === "Receber") {
            setFormaCliente(result);
        } else {
            //setForma(result); nesse caso não vou precisar de todos
        }
    };
    const consultaUF = async () => {
        const uf = await VerificadoresAPI.uf("");
        setUfs(uf);
    };
    const consultaMUNICIPIO = async (ufId, uf, descricao) => {
        const municipio = await VerificadoresAPI.municipio(ufId, uf, descricao);
        setMUNICIPIO(municipio);
    };
    const consultaPais = async () => {
        const result = await TabelaAPI.retornaPais();
        setPais(result);
    };
    //funções e rotinas acionadas pelo useEffect
    useEffect(() => {
        if (!token) {
            return navigate("/Auth", { replace: true });
        }

        if (uuid && uuid !== "null") {
            PessoaAPI.consulta(uuid)
                .then((response) => {
                    setDados(response.data);
                })
                .catch((error) => {
                    console.error(error);
                    setFlashMessage(error.response.data.message, error.response.data.type);
                });
        }

        //carregar ENUMS
        //pessoa
        consultaEnum("Pessoas", "tipo");
        consultaEnum("Pessoas", "vinculoFinanceiro");
        //pessoaNome
        consultaEnum("pessoaNomes", "origem");
        //pessoaFisica
        consultaEnum("pessoaFisicas", "sexo");
        consultaEnum("pessoaFisicas", "estadoCivil");
        consultaEnum("pessoaFisicas", "tipoSanguineo");
        consultaEnum("pessoaFisicas", "cnhCategoria");
        consultaPais();
        //pessoaJuridica
        consultaEnum("pessoaJuridicas", "ramoAtividade");
        consultaEnum("pessoaJuridicas", "tipo");
        //Endereco
        consultaEnum("pessoaEnderecos", "tipo");
        //email
        consultaEnum("pessoaEmails", "tipo");
        //telefones
        consultaEnum("pessoaTelefones", "tipo");

        //carregar API's Externas
        consultaEmpresa();
        consultaForma("Pagar", false, dados.empresaId, null, null, null, null);
        consultaForma("Receber", false, dados.empresaId, null, null, null, null);
        consultaUF();
        consultaMUNICIPIO();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, uuid]);

    async function managerDados(obj) {
        let urlNavigate = pathname;

        if (!obj.CPForCNPJ.length) {
            obj.CPForCNPJ = onlyNumbers(obj.CPForCNPJ);
        }

        const formData = new FormData();
        buildFormData(obj, formData);

        try {
            let response;

            if (uuid && uuid !== "null") {
                response = await PessoaAPI.editar(formData);
            } else {
                response = await PessoaAPI.cadastrar(formData);

                if (response.status === 202) {
                    setDados(response.data);
                    response.data.message = "Registro em duplicidade!";
                    response.data.type = "warning";
                    urlNavigate = pathname + "/CreateOrEdit";
                    params.set("uuid", response.data.uuid);
                }
            }

            setFlashMessage(response.data.message, response.data.type);

            setTimeout(() => {
                navigate(urlNavigate, params);
            }, parametro.msgTempo * 1000);
        } catch (error) {
            console.error(error);
            setFlashMessage(error.message, "error");
            //setFlashMessage(error.response.data.message, error.response.data.type);
        }
    }

    return (
        <section className={`${styles.form_container} ${styles[parametro.tema]}`}>
            <Form
                token={token}
                handleSubmit={managerDados}
                formData={dados}
                btnText={btnText}
                EnumTipo={EnumTipo}
                EnumVinculoFinanceiro={EnumVinculoFinanceiro}
                EnumOrigem={EnumOrigem}
                EnumSexo={EnumSexo}
                EnumTipoSanguineo={EnumTipoSanguineo}
                EnumEstadoCivil={EnumEstadoCivil}
                EnumCnhCategoria={EnumCnhCategoria}
                EnumRamoAtividade={EnumRamoAtividade}
                EnumPJTipo={EnumPJTipo}
                EnumTipoEndereco={EnumTipoEndereco}
                EnumTipoEmail={EnumTipoEmail}
                EnumTipoTelefone={EnumTipoTelefone}
                ufs={ufs}
                municipios={municipios}
                OptionsEmpresa={OptionsEmpresa}
                OptionsFormaFornecedor={OptionsFormaFornecedor}
                OptionsFormaCliente={OptionsFormaCliente}
                OptionsPais={OptionsPais}
                caminhoVoltar={pathname}
            />
        </section>
    );
};
export default CreateOrEdit;
