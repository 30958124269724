import { useState, useEffect } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "moment/locale/pt-br";

import { Stack, Button } from "@mui/material";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";
import * as TabelaAPI from "../../../api/tabela";

import Input from "../../form/Input";
import TextArea from "../../form/TextArea";
import Checkbox from "../../form/Checkbox";
import SelectEmpresa from "../../form/SelectEmpresa";
import Select from "../../form/Select";
import DataList from "../../form/DataList";

//api
import * as DataListAPI from "../../../api/dataList";
import * as FinanceiroAPI from "../../../api/financeiro";

/* utils */
import { formatarNumeroDecimal } from "../../../utils/masks";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, OptionsEmpresa, OptionsOperacao, OptionsSituacao }) {
    const [dados, setDados] = useState(formData || {});

    const [CFOP, setCFOP] = useState(formData?.CFOP);
    const [OptionsCFOP, setOptionsCFOP] = useState([]);

    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();

    const [enumTipoColaborador, setEnumTipoColaborador] = useState([]);

    const [optionsSituacaoAlmoxarifado, setSituacaoAlmoxarifado] = useState([]);

    if (!dados.ativo) {
        dados.ativo = true;
    }

    const consultaDatalist = async (token, empresaId, tabela, id, descricao, condicao, filtro) => {
        let result = await DataListAPI.dataList(empresaId, tabela, id, descricao, condicao, filtro);
        return result;
    };

    const consultaEnum = async (tabela, campo, setEnum) => {
        const result = await TabelaAPI.enums(tabela, campo);
        if (setEnum === "setEnumTipoColaborador") {
            setEnumTipoColaborador(result ? result : []);
        }
    };
    //Consultas no backend
    const consultaSituacao = async (empresaId, campo) => {
        const result = await FinanceiroAPI.situacao(empresaId, campo);

        const mappedResult = result.map((item) => ({
            text: item.descricao,
            value: item.descricao,
        }));
        campo === "almoxarifado" && setSituacaoAlmoxarifado(result);
    };

    useEffect(() => {
        setDados(formData || {});
        setCFOP(formData.CFOP || {});
        //carrega enums
        consultaEnum("Operacao", "tipoColaborador", "setEnumTipoColaborador");

        //consulta situacao
        consultaSituacao(dados.empresaId, "almoxarifado");
    }, [formData]);

    async function handleChange(e) {
        const camposBooleanos = ["ativo", "entrada", "saida", "balanco", "servico", "industria", "manutencao"];
        const camposNumericos = ["x", "y"];
        if (camposBooleanos.includes(e.target.name)) {
            setDados({ ...dados, [e.target.name]: e.target.checked });
        } else if (camposNumericos.includes(e.target.name)) {
            const valorFormatado = formatarNumeroDecimal(e.target.value);
            setDados({
                ...dados,
                [e.target.name]: valorFormatado,
            });
        } else if (e.target.name.startsWith("CFOP.")) {
            setCFOP({ ...CFOP, [e.target.name.split(".")[1]]: e.target.value });

            let result = await consultaDatalist(token, dados.empresaId, "CFOP", "id", "descricao", "", e.target.value);
            setOptionsCFOP(result);

            if (document.querySelector(`datalist option[value="${e.target.value}"]`)?.dataset.id) {
                setDados({
                    ...dados,
                    cfopId: document.querySelector(`datalist option[value="${e.target.value}"]`)?.dataset.id,
                });
            }
        } else {
            setDados({ ...dados, [e.target.name]: e.target.value });
        }
    }
    async function handleOnBlur(e) {
        //verifica duplicidade
        if (e.target.name === "descricao" && btnText === "Cadastrar") {
            const duplicidade = await TabelaAPI.duplicidadeDescricao(
                document.getElementById("empresaId")?.value || 1,
                "Operacao",
                e.target.value
            );

            if (duplicidade?.uuid) {
                setFlashMessage("Registro em duplicidade!", "warning");
                return navigate(`/Operacao/CreateOrEdit`, {
                    uuid: duplicidade.uuid,
                });
            }
        }
    }
    async function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();

        handleSubmit(dados);
    };

    return (
        <Tabs>
            {" "}
            <form onSubmit={submit}>
                <h1>Operação</h1>
                <TabList>
                    <Tab title={"Paramêtros gerais das operações"}>Operação</Tab>
                    <Tab title={"Paramêtros do modulo de estoque"}>Estoque</Tab>
                    <Tab title={"Paramêtros do modulo financeiro"}>Financeiro</Tab>
                    <Tab title={"Paramêtros do modulo indústria"}>Indústria</Tab>
                </TabList>
                <TabPanel>
                    <div>
                        <SelectEmpresa
                            text="Selecione a Empresa"
                            name="empresaId"
                            options={OptionsEmpresa}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.empresaId}
                            required
                            notOptNull={true}
                        />
                        <Input
                            text="Descrição"
                            type="text"
                            name="descricao"
                            placeholder="Operação"
                            title="Digite o nome do cilindro"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.descricao || ""}
                            required={true}
                        />
                        <Input
                            text="Sigla"
                            type="text"
                            name="sigla"
                            placeholder="Sigla"
                            title="Digite a sigla"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.sigla || ""}
                            required={true}
                        />
                        <Select
                            name="tipoColaborador"
                            title="Selecione o vinculo com o colaborador"
                            text="Tipo Colaborador"
                            inputWidth="150px"
                            options={enumTipoColaborador}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.tipoColaborador}
                            isEnum={true}
                            notOptNull={true}
                            required={true}
                        />
                        <Checkbox
                            title="Ativa quando marcado"
                            text="Ativo"
                            name="ativo"
                            type="checkbox"
                            handleOnChange={handleChange}
                            value={dados.ativo}
                            checked={dados.ativo === true ? "checked" : ""}
                        />
                    </div>
                    <div>
                        {" "}
                        <TextArea
                            text="Aplicação"
                            type="text"
                            name="aplicacao"
                            placeholder="Digite aqui detalhes da operação"
                            title="aplicação da operação"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.aplicacao || ""}
                            textAlign="left"
                            linhas="4"
                            //colunas="122"
                        />
                    </div>
                </TabPanel>
                <TabPanel>
                    <div>
                        <Checkbox
                            title="Define que a operação dar entrada no estoque"
                            text="Entrada"
                            name="entrada"
                            type="checkbox"
                            handleOnChange={handleChange}
                            value={dados.entrada}
                            checked={dados.entrada === true ? "checked" : ""}
                        />
                        <Checkbox
                            title="Define que a operação dar sáida no estoque"
                            text="Saída"
                            name="saida"
                            type="checkbox"
                            handleOnChange={handleChange}
                            value={dados.saida}
                            checked={dados.saida === true ? "checked" : ""}
                        />
                        <Checkbox
                            title="Operação de balanço atua com entradas ou saídas no estoque, conforme a necessidade do balanço"
                            text="Balanço"
                            name="balanco"
                            type="checkbox"
                            handleOnChange={handleChange}
                            value={dados.balanco}
                            checked={dados.balanco === true ? "checked" : ""}
                        />
                        <Checkbox
                            title="Define que a operação é um serviço"
                            text="Serviço"
                            name="servico"
                            type="checkbox"
                            handleOnChange={handleChange}
                            value={dados.servico}
                            checked={dados.servico === true ? "checked" : ""}
                        />
                        <Checkbox
                            title="Define que a operação é de manutenção"
                            text="Manutenção"
                            name="manutencao"
                            type="checkbox"
                            handleOnChange={handleChange}
                            value={dados.manutencao}
                            checked={dados.manutencao === true ? "checked" : ""}
                        />
                    </div>
                </TabPanel>
                <TabPanel>
                    <div></div>
                </TabPanel>
                <TabPanel>
                    <div>
                        <Checkbox
                            title="Define que a operação é utilizada no modulo de indústria"
                            text="Indústria"
                            name="industria"
                            type="checkbox"
                            handleOnChange={handleChange}
                            value={dados.industria}
                            checked={dados.industria === true ? "checked" : ""}
                        />
                    </div>
                </TabPanel>
                <div>
                    <Stack direction="row" spacing={1}>
                        <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                            Voltar
                        </Button>
                        <Button type="submit" variant="contained" color="success">
                            {btnText}
                        </Button>
                    </Stack>
                </div>
            </form>
        </Tabs>
    );
}
export default Form;
