import { useState, useEffect, useContext, useMemo } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

/* contexts */
import { Context } from "../../../context/UserContext";

import { useDispatch } from "react-redux";
import { selectComponent, clearSelectedComponent } from "../../../store/reducers/actions";

import moment from "moment";
import "moment/locale/pt-br";

import { Stack, Button, Box, Tooltip, IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import MaterialReactTable from "material-react-table";

import { ThemeProvider } from "@mui/material";
/* tema customizado*/
import tableThemeCustom from "../../form/tableThemeCustom";

//Import Material React Table Translations
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

/* utils */
import { formatarNumero, formatarNumeroDecimal } from "../../../utils/masks";
import { retornaData, retornaDataHora } from "../../../utils/retornaDataFormatada";
import maskCEP from "../../../utils/maskCEP";

import Input from "../../form/Input";
import InputNumber from "../../form/InputNumber";
import Select from "../../form/Select";
import Checkbox from "../../form/Checkbox";
import DataList from "../../form/DataList";
import SelectEmpresa from "../../form/SelectEmpresa";
import TextArea from "../../form/TextArea";

import formStyles from "../../form/Form.module.css";

/* apis */
import * as DataListAPI from "../../../api/dataList";
import * as ProdutoAPI from "../../../api/produtoLIM";
import * as TabelaAPI from "../../../api/tabela";
import * as VerificadoresAPI from "../../../api/verificadores";
import * as NCMAPI from "../../../api/NCM";
import * as EmbalagemAPI from "../../../api/embalagem";
import * as CotacaoAPI from "../../../api/cotacao";

/* util */
import { obterPermissoesUsuario } from "../../../utils/funcoes";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, OptionsEmpresa, OptionsEmbalagem }) {
    const onlyNumbers = (str) => str.replace(/[^0-9]/g, "");

    const [dados, setDados] = useState({});
    const [itensEmbalagens, setItensEmbalagens] = useState([]); //grid da embalagem
    const [itensInventario, setItensInventario] = useState([]); //grid do inventario

    const [preview, setPreview] = useState([]);
    const [previewInvNF, setPreviewInvNF] = useState([]);
    const [previewInvIMG, setPreviewInvIMG] = useState([]);

    const [optionsAmbiente, setOptionsAmbiente] = useState([]);

    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();
    const { parametro } = useContext(Context);

    const dispatch = useDispatch();

    const urlParams = new URLSearchParams(window.location.search);

    const ul = urlParams.get("ul") || 2;
    const li = urlParams.get("li") || 46;

    //set datalist
    const [NCM, setNCM] = useState(formData?.NCM);
    const [OptionsNCM, setOptionsNCM] = useState([]);
    const [Fabricante, setFabricante] = useState({});
    const [produtoBens, setBem] = useState({});
    const [NCMInventario, setNCMInventario] = useState({});
    const [OptionsNCMInventario, setOptionsNCMInventario] = useState([]);

    /* Empresa usa tabela comum ou propria */
    let empresaId = 1;
    if (parametro.tabelasComuns === false) {
        empresaId = localStorage.getItem("empresaId");
    }

    if (!dados.empresaId) {
        dados.ativo = true;
        dados.empresaId = parseInt(localStorage.getItem("empresaId"));
        produtoBens.ativo = true;
    }

    // eslint-disable-next-line
    const [produtoBensInventarios, setProdutoBensInventario] = useState([]);

    const [produtoBensInventarioImagens, setProdutoBensInventarioImagens] = useState([]);
    const [produtoBensInventarioNFs, setProdutoBensInventarioNF] = useState([]);

    const [Proprietario, setProprietario] = useState({});
    const [OptionsProprietario, setOptionsProprietario] = useState([]);

    const [produtoImagems, setProdutoImagems] = useState([]);

    const [OptionsFabricante, setOptionsFabricante] = useState([]);

    const [produtoEmbalagens, setProdutoEmbalagem] = useState([]);

    //tratamento da imagem do produto
    const [divImageProduto, setDivImageProduto] = useState(false);
    const [novaImagemProduto, setNovaImagemProduto] = useState({
        produtoId: dados.id,
        descricao: "",
        uuid: "",
        ativo: true,
    });
    //tratamento da mobilia
    const [divImage, setDivImage] = useState(false);
    const [divPDF, setDivPDF] = useState(false);
    const [divImovel, setDivImovel] = useState(true);

    const [Ambiente, setAmbiente] = useState({});

    // Estado para rastrear a aba ativa
    const [abaAtiva, setAbaAtiva] = useState(0);
    const [gridAtiva, setGridAtiva] = useState();

    /* identifica a aba atual */
    const handleSelect = (index) => {
        setAbaAtiva(index);

        // Definir qual grid será atualizada com base na aba ativa
        switch (index) {
            case 0:
                setGridAtiva("produto");
                break;
            case 1:
                setGridAtiva("embalagens");
                break;
            case 2:
                setGridAtiva("bens");
                break;
            case 3:
                setGridAtiva("mobilia");
                break;
            case 4:
                setGridAtiva("imagens");
                break;
            default:
                setGridAtiva("bens");
        }
    };

    //abas
    const [tabEnabled, setTabEnabled] = useState({
        produto: true,
        embalagens: true,
        bens: true,
        mobilia: false,
        imagens: true,
    });

    //set enums
    const [EnumTipo, setTipo] = useState(formData?.OptionsTipo || {});
    const [EnumBemTipo, setBemTipo] = useState(formData?.OptionsBemTipo || {});
    const [optionsUF, setUF] = useState([]);
    const [optionsMunicipios, setMunicipio] = useState([]);
    const [EnumPosicao, setEnumPosicao] = useState();

    //carregar ENUMS
    const consultaEnum = async (table, field, filter) => {
        const result = await TabelaAPI.enums(table, field, filter);
        if (table === "Produtos") {
            const filteredResult = result ? result.filter((item) => item === "Imobilizado" || item === "Material") : []; //filtra os resutados
            field === "tipo" && setTipo(result ? filteredResult : []);
        } else if (table === "produtoBens") {
            const filteredResults = result ? result.filter((item) => item === "Imóvel") : []; //filtra os resutados
            field === "tipo" && setBemTipo(result ? filteredResults : []);
        } else if (table === "produtoBensInventarioImagens") {
            field === "posicao" && setEnumPosicao(result ? result : []);
        }
    };

    //carrega APIs
    const consultaUF = async () => {
        let uf = await VerificadoresAPI.uf("");
        setUF(uf);
    };
    const consultaMUNICIPIO = async () => {
        let municipio = await VerificadoresAPI.municipio(produtoBens.ufId, produtoBens.uf, null);
        setMunicipio(municipio);
    };
    const consultaCEP = async (cep) => {
        const result = await VerificadoresAPI.cep(cep);

        if (result.type === "error") {
            setFlashMessage(result.message, result.type);
        } else {
            setBem({
                ...produtoBens,
                ufId: result.ufId,
                municipioId: result.municipioId,
                bairro: result.neighborhood,
                logradouro: result.street,
            });
        }
        consultaMUNICIPIO(result.ufId);
    };

    const consultaTabelaById = async (empresaId, tabela, id) => {
        const result = await TabelaAPI.tabelaById(empresaId, tabela, id);

        if (tabela === "Ambiente") {
            setAmbiente(result);
        }
    };

    const consultaTabela = async (empresaId, tabela, order) => {
        const result = await TabelaAPI.tabela(empresaId, tabela, order);
        if (tabela === "Ambiente") {
            setOptionsAmbiente(result);
        }
    };
    const consultaNCMById = async (id, campo = null) => {
        const result = await NCMAPI.ncmById(id);

        if (!campo || campo === "NCM") {
            setNCM(result);
            setDados({
                ...dados,
                NCM: result,
            });
        } else if (campo === "NCMInventario") {
            setNCMInventario(result);
            setNovaMobilia((prev) => ({ ...prev, NCM: result }));
        }
    };

    //Atualiza o Datalist
    const consultaDatalist = async (token, empresaId, tabela, id, descricao, condicao, filtro) => {
        let result = await DataListAPI.dataList(empresaId, tabela, id, descricao, condicao, filtro);
        return result;
    };

    const consultaDatalistProprietario = async (empresaId, filtro, locador, locatario, fiador, funcionarioId) => {
        const result = await DataListAPI.dataListParceiroImovel(empresaId, filtro, locador, locatario, fiador, funcionarioId);
        return result;
    };

    const consultaCotacao = async (dia) => {
        const response = await CotacaoAPI.buscaCotacaoByData(dia);
        if (response.status === 200) {
            setNovaMobilia((prev) => ({
                ...prev,
                cotacaoId: response.data[0]?.id ? response.data[0]?.id : null,
            }));
        }
    };

    //rotina para criar objetos de tabelas filhas
    const [novaEmbalagem, setNovaEmbalagem] = useState({
        produtoId: dados.id,
        empresaId: dados.empresaId,
        empresa: "",
        embalagemId: "",
        embalagem: "",
        uuid: "",
        codigoBarras: "",
        ativo: true,
        funcao: "adicionar",
    });

    const [novaMobilia, setNovaMobilia] = useState({
        produtoBensId: produtoBens.id,
        ambienteId: produtoBensInventarios.ambienteId,
        descricao: "",
        valor: "",

        dataCompra: retornaData(moment()),
        ncmId: null,
        cotacaoId: null,

        uuid: "",
        Ambiente: Ambiente,
        NCM: NCMInventario || [],
        invImages: [],
        invNFs: [],
        ativo: true,
        funcao: "adicionar",
    });

    //tratamento das imagens do produto do inventário e da nota fiscal
    const [novaImagem, setNovaImagem] = useState({
        produtoBensInventarioId: produtoBensInventarios.id,
        posicao: "",
        uuid: "",
        ativo: true,
    });
    const [novaPDF, setNovaPDF] = useState({
        produtoBensInventarioId: produtoBensInventarios.id,
        uuid: "",
        ativo: true,
    });

    useEffect(() => {
        setDados(formData || {});
        setFabricante(formData?.Fabricante || {});

        setProdutoEmbalagem(formData?.produtoEmbalagens || []); //depois remover
        setItensEmbalagens(formData?.produtoEmbalagens || []); //atualiza a grid de embalagens

        setNCM(formData.NCM || {});

        setBem(formData?.produtoBens || { ...produtoBens, tipo: "Imóvel" });
        // setItensInventario(
        //     Array.isArray(formData?.produtoBens?.produtoBensInventarios) ? formData.produtoBens.produtoBensInventarios : []
        // );
        // setRowCount(
        //     Array.isArray(formData?.produtoBens?.produtoBensInventarios)
        //         ? formData?.produtoBens?.produtoBensInventarios.length
        //         : 0
        // );

        setProdutoImagems(formData?.produtoImagems || []);

        setTipo(formData?.OptionsTipo);
        setBemTipo(formData?.OptionsBemTipo);
        setProprietario(formData?.produtoBens?.Proprietario);

        setTabEnabled({
            produto: true,
            embalagens: true,
            bens: true,
            imagens: true,
        });

        //enums
        consultaEnum("Produtos", "tipo");
        consultaEnum("produtoBens", "tipo");
        consultaEnum("produtoBensInventarioImagens", "posicao");

        //carregar API's Externas
        consultaUF();
        consultaMUNICIPIO();

        consultaTabela(parametro.tabelasComuns === true ? 1 : dados.empresaId, "Ambiente", "descricao");

        btnText === "Cadastrar" && (dados.ncmId = 637); //ncm padrão para obras
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData, btnText]);

    useEffect(() => {
        setNovaEmbalagem({ ...novaEmbalagem, produtoId: dados.id });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dados.id]);

    useEffect(() => {
        dados.ncmId && consultaNCMById(dados.ncmId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dados.ncmId]);

    useEffect(() => {
        if (novaMobilia.dataCompra && novaMobilia.dataCompra.length === 10) {
            const [ano, mes, dia] = novaMobilia.dataCompra.split("-").map(Number); // ou formato "YYYY-MM-DD"
            if (ano > 2000) {
                consultaCotacao(novaMobilia.dataCompra);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [novaMobilia.dataCompra]);

    //atualiza tabelas filhas
    useEffect(() => {
        if (produtoBens.mobiliado === true) {
            setTabEnabled({ ...tabEnabled, mobilia: true });
        } else {
            setTabEnabled({ ...tabEnabled, mobilia: false });
        }
        setDados((prev) => {
            return {
                ...prev,
                produtoBens: produtoBens,
                produtoEmbalagens: produtoEmbalagens,
            };
        });
        // eslint-disable-next-line
    }, [produtoBens, produtoEmbalagens]);

    async function onFileChange(e) {
        if (e.target.name === "images") {
            setPreview(Array.from(e.target.files));
            setDados({ ...dados, images: [...e.target.files] });
        } else if (e.target.name === "novaMobilia.invImages") {
            setPreviewInvIMG(Array.from(e.target.files));
            // Atualiza produtoBensInventarioImagem com as novas imagens
            setProdutoBensInventarioImagens({ ...produtoBensInventarioImagens, images: [...e.target.files] });
            setNovaMobilia({ ...novaMobilia, invImages: [...e.target.files] }); // Usando 'invImages' para imagens
        } else if (e.target.name === "novaMobilia.invNF") {
            setPreviewInvNF(Array.from(e.target.files));
            // Atualiza produtoBensInventarioNF com as novas notas fiscais pdf
            setProdutoBensInventarioNF({ ...produtoBensInventarioNFs, pdfs: [...e.target.files] }); // Usando 'pdfs' para PDFs
            setNovaMobilia({ ...novaMobilia, invNFs: [...e.target.files] }); // Usando 'invNFs' para PDFs
        }
    }
    async function handleChange(e) {
        const camposMonetarios = [
            "comissao",
            "pesoBruto",
            "pesoLiquido",
            "estoqueMinimo",
            "producaoMinima",
            "pesoMolecular",
            "toleranciaMinima",
            "toleranciaMaxima",
        ];
        const camposBooleanos = [
            "ativo",
            "compra",
            "venda",
            "locavel",
            "embalagem",
            "laminado",
            "sanfonado",
            "separacao",
            "refugo",
            "lavagem",
            "producao",
            "extrusao",
            "finalizaExtrusora",
            "recuperadora",
            "corrugadora",
            "impressora",
            "corteSolda",
        ];

        if (e.target.name.startsWith("Proprietario.")) {
            setProprietario({
                ...Proprietario,
                [e.target.name.split(".")[1]]: e.target.value,
            });
            if (e.target.name === "Proprietario.descricao") {
                //carrega o datalist
                if (e.target.value?.length > 0) {
                    const result = await consultaDatalistProprietario(dados.empresaId, e.target.value, true, null, null, null);
                    setOptionsProprietario(result);
                }
                const proprietarioId = document.querySelector(`datalist option[value="${e.target.value}"]`)?.dataset.id;

                if (proprietarioId) {
                    setBem((prev) => ({ ...prev, proprietarioId: proprietarioId }));
                }
            }
        } else if (e.target.name === "tipo") {
            setDados({ ...dados, [e.target.name]: e.target.value });

            if (e.target.value === "Imobilizado" || e.target.value === "Material") {
                setTabEnabled({
                    ...tabEnabled,
                    bens: true,
                });
            } else {
                setTabEnabled({
                    ...tabEnabled,
                    bens: false,
                });
            }
        } else if (camposBooleanos.includes(e.target.name)) {
            setDados({ ...dados, [e.target.name]: e.target.checked });
        } else if (e.target.name.startsWith("NCM.")) {
            setNCM({
                ...NCM,
                [e.target.name.split(".")[1]]: e.target.value,
            });
            if (e.target.name.startsWith("NCM.descricao")) {
                //carrega o datalist
                if (e.target.value?.length > 3) {
                    let result = await consultaDatalist(token, empresaId, "NCM", "id", "descricao", "", e.target.value);
                    setOptionsNCM(result);
                }

                setDados({
                    ...dados,
                    ncmId: document.querySelector(`datalist option[value="${e.target.value}"]`)?.dataset.id,
                });

                // if (document.querySelector(`datalist option[value="${e.target.value}"]`)?.value) {
                //     const arr = document.querySelector(`datalist option[value="${e.target.value}"]`)?.value?.split(" ");
                //     setNCM({ ncm: arr[0] });
                // }
            }
        } else if (e.target.name.startsWith("NCMInventario.")) {
            setNCMInventario({
                ...NCMInventario,
                [e.target.name.split(".")[1]]: e.target.value,
            });
            if (e.target.name.startsWith("NCMInventario.descricao")) {
                //carrega o datalist
                if (e.target.value?.length > 3) {
                    let result = await consultaDatalist(token, empresaId, "NCM", "id", "descricao", "", e.target.value);
                    setOptionsNCMInventario(result);
                }
                const ncmId = document.querySelector(`datalist option[value="${e.target.value}"]`)?.dataset.id;
                setNovaMobilia((prev) => ({
                    ...novaMobilia,
                    ncmId: ncmId,
                }));
            }
        } else if (e.target.name.startsWith("Fabricante")) {
            setFabricante({
                ...Fabricante,
                [e.target.name.split(".")[1]]: e.target.value,
            });

            if (e.target.value?.length > 2) {
                let result = await consultaDatalist(
                    token,
                    dados.empresaId,
                    "Pessoa",
                    "id",
                    "descricao",
                    "fabricante",
                    e.target.value
                );
                setOptionsFabricante(result);
            }

            setDados({
                ...dados,
                fabricanteId: document.querySelector(`datalist option[value="${e.target.value}"]`)?.dataset.id,
            });
        } else if (camposMonetarios.includes(e.target.name)) {
            const valorFormatado = formatarNumeroDecimal(e.target.value);
            setDados({ ...dados, [e.target.name]: valorFormatado });
        } else if (e.target.name.startsWith("produtoBens.")) {
            if (e.target.name === "produtoBens.cep") {
                setBem({
                    ...produtoBens,
                    [e.target.name.split(".")[1]]: onlyNumbers(e.target.value),
                });
            }
            //para checkbox
            let valor = e.target.type === "checkbox" ? e.target.checked : e.target.value;
            setBem({
                ...produtoBens,
                [e.target.name.split(".")[1]]: valor,
            });
        } else if (e.target.name.startsWith("novaEmbalagem.")) {
            // Verifica se é um checkbox e define o valor correspondente
            let valor = e.target.type === "checkbox" ? e.target.checked : e.target.value;

            setNovaEmbalagem({
                ...novaEmbalagem,
                [e.target.name.split(".")[1]]: valor,
                empresa:
                    document.getElementById("novaEmbalagem.empresaId").options[
                        document.getElementById("novaEmbalagem.empresaId").selectedIndex
                    ].text,
                embalagem:
                    document.getElementById("novaEmbalagem.embalagemId").options[
                        document.getElementById("novaEmbalagem.embalagemId").selectedIndex
                    ].text,
            });
        } else if (e.target.name.startsWith("novaMobilia.")) {
            // Verifica se é um checkbox e define o valor correspondente
            let valor = e.target.type === "checkbox" ? e.target.checked : e.target.value;
            let cotacaoId = null;

            if (e.target.name === "novaMobilia.valor") {
                valor = formatarNumeroDecimal(valor);
            } else if (e.target.name === "novaMobilia.ambienteId") {
                await consultaTabelaById(parametro.tabelasComuns === true ? 1 : dados.empresaId, "Ambiente", e.target.value);
            } else if (e.target.name === "novaMobilia.dataCompra") {
            }
            const ambiente =
                document.getElementById("novaMobilia.ambienteId").options[
                    document.getElementById("novaMobilia.ambienteId").selectedIndex
                ].text;

            setNovaMobilia((prev) => ({
                ...prev,
                produtoBensId: produtoBens.id,
                [e.target.name.split(".")[1]]: valor,
                ambiente,
            }));
        } else if (e.target.name.startsWith("novaImagem.")) {
            const valor = e.target.type === "checkbox" ? e.target.checked : e.target.value;
            const campo = e.target.name.split(".")[1];

            setNovaImagem({ ...novaImagem, [campo]: valor });
        } else if (e.target.name.startsWith("novaPDF.")) {
            const valor = e.target.type === "checkbox" ? e.target.checked : e.target.value;
            const campo = e.target.name.split(".")[1];

            setNovaPDF({ ...novaPDF, [campo]: valor });
        } else if (e.target.name.startsWith("novaImagemProduto.")) {
            const valor = e.target.type === "checkbox" ? e.target.checked : e.target.value;
            const campo = e.target.name.split(".")[1];

            setNovaImagemProduto({ ...novaImagemProduto, [campo]: valor });
        } else {
            if (e.target.name === "descricao") {
                setBem({ ...produtoBens, [e.target.name]: e.target.value });
            }
            setDados({ ...dados, [e.target.name]: e.target.value });
        }
    }
    async function handleOnBlur(e) {
        if (e.target.name === "descricao") {
            //verifica duplicidade
            if (e.target.name === "descricao" && btnText === "Cadastrar") {
                //verifica se o centroCusto existe na base de dados
                const duplicidade = await TabelaAPI.duplicidadeDescricao(
                    document.getElementById("empresaId").value,
                    "Produto",
                    e.target.value
                );

                if (duplicidade?.uuid) {
                    setFlashMessage("Registro em duplicidade!", "warning");
                    return navigate(`/ImoveL/CreateOrEdit`, {
                        uuid: duplicidade.uuid,
                    });
                }
            }
        }
        //localiza endereço
        if (e.target.name === "produtoBens.cep") {
            if (e.target.value) {
                produtoBens.cep = onlyNumbers(e.target.value);
            }

            if (produtoBens.cep?.length === 8) {
                consultaCEP(produtoBens.cep, token);
            } else if (produtoBens.cep?.length > 0 && produtoBens.cep?.length !== 8) {
                setFlashMessage("CEP inválido!!", "error");
            }
        }
    }
    async function handleOnFocus(e) {
        // if (e.target.name === "produtoBens.descricao") {
        //     setBem({
        //         ...produtoBens,
        //         descricao: dados.descricao,
        //     });
        // }
    }
    const submit = async (e) => {
        e.preventDefault();
        if (!produtoBens.tipo) {
            produtoBens.tipo = document.getElementById("produtBens.tipo").value;
        }
        if (!dados.empresaId) {
            dados.empresaId = parseInt(localStorage.getItem("empresaId"));
        }
        if (!dados.tipo) {
            const tipoElement = document.getElementById("tipo");
            dados.tipo = tipoElement ? tipoElement.value : "Imobilizado";
        }
        //validações
        if (!dados.descricao) {
            setFlashMessage("A descrição do imóvel é um campo obrigatório!", "error");
            return;
        }
        if (!dados.ncmId) {
            setFlashMessage("NCM é um campo obrigatório!", "error");
            return;
        }

        handleSubmit(dados);
    };

    //data and fetching state
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCountEmbalagem, setRowCountEmbalagem] = useState(0);
    const [rowCount, setRowCount] = useState(0);

    //table state
    const [columnFilterFns, setColumnFiltersMode] = useState(
        localStorage.columnFilterFns.length > 2 ? JSON.parse(localStorage.columnFilterFns) : []
    );
    const [columnFilters, setColumnFilters] = useState(
        localStorage.columnFilters.length > 2 ? JSON.parse(localStorage.columnFilters) : []
    );
    const [globalFilter, setGlobalFilter] = useState(
        localStorage.globalFilter.length > 2 ? JSON.parse(localStorage.globalFilter) : ""
    );
    const [sorting, setSorting] = useState(localStorage.sorting.length > 2 ? JSON.parse(localStorage.sorting) : []);
    const [pagination, setPagination] = useState({
        pageIndex: JSON.parse(localStorage.pagination).pageIndex || 0,
        pageSize: JSON.parse(localStorage.pagination).pageSize || parametro.paginacao,
    });

    /* modo tema */
    //const globalTheme = useTheme();
    //const theme = tableTheme(globalTheme);
    const theme = tableThemeCustom();

    const columnsEmbalagem = useMemo(
        () => [
            {
                id: "embalagem",
                header: "Unidade de venda do imóvel",
                columns: [
                    { accessorKey: "Empresa.nomeFantasia", header: "Empresa" },
                    {
                        accessorKey: "Embalagem.descricao",
                        header: "Descrição",
                        //you can access a cell in many callback column definition options like this
                        muiTableBodyCellProps: ({ cell }) => ({
                            onClick: () => {},
                        }),
                    },
                    {
                        accessorKey: "codigoBarras",
                        header: "Código de Barras",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                    },
                    {
                        accessorKey: "ativo",
                        header: "Ativo",
                        columnFilterModeOptions: ["equals"],
                        filterSelectOptions: [
                            { text: "Todos", value: "" },
                            { text: "Ativo", value: 1 },
                            { text: "Inativo", value: 0 },
                        ],
                        filterVariant: "select",
                        muiTableBodyCellProps: ({ row }) => ({
                            align: "center",
                            onClick: () => {},
                        }),
                        Cell: ({ row }) => {
                            return row.original.ativo ? "Ativo" : "Inativo";
                        },
                    },
                ],
            },

            //column definitions...
        ],
        [] //getCommonEditTextFieldProps
    );
    const columnsInventario = useMemo(
        () => [
            {
                id: "inventario",
                header: "Itens do Inventário",
                columns: [
                    { accessorKey: "Ambiente.descricao", id: "Ambiente.descricao", header: "Ambiente" },
                    {
                        accessorKey: "descricao",
                        id: "descricao",
                        header: "Descrição",
                        //you can access a cell in many callback column definition options like this
                        muiTableBodyCellProps: ({ cell }) => ({
                            onClick: () => {},
                        }),
                    },
                    {
                        accessorKey: "valor",
                        header: "Valor",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valorUnitario === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.valor)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "ativo",
                        header: "Ativo",
                        columnFilterModeOptions: ["equals"],
                        filterSelectOptions: [
                            { text: "Todos", value: "" },
                            { text: "Ativo", value: 1 },
                            { text: "Inativo", value: 0 },
                        ],
                        filterVariant: "select",
                        muiTableBodyCellProps: ({ row }) => ({
                            align: "center",
                            onClick: () => {},
                        }),
                        Cell: ({ row }) => {
                            return row.original.ativo ? "Ativo" : "Inativo";
                        },
                    },
                    {
                        accessorKey: "produtoBensInventarioNFs[0].descricao",
                        header: "PDF",
                        muiTableBodyCellProps: ({ row }) => {
                            const hasDescricao = row?.original?.produtoBensInventarioNFs?.[0]?.descricao;

                            return {
                                align: "center",
                                onClick: () => {
                                    if (hasDescricao) {
                                        navigator.clipboard
                                            .writeText(hasDescricao)
                                            .then(() => {
                                                setFlashMessage(
                                                    "Nome do arquivo copiado para a área de transferência, agora selecione os itens da mesma nota fiscal",
                                                    "info"
                                                );
                                            })
                                            .catch((err) => {
                                                console.error("Erro ao copiar: ", err);
                                                setFlashMessage("Erro ao copiar nome do arquivo", "error");
                                            });
                                    } else {
                                        setFlashMessage("Nenhuma descrição disponível para copiar", "warning");
                                    }
                                },
                                title: hasDescricao
                                    ? `click para copiar o nome do arquivo ${hasDescricao}`
                                    : "Sem PDF disponível",
                                style: {
                                    cursor: hasDescricao ? "pointer" : "default",
                                },
                            };
                        },
                        Cell: ({ row }) => {
                            return row.original.produtoBensInventarioNFs?.[0]?.descricao || "Sem PDF";
                        },
                    },
                ],
            },
        ], //eslint-disable-next-line
        [] //getCommonEditTextFieldProps
    );

    //atualização da grid
    useEffect(() => {
        if (!token) {
            return navigate("/Auth", { replace: true });
        }

        //funcao para setar localStorage
        localStorage.setItem(
            "pagination",
            JSON.stringify({
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
            })
        );
        localStorage.setItem("columnFilterFns", JSON.stringify(columnFilterFns));
        localStorage.setItem("columnFilters", JSON.stringify(columnFilters));
        localStorage.setItem("globalFilter", JSON.stringify(globalFilter));
        localStorage.setItem("sorting", JSON.stringify(sorting));

        //permissões do usuario
        obterPermissoesUsuario(localStorage.getItem("pessoaId"), localStorage.getItem("empresaId"), ul, li);

        fetchData(gridAtiva);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, columnFilterFns, columnFilters, globalFilter, pagination.pageIndex, pagination.pageSize, sorting, gridAtiva]);

    const fetchData = async (grid) => {
        //setFlashMessage(grid, "warning");
        if (!itensInventario.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }

        if (dados.id) {
            if (grid === "embalagens") {
                try {
                    EmbalagemAPI.listarEmbalagens(
                        pagination.pageIndex * pagination.pageSize,
                        pagination.pageSize,
                        JSON.stringify(columnFilters ?? []),
                        JSON.stringify(columnFilterFns ?? []),
                        globalFilter ?? "",
                        JSON.stringify(sorting ?? []),
                        dados.id ?? ""
                    ).then((response) => {
                        setItensEmbalagens(response.data.data);
                        setRowCountEmbalagem(response.data.meta.totalRowCount);
                    });
                } catch (error) {
                    setIsError(true);
                    console.error(error);
                    return;
                }
            } else if (grid === "mobilia") {
                try {
                    ProdutoAPI.listarInventario(
                        pagination.pageIndex * pagination.pageSize,
                        pagination.pageSize,
                        JSON.stringify(columnFilters ?? []),
                        JSON.stringify(columnFilterFns ?? []),
                        globalFilter ?? "",
                        JSON.stringify(sorting ?? []),
                        produtoBens.id ?? ""
                    ).then((response) => {
                        setItensInventario(response.data.data);
                        setRowCount(response.data.meta.totalRowCount);
                    });
                } catch (error) {
                    setIsError(true);
                    console.error(error);
                    return;
                }
            } else if (grid === "imagens") {
                try {
                    ProdutoAPI.listarImagem(
                        pagination.pageIndex * pagination.pageSize,
                        pagination.pageSize,
                        JSON.stringify(columnFilters ?? []),
                        JSON.stringify(columnFilterFns ?? []),
                        globalFilter ?? "",
                        JSON.stringify(sorting ?? []),
                        dados.id ?? ""
                    ).then((response) => {
                        setProdutoImagems(response.data.data);
                    });
                } catch (error) {
                    setIsError(true);
                    console.error(error);
                    return;
                }
            }
        }

        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
    };

    const handleEmbalagem = async () => {
        // Função auxiliar para resetar novaEmbalagem
        const resetNovaEmbalagem = () => ({
            produtoId: dados.id,
            empresaId: novaEmbalagem.empresaId,
            embalagemId: novaEmbalagem.embalagemId,
            uuid: "",
            codigoBarras: "",
            ativo: true,
            funcao: "adicionar",
        });

        // Verifica e define campos não preenchidos
        novaEmbalagem.produtoId = novaEmbalagem.produtoId || parseInt(dados.id);
        novaEmbalagem.embalagemId =
            novaEmbalagem.embalagemId || parseInt(document.getElementById("novaEmbalagem.embalagemId").value);
        novaEmbalagem.empresaId = novaEmbalagem.empresaId || parseInt(document.getElementById("novaEmbalagem.empresaId").value);

        if (dados.id) {
            const result = await CreateOrEditEmbalagem(novaEmbalagem);

            if (result.status === 202) {
                setFlashMessage("Embalagem já cadastrada!", "info");
            } else {
                setFlashMessage(result.message, result.type);

                if (result.type === "success") {
                    setProdutoEmbalagem(result.resultado);
                }

                setNovaEmbalagem(resetNovaEmbalagem());
            }

            fetchData("embalagens");
        } else {
            const empresa = await consultaTabelaById(null, "Empresa", novaEmbalagem.empresaId);
            const embalagem = await consultaTabelaById(novaEmbalagem.empresaId, "Embalagem", novaEmbalagem.embalagemId);

            if (novaEmbalagem.funcao === "editar") {
                if (novaEmbalagem.uuid) {
                    await CreateOrEditEmbalagem(novaEmbalagem);
                } else {
                    setItensEmbalagens((prevItens) => {
                        const novosItens = [...prevItens];
                        novosItens[novaEmbalagem.index] = { ...prevItens[novaEmbalagem.index], ...novaEmbalagem };
                        return novosItens;
                    });
                    setRowCount(itensEmbalagens.length);
                }
            } else if (novaEmbalagem.funcao === "deletar") {
                if (novaEmbalagem.uuid) {
                    try {
                        const response = await EmbalagemAPI.deletarEmbalagem(novaEmbalagem);
                        setFlashMessage(response.message, response.type);
                    } catch (error) {
                        console.error(error);
                        setFlashMessage(error.response.message, error.data.type);
                    }
                } else {
                    setItensEmbalagens((prevItens) => {
                        const novosItens = [...prevItens];
                        novosItens.splice(novaEmbalagem.index, 1);
                        return novosItens;
                    });
                }
            } else {
                if (dados.id) {
                    novaEmbalagem.produtoId = dados.id;

                    const result = await EmbalagemAPI.duplicidadeCodigobarras(
                        novaEmbalagem.empresaId,
                        novaEmbalagem.codigoBarras
                    );

                    if (result && novaEmbalagem.funcao === "adicionar") {
                        setFlashMessage("Código de barras em duplicidade!", "warning");
                        handleEditarEmbalagem(result);
                        return;
                    }

                    await CreateOrEditEmbalagem(novaEmbalagem);
                } else {
                    const codigoBarrasExiste = itensEmbalagens.some(
                        (item) => item.codigoBarras === novaEmbalagem.codigoBarras && item.empresaId === novaEmbalagem.empresaId
                    );

                    if (codigoBarrasExiste) {
                        setFlashMessage("Código de barras já cadastrado para o produto!", "warning");
                        return;
                    }

                    const result = await EmbalagemAPI.duplicidadeCodigobarras(
                        novaEmbalagem.empresaId,
                        novaEmbalagem.codigoBarras
                    );

                    if (result && novaEmbalagem.funcao === "adicionar") {
                        setFlashMessage("Código de barras em duplicidade!", "warning");
                        return;
                    }

                    const novoItemArray = [
                        ...itensEmbalagens,
                        {
                            uuid: novaEmbalagem.uuid,
                            produtoId: novaEmbalagem.produtoId || dados.Id,
                            empresaId: novaEmbalagem.empresaId,
                            embalagemId: novaEmbalagem.embalagemId,
                            codigoBarras: novaEmbalagem.codigoBarras,
                            ativo: novaEmbalagem.ativo,
                            Empresa: empresa,
                            Embalagem: embalagem,
                        },
                    ];

                    setItensEmbalagens(novoItemArray);
                    setRowCountEmbalagem(novoItemArray.length);
                    setProdutoEmbalagem(novoItemArray);
                    setDados((prev) => ({
                        ...prev,
                        produtoEmbalagens: novoItemArray,
                    }));
                }
            }

            setNovaEmbalagem(resetNovaEmbalagem());
        }
    };
    const CreateOrEditEmbalagem = async (novaEmbalagem) => {
        try {
            const result = await EmbalagemAPI.managerProdutoEmbalagem(novaEmbalagem);
            setFlashMessage(result.message, result.type);
            fetchData("embalagens");
            return result;
        } catch (error) {
            console.error(error);
            if (error.response && error.response.status === 422) {
                // Tratar o erro 422, que é específico da validação no servidor
                setFlashMessage("Erro de validação: " + error.response.data.message, "error");
            } else {
                // Tratar outros erros de rede ou servidor
                setFlashMessage("Ocorreu um erro ao processar a solicitação.", "error");
            }
        }
    };
    const handleEditarEmbalagem = async (originalRow, row) => {
        // Define os dados para o novo produto
        const novaEmbalagem = {
            uuid: originalRow.uuid,
            Embalagem: originalRow.Embalagem,
            Empresa: originalRow.Empresa,

            produtoId: originalRow.produtoId,
            embalagemId: originalRow.embalagemId,
            codigoBarras: originalRow.codigoBarras,
            descricao: originalRow.descricao,

            ativo: originalRow.ativo,
            funcao: "editar",
            index: row,
        };

        // Atualize o estado do novo produto
        setNovaEmbalagem(novaEmbalagem);

        return;
    };
    const handleDeleteEmbalagem = (originalRow, row) => {
        const result = {
            uuid: originalRow.uuid,
            Embalagem: originalRow.Embalagem,
            Empresa: originalRow.Empresa,

            produtoId: originalRow.produtoId,
            embalagemId: originalRow.embalagemId,
            codigoBarras: originalRow.codigoBarras,
            descricao: originalRow.descricao,

            ativo: originalRow.ativo,
            funcao: "deletar",
            index: row,
        };

        // Atualize o estado
        setNovaEmbalagem(result);
    };

    const handleMobilia = async () => {
        /* grava no banco de dados o sistema entende que esta no modo EDIT */
        if (!novaMobilia.produtoBensId) {
            if (produtoBens && produtoBens.id) {
                novaMobilia.produtoBensId = parseInt(produtoBens.id);
            } else {
                novaMobilia.produtoBensId = null;
            }
        }

        if (!novaMobilia.ambienteId) {
            novaMobilia.ambienteId = parseInt(document.getElementById("novaMobilia.ambienteId").value);
        }

        if (novaMobilia.funcao === "editar") {
            if (novaMobilia.uuid) {
                CreateOrEditMobilia(novaMobilia);
            } else {
                setItensInventario((prevItens) => {
                    const novosItens = [...prevItens];
                    novosItens[novaMobilia.index] = {
                        ...prevItens[novaMobilia.index],
                        ...novaMobilia,
                    };
                    return novosItens;
                });
                setRowCount(itensInventario.length);
            }
        } else if (novaMobilia.funcao === "deletar") {
            if (novaMobilia.uuid) {
                try {
                    const response = await ProdutoAPI.deletarMobilia(novaMobilia);

                    setFlashMessage(response.message, response.type);
                } catch (error) {
                    console.error(error);
                    setFlashMessage(error.response.message, error.data.type);
                }
            } else {
                setItensInventario((prevItens) => {
                    const novosItens = [...prevItens];
                    novosItens.splice(novaMobilia.index, 1);
                    return novosItens;
                });
            }
        } else {
            //cadastrar
            if (dados.id) {
                //incluir o numero do movimento ao objeto
                novaMobilia.produtoBensId = produtoBens.id;

                /*
                    //verificar duplicidade
                    const result = await MovimentoAPI.duplicidadeProduto(
                        novoProduto.empresaId,
                        novoProduto.movimentoId,
                        novoProduto.produtoId,
                        novoProduto.embalagemId
                    );

                    if (result && novoProduto.funcao === "adicionar") {
                        setFlashMessage("Registo em duplicidade!", "warning");
                        handleEditar(result);
                        return;
                    }
                */
                CreateOrEditMobilia(novaMobilia);
            } else {
                const novoItemArray = [
                    ...itensInventario,
                    {
                        uuid: novaMobilia.uuid,
                        produtoBensId: novaMobilia.produtoBensId || produtoBens.Id,
                        ambienteId: novaMobilia.ambienteId,
                        descricao: novaMobilia.descricao,
                        valor: novaMobilia.valor,
                        ativo: novaMobilia.ativo,

                        dataCompra: novaMobilia.dataCompra,

                        ncmId: novaMobilia.ncmId,
                        cotacaoId: novaMobilia.cotacaoId,

                        Ambiente: Ambiente,
                        //images: novaMobilia.invImages,
                        invImages: Array.isArray(novaMobilia.invImages) ? novaMobilia.invImages : [],
                        invNFs: Array.isArray(novaMobilia.invNFs) ? novaMobilia.invNFs : [],
                    },
                ];

                setItensInventario(novoItemArray);
                // return;
                // setRowCount(novoItemArray.length);

                // setBem((prev) => ({
                //     ...prev, // Mantém as outras propriedades do objeto
                //     produtoBensInventarios: novoItemArray, // Adiciona o novo array ou atualiza o existente
                // }));
            }
        }

        setNovaMobilia({
            produtoBensId: produtoBens.id,
            descricao: "",
            valor: "",

            invImages: [],
            invNFs: [],
            uuid: "",
            ativo: true,
            funcao: "adicionar",
        });

        setPreviewInvIMG([]);
        document.getElementById("novaMobilia.invImages").value = null;
        setProdutoBensInventarioImagens([]);

        setPreviewInvNF([]);
        document.getElementById("novaMobilia.invNF").value = null;
        setProdutoBensInventarioNF([]);

        setNCMInventario({ descricao: "" });
        fetchData("mobilia");
    };
    const CreateOrEditMobilia = async (novaMobilia) => {
        try {
            const result = await ProdutoAPI.mobilia(novaMobilia);
            setFlashMessage(result.message, result.type);
            fetchData("mobilia");
        } catch (error) {
            console.error(error);
            if (error.response && error.response.status === 422) {
                // Tratar o erro 422, que é específico da validação no servidor
                setFlashMessage("Erro de validação: " + error.response.data.message, "error");
            } else {
                // Tratar outros erros de rede ou servidor
                setFlashMessage("Ocorreu um erro ao processar a solicitação.", "error");
            }
        }
    };
    const handleEditar = async (originalRow, row) => {
        // Define os dados para o novo produto
        const novaMobiliaData = {
            id: originalRow.id,
            uuid: originalRow.uuid,
            Produto: originalRow.Produto,
            Ambiente: originalRow.Ambiente,
            produtoBensId: originalRow.produtoBensId,
            ambienteId: originalRow.ambienteId,
            valor: originalRow.valor,
            descricao: originalRow.descricao,

            dataCompra: originalRow.dataCompra,
            ncmId: originalRow.ncmId,
            cotacaoId: originalRow.cotacaoId,

            ativo: originalRow.ativo,
            funcao: "editar",
            index: row,
        };

        setNCMInventario({ descricao: originalRow.NCM ? originalRow.NCM.ncm + " - " + originalRow.NCM.descricao : "" });
        // Se 'dados.id' existe, define as imagens e as NFs como no originalRow
        // Caso contrário, define as imagens com base em 'originalRow.images'
        if (dados.id) {
            setProdutoBensInventarioImagens(originalRow.produtoBensInventarioImagens);
            setProdutoBensInventarioNF(originalRow.produtoBensInventarioNFs);
        } else {
            setPreviewInvIMG(Array.from(originalRow.invImages));
            setPreviewInvNF(Array.from(originalRow.invNFs));
        }

        // Atualize o estado do novo produto
        setNovaMobilia(novaMobiliaData);
    };
    const handleDelete = (originalRow, row) => {
        const result = {
            uuid: originalRow.uuid,

            produtoBensId: originalRow.produtoBensId,
            ambienteId: originalRow.ambienteId,
            valor: originalRow.valor,
            descricao: originalRow.descricao,

            ativo: originalRow.ativo,
            funcao: "deletar",
            index: row,
        };

        // Se 'dados.id' existe, define as imagens e as NFs como no originalRow
        // Caso contrário, define as imagens com base em 'originalRow.images'
        if (dados.id) {
            setProdutoBensInventarioImagens(originalRow.produtoBensInventarioImagens);
            setProdutoBensInventarioNF(originalRow.produtoBensInventarioNFs);
        } else {
            setPreviewInvIMG(Array.from(originalRow.invImages));
            setPreviewInvNF(Array.from(originalRow.invNFs));
        }

        // Atualize o estado
        setNovaMobilia(result);
    };

    //tratamento das imagens
    const abrirEmNovaAba = (image, isFromDB) => {
        const baseUrl = isFromDB ? `${process.env.REACT_APP_API}` : null;
        const folder = isFromDB && image.descricao.endsWith(".pdf") ? "pdf" : "imagens";
        const imageUrl = baseUrl ? `${baseUrl}${folder}/${image.descricao}` : URL.createObjectURL(image);

        // Abre a URL em uma nova aba
        window.open(imageUrl, "_blank");
    };
    const editarImagem = (image, abaAtiva) => {
        if (image.descricao) {
            // Coloque sua lógica aqui, como navegar para uma página de edição
            if (abaAtiva === 3) {
                //lógica para aba de bem do inventario
                setDivImage(true);
                setDivPDF(false);
                setDivImovel(false);
                setNovaImagem(image);
            } else if (abaAtiva === 4) {
                //lógica para imagem do bem
                setDivImageProduto(true);
                setNovaImagemProduto(image);
            }
        } else {
            // Coloque sua lógica aqui, como abrir um modal de edição
        }
    };
    const editarPDF = (image) => {
        if (image.descricao) {
            // Coloque sua lógica aqui, como navegar para uma página de edição
            setDivImage(false);
            setDivPDF(true);
            setDivImovel(false);
            setNovaPDF(image);
        } else {
            // Coloque sua lógica aqui, como abrir um modal de edição
        }
    };

    //função para os botões da imagem
    const handlerImagem = async (image, funcao) => {
        if (funcao === "editar") {
            //lógica para editar
            try {
                const response = await ProdutoAPI.editarInventarioBemImagem(image);

                setFlashMessage(response.data.message, response.data.type);

                setDivImage(false);
                setDivPDF(false);
                setDivImovel(true);

                setPreviewInvIMG([]);
                setPreviewInvNF([]);
                setProdutoBensInventarioImagens([]);
                setProdutoBensInventarioNF([]);
                setNovaMobilia({});
                fetchData("mobilia");
            } catch (error) {
                console.error(error);
                setFlashMessage(error.response.data.message, error.response.data.type);
            }
        } else if (funcao === "cancelar") {
            //lógica para cancelar
            try {
                const response = await ProdutoAPI.deletarInventarioBemImagem(image);

                setFlashMessage(response.data.message, response.data.type);

                setDivImage(false);
                setDivPDF(false);
                setDivImovel(true);

                setPreviewInvIMG([]);
                setPreviewInvNF([]);
                setProdutoBensInventarioImagens([]);
                setProdutoBensInventarioNF([]);
                setNovaMobilia({});
                fetchData("mobilia");
            } catch (error) {
                console.error(error);
                setFlashMessage(error.response.data.message, error.response.data.type);
            }
        } else {
            //não faz nada
            return;
        }
    };
    const handlerPDF = async (image, funcao) => {
        if (funcao === "editar") {
            //lógica para editar
            try {
                const response = await ProdutoAPI.editarInventarioBemPDF(image);

                setFlashMessage(response.data.message, response.data.type);

                setDivImage(false);
                setDivPDF(false);
                setDivImovel(true);

                setPreviewInvIMG([]);
                setPreviewInvNF([]);
                setProdutoBensInventarioImagens([]);
                setProdutoBensInventarioNF([]);
                setNovaMobilia({ ativo: true });
                fetchData("mobilia");
            } catch (error) {
                console.error(error);
                setFlashMessage(error.response.data.message, error.response.data.type);
            }
        } else if (funcao === "cancelar") {
            //lógica para cancelar
            try {
                const response = await ProdutoAPI.deletarInventarioBemPDF(image);

                setFlashMessage(response.data.message, response.data.type);

                setDivImage(false);
                setDivPDF(false);
                setDivImovel(true);

                setPreviewInvIMG([]);
                setPreviewInvNF([]);
                setProdutoBensInventarioImagens([]);
                setProdutoBensInventarioNF([]);
                setNovaMobilia({});
                fetchData("mobilia");
            } catch (error) {
                console.error(error);
                setFlashMessage(error.response.data.message, error.response.data.type);
            }
        } else {
            //não faz nada
            return;
        }
    };

    //função para os botões da imagem do produto
    const handlerImagemProduto = async (image, funcao) => {
        if (funcao === "editar") {
            //lógica para editar
            try {
                const response = await ProdutoAPI.editarImagem(image);

                setFlashMessage(response.data.message, response.data.type);
            } catch (error) {
                console.error(error);
                setFlashMessage(error.response.data.message, error.response.data.type);
            }
        } else if (funcao === "cancelar") {
            //lógica para cancelar
            try {
                const response = await ProdutoAPI.deletarImagem(image);

                setFlashMessage(response.data.message, response.data.type);
            } catch (error) {
                console.error(error);
                setFlashMessage(error.response.data.message, error.response.data.type);
            }
        } else {
            //não faz nada
            return;
        }

        // Limpa o preview e o valor do input e fecha a div
        setNovaImagemProduto({});
        setDivImageProduto(false);
        fetchData("imagens");
        fetchData("mobilia");
    };

    return (
        <Tabs selectedIndex={abaAtiva} onSelect={handleSelect}>
            <form onSubmit={submit}>
                <h1>Gestão de Imóveis</h1>
                <TabList>
                    <Tab disabled={!tabEnabled.produto} title={"Infomações genéricas do imóvel"}>
                        Informações
                    </Tab>
                    <Tab disabled={!tabEnabled.embalagens} title={"Embalagens do imóvel somente utilizado em processo de venda"}>
                        Embalagens
                    </Tab>
                    <Tab disabled={!tabEnabled.bens} title={"Informações específicas imóvel"}>
                        Imóvel
                    </Tab>
                    <Tab disabled={!tabEnabled.mobilia} title={"cadastro de móveis instalados no imóvel"}>
                        Mobilia
                    </Tab>
                    <Tab disabled={!tabEnabled.imagens} title={"Imagens do imóvel"}>
                        Imagens
                    </Tab>
                </TabList>
                <TabPanel>
                    <div className={formStyles.preview_bandeira}>
                        <h4>Informações principais do Imóvel</h4>
                    </div>
                    <div>
                        <SelectEmpresa
                            name="empresaId"
                            text="Empresa"
                            options={OptionsEmpresa}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.empresaId}
                            isEnum={false}
                            notOptNull={true}
                        />
                        <Input
                            text="Data Cadastro"
                            type="datetime-local"
                            name="createdAt"
                            placeholder="última atualização"
                            title="Data de inserção do cadastro no sistema"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={(dados.createdAt && retornaDataHora(dados.createdAt)) || retornaDataHora(moment())}
                            readonly={true}
                        />
                        <Input
                            text="Data Atualização"
                            type="datetime-local"
                            name="updatedAt"
                            placeholder="última atualização"
                            title="Data da última atualização dos dados"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={(dados.updatedAt && retornaDataHora(dados.updatedAt)) || retornaDataHora(moment())}
                            readonly={true}
                        />
                        <Checkbox
                            title="Ativa ou Inativa o produto"
                            text="Ativo"
                            name="ativo"
                            type="checkbox"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.ativo}
                            checked={dados.ativo === true ? "checked" : ""}
                        />
                        <Checkbox
                            title="Informa que o produto utiliza a rotina de compras"
                            text="Compra"
                            name="compra"
                            type="checkbox"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.compra}
                            checked={dados.compra === true ? "checked" : ""}
                        />
                        <Checkbox
                            title="Informa que o produto utiliza a rotina de vendas"
                            text="Venda"
                            name="venda"
                            type="checkbox"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.venda}
                            checked={dados.venda === true ? "checked" : ""}
                        />
                        <Checkbox
                            title="Informa que o produto pode ser locado"
                            text="Locavel"
                            name="locavel"
                            type="checkbox"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.locavel}
                            checked={dados.locavel === true ? "checked" : ""}
                        />
                    </div>
                    <div>
                        <Input
                            text="Código"
                            type="text"
                            name="codigo"
                            placeholder="Código"
                            title="O sistema vai gerar um código para o produto"
                            minlength={14}
                            maxlength={18}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.codigo || ""} //posso colocar para gerar um código
                            readonly={true}
                            inputWidth={"150px"}
                        />
                        <Input
                            text="Descrição"
                            type="text"
                            name="descricao"
                            placeholder="Descrição"
                            title="digite a descrição do produto"
                            inputWidth="350px"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.descricao || ""}
                            readonly={false}
                        />
                        <Select
                            name="tipo"
                            text="Tipo"
                            title="Determina o tipo do produto"
                            options={EnumTipo}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.tipo}
                            isEnum={true}
                        />
                    </div>
                    <div>
                        <Input
                            text="referência"
                            type="text"
                            name="referencia"
                            placeholder="Código de referência"
                            title="Digite o código de referência do produto"
                            minlength={14}
                            maxlength={18}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.referencia || ""}
                            inputWidth={"150px"}
                        />
                        <Input
                            text="Código Fabricante"
                            type="text"
                            name="codigoFabricante"
                            placeholder="Código Fabricante"
                            title="Digite o código do fabricante do produto"
                            minlength={14}
                            maxlength={18}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.codigoFabricante || ""}
                            inputWidth={"150px"}
                        />
                        <DataList
                            text="Fabricante"
                            title={"Vincula o Fabricante ao produto"}
                            name="Fabricante.descricao"
                            inputWidth="350px"
                            filteredOptions={OptionsFabricante}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={Fabricante.descricao || ""}
                        />
                    </div>
                    <div>
                        <Input
                            text="NCM"
                            type="text"
                            name="NCM.ncm"
                            placeholder="NCM"
                            inputWidth="150px"
                            title="Código do ncm do produto"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={NCM?.ncm}
                            readonly={true}
                        />
                        <DataList
                            text="NCM Descrição"
                            title={"Vincula o NCM ao produto"}
                            name="NCM.descricao"
                            placeholder={"Digite mais de 3 caracteres para pesquisar"}
                            inputWidth="510px"
                            filteredOptions={OptionsNCM}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={NCM?.descricao}
                            required={true}
                        />
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.preview_bandeira}>
                        <h4>Embalagens do produto</h4>
                    </div>
                    <div>
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "1rem",
                                }}
                            >
                                <SelectEmpresa
                                    name="novaEmbalagem.empresaId"
                                    text="Empresa"
                                    options={OptionsEmpresa}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novaEmbalagem.empresaId}
                                    isEnum={false}
                                    notOptNull={true}
                                />
                                <Select
                                    name="novaEmbalagem.embalagemId"
                                    text="Embalagem"
                                    options={OptionsEmbalagem}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novaEmbalagem.embalagemId}
                                    isEnum={false}
                                    notOptNull={true}
                                />
                                <Input
                                    text="Código de Barras"
                                    type="text"
                                    name="novaEmbalagem.codigoBarras"
                                    placeholder="Código de Barras"
                                    title="digite o código de barras do produto"
                                    inputWidth="200px"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novaEmbalagem.codigoBarras || ""}
                                    readonly={false}
                                />
                                <Checkbox
                                    title="Ativo quando marcado"
                                    text="Ativo"
                                    name="novaEmbalagem.ativo"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novaEmbalagem.ativo}
                                    checked={novaEmbalagem.ativo === true ? "checked" : ""}
                                />{" "}
                                <Button
                                    variant="contained"
                                    color="inherit"
                                    onClick={() =>
                                        setNovaEmbalagem({
                                            uuid: "",
                                            empresaId: dados.empresaId,
                                            produtoId: dados.id,
                                            embalagemId: "",
                                            codigoBarras: "",
                                            ativo: false,
                                            funcao: "adicionar",
                                        })
                                    }
                                    style={{ marginLeft: "10px" }}
                                >
                                    Limpar
                                </Button>
                                <Button
                                    variant="contained"
                                    color={novaEmbalagem.funcao === "deletar" ? "error" : "info"}
                                    onClick={() => handleEmbalagem(novaEmbalagem)}
                                    disabled={novaEmbalagem.codigoBarras?.length < 1 ? true : false}
                                    style={{ marginLeft: "5px" }}
                                >
                                    {novaEmbalagem.funcao}
                                </Button>
                            </div>
                        </div>
                        <ThemeProvider theme={theme}>
                            <MaterialReactTable
                                displayColumnDefOptions={{
                                    "mrt-row-actions": {
                                        muiTableHeadCellProps: {
                                            align: "center",
                                        },
                                        size: 100,
                                    },
                                    "mrt-row-numbers": {
                                        enableColumnOrdering: true, //turn on some features that are usually off
                                        enableResizing: true,
                                        muiTableHeadCellProps: {
                                            sx: {
                                                fontSize: "1.2rem",
                                            },
                                        },
                                    },
                                    "mrt-row-select": {
                                        enableColumnActions: false,
                                        enableHiding: true,
                                        size: 50,
                                    },
                                }}
                                enableColumnFilterModes
                                enableGlobalFilterModes
                                enableColumnResizing
                                enableColumnOrdering
                                enableEditing
                                renderRowActions={({ row, table }) => (
                                    <Box sx={{ display: "flex", gap: "1rem" }}>
                                        <Tooltip arrow placement="left" title="Editar">
                                            <span>
                                                {" "}
                                                <IconButton
                                                    onClick={() => {
                                                        handleEditarEmbalagem(row.original, row.index);
                                                    }}
                                                    disabled={localStorage.getItem("editar") === "true" ? false : true}
                                                >
                                                    <Edit />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                        <Tooltip arrow placement="right" title="Deletar">
                                            <span>
                                                <IconButton
                                                    color="error"
                                                    //onClick={() => handleOpenDialog(row.id)}
                                                    onClick={() => handleDeleteEmbalagem(row.original, row.id)}
                                                    disabled={localStorage.getItem("cancelar") === "true" ? false : true}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    </Box>
                                )}
                                renderTopToolbarCustomActions={({ table }) => {
                                    const handleImprimir = async (table) => {};

                                    //const handleReaproveitarArquivoNF = async () => {};
                                    return (
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "8px",
                                            }}
                                        >
                                            <Tooltip arrow placement="top-end" title={`Imprimir`}>
                                                <div>
                                                    {" "}
                                                    <Button
                                                        color="info"
                                                        onClick={handleImprimir}
                                                        variant="contained"
                                                        disabled={JSON.parse(localStorage.getItem("imprimir")) === false}
                                                    >
                                                        Imprimir
                                                    </Button>
                                                </div>
                                            </Tooltip>
                                            {/* <Tooltip
                                                arrow
                                                placement="top-end"
                                                title={`Reaproveitar arquivo de nota fiscal para vários itens selecionados, primeiro copie o nome do arquivo na coluna PDF, depois selecione os itens da mesma nota, em seguida click no botão Arquivo NF e depois cole no campo descrição`}
                                            >
                                                <div>
                                                    <Button
                                                        color="success"
                                                        variant="contained"
                                                        disabled={
                                                            JSON.parse(localStorage.getItem("editar")) === false ||
                                                            (!table.getIsSomeRowsSelected() && !table.getIsAllPageRowsSelected())
                                                        }
                                                        onClick={
                                                            !table.getIsSomeRowsSelected() && !table.getIsAllPageRowsSelected()
                                                                ? null
                                                                : handleReaproveitarArquivoNF
                                                        }
                                                    >
                                                        Arquivo NF
                                                    </Button>
                                                </div>
                                            </Tooltip> */}
                                        </div>
                                    );
                                }}
                                columns={columnsEmbalagem}
                                data={itensEmbalagens}
                                enableRowSelection
                                getRowId={(row) => row.uuid}
                                manualFiltering
                                manualPagination
                                manualSorting
                                muiToolbarAlertBannerProps={
                                    isError
                                        ? {
                                              color: "error",
                                              children: "Error loading data",
                                          }
                                        : undefined
                                }
                                onColumnFiltersChange={setColumnFilters}
                                onColumnFilterFnsChange={setColumnFiltersMode}
                                onGlobalFilterChange={setGlobalFilter}
                                onPaginationChange={setPagination}
                                onSortingChange={setSorting}
                                rowCount={rowCountEmbalagem}
                                initialState={{
                                    showColumnFilters: true,
                                    density: "compact",
                                    //columnPinning: { left: ["mrt-row-select"] },
                                }}
                                state={{
                                    columnFilterFns,
                                    columnFilters,
                                    globalFilter,
                                    isLoading,
                                    pagination,
                                    showAlertBanner: isError,
                                    showProgressBars: isRefetching,
                                    sorting,
                                }}
                                //positionToolbarDropZone={"top"}
                                positionExpandColumn={"first"}
                                positionActionsColumn={"first"}
                                positionToolbarAlertBanner="bottom"
                                localization={MRT_Localization_PT_BR}
                            />
                        </ThemeProvider>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.stiloPadrao}>
                        <h4>Imóvel</h4>
                        <div>
                            <Input
                                text="Patrimônio"
                                type="text"
                                name="produtoBens.patrimonio"
                                placeholder=""
                                title="Número do patrimônio"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={produtoBens.patrimonio || ""}
                                inputWidth={"150px"}
                            />
                            <Input
                                text="Descrição do BEM"
                                type="text"
                                name="produtoBens.descricao"
                                placeholder="Descrição"
                                title="digite a descrição do BEM"
                                inputWidth="350px"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={produtoBens.descricao || ""}
                                readonly={false}
                            />
                            <Select
                                name="produtoBens.tipo"
                                text="Tipo"
                                title="Determina o tipo do produto"
                                options={EnumBemTipo}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={produtoBens.tipo}
                                isEnum={true}
                            />
                            <Checkbox
                                title="Ativa ou Inativa o BEM"
                                text="Ativo"
                                name="produtoBens.ativo"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={produtoBens.ativo}
                                checked={produtoBens.ativo === true ? "checked" : ""}
                            />
                        </div>
                        <div>
                            <DataList
                                text="Proprietário"
                                name="Proprietario.descricao"
                                placeholder={"Proprietário do bem"}
                                title={"Proprietário do bem, poderá ser vinculado ao contrato de locação do bem"}
                                inputWidth="400px"
                                filteredOptions={OptionsProprietario}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={Proprietario?.descricao || ""}
                            />
                            <Checkbox
                                title="Quando mobiliado deixar marcado"
                                text="Mobiliado"
                                name="produtoBens.mobiliado"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={produtoBens.mobiliado}
                                checked={produtoBens.mobiliado === true ? "checked" : ""}
                            />
                        </div>
                        <div>
                            <Input
                                text="Inscrioção Municipal"
                                type="text"
                                name="produtoBens.inscricaoMunicipal"
                                placeholder=""
                                title="Número da inscrição municipal"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={produtoBens.inscricaoMunicipal || ""}
                                inputWidth={"150px"}
                            />
                            <Input
                                text="Matrícula"
                                type="text"
                                name="produtoBens.matricula"
                                placeholder=""
                                title="Número da matrícula do imóvel"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={produtoBens.matricula || ""}
                                inputWidth={"150px"}
                            />
                            <Input
                                text="Contador água"
                                type="text"
                                name="produtoBens.contadorAgua"
                                placeholder=""
                                title="Número do contador de água do imóvel"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={produtoBens.contadorAgua || ""}
                                inputWidth={"150px"}
                            />
                            <Input
                                text="Contador energia"
                                type="text"
                                name="produtoBens.contadorEnergia"
                                placeholder=""
                                title="Número do contador de energia do imóvel"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={produtoBens.contadorEnergia || ""}
                                inputWidth={"150px"}
                            />
                        </div>
                        <div>
                            <Input
                                text="CEP"
                                type="text"
                                name="produtoBens.cep"
                                maxlength={10}
                                placeholder="CEP"
                                title="Digite o CEP do imóveel"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={produtoBens.cep ? maskCEP(produtoBens.cep) : ""}
                                inputWidth="100px"
                            />
                            <Select
                                text="Selecione o Estado"
                                name="produtoBens.ufId"
                                options={optionsUF}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={produtoBens.ufId || ""}
                                isEnum={false}
                            />
                            <Select
                                text="Município"
                                name="produtoBens.municipioId"
                                options={optionsMunicipios}
                                title="Escolha a município"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={produtoBens.municipioId || ""}
                                isEnum={false}
                            />
                            <Input
                                text="Latitude"
                                type="text"
                                name="produtoBens.latitude"
                                maxlength={10}
                                placeholder="Latitude do imóvel"
                                title="Digite a latitude da localização  do imóvel"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={produtoBens.latitude || ""}
                            />
                            <Input
                                text="Longitude"
                                type="text"
                                name="produtoBens.longitude"
                                maxlength={10}
                                placeholder="Longitude do imóvel"
                                title="Digite a Longitude da localização  do imóvel"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={produtoBens.longitude || ""}
                            />
                        </div>
                        <div>
                            <Input
                                text="Bairro"
                                type="text"
                                name="produtoBens.bairro"
                                placeholder="Bairro"
                                title="Digite o bairro do imóvel"
                                inputWidth="350px"
                                handleOnChange={handleChange}
                                value={produtoBens.bairro || ""}
                            />
                            <Input
                                text="Logradouro"
                                type="text"
                                name="produtoBens.logradouro"
                                placeholder="Logradouro"
                                title="Digite o logradouro do imóvel"
                                inputWidth="350px"
                                handleOnChange={handleChange}
                                value={produtoBens.logradouro || ""}
                            />
                            <Input
                                text="Número"
                                type="text"
                                name="produtoBens.numero"
                                placeholder="Número"
                                title="Digite o número do imóvel"
                                handleOnChange={handleChange}
                                value={produtoBens.numero || ""}
                            />
                            <Input
                                text="Complemento"
                                type="text"
                                name="produtoBens.complemento"
                                placeholder="Complemento do imóvel"
                                title="Digite o complemento do imóvel"
                                inputWidth="350px"
                                handleOnChange={handleChange}
                                value={produtoBens.complemento || ""}
                            />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.stiloPadrao}>
                        <h4>Mobilia do imóvel</h4>
                        {divImovel && (
                            <div>
                                <div>
                                    <TextArea
                                        text="Descrição do Bem"
                                        type="text"
                                        name="novaMobilia.descricao"
                                        placeholder="Digite aqui a descrição"
                                        title="Digite descrição do bem do imóvel"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novaMobilia.descricao || ""}
                                        textAlign="left"
                                        linhas="4"
                                        colunas="120"
                                    />
                                </div>
                                <div>
                                    <div>
                                        <Input
                                            text="Fotos do item do inventário"
                                            type="file"
                                            name="novaMobilia.invImages"
                                            handleOnChange={onFileChange}
                                            inputWidth="400px"
                                            multiple={true}
                                            accept=".jpg, .jpeg, .png"
                                        />
                                    </div>

                                    {previewInvIMG.length > 0 &&
                                        previewInvIMG.map((image, index) => (
                                            <div
                                                style={{
                                                    display: "inline-block", // Para que os elementos fiquem lado a lado
                                                    cursor: "pointer", // Muda o cursor para indicar que é clicável
                                                    margin: "0 10px 0 0", // Espaçamento direito e inferior
                                                }}
                                                title="Clique para abrir em uma nova aba" // Mensagem exibida ao passar o mouse
                                            >
                                                <img
                                                    src={URL.createObjectURL(image)}
                                                    alt={`Imagem ${index + 1}`}
                                                    key={image.name + index}
                                                    style={{
                                                        borderColor: "black",
                                                        border: "solid 0.05em",
                                                        margin: "2px",
                                                    }}
                                                    width={60}
                                                    height={80}
                                                    onClick={() => abrirEmNovaAba(image, false)}
                                                    title="Clique para abrir em uma nova aba" // Mensagem exibida ao passar o mouse
                                                />
                                            </div>
                                        ))}

                                    {/* Renderização de imagens do banco de dados, caso queira que apareçam independentemente */}
                                    {produtoBensInventarioImagens &&
                                        produtoBensInventarioImagens.length > 0 &&
                                        produtoBensInventarioImagens.map((image, index) => (
                                            <div
                                                style={{
                                                    display: "inline-block", // Para que os elementos fiquem lado a lado
                                                    cursor: "pointer", // Muda o cursor para indicar que é clicável
                                                    margin: "0 10px 0 0", // Espaçamento direito e inferior
                                                }}
                                                title="Clique para abrir em uma nova aba, clique com o botão direito para editar" // Mensagem exibida ao passar o mouse
                                            >
                                                <img
                                                    src={`${process.env.REACT_APP_API}imagens/${image.descricao}`}
                                                    alt={`Imagem ${index + 1}`}
                                                    key={image.uuid || index}
                                                    style={{
                                                        borderColor: "black",
                                                        border: "solid 0.05em",
                                                        margin: "2px",
                                                    }}
                                                    loading="lazy"
                                                    width={60}
                                                    height={80}
                                                    onClick={() => abrirEmNovaAba(image, true)}
                                                    onContextMenu={(e) => {
                                                        e.preventDefault();
                                                        editarImagem(image, abaAtiva);
                                                    }}
                                                    title="Clique para abrir em uma nova aba, clique com o botão direito para editar"
                                                />
                                            </div>
                                        ))}
                                </div>
                                <div>
                                    <div>
                                        <Input
                                            text="PDF da Nota Fiscal"
                                            type="file"
                                            name="novaMobilia.invNF"
                                            handleOnChange={onFileChange}
                                            inputWidth="400px"
                                            multiple={true}
                                            accept="application/pdf"
                                        />
                                    </div>
                                    {previewInvNF.length > 0 &&
                                        previewInvNF.map((image, index) => (
                                            <div
                                                style={{
                                                    display: "inline-block", // Para que os elementos fiquem lado a lado
                                                    cursor: "pointer", // Muda o cursor para indicar que é clicável
                                                    margin: "0 10px 0 0", // Espaçamento direito e inferior
                                                }}
                                                onClick={() => window.open(URL.createObjectURL(image), "_blank")} // Abre o PDF em uma nova aba
                                                title="Clique para abrir em uma nova aba" // Mensagem exibida ao passar o mouse
                                            >
                                                <embed
                                                    src={URL.createObjectURL(image)}
                                                    type="application/pdf"
                                                    key={`${image.name}-${index}`}
                                                    width={60}
                                                    height={80}
                                                    style={{
                                                        borderColor: "black",
                                                        border: "solid 0.05em",
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    {/* Renderização de pdf do banco de dados, caso queira que apareçam independentemente */}
                                    {produtoBensInventarioNFs &&
                                        produtoBensInventarioNFs.length > 0 &&
                                        produtoBensInventarioNFs.map((image, index) => (
                                            <div
                                                style={{
                                                    display: "inline-block", // Para que os elementos fiquem lado a lado
                                                    cursor: "pointer", // Muda o cursor para indicar que é clicável
                                                    margin: "0 10px 0 0", // Espaçamento direito e inferior
                                                }}
                                                onClick={() => abrirEmNovaAba(image, true)}
                                                onContextMenu={(e) => {
                                                    e.preventDefault();
                                                    editarPDF(image);
                                                }}
                                                title="Clique para abrir em uma nova aba, clique com o botão direito para editar" // Mensagem exibida ao passar o mouse
                                            >
                                                <embed
                                                    src={`${process.env.REACT_APP_API}pdf/${image.descricao}`}
                                                    type="application/pdf"
                                                    key={`${image.name}-${index}`}
                                                    width={60}
                                                    height={80}
                                                    style={{
                                                        borderColor: "black",
                                                        border: "solid 0.05em",
                                                    }}
                                                />
                                            </div>
                                        ))}
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginTop: "1rem",
                                        flexWrap: "wrap", // Permite que os itens quebrem a linha quando necessário
                                        justifyContent: "left", //"space-between", // Distribui os itens igualmente
                                    }}
                                >
                                    <DataList
                                        text="NCM Descrição"
                                        title={"Vincula o NCM ao produto"}
                                        name="NCMInventario.descricao"
                                        placeholder={"Digite mais de 3 caracteres para pesquisar"}
                                        inputWidth="510px"
                                        filteredOptions={OptionsNCMInventario}
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={NCMInventario.descricao}
                                    />
                                    <Select
                                        name="novaMobilia.ambienteId"
                                        text="Ambiente"
                                        options={optionsAmbiente}
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novaMobilia.ambienteId}
                                        isEnum={false}
                                        notOptNull={false}
                                    />
                                    <InputNumber
                                        text="Valor"
                                        type="text"
                                        name="novaMobilia.valor"
                                        placeholder="Valor R$"
                                        title="digite o valor em R$"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novaMobilia.valor ? formatarNumero(novaMobilia.valor) : ""}
                                    />
                                    <Input
                                        text="Data"
                                        type="date"
                                        name="novaMobilia.dataCompra"
                                        placeholder=""
                                        title="Data da compra do bem"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novaMobilia.dataCompra || ""}
                                    />
                                    <Checkbox
                                        title="Ativo quando marcado"
                                        text="Ativo"
                                        name="novaMobilia.ativo"
                                        type="checkbox"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novaMobilia.ativo}
                                        checked={novaMobilia.ativo === true ? "checked" : ""}
                                    />

                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => {
                                            // Limpa o preview e o valor do input
                                            setPreviewInvIMG([]);
                                            document.getElementById("novaMobilia.invImages").value = null;
                                            setProdutoBensInventarioImagens([]);

                                            setPreviewInvNF([]);
                                            document.getElementById("novaMobilia.invNF").value = null;
                                            setProdutoBensInventarioNF([]);

                                            setNCMInventario({ descricao: "" });

                                            // Atualiza novaMobilia conforme necessário
                                            setNovaMobilia({
                                                uuid: null,
                                                produtoBensId: produtoBens.id,
                                                ncmId: null,
                                                ambienteId: null,
                                                descricao: null,
                                                valor: null,
                                                dataCompra: moment().format("YYYY-MM-DD"),
                                                NCM: {},
                                                ativo: true,
                                                funcao: "adicionar",
                                            });
                                        }}
                                        //style={{ marginLeft: "10px" }}
                                        style={{
                                            marginLeft: "10px",
                                            minWidth: "80px",
                                            fontSize: "14px",
                                            "@media (max-width: 600px)": {
                                                fontSize: "12px", // Ajusta o tamanho da fonte em telas menores
                                                padding: "8px 12px", // Ajusta o padding para manter o botão visível
                                            },
                                        }}
                                    >
                                        Limpar
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color={novaMobilia.funcao === "deletar" ? "error" : "success"}
                                        onClick={() => {
                                            handleMobilia(novaMobilia);
                                        }}
                                        disabled={!novaMobilia.descricao ? true : false}
                                        //style={{ marginLeft: "5px" }}
                                        style={{
                                            marginLeft: "10px",
                                            minWidth: "80px",
                                            fontSize: "14px",
                                            "@media (max-width: 600px)": {
                                                fontSize: "12px", // Ajusta o tamanho da fonte em telas menores
                                                padding: "8px 12px", // Ajusta o padding para manter o botão visível
                                            },
                                        }}
                                    >
                                        {novaMobilia.funcao}
                                    </Button>
                                </div>
                                <ThemeProvider theme={theme}>
                                    <MaterialReactTable
                                        displayColumnDefOptions={{
                                            "mrt-row-actions": {
                                                muiTableHeadCellProps: {
                                                    align: "center",
                                                },
                                                size: 100,
                                            },
                                            "mrt-row-numbers": {
                                                enableColumnOrdering: true, //turn on some features that are usually off
                                                enableResizing: true,
                                                muiTableHeadCellProps: {
                                                    sx: {
                                                        fontSize: "1.2rem",
                                                    },
                                                },
                                            },
                                            "mrt-row-select": {
                                                enableColumnActions: false,
                                                enableHiding: true,
                                                size: 50,
                                            },
                                        }}
                                        enableColumnFilterModes
                                        enableGlobalFilterModes
                                        enableColumnResizing
                                        enableColumnOrdering
                                        enableEditing
                                        renderRowActions={({ row, table }) => (
                                            <Box sx={{ display: "flex", gap: "1rem" }}>
                                                <Tooltip arrow placement="left" title="Editar">
                                                    <span>
                                                        {" "}
                                                        <IconButton
                                                            onClick={() => {
                                                                handleEditar(row.original, row.index);
                                                            }}
                                                            disabled={localStorage.getItem("editar") === "true" ? false : true}
                                                        >
                                                            <Edit />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                                <Tooltip arrow placement="right" title="Deletar">
                                                    <span>
                                                        <IconButton
                                                            color="error"
                                                            //onClick={() => handleOpenDialog(row.id)}
                                                            onClick={() => handleDelete(row.original, row.id)}
                                                            disabled={localStorage.getItem("cancelar") === "true" ? false : true}
                                                        >
                                                            <Delete />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                            </Box>
                                        )}
                                        renderTopToolbarCustomActions={({ table }) => {
                                            const handleImprimirInventario = async (table) => {
                                                const dadosFiltrados = dados;
                                                const urlSearchParams = new URLSearchParams({
                                                    start: `${pagination.pageIndex * pagination.pageSize}`,
                                                    size: `${pagination.pageSize}`,
                                                    filters: JSON.stringify(columnFilters ?? []),
                                                    filtersMode: JSON.stringify(columnFilterFns ?? []),
                                                    globalFilter: globalFilter ?? "",
                                                    sorting: JSON.stringify(sorting ?? []),
                                                    tipo: urlParams.get("tipo") ?? "",
                                                });

                                                const response = await ProdutoAPI.inventario(urlSearchParams, dadosFiltrados);

                                                // Cria um Blob a partir dos dados do arraybuffer
                                                const pdfBlob = new Blob([response.data], {
                                                    type: "application/pdf",
                                                });

                                                // Cria um URL temporário para o Blob
                                                const pdfUrl = URL.createObjectURL(pdfBlob);

                                                // Use o URL do PDF para abrir em uma nova janela ou realizar outras ações
                                                window.open(pdfUrl);
                                            };

                                            const handleReaproveitarArquivoNF = async () => {
                                                dispatch(clearSelectedComponent()); // Limpa os valores selecionados
                                                const uuids = table.getSelectedRowModel().flatRows.map((row) => row.id);

                                                dispatch(selectComponent(uuids)); // Preenche com os novos valores

                                                navigate("/Imovel/EditarNF", {
                                                    ul: urlParams.get("ul"),
                                                    li: urlParams.get("li"),
                                                    tipo: urlParams.get("tipo"),
                                                    uuid: urlParams.get("uuid"),
                                                });
                                            };
                                            return (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        gap: "8px",
                                                    }}
                                                >
                                                    <Tooltip arrow placement="top-end" title={`Imprimir Inventário`}>
                                                        <div>
                                                            {" "}
                                                            <Button
                                                                color="info"
                                                                onClick={handleImprimirInventario}
                                                                variant="contained"
                                                                disabled={JSON.parse(localStorage.getItem("imprimir")) === false}
                                                            >
                                                                Imprimir
                                                            </Button>
                                                        </div>
                                                    </Tooltip>
                                                    <Tooltip
                                                        arrow
                                                        placement="top-end"
                                                        title={`Reaproveitar arquivo de nota fiscal para vários itens selecionados, primeiro copie o nome do arquivo na coluna PDF, depois selecione os itens da mesma nota, em seguida click no botão Arquivo NF e depois cole no campo descrição`}
                                                    >
                                                        <div>
                                                            <Button
                                                                color="success"
                                                                variant="contained"
                                                                disabled={
                                                                    JSON.parse(localStorage.getItem("editar")) === false ||
                                                                    (!table.getIsSomeRowsSelected() &&
                                                                        !table.getIsAllPageRowsSelected())
                                                                }
                                                                onClick={
                                                                    !table.getIsSomeRowsSelected() &&
                                                                    !table.getIsAllPageRowsSelected()
                                                                        ? null
                                                                        : handleReaproveitarArquivoNF
                                                                }
                                                            >
                                                                Arquivo NF
                                                            </Button>
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                            );
                                        }}
                                        columns={columnsInventario}
                                        data={itensInventario}
                                        enableRowSelection
                                        getRowId={(row) => row.uuid}
                                        manualFiltering
                                        manualPagination
                                        manualSorting
                                        muiToolbarAlertBannerProps={
                                            isError
                                                ? {
                                                      color: "error",
                                                      children: "Error loading data",
                                                  }
                                                : undefined
                                        }
                                        onColumnFiltersChange={setColumnFilters}
                                        onColumnFilterFnsChange={setColumnFiltersMode}
                                        onGlobalFilterChange={setGlobalFilter}
                                        onPaginationChange={setPagination}
                                        onSortingChange={setSorting}
                                        rowCount={rowCount}
                                        initialState={{
                                            showColumnFilters: true,
                                            density: "compact",
                                            //columnPinning: { left: ["mrt-row-select"] },
                                        }}
                                        state={{
                                            columnFilterFns,
                                            columnFilters,
                                            globalFilter,
                                            isLoading,
                                            pagination,
                                            showAlertBanner: isError,
                                            showProgressBars: isRefetching,
                                            sorting,
                                        }}
                                        //positionToolbarDropZone={"top"}
                                        positionExpandColumn={"first"}
                                        positionActionsColumn={"first"}
                                        positionToolbarAlertBanner="bottom"
                                        localization={MRT_Localization_PT_BR}
                                    />
                                </ThemeProvider>
                            </div>
                        )}
                    </div>
                    {divImage && (
                        <div className={formStyles.stiloPadrao}>
                            <div>
                                <h4>Imagem do bem do inventário</h4>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginTop: "1rem",
                                        flexWrap: "wrap", // Permite que os itens quebrem a linha quando necessário
                                        justifyContent: "left", //"space-between", // Distribui os itens igualmente
                                    }}
                                >
                                    <Select
                                        name="novaImagem.posicao"
                                        text="Posição"
                                        options={EnumPosicao}
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novaImagem.posicao}
                                        isEnum={true}
                                        notOptNull={true}
                                    />
                                    <Checkbox
                                        title="Ativo quando marcado"
                                        text="Ativo"
                                        name="novaImagem.ativo"
                                        type="checkbox"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novaImagem.ativo}
                                        checked={novaImagem.ativo === true ? "checked" : ""}
                                    />

                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => {
                                            // Limpa o preview e o valor do input
                                            setDivImage(false);
                                            setDivImovel(true);
                                            // Atualiza novaMobilia conforme necessário
                                            setNovaImagem({
                                                produtoBensInventarioId: produtoBensInventarios.id,
                                                posicao: "",
                                                uuid: "",
                                                ativo: true,
                                            });
                                        }}
                                        //style={{ marginLeft: "10px" }}
                                        style={{
                                            marginLeft: "10px",
                                            minWidth: "80px",
                                            fontSize: "14px",
                                            "@media (max-width: 600px)": {
                                                fontSize: "12px", // Ajusta o tamanho da fonte em telas menores
                                                padding: "8px 12px", // Ajusta o padding para manter o botão visível
                                            },
                                        }}
                                    >
                                        Voltar
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color={"success"}
                                        onClick={() => {
                                            handlerImagem(novaImagem, "editar");
                                        }}
                                        disabled={localStorage.getItem("editar") === "true" ? false : true}
                                        //style={{ marginLeft: "5px" }}
                                        style={{
                                            marginLeft: "10px",
                                            minWidth: "80px",
                                            fontSize: "14px",
                                            "@media (max-width: 600px)": {
                                                fontSize: "12px", // Ajusta o tamanho da fonte em telas menores
                                                padding: "8px 12px", // Ajusta o padding para manter o botão visível
                                            },
                                        }}
                                    >
                                        Editar
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color={"error"}
                                        onClick={() => {
                                            handlerImagem(novaImagem, "cancelar");
                                        }}
                                        disabled={localStorage.getItem("cancelar") === "true" ? false : true}
                                        //style={{ marginLeft: "5px" }}
                                        style={{
                                            marginLeft: "10px",
                                            minWidth: "80px",
                                            fontSize: "14px",
                                            "@media (max-width: 600px)": {
                                                fontSize: "12px", // Ajusta o tamanho da fonte em telas menores
                                                padding: "8px 12px", // Ajusta o padding para manter o botão visível
                                            },
                                        }}
                                    >
                                        Deletar
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                    {divPDF && (
                        <div className={formStyles.stiloPadrao}>
                            <div>
                                <h4>PDF da nota fiscal do bem do inventário</h4>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginTop: "1rem",
                                        //flexWrap: "wrap", // Permite que os itens quebrem a linha quando necessário
                                        justifyContent: "left", //"space-between", // Distribui os itens igualmente
                                    }}
                                >
                                    <Input
                                        text="Nome do arquivo"
                                        type="text"
                                        name="novaPDF.descricao"
                                        placeholder="Arquivo"
                                        title="Nome do arquivo do pdf, pode ser copiado para evitar gravar varias vezes a nota que contém muitos itens"
                                        inputWidth="250px"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novaPDF.descricao || ""}
                                        readonly={true}
                                    />

                                    <Checkbox
                                        title="Ativo quando marcado"
                                        text="Ativo"
                                        name="novaPDF.ativo"
                                        type="checkbox"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novaPDF.ativo}
                                        checked={novaPDF.ativo === true ? "checked" : ""}
                                    />

                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => {
                                            // Limpa o preview e o valor do input
                                            setDivImage(false);
                                            setDivPDF(false);
                                            setDivImovel(true);

                                            // Atualiza novaMobilia conforme necessário
                                            setNovaPDF({
                                                produtoBensInventarioId: produtoBensInventarios.id,
                                                uuid: "",
                                                ativo: true,
                                            });
                                        }}
                                        //style={{ marginLeft: "10px" }}
                                        style={{
                                            marginLeft: "10px",
                                            minWidth: "80px",
                                            fontSize: "14px",
                                            "@media (max-width: 600px)": {
                                                fontSize: "12px", // Ajusta o tamanho da fonte em telas menores
                                                padding: "8px 12px", // Ajusta o padding para manter o botão visível
                                            },
                                        }}
                                    >
                                        Voltar
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color={"success"}
                                        onClick={() => {
                                            handlerPDF(novaPDF, "editar");
                                        }}
                                        disabled={localStorage.getItem("editar") === "true" ? false : true}
                                        //style={{ marginLeft: "5px" }}
                                        style={{
                                            marginLeft: "10px",
                                            minWidth: "80px",
                                            fontSize: "14px",
                                            "@media (max-width: 600px)": {
                                                fontSize: "12px", // Ajusta o tamanho da fonte em telas menores
                                                padding: "8px 12px", // Ajusta o padding para manter o botão visível
                                            },
                                        }}
                                    >
                                        Editar
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color={"error"}
                                        onClick={() => {
                                            handlerPDF(novaPDF, "cancelar");
                                        }}
                                        disabled={localStorage.getItem("cancelar") === "true" ? false : true}
                                        //style={{ marginLeft: "5px" }}
                                        style={{
                                            marginLeft: "10px",
                                            minWidth: "80px",
                                            fontSize: "14px",
                                            "@media (max-width: 600px)": {
                                                fontSize: "12px", // Ajusta o tamanho da fonte em telas menores
                                                padding: "8px 12px", // Ajusta o padding para manter o botão visível
                                            },
                                        }}
                                    >
                                        Deletar
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.preview_bandeira}>
                        <h4>Imagens do Produto</h4>
                    </div>
                    <div className={formStyles.preview_bandeira}>
                        <div>
                            <Input
                                text="Fotos do Produto"
                                type="file"
                                name="images"
                                handleOnChange={onFileChange}
                                inputWidth="400px"
                                multiple={true}
                                accept=".jpg, .jpeg, .png"
                            />
                        </div>
                        {preview.length > 0
                            ? preview.map((image, index) => (
                                  <div
                                      style={{
                                          display: "inline-block", // Para que os elementos fiquem lado a lado
                                          cursor: "pointer", // Muda o cursor para indicar que é clicável
                                      }}
                                      title="Clique para abrir em uma nova aba" // Mensagem exibida ao passar o mouse
                                  >
                                      <img
                                          src={URL.createObjectURL(image)}
                                          alt={`Imagem ${index + 1}`}
                                          key={image.name + index}
                                          style={{
                                              borderColor: "black",
                                              border: "solid 0.05em",
                                              margin: "2px",
                                          }}
                                          width={60}
                                          height={80}
                                          onClick={() => abrirEmNovaAba(image, false)}
                                          title="Clique para abrir em uma nova aba" // Mensagem exibida ao passar o mouse
                                      />
                                  </div>
                              ))
                            : produtoImagems &&
                              produtoImagems.map((image, index) => (
                                  <div
                                      style={{
                                          display: "inline-block", // Para que os elementos fiquem lado a lado
                                          cursor: "pointer", // Muda o cursor para indicar que é clicável
                                      }}
                                      title="Clique para abrir em uma nova aba, clique com o botão direito para editar" // Mensagem exibida ao passar o mouse
                                  >
                                      <img
                                          src={`${process.env.REACT_APP_API}imagens/${image.descricao}`}
                                          alt={`Imagem ${index + 1}`}
                                          key={image.uuid || index}
                                          style={{
                                              borderColor: "black",
                                              border: "solid 0.05em",
                                              margin: "2px",
                                          }}
                                          loading="lazy"
                                          width={60}
                                          height={80}
                                          onClick={() => abrirEmNovaAba(image, true)}
                                          onContextMenu={(e) => {
                                              e.preventDefault();
                                              editarImagem(image, abaAtiva);
                                          }}
                                          title="Clique para abrir em uma nova aba, clique com o botão direito para editar"
                                      />
                                  </div>
                              ))}
                    </div>
                    {divImageProduto && (
                        <>
                            {" "}
                            <div className={formStyles.stiloPadrao}>
                                <h4>Editar ou Deletar imagem do Produto</h4>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginTop: "1rem",
                                        flexWrap: "wrap", // Permite que os itens quebrem a linha quando necessário
                                        justifyContent: "left", //"space-between", // Distribui os itens igualmente
                                    }}
                                >
                                    <Input
                                        text="Nome do arquivo"
                                        type="text"
                                        name="novaImagemProduto.descricao"
                                        placeholder=""
                                        title="Nome do arquivo gravado na nuvem"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novaImagemProduto.descricao || ""}
                                        inputWidth={"250px"}
                                        readonly={true}
                                    />
                                    <Select
                                        name="novaImagemProduto.posicao"
                                        text="Posição"
                                        options={EnumPosicao}
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novaImagemProduto.posicao}
                                        isEnum={true}
                                        notOptNull={true}
                                    />
                                    <Checkbox
                                        title="Ativo quando marcado"
                                        text="Ativo"
                                        name="novaImagemProduto.ativo"
                                        type="checkbox"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novaImagemProduto.ativo}
                                        checked={novaImagemProduto.ativo === true ? "checked" : ""}
                                    />

                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => {
                                            // Limpa o preview e o valor do input e fecha a div
                                            setNovaImagemProduto({});
                                            setDivImageProduto(false);
                                        }}
                                        //style={{ marginLeft: "10px" }}
                                        style={{
                                            marginLeft: "10px",
                                            minWidth: "80px",
                                            fontSize: "14px",
                                            "@media (max-width: 600px)": {
                                                fontSize: "12px", // Ajusta o tamanho da fonte em telas menores
                                                padding: "8px 12px", // Ajusta o padding para manter o botão visível
                                            },
                                        }}
                                    >
                                        Voltar
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color={"success"}
                                        onClick={() => {
                                            handlerImagemProduto(novaImagemProduto, "editar");
                                        }}
                                        disabled={localStorage.getItem("editar") === "true" ? false : true}
                                        //style={{ marginLeft: "5px" }}
                                        style={{
                                            marginLeft: "10px",
                                            minWidth: "80px",
                                            fontSize: "14px",
                                            "@media (max-width: 600px)": {
                                                fontSize: "12px", // Ajusta o tamanho da fonte em telas menores
                                                padding: "8px 12px", // Ajusta o padding para manter o botão visível
                                            },
                                        }}
                                    >
                                        Editar
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color={"error"}
                                        onClick={() => {
                                            handlerImagemProduto(novaImagemProduto, "cancelar");
                                        }}
                                        disabled={localStorage.getItem("cancelar") === "true" ? false : true}
                                        //style={{ marginLeft: "5px" }}
                                        style={{
                                            marginLeft: "10px",
                                            minWidth: "80px",
                                            fontSize: "14px",
                                            "@media (max-width: 600px)": {
                                                fontSize: "12px", // Ajusta o tamanho da fonte em telas menores
                                                padding: "8px 12px", // Ajusta o padding para manter o botão visível
                                            },
                                        }}
                                    >
                                        Deletar
                                    </Button>
                                </div>
                            </div>
                        </>
                    )}
                </TabPanel>
                <div>
                    <Stack direction="row" spacing={1}>
                        <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                            Voltar
                        </Button>
                        <Button type="submit" variant="contained" color="success">
                            {btnText}
                        </Button>
                    </Stack>
                </div>
            </form>
        </Tabs>
    );
}

export default Form;
