import api from ".";

export const embalagem = async (novaEmbalagem) => {
    try {
        const { data } = await api.post("/produtoLIM/embalagem", novaEmbalagem);
        return data;
    } catch (error) {
        return error;
    }
};

export const ncm = async (pesquisa, empresaId = 1) => {
    try {
        const { data } = await api.get("/serviceNCM", {
            params: { pesquisa, empresaId },
        });
        return data;
    } catch (error) {
        return error;
    }
};

export const operacao = async (entrada, saida, industria, balanco, manutencao) => {
    try {
        const { data } = await api.get("/serviceOPERACAO", {
            params: { entrada, saida, industria, balanco, manutencao },
        });
        return data;
    } catch (error) {
        return error;
    }
};

export const cadastrar = (dados) =>
    api.post("/produtoLIM/cadastrar/", dados, {
        headers: { "Content-Type": "multipart/form-data" },
    });

export const consulta = (uuid) => api.get(`/produtoLIM/editar/${uuid}`);

export const editar = (dados) =>
    api.post("/produtoLIM/editar/", dados, {
        headers: { "Content-Type": "multipart/form-data" },
    });

export const deletar = (selectedRow) => api.post("/produtoLIM/deletar/", selectedRow);

export const listar = (start, size, filters, filtersMode, globalFilter, sorting) =>
    api.get("/produtoLIM/listar/", {
        params: { start, size, filters, filtersMode, globalFilter, sorting },
    });

export const listarInventario = (start, size, filters, filtersMode, globalFilter, sorting, produtoBensId) =>
    api.get("/produtoLIM/listarInventario/", {
        params: { start, size, filters, filtersMode, globalFilter, sorting, produtoBensId },
    });

export const relatorio = (dadosFiltrados, params) =>
    api.post(
        "/produtoLIM/relatorio/",
        {
            params: { ...params },
            dadosFiltrados,
        },
        {
            responseType: "arraybuffer", // Define o tipo de resposta como arraybuffer
        }
    );

//mobilia de inventario
export const mobilia = async (novaMobilia) => {
    try {
        const { data } = await api.post("/produtoLIM/mobilia", novaMobilia, {
            headers: { "Content-Type": "multipart/form-data" },
        });
        return data;
    } catch (error) {
        return error;
    }
};

export const deletarMobilia = async (novaMobilia) => {
    try {
        const { data } = await api.post("/produtoLIM/deletarMobilia", novaMobilia);
        return data;
    } catch (error) {
        return error;
    }
};

//tratamento de imagens e pdf de not fiscal do inventario e do produto
export const editarInventarioBemImagem = (image) => api.post("/produtoLIM/editarInventarioBemImagem/", image);
export const editarInventarioBemPDF = (image) => api.post("/produtoLIM/editarInventarioBemPDF/", image);
export const deletarInventarioBemImagem = (image) => api.post("/produtoLIM/deletarInventarioBemImagem/", image);
export const deletarInventarioBemPDF = (image) => api.post("/produtoLIM/deletarInventarioBemPDF/", image);

export const editarImagem = (image) => api.post("/produtoLIM/editarImagem/", image);
export const listarImagem = (start, size, filters, filtersMode, globalFilter, sorting, produtoId) =>
    api.get("/produtoLIM/listarImagem/", {
        params: { start, size, filters, filtersMode, globalFilter, sorting, produtoId },
    });
export const deletarImagem = (image) => api.post("/produtoLIM/deletarImagem/", image);

/**
 * utilizar varios UUIDs para rotinas distintas
 * @param {*} selectedUUIDs
 * @param {*} tabela
 * @param {*} funcao para identificar o que sera feito com os dados
 * @returns
 */
export const reaproveitarFileByUUIDs = (selectedUUIDs, tabela, funcao) =>
    api.post("/produtoLIM/reaproveitarFileByUUIDs", selectedUUIDs, tabela, funcao);

export const reaproveitarNFs = (dados, novosDados) => api.post("/produtoLIM/reaproveitarNFs", dados, novosDados);

//serviços
export const bem = async (empresaId, ativo, filtro, condicao) => {
    try {
        const { data } = await api.get("/serviceBem", {
            params: {
                empresaId,
                ativo,
                filtro,
                condicao,
            },
        });
        return data;
    } catch (error) {
        return error;
    }
};

// relatorios
export const inventario = (dadosFiltrados, params) =>
    api.post(
        "/produtoLIM/inventario/",
        {
            params: { ...params },
            dadosFiltrados,
        },
        {
            responseType: "arraybuffer", // Define o tipo de resposta como arraybuffer
        }
    );
