import { useEffect, useState, useContext } from "react";
import { Context } from "../../context/UserContext";

import styles from "./Message.module.css";

import bus from "../../utils/bus";

function Message(params) {
    const [visibility, setVisibility] = useState(false);
    const [msgText, setMessage] = useState("");
    const [msgType, setType] = useState("");
    //const [msgPosition, setPosition] = useState("");
    const { parametro } = useContext(Context);
    const [backgroundColor, setBackgroundColor] = useState("");
    const [textColor, setTextColor] = useState("");

    /* types =>  "success" | "error" | "info" | "warning" | "secondary" */

    //aula 277 2min
    useEffect(() => {
        setBackgroundColor(parametro.msgBackgroundColor);
        setTextColor(parametro.msgTextColor);

        bus.addListener("flash", ({ msgText, msgType, msgTempo /*msgPosition*/ }) => {
            msgTempo = msgTempo ? msgTempo * 1000 : parametro.msgTempo;
            setVisibility(true);
            setMessage(msgText);
            setType(msgType);
            //setPosition(msgPosition);

            setTimeout(() => {
                setVisibility(false);
            }, msgTempo);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (parametro.msgCoresDefault) {
            switch (msgType) {
                case "success":
                    setBackgroundColor("#28a745"); // Verde para sucesso
                    setTextColor("#fff"); // Texto branco
                    break;
                case "error":
                    setBackgroundColor("#dc3545"); // Vermelho para erro
                    setTextColor("#fff"); // Texto branco
                    break;
                case "info":
                    setBackgroundColor("#17a2b8"); // Azul claro para informação
                    setTextColor("#fff"); // Texto branco
                    break;
                case "warning":
                    setBackgroundColor("#ffc107"); // Amarelo para aviso
                    setTextColor("#212529"); // Texto escuro
                    break;
                case "secondary":
                    setBackgroundColor("#6c757d"); // Cinza para secundário
                    setTextColor("#fff"); // Texto branco
                    break;
                default:
                    setBackgroundColor("#007bff"); // Azul padrão
                    setTextColor("#fff"); // Texto branco
            }
        }
    }, [msgType, parametro.msgCoresDefault]);

    return (
        visibility && (
            <div
                className={`${styles[msgType]} ${styles[parametro.msgPosition]}`}
                style={{
                    backgroundColor: backgroundColor,
                    color: textColor,
                }}
            >
                {msgText}
            </div>
        )
    );
}

export default Message;
