import api from ".";

export const empresa = async (id) => {
    try {
        const { data } = await api.get("/serviceEMPRESA", { params: { id } });
        return data;
    } catch (error) {
        return error;
    }
};

export const centroCustos = async (empresaId, descricao, tipo, combustivel, manutencao, industria, servico) => {
    try {
        const { data } = await api.get("/serviceCENTROCUSTOS", {
            params: {
                empresaId,
                descricao,
                tipo,
                combustivel,
                manutencao,
                industria,
                servico,
            },
        });
        return data;
    } catch (error) {
        return error;
    }
};

export const unidade = async (empresaId, pesavel, entrada, saida, industria) => {
    try {
        const { data } = await api.get("/serviceUNIDADE", {
            params: { empresaId, pesavel, entrada, saida, industria },
        });
        return data;
    } catch (error) {
        return error;
    }
};

export const cadastrar = (dados) =>
    api.post("/empresa/cadastrar/", dados, {
        headers: { "Content-Type": "multipart/form-data" },
    });

export const consulta = (uuid) => api.get(`/empresa/editar/${uuid}`);

export const editar = (dados) =>
    api.patch("/empresa/editar/", dados, {
        headers: { "Content-Type": "multipart/form-data" },
    });

export const deletar = (selectedRow) => api.post("/empresa/deletar/", selectedRow);

export const listar = (start, size, filters, filtersMode, globalFilter, sorting) =>
    api.get("/empresa/listar/", {
        params: { start, size, filters, filtersMode, globalFilter, sorting },
    });
