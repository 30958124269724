import api from ".";

export const listarTributacoes = (start, size, filters, filtersMode, globalFilter, sorting, tributacaoId) =>
    api.get("/tributacao/listarTributacoes", {
        params: {
            start,
            size,
            filters,
            filtersMode,
            globalFilter,
            sorting,
            tributacaoId,
        },
    });

export const managerTributacoes = async (novoProduto) => {
    try {
        const { data } = await api.post("/tributacao/managerTributacoes", novoProduto);
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};

export const duplicidade = async (ufId) => {
    try {
        const { data } = await api.get("/tributacao/duplicidadeTributacoes/", {
            params: { ufId },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};

export const deletarTributacoes = (uuid) => api.post("/tributacao/deletarTributacoes", { uuid });

export const tributacaoByNCM = (ncmId) => api.post("/tributacao/tributacaoByNCM", { ncmId });
