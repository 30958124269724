import api from "./";

export const pessoa = async (CPForCNPJ, empresaId, tipo, vinculoFinanceiro, fabricante) => {
    try {
        const { data } = await api.get("/servicePESSOA", {
            params: {
                CPForCNPJ,
                empresaId,
                tipo,
                vinculoFinanceiro,
                fabricante,
            },
        });
        return data;
    } catch (error) {
        return error;
    }
};
export const colaborador = async (empresaId, filtro, condicao) => {
    try {
        const { data } = await api.get("/serviceColaborador", {
            params: {
                empresaId,
                filtro,
                condicao,
            },
        });
        return data;
    } catch (error) {
        return error;
    }
};
export const financeiro = async (empresaId, pessoaId) => {
    try {
        const { data } = await api.get("/pessoa/pessoaFinanceiro", {
            params: { empresaId, pessoaId },
        });
        return data;
    } catch (error) {
        return error;
    }
};

export const cadastrar = (dados) =>
    api.post("/pessoa/cadastrar/", dados, {
        headers: { "Content-Type": "multipart/form-data" },
    });

export const consulta = (uuid) => api.get(`/pessoa/editar/${uuid}`);

export const editar = (dados) =>
    api.patch("/pessoa/editar/", dados, {
        headers: { "Content-Type": "multipart/form-data" },
    });

export const deletar = (selectedRow) => api.post("/pessoa/deletar/", selectedRow);

export const listar = (start, size, filters, filtersMode, globalFilter, sorting) =>
    api.get("/pessoa/listar/", {
        params: { start, size, filters, filtersMode, globalFilter, sorting },
    });

export const emails = async (novoEmail) => api.post(`pessoa/email`, novoEmail);

export const enderecos = async (novoEndereco) => api.post("pessoa/endereco", novoEndereco);

export const nomes = async (novoNome) => api.post(`pessoa/nome`, novoNome);

export const telefones = async (novoTelefone) => api.post(`pessoa/telefone`, novoTelefone);

export const filial = async (novaFilial) => api.post(`pessoa/filial`, novaFilial);

export const funcionarios = async (novoFuncionario) => api.post(`pessoa/funcionarios`, novoFuncionario);

export const tabelaPreco = async (novaTabelaPreco) => api.post(`pessoa/tabelaPreco`, novaTabelaPreco);

export const relatorio = (url, pessoasFiltrados) =>
    api.post(`/lancamento/relatorio/?${url.toString()}`, pessoasFiltrados, {
        responseType: "arraybuffer",
    });
export const pessoaById = async (pessoaId) => {
    try {
        const { data } = await api.get("pessoa/pessoaById", {
            params: { pessoaId },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};

export const parametroUsuario = async (pessoaId) => {
    try {
        const { data } = await api.get("pessoa/parametroUsuario", {
            params: { pessoaId },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};
export const parametroPessoa = async (id) => {
    try {
        const { data } = await api.get("pessoa/parametroPessoa", {
            params: { id },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};
export const parametroFinanceiro = async (id) => {
    try {
        const { data } = await api.get("pessoa/parametroFinanceiro", {
            params: { id },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};
