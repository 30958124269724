// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header {
    /*
        flex: 1 1 100vw;
        height: 100px;
    */
    display: flex;
    justify-content: space-between;
    padding: 1em 1.5em;
    background-color: #3b5998;
    color: #ffd400;
}

.Header_header_logo__vK2PR {
    display: flex;
    align-items: center;
}

.Header_header_logo__vK2PR img {
    /* width: 40px; */
    margin-right: 0.8em;
}

.Header_header__OPGSD h2 {
    text-decoration: none;
    color: #16479d;
    font-weight: bold;
    cursor: pointer;
    transition: 0.5s;
    padding: 0.5em 0.8em;
    border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/layout/Header.module.css"],"names":[],"mappings":"AAAA;IACI;;;KAGC;IACD,aAAa;IACb,8BAA8B;IAC9B,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,cAAc;IACd,iBAAiB;IACjB,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,kBAAkB;AACtB","sourcesContent":["header {\n    /*\n        flex: 1 1 100vw;\n        height: 100px;\n    */\n    display: flex;\n    justify-content: space-between;\n    padding: 1em 1.5em;\n    background-color: #3b5998;\n    color: #ffd400;\n}\n\n.header_logo {\n    display: flex;\n    align-items: center;\n}\n\n.header_logo img {\n    /* width: 40px; */\n    margin-right: 0.8em;\n}\n\n.header h2 {\n    text-decoration: none;\n    color: #16479d;\n    font-weight: bold;\n    cursor: pointer;\n    transition: 0.5s;\n    padding: 0.5em 0.8em;\n    border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header_logo": `Header_header_logo__vK2PR`,
	"header": `Header_header__OPGSD`
};
export default ___CSS_LOADER_EXPORT___;
