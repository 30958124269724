import api from "./";

export const almoxarifados = async (empresaId) => {
    try {
        const { data } = await api.get("/almoxarifado/almoxarifados", {
            params: { empresaId },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};
