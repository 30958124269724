import { useState, useEffect } from "react";
import "react-tabs/style/react-tabs.css";
import "moment/locale/pt-br";

import { Stack, Button } from "@mui/material";

/* hooks */
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import DataList from "../../form/DataList";
import Checkbox from "../../form/Checkbox";
import SelectEmpresa from "../../form/SelectEmpresa";

/* api */
import * as DataListAPI from "../../../api/dataList";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, OptionsEmpresa }) {
    const [dados, setDados] = useState(formData || {});
    const navigate = useNavigateParams();

    if (!dados.empresaId) {
        dados.empresaId = localStorage.getItem("empresaId");
    }

    //set datalist
    const [pessoa, setPessoa] = useState(formData?.Pessoa || []);
    const [OptionsPessoa, setOptionsPessoa] = useState(formData?.Pessoa || []);

    //Atualiza o Datalist
    const consultaDatalist = async (empresaId, tabela, id, descricao, condicao, filtro) => {
        const result = await DataListAPI.dataList(empresaId, tabela, id, descricao, condicao, filtro);
        return result;
    };

    useEffect(() => {
        setDados(formData || {});
        setPessoa(formData?.pessoas || []);
    }, [formData]);

    async function handleChange(e) {
        const { name, value, checked, type } = e.target;

        if (name === "ativo") {
            setDados({ ...dados, [name]: checked });
        } else if (name === "pessoa.descricao") {
            setPessoa({
                ...pessoa,
                [name.split(".")[1]]: value,
            });

            //carrega o datalist
            const result = await consultaDatalist(dados.empresaId, "Pessoa", "id", "descricao", "pessoaUsuario", value);
            setOptionsPessoa(result);

            const selectedId = document.querySelector(`datalist option[value="${value}"]`)?.dataset.id;
            setDados({
                ...dados,
                pessoaId: selectedId ?? null,
            });
        } else {
            setDados({ ...dados, [name]: value });
        }
    }

    async function handleOnBlur(e) {}
    async function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();
        if (!dados.empresaId) {
            dados.empresaId = parseInt(document.getElementById("empresaId").value);
        }

        handleSubmit(dados);
    };

    return (
        <form onSubmit={submit}>
            <h1>Pessoa x Empresa</h1>
            <div>
                <DataList
                    text="Pessoa"
                    title={"Seleciona a pessoa"}
                    name="pessoa.descricao"
                    inputWidth="300px"
                    filteredOptions={OptionsPessoa}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={pessoa.descricao || ""}
                />
                <SelectEmpresa
                    text="Selecione a Empresa"
                    name="empresaId"
                    inputWidth="300px"
                    options={OptionsEmpresa}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.empresaId || ""}
                    required
                    notOptNull={true}
                />

                <Checkbox
                    title="Ativa quando marcado"
                    text="Ativo"
                    name="ativo"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.ativo}
                    checked={dados.ativo === true ? "checked" : ""}
                />
            </div>
            <div>
                <Stack direction="row" spacing={1}>
                    <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                        Voltar
                    </Button>
                    <Button type="submit" variant="contained" color="success">
                        {btnText}
                    </Button>
                </Stack>
            </div>
        </form>
    );
}
export default Form;
