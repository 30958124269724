import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import { Context } from "../../../context/UserContext";
import Form from "./Form";
import styles from "../../form/Form.module.css";

import moment from "moment";
import "moment/locale/pt-br";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";

import * as DataListAPI from "../../../api/dataList";
import * as EmpresaAPI from "../../../api/empresa";
import * as FinanceiroAPI from "../../../api/financeiro";
import * as LancamentosAPI from "../../../api/lancamentos";
import * as TabelaAPI from "../../../api/tabela";

import { retornaData } from "../../../utils/retornaDataFormatada";

const CreateOrEdit = () => {
    const [dados, setDados] = useState({});
    const [token] = useState(localStorage.getItem("token")) || "";
    const { parametro } = useContext(Context);
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    const [OptionsCentroCustos, setCC] = useState([]);
    const [OptionsConta, setConta] = useState([]);
    const [OptionsForma, setForma] = useState([]);

    const uuid = urlParams.get("uuid");
    let btnText = urlParams.get("btnText") === "Baixar" ? "Baixar" : uuid ? "Atualizar" : "Cadastrar";

    //enums e tabelas
    const [OptionsEmpresa, setEmpresa] = useState([]);

    //para lancamento vazio preenche valores pré definidos
    if (!dados?.empresaId) {
        dados.empresaId = localStorage.getItem("empresaId");
        dados.parcela = 1;
        dados.dataLancamento = retornaData(moment());
        dados.dataVencimento = retornaData(moment());
        dados.tipo = urlParams.get("tipo");
    }
    if ((!dados.baixa && dados.valor) || dados.acrescimoValor || dados.valorBaixado || dados.descontoValor) {
        dados.baixa = (
            parseFloat(dados.valor) +
            parseFloat(dados.acrescimoValor) -
            parseFloat(dados.valorBaixado) -
            parseFloat(dados.descontoValor)
        ).toFixed(2);
    }

    //carregar API's Externas
    const consultaEmpresa = async () => {
        let result = await EmpresaAPI.empresa(localStorage.getItem("empresaId"));
        setEmpresa(result);
    };
    const consultaForma = async (tipo) => {
        let result = await FinanceiroAPI.forma(tipo, "", dados.empresaId);
        setForma(result);
    };
    const consultaCC = async () => {
        let dado = await DataListAPI.dataList(
            !dados.empresaId?.id ? localStorage.getItem("empresaId") : dados.empresaId,
            "centroCusto",
            "id",
            "descricao",
            urlParams.get("tipo"),
            ""
        );
        setCC(dado);
    };
    const consultaConta = async () => {
        let result = await TabelaAPI.tabela(dados.empresaId, "Conta", "descricao");
        setConta(result);
    };

    useEffect(() => {
        if (!token) {
            navigate("/Auth", { replace: true });
        }
    }, [token, navigate]);

    useEffect(() => {
        consultaEmpresa();
        consultaForma(urlParams.get("tipo"));

        consultaCC();
        consultaConta();

        if (uuid) {
            //consulta a API
            LancamentosAPI.consulta(uuid)
                .then((response) => {
                    setDados(response.data);
                })
                .catch((error) => {
                    console.error(error);
                    setFlashMessage(error.response.data.message, error.response.data.type);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid]);

    async function managerLancamento(lancamento) {
        try {
            let response;

            if (uuid && btnText !== "Baixar") {
                response = await LancamentosAPI.editar(lancamento);
            } else if (btnText === "Baixar") {
                response = await LancamentosAPI.baixar(lancamento);
            } else {
                response = await LancamentosAPI.cadastrar(lancamento);
            }

            setFlashMessage(response.data.message, response.data.type);
            setTimeout(() => {
                if (response.data.type === "success") {
                    navigate("/Lancamentos", {
                        ul: urlParams.get("ul"),
                        li: urlParams.get("li"),
                        tipo: urlParams.get("tipo"),
                    });
                }
            }, parametro.msgTempo * 1000);
        } catch (error) {
            console.error(error);
            setFlashMessage(error.response.data.message, error.response.data.type);
        }
    }

    return (
        <section className={`${styles.form_container} ${styles[parametro.tema]}`}>
            <Form
                token={token}
                handleSubmit={managerLancamento}
                formData={dados}
                btnText={btnText}
                OptionsEmpresa={OptionsEmpresa}
                OptionsCentroCustos={OptionsCentroCustos}
                OptionsForma={OptionsForma}
                OptionsConta={OptionsConta}
                caminhoVoltar={"/Lancamentos"}
            />
        </section>
    );
};
export default CreateOrEdit;
