import React, { useEffect, useMemo, useState, useContext, useCallback, useRef } from "react";
import { Context } from "../../../context/UserContext";

import { useNavigateParams } from "../../../hooks/useNavigateParams";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

import { parseISO, format, isValid } from "date-fns";

import { ThemeProvider } from "@mui/material";
/* tema customizado*/
import tableThemeCustom from "../../form/tableThemeCustom";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";

//Import Material React Table Translations
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";
import CustomDialog from "../../layout/CustomDialog";

import { Delete, Edit, Print, MonetizationOn, MoneyOff } from "@mui/icons-material";
import { Box, Button, IconButton, Tooltip, Checkbox, Typography } from "@mui/material";

import tratar from "../../../utils/retornaTratamentoURL";
import CrudsAPI from "../../../api/CRUDs";

/* api */
import * as FinanceiroAPI from "../../../api/financeiro";

/* util */
import { relatorios } from "../../../api/relatorios";
import { obterPermissoesUsuario, corrigirDataColumnFilters, corrigirDataColumnFiltersObjeto } from "../../../utils/funcoes";

const Index = () => {
    const permissoes = {
        incluir: JSON.parse(localStorage.getItem("incluir")) !== false,
        editar: JSON.parse(localStorage.getItem("editar")) !== false,
        baixar: JSON.parse(localStorage.getItem("baixar")) !== false,
        cancelarBaixa: JSON.parse(localStorage.getItem("cancelarBaixa")) !== false,
        imprimir: JSON.parse(localStorage.getItem("imprimir")) !== false,
        cancelar: JSON.parse(localStorage.getItem("cancelar")) !== false,
    };

    const [token] = useState(localStorage.getItem("token")) || "";
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();

    const [dados, setDados] = useState([]);

    const pessoaId = localStorage.getItem("pessoaId");

    const urlParams = new URLSearchParams(window.location.search);

    const pathname = "/Caixa/CreateOrEdit";
    const ul = urlParams.get("ul") || 4;
    const li = urlParams.get("li") || 58;
    const tipo = urlParams.get("tipo");
    const operacaoUUID = urlParams.get("operacao");
    const uuid = urlParams.get("uuid");
    const btnText = urlParams.get("btnText") || "Cadastrar";

    const params = new URLSearchParams({
        ul: ul,
        li: li,
        tipo: tipo,
        operacao: operacaoUUID,
        uuid: uuid,
        btnText: btnText,
    });

    const { parametro } = useContext(Context);
    /* Obtem a paginação padrão */
    useEffect(() => {
        if (parametro && parametro.paginacao) {
            setPagination({
                pageIndex: pagination.pageIndex || 0,
                pageSize: parametro.paginacao,
            });
        }
    }, [parametro]);

    //dialog customizado
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});

    //data and fetching state
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    //tratar filtros se vier pela navbar
    if (localStorage.path.replace(/["]/g, "") !== window.location.pathname) {
        tratar.LimparFiltros();
        localStorage.setItem("path", JSON.stringify(window.location.pathname));
    }

    //table state
    const [columnFilterFns, setColumnFiltersMode] = useState(
        localStorage.columnFilterFns.length > 2 ? JSON.parse(localStorage.columnFilterFns) : []
    );

    const [columnFilters, setColumnFilters] = useState(
        localStorage.columnFilters.length > 2 ? JSON.parse(localStorage.columnFilters) : []
    );
    const prevColumnFilters = useRef(columnFilters || {});

    const [globalFilter, setGlobalFilter] = useState(
        localStorage.globalFilter.length > 2 ? JSON.parse(localStorage.globalFilter) : ""
    );

    const [sorting, setSorting] = useState(localStorage.sorting.length > 2 ? JSON.parse(localStorage.sorting) : []);

    const [pagination, setPagination] = useState({
        pageIndex: JSON.parse(localStorage.pagination).pageIndex || 0,
        pageSize: JSON.parse(localStorage.pagination).pageSize || 5,
    });
    const fetchData = useCallback(async () => {
        if (!dados.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }

        try {
            CrudsAPI.caixa
                .listar(
                    pagination.pageIndex * pagination.pageSize,
                    pagination.pageSize,
                    JSON.stringify(columnFilters ?? []),
                    JSON.stringify(columnFilterFns ?? []),
                    globalFilter ?? "",
                    JSON.stringify(sorting ?? [])
                )
                .then((response) => {
                    setDados(response.data.data);
                    setRowCount(response.data.meta.totalRowCount);
                });
        } catch (error) {
            setIsError(true);
            console.error(error);
            return;
        }
        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
    }, [dados.length, pagination.pageIndex, pagination.pageSize, columnFilters, columnFilterFns, globalFilter, sorting]);

    //funções do dialog
    const handleOpenDialog = (row) => {
        setOpenDialog(true);
        setSelectedRow(row);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmDialog = async (row) => {
        // Verifique se há uma linha selecionada
        let msgType = "success";
        if (selectedRow) {
            try {
                const response = await CrudsAPI.caixa.deletar(selectedRow);

                setFlashMessage(response.data.message, msgType);

                // Após a exclusão, chame a função fetchData() novamente para atualizar as unidades
                fetchData();
            } catch (error) {
                msgType = "error";
                console.error(error);
                setFlashMessage(error.response.data.message, msgType);
            }
        }
        setOpenDialog(false);
    };

    useEffect(() => {
        if (!token) {
            return navigate("/Auth", { replace: true });
        }

        //funcao para setar localStorage
        localStorage.setItem(
            "pagination",
            JSON.stringify({
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
            })
        );
        localStorage.setItem("columnFilterFns", JSON.stringify(columnFilterFns));
        localStorage.setItem("columnFilters", JSON.stringify(columnFilters));
        localStorage.setItem("globalFilter", JSON.stringify(globalFilter));
        localStorage.setItem("sorting", JSON.stringify(sorting));

        //rotina para corrigir o formato de data na grid precisa esta antes do fetchData
        // Formata os filtros de datas apenas se necessário
        const formattedFilters = corrigirDataColumnFilters(columnFilters);
        // Verifica se há mudanças nos filtros formatados
        if (JSON.stringify(formattedFilters) !== JSON.stringify(columnFilters)) {
            setColumnFilters(formattedFilters);
        }

        //permissões do usuario
        obterPermissoesUsuario(localStorage.getItem("pessoaId"), localStorage.getItem("empresaId"), ul, li);

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, columnFilterFns, columnFilters, globalFilter, pagination.pageIndex, pagination.pageSize, sorting, fetchData]);

    const columns = useMemo(
        () => [
            {
                id: "Caixa",
                header: "Lançamentos dos caixas",
                columns: [
                    {
                        accessorKey: "Empresa.nomeFantasia",
                        header: "Empresa",
                    },
                    {
                        accessorKey: "contador",
                        header: "Número",
                    },
                    {
                        accessorFn: (row) => new Date(row),
                        accessorKey: "data",
                        header: "Data",
                        filterVariant: "date",
                        sortingFn: "date",
                        columnFilterModeOptions: [
                            "fuzzy",
                            "equals",
                            "between",
                            "betweenInclusive",
                            "greaterThanOrEqualTo",
                            "lessThanOrEqualTo",
                        ],
                        muiTableBodyCellProps: () => ({
                            align: "center",
                        }),
                        Cell: ({ row }) => {
                            return (
                                <span>
                                    {row.original?.data
                                        ? isValid(parseISO(row.original?.data))
                                            ? format(parseISO(row.original?.data), "dd/MM/yyyy")
                                            : null
                                        : null}
                                </span>
                            );
                        },
                    },
                ],
            },

            //column definitions...
        ],
        [] //getCommonEditTextFieldProps
    );

    /* modo tema */
    const theme = tableThemeCustom();

    return (
        <div>
            <div>
                {openDialog && (
                    <CustomDialog
                        title="Delete CAIXA!"
                        content="Você tem certeza?"
                        onClose={handleCloseDialog}
                        onConfirm={handleConfirmDialog}
                    />
                )}
            </div>
            <ThemeProvider theme={theme}>
                <MaterialReactTable
                    displayColumnDefOptions={{
                        "mrt-row-actions": {
                            muiTableHeadCellProps: {
                                align: "center",
                            },
                            size: 100,
                        },
                        "mrt-row-numbers": {
                            enableColumnOrdering: true, //turn on some features that are usually off
                            enableResizing: true,
                            muiTableHeadCellProps: {
                                sx: {
                                    fontSize: "1.2rem",
                                },
                            },
                        },
                        "mrt-row-select": {
                            enableColumnActions: true,
                            enableHiding: true,
                            size: 50,
                        },
                    }}
                    enableColumnFilterModes
                    enableGlobalFilterModes
                    //enableColumnResizing
                    enableColumnOrdering
                    enableEditing
                    renderRowActions={({ row, table }) => {
                        //valida as permissões
                        const { baixado, conferido } = row.original || {};

                        // Definição das condições de desativação
                        const isEditarDisabled = !permissoes.editar || baixado;
                        const isBaixarDisabled = baixado || !permissoes.baixar || !conferido;
                        const isEstornarDisabled = !permissoes.cancelarBaixa || !baixado;
                        const isImprimirDisabled = !permissoes.imprimir;
                        const isDeletarDisabled = !permissoes.cancelar || conferido;

                        const handleImprimir = async (obj) => {
                            //enviar para o serviço de relatorios
                            const caminho = "caixa";
                            const url = "caixa";
                            const response = await relatorios(caminho, url, obj);

                            //enviar para o controller
                            //const url = "caixa";
                            //const response = await CrudsAPI.caixa.imprimir(url, obj);

                            // Cria um Blob a partir dos dados do arraybuffer
                            const pdfBlob = new Blob([response.data], {
                                type: "application/pdf",
                            });

                            // Cria um URL temporário para o Blob
                            const pdfUrl = URL.createObjectURL(pdfBlob);

                            // Use o URL do PDF para abrir em uma nova janela ou realizar outras ações
                            window.open(pdfUrl);
                        };
                        const handlerCaixa = async (obj) => {
                            const result = await FinanceiroAPI.caixaBaixarOrEstornar(obj);
                            setFlashMessage(result.message, result.type);
                            fetchData();
                        };

                        return (
                            <Box sx={{ display: "flex", gap: "1rem" }}>
                                <Tooltip arrow placement="top" title="Editar">
                                    <span>
                                        {" "}
                                        <IconButton
                                            onClick={() => {
                                                params.set("uuid", row.id);
                                                params.set("btnText", "Atualizar");
                                                navigate(pathname, params);
                                            }}
                                            disabled={isEditarDisabled}
                                        >
                                            <Edit />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Tooltip arrow placement="top-start" title="Baixar lançamentos do caixa">
                                    <span>
                                        <IconButton
                                            color="error"
                                            onClick={() => {
                                                handlerCaixa({ uuid: row.id, btnText: "Baixar" });
                                            }}
                                            disabled={isBaixarDisabled}
                                        >
                                            <Typography variant="body1" component="span"></Typography>
                                            <MonetizationOn />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Tooltip arrow placement="top-start" title="Estornar lançamentos do caixa">
                                    <span>
                                        <IconButton
                                            color="error"
                                            onClick={() => {
                                                handlerCaixa({ uuid: row.id, btnText: "Estornar" });
                                            }}
                                            disabled={isEstornarDisabled}
                                        >
                                            <MoneyOff />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Tooltip arrow placement="bottom" title="Imprimir">
                                    <span>
                                        {" "}
                                        <IconButton
                                            onClick={() => {
                                                handleImprimir({ uuid: row.id });
                                            }}
                                            disabled={isImprimirDisabled}
                                        >
                                            <Print />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Tooltip arrow placement="right" title="Deletar">
                                    <span>
                                        <IconButton
                                            color="error"
                                            onClick={() => handleOpenDialog(row)}
                                            disabled={isDeletarDisabled}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </Box>
                        );
                    }}
                    renderTopToolbarCustomActions={() => {
                        // Definição das condições de desativação
                        const isIncluirDisabled = !permissoes.incluir;
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    gap: "8px",
                                }}
                            >
                                <Tooltip arrow placement="top-end" title={`Novo Registro`}>
                                    <div>
                                        {" "}
                                        <Button
                                            color="secondary"
                                            onClick={() => {
                                                params.delete("uuid");
                                                params.set("btnText", "Cadastrar");
                                                navigate(pathname, params);
                                            }}
                                            variant="contained"
                                            disabled={isIncluirDisabled}
                                        >
                                            Novo
                                        </Button>
                                    </div>
                                </Tooltip>
                            </div>
                        );
                    }}
                    columns={columns}
                    data={dados}
                    enableRowSelection
                    getRowId={(row) => row.uuid}
                    manualFiltering
                    manualPagination
                    manualSorting
                    muiToolbarAlertBannerProps={
                        isError
                            ? {
                                  color: "error",
                                  children: "Error loading data",
                              }
                            : undefined
                    }
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setColumnFiltersMode}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    rowCount={rowCount}
                    initialState={{
                        showColumnFilters: true,
                        density: "compact",
                        //columnPinning: { left: ["mrt-row-select"] },
                    }}
                    state={{
                        columnFilterFns,
                        columnFilters,
                        globalFilter,
                        isLoading,
                        pagination,
                        showAlertBanner: isError,
                        showProgressBars: isRefetching,
                        sorting,
                    }}
                    //positionToolbarDropZone={"top"}
                    positionExpandColumn={"first"}
                    positionActionsColumn={"first"}
                    positionToolbarAlertBanner="bottom"
                    localization={MRT_Localization_PT_BR}
                />
            </ThemeProvider>
        </div>
    );
};
export default Index;
