import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import { Context } from "../../../context/UserContext";
import Form from "./Form";
import styles from "../../form/Form.module.css";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";

//utils
import * as EmpresaAPI from "../../../api/empresa";
import * as UsuarioAPI from "../../../api/usuario";
import CRUDsAPI from "../../../api/CRUDs";

const CreateOrEdit = () => {
    const [dados, setDados] = useState({});
    const [token] = useState(localStorage.getItem("token")) || "";
    const { parametro } = useContext(Context);

    const [OptionsEmpresa, setEmpresa] = useState([]);
    const [OptionsUsuarios, setUsuario] = useState([]);

    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    const uuid = urlParams.get("uuid");

    let btnText = "";
    !uuid ? (btnText = "Cadastrar") : (btnText = "Atualizar");

    //carregar API's Externas
    const consultaEmpresa = async () => {
        let empresa = await EmpresaAPI.empresa(localStorage.getItem("empresaId"));
        setEmpresa(empresa);
    };

    const consultaUsuario = async () => {
        let usuario = await UsuarioAPI.usuario("", "");
        setUsuario(usuario);
    };

    useEffect(() => {
        if (!token) {
            return navigate("/Auth", { replace: true });
        }

        consultaEmpresa();
        consultaUsuario();

        if (uuid) {
            CRUDsAPI.permissoes
                .consulta(uuid)
                .then((response) => {
                    setDados(response.data);
                })
                .catch((error) => {
                    console.error(error);
                    setFlashMessage(error.response.data.message, error.response.data.type);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, uuid]);

    async function managerDados(dados) {
        let urlNavigate = "";

        try {
            let response;

            if (uuid) {
                response = await CRUDsAPI.permissoes.editar(dados);
            } else {
                response = await CRUDsAPI.permissoes.cadastrar(dados);

                if (response.status === 202) {
                    setDados(response.data);
                    response.data.message = "Registro em duplicidade!";
                    response.data.type = "warning";
                    urlNavigate = `/Permissoes/CreateOrEdit?uuid=${response.data.uuid}`;
                } else {
                    urlNavigate = "/Permissoes";
                }
            }

            setFlashMessage(response.data.message, response.data.type);

            setTimeout(() => {
                if (response.data.type === "success") {
                    navigate("/Permissoes", {
                        ul: urlParams.get("ul"),
                        li: urlParams.get("li"),
                        tipo: urlParams.get("tipo"),
                    });
                } else {
                    navigate(urlNavigate);
                }
            }, parametro.msgTempo * 1000);
        } catch (error) {
            console.error(error);
            setFlashMessage(error.response.data.message, error.response.data.type);
        }
    }
    return (
        <section className={`${styles.form_container} ${styles[parametro.tema]}`}>
            <Form
                token={token}
                handleSubmit={managerDados}
                formData={dados}
                btnText={btnText}
                caminhoVoltar={`/Permissoes`}
                OptionsEmpresa={OptionsEmpresa}
                OptionsUsuarios={OptionsUsuarios}
            />
        </section>
    );
};
export default CreateOrEdit;
