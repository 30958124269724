import React, { useEffect, useMemo, useState, useContext } from "react";
import "react-tabs/style/react-tabs.css";
import "moment/locale/pt-br";

/* contexts */
import { Context } from "../../../context/UserContext";

import { Stack, Button, Box, Tooltip, IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

import { ThemeProvider } from "@mui/material";
/* tema customizado*/
import tableThemeCustom from "../../form/tableThemeCustom";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

//Import Material React Table Translations
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";

import TextArea from "../../form/TextArea";
import DataList from "../../form/DataList";
import InputNumber from "../../form/InputNumber";
import Select from "../../form/Select";
import Checkbox from "../../form/Checkbox";
import SelectEmpresa from "../../form/SelectEmpresa";

import formStyles from "../../form/Form.module.css";

/* utils */
import { formatarNumero, formatarNumeroDecimal } from "../../../utils/masks";
import tratar from "../../../utils/retornaTratamentoURL";

/* api */
import * as DataListAPI from "../../../api/dataList";
import * as TributacaoAPI from "../../../api/tributacao";
import * as TabelaAPI from "../../../api/tabela";
//import * as TabelaPrecoAPI from "../../../api/tabelaPreco";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, OptionsEmpresa, EnumTipo }) {
    const [dados, setDados] = useState(formData || {});
    const [itens, setItens] = useState([]);
    const [OptionsUF, setOptionsUF] = useState([]);

    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();

    //set datalist
    const [NCM, setNCM] = useState(formData?.NCM);
    const [OptionsNCM, setOptionsNCM] = useState([]);

    //obtem os parametros da empresa
    const { parametro } = useContext(Context);

    //para definir campos padrões
    if (!dados.empresaId) {
        dados.empresaId = localStorage.getItem("empresaId");
        dados.ativo = true;
    }

    //data and fetching state
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    const [novoItem, setNovoItem] = useState({
        uuid: null,
        tributacaoId: dados.id,
        ufId: null,
        icms: null,
        fecoep: null,
        mva: null,
        ipi: null,

        ativo: true,
        funcao: "adicionar",
    });

    const consultaTabela = async (empresaId, tabela, order) => {
        const result = await TabelaAPI.tabela(empresaId, tabela, order);
        if (tabela === "UF") {
            setOptionsUF(result);
        }
    };

    //tratar filtros se vier pela navbar
    if (localStorage.path.replace(/["]/g, "") !== window.location.pathname) {
        tratar.LimparFiltros();
        localStorage.setItem("path", JSON.stringify(window.location.pathname));
    }

    //Atualiza o Datalist
    const consultaDatalist = async (empresaId, tabela, id, descricao, condicao, filtro) => {
        let result = await DataListAPI.dataList(empresaId, tabela, id, descricao, condicao, filtro);
        return result;
    };

    //table state
    const [columnFilterFns, setColumnFiltersMode] = useState(
        localStorage.columnFilterFns.length > 2 ? JSON.parse(localStorage.columnFilterFns) : []
    );
    const [columnFilters, setColumnFilters] = useState(
        localStorage.columnFilters.length > 2 ? JSON.parse(localStorage.columnFilters) : []
    );
    const [globalFilter, setGlobalFilter] = useState(
        localStorage.globalFilter.length > 2 ? JSON.parse(localStorage.globalFilter) : ""
    );
    const [sorting, setSorting] = useState(localStorage.sorting.length > 2 ? JSON.parse(localStorage.sorting) : []);
    const [pagination, setPagination] = useState({
        pageIndex: JSON.parse(localStorage.pagination).pageIndex || 0,
        pageSize: JSON.parse(localStorage.pagination).pageSize || 5,
    });

    const tributacaoId = dados.id ? dados.id : null;

    const fetchData = async () => {
        if (!itens.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }

        if (dados.id) {
            try {
                TributacaoAPI.listarTributacoes(
                    pagination.pageIndex * pagination.pageSize,
                    pagination.pageSize,
                    JSON.stringify(columnFilters ?? []),
                    JSON.stringify(columnFilterFns ?? []),
                    globalFilter ?? "",
                    JSON.stringify(sorting ?? []),
                    tributacaoId ?? ""
                ).then((response) => {
                    setItens(response.data.data);
                    setRowCount(response.data.meta.totalRowCount);
                });
            } catch (error) {
                setIsError(true);
                console.error(error);
                return;
            }
        }

        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
    };

    const limparItem = () => {
        setNovoItem({
            tributacaoId: dados.id,
            ufId: null,
            icms: null,
            fecoep: null,
            mva: null,
            ipi: null,

            mensagem: null,
            ativo: true,
            funcao: "adicionar",
        });
        fetchData();
    };
    const CreateOrEditItem = async (novoItem) => {
        try {
            const result = await TributacaoAPI.managerTributacoes(novoItem);
            setFlashMessage(result.message, result.type);

            if (result?.status === 200 || result?.type === "success") {
                limparItem();
                fetchData();
            }
        } catch (error) {
            console.error(error);
            if (error.response && error.response.status === 422) {
                // Tratar o erro 422, que é específico da validação no servidor
                setFlashMessage("Erro de validação: " + error.response.data.message);
            } else {
                // Tratar outros erros de rede ou servidor
                setFlashMessage("Ocorreu um erro ao processar a solicitação.", "error");
            }
        }
    };

    /* TRIBUTAÇÕES */
    const handleItem = async () => {
        if (novoItem.funcao === "editar") {
            if (novoItem.uuid) {
                CreateOrEditItem(novoItem);
            } else {
                setItens((prevItens) => {
                    const novosItens = [...prevItens];
                    novosItens[novoItem.index] = {
                        ...prevItens[novoItem.index],
                        ...novoItem,
                    };
                    return novosItens;
                });
            }
        } else if (novoItem.funcao === "deletar") {
            if (novoItem.uuid) {
                try {
                    const response = await TributacaoAPI.deletarTributacoes(novoItem.uuid);
                    setFlashMessage(response.data.message, response.data.type);
                } catch (error) {
                    console.error(error);
                    setFlashMessage(error.response.data.message, error.response.data.type);
                }
            } else {
                setItens((prevItens) => {
                    const novosItens = [...prevItens];
                    novosItens.splice(novoItem.index, 1);
                    return novosItens;
                });
            }
        } else {
            //cadastrar
            if (dados.id) {
                //verificar duplicidade
                const result = await TributacaoAPI.duplicidade(novoItem.ufId);

                if (result && novoItem.funcao === "adicionar") {
                    setFlashMessage("Registo em duplicidade!", "warning");
                    handleEditar(result);
                    return;
                }

                CreateOrEditItem(novoItem);
            } else {
                const UF = await TabelaAPI.tabelaById(1, "UF", novoItem.ufId);

                // Verifica se já existe um item com o mesmo ufId
                const existeUfId = itens.find((item) => item.ufId === novoItem.ufId);

                if (existeUfId) {
                    setFlashMessage(`Já existe tributação para o Estado de ${UF.descricao}`, "warning");
                    return; // Interrompe o processo para evitar duplicidade
                }

                const novoItemArray = [
                    ...itens,
                    {
                        uuid: novoItem.uuid,
                        tributacaoId: novoItem.tributacaoId,
                        ufId: novoItem.ufId,
                        icms: novoItem.icms,
                        fecoep: novoItem.fecoep,
                        mva: novoItem.mva,
                        ipi: novoItem.ipi,

                        mensagem: novoItem.mensagem,
                        UF: UF,
                        ativo: novoItem.ativo,
                    },
                ];

                // Ordena o array pelo campo UF.descricao
                novoItemArray.sort((a, b) => {
                    if (a.UF.descricao < b.UF.descricao) return -1; // a vem antes de b
                    if (a.UF.descricao > b.UF.descricao) return 1; // b vem antes de a
                    return 0; // são iguais
                });

                setItens(novoItemArray);
                setRowCount(novoItemArray.length);

                setDados((prev) => ({
                    ...prev, // Mantém as outras propriedades do objeto
                    Tributacoes: novoItemArray,
                }));
            }
        }

        limparItem();

        fetchData();
    };

    const handleEditar = (originalRow, row) => {
        const novoItemData = {
            uuid: originalRow.uuid,
            tributacaoId: originalRow.tributacaoId,
            ufId: originalRow.ufId,
            icms: originalRow.icms,
            fecoep: originalRow.fecoep,
            mva: originalRow.mva,
            ipi: originalRow.ipi,

            mensagem: originalRow.mensagem,
            UF: originalRow.UF,

            ativo: originalRow.ativo,
            funcao: "editar",
            index: row,
        };

        setNovoItem(novoItemData);
        return;
    };

    const handleDelete = (originalRow, row) => {
        const novoItemData = {
            uuid: originalRow.uuid,
            tributacaoId: originalRow.tributacaoId,
            ufId: originalRow.ufId,
            icms: originalRow.icms,
            fecoep: originalRow.fecoep,
            mva: originalRow.mva,
            ipi: originalRow.ipi,
            ativo: originalRow.ativo,

            mensagem: originalRow.mensagem,

            UF: originalRow.UF,

            funcao: "deletar",
            index: row,
        };

        setNovoItem(novoItemData);
    };

    useEffect(() => {
        setDados(formData || {});
        setNCM(formData.NCM || {});
        setItens(formData.Tributacoes || []);
        consultaTabela(1, "UF", "descricao");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData, token]);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnFilterFns, columnFilters, globalFilter, pagination.pageIndex, pagination.pageSize, sorting, tributacaoId]);

    async function handleChange(e) {
        const { name, value, checked, type } = e.target;
        const camposBooleanos = ["ativo"];
        const camposNumericos = ["icms"];

        if (camposBooleanos.includes(name)) {
            setDados({ ...dados, [name]: checked });
        } else if (name.startsWith("novoItem")) {
            if (name === "novoItem.ufId") {
                setNovoItem({
                    ...novoItem,
                    [name.split(".")[1]]: value,
                });
            } else if (
                name === "novoItem.icms" ||
                name === "novoItem.fecoep" ||
                name === "novoItem.mva" ||
                name === "novoItem.ipi"
            ) {
                const valorFormatado = formatarNumeroDecimal(value);

                setNovoItem({
                    ...novoItem,
                    [name.split(".")[1]]: formatarNumeroDecimal(value),
                });
            } else {
                //set os dados dos checkbox
                setNovoItem({
                    ...novoItem,
                    tributacaoId: dados.id,
                    [name.split(".")[1]]: value,
                });
            }
        } else if (name.startsWith("NCM")) {
            setNCM({
                ...NCM,
                [name.split(".")[1]]: value,
            });
            if (name.startsWith("NCM.descricao")) {
                //carrega o datalist
                const result = await consultaDatalist(
                    parametro.tabelasComuns === true ? 1 : dados.empresaId,
                    "NCM",
                    "id",
                    "descricao",
                    "",
                    value
                );
                setOptionsNCM(result);
                const selectedId = document.querySelector(`datalist option[value="${value}"]`)?.dataset.id;
                setDados({
                    ...dados,
                    ncmId: selectedId ?? null,
                });

                if (document.querySelector(`datalist option[value="${value}"]`)?.value) {
                    const arr = document.querySelector(`datalist option[value="${value}"]`)?.value?.split(" ");

                    setNCM({ ncm: arr[0] });
                }
            }
        } else {
            setDados({ ...dados, [name]: value });
        }
    }
    async function handleOnBlur(e) {
        const { name, value, checked, type } = e.target;
        //verifica duplicidade
        if (name === "descricao" && btnText === "Cadastrar") {
            const duplicidade = await TabelaAPI.duplicidadeDescricao(dados.empresaId, "tabelaPreco", value);

            if (duplicidade && duplicidade.uuid) {
                setFlashMessage("Registro em duplicidade!", "warning");
                return navigate(`/Tributacao/CreateOrEdit`, {
                    uuid: duplicidade.uuid,
                });
            }
        }
    }
    async function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();
        if (!dados.empresaId) {
            dados.empresaId = parseInt(document.getElementById("empresaId").value);
        }

        handleSubmit(dados);
    };

    /* código da grid */
    const columns = useMemo(
        () => [
            {
                id: "tributacao",
                header: "Tributações por NCM",
                columns: [
                    {
                        accessorKey: "UF.descricao",
                        header: "Estado",
                        //you can access a cell in many callback column definition options like this
                        muiTableBodyCellProps: ({ cell }) => ({
                            onClick: () => {},
                        }),
                    },
                    {
                        accessorKey: "icms",
                        header: "ICMS",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.icms === null
                                ? ""
                                : parseFloat(row.icms)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }) + " %",
                    },
                    {
                        accessorKey: "fecoep",
                        header: "FECOEP",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.fecoep === null
                                ? ""
                                : parseFloat(row.fecoep)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }) + " %",
                    },
                    {
                        accessorKey: "mva",
                        header: "MVA",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.mva === null
                                ? ""
                                : parseFloat(row.mva)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }) + " %",
                    },
                    {
                        accessorKey: "ipi",
                        header: "IPI",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.ipi === null
                                ? ""
                                : parseFloat(row.ipi)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }) + " %",
                    },
                ],
            },

            //column definitions...
        ],
        [] //getCommonEditTextFieldProps
    );

    /* modo tema */
    const theme = tableThemeCustom();
    return (
        <form onSubmit={submit}>
            <div className={formStyles.stiloPadrao}>
                <h1>Tributação</h1>
                <div>
                    <SelectEmpresa
                        text="Selecione a Empresa"
                        name="empresaId"
                        options={OptionsEmpresa}
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        value={dados.empresaId || ""}
                        required
                        notOptNull={true}
                    />
                    <DataList
                        text="NCM Descrição"
                        title={"Selecione o NCM"}
                        name="NCM.descricao"
                        placeholder={"Digite mais de 3 caracteres para pesquisar"}
                        inputWidth="600px"
                        filteredOptions={OptionsNCM}
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        value={NCM?.descricao}
                        required={true}
                    />
                    <Checkbox
                        title="Quando marcada a opção ATIVA"
                        text="Ativo"
                        name="ativo"
                        type="checkbox"
                        handleOnChange={handleChange}
                        value={dados.ativo}
                        checked={dados.ativo === true ? "checked" : ""}
                    />
                </div>
                <h4>Tributações</h4>
                <div>
                    <div>
                        <Select
                            name="novoItem.ufId"
                            title="Escolha o Estado"
                            text="Estado"
                            options={OptionsUF}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={novoItem.ufId}
                            isEnum={false}
                            notOptNull={false}
                        />
                        <InputNumber
                            text="ICMS"
                            type="text"
                            name="novoItem.icms"
                            placeholder="ICMS"
                            inputWidth="100px"
                            title="aliquota do ICMS"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={novoItem.icms ? formatarNumero(novoItem.icms) : ""}
                            readonly={!novoItem.ufId}
                        />
                        <InputNumber
                            text="FECOEP"
                            type="text"
                            name="novoItem.fecoep"
                            placeholder="FECOEP"
                            inputWidth="100px"
                            title="aliquota do FECOEP"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={novoItem.fecoep ? formatarNumero(novoItem.fecoep) : ""}
                            readonly={!novoItem.ufId}
                        />
                        <InputNumber
                            text="MVA"
                            type="text"
                            name="novoItem.mva"
                            placeholder="MVA"
                            inputWidth="100px"
                            title="aliquota do MVA"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={novoItem.mva ? formatarNumero(novoItem.mva) : ""}
                            readonly={!novoItem.ufId}
                        />
                        <InputNumber
                            text="IPI"
                            type="text"
                            name="novoItem.ipi"
                            placeholder="IPI"
                            inputWidth="100px"
                            title="aliquota do IPI"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={novoItem.ipi ? formatarNumero(novoItem.ipi) : ""}
                            readonly={!novoItem.ufId}
                        />
                    </div>
                    <div>
                        <TextArea
                            text="Mensagem"
                            type="text"
                            name="novoItem.mensagem"
                            placeholder="Digite aqui a mensagem"
                            title="Digite aqui a mensagem da tributação"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={novoItem.mensagem || ""}
                            textAlign="left"
                            linhas="2"
                            colunas="60"
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            //marginTop: "1rem",
                        }}
                    >
                        <Checkbox
                            title="Ativo quando marcado"
                            text="Ativo"
                            name="novoItem.ativo"
                            type="checkbox"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={novoItem.ativo}
                            checked={novoItem.ativo === true ? "checked" : ""}
                        />
                        <Button
                            variant="contained"
                            color="inherit"
                            onClick={() => {
                                setNovoItem({
                                    uuid: null,
                                    tributacaoId: dados.id,
                                    ufId: null,
                                    icms: null,
                                    fecoep: null,
                                    mva: null,
                                    ipi: null,

                                    ativo: true,
                                    funcao: "adicionar",
                                });
                            }}
                            style={{ marginLeft: "10px" }}
                        >
                            Limpar
                        </Button>
                        <Button
                            variant="contained"
                            color={novoItem.funcao === "deletar" ? "error" : "info"}
                            onClick={() => handleItem(novoItem)}
                            disabled={!dados.ncmId || !novoItem.ufId}
                            style={{ marginLeft: "10px" }}
                            sx={{
                                marginLeft: "10px",
                                "&.Mui-disabled": {
                                    backgroundColor: "rgba(255, 255, 255, 0.12)",
                                    color: "rgba(255, 255, 255, 0.5)",
                                    border: "1px solid rgba(255, 255, 255, 0.2)", // Opcional
                                },
                            }}
                        >
                            {novoItem.funcao}
                        </Button>
                    </div>
                </div>
            </div>

            <ThemeProvider theme={theme}>
                <MaterialReactTable
                    displayColumnDefOptions={{
                        "mrt-row-actions": {
                            muiTableHeadCellProps: {
                                align: "center",
                            },
                            size: 100,
                        },
                        "mrt-row-numbers": {
                            enableColumnOrdering: true, //turn on some features that are usually off
                            enableResizing: true,
                            muiTableHeadCellProps: {
                                sx: {
                                    fontSize: "1.2rem",
                                },
                            },
                        },
                        "mrt-row-select": {
                            enableColumnActions: true,
                            enableHiding: true,
                            size: 50,
                        },
                    }}
                    enableColumnFilterModes
                    enableGlobalFilterModes
                    //enableColumnResizing
                    enableColumnOrdering
                    enableEditing
                    renderRowActions={({ row, table }) => (
                        <Box sx={{ display: "flex", gap: "1rem" }}>
                            <Tooltip arrow placement="left" title="Editar">
                                <span>
                                    {" "}
                                    <IconButton
                                        onClick={() => handleEditar(row.original, row.id)}
                                        disabled={localStorage.getItem("editar") === "true" ? false : true}
                                    >
                                        <Edit />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip arrow placement="right" title="Deletar">
                                <span>
                                    <IconButton
                                        color="error"
                                        //onClick={() => handleOpenDialog(row.id)}
                                        onClick={() => handleDelete(row.original, row.id)}
                                        disabled={localStorage.getItem("cancelar") === "true" ? false : true}
                                    >
                                        <Delete />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Box>
                    )}
                    renderTopToolbarCustomActions={() => (
                        <div
                            style={{
                                display: "flex",
                                gap: "8px",
                            }}
                        >
                            <Tooltip arrow placement="top-end" title={`Novo Registro`}>
                                <div>
                                    {" "}
                                    <Button
                                        color="secondary"
                                        onClick={() => navigate("/Tributacao/CreateOrEdit")}
                                        variant="contained"
                                        disabled={
                                            true
                                            // localStorage.getItem("incluir") ===
                                            // "true"
                                            //     ? false
                                            //     : true
                                        }
                                    >
                                        Novo
                                    </Button>
                                </div>
                            </Tooltip>
                        </div>
                    )}
                    columns={columns}
                    data={itens}
                    enableRowSelection
                    getRowId={(row) => row.uuid}
                    manualFiltering
                    manualPagination
                    manualSorting
                    muiToolbarAlertBannerProps={
                        isError
                            ? {
                                  color: "error",
                                  children: "Error loading data",
                              }
                            : undefined
                    }
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setColumnFiltersMode}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    rowCount={rowCount}
                    initialState={{
                        showColumnFilters: true,
                        density: "compact",
                        //columnPinning: { left: ["mrt-row-select"] },
                    }}
                    state={{
                        columnFilterFns,
                        columnFilters,
                        globalFilter,
                        isLoading,
                        pagination,
                        showAlertBanner: isError,
                        showProgressBars: isRefetching,
                        sorting,
                    }}
                    //positionToolbarDropZone={"top"}
                    positionExpandColumn={"first"}
                    positionActionsColumn={"first"}
                    positionToolbarAlertBanner="bottom"
                    localization={MRT_Localization_PT_BR}
                />
            </ThemeProvider>

            <div>
                <Stack direction="row" spacing={1}>
                    <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                        Voltar
                    </Button>
                    <Button type="submit" variant="contained" color="success">
                        {btnText}
                    </Button>
                </Stack>
            </div>
        </form>
    );
}
export default Form;
