// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextArea_form_control__yxgmR {
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 1em;
}
.TextArea_form_control__yxgmR label {
    margin-left: 0.7em;
    margin-bottom: 0.3em;
    font-weight: bold;
    font-size: 0.8em;
}

.TextArea_form_control__yxgmR textarea {
    width: 100%;
    padding: 0.7em;
    border: 1px solid #777;
    border-radius: 5px;
    margin-left: 0.7em;
    box-sizing: border-box; /* Evita que o padding afete a largura total */
    background-color: #ffe7e7;
}

.TextArea_form_control__yxgmR.TextArea_dark__4r-Yg textarea {
    background-color: #484848;
    color: #ffe7e7;
}

.TextArea_form_control__yxgmR textarea::placeholder {
    color: #7b7b7b;
}
`, "",{"version":3,"sources":["webpack://./src/components/form/TextArea.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,sBAAsB;IACtB,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,cAAc;IACd,sBAAsB;IACtB,kBAAkB;IAClB,kBAAkB;IAClB,sBAAsB,EAAE,8CAA8C;IACtE,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".form_control {\n    display: inline-flex;\n    flex-direction: column;\n    margin-bottom: 1em;\n}\n.form_control label {\n    margin-left: 0.7em;\n    margin-bottom: 0.3em;\n    font-weight: bold;\n    font-size: 0.8em;\n}\n\n.form_control textarea {\n    width: 100%;\n    padding: 0.7em;\n    border: 1px solid #777;\n    border-radius: 5px;\n    margin-left: 0.7em;\n    box-sizing: border-box; /* Evita que o padding afete a largura total */\n    background-color: #ffe7e7;\n}\n\n.form_control.dark textarea {\n    background-color: #484848;\n    color: #ffe7e7;\n}\n\n.form_control textarea::placeholder {\n    color: #7b7b7b;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form_control": `TextArea_form_control__yxgmR`,
	"dark": `TextArea_dark__4r-Yg`
};
export default ___CSS_LOADER_EXPORT___;
