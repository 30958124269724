import api from "./";

export const permissoes = async (pessoaId, empresaId, menuId, menuSubId) => {
    try {
        const { data } = await api.get("/servicePermissoesUsuarios", { params: { pessoaId, empresaId, menuId, menuSubId } });
        return data;
    } catch (error) {
        return error;
    }
};

export const usuario = async (pessoaId, empresaId) => {
    try {
        const { data } = await api.get("/serviceUSUARIO", { params: { pessoaId, empresaId } });
        return data;
    } catch (error) {
        return error;
    }
};

export const login = async (usuario) => api.post("/login", usuario);

export const assinatura = async (empresaId, usuario, senha, uuid, tabela) => {
    try {
        const { data } = await api.get("/assinatura", { params: { empresaId, usuario, senha, uuid, tabela } });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};
