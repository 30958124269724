import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

/* components */
import Header from "./components/layout/Header";
import Navbar from "./components/layout/Navbar";
import Container from "./components/layout/Container";
import Footer from "./components/layout/Footer";
import Message from "./components/layout/Message";

/*  pages */
import Home from "./components/pages/Home";
import Login from "./components/pages/Auth/Login";

/* Administração */
import Ambiente from "./components/pages/Ambiente/Index";
import AmbienteCreateOrEdit from "./components/pages/Ambiente/CreateOrEdit";

import Banco from "./components/pages/Bancos/Index";
import BancoCreateOrEdit from "./components/pages/Bancos/CreateOrEdit";

import CFOP from "./components/pages/CFOP/Index";
import CFOPCreateOrEdit from "./components/pages/CFOP/CreateOrEdit";

//Estado
import Uf from "./components/pages/UF/Index";
import UfEdit from "./components/pages/UF/Edit";

import Menu from "./components/pages/Menus/Index";
import MenuCreateOrEdit from "./components/pages/Menus/CreateOrEdit";

import MenuSub from "./components/pages/MenusSub/Index";
import MenuSubCreateOrEdit from "./components/pages/MenusSub/CreateOrEdit";

import Municipio from "./components/pages/Municipios/Index";
import MunicipioCreateOrEdit from "./components/pages/Municipios/CreateOrEdit";

import NCM from "./components/pages/NCM/Index";
import NCMCreateOrEdit from "./components/pages/NCM/CreateOrEdit";

import Operacao from "./components/pages/Operacao/Index";
import OperacaoCreateOrEdit from "./components/pages/Operacao/CreateOrEdit";

import Situacao from "./components/pages/Situacao/Index";
import SituacaoCreateOrEdit from "./components/pages/Situacao/CreateOrEdit";

/* Administrativo */
import Plantao from "./components/pages/Plantao/Index";
import PlantaoCreateOrEdit from "./components/pages/Plantao/CreateOrEdit";

/* Cadastros */
import Anilox from "./components/pages/Anilox/Index";
import AniloxCreateOrEdit from "./components/pages/Anilox/CreateOrEdit";

import Assinaturas from "./components/pages/Assinaturas/Index";
import AssinaturasCreateOrEdit from "./components/pages/Assinaturas/CreateOrEdit";

import Bobinas from "./components/pages/Bobinas/Index";
import BobinasCreateOrEdit from "./components/pages/Bobinas/CreateOrEdit";

import Cilindros from "./components/pages/Cilindros/Index";
import CilindrosCreateOrEdit from "./components/pages/Cilindros/CreateOrEdit";

import Cores from "./components/pages/Cores/Index";
import CoresCreateOrEdit from "./components/pages/Cores/CreateOrEdit";

import Equipe from "./components/pages/Equipes/Index";
import EquipeCreateOrEdit from "./components/pages/Equipes/CreateOrEdit";

import Embalagem from "./components/pages/Embalagens/Index";
import EmbalagemCreateOrEdit from "./components/pages/Embalagens/CreateOrEdit";

import Imovel from "./components/pages/Imovel/Index";
import ImovelCreateOrEdit from "./components/pages/Imovel/CreateOrEdit";
import ImovelEditarNF from "./components/pages/Imovel/EditarNF";

import Maquinas from "./components/pages/Maquinas/Index";
import MaquinasCreateOrEdit from "./components/pages/Maquinas/CreateOrEdit";

import Pessoa from "./components/pages/Pessoas/Index";
import PessoaCreateOrEdit from "./components/pages/Pessoas/CreateOrEdit";

import TabelaPreco from "./components/pages/tabelaPrecos/Index";
import TabelaPrecoCreateOrEdit from "./components/pages/tabelaPrecos/CreateOrEdit";

import Tributacao from "./components/pages/Tributacao/Index";
import TributacaoCreateOrEdit from "./components/pages/Tributacao/CreateOrEdit";

import Unidades from "./components/pages/Unidades/Index";
import UnidadeCreateOrEdit from "./components/pages/Unidades/CreateOrEdit";

/* Estoque */
import Almoxarifado from "./components/pages/Almoxarifado/Index";
import AlmoxarifadoCreateOrEdit from "./components/pages/Almoxarifado/CreateOrEdit";

import Produto from "./components/pages/Produto/Index";
import ProdutoCreateOrEdit from "./components/pages/Produto/CreateOrEdit";

/* Financeiro */
import Forma from "./components/pages/Forma/Index";
import FormaCreateOrEdit from "./components/pages/Forma/CreateOrEdit";
import Prazo from "./components/pages/Prazo/Index";
import PrazoCreateOrEdit from "./components/pages/Prazo/CreateOrEdit";

import CentroCusto from "./components/pages/CentroCustos/Index";
import CentroCustoCreateOrEdit from "./components/pages/CentroCustos/CreateOrEdit";
import CentroCustoLimite from "./components/pages/CentroCustoLimites/Index";
import CentroCustoLimiteCreateOrEdit from "./components/pages/CentroCustoLimites/CreateOrEdit";
import CentroCustoLimiteDuplicar from "./components/pages/CentroCustoLimites/Duplicar";

import Conta from "./components/pages/Contas/Index";
import ContaCreateOrEdit from "./components/pages/Contas/CreateOrEdit";
import CPR from "./components/pages/Lancamentos/Index";
import CPRCreateOrEdit from "./components/pages/Lancamentos/CreateOrEdit";
import CPRBaixaLancamento from "./components/pages/Lancamentos/BaixaLancamento";
import CPRBaixas from "./components/pages/lancamentosBaixas/Index";
import Extrato from "./components/pages/lancamentoContas/Index";
import ExtratoCreateOrEdit from "./components/pages/lancamentoContas/CreateOrEdit";

/* Frota */
import ProdutoModelo from "./components/pages/ProdutoModelo/Index";
import ProdutoModeloCreateOrEdit from "./components/pages/ProdutoModelo/CreateOrEdit";
import MapaAbastecimento from "./components/pages/MapaAbastecimento/Index";
import MapaAbastecimentoCreateOrEdit from "./components/pages/MapaAbastecimento/CreateOrEdit";
import Veiculo from "./components/pages/Veiculo/Index";
import VeiculoCreateOrEdit from "./components/pages/Veiculo/CreateOrEdit";

/* Movimentos */
import Entradas from "./components/pages/MovimentoEntradas/Index";
import EntradasCreateOrEdit from "./components/pages/MovimentoEntradas/CreateOrEdit";
import LIM from "./components/pages/MovimentoLIM/Index";
import LIMCreateOrEdit from "./components/pages/MovimentoLIM/CreateOrEdit";
import PDC from "./components/pages/Movimento/Index";
import PDCCreateOrEdit from "./components/pages/Movimento/CreateOrEdit";
import Saidas from "./components/pages/MovimentoSaidas/Index";
import SaidasCreateOrEdit from "./components/pages/MovimentoSaidas/CreateOrEdit";

/* Parametros */
import Empresa from "./components/pages/Empresas/Index";
import EmpresaCreateOrEdit from "./components/pages/Empresas/CreateOrEdit";
import ParametroOperacoes from "./components/pages/ParametroOperacoes/Index";
import ParametroOperacoesCreateOrEdit from "./components/pages/ParametroOperacoes/CreateOrEdit";
import Parametro from "./components/pages/Parametros/Index";
import ParametroCreateOrEdit from "./components/pages/Parametros/CreateOrEdit";
import PessoaEmpresa from "./components/pages/PessoaEmpresa/Index";
import PessoaEmpresaCreateOrEdit from "./components/pages/PessoaEmpresa/CreateOrEdit";
import Permissoes from "./components/pages/Permissoes/Index";
import PermissoesCreateOrEdit from "./components/pages/Permissoes/CreateOrEdit";

/* contexts */
import { UserProvider } from "./context/UserContext";
//import { Provider } from "react-redux";

function App() {
    return (
        <Router>
            <UserProvider>
                <Header />
                <Navbar />
                <Message />
                <Container>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/Auth" element={<Login />} />

                        {/* Administração */}
                        <Route path="/Ambiente" element={<Ambiente />} />
                        <Route path="/Ambiente/CreateOrEdit" element={<AmbienteCreateOrEdit />} />

                        <Route path="/Bancos" element={<Banco />} />
                        <Route path="/Bancos/CreateOrEdit" element={<BancoCreateOrEdit />} />

                        <Route path="/CFOP" element={<CFOP />} />
                        <Route path="/CFOP/CreateOrEdit" element={<CFOPCreateOrEdit />} />

                        <Route path="/Uf" element={<Uf />} />
                        <Route path="Uf/Edit/:uuid" element={<UfEdit />} />

                        <Route path="/Menu" element={<Menu />} />
                        <Route path="/Menu/CreateOrEdit" element={<MenuCreateOrEdit />} />

                        <Route path="/MenuSub" element={<MenuSub />} />
                        <Route path="/MenuSub/CreateOrEdit" element={<MenuSubCreateOrEdit />} />

                        <Route path="/Municipio" element={<Municipio />} />
                        <Route path="/Municipio/CreateOrEdit" element={<MunicipioCreateOrEdit />} />

                        <Route path="/NCM" element={<NCM />} />
                        <Route path="/NCM/CreateOrEdit" element={<NCMCreateOrEdit />} />

                        <Route path="/Operacao" element={<Operacao />} />
                        <Route path="/Operacao/CreateOrEdit" element={<OperacaoCreateOrEdit />} />

                        <Route path="/Situacao" element={<Situacao />} />
                        <Route path="/Situacao/CreateOrEdit" element={<SituacaoCreateOrEdit />} />

                        {/* Administrativo */}
                        <Route path="/Plantao" element={<Plantao />} />
                        <Route path="/Plantao/CreateOrEdit" element={<PlantaoCreateOrEdit />} />

                        {/* Cadastros */}
                        <Route path="/Anilox" element={<Anilox />} />
                        <Route path="/Anilox/CreateOrEdit" element={<AniloxCreateOrEdit />} />
                        <Route path="/Assinaturas" element={<Assinaturas />} />
                        <Route path="/Assinaturas/CreateOrEdit" element={<AssinaturasCreateOrEdit />} />
                        <Route path="/Bobinas" element={<Bobinas />} />
                        <Route path="/Bobinas/CreateOrEdit" element={<BobinasCreateOrEdit />} />
                        <Route path="/Cilindros" element={<Cilindros />} />
                        <Route path="/Cilindros/CreateOrEdit" element={<CilindrosCreateOrEdit />} />
                        <Route path="/Cores" element={<Cores />} />
                        <Route path="/Cores/CreateOrEdit" element={<CoresCreateOrEdit />} />
                        <Route path="/Equipes/CreateOrEdit" element={<EquipeCreateOrEdit />} />
                        <Route path="/Equipes" element={<Equipe />} />
                        <Route path="/Embalagens" element={<Embalagem />} />
                        <Route path="/Embalagens/CreateOrEdit" element={<EmbalagemCreateOrEdit />} />

                        <Route path="/Imovel" element={<Imovel />} />
                        <Route path="/Imovel/CreateOrEdit" element={<ImovelCreateOrEdit />} />
                        <Route path="/Imovel/EditarNF" element={<ImovelEditarNF />} />

                        <Route path="/Maquinas" element={<Maquinas />} />
                        <Route path="/Maquinas/CreateOrEdit" element={<MaquinasCreateOrEdit />} />
                        <Route path="/Pessoa" element={<Pessoa />} />
                        <Route path="/Pessoa/CreateOrEdit" element={<PessoaCreateOrEdit />} />
                        <Route path="/tabelaPrecos" element={<TabelaPreco />} />
                        <Route path="/tabelaPrecos/CreateOrEdit" element={<TabelaPrecoCreateOrEdit />} />
                        <Route path="/Tributacao" element={<Tributacao />} />
                        <Route path="/Tributacao/CreateOrEdit" element={<TributacaoCreateOrEdit />} />
                        <Route path="/Unidades" element={<Unidades />} />
                        <Route path="/Unidades/CreateOrEdit" element={<UnidadeCreateOrEdit />} />
                        {/* Estoque */}
                        <Route path="/Almoxarifado" element={<Almoxarifado />} />
                        <Route path="/Almoxarifado/CreateOrEdit" element={<AlmoxarifadoCreateOrEdit />} />

                        <Route path="/Produtos" element={<Produto />} />
                        <Route path="/Produtos/CreateOrEdit" element={<ProdutoCreateOrEdit />} />

                        {/* Financeiro */}
                        <Route path="/CentroCustos" element={<CentroCusto />} />
                        <Route path="/CentroCustos/CreateOrEdit" element={<CentroCustoCreateOrEdit />} />
                        <Route path="/CentroCustoLimites" element={<CentroCustoLimite />} />
                        <Route path="/CentroCustoLimites/CreateOrEdit" element={<CentroCustoLimiteCreateOrEdit />} />

                        <Route path="/CentroCustoLimites/Duplicar" element={<CentroCustoLimiteDuplicar />} />
                        <Route path="/Contas" element={<Conta />} />
                        <Route path="/Contas/CreateOrEdit" element={<ContaCreateOrEdit />} />
                        <Route path="/lancamentoContas" element={<Extrato />} />
                        <Route path="/lancamentoContas/CreateOrEdit" element={<ExtratoCreateOrEdit />} />
                        <Route path="/Forma" element={<Forma />} />
                        <Route path="/Forma/CreateOrEdit" element={<FormaCreateOrEdit />} />
                        <Route path="/Prazo" element={<Prazo />} />
                        <Route path="/Prazo/CreateOrEdit" element={<PrazoCreateOrEdit />} />
                        <Route path="/Lancamentos" element={<CPR />} />
                        <Route path="/Lancamentos/CreateOrEdit" element={<CPRCreateOrEdit />} />
                        <Route path="/Lancamentos/BaixaLancamento" element={<CPRBaixaLancamento />} />
                        <Route path="/LancamentosBaixas" element={<CPRBaixas />} />

                        {/* Frota */}
                        <Route path="/ProdutoModelo" element={<ProdutoModelo />} />
                        <Route path="/ProdutoModelo/CreateOrEdit" element={<ProdutoModeloCreateOrEdit />} />
                        <Route path="/MapaAbastecimento" element={<MapaAbastecimento />} />
                        <Route path="/MapaAbastecimento/CreateOrEdit" element={<MapaAbastecimentoCreateOrEdit />} />
                        <Route path="/Veiculo" element={<Veiculo />} />
                        <Route path="/Veiculo/CreateOrEdit" element={<VeiculoCreateOrEdit />} />

                        {/* Movimentos */}
                        <Route path="/MovimentoEntradas" element={<Entradas />} />
                        <Route path="/MovimentoEntradas/CreateOrEdit" element={<EntradasCreateOrEdit />} />
                        <Route path="/MovimentoLIM" element={<LIM />} />
                        <Route path="/MovimentoLIM/CreateOrEdit" element={<LIMCreateOrEdit />} />
                        <Route path="/Movimento" element={<PDC />} />
                        <Route path="/Movimento/CreateOrEdit" element={<PDCCreateOrEdit />} />
                        <Route path="/MovimentoSaidas" element={<Saidas />} />
                        <Route path="/MovimentoSaidas/CreateOrEdit" element={<SaidasCreateOrEdit />} />

                        {/* Parâmetros */}
                        <Route path="/Empresa" element={<Empresa />} />
                        <Route path="/Empresa/CreateOrEdit" element={<EmpresaCreateOrEdit />} />
                        <Route path="/ParametroOperacoes" element={<ParametroOperacoes />} />
                        <Route path="/ParametroOperacoes/CreateOrEdit" element={<ParametroOperacoesCreateOrEdit />} />
                        <Route path="/Parametro" element={<Parametro />} />
                        <Route path="/Parametro/CreateOrEdit" element={<ParametroCreateOrEdit />} />
                        <Route path="/Permissoes" element={<Permissoes />} />
                        <Route path="/Permissoes/CreateOrEdit" element={<PermissoesCreateOrEdit />} />

                        <Route path="/PessoaEmpresa" element={<PessoaEmpresa />} />
                        <Route path="/PessoaEmpresa/CreateOrEdit" element={<PessoaEmpresaCreateOrEdit />} />
                    </Routes>
                </Container>
                <Footer />
            </UserProvider>
        </Router>
    );
}

export default App;
