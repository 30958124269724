import { useState, useEffect } from "react";
import "react-tabs/style/react-tabs.css";
import "moment/locale/pt-br";

import { Stack, Button } from "@mui/material";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import * as cfopAPI from "../../../api/cfop";

import Input from "../../form/Input";
import TextArea from "../../form/TextArea";
import Checkbox from "../../form/Checkbox";
import SelectEmpresa from "../../form/SelectEmpresa";

/* utils */
import { formatarNumeroDecimal } from "../../../utils/masks";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, OptionsEmpresa }) {
    const [dados, setDados] = useState(formData || {});
    const navigate = useNavigateParams();
    const { setFlashMessage } = useFlashMessage();

    //define o estado inicial do objeto
    if (!dados.empresaId) {
        dados.empresaId = localStorage.getItem("empresaId");
        dados.ativo = true;
    }

    useEffect(() => {
        setDados(formData || {});
    }, [formData]);

    async function handleChange(e) {
        if (e.target.name === "ativo") {
            setDados({ ...dados, [e.target.name]: e.target.checked });
        } else if (e.target.name === "aliquota") {
            const valorFormatado = formatarNumeroDecimal(e.target.value);
            setDados({
                ...dados,
                [e.target.name]: valorFormatado,
            });
        } else {
            setDados({ ...dados, [e.target.name]: e.target.value });
        }
    }

    async function handleOnBlur(e) {
        //verifica duplicidade
        if (e.target.name === "cfop" && btnText === "Cadastrar") {
            const duplicidade = await cfopAPI.duplicidadeCFOP(document.getElementById("empresaId").value, e.target.value);
            if (duplicidade?.uuid) {
                setFlashMessage("Registro em duplicidade!", "warning");
                return navigate(`/CFOP/CreateOrEdit`, {
                    uuid: duplicidade.uuid,
                });
            }
        }
    }
    function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();

        handleSubmit(dados);
    };

    return (
        <form onSubmit={submit}>
            <h1>CFOP - Código Fiscal de Operações e Prestações</h1>
            <div>
                <SelectEmpresa
                    text="Selecione a Empresa"
                    name="empresaId"
                    options={OptionsEmpresa}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.empresaId || ""}
                    required
                    notOptNull={true}
                />
                <Input
                    text="CFOP"
                    type="text"
                    name="cfop"
                    placeholder="CFOP"
                    title="Digite o código da operação fiscal"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.cfop || ""}
                />
                <Input
                    text="CFOP Formatado"
                    type="text"
                    name="cfopFormatado"
                    placeholder="CFOP"
                    title="Digite o código da operação fiscal"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.cfopFormatado || ""}
                />
                <Checkbox
                    title="Ativa quando marcado"
                    text="Ativo"
                    name="ativo"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.ativo}
                    checked={dados.ativo === true ? "checked" : ""}
                />
            </div>
            <div>
                <TextArea
                    text="Descrição"
                    type="text"
                    name="descricao"
                    placeholder="nome do CFOP"
                    title="Digite o nome do CFOP"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.descricao || ""}
                    required
                    textAlign="left"
                    linhas="4"
                    colunas="122"
                />
            </div>
            <div>
                <TextArea
                    text="Aplicacao"
                    type="text"
                    name="aplicacao"
                    placeholder="Aplicação"
                    inputWidth="500px"
                    title="Descrição da aplicação do CFOP"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.aplicacao || ""}
                    textAlign="left"
                    linhas="4"
                    colunas="122"
                />
            </div>
            <div>
                <Stack direction="row" spacing={1}>
                    <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                        Voltar
                    </Button>
                    <Button type="submit" variant="contained" color="success">
                        {btnText}
                    </Button>
                </Stack>
            </div>
        </form>
    );
}
export default Form;
