import React, { useEffect, useMemo, useState, useContext } from "react";
import { Context } from "../../../context/UserContext";

import moment from "moment";

import { useDispatch } from "react-redux";
import { selectComponent, clearSelectedComponent } from "../../../store/reducers/actions";

import { useNavigateParams } from "../../../hooks/useNavigateParams";
import MaterialReactTable from "material-react-table";

import { ThemeProvider } from "@mui/material";
/* tema customizado*/
import tableThemeCustom from "../../form/tableThemeCustom";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";

//Import Material React Table Translations
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";

import { Box, Button, IconButton, Tooltip, Checkbox } from "@mui/material";
import { Delete, Edit, ContentCopy } from "@mui/icons-material";

import CustomDialog from "../../layout/CustomDialog";
import tratar from "../../../utils/retornaTratamentoURL";
import * as EmpresaAPI from "../../../api/empresa";
import CrudsAPI from "../../../api/CRUDs";
import { relatorioLimiteCentroCustoPorMes } from "../../../api/centroCustoLimite";
import { recebeAnoMesRetornaPorExtenso } from "../../../utils/retornaDataFormatada";

/* util */
import { obterPermissoesUsuario } from "../../../utils/funcoes";

const Index = () => {
    const [dados, setDados] = useState([]);
    const [token] = useState(localStorage.getItem("token")) || "";
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();
    const urlParams = new URLSearchParams(window.location.search);
    const ul = urlParams.get("ul") || 3;
    const li = urlParams.get("li") || 37;
    const tipo = urlParams.get("tipo") || "Pagar";

    const { parametro } = useContext(Context);
    /* Obtem a paginação padrão */
    useEffect(() => {
        if (parametro && parametro.paginacao) {
            setPagination({
                pageIndex: 0,
                pageSize: parametro.paginacao,
            });
        }
    }, [parametro]);

    //options
    const [OptionsCentroCustos, setCC] = useState([]);

    //dialog customizado
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});

    //duplicar varios
    const dispatch = useDispatch();

    // Formate a data no formato desejado (YYYY-MM)
    const dataAtual = moment();
    const dataFormatada = dataAtual.format("YYYY-MM");

    //data and fetching state
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    //tratar filtros se vier pela navbar
    if (localStorage.path.replace(/["]/g, "") !== window.location.pathname) {
        tratar.LimparFiltros();
        localStorage.setItem("path", JSON.stringify(window.location.pathname));
    }

    //table state
    const [columnFilterFns, setColumnFiltersMode] = useState(
        localStorage.columnFilterFns.length > 2 ? JSON.parse(localStorage.columnFilterFns) : []
    );

    const [columnFilters, setColumnFilters] = useState(
        localStorage.columnFilters.length > 2
            ? JSON.parse(localStorage.columnFilters)
            : [
                  {
                      id: "mes",
                      value: [dataFormatada],
                  },
              ]
    );

    const [globalFilter, setGlobalFilter] = useState(
        localStorage.globalFilter.length > 2 ? JSON.parse(localStorage.globalFilter) : ""
    );

    const [sorting, setSorting] = useState(localStorage.sorting.length > 2 ? JSON.parse(localStorage.globalFilter) : []);

    const [pagination, setPagination] = useState({
        pageIndex: JSON.parse(localStorage.pagination).pageIndex || 0,
        pageSize: JSON.parse(localStorage.pagination).pageSize || 10,
    });

    //atualizar a grid
    const fetchData = async () => {
        if (!dados.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }

        const url = new URL(
            "/centroCustoLimite/listar",
            process.env.NODE_ENV === "production" ? process.env.REACT_APP_API : "http://localhost:3333/"
        );
        url.searchParams.set("start", `${pagination.pageIndex * pagination.pageSize}`);
        url.searchParams.set("size", `${pagination.pageSize}`);
        url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
        url.searchParams.set("filtersMode", JSON.stringify(columnFilterFns ?? []));
        url.searchParams.set("globalFilter", globalFilter ?? "");
        url.searchParams.set("sorting", JSON.stringify(sorting ?? []));

        try {
            CrudsAPI.centroCustoLimite
                .listar(
                    pagination.pageIndex * pagination.pageSize,
                    pagination.pageSize,
                    JSON.stringify(columnFilters ?? []),
                    JSON.stringify(columnFilterFns ?? []),
                    globalFilter ?? "",
                    JSON.stringify(sorting ?? [])
                )
                .then((response) => {
                    setDados(response.data.data);
                    setRowCount(response.data.meta.totalRowCount);
                });
        } catch (error) {
            setIsError(true);
            console.error(error);
            return;
        }
        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
    };
    const consultaCC = async () => {
        let result = await EmpresaAPI.centroCustos(dados.empresaId, null, tipo, null, null, null);
        // Mapear o array para extrair apenas 'id' e 'descricao'
        const mappedResult = result.map((item) => ({
            text: item.descricao,
            value: item.descricao,
        }));

        setCC(mappedResult);
    };

    //funções do dialog
    const handleOpenDialog = (row) => {
        setOpenDialog(true);
        setSelectedRow(row);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmDialog = async (row) => {
        // Verifique se há uma linha selecionada
        let msgType = "success";
        if (selectedRow) {
            try {
                const response = await CrudsAPI.centroCustoLimite.deletar(selectedRow);

                setFlashMessage(response.data.message, msgType);

                // Após a exclusão, chame a função fetchData() novamente para atualizar as unidades
                fetchData();
            } catch (error) {
                msgType = "error";
                console.error(error);
                setFlashMessage(error.response.data.message, msgType);
            }
        }
        setOpenDialog(false);
    };

    useEffect(() => {
        if (!token) {
            navigate("/Auth", { replace: true });
        }
    }, [token, navigate]);

    useEffect(() => {
        //funcao para setar localStorage
        localStorage.setItem(
            "pagination",
            JSON.stringify({
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
            })
        );
        localStorage.setItem("columnFilterFns", JSON.stringify(columnFilterFns));
        localStorage.setItem("columnFilters", JSON.stringify(columnFilters));
        localStorage.setItem("globalFilter", JSON.stringify(globalFilter));
        localStorage.setItem("sorting", JSON.stringify(sorting));

        //permissões do usuario
        obterPermissoesUsuario(localStorage.getItem("pessoaId"), localStorage.getItem("empresaId"), ul, li);

        consultaCC();

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnFilterFns, columnFilters, globalFilter, pagination.pageIndex, pagination.pageSize, sorting]);

    const columns = useMemo(
        //column definitions...
        () => [
            {
                id: "CentroCusto",
                header: "Limite de Centros de Custos",
                columns: [
                    {
                        accessorKey: "ativo",
                        header: "Ativo",
                        columnFilterModeOptions: ["equals"],
                        filterSelectOptions: [
                            { text: "Todos", value: "" },
                            { text: "Ativo", value: 1 },
                            { text: "Inativo", value: 0 },
                        ],
                        filterVariant: "select",
                        muiTableBodyCellProps: ({ row }) => ({
                            align: "center",
                            onClick: () => {},
                        }),
                        Cell: ({ renderedCellValue, row }) => {
                            return (
                                <Checkbox
                                    checked={row.original.ativo}
                                    sx={{
                                        "& .MuiSvgIcon-root": { fontSize: 28 },
                                    }}
                                    inputProps={{ "aria-label": "controlled" }}
                                />
                            );
                        },
                    },
                    {
                        accessorKey: "centroCusto.descricao",
                        header: "Centro de Custo",
                        filterVariant: "select",
                        filterSelectOptions: OptionsCentroCustos,
                    },
                    // {
                    //     accessorKey: "centroCusto.tipo",
                    //     header: "Tipo",
                    // },
                    {
                        accessorKey: "mes",
                        header: "Mês",
                        sortingFn: "date",
                        columnFilterModeOptions: [
                            "fuzzy",
                            "equals",
                            "between",
                            "betweenInclusive",
                            "greaterThanOrEqualTo",
                            "lessThanOrEqualTo",
                        ],
                        muiTableBodyCellProps: ({ row }) => ({
                            align: "left",
                            onClick: () => {},
                        }),
                        muiTableHeadCellFilterTextFieldProps: {
                            type: "month",
                            InputLabelProps: {
                                shrink: true,
                            },
                            sx: {
                                minWidth: "200px",
                            },
                        },
                        Cell: ({ renderedCellValue, row }) => {
                            const formatoDesejado = recebeAnoMesRetornaPorExtenso(row.original.mes);
                            return <span>{formatoDesejado}</span>;
                        },
                    },
                    {
                        accessorKey: "limite",
                        header: "Limite",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.limite === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.limite)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "debito",
                        header: "Atrasado",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        columnFilterModeOptions: [],
                        accessorFn: (row) =>
                            row.debito === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.debito)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "aberto",
                        header: "Aberto",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        columnFilterModeOptions: [],
                        accessorFn: (row) =>
                            row.aberto === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.aberto)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "baixado",
                        header: "Baixado",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        columnFilterModeOptions: [],
                        accessorFn: (row) =>
                            row.baixado === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.baixado)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "meta",
                        header: "Meta",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        columnFilterModeOptions: [],
                        accessorFn: (row) =>
                            row.meta === null
                                ? ""
                                : parseFloat(row.meta)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }) + " %",
                    },
                    {
                        accessorKey: "Empresa.nomeFantasia",
                        header: "Empresa",
                    },
                ],
            },
        ],
        [OptionsCentroCustos] //getCommonEditTextFieldProps
    );
    /* modo tema */
    const theme = tableThemeCustom();

    return (
        <div>
            <div>
                {openDialog && (
                    <CustomDialog
                        title="Delete limite de CENTRO DE CUSTO!"
                        content="Você tem certeza?"
                        onClose={handleCloseDialog}
                        onConfirm={handleConfirmDialog}
                    />
                )}
            </div>
            <ThemeProvider theme={theme}>
                <MaterialReactTable
                    displayColumnDefOptions={{
                        "mrt-row-select": {
                            enableColumnActions: true,
                            enableHiding: true,
                            size: 50,
                        },
                        "mrt-row-actions": {
                            muiTableHeadCellProps: {
                                align: "center",
                            },
                            size: 100,
                        },
                        /* "mrt-row-numbers": {
                            enableColumnOrdering: true, //turn on some features that are usually off
                            enableResizing: true,
                            muiTableHeadCellProps: {
                                sx: {
                                    fontSize: "1.2rem",
                                },
                            },
                        },
                        */
                    }}
                    enableColumnFilterModes
                    enableGlobalFilterModes
                    //enableColumnResizing
                    enableColumnOrdering
                    //editingMode="modal" //default ver metodos'modal' | 'cell' | 'row' | 'table' ===> https://www.material-react-table.com/docs/guides/editing
                    enableEditing
                    //onEditingRowSave={handleSaveRowEdits}
                    //onEditingRowCancel={handleCancelRowEdits}
                    renderRowActions={({ row, table }) => {
                        const handleDuplicar = async () => {
                            navigate(
                                `/CentroCustoLimites/CreateOrEdit`,

                                {
                                    uuid: row.id,
                                    ul: ul,
                                    li: li,
                                    tipo: tipo,
                                    btnText: urlParams.get("btnText") || "Duplicar",
                                }
                            );
                        };
                        return (
                            <div>
                                {" "}
                                <Box sx={{ display: "flex", gap: "1rem" }}>
                                    <Tooltip arrow placement="left" title="Editar">
                                        <span>
                                            {" "}
                                            <IconButton
                                                onClick={() =>
                                                    navigate(`/CentroCustoLimites/CreateOrEdit`, {
                                                        uuid: row.id,
                                                        ul: ul,
                                                        li: li,
                                                        tipo: tipo,
                                                    })
                                                }
                                                disabled={localStorage.getItem("editar") === "true" ? false : true}
                                            >
                                                <Edit />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                    <Tooltip arrow placement="right" title="Deletar">
                                        <span>
                                            <IconButton
                                                color="error"
                                                onClick={() => handleOpenDialog(row)}
                                                disabled={localStorage.getItem("cancelar") === "true" ? false : true}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                    <Tooltip arrow placement="right" title="Duplicar">
                                        <span>
                                            <IconButton
                                                color="error"
                                                onClick={() => handleDuplicar(row)}
                                                disabled={localStorage.getItem("incluir") === "true" ? false : true}
                                            >
                                                <ContentCopy />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Box>
                            </div>
                        );
                    }}
                    renderTopToolbarCustomActions={({ table }) => {
                        const handleImpimirLancamento = async () => {
                            const urlSearchParams = new URLSearchParams({
                                start: `${pagination.pageIndex * pagination.pageSize}`,
                                size: `${pagination.pageSize}`,
                                filters: JSON.stringify(columnFilters ?? []),
                                filtersMode: JSON.stringify(columnFilterFns ?? []),
                                globalFilter: globalFilter ?? "",
                                sorting: JSON.stringify(sorting ?? []),
                                tipo: urlParams.get("tipo") ?? "",
                            });

                            const response = await relatorioLimiteCentroCustoPorMes(urlSearchParams);
                            // Cria um Blob a partir dos dados do arraybuffer
                            const pdfBlob = new Blob([response.data], {
                                type: "application/pdf",
                            });

                            // Cria um URL temporário para o Blob
                            const pdfUrl = URL.createObjectURL(pdfBlob);

                            // Use o URL do PDF para abrir em uma nova janela ou realizar outras ações
                            window.open(pdfUrl);
                        };
                        const handleDuplicarSelected = async () => {
                            const uuids = table
                                .getSelectedRowModel()
                                .flatRows.map((row) => row.id)
                                .flat();

                            dispatch(clearSelectedComponent()); // Limpa os valores selecionados
                            dispatch(selectComponent(uuids)); // Preenche com os novos valores
                            localStorage.setItem("selectedUUIds", uuids);
                            // Navega para a rota apenas se houver itens selecionados
                            if (uuids.length > 0) {
                                navigate("/CentroCustoLimites/Duplicar", {
                                    ul: ul,
                                    li: li,
                                    tipo: tipo,
                                });
                            }
                        };

                        return (
                            <div
                                style={{
                                    display: "flex",
                                    gap: "8px",
                                }}
                            >
                                <Tooltip arrow placement="top-end" title={`Novo Registro`}>
                                    <div>
                                        <Button
                                            color="secondary"
                                            onClick={() =>
                                                navigate("/CentroCustoLimites/CreateOrEdit", {
                                                    ul: ul,
                                                    li: li,
                                                    tipo: tipo,
                                                })
                                            }
                                            variant="contained"
                                            disabled={localStorage.getItem("incluir") === "true" ? false : true}
                                        >
                                            Novo
                                        </Button>
                                    </div>
                                </Tooltip>
                                <Tooltip arrow placement="top-end" title={`Duplicar registros selecionados`}>
                                    <div>
                                        <Button
                                            color="success"
                                            onClick={
                                                !table.getIsSomeRowsSelected() && !table.getIsAllPageRowsSelected()
                                                    ? null
                                                    : handleDuplicarSelected
                                            }
                                            variant="contained"
                                            disabled={
                                                JSON.parse(localStorage.getItem("incluir")) === false ||
                                                (!table.getIsSomeRowsSelected() && !table.getIsAllPageRowsSelected())
                                            }
                                        >
                                            Duplicar
                                        </Button>
                                    </div>
                                </Tooltip>
                                <Tooltip arrow placement="top-end" title={`Imprimir Extrato`}>
                                    <div>
                                        <span>
                                            <Button
                                                color="info"
                                                variant="contained"
                                                disabled={JSON.parse(localStorage.getItem("imprimir")) === true ? false : true}
                                                onClick={handleImpimirLancamento}
                                            >
                                                Imprimir
                                            </Button>
                                        </span>
                                    </div>
                                </Tooltip>
                            </div>
                        );
                    }}
                    columns={columns}
                    data={dados}
                    enableRowSelection
                    getRowId={(row) => row.uuid}
                    manualFiltering
                    manualPagination
                    manualSorting
                    muiToolbarAlertBannerProps={
                        isError
                            ? {
                                  color: "error",
                                  children: "Error loading data",
                              }
                            : undefined
                    }
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setColumnFiltersMode}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    rowCount={rowCount}
                    initialState={{
                        showColumnFilters: true,
                        density: "compact",
                        //columnPinning: { left: ["mrt-row-select"] },
                    }}
                    state={{
                        columnFilterFns,
                        columnFilters,
                        globalFilter,
                        isLoading,
                        pagination,
                        showAlertBanner: isError,
                        showProgressBars: isRefetching,
                        sorting,
                    }}
                    //positionToolbarDropZone={"top"}
                    //positionExpandColumn={"last"}
                    positionActionsColumn={"first"}
                    positionToolbarAlertBanner="bottom"
                    localization={MRT_Localization_PT_BR}
                />
            </ThemeProvider>
        </div>
    );
};
export default Index;
