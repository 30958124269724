// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Form_form_container__kIgjM {
    max-width: 300px;
    margin: 0 auto;
    background-color: #393939;
    color: #ffffff;
    /* background-color: #ffe7e7; */
    border-radius: 15px;
}

.Form_form_container__kIgjM input[type="submit"] {
    border-radius: 8px;
    background-color: #25b456;
    color: #fff;
    border: none;
    min-width: 100px;
    min-height: 2.5rem;
    width: 100%;
    cursor: pointer;
    font-size: 1.1em;
    font-weight: bold;
}

.Form_form_container__kIgjM input[type="submit"]:hover {
    background-color: #1c8a42;
}

.Form_form_container__kIgjM p {
    margin-top: 1em;
}

.Form_form_container__kIgjM p a {
    color: #16479d;
    font-weight: bold;
}

/* form de pets */
.Form_preview_pet_images__eUTqL {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
}

.Form_preview_pet_images__eUTqL img {
    width: 100px;
    height: 100px;
    margin-left: 1em;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/Auth/Form.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,yBAAyB;IACzB,cAAc;IACd,+BAA+B;IAC/B,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA,iBAAiB;AACjB;IACI,aAAa;IACb,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,gBAAgB;AACpB","sourcesContent":[".form_container {\n    max-width: 300px;\n    margin: 0 auto;\n    background-color: #393939;\n    color: #ffffff;\n    /* background-color: #ffe7e7; */\n    border-radius: 15px;\n}\n\n.form_container input[type=\"submit\"] {\n    border-radius: 8px;\n    background-color: #25b456;\n    color: #fff;\n    border: none;\n    min-width: 100px;\n    min-height: 2.5rem;\n    width: 100%;\n    cursor: pointer;\n    font-size: 1.1em;\n    font-weight: bold;\n}\n\n.form_container input[type=\"submit\"]:hover {\n    background-color: #1c8a42;\n}\n\n.form_container p {\n    margin-top: 1em;\n}\n\n.form_container p a {\n    color: #16479d;\n    font-weight: bold;\n}\n\n/* form de pets */\n.preview_pet_images {\n    display: flex;\n    justify-content: center;\n    margin-bottom: 1em;\n}\n\n.preview_pet_images img {\n    width: 100px;\n    height: 100px;\n    margin-left: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form_container": `Form_form_container__kIgjM`,
	"preview_pet_images": `Form_preview_pet_images__eUTqL`
};
export default ___CSS_LOADER_EXPORT___;
