import React, { useEffect, useMemo, useState, useContext, useCallback } from "react";
import { Context } from "../../../context/UserContext";

import moment from "moment";

import { useDispatch } from "react-redux";
import { selectComponent, clearSelectedComponent } from "../../../store/reducers/actions";

import { useLocation } from "react-router-dom";
import { useNavigateParams } from "../../../hooks/useNavigateParams";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

import { ThemeProvider } from "@mui/material";
/* tema customizado*/
import tableThemeCustom from "../../form/tableThemeCustom";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";

//Import Material React Table Translations
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";
import CustomDialog from "../../layout/CustomDialog";

import { Box, Button, IconButton, Tooltip, Select, MenuItem } from "@mui/material";
import { Delete, Edit, Print, ReceiptLong, Assignment, RequestPage } from "@mui/icons-material";

import tratar from "../../../utils/retornaTratamentoURL";
import CRUDsAPI from "../../../api/CRUDs";

/* util */
import { obterPermissoesUsuario, corrigirDataColumnFilters } from "../../../utils/funcoes";

import { relatorios } from "../../../api/relatorios";

import * as OperacaoAPI from "../../../api/operacao";
import * as TransformaMovimentosAPI from "../../../api/transformaMovimentos";

const Index = () => {
    const [dados, setDados] = useState([]);
    const [operacao, setOperacao] = useState({});
    const [operacaoParametros, setOperacaoParametros] = useState({});
    const [operacaoParametrosDocumentos, setOperacaoParametrosDocumentos] = useState([]);
    const [token] = useState(localStorage.getItem("token")) || "";
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();
    const location = useLocation();
    //const newUrlParams = new URLSearchParams(location.search);

    const newUrlParams = useMemo(() => {
        const searchParams = new URLSearchParams(location.search);
        return searchParams;
    }, [location.search]);
    const [urlParams, setUrlParams] = useState(new URLSearchParams(window.location.search));

    /* para a select de varios componentes */
    const dispatch = useDispatch();

    const pathname = "/MovimentoEntradas/CreateOrEdit";
    const ul = urlParams.get("ul");
    const li = urlParams.get("li");
    const tipo = urlParams.get("tipo");
    const operacaoUUID = urlParams.get("operacao");
    const uuid = urlParams.get("uuid");
    const btnText = urlParams.get("btnText") || "Cadastrar";

    const params = new URLSearchParams({
        ul: ul,
        li: li,
        tipo: tipo,
        operacao: operacaoUUID,
        uuid: uuid,
        btnText: btnText,
    });

    let parceiroTipo = "Fornecedor";

    //informação para tratamento de permissões
    //const isAdmin = JSON.parse(localStorage.getItem("isAdmin"));

    const { parametro } = useContext(Context);
    /* Obtem a paginação padrão */
    useEffect(() => {
        if (parametro && parametro.paginacao) {
            setPagination({
                pageIndex: pagination.pageIndex || 0,
                pageSize: parametro.paginacao,
            });
        }
        operacao && retornaOperacaoParametros(operacao.id, parametro.empresaId);
    }, [parametro, operacao]);

    //dialog customizado
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [dialogFuncao, setDialogFuncao] = useState();
    const [selectedOption, setSelectedOption] = useState("");

    //para transformar atraves do dialog
    const [dadosFiltrados, setDadosFiltrados] = useState([]);

    //data and fetching state
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    //tratar filtros se vier pela navbar
    if (localStorage.path.replace(/["]/g, "") !== window.location.pathname) {
        tratar.LimparFiltros();
        localStorage.setItem("path", JSON.stringify(window.location.pathname));
    }

    //table state
    const [columnFilterFns, setColumnFiltersMode] = useState(
        localStorage.columnFilterFns.length > 2 ? JSON.parse(localStorage.columnFilterFns) : []
    );

    const [columnFilters, setColumnFilters] = useState(
        localStorage.columnFilters.length > 2 ? JSON.parse(localStorage.columnFilters) : []
    );

    const [globalFilter, setGlobalFilter] = useState(
        localStorage.globalFilter.length > 2 ? JSON.parse(localStorage.globalFilter) : ""
    );

    const [sorting, setSorting] = useState(localStorage.sorting.length > 2 ? JSON.parse(localStorage.sorting) : []);

    const [pagination, setPagination] = useState({
        pageIndex: JSON.parse(localStorage.pagination).pageIndex || 0,
        pageSize: JSON.parse(localStorage.pagination).pageSize || 5,
    });

    useEffect(() => {
        if (!token) {
            navigate("/Auth", { replace: true });
        }
    }, [token, navigate]);

    const fetchData = useCallback(
        async (operacao) => {
            if (!dados.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            try {
                const response = await CRUDsAPI.movimentoEntradas.listar(
                    pagination.pageIndex * pagination.pageSize,
                    pagination.pageSize,
                    JSON.stringify(columnFilters ?? []),
                    JSON.stringify(columnFilterFns ?? []),
                    globalFilter ?? "",
                    JSON.stringify(sorting ?? []),
                    null,
                    operacao.id
                );
                if (response.data.data.length > 0) {
                    setDados(response.data.data);
                    setRowCount(response.data.meta.totalRowCount);
                    //grava os documentos modelos
                    setOperacaoParametrosDocumentos(
                        response.data.data[0].Operacao.operacaoParametros[0].operacaoParametrosDocumentos
                    );
                }
            } catch (error) {
                setIsError(true);
                console.error(error);
                return;
            } finally {
                setIsLoading(false);
                setIsRefetching(false);
            }

            setIsError(false);
        },
        [dados.length, pagination.pageIndex, pagination.pageSize, columnFilters, columnFilterFns, globalFilter, sorting]
    );

    // Separar a chamada do retornaOperacao e fetchData em um useEffect separado
    useEffect(() => {
        const carregarOperacao = async () => {
            const data = await retornaOperacao(operacaoUUID);
            if (data) {
                fetchData(data); //atualiza depois que a operação foi carregada
            }
        };

        carregarOperacao();
        // eslint-disable-next-line
    }, [operacaoUUID, fetchData]);

    useEffect(() => {
        //funcao para setar localStorage
        localStorage.setItem(
            "pagination",
            JSON.stringify({
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
            })
        );
        localStorage.setItem("columnFilterFns", JSON.stringify(columnFilterFns));
        localStorage.setItem("columnFilters", JSON.stringify(columnFilters));
        localStorage.setItem("globalFilter", JSON.stringify(globalFilter));
        localStorage.setItem("sorting", JSON.stringify(sorting));

        //permissões do usuario
        obterPermissoesUsuario(localStorage.getItem("pessoaId"), localStorage.getItem("empresaId"), ul, li);

        //rotina para corrigir o formato de data na grid precisa esta antes do fetchData
        const idsTipoData = ["saida"];

        // Formata os filtros de datas apenas se necessário
        const formattedFilters = corrigirDataColumnFilters(columnFilters, idsTipoData);

        // Verifica se há mudanças nos filtros formatados
        if (JSON.stringify(formattedFilters) !== JSON.stringify(columnFilters)) {
            setColumnFilters(formattedFilters);
        }

        /* Inicio da função para renderizar a página */
        // Verifica se os urlParams atuais são diferentes dos novos urlParams
        const areUrlParamsDifferent = () => {
            const currentKeys = Array.from(urlParams.keys());
            const newKeys = Array.from(newUrlParams.keys());

            // Verifica se o número de chaves é diferente
            if (currentKeys.length !== newKeys.length) {
                return true;
            }

            // Verifica se as chaves são diferentes
            for (let i = 0; i < currentKeys.length; i++) {
                if (currentKeys[i] !== newKeys[i]) {
                    return true;
                }
            }

            // Verifica se os valores são diferentes
            for (const key of currentKeys) {
                const currentValue = urlParams.get(key);
                const newValue = newUrlParams.get(key);

                if (currentValue !== newValue) {
                    return true;
                }
            }

            return false;
        };

        if (areUrlParamsDifferent()) {
            setUrlParams(newUrlParams);
        }
    }, [
        token,
        columnFilterFns,
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
        //função para renderizar
        location.search,
        urlParams,
        ul,
        li,
        operacaoUUID,
        newUrlParams,
    ]);

    useEffect(() => {
        operacao && retornaOperacaoParametros(operacao.id, parametro.empresaId);
    }, [parametro, operacao]);

    //funções do dialog
    const handleOpenDialog = (row, funcao) => {
        setOpenDialog(true);
        setSelectedRow(row);
        setDialogFuncao(funcao);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedOption(""); // Reseta a opção ao fechar
    };

    const handleConfirmDialog = async () => {
        // Verifique se há uma linha selecionada
        let msgType = "success";

        if (dialogFuncao === "transformar") {
            const urlSearchParams = new URLSearchParams({
                start: `${pagination.pageIndex * pagination.pageSize}`,
                size: `${pagination.pageSize}`,
                filters: JSON.stringify(columnFilters ?? []),
                filtersMode: JSON.stringify(columnFilterFns ?? []),
                globalFilter: globalFilter ?? "",
                sorting: JSON.stringify(sorting ?? []),
                tipo: tipo ?? "Entrada",
                operacaoParaTransformarUUID: selectedOption,
            });

            const response = await TransformaMovimentosAPI.transformaMovimentos(
                "transformaMovimentos",
                urlSearchParams,
                selectedRow
            );

            setFlashMessage(response.data.message, response.data.type);
            fetchData(operacao);
        } else if (dialogFuncao === "cancelar" && selectedRow) {
            try {
                const response = await CRUDsAPI.movimento.deletar(selectedRow);
                setFlashMessage(response.data.message, msgType);

                // atualizar a grid
                fetchData(operacao);
            } catch (error) {
                msgType = "error";
                console.error(error);
                setFlashMessage(error.response.data.message, msgType);
            }
        }

        setOpenDialog(false);
    };

    async function retornaOperacao(uuid) {
        try {
            const resultado = await OperacaoAPI.operacao(uuid);
            setOperacao(resultado);
            return resultado;
        } catch (erro) {
            // Lide com o erro
            console.error(erro);
        }
    }

    async function retornaOperacaoParametros(operacaoId, empresaId) {
        try {
            const data = await OperacaoAPI.retornaOperacaoParametros(operacaoId, empresaId);
            setOperacaoParametros(data);
            return data;
        } catch (error) {
            console.error(error);
        }
    }

    const columns = useMemo(
        () => [
            {
                id: "Movimento",
                header: operacao.descricao + " - " + operacao.sigla,
                columns: [
                    {
                        accessorFn: (row) => new Date(row),
                        accessorKey: "data",
                        id: "data",
                        header: "Data",
                        //Header: ({ column }) => <em>{column.columnDef.header}</em>,
                        muiFilterTextFieldProps: {
                            sx: {
                                minWidth: "250px",
                            },
                        },
                        filterVariant: "date",
                        sortingFn: "date",
                        columnFilterModeOptions: [
                            "fuzzy",
                            "equals",
                            "between",
                            "betweenInclusive",
                            "greaterThanOrEqualTo",
                            "lessThanOrEqualTo",
                        ],
                        muiTableBodyCellProps: ({ row }) => ({
                            align: "center",
                            onClick: () => {},
                        }),
                        muiTableHeadCellFilterTextFieldProps: {
                            type: "date",
                            InputLabelProps: {
                                shrink: true,
                            },
                            sx: {
                                minWidth: "200px",
                            },
                        },
                        Cell: ({ renderedCellValue, row }) => {
                            return <span>{moment.utc(row.original.data).format("DD/MM/YYYY")}</span>;
                        },
                    },
                    {
                        id: "Parceiro.descricao",
                        accessorKey: "Parceiro.descricao",
                        header: parceiroTipo,
                        //you can access a cell in many callback column definition options like this
                        muiTableBodyCellProps: ({ row, cell }) => ({
                            onClick: () => {},
                        }),
                    },
                    {
                        accessorKey: "totalValorTotal",
                        header: "Valor Total",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.totalValorMovimentoItems === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.totalValorMovimentoItems)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "totalPesoLiquido",
                        header: "Peso Liquido",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.totalPesoLiquido === null
                                ? ""
                                : parseFloat(row.totalPesoLiquido / 1000)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 3,
                                      maximumFractionDigits: 3,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }) + " Kg",
                    },
                    {
                        accessorKey: "Empresa.nomeFantasia",
                        header: "Empresa",
                    },
                ],
            },

            //column definitions...
        ],
        [operacao, parceiroTipo] //getCommonEditTextFieldProps
    );

    /* modo tema */
    const theme = tableThemeCustom();

    return (
        <div>
            <div>
                {/* Modal de Exclusão */}
                {openDialog && dialogFuncao === "cancelar" && (
                    <CustomDialog
                        title="Deletar Registro"
                        content="Você tem certeza?"
                        onClose={handleCloseDialog}
                        onConfirm={handleConfirmDialog}
                    />
                )}

                {/* Modal de Transformação */}
                {openDialog && dialogFuncao === "transformar" && (
                    <CustomDialog
                        title="Escolha a Transformação"
                        content={
                            <Select
                                fullWidth
                                value={selectedOption || ""} // Garante que não seja undefined
                                onChange={(e) => {
                                    setSelectedOption(e.target.value);
                                }}
                            >
                                {operacaoParametros?.operacaoTransformacoes?.map((option) => (
                                    <MenuItem key={option.uuid} value={option.uuid}>
                                        {option.OperacaoDestino.descricao}
                                    </MenuItem>
                                ))}
                            </Select>
                        }
                        onClose={handleCloseDialog}
                        onConfirm={handleConfirmDialog}
                        disableConfirm={!selectedOption} // Impede confirmar sem escolher
                    />
                )}
            </div>

            <ThemeProvider theme={theme}>
                <MaterialReactTable
                    enableColumnFilterModes
                    enableGlobalFilterModes
                    //enableColumnResizing
                    enableColumnOrdering
                    enableEditing
                    enableRowActions
                    enableRowSelection
                    //enableRowNumbers
                    displayColumnDefOptions={{
                        "mrt-row-actions": {
                            muiTableHeadCellProps: {
                                align: "center",
                            },
                            size: 130,
                        },
                        "mrt-row-numbers": {
                            enableColumnOrdering: true, //turn on some features that are usually off
                            enableResizing: true,
                            muiTableHeadCellProps: {
                                sx: {
                                    fontSize: "1.2rem",
                                },
                            },
                        },
                        "mrt-row-select": {
                            enableColumnActions: true,
                            enableHiding: true,
                            size: 50,
                        },
                    }}
                    renderRowActions={({ row, table }) => {
                        const handleImprimir = async (documento, obj) => {
                            const caminho = documento.Documento.caminho;
                            const url = documento.Documento.funcao;

                            const response = await relatorios(caminho, url, obj);

                            // Cria um Blob a partir dos dados do arraybuffer
                            const pdfBlob = new Blob([response.data], {
                                type: "application/pdf",
                            });

                            // Cria um URL temporário para o Blob
                            const pdfUrl = URL.createObjectURL(pdfBlob);

                            // Use o URL do PDF para abrir em uma nova janela ou realizar outras ações
                            window.open(pdfUrl);
                        };
                        return (
                            <Box sx={{ display: "flex", gap: "1rem" }}>
                                <Tooltip
                                    arrow
                                    placement="top"
                                    title={`${
                                        row.original.transformado === true ? "Transformado não pode ser Editado" : "Editar"
                                    }`}
                                >
                                    <span>
                                        {" "}
                                        <IconButton
                                            onClick={() => {
                                                params.set("uuid", row.id);
                                                params.set("btnText", "Atualizar");
                                                params.set("operacaoId", row.original.operacaoId);
                                                navigate(pathname, params);
                                            }}
                                            disabled={
                                                JSON.parse(localStorage.getItem("editar")) === false ||
                                                row.original.transformado === true
                                            }
                                        >
                                            <Edit />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Tooltip
                                    arrow
                                    placement="top"
                                    title={`${
                                        row.original.transformado === true ? "Transformado não pode ser Deletado" : "Deletar"
                                    }`}
                                >
                                    <span>
                                        <IconButton
                                            color="error"
                                            onClick={() => {
                                                handleOpenDialog(row, "cancelar");
                                            }}
                                            disabled={
                                                JSON.parse(localStorage.getItem("cancelar")) === false ||
                                                row.original.transformado === true ||
                                                row.original.Lancamentos.some(
                                                    (lancamento) =>
                                                        lancamento.lancamentoBaixas.length > 0 &&
                                                        lancamento.lancamentoBaixas.some((baixa) => baixa.estornado === false)
                                                )
                                            }
                                        >
                                            <Delete />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                {operacaoParametrosDocumentos.map((documento, index) => (
                                    <Tooltip key={index} arrow placement="right" title={`Imprimir ${documento.Documento.tipo}`}>
                                        <span>
                                            <IconButton
                                                onClick={() => handleImprimir(documento, row.original)}
                                                disabled={localStorage.getItem("imprimir") !== "true"}
                                            >
                                                {documento.Documento.tipo === "pedido" && <Print />}
                                                {documento.Documento.tipo === "contrato" && <ReceiptLong />}
                                                {documento.Documento.tipo === "garantia" && <Assignment />}
                                                {documento.Documento.tipo === "recibo" && <RequestPage />}
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                ))}
                            </Box>
                        );
                    }}
                    renderTopToolbarCustomActions={({ table }) => {
                        const handleTransformar = async () => {
                            // Limpa os valores selecionados
                            dispatch(clearSelectedComponent());

                            // Obtém os uuids das linhas selecionadas
                            const uuids = table.getSelectedRowModel().flatRows.map((row) => row.id);

                            // Preenche com os novos valores
                            dispatch(selectComponent(uuids));

                            // Filtrar os lançamentos com base nos UUIDs e transformado === false
                            const dadosUUIDs =
                                uuids.length > 0
                                    ? dados.filter((item) => uuids.includes(item.uuid) && item.transformado === false) // Adiciona a verificação de 'transformado === false'
                                    : dados.filter((item) => item.transformado === false); // Se não houver uuids, filtra apenas por 'transformado === false'

                            if (dadosUUIDs.length === 0) {
                                setFlashMessage("Não existe registros para transformar!", "warning");
                                table.resetRowSelection();
                                return;
                            }

                            handleOpenDialog(uuids, "transformar");
                            table.resetRowSelection();
                        };
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    gap: "8px",
                                }}
                            >
                                <Tooltip arrow placement="top-end" title={`Novo Registro`}>
                                    <div>
                                        {" "}
                                        <Button
                                            color="secondary"
                                            onClick={() => {
                                                params.delete("uuid");
                                                navigate(pathname, params);
                                            }}
                                            variant="contained"
                                            disabled={JSON.parse(localStorage.getItem("incluir")) === false}
                                        >
                                            Novo
                                        </Button>
                                    </div>
                                </Tooltip>
                                <Tooltip arrow placement="top-end" title="Transformar">
                                    <div>
                                        {" "}
                                        <Button
                                            color="info"
                                            onClick={
                                                !table.getIsSomeRowsSelected() && !table.getIsAllPageRowsSelected()
                                                    ? null
                                                    : handleTransformar
                                            }
                                            variant="contained"
                                            disabled={
                                                JSON.parse(localStorage.getItem("transformar")) === false ||
                                                (!table.getIsSomeRowsSelected() && !table.getIsAllPageRowsSelected())
                                            }
                                        >
                                            Transformar
                                        </Button>
                                    </div>
                                </Tooltip>
                            </div>
                        );
                    }}
                    columns={columns}
                    data={dados}
                    getRowId={(row) => row.uuid}
                    manualFiltering
                    manualPagination
                    manualSorting
                    muiToolbarAlertBannerProps={
                        isError
                            ? {
                                  color: "error",
                                  children: "Error loading data",
                              }
                            : undefined
                    }
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setColumnFiltersMode}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    rowCount={rowCount}
                    initialState={{
                        showColumnFilters: true,
                        density: "compact",
                        //columnPinning: { left: ["mrt-row-select"] },
                    }}
                    state={{
                        columnFilterFns,
                        columnFilters,
                        globalFilter,
                        isLoading,
                        pagination,
                        showAlertBanner: isError,
                        showProgressBars: isRefetching,
                        sorting,
                    }}
                    //positionToolbarDropZone={"top"}
                    positionExpandColumn={"first"}
                    positionActionsColumn={"first"}
                    positionToolbarAlertBanner="bottom"
                    localization={MRT_Localization_PT_BR}
                />
            </ThemeProvider>
        </div>
    );
};
export default Index;
