// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DataList_form_control__-9Kup {
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 1em;
}

.DataList_form_control__-9Kup label {
    margin-left: 0.7em;
    margin-bottom: 0.3em;
    font-weight: bold;
    font-size: 0.8em;
}

.DataList_form_control__-9Kup input,
datalist {
    padding: 0.7em;
    border: 1px solid #777;
    border-radius: 5px;
    margin-left: 0.7em;
    background-color: #ffe7e7;
    /* display: block; */
}
.DataList_form_control__-9Kup.DataList_dark__sDUWR input,
datalist {
    background-color: #484848;
    color: #ffe7e7;
}
.DataList_form_control__-9Kup datalist::placeholder {
    color: #7b7b7b;
}
`, "",{"version":3,"sources":["webpack://./src/components/form/DataList.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;;IAEI,cAAc;IACd,sBAAsB;IACtB,kBAAkB;IAClB,kBAAkB;IAClB,yBAAyB;IACzB,oBAAoB;AACxB;AACA;;IAEI,yBAAyB;IACzB,cAAc;AAClB;AACA;IACI,cAAc;AAClB","sourcesContent":[".form_control {\n    display: inline-flex;\n    flex-direction: column;\n    margin-bottom: 1em;\n}\n\n.form_control label {\n    margin-left: 0.7em;\n    margin-bottom: 0.3em;\n    font-weight: bold;\n    font-size: 0.8em;\n}\n\n.form_control input,\ndatalist {\n    padding: 0.7em;\n    border: 1px solid #777;\n    border-radius: 5px;\n    margin-left: 0.7em;\n    background-color: #ffe7e7;\n    /* display: block; */\n}\n.form_control.dark input,\ndatalist {\n    background-color: #484848;\n    color: #ffe7e7;\n}\n.form_control datalist::placeholder {\n    color: #7b7b7b;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form_control": `DataList_form_control__-9Kup`,
	"dark": `DataList_dark__sDUWR`
};
export default ___CSS_LOADER_EXPORT___;
