import api from ".";

/**
 * Retorna as condições de venda do produto
 * @param {*} empresaId
 * @param {*} tabelaPrecoId
 * @param {*} produtoId
 * @param {*} pessoaId
 * @returns
 */

export const condicaoVenda = async (empresaId, tabelaPrecoId, produtoId, pessoaId) => {
    try {
        const { data } = await api.get("/serviceMovimento", {
            params: {
                empresaId,
                tabelaPrecoId,
                produtoId,
                pessoaId,
            },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};
/* obter os dados da tabelaPreco e dos itens na tabela*/
export const retornaTabelaPrecoItem = async (tabelaPrecoId, produtoId) => {
    try {
        const { data } = await api.get("/retornaTabelaPrecoItem", {
            params: { tabelaPrecoId, produtoId },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};

export const retornaEmbalagensByProdutoId = async (empresaId, produtoId) => {
    try {
        const { data } = await api.get("/retornaEmbalagensByProdutoId", {
            params: { empresaId, produtoId },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};

/**
 * retorna todos os itens da tabela de preços
 * @param {*} tabelaPrecoId
 * @returns
 */
export const retornaTabelaPreco = async (tabelaPrecoId) => {
    try {
        const { data } = await api.get("/retornaTabelaPreco", {
            params: { tabelaPrecoId },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};
/* movimentoItem */
export const listarFinanceiro = (start, size, filters, filtersMode, globalFilter, sorting, parceiroId, situacaoId) =>
    api.get("/movimento/listarFinanceiro", {
        params: {
            start,
            size,
            filters,
            filtersMode,
            globalFilter,
            sorting,
            parceiroId,
            situacaoId,
        },
    });

export const listarProdutos = (start, size, filters, filtersMode, globalFilter, sorting, movimentoId) =>
    api.get("/movimento/listarProdutos", {
        params: {
            start,
            size,
            filters,
            filtersMode,
            globalFilter,
            sorting,
            movimentoId,
        },
    });

export const listarLancamentos = (start, size, filters, filtersMode, globalFilter, sorting, movimentoId) =>
    api.get("/movimento/listarLancamentos", {
        params: {
            start,
            size,
            filters,
            filtersMode,
            globalFilter,
            sorting,
            movimentoId,
        },
    });

export const deletarProduto = (uuid) => api.post("/movimento/deletarProduto", { uuid });
export const duplicidadeProduto = async (empresaId, movimentoId, produtoId, embalagemId) => {
    try {
        const { data } = await api.get("/movimento/duplicidadeProduto/", {
            params: { empresaId, movimentoId, produtoId, embalagemId },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};
export const managerProduto = async (novoProduto) => {
    try {
        const { data } = await api.post("/movimento/CreateOrEditProduto", novoProduto);
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};

/* TERMO ADITIVO*/
export const duplicidadeAditivo = async (movimentoId, tipo, data) => {
    try {
        const { data: result } = await api.get("/movimento/duplicidadeAditivo/", {
            params: { movimentoId, tipo, data },
        });
        return result; // Retorna os dados ao chamador
    } catch (error) {
        console.error(error); // Loga qualquer erro que ocorrer
        return error;
    }
};
export const managerAditivo = async (novoAditivo) => {
    try {
        const { data } = await api.post("/movimento/CreateOrEditAditivo", novoAditivo);
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};
export const listarAditivos = (start, size, filters, filtersMode, globalFilter, sorting, movimentoId) =>
    api.get("/movimento/listarAditivos", {
        params: {
            start,
            size,
            filters,
            filtersMode,
            globalFilter,
            sorting,
            movimentoId,
        },
    });
export const deletarAditivo = (uuid) => api.post("/movimento/deletarAditivo", { uuid });
