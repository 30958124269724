import { useState, useEffect, useCallback } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import "moment/locale/pt-br";

import { Stack, Button, Tooltip } from "@mui/material";

/* hooks */
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import DataList from "../../form/DataList";
import Input from "../../form/Input";
import Select from "../../form/Select";
import SelectEmpresa from "../../form/SelectEmpresa";
import Checkbox from "../../form/Checkbox";

import formStyles from "../../form/Form.module.css";

/* api */
import * as DataListAPI from "../../../api/dataList";
import * as EmpresaAPI from "../../../api/empresa";
import * as TabelaAPI from "../../../api/tabela";

function Form({
    token,
    handleSubmit,
    formData,
    btnText,
    caminhoVoltar,
    OptionsEmpresa,
    //OptionsPessoa,
    OptionsForma,
    OptionsPrazo,
}) {
    const [dados, setDados] = useState(formData || {});
    const [pessoa, setPessoa] = useState(formData?.pessoaPadrao || {});
    const navigate = useNavigateParams();
    const urlParams = new URLSearchParams(window.location.search);

    //para produto vazio preenche valores pré definidos
    if (dados.empresaId === undefined) {
        dados.empresaId = localStorage.getItem("empresaId");
        dados.ativo = true;
    }

    //set datalist
    const [OptionsParceiro, setOptionsParceiro] = useState([]);

    //Atualiza o Datalist
    const consultaDatalist = async (token, empresaId, tabela, id, descricao, condicao, filtro) => {
        let result = await DataListAPI.dataList(empresaId, tabela, id, descricao, condicao, filtro);
        return result;
    };

    //options dos selects
    const [OptionsTRF, setTRF] = useState([]); //centros de custos de transferencias entre contas
    const [OptionsCCI, setCCI] = useState([]); //centro de custos da industria
    const [OptionsCCR, setCCR] = useState([]); //centro de custos de receitas
    const [OptionsCCP, setCCP] = useState([]); //centros de custos de despesas
    const [OptionsCCLIM, setCCLIM] = useState([]); //centros de custos de locação de imoaveis
    const [OptionsPosition, setOptionsPosition] = useState([]);
    const [OptionsTema, setOptionsTema] = useState([]);

    //opções de paginação
    const OptionsPaginacao = [
        { id: "5", descricao: "5" },
        { id: "10", descricao: "10" },
        { id: "15", descricao: "15" },
        { id: "20", descricao: "20" },
        { id: "25", descricao: "25" },
        { id: "30", descricao: "30" },
        { id: "50", descricao: "50" },
        { id: "100", descricao: "100" },
    ];

    //consulta backend
    const consultaCC = useCallback(
        async (
            descricao = null,
            tipo = null,
            combustivel = false,
            manutencao = false,
            industria = false,
            option = false,
            servico = false
        ) => {
            const dado = await EmpresaAPI.centroCustos(
                dados.empresaId,
                descricao,
                tipo,
                combustivel,
                manutencao,
                industria,
                servico
            );

            if (tipo === "Receber") {
                setCCR(dado);
            }
            if (tipo === "Pagar") {
                setCCP(dado);
            }
            if (tipo === "Ambos") {
                setTRF(dado);
            }
            if (industria === true) {
                setCCI(dado);
            }
            if (servico === true) {
                setCCLIM(dado);
            }

            return dado;
        },
        [dados.empresaId, setCCR, setCCP, setTRF, setCCI, setCCLIM]
    );
    const consultaEnum = async (tabela, campo, setEnum) => {
        const result = await TabelaAPI.enums(tabela, campo);
        if (setEnum === "OptionsPosition") {
            setOptionsPosition(result ? result : []);
        } else if (setEnum === "OptionsTema") {
            setOptionsTema(result ? result : []);
        }
    };

    useEffect(() => {
        setDados(formData || {});
        setPessoa(formData?.pessoaPadrao || {});

        consultaCC("", "Ambos", false, false, false); //Receitas
        consultaCC("", "Receber", false, false, false); //Receitas
        consultaCC("", "Pagar", false, false, false); //Despesas
        consultaCC("", "", false, false, true, false, false); // Industria
        consultaCC("", "", false, false, false, false, true); // Serviço

        //carrega enums
        consultaEnum("Parametros", "msgPosition", "OptionsPosition");
        consultaEnum("Parametros", "tema", "OptionsTema");
    }, [formData, consultaCC]);

    //const consultaForma = async () => {};

    async function handleChange(e) {
        const camposBooleanos = [
            "plantao",
            "ativo",
            "materiaPrimaEstocaGraos",
            "refugoMateriaPrimaGeraFinanceiro",
            "tabelasComuns",
            "msgCoresDefault",
            "calculaJuros",
        ];
        //configura os chebox
        if (camposBooleanos.includes(e.target.name)) {
            setDados({
                ...dados,
                [e.target.name]: e.target.checked,
            });
        } else if (e.target.name === "centroCustoMateriaPrimaId") {
            setDados({
                ...dados,
                [e.target.name]: e.target.value,
            });
        } else if (e.target.name.startsWith("Pessoa.")) {
            setPessoa({
                ...pessoa,
                [e.target.name.split(".")[1]]: e.target.value,
            });

            //consulta datalist
            let result = await consultaDatalist(
                token,
                dados.empresaId,
                "Pessoa",
                "id",
                "descricao",
                urlParams.get("tipo"),
                e.target.value
            );
            setOptionsParceiro(result);

            const selectedId = document.querySelector(`datalist option[value="${e.target.value}"]`)?.dataset.id;

            setDados((prevState) => ({
                ...prevState,
                pessoaId: selectedId,
            }));
        } else {
            setDados({
                ...dados,
                [e.target.name]: e.target.value,
            });
        }
    }
    async function handleOnBlur(e) {}
    async function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();
        handleSubmit(dados);
    };

    return (
        <Tabs>
            <form onSubmit={submit}>
                <h1>Paramêtros</h1>
                <TabList>
                    <Tab
                        //disabled={!tabEnabled.pessoa}
                        title={"Paramêtros Básicos"}
                    >
                        Básico
                    </Tab>
                    <Tab
                        //disabled={!tabEnabled.fisica}
                        title={"Paramêtros da Indústria"}
                    >
                        Indústria
                    </Tab>
                    <Tab
                        //disabled={!tabEnabled.fisica}
                        title={"Paramêtros Financeiros"}
                    >
                        Financeiro
                    </Tab>
                    <Tab
                        //disabled={!tabEnabled.fisica}
                        title={"Paramêtros de Movimentações de Entradas"}
                    >
                        Entradas
                    </Tab>
                    <Tab
                        //disabled={!tabEnabled.fisica}
                        title={"Paramêtros de Movimentações de Saídas"}
                    >
                        Saídas
                    </Tab>
                    <Tab
                        //disabled={!tabEnabled.fisica}
                        title={"Paramêtros de Movimentações de Serviço"}
                    >
                        Serviço
                    </Tab>
                </TabList>
                <TabPanel>
                    <div className={formStyles.stiloPadrao}>
                        <h4>Paramêtros básicos da empresa</h4>
                        <div>
                            <div>
                                <SelectEmpresa
                                    text="Selecione a Empresa"
                                    name="empresaId"
                                    options={OptionsEmpresa}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.empresaId || ""}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className={formStyles.stiloPadrao}>
                        <h4>Configurações de Tela</h4>
                        <div>
                            <Input
                                text="Altura"
                                type="number"
                                name="logomarcaAltura"
                                placeholder="Altura"
                                inputWidth="100px"
                                title="configura a altura da logomarca da empresa"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.logomarcaAltura || 80}
                                textAlign="center"
                                min={5}
                            />
                            <Input
                                text="Largura"
                                type="number"
                                name="logomarcaLargura"
                                placeholder="Largura"
                                inputWidth="100px"
                                title="configura a largura da logomarca da empresa"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.logomarcaLargura || 80}
                                textAlign="center"
                                min={5}
                            />
                            <Input
                                text="Tempo"
                                type="range"
                                name="msgTempo"
                                inputWidth="100px"
                                title="configura o tempo de exposição da mensgem em segundos"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.msgTempo}
                                textAlign="center"
                                min={1}
                                max={5}
                            />
                            <Select
                                name="msgPosition"
                                title="Selecione a posição da mensagem"
                                text="Posição"
                                inputWidth="150px"
                                options={OptionsPosition}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.msgPosition}
                                isEnum={true}
                                notOptNull={true}
                                required={true}
                            />
                            <Checkbox
                                title="Utiliza cores padrão do sistema ou o usuário configura"
                                text="Cores padrão"
                                name="msgCoresDefault"
                                type="checkbox"
                                handleOnChange={handleChange}
                                value={dados.msgCoresDefault}
                                checked={dados.msgCoresDefault === true ? "checked" : ""}
                            />
                            <Input
                                text="BackGround-Color"
                                type="color"
                                name="msgBackgroundColor"
                                title="configura a cor de fundo da mensagem"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.msgBackgroundColor}
                            />
                            <Input
                                text="Text-Color"
                                type="color"
                                name="msgTextColor"
                                title="configura a cor do texto da mensagem"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.msgTextColor}
                            />
                            <Select
                                name="tema"
                                title="Selecione o tema"
                                text="Tema"
                                inputWidth="150px"
                                options={OptionsTema}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.tema}
                                isEnum={true}
                                notOptNull={true}
                                required={true}
                            />
                        </div>

                        <div>
                            <Input
                                text="Latitude"
                                type="text"
                                name="latitude"
                                placeholder="Latitude"
                                inputWidth="140px"
                                title="Informa a latitude da empresa"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.latitude}
                            />
                            <Input
                                text="Longitude"
                                type="text"
                                name="longitude"
                                placeholder="Longitude"
                                inputWidth="140px"
                                title="Informa a longitude da empresa"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.longitude}
                            />
                            <Input
                                text="Zoom"
                                type="number"
                                name="zoom"
                                placeholder="Zoom"
                                inputWidth="100px"
                                title="configura a aproximação padrão de abertura do mapa"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.zoom || 10}
                                textAlign="center"
                                min={1}
                            />
                            <Select
                                name="paginacao"
                                title="Selecione a qauntidade de registros na GRID"
                                text="Paginação"
                                inputWidth="100px"
                                options={OptionsPaginacao}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.paginacao}
                                isEnum={false}
                                notOptNull={true}
                                required={true}
                            />
                        </div>
                        <div>
                            <Checkbox
                                title="O sistema possui várias tabelas comuns, que são Bancos, CFOP, NCM, etc..,
                            ao desmarcar a opção, indica que as tabelas consideradas comuns precisam ser alimentadas
                            pela empresa. Por padrão deve deixar essa opção marcada para usar as tabelas existentes no sistema."
                                text="Tabelas Comuns"
                                name="tabelasComuns"
                                type="checkbox"
                                handleOnChange={handleChange}
                                value={dados.tabelasComuns}
                                checked={dados.tabelasComuns === true ? "checked" : ""}
                            />
                            <DataList
                                title="Pessoa que será colocada nos movimentos, financeiro, industria como padrão"
                                text="Selecione a Pessoa Padrão"
                                name="Pessoa.nomePrincipal"
                                inputWidth="350px"
                                filteredOptions={OptionsParceiro}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoa.nomePrincipal || ""}
                            />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.stiloPadrao}>
                        <h4>Paramêtros para o modulo de indústria</h4>

                        <div>
                            <Checkbox
                                title="Informa se a entrada de materia prima estoca no almoxarifado de grãos"
                                text="MP Est Grãos"
                                name="materiaPrimaEstocaGraos"
                                type="checkbox"
                                handleOnChange={handleChange}
                                value={dados.materiaPrimaEstocaGraos}
                                checked={dados.materiaPrimaEstocaGraos === true ? "checked" : ""}
                            />
                            <Select
                                name="centroCustoMateriaPrimaId"
                                title={"Define o centro de custo padrão para entrada de materia prima"}
                                text="Centro Custo MP"
                                options={OptionsCCI}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.centroCustoMateriaPrimaId}
                                isEnum={false}
                                notOptNull={false}
                            />
                            <Checkbox
                                title="Informa se o refugo vai gerar valor a receber no modulo financeiro"
                                text="Refugo MP gera financeiro"
                                name="refugoMateriaPrimaGeraFinanceiro"
                                type="checkbox"
                                handleOnChange={handleChange}
                                value={dados.refugoMateriaPrimaGeraFinanceiro}
                                checked={dados.refugoMateriaPrimaGeraFinanceiro === true ? "checked" : ""}
                            />
                            <Select
                                name="centroCustoRefugoMateriaPrimaId"
                                title={"Define o centro de custo padrão para o refugo da materia prima"}
                                text="Centro Custo Refugo"
                                options={OptionsCCI}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.centroCustoRefugoMateriaPrimaId}
                                isEnum={false}
                                notOptNull={false}
                            />
                        </div>
                        <div>
                            {" "}
                            <Checkbox
                                title="Informa se a empresa utiliza plantões na produção"
                                text="Plantão"
                                name="plantao"
                                type="checkbox"
                                handleOnChange={handleChange}
                                value={dados.plantao}
                                checked={dados.plantao === true ? "checked" : ""}
                            />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.stiloPadrao}>
                        <h4>Paramêtros de Comuns</h4>
                        <div>
                            <Select
                                name="centroCustoTransferencia"
                                title={"Define o centro de custo padrão para os transferências entre contas"}
                                text="Centro Custo Transferências"
                                inputWidth="200px"
                                options={OptionsTRF}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.centroCustoTransferencia}
                                isEnum={false}
                                notOptNull={false}
                            />
                            <Select
                                name="transferenciaForma"
                                title={"Define a forma padrão para os transferências entre contas"}
                                text="Forma Transferências"
                                inputWidth="200px"
                                options={OptionsForma}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.transferenciaForma}
                                isEnum={false}
                                notOptNull={false}
                            />
                            <Select
                                name="transferenciaPrazo"
                                title={"Define o prazo padrão para os transferências entre contas"}
                                text="Prazo Transferências"
                                inputWidth="200px"
                                options={OptionsPrazo}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.transferenciaPrazo}
                                isEnum={false}
                                notOptNull={false}
                            />
                            <Checkbox
                                title="Permite calcular juros e multa"
                                text="Calcula Juros"
                                name="calculaJuros"
                                type="checkbox"
                                handleOnChange={handleChange}
                                value={dados.calculaJuros}
                                checked={dados.calculaJuros === true ? "checked" : ""}
                            />
                        </div>
                        <div>
                            <Select
                                name="centroCustoComissaoId"
                                title={"Define o centro de custo padrão para as comissões"}
                                text="Centro Custo Comissão"
                                inputWidth="200px"
                                options={OptionsCCP}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.centroCustoComissaoId}
                                isEnum={false}
                                notOptNull={false}
                            />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.stiloPadrao}>
                        <h4>Paramêtros de movimentações de entradas</h4>
                        <div>
                            <Select
                                name="centroCustoPDC"
                                title={"Define o centro de custo padrão para operações de Compras"}
                                text="Centro Custo Compras"
                                inputWidth="200px"
                                options={OptionsCCP}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.centroCustoPDC}
                                isEnum={false}
                                notOptNull={false}
                            />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.stiloPadrao}>
                        <h4>Paramêtros de movimentações de saídas</h4>
                        <div>
                            <Select
                                name="centroCustoPDV"
                                title={"Define o centro de custo padrão para operações de Vendas"}
                                text="Centro Custo Vendas"
                                inputWidth="200px"
                                options={OptionsCCR}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.centroCustoPDV}
                                isEnum={false}
                                notOptNull={false}
                            />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.stiloPadrao}>
                        <h4>Paramêtros de movimentações de serviço</h4>
                        <div>
                            <Select
                                name="centroCustoLIM"
                                title={"Define o centro de custo padrão para locação de imóveis"}
                                text="Centro Custo LIM"
                                inputWidth="200px"
                                options={OptionsCCLIM}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.centroCustoLIM}
                                isEnum={false}
                                notOptNull={false}
                            />
                        </div>
                    </div>
                </TabPanel>
                <div>
                    <Stack direction="row" spacing={1}>
                        <Tooltip arrow placement="top-start" title="Voltar">
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() =>
                                    navigate(caminhoVoltar, {
                                        ul: urlParams.get("ul"),
                                        li: urlParams.get("li"),
                                        tipo: urlParams.get("tipo"),
                                    })
                                }
                            >
                                Voltar
                            </Button>
                        </Tooltip>
                        <Button
                            type="submit"
                            variant="contained"
                            color="success"
                            //disabled={}
                        >
                            {btnText}
                        </Button>
                    </Stack>
                </div>
            </form>
        </Tabs>
    );
}
export default Form;
