import { useState, useEffect, useContext, useMemo } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

/* contexts */
import { Context } from "../../../context/UserContext";

import moment from "moment";
import "moment/locale/pt-br";

import { Stack, Button, Box, Tooltip, IconButton } from "@mui/material";
import { Delete, Edit, Print, ReceiptLong } from "@mui/icons-material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

import { ThemeProvider } from "@mui/material";
/* tema customizado*/
import tableThemeCustom from "../../form/tableThemeCustom";

//Import Material React Table Translations
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import Input from "../../form/Input";
import InputNumber from "../../form/InputNumber";
import DataList from "../../form/DataList";
import Select from "../../form/Select";
import Checkbox from "../../form/Checkbox";
import TextArea from "../../form/TextArea";

import formStyles from "../../form/Form.module.css";

/* utils */
import { formatarNumero, formatarNumeroDecimal, maskDecimal } from "../../../utils/masks";
import { retornaDataHora, retornaData } from "../../../utils/retornaDataFormatada";

import * as DataListAPI from "../../../api/dataList";
import * as EmpresaAPI from "../../../api/empresa";
import * as FinanceiroAPI from "../../../api/financeiro";
import * as OperacaoAPI from "../../../api/operacao";
import * as PessoaAPI from "../../../api/pessoa";
import * as TabelaAPI from "../../../api/tabela";
import * as MovimentoAPI from "../../../api/movimento";
import { relatorios } from "../../../api/relatorios";

function Form({ handleSubmit, formData, btnText, caminhoVoltar }) {
    const [dados, setDados] = useState(formData || {});
    const [itens, setItens] = useState([]); //itens da grid
    const [itemLancamentos, setItemLancamentos] = useState([]);
    const [itemAditivos, setItemAditivos] = useState([]);

    const [operacao, setOperacao] = useState({});
    const [parametroPessoa, setParametroPessoa] = useState({});
    const [operacaoParametros, setOperacaoParametros] = useState({});
    const [operacaoParametrosDocumentos, setOperacaoParametrosDocumentos] = useState([]);

    const [lancamentos, setLancamentos] = useState([]);

    /* dados para o movimento */
    const [Produto, setProduto] = useState({});
    //const [Embalagem, setEmbalagem] = useState({});
    const [OptionsProdutos, setOptionsProdutos] = useState([]);
    //const [OptionsEmbalagem, setOptionsEmbalagem] = useState([]);

    /* button enable ou disabled */
    const [isDisabled, setIsDisabled] = useState(false);

    /* Todas as operações*/
    const [novoProduto, setNovoProduto] = useState({
        uuid: "",

        movimentoId: dados.id,
        empresaId: dados.empresaId,
        tabelaPrecoId: "",
        produtoId: "",
        embalagemId: "",

        Empresa: {},
        Produto: {},
        Embalagem: {},
        movimentoItems: {},

        custo: "",
        custoCalculado: "",
        quantidade: "",
        valorUnitario: "",
        descontoPercentual: "",
        descontoReal: "",
        valorTotal: "",

        ativo: true,
        funcao: "Adicionar",
    });

    /* OPERACAO LIM */
    const [novoAditivo, setNovoAditivo] = useState({
        uuid: null,

        movimentoId: dados.id,

        tipo: null, //define o tipo de termo
        data: null, //define a data inicial do termo aditivo
        valorAnterior: null,
        percentual: 0, //define o percentual de reajuste
        valorReajustado: 0,
        quantidade: 0,
        observacao: null,

        ativo: true,
        funcao: "Adicionar",
    });

    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();

    const urlParams = new URLSearchParams(window.location.search);
    const ul = urlParams.get("ul");
    const li = urlParams.get("li");
    const tipo = urlParams.get("tipo");
    const operacaoUUID = urlParams.get("operacao");
    const uuid = urlParams.get("uuid");

    const params = new URLSearchParams({
        ul: ul,
        li: li,
        tipo: tipo,
        operacao: operacaoUUID,
        uuid: uuid,
    });
    //obtem os parametros da empresa
    const { parametro } = useContext(Context);

    //para produto vazio preenche valores pré definidos
    const empresaArmazenada = JSON.parse(localStorage.getItem("Empresa"));

    let colaborador = "";
    let parceiroTipo = "Cliente";
    let lancamentoTipo = "Receber";
    let lancamentoSituacaoId = 1; //representa o id da situaçao para tabela de lançamento

    let textoHeaderGrid = "Movimento";

    //para lancamento
    let diasEntreParcelas = 0;
    let lancamentoComissaoTipo = "Pagar";

    if (operacao.servico === true) {
        colaborador = "Corretor";
        parceiroTipo = "Locatário";
        lancamentoTipo = "Receber";
        textoHeaderGrid = "Movimento de Locação de Imóveis";
        lancamentoSituacaoId = operacaoParametros.lancamentoSituacaoId;

        if (!dados.centroCustoId) {
            dados.centroCustoId = operacaoParametros.centroCustoId;
        }
        if (!dados.entrada) {
            dados.entrada = moment().startOf("day").format("YYYY-MM-DD 00:00");
        }
        if (!dados.saida) {
            dados.saida = moment().startOf("day").format("YYYY-MM-DD 00:00");
        }
    }

    //para locação vazia preenche valores pré definidos
    if (!dados.empresaId) {
        dados.empresaId = localStorage.getItem("empresaId");
        dados.ativo = true;

        dados.createdAt = moment().format("YYYY-MM-DD HH:mm:ss");
        dados.updatedAt = moment().format("YYYY-MM-DD HH:mm:ss");
        dados.data = moment().format("YYYY-MM-DD 00:00:00");

        dados.multa = Number(2).toFixed(2);
        dados.juros = Number(1).toFixed(2);
        dados.diaVencimento = "15";
        dados.condominio = "Locador";
        dados.iptu = "Locador";
        dados.contrato = "Proprietário";
        dados.energia = "Locatário";
        dados.agua = "Locatário";
        dados.locacao = "Comercial";
    }

    async function retornaOperacao(uuid) {
        try {
            const data = await OperacaoAPI.operacao(uuid);

            if (data?.response?.status === 404) {
                setFlashMessage(data.response.data.message, data.response.type);
                return;
            }

            setOperacao(data);
            setDados((prev) => ({ ...prev, operacaoId: data.id }));
            return data;
        } catch (erro) {
            // Lide com o erro
            console.error(erro);
        }
    }
    async function retornaOperacaoParametros(operacaoId, empresaId) {
        try {
            const data = await OperacaoAPI.retornaOperacaoParametros(operacaoId, empresaId);
            setOperacaoParametros(data);
            return data;
        } catch (error) {
            console.error(error);
        }
    }
    async function retornaParametroPessoa() {
        try {
            const data = await PessoaAPI.parametroPessoa(localStorage.getItem("pessoaId"));
            if (!data) {
                return;
            }
            setParametroPessoa(data);
            setDados((prev) => ({
                ...prev,
                colaboradorId: data.id,
            }));
            setColaborador({
                ...Colaborador,
                descricao: data.descricao,
            });
            return data;
        } catch (erro) {
            // Lide com o erro
            console.error(erro);
        }
    }
    async function retornaLancamentos(pessoaId, tipo) {
        try {
            const data = await FinanceiroAPI.lancamentosEmAberto(pessoaId, tipo);
            setLancamentos(data);
        } catch (erro) {
            console.error(erro);
        }
    }
    async function retornaParcelas(
        totalParcelas,
        primeiraDataVencimento,
        dadosLancamento,
        credito,
        fixaDiaVencimento,
        diasEntreParcelas,
        valorOriginal,
        valor,
        diaVencimento
    ) {
        //cria um objeto dados padrões do lançamento
        dadosLancamento = { movimentoId: dados.id, empresaId: dados.empresaId, provisao: dados.provisao };
        try {
            const data = await FinanceiroAPI.calcularParcelas(
                totalParcelas,
                primeiraDataVencimento,
                dadosLancamento,
                credito,
                fixaDiaVencimento,
                diasEntreParcelas,
                valorOriginal,
                valor,
                diaVencimento
            );
            return data;
        } catch (erro) {
            console.error(erro);
        }
    }
    async function retornaTabelaByDescricao(empresaId, tabela, descricao) {
        try {
            const data = await TabelaAPI.TabelaByDescricao(empresaId, tabela, descricao);

            if (data && operacao?.sigla === "LIM") {
                lancamentoSituacaoId = data.id; // id da situação para a descrição Aberto
            }
        } catch (error) {
            console.error("Erro ao buscar a tabela por descrição:", error);
        }
    }

    //abas
    const [tabEnabled, setTabEnabled] = useState({
        cabeçalho: true,
        financeiro: true,
        itens: true,
        lancamento: true,
        observacao: true,
        aditivo: false,
    });

    //set datalist
    const [Colaborador, setColaborador] = useState(formData?.Colaborador);
    const [OptionsColaborador, setOptionsColaborador] = useState([]);

    const [Parceiro, setParceiro] = useState(formData?.Parceiro);
    const [OptionsParceiro, setOptionsParceiro] = useState([]);

    const [ParceiroVinculado, setParceiroVinculado] = useState(formData?.ParceiroVinculado);
    const [OptionsParceiroVinculado, setOptionsParceiroVinculado] = useState([]);

    const [Administrador, setAdministrador] = useState(formData?.Administrador);
    const [OptionsAdministrador, setOptionsAdministrador] = useState([]);

    //data and fetching state
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [rowCountFinanceiro, setRowCountFinanceiro] = useState(0);
    const [rowCountLancamento, setRowCountLancamento] = useState(0);
    const [rowCountAditivo, setRowCountAditivo] = useState(0);

    //table state
    const [columnFilterFns, setColumnFiltersMode] = useState(
        localStorage.columnFilterFns.length > 2 ? JSON.parse(localStorage.columnFilterFns) : []
    );
    const [columnFilters, setColumnFilters] = useState(
        localStorage.columnFilters.length > 2 ? JSON.parse(localStorage.columnFilters) : []
    );
    const [globalFilter, setGlobalFilter] = useState(
        localStorage.globalFilter.length > 2 ? JSON.parse(localStorage.globalFilter) : ""
    );
    const [sorting, setSorting] = useState(localStorage.sorting.length > 2 ? JSON.parse(localStorage.sorting) : []);
    const [pagination, setPagination] = useState({
        pageIndex: JSON.parse(localStorage.pagination).pageIndex || 0,
        pageSize: JSON.parse(localStorage.pagination).pageSize || 5,
    });

    const movimentoId = dados.id ? dados.id : null;

    //Atualiza o Datalist
    const consultaDatalist = async (empresaId, tabela, id, descricao, condicao, filtro, operacao, funcionarioId) => {
        const result = await DataListAPI.dataList(empresaId, tabela, id, descricao, condicao, filtro, operacao, funcionarioId);
        return result;
    };
    const consultaDatalistColaborador = async (empresaId, filtro, ativo, tipo) => {
        const result = await DataListAPI.dataListColaborador(empresaId, filtro, ativo, tipo);
        return result;
    };

    const consultaDatalistParceiroImoveis = async (
        empresaId,
        filtro,
        locador,
        locatario,
        fiador,
        funcionarioId,
        administrador
    ) => {
        const data = await DataListAPI.dataListParceiroImovel(
            empresaId,
            filtro,
            locador,
            locatario,
            fiador,
            funcionarioId,
            administrador
        );
        return data;
    };
    const consultaDatalistProduto = async (empresaId, tabelaPrecoId, filtro) => {
        const data = await DataListAPI.dataListTabelaPrecoItemProduto(empresaId, tabelaPrecoId, filtro);
        return data;
    };

    //Atualiza Selects
    const [OptionsSituacao, setOptionsSituacao] = useState([]);
    const [OptionsCC, setCC] = useState([]); //centro de custo
    const [OptionsForma, setForma] = useState([]);
    const [OptionsPrazo, setPrazo] = useState([]);
    const [OptionsConta, setConta] = useState([]);
    const [OptionsTabelaPreco, setTabelaPreco] = useState([]);

    //Enums
    const [enumCondominio, setEnumCondominio] = useState([]);
    const [enumDiaVencimento, setEnumDiaVencimento] = useState([]);
    const [enumLocacao, setEnumLocacao] = useState([]);
    const [enumIPTU, setEnumIPTU] = useState([]);
    const [enumContrato, setEnumContrato] = useState([]);
    const [enumEnergia, setEnumEnergia] = useState([]);
    const [enumAgua, setEnumAgua] = useState([]);

    const [enumTipoAditivo, setEnumTipoAditivo] = useState([]);

    const consultaEnum = async (table, field, filter) => {
        const data = await TabelaAPI.enums(table, field, filter);

        const movimentosFields = {
            condominio: setEnumCondominio,
            diaVencimento: setEnumDiaVencimento,
            locacao: setEnumLocacao,
            iptu: setEnumIPTU,
            contrato: setEnumContrato,
            energia: setEnumEnergia,
            agua: setEnumAgua,
        };

        const aditivosFields = {
            tipo: setEnumTipoAditivo,
        };

        if (table === "Movimentos" && movimentosFields[field]) {
            movimentosFields[field](data);
        } else if (table === "movimentoAditivos" && aditivosFields[field]) {
            aditivosFields[field](data);
        }
    };

    // Estado para rastrear a aba ativa
    const [abaAtiva, setAbaAtiva] = useState(0);
    const [gridAtiva, setGridAtiva] = useState("financeiro");

    /* identifica a aba atual */
    const handleSelect = (index) => {
        setAbaAtiva(index);

        // Definir qual grid será atualizada com base na aba ativa
        switch (index) {
            case 0:
                setGridAtiva("cabecalho");
                break;
            case 1:
                setGridAtiva("financeiro");
                break;
            case 2:
                setGridAtiva("movimento");
                break;
            case 3:
                setGridAtiva("lancamento");
                break;
            case 4:
                setGridAtiva("observacao");
                break;
            case 5:
                setGridAtiva("aditivo");
                break;
            default:
                setGridAtiva("financeiro");
        }
    };

    useEffect(() => {
        setDados(formData || {});
        setParceiro(formData?.Parceiro || {});
        setParceiroVinculado(formData?.ParceiroVinculado || {});
        setAdministrador(formData?.Administrador || {});
        setItens(formData?.movimentoItems || []);

        consultaCC();
        retornaOperacao(operacaoUUID);
        retornaParametroPessoa();
        consultaConta();
        /* ENUM */
        consultaEnum("Movimentos", "condominio", null);
        consultaEnum("Movimentos", "diaVencimento", null);
        consultaEnum("Movimentos", "locacao", null);
        consultaEnum("Movimentos", "iptu", null);
        consultaEnum("Movimentos", "contrato", null);
        consultaEnum("Movimentos", "energia", null);
        consultaEnum("Movimentos", "agua", null);

        consultaEnum("movimentoAditivos", "tipo", null);

        //conta os registros e faz a paginação
        setRowCount(formData?.movimentoItems?.length);
        setRowCountLancamento(formData?.Lancamentos?.length);
        setRowCountFinanceiro(formData?.Lancamentos?.length);
        setRowCountAditivo(formData?.movimentoAdivitos?.length);

        //bloquea as alterações
        if (formData.assinado) {
            setIsDisabled(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);
    useEffect(() => {
        const fetchOperacaoParametros = async () => {
            if (operacao?.id && parametro?.empresaId) {
                await retornaOperacaoParametros(operacao.id, parametro.empresaId);
            }
        };

        fetchOperacaoParametros();
    }, [operacao, parametro]);
    useEffect(() => {
        setTabEnabled((prev) => ({ ...prev, lancamento: operacaoParametros.geraFinanceiro === true ? true : false }));
        operacaoParametros && setOperacaoParametrosDocumentos(operacaoParametros.operacaoParametrosDocumentos);
    }, [operacaoParametros]);

    useEffect(() => {
        //modifica o campo para a select de situacao
        if (operacao.sigla === "LIM") {
            retornaTabelaByDescricao(parametro.tabelasComuns === true ? 1 : dados.empresaId, "Situacao", "Aberto");
            consultaSituacao(parametro.tabelasComuns === true ? 1 : dados.empresaId, "locacao");

            if (!dados.id) {
                dados.situacaoId = 3;
                dados.provisao = true;
            }

            setTabEnabled((prev) => ({ ...prev, aditivo: true }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operacao]);

    useEffect(() => {
        if (gridAtiva) {
            // Chame aqui o fetchData() com a gridAtiva
            fetchData(gridAtiva);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        columnFilterFns,
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
        movimentoId,
        dados.parceiroId,
        gridAtiva,
    ]);
    //atualiza a quantidade para locação de imóvel
    useEffect(() => {
        const fetchPrazoLIM = async () => {
            if (!dados.prazoId) {
                return;
            }
            try {
                const resultado = await FinanceiroAPI.buscaPrazoById(dados.prazoId);
                if (resultado) {
                    const parcela = resultado.parcela;
                    novoProduto.quantidade = parcela;
                    const valorTotal = (parcela * novoProduto.valorUnitario).toFixed(2);

                    const dataSaida = dados.dataSaida ? moment(dados.dataSaida).format("YYYY-MM-DD HH:mm:ss") : null;
                    const entrada =
                        dados.locacao === "Comercial" || dados.locacao === "Residencial"
                            ? moment(dados.entrada).startOf("day").format("YYYY-MM-DD 00:00:00")
                            : moment(dados.entrada).format("YYYY-MM-DD HH:mm:ss");
                    const saida =
                        dados.locacao === "Comercial" || dados.locacao === "Residencial"
                            ? moment(dados.entrada).add(parcela, "months").startOf("day").format("YYYY-MM-DD 00:00:00")
                            : moment(dados.saida).format("YYYY-MM-DD HH:mm:ss");

                    setDados((prev) => ({
                        ...prev,
                        totalValor: valorTotal ?? 0,
                        totalParcela: parcela,
                        valorParcela: novoProduto.valorUnitario ?? 0,
                        Prazo: resultado,
                        dataSaida: dataSaida,
                        entrada: entrada,
                        saida: saida,
                    }));

                    setNovoProduto((prev) => ({
                        ...prev,
                        quantidade: parcela,
                        valorTotal: valorTotal,
                    }));
                }
            } catch (error) {
                console.error("Erro ao buscar prazo:", error);
            }
        };

        operacao.sigla === "LIM" && fetchPrazoLIM();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dados.prazoId]);

    useEffect(() => {
        // Atualizar tabelas vinculadas ao parceiro
        // prazo, forma, etc
        dados.parceiroId && atualizaTabelasVinculadasParceiro(dados.parceiroId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dados.parceiroId]);

    useEffect(() => {
        if (itemLancamentos.length > 0) {
            setDados((prev) => ({
                ...prev, // Mantém as outras propriedades do objeto
                Lancamentos: itemLancamentos, // Atualiza os lançamentos
            }));
        }
    }, [itemLancamentos]); // Executa o efeito quando `itemLancamentos` é atualizado

    useEffect(() => {
        let valor = "Locatário";
        if (dados.solar === true) {
            valor = "Locador";

            setDados((prev) => ({
                ...prev,
                energia: valor,
            }));
        }
    }, [dados.solar]); // Executa o efeito quando `itemLancamentos` é atualizado

    useEffect(() => {
        if (novoAditivo.tipo && novoAditivo.funcao.toLocaleLowerCase() === "adicionar") {
            // Verifica cada item em itemAditivos
            itemAditivos.forEach((item) => {
                // Comparar as datas e o tipo
                if (novoAditivo.data <= item.data && novoAditivo.tipo === item.tipo) {
                    // Adicione a lógica que você deseja aqui
                    setFlashMessage(
                        `Último Termo Aditivo de ${novoAditivo.tipo} foi ${moment(item.data).format(
                            "DD/MM/YYYY"
                        )}, a data tem que ser superior ao último reajuste!`,
                        "warning"
                    );
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [novoAditivo.data]);

    //Tratamento por situacao
    useEffect(() => {
        if (operacao.sigla === "LIM") {
            if (dados.situacaoId === "2" || dados.situacaoId === "9") {
                setIsDisabled(false);
                setDados((prev) => ({
                    ...prev,
                    assinado: false,
                    verificarSituacao: dados.situacaoId, // Adiciona o array ao estado
                }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dados.situacaoId]);

    const fetchData = async (grid) => {
        if (!itens.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }
        if (dados.id) {
            if (grid === "financeiro") {
                try {
                    MovimentoAPI.listarFinanceiro(
                        pagination.pageIndex * pagination.pageSize,
                        pagination.pageSize,
                        JSON.stringify(columnFilters ?? []),
                        JSON.stringify(columnFilterFns ?? []),
                        globalFilter ?? "",
                        JSON.stringify(sorting ?? []),
                        dados.parceiroId ?? "",
                        lancamentoSituacaoId ?? 1
                    ).then((response) => {
                        setLancamentos(response.data.data);
                        setRowCountFinanceiro(response.data.meta.totalRowCount);
                    });
                } catch (error) {
                    setIsError(true);
                    console.error(error);
                    return;
                }
            }
            if (grid === "produto") {
                try {
                    MovimentoAPI.listarProdutos(
                        pagination.pageIndex * pagination.pageSize,
                        pagination.pageSize,
                        JSON.stringify(columnFilters ?? []),
                        JSON.stringify(columnFilterFns ?? []),
                        globalFilter ?? "",
                        JSON.stringify(sorting ?? []),
                        movimentoId ?? ""
                    ).then((response) => {
                        setItens(response.data.data);
                        setRowCount(response.data.meta.totalRowCount);
                    });
                } catch (error) {
                    setIsError(true);
                    console.error(error);
                    return;
                }
            }
            if (grid === "lancamento") {
                try {
                    MovimentoAPI.listarLancamentos(
                        pagination.pageIndex * pagination.pageSize,
                        pagination.pageSize,
                        JSON.stringify(columnFilters ?? []),
                        JSON.stringify(columnFilterFns ?? []),
                        globalFilter ?? "",
                        JSON.stringify(sorting ?? []),
                        movimentoId ?? ""
                    ).then((response) => {
                        setItemLancamentos(response.data.data);
                        setRowCountLancamento(response.data.meta.totalRowCount);
                    });
                } catch (error) {
                    setIsError(true);
                    console.error(error);
                    return;
                }
            }
            if (grid === "aditivo") {
                try {
                    MovimentoAPI.listarAditivos(
                        pagination.pageIndex * pagination.pageSize,
                        pagination.pageSize,
                        JSON.stringify(columnFilters ?? []),
                        JSON.stringify(columnFilterFns ?? []),
                        globalFilter ?? "",
                        JSON.stringify(sorting ?? []),
                        movimentoId ?? ""
                    ).then((response) => {
                        setItemAditivos(response.data.data);
                        setRowCountAditivo(response.data.meta.totalRowCount);
                    });
                } catch (error) {
                    setIsError(true);
                    console.error(error);
                    return;
                }
            }
        }

        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
    };

    //Consultas no backend
    const consultaSituacao = async (empresaId, campo) => {
        try {
            const result = await FinanceiroAPI.situacao(empresaId, campo);
            setOptionsSituacao(result);

            // Mapeamento de operacao.sigla para descrições de situação
            const operacaoSituacoes = {
                LIM: "Orçamento",
                // Adicione aqui outras siglas e suas descrições correspondentes
                OUTRA_SIGLA: "Outra Descricao",
            };

            if (!dados.situacaoId && operacao.sigla in operacaoSituacoes) {
                const descricaoSituacao = operacaoSituacoes[operacao.sigla];

                // Encontra a situação correspondente à descrição mapeada
                const situacaoEncontrada = result.find((item) => item.descricao === descricaoSituacao);

                if (situacaoEncontrada) {
                    dados.situacaoId = situacaoEncontrada.id; // Define o id da situação correspondente
                } else {
                    console.warn(`Situação com descrição "${descricaoSituacao}" não encontrada.`);
                }
            }

            return result;
        } catch (error) {
            console.error("Erro ao consultar situação:", error);
        }
    };

    const consultaCC = async () => {
        const result = await EmpresaAPI.centroCustos(dados.empresaId, "", tipo === "Entrada" ? "Pagar" : "Receber");
        setCC(result);
        return result;
    };
    const consultaForma = async (tipo, credito, empresaId = dados.empresaId, descricao, dependente, nivel, formaId) => {
        const result = await FinanceiroAPI.forma(tipo, credito, empresaId, descricao, dependente, nivel, formaId);
        setForma(result);
        return result;
    };
    const consultaPrazo = async (tipo, formaId, nivel, prazoId, empresaId = dados.empresaId, prazoMedio) => {
        const result = await FinanceiroAPI.prazo(tipo, formaId, nivel, prazoId, empresaId, prazoMedio);
        setPrazo(result);
        return result;
    };
    const consultaConta = async () => {
        const result = await TabelaAPI.tabela(dados.empresaId, "Conta", "descricao");
        setConta(result);
    };
    const consultaTabelaPreco = async (empresaId, pessoaId, operacaoId, entrada, liberada, locacao, saida, servico) => {
        const result = await TabelaAPI.precoByPessoaId(
            empresaId,
            pessoaId,
            operacaoId,
            entrada,
            liberada,
            locacao,
            saida,
            servico
        );
        return result;
    };
    const consultaParceiro = async (empresaId = dados.empresaId, pessoaId) => {
        const result = await PessoaAPI.financeiro(empresaId, pessoaId);
        return result;
    };

    const atualizaDadosPorParceiro = async (parceiroId) => {
        if (!parceiroId) {
            return null;
        }
        const resultado = await consultaParceiro(null, parceiroId);

        if (resultado) {
            const formaId = tipo === "Entrada" ? resultado.formaFornecedorId : resultado.formaClienteId;
            const prazoId = tipo === "Entrada" ? resultado.prazoFornecedorId : resultado.prazoClienteId;
            const contaId = tipo === "Entrada" ? resultado.contaFornecedorId : resultado.contaClienteId;
            const nivelVenda = tipo === "Entrada" ? resultado.prazoFornecedor?.nivelVenda : resultado.prazoCliente?.nivelVenda;
            const prazoMedio = tipo === "Entrada" ? resultado.prazoFornecedor?.prazoMedio : resultado.prazoCliente?.prazoMedio;

            let formaCorrespondente = null;
            let prazoCorrespondente = null;

            // Atualiza os dados do endereço principal
            if (resultado.pessoaEnderecos) {
                const enderecoPrincipal = resultado.pessoaEnderecos.find((endereco) => endereco.principal === true);

                if (enderecoPrincipal) {
                    setDados((prevState) => ({
                        ...prevState,
                        remetenteCEP: tipo === "Entrada" ? enderecoPrincipal.cep : empresaArmazenada.cep || null,
                        remetenteBairro: tipo === "Entrada" ? enderecoPrincipal.bairro : empresaArmazenada.bairro || null,
                        remetenteLogradouro:
                            tipo === "Entrada" ? enderecoPrincipal.logradouro : empresaArmazenada.logradouro || null,
                        remetenteNumero: tipo === "Entrada" ? enderecoPrincipal.numero : empresaArmazenada.numero || null,
                        remetenteComplemento:
                            tipo === "Entrada" ? enderecoPrincipal.complemento : empresaArmazenada.complemento || null,
                        remetenteUfId: tipo === "Entrada" ? enderecoPrincipal.ufId : empresaArmazenada.ufId || null,
                        remetenteMunicipioId:
                            tipo === "Entrada" ? enderecoPrincipal.municipioId : empresaArmazenada.municipioId || null,

                        destinatarioCEP: tipo === "Entrada" ? empresaArmazenada.cep : enderecoPrincipal.cep || null,
                        destinatarioBairro: tipo === "Entrada" ? empresaArmazenada.bairro : enderecoPrincipal.bairro || null,
                        destinatarioLogradouro:
                            tipo === "Entrada" ? empresaArmazenada.logradouro : enderecoPrincipal.logradouro || null,
                        destinatarioNumero: tipo === "Entrada" ? empresaArmazenada.numero : enderecoPrincipal.numero || null,
                        destinatarioComplemento:
                            tipo === "Entrada" ? empresaArmazenada.complemento : enderecoPrincipal.complemento || null,
                        destinatarioUfId: tipo === "Entrada" ? empresaArmazenada.ufId : enderecoPrincipal.ufId || null,
                        destinatarioMunicipioId:
                            tipo === "Entrada" ? empresaArmazenada.municipioId : enderecoPrincipal.municipioId || null,
                    }));
                }
            }
            // Consulta forma de pagamento, se houver nível de venda
            const nivel = tipo === "Entrada" ? resultado?.formaFornecedor?.nivelVenda : resultado?.formaCliente?.nivelVenda;
            formaCorrespondente = await consultaForma(
                tipo === "Entrada" ? "Pagar" : "Receber",
                false,
                dados.empresaId,
                null,
                false,
                nivel,
                null
            );

            // Consulta prazo de pagamento
            prazoCorrespondente = await consultaPrazo(
                tipo === "Entrada" ? "Pagar" : "Receber",
                formaId,
                nivelVenda,
                "",
                dados.empresaId,
                prazoMedio
            );

            // Atualiza as tabelas de preços liberadas e de locacao
            const result = await consultaTabelaPreco(dados.empresaId, parceiroId, false, false, true, true, false, false);
            setTabelaPreco(result);

            retornaLancamentos(parceiroId, lancamentoTipo);

            // Filtrando prazoCorrespondente para obter apenas o item com o prazoId
            const prazoFiltrado = prazoCorrespondente.find((prazo) => prazo.id === prazoId);

            // Filtrando formaCorrespondente para obter apenas o item com o formaId
            const formaFiltrada = formaCorrespondente.find((forma) => forma.id === formaId);

            // Atualiza os dados principais do parceiro
            setDados((prevState) => ({
                ...prevState,
                Operacao: operacao,
                parceiroId: parceiroId,
                formaId: formaId,
                prazoId: prazoId,
                contaId: contaId,
                Forma: formaFiltrada, //alterei forma e prazo para inicial maiuscula
                Prazo: prazoFiltrado,
            }));
        }
    };
    const atualizaTabelasVinculadasParceiro = async (parceiroId) => {
        const resultado = await PessoaAPI.pessoaById(parceiroId);
        // Consulta forma de pagamento, se houver nível de venda
        const nivel = tipo === "Entrada" ? resultado.formaFornecedor?.nivelVenda : resultado.formaCliente?.nivelVenda;
        await consultaForma(tipo === "Entrada" ? "Pagar" : "Receber", false, dados.empresaId, null, null, nivel, null);
        await consultaPrazo(
            tipo === "Entrada" ? "Pagar" : "Receber",
            tipo === "Entrada" ? resultado.formaFornecedorId : resultado.formaClienteId,
            tipo === "Entrada" ? resultado.prazoFornecedor?.nivelVenda : resultado.prazoCliente?.nivelVenda,
            "",
            dados.empresaId,
            tipo === "Entrada" ? resultado.prazoFornecedor?.prazoMedio : resultado.prazoCliente?.prazoMedio
        );

        //ao escolher o parceiro atualiza as tabelas de preços disponíveis
        const result = await consultaTabelaPreco(dados.empresaId, parceiroId, operacao.id);
        setTabelaPreco(result);

        retornaLancamentos(parceiroId, lancamentoTipo);
    };

    //Condições de Venda do produto no Movimento
    const consultaCondicaoVenda = async (empresaId, tabelaPrecoId, produtoId, pessoaId) => {
        try {
            const result = await MovimentoAPI.condicaoVenda(empresaId, tabelaPrecoId, produtoId, pessoaId);

            if (result && result.length > 0 && result[0].tabelaPrecoItens && result[0].tabelaPrecoItens.length > 0) {
                const { preco } = result[0].tabelaPrecoItens[0]; // Desestruturação para obter o preço

                const novoProduto = {
                    produtoId: produtoId,
                    valorUnitario: preco, // Atualiza o valor com o preço retornado
                };

                /* Locação de Imóvel - LIM*/
                if (operacao.sigla === "LIM") {
                    const res = await FinanceiroAPI.buscaPrazoById(dados.prazoId);
                    const parcela = res.parcela;
                    diasEntreParcelas = res.parcela;
                    novoProduto.quantidade = parcela;
                    novoProduto.valorTotal = (parcela * preco).toFixed(2);
                }

                setNovoProduto((prev) => ({
                    ...prev,
                    ...novoProduto,
                }));
            } else {
                console.warn("Dados inesperados ou incompletos recebidos:", result);
            }

            return result;
        } catch (error) {
            console.error("Erro ao consultar a condição de venda:", error);
            // Dependendo da lógica do seu sistema, você pode querer retornar um valor padrão ou tomar uma ação específica em caso de erro.
            return null;
        }
    };

    /*Tabela de movimentos de produtos  movimentoItem */
    const limparProduto = () => {
        setNovoProduto({
            empresaId: dados.empresaId,
            embalagemId: null,
            preco: null,
            precoUnitario: null,
            ativo: true,
            fator: 1,
            funcao: "Adicionar",
        });
        setProduto({
            id: null,
            descricao: null,
        });
        //setOptionsEmbalagem([]);
    };
    const handleProduto = async () => {
        if (novoProduto.funcao.toLocaleLowerCase() === "editar") {
            if (novoProduto.uuid) {
                CreateOrEditProduto(novoProduto);
            } else {
                setItens((prevItens) => {
                    const novosItens = [...prevItens];
                    novosItens[novoProduto.index] = {
                        ...prevItens[novoProduto.index],
                        ...novoProduto,
                    };
                    return novosItens;
                });
                setRowCount(itens.length);

                //caso locação de imoveis
                if (operacao.sigla === "LIM") {
                    setItemLancamentos([]);
                    atualizaLancamentosLIM(novoProduto);
                }
            }
        } else if (novoProduto.funcao.toLocaleLowerCase() === "deletar") {
            if (novoProduto.uuid) {
                try {
                    const response = await MovimentoAPI.deletarProduto(novoProduto.uuid);
                    setFlashMessage(response.data.message, response.data.type);
                } catch (error) {
                    console.error(error);
                    setFlashMessage(error.response.data.message, error.response.data.type);
                }
            } else {
                setItens((prevItens) => {
                    const novosItens = [...prevItens];
                    novosItens.splice(novoProduto.index, 1);
                    return novosItens;
                });
            }
        } else {
            //cadastrar
            if (dados.id) {
                //incluir o numero do movimento ao objeto
                novoProduto.movimentoId = dados.id;

                //verificar duplicidade
                const result = await MovimentoAPI.duplicidadeProduto(
                    novoProduto.empresaId,
                    novoProduto.movimentoId,
                    novoProduto.produtoId,
                    novoProduto.embalagemId
                );

                if (result && novoProduto.funcao.toLocaleLowerCase() === "adicionar") {
                    setFlashMessage("Registo em duplicidade!", "warning");
                    handleEditar(result);
                    return;
                }

                CreateOrEditProduto(novoProduto);
            } else {
                const novoItemArray = [
                    ...itens,
                    {
                        uuid: novoProduto.uuid,
                        empresaId: novoProduto.empresaId || dados.empresaId,
                        tabelaPrecoId: novoProduto.tabelaPrecoId,
                        produtoId: novoProduto.produtoId,
                        embalagemId: novoProduto.embalagemId,

                        descricao: novoProduto.descricao,
                        fator: novoProduto.fator,
                        custo: novoProduto.custo,
                        valorUnitario: novoProduto.valorUnitario,
                        quantidade: novoProduto.quantidade,
                        valorTotal: novoProduto.valorTotal,

                        ativo: novoProduto.ativo,

                        Embalagem: novoProduto.Embalagem,
                        Empresa: novoProduto.Empresa,
                        Produto: novoProduto.Produto,
                    },
                ];

                setItens(novoItemArray);
                setRowCount(novoItemArray.length);

                setDados((prev) => ({
                    ...prev, // Mantém as outras propriedades do objeto
                    movimentoItems: novoItemArray, // Adiciona o novo array ou atualiza o existente
                    totalValor: novoProduto.valorTotal,
                    totalParcela: novoProduto.quantidade,
                    valorParcela: novoProduto.valorUnitario,
                }));

                //caso locação de imoveis
                if (operacao.sigla === "LIM") {
                    atualizaLancamentosLIM(novoProduto);
                }
            }
        }

        limparProduto();

        fetchData("produto");
    };
    const handleEditar = async (originalRow, row) => {
        // Define os dados para o novo produto
        const novoProdutoData = {
            uuid: originalRow.uuid,
            Produto: originalRow.Produto,
            Embalagem: originalRow.Embalagem,
            Empresa: originalRow.Empresa,

            empresaId: originalRow.empresaId,
            embalagemId: originalRow.embalagemId,
            movimentoId: originalRow.movimentoId,

            fator: originalRow.fator,

            tabelaPrecoId: originalRow.tabelaPrecoId,
            produtoId: originalRow.produtoId,
            descricao: originalRow.Produto.descricao,

            valorUnitario: originalRow.valorUnitario,
            quantidade: originalRow.quantidade,
            valorTotal: originalRow.valorTotal,

            ativo: originalRow.ativo,
            funcao: "Editar",
            index: row,
        };

        // Atualize o estado do novo produto
        setNovoProduto(novoProdutoData);

        // Atualize o estado do Produto com a descrição
        setProduto({ ...Produto, descricao: originalRow.descricao });

        //Atualiza o estado da Embalagem
        //setOptionsEmbalagem([originalRow.Embalagem]);

        return;
    };
    const handleDelete = (originalRow, row) => {
        const dado = {
            uuid: originalRow.uuid,
            Produto: originalRow.Produto,
            Embalagem: originalRow.Embalagem,
            Empresa: originalRow.Empresa,

            empresaId: originalRow.empresaId,
            embalagemId: originalRow.embalagemId,
            movimentoId: originalRow.movimentoId,

            fator: originalRow.fator,

            tabelaPrecoId: originalRow.tabelaPrecoId,
            produtoId: originalRow.produtoId,
            descricao: originalRow.Produto.descricao,

            valorUnitario: originalRow.valorUnitario,
            quantidade: originalRow.quantidade,
            valorTotal: originalRow.valorTotal,

            ativo: originalRow.ativo,
            funcao: "Deletar",
            index: row,
        };

        // Atualize o estado
        setNovoProduto(dado);
    };
    const CreateOrEditProduto = async (novoProduto) => {
        try {
            const result = await MovimentoAPI.managerProduto(novoProduto);
            setFlashMessage(result.message, result.type);
        } catch (error) {
            console.error(error);
            if (error.response && error.response.status === 422) {
                // Tratar o erro 422, que é específico da validação no servidor
                setFlashMessage("Erro de validação: " + error.response.data.message, "error");
            } else {
                // Tratar outros erros de rede ou servidor
                setFlashMessage("Ocorreu um erro ao processar a solicitação.", "error");
            }
        }
    };

    //Termo Aditivo
    const handleAditivo = async () => {
        if (dados.id) {
            //incluir o numero do movimento ao objeto
            novoAditivo.movimentoId = dados.id;

            //ajusta o formato da data
            const data = moment(novoAditivo.data).format("YYYY-MM-DD");
            novoAditivo.data = data;
        } else {
            //não pode reajustar nem prorrogar se o movimento não existe
            return;
        }

        if (novoAditivo.funcao.toLocaleLowerCase() === "editar") {
            if (novoAditivo.uuid) {
                await CreateOrEditAditivo(novoAditivo);
            } else {
                setItemAditivos((prevItens) => {
                    const novosItens = [...prevItens];
                    novosItens[novoAditivo.index] = {
                        ...prevItens[novoAditivo.index],
                        ...novoAditivo,
                    };
                    return novosItens;
                });
                setRowCountAditivo(itemAditivos.length);

                //caso locação de imoveis
                if (operacao.sigla === "LIM") {
                }
            }
        } else if (novoAditivo.funcao.toLocaleLowerCase() === "deletar") {
            if (novoAditivo.uuid) {
                try {
                    const response = await MovimentoAPI.deletarAditivo(novoAditivo.uuid);
                    setFlashMessage(response.data.message, response.data.type);
                } catch (error) {
                    console.error(error);
                    setFlashMessage(error.response.data.message, error.response.data.type);
                }
            } else {
                setItemAditivos((prevItens) => {
                    const novosItens = [...prevItens];
                    novosItens.splice(novoAditivo.index, 1);
                    return novosItens;
                });
            }
        } else {
            //cadastrar

            //verificar duplicidade
            const result = await MovimentoAPI.duplicidadeAditivo(novoAditivo.movimentoId, novoAditivo.tipo, novoAditivo.data);

            if (result) {
                setFlashMessage("Registo em duplicidade!", "warning");
                handleAditivoEditar(result);
                return;
            } else {
                await CreateOrEditAditivo(novoAditivo);
            }
        }

        limparAditivo();
        fetchData("aditivo");
    };
    const CreateOrEditAditivo = async (novoAditivo) => {
        // Força o valorAnterior no contrato
        novoAditivo.valorAnterior = itemAditivos[0]?.valorReajustado || itens[0]?.valorUnitario;

        try {
            const result = await MovimentoAPI.managerAditivo(novoAditivo);

            const msgText = result.message ? result.message : result.response.data.message;
            const msgType = result.type ? result.type : result.response.data.type;

            setFlashMessage(msgText, msgType);

            return result;
        } catch (error) {
            console.error(error);
            if (error.response && error.response.status === 422) {
                // Tratar o erro 422, que é específico da validação no servidor
                setFlashMessage("Erro de validação: " + error.response.data.message, "error");
            } else {
                // Tratar outros erros de rede ou servidor
                setFlashMessage("Ocorreu um erro ao processar a solicitação.", "error");
            }
        }
    };
    const handleAditivoEditar = async (originalRow, row) => {
        // Define os dados
        const novoAditivoData = {
            id: originalRow.id,
            uuid: originalRow.uuid,
            movimentoId: originalRow.movimentoId,
            tipo: originalRow.tipo,
            data: originalRow.data,
            valorAnterior: originalRow.valorAnterior,
            percentual: originalRow.percentual,
            valorReajustado: originalRow.valorReajustado,
            quantidade: originalRow.quantidade,
            observacao: originalRow.observacao,

            ativo: originalRow.ativo,
            funcao: "Editar",
            index: row,
        };

        // Atualize o estado do novo produto
        setNovoAditivo(novoAditivoData);

        return;
    };
    const handleAditivoDelete = (originalRow, row) => {
        const dado = {
            uuid: originalRow.uuid,
            movimentoId: originalRow.contaId,
            tipo: originalRow.tipo,
            data: originalRow.data,
            valorAnterior: originalRow.valorAnterior,
            percentual: originalRow.percentual,
            valorReajustado: originalRow.valorReajustado,
            quantidade: originalRow.quantidade,
            observacao: originalRow.observacao,

            ativo: originalRow.ativo,
            funcao: "Deletar",
            index: row,
        };

        // Atualize o estado
        setNovoAditivo(dado);
    };
    const limparAditivo = () => {
        setNovoAditivo({
            movimentoId: dados.id,
            diaVencimento: dados.diaVencimento,
            tipo: null,
            data: null,
            percentual: null,
            valorReajustado: null,
            quantidade: null,
            observacao: null,

            ativo: true,
            funcao: "Adicionar",
        });
    };
    // const handleAditivoImprimir = async (originalRow, row) => {
    //     const urlSearchParams = new URLSearchParams({
    //         start: `${pagination.pageIndex * pagination.pageSize}`,
    //         size: `${pagination.pageSize}`,
    //         filters: JSON.stringify(columnFilters ?? []),
    //         filtersMode: JSON.stringify(columnFilterFns ?? []),
    //         globalFilter: globalFilter ?? "",
    //         sorting: JSON.stringify(sorting ?? []),
    //         tipo: urlParams.get("tipo") ?? "",
    //     });

    //     const response = await MovimentoAPI.imprimirAditivo(urlSearchParams, originalRow);

    //     // Cria um Blob a partir dos dados do arraybuffer
    //     const pdfBlob = new Blob([response.data], {
    //         type: "application/pdf",
    //     });

    //     // Cria um URL temporário para o Blob
    //     const pdfUrl = URL.createObjectURL(pdfBlob);

    //     // Use o URL do PDF para abrir em uma nova janela ou realizar outras ações
    //     window.open(pdfUrl);
    // };
    //Atualiza os lançamentos para registro ainda não gravado no banco
    /**
     * utilizado em lançamentos não gravados no banco de dados
     * @param {*} novoProduto
     */
    const atualizaLancamentosLIM = async (novoProduto) => {
        let contador = 0;

        const totalParcelas = dados.Prazo.parcela || novoProduto.quantidade;
        const dataVencimento = moment(dados.data).date(dados.diaVencimento).add(dados.Prazo.primeiraParcela, "days");
        const primeiraDataVencimento = moment(dataVencimento).date(dados.diaVencimento).format("YYYY-MM-DD");

        //inseri os lançamentos
        const result = await retornaParcelas(
            totalParcelas,
            primeiraDataVencimento,
            {},
            false,
            true,
            dados.Prazo.diasEntreParcelas,
            novoProduto.valorTotal,
            novoProduto.valorUnitario,
            dados.diaVencimento
        );

        // Adiciona o campo empresaId a cada parcela
        const resultWithEmpresaId = result.map((parcela) => ({
            ...parcela,
            uuid: null, //remove o uuid
            empresaId: dados.empresaId,
            pessoaId: dados.parceiroId,
            formaId: dados.formaId,
            prazoId: dados.prazoId,
            centroCustoId: dados.centroCustoId,
            contaId: dados.contaId,
            colaboradorId: dados.colaboradorId,
            historico: `Parcela ${parcela.parcelas}, do Contrato de Locação Nº `,
            tipo: lancamentoTipo,
            situacaoId: lancamentoSituacaoId,
            dataLancamento: moment(dados.data).format("YYYY-MM-DD"),
            parcela: parcela.parcela,
            provisao: dados.provisao,
            aluguel: true, //campo para facilitar o processo reverso
        }));
        //acrescenta os registros
        contador = resultWithEmpresaId.length;

        // Atualiza os lançamentos com o novo array que inclui empresaId
        setItemLancamentos(resultWithEmpresaId);

        /* Calcula a comissão do corretor */
        if (dados.geraComissao && novoProduto.Produto.comissao > 0) {
            const valorDaComissao = (novoProduto.valorUnitario * novoProduto.Produto.comissao) / 100;
            const valorTotalComissao = valorDaComissao * novoProduto.quantidade;

            const resultComissao = await retornaParcelas(
                novoProduto.quantidade,
                dados.data,
                {}, //dadosLancamento
                false,
                true,
                diasEntreParcelas,
                valorTotalComissao,
                valorDaComissao,
                dados.diaVencimento
            );

            // Adiciona o campo empresaId a cada parcela
            const resultComissaoWithEmpresaId = resultComissao.map((parcela) => ({
                ...parcela,
                uuid: null, //remove o uuid
                empresaId: dados.empresaId,
                pessoaId: dados.colaboradorId,
                formaId: dados.formaId,
                prazoId: dados.prazoId,
                centroCustoId: parametro.centroCustoComissaoId, //obeter padrao
                contaId: dados.contaId,
                colaboradorId: dados.colaboradorId,
                historico: `Comissão, parcela ${parcela.parcelas} do Contrato de Locação Nº `,
                tipo: "Pagar",
                situacaoId: lancamentoSituacaoId,
                dataLancamento: moment(dados.data).format("YYYY-MM-DD"),
                parcela: parcela.parcela,
                provisao: dados.provisao,
                comissao: true, //campo para facilitar o processo reverso
            }));
            setItemLancamentos((prev) => [
                ...prev, // Mantém as parcelas anteriores
                ...resultComissaoWithEmpresaId,
            ]);
        }

        // Inserir a caução, se houver
        if (dados.caucao > 0) {
            setItemLancamentos((prev) => [
                ...prev, // Mantém as parcelas anteriores
                {
                    empresaId: dados.empresaId,
                    pessoaId: dados.parceiroId,
                    formaId: dados.formaId,
                    prazoId: dados.prazoId,
                    centroCustoId: dados.centroCustoId,
                    contaId: dados.contaId,
                    colaboradorId: dados.colaboradorId,
                    historico: "Caução do Contrato de Locação Nº ",
                    tipo: lancamentoTipo,
                    situacaoId: lancamentoSituacaoId,
                    dataLancamento: moment(dados.data).format("YYYY-MM-DD"),
                    parcela: "1",
                    parcelas: "1/1",

                    dataVencimento: moment(dados.data).format("YYYY-MM-DD"),
                    valorOriginal: dados.caucao,
                    valor: dados.caucao,
                    valorAberto: dados.caucao,
                    caucao: true, //campo para facilitar o processo reverso
                },
            ]);

            contador++;
        }

        // Inserir a comissão, se houver
        if (dados.valorComissao > 0) {
            setItemLancamentos((prev) => [
                ...prev, // Mantém as parcelas anteriores
                {
                    empresaId: dados.empresaId,
                    pessoaId: dados.colaboradorId,
                    formaId: dados.formaId,
                    prazoId: dados.prazoId,
                    centroCustoId: dados.centroCustoId,
                    contaId: dados.contaId,
                    colaboradorId: dados.colaboradorId,
                    historico: "Comissão do Contrato de Locação Nº ",
                    tipo: lancamentoComissaoTipo,
                    situacaoId: lancamentoSituacaoId,
                    dataLancamento: moment(dados.data).format("YYYY-MM-DD"),
                    parcela: "1",
                    parcelas: "1/1",

                    dataVencimento: moment(dados.data).format("YYYY-MM-DD"),
                    valorOriginal: dados.valorComissao,
                    valor: dados.valorComissao,
                    valorAberto: dados.valorComissao,
                    comissao: true, //campo para facilitar o processo reverso
                },
            ]);

            contador++;
        }

        setRowCountLancamento(contador);
    };

    /**
     *
     * @param {*} campo Campo facilitador boolean comissao, caucao, aluguel
     * @param {*} novoValor
     */
    const atualizarValorPorCampoFacilitar = (campo, novoValor) => {
        const updatedLancamentos = itemLancamentos.map((item) => {
            // Verifica se o histórico contém a frase específica (Comissão ou Caução)
            if (item[campo] === true) {
                // Verifica se o valor foi modificado
                if (item.valor !== parseFloat(novoValor)) {
                    return {
                        ...item, // Copia o restante das propriedades do objeto
                        valor: formatarNumeroDecimal(novoValor), // Atualiza o valor
                        valorAberto: formatarNumeroDecimal(novoValor),
                        valorOriginal: formatarNumeroDecimal(novoValor),
                    };
                }
            }
            return item; // Retorna o item inalterado se não for encontrado ou não modificado
        });

        // Atualiza o estado de itemLancamentos com o array atualizado
        setItemLancamentos(updatedLancamentos);
        setRowCountLancamento(updatedLancamentos.length);
    };

    async function handleChange(e) {
        const { name, value, checked, type } = e.target;
        const camposBooleanos = ["assinado", "ativo", "geraComissao", "orcamento", "provisao", "solar", "mobiliado"];
        const camposMonetarios = ["valorComissao", "percentualComissao", "caucao", "multa", "juros"];

        if (camposBooleanos.includes(name)) {
            let zeraComissao = {};
            if (name === "geraComissao" && checked === false) {
                zeraComissao = { percentualComissao: 0 };
            }
            setDados({ ...dados, [name]: checked, ...zeraComissao });
        } else if (camposMonetarios.includes(name)) {
            const valorFormatado = formatarNumeroDecimal(value);

            setDados((prevDados) => ({
                ...prevDados,
                [name]: valorFormatado,
                geraComissao: name === "valorComissao" && Number(value) > 0 ? false : prevDados.geraComissao,
            }));
        } else if (name.startsWith("Colaborador")) {
            setColaborador({
                ...Colaborador,
                [name.split(".")[1]]: value,
            });
            if (name.startsWith("Colaborador.descricao")) {
                //carrega o datalist
                const result = await consultaDatalistColaborador(dados.empresaId, value, true, operacao.tipoColaborador);
                setOptionsColaborador(result);

                const selectedId = document.querySelector(`datalist option[value="${value}"]`)?.dataset.id;
                setDados({
                    ...dados,
                    colaboradorId: selectedId ?? null,
                });
            }
        } else if (name.startsWith("Administrador.")) {
            setAdministrador({
                ...Administrador,
                [name.split(".")[1]]: value,
            });

            if (name === "Administrador.descricao") {
                //carrega o datalist
                const result = await consultaDatalistParceiroImoveis(dados.empresaId, value, null, null, null, null, true);
                setOptionsAdministrador(result);

                const selectedId = document.querySelector(`datalist option[value="${value}"]`)?.dataset.id;

                setDados((prev) => ({ ...prev, administradorId: selectedId ?? null }));
            }
        } else if (name.startsWith("Parceiro.")) {
            setParceiro({
                ...Parceiro,
                [name.split(".")[1]]: value,
            });

            if (name === "Parceiro.descricao") {
                //carrega o datalist
                const result = await consultaDatalistParceiroImoveis(
                    dados.empresaId,
                    value,
                    null,
                    true,
                    null,
                    dados.colaboradorId,
                    null
                );

                setOptionsParceiro(result);

                const selectedId = document.querySelector(`datalist option[value="${value}"]`)?.dataset.id || null;

                await atualizaDadosPorParceiro(selectedId);

                setDados((prev) => ({ ...prev, parceiroId: selectedId }));
            }
        } else if (name.startsWith("ParceiroVinculado.")) {
            setParceiroVinculado({
                ...ParceiroVinculado,
                [name.split(".")[1]]: value,
            });

            if (name.startsWith("ParceiroVinculado.descricao")) {
                //carrega o datalist
                const result = await consultaDatalistParceiroImoveis(
                    dados.empresaId,
                    value,
                    null,
                    null,
                    true,
                    dados.colaboradorId,
                    null
                );
                setOptionsParceiroVinculado(result);

                const selectedId = document.querySelector(`datalist option[value="${e.target.value}"]`)?.dataset.id;
                setDados((prev) => ({ ...prev, parceiroVinculadoId: selectedId ?? null }));
            }
        } else if (name.startsWith("novoProduto.")) {
            // Desestruturar o campo
            const campo = name.split(".")[1];

            // Verifica se é um checkbox e define o valor correspondente
            let valor = type === "checkbox" ? checked : value;

            if (campo === "tabelaPrecoId") {
                setNovoProduto((prev) => ({ ...prev, [campo]: valor }));
            } else if (campo === "descricao") {
                // Carrega o datalist
                setNovoProduto((prev) => ({ ...prev, [campo]: valor }));

                const result = await consultaDatalistProduto(dados.empresaId, novoProduto.tabelaPrecoId, value);
                setOptionsProdutos(result);

                const produtoId = document.querySelector(`datalist option[value="${value}"]`)?.dataset.id;

                if (produtoId) {
                    const tabelaPrecoItem = await MovimentoAPI.retornaTabelaPrecoItem(novoProduto.tabelaPrecoId, produtoId);

                    consultaCondicaoVenda(dados.empresaId, novoProduto.tabelaPrecoId, produtoId, dados.pessoaId);
                    //consultaEmbalagemByProdutoId(dados.empresaId, produtoId);

                    //obter a Embalagem e o Fator
                    if (operacao.sigla === "LIM") {
                        setNovoProduto((prev) => ({
                            ...prev,
                            produtoId: produtoId,
                            Produto: tabelaPrecoItem.Produto,
                            Embalagem: tabelaPrecoItem.Embalagem,
                            Empresa: tabelaPrecoItem.Empresa,
                            movimentoItems: tabelaPrecoItem,
                            embalagemId: tabelaPrecoItem.embalagemId,
                            fator: tabelaPrecoItem.fator,
                            empresaId: dados.empresaId,
                        }));
                    } else {
                        setNovoProduto((prev) => ({
                            ...prev,
                            produtoId: produtoId,
                            Produto: Produto,
                            movimentoItems: tabelaPrecoItem,
                        }));
                    }
                }
            } else if (campo === "valorUnitario" || campo === "valorTotal" || campo === "quantidade") {
                const valorFormatado = formatarNumeroDecimal(value);

                if (campo === "valorUnitario") {
                    const valorTotal = (novoProduto.quantidade * valorFormatado).toFixed(2);
                    novoProduto.valorTotal = valorTotal;
                } else if (campo === "quantidade") {
                    const valorTotal = (valorFormatado * novoProduto.valorUnitario).toFixed(2);
                    novoProduto.valorTotal = valorTotal;
                } else if (campo === "valorTotal") {
                    const valorUnitario = (valorFormatado / novoProduto.quantidade).toFixed(2);
                    novoProduto.valorUnitario = valorUnitario;
                }

                setNovoProduto((prev) => ({ ...prev, [campo]: valorFormatado }));
            } else {
                setNovoProduto((prev) => ({ ...prev, [campo]: valor }));
            }
        } else if (name.startsWith("novoAditivo.")) {
            const camposNumerico = ["percentual", "quantidade", "valorReajustado"];
            // Desestruturar o campo
            const campo = name.split(".")[1];

            // Verifica se é um checkbox e define o valor correspondente
            let valor = type === "checkbox" ? checked : value;

            if (operacao.sigla === "LIM") {
                // Força o valorAnterior no contrato
                novoAditivo.valorAnterior = itemAditivos[0]?.valorReajustado || itens[0]?.valorUnitario;
                novoAditivo.valorReajustado = itemAditivos[0]?.valorReajustado || itens[0]?.valorUnitario;

                //caso PRORROGAR a data não pode ser menor que a saida
                if (campo === "tipo" && valor === "Prorrogar") {
                    novoAditivo.data = moment(dados.saida).startOf("day").format("YYYY-MM-DD 00:00:00");
                } else if (campo === "tipo" && valor === "Reajuste") {
                    novoAditivo.data = moment().startOf("day").format("YYYY-MM-DD 00:00:00");
                    novoAditivo.quantidade = null;
                }
            }

            // verifica se é um campo numerico para formatar
            if (camposNumerico.includes(campo)) {
                const valorFormatado = formatarNumeroDecimal(valor);

                //calculo do reajuste
                if (campo === "percentual") {
                    const percentual = formatarNumeroDecimal(valor) / 100 + 1;
                    const val = itens[0]?.valorUnitario ? percentual * itens[0]?.valorUnitario : null;
                    setNovoAditivo((prev) => ({ ...prev, valorReajustado: parseFloat(val).toFixed(2) }));
                }

                if (campo === "valorReajustado") {
                    const valorReajustado = formatarNumeroDecimal(valor);
                    const val = itens[0]?.valorUnitario ? (valorReajustado / itens[0]?.valorUnitario - 1) * 100 : null;
                    setNovoAditivo((prev) => ({ ...prev, percentual: parseFloat(val).toFixed(2) }));
                }

                setNovoAditivo((prev) => ({ ...prev, [campo]: valorFormatado, valorAnterior: itens[0]?.valorUnitario }));
            } else {
                setNovoAditivo((prev) => ({ ...prev, [campo]: valor }));
            }
        } else if (name === "situacaoId") {
            if (operacao.sigla === "LIM") {
                setDados({ ...dados, [name]: value, provisao: value === "3" ? true : false });
            } else {
                setDados({ ...dados, [name]: value });
            }
        } else {
            setDados({ ...dados, [name]: value });
        }
    }
    async function handleOnBlur(e) {
        const { name, value, checked, type } = e.target;
        const camposMonetarios = ["valorComissao", "percentualComissao", "caucao", "multa", "juros"];

        if (name.startsWith("Colaborador")) {
            if (!dados.colaboradorId) {
                const arr = value.split(") ");
                if (arr[1]) {
                    const result = await consultaDatalist(
                        //token,
                        dados.empresaId,
                        "Pessoa",
                        "id",
                        "descricao",
                        tipo === "Entrada" ? "compras" : "vendas",
                        arr[1]
                    );
                    setOptionsColaborador(result);
                }
                const selectedId = document.querySelector(`datalist option[value="${value}"]`)?.dataset.id;
                setDados({
                    ...dados,
                    colaboradorId: selectedId ?? null,
                });
            }
        } else if (name.startsWith("Parceiro.descricao")) {
            if (!isNaN(parseInt(dados.parceiroId))) {
                await atualizaDadosPorParceiro(dados.parceiroId);
            }
        } else if (camposMonetarios.includes(name)) {
            //verifica se comissao ou caucao foi alterado
            if (operacao.sigla === "LIM") {
                if (name === "valorComissao") {
                    atualizarValorPorCampoFacilitar("comissao", value);
                } else if (name === "caucao") {
                    atualizarValorPorCampoFacilitar("caucao", value);
                }
            }
        }
    }
    function handleOnFocus(e) {
        const { name, value, checked, type } = e.target;
        if (name === "Parceiro.descricao") {
            //envia a sigla da operacao para obter o contador da operacao
            dados.operacaoSigla = operacao.sigla.toLowerCase();

            if (!dados.situacaoId) {
                const selectElement = document.getElementById("situacaoId");
                dados.situacaoId = selectElement.value;
            }
            if (operacao.sigla === "LIM") {
                if (!dados.diaVencimento) {
                    const selectElement = document.getElementById("diaVencimento");
                    dados.diaVencimento = selectElement.value;
                }
                if (!dados.condominio) {
                    const selectElement = document.getElementById("condominio");
                    dados.condominio = selectElement.value;
                }
                if (!dados.iptu) {
                    const selectElement = document.getElementById("iptu");
                    dados.iptu = selectElement.value;
                }
                if (!dados.contrato) {
                    const selectElement = document.getElementById("contrato");
                    dados.contrato = selectElement.value;
                }
                if (!dados.locacao) {
                    const selectElement = document.getElementById("locacao");
                    dados.locacao = selectElement.value;
                }
            }
        }
    }

    const submit = (e) => {
        e.preventDefault();
        let mensagem = "Movimento precisa de um item incluso!"; //consulta produto => required:true

        //particularidades por operacao
        if (operacao.sigla === "LIM") {
            //verifica se a situação é valida
            if (dados.verificarSituacao && dados.verificarSituacao !== dados.situacaoId) {
                setFlashMessage("Contrato Assinado só pode ser Cancelado ou Devolvido!", "warning");
                return;
            }

            if (!dados.movimentoItems) {
                mensagem = "Não existe imóvel no contrato!";
            }
        }

        if (!dados.movimentoItems) {
            setFlashMessage(mensagem, "warning");
            return;
        }

        handleSubmit(dados);
    };

    /* código da grid */
    /* caso inclua colunas nas grids deve alterar o controller na variavel colunasPermitidas */
    const columnsFinanceiro = useMemo(
        () => [
            {
                id: "financeiro",
                header: "Lançamentos em aberto",
                columns: [
                    {
                        accessorKey: "parcelas",
                        header: "Parcelas",
                        muiTableBodyCellProps: {
                            align: "center",
                        },
                    },
                    {
                        accessorFn: (row) => new Date(row),
                        accessorKey: "dataVencimento",
                        header: "Vencimento",
                        Header: ({ column }) => <em>{column.columnDef.header}</em>,
                        muiFilterTextFieldProps: {
                            sx: {
                                minWidth: "350px",
                            },
                        },
                        filterVariant: "date",
                        sortingFn: "date",
                        columnFilterModeOptions: [
                            "fuzzy",
                            "equals",
                            "between",
                            "betweenInclusive",
                            "greaterThanOrEqualTo",
                            "lessThanOrEqualTo",
                        ],
                        muiTableBodyCellProps: ({ row }) => ({
                            align: "center",
                            onClick: () => {},
                        }),
                        muiTableHeadCellFilterTextFieldProps: {
                            type: "date",
                            InputLabelProps: {
                                shrink: true,
                            },
                            sx: {
                                minWidth: "200px",
                            },
                        },
                        Cell: ({ renderedCellValue, row }) => {
                            return (
                                <span>
                                    {row.original.dataVencimento
                                        ? moment.utc(row.original.dataVencimento).format("DD/MM/YYYY")
                                        : null}
                                </span>
                            );
                        },
                    },
                    {
                        accessorKey: "valor",
                        header: "Valor",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valor === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.valor)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "valorBaixado",
                        header: "Baixado",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valorBaixado === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.valorBaixado)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "valorAberto",
                        header: "Aberto",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valorAberto === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.valorAberto)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorFn: (row) => new Date(row),
                        accessorKey: "dataLancamento",
                        header: "Data",
                        Header: ({ column }) => <em>{column.columnDef.header}</em>,
                        muiFilterTextFieldProps: {
                            sx: {
                                minWidth: "350px",
                            },
                        },
                        filterVariant: "date",
                        sortingFn: "date",
                        columnFilterModeOptions: [
                            "fuzzy",
                            "equals",
                            "between",
                            "betweenInclusive",
                            "greaterThanOrEqualTo",
                            "lessThanOrEqualTo",
                        ],
                        muiTableBodyCellProps: ({ row }) => ({
                            align: "center",
                            onClick: () => {},
                        }),
                        muiTableHeadCellFilterTextFieldProps: {
                            type: "date",
                            InputLabelProps: {
                                shrink: true,
                            },
                            sx: {
                                minWidth: "200px",
                            },
                        },
                        Cell: ({ renderedCellValue, row }) => {
                            return (
                                <span>
                                    {row.original.dataLancamento
                                        ? moment.utc(row.original.dataLancamento).format("DD/MM/YYYY")
                                        : null}
                                </span>
                            );
                        },
                    },
                    {
                        accessorKey: "valorOriginal",
                        header: "Valor Total",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valorOriginal === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.valorOriginal)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "Empresa.nomeFantasia",
                        id: "Empresa.nomeFantasia",
                        header: "Empresa",
                        //you can access a cell in many callback column definition options like this
                        muiTableBodyCellProps: ({ cell }) => ({
                            onClick: () => {},
                        }),
                    },
                    {
                        accessorKey: "documento",
                        header: "Documento",
                        muiTableBodyCellProps: {
                            align: "center",
                        },
                    },
                ],
            },

            //column definitions...
        ],
        [] //getCommonEditTextFieldProps
    );
    const columns = useMemo(
        () => [
            {
                id: "produto",
                header: textoHeaderGrid,
                columns: [
                    {
                        accessorKey: "Produto.descricao",
                        id: "Produto.descricao",
                        header: "Descrição",
                        //you can access a cell in many callback column definition options like this
                        muiTableBodyCellProps: ({ cell }) => ({
                            onClick: () => {},
                        }),
                    },
                    {
                        accessorKey: "valorUnitario",
                        header: "Aluguel",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valorUnitario === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.valorUnitario)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "quantidade",
                        header: "Meses",
                        muiTableBodyCellProps: {
                            align: "center",
                        },
                    },
                    {
                        accessorKey: "valorTotal",
                        header: "Valor Total",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valorTotal === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.valorTotal)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                ],
            },

            //column definitions...
        ],
        [textoHeaderGrid] //getCommonEditTextFieldProps
    );
    const columnsLancamento = useMemo(
        () => [
            {
                id: "lancamento",
                header: "Lançamentos da Locação de Imóveis",
                columns: [
                    {
                        accessorKey: "parcelas",
                        header: "Parcelas",
                        muiTableBodyCellProps: {
                            align: "center",
                        },
                    },
                    {
                        accessorFn: (row) => new Date(row),
                        accessorKey: "dataVencimento",
                        header: "Vencimento",
                        Header: ({ column }) => <em>{column.columnDef.header}</em>,
                        muiFilterTextFieldProps: {
                            sx: {
                                minWidth: "350px",
                            },
                        },
                        filterVariant: "date",
                        sortingFn: "date",
                        columnFilterModeOptions: [
                            "fuzzy",
                            "equals",
                            "between",
                            "betweenInclusive",
                            "greaterThanOrEqualTo",
                            "lessThanOrEqualTo",
                        ],
                        muiTableBodyCellProps: ({ row }) => ({
                            align: "center",
                            onClick: () => {},
                        }),
                        muiTableHeadCellFilterTextFieldProps: {
                            type: "date",
                            InputLabelProps: {
                                shrink: true,
                            },
                            sx: {
                                minWidth: "200px",
                            },
                        },
                        Cell: ({ renderedCellValue, row }) => {
                            return (
                                <span>
                                    {row.original.dataVencimento
                                        ? moment.utc(row.original.dataVencimento).format("DD/MM/YYYY")
                                        : null}
                                </span>
                            );
                        },
                    },
                    {
                        accessorKey: "valor",
                        header: "Aluguel",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valor === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.valor)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "valorBaixado",
                        header: "Pago",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            !row.valorBaixado
                                ? null
                                : "R$ " +
                                  parseFloat(row.valorBaixado)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "valorAberto",
                        header: "Aberto",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valor === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.valorAberto)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "tipo",
                        header: "Tipo",
                        muiTableBodyCellProps: {
                            align: "center",
                        },
                    },
                    {
                        accessorKey: "documento",
                        id: "documento",
                        header: "Documento",
                        //you can access a cell in many callback column definition options like this
                        muiTableBodyCellProps: ({ cell }) => ({
                            onClick: () => {},
                        }),
                    },
                    {
                        accessorKey: "historico",
                        header: "Historico",
                    },
                ],
            },

            //column definitions...
        ],
        [] //getCommonEditTextFieldProps
    );
    const columnsAditivo = useMemo(
        () => [
            {
                id: "aditivo",
                header: textoHeaderGrid,
                columns: [
                    {
                        accessorKey: "tipo",
                        id: "tipo",
                        header: "Tipo",
                        //you can access a cell in many callback column definition options like this
                        muiTableBodyCellProps: ({ cell, row }) => ({
                            onClick: () => {},
                        }),
                    },
                    {
                        accessorFn: (row) => new Date(row),
                        accessorKey: "data",
                        header: "Data",
                        Header: ({ column }) => <em>{column.columnDef.header}</em>,
                        muiFilterTextFieldProps: {
                            sx: {
                                minWidth: "350px",
                            },
                        },
                        filterVariant: "date",
                        sortingFn: "date",
                        columnFilterModeOptions: [
                            "fuzzy",
                            "equals",
                            "between",
                            "betweenInclusive",
                            "greaterThanOrEqualTo",
                            "lessThanOrEqualTo",
                        ],
                        muiTableBodyCellProps: ({ row }) => ({
                            align: "center",
                            onClick: () => {},
                        }),
                        muiTableHeadCellFilterTextFieldProps: {
                            type: "date",
                            InputLabelProps: {
                                shrink: false,
                            },
                            sx: {
                                minWidth: "200px",
                            },
                        },
                        Cell: ({ renderedCellValue, row }) => {
                            return (
                                <span>
                                    {row.original.data ? moment.utc(row.original.data).startOf("day").format("DD/MM/YYYY") : null}
                                </span>
                            );
                        },
                    },
                    {
                        accessorKey: "valorAnterior",
                        header: "Valor Anterior",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valorAnterior === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.valorAnterior)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "percentual",
                        header: "Percentual",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.percentual === null
                                ? ""
                                : parseFloat(row.percentual)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }) + " %",
                    },
                    {
                        accessorKey: "valorReajustado",
                        header: "Aluguel",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valorReajustado === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.valorReajustado)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "quantidade",
                        header: "Meses",
                        muiTableBodyCellProps: {
                            align: "center",
                        },
                    },
                ],
            },

            //column definitions...
        ],
        [textoHeaderGrid] //getCommonEditTextFieldProps
    );

    /* modo tema */
    const theme = tableThemeCustom();

    return (
        <div>
            <Tabs selectedIndex={abaAtiva} onSelect={handleSelect}>
                <form onSubmit={submit}>
                    <h1>{operacao.descricao + " - " + operacao.sigla}</h1>
                    <TabList>
                        <Tab disabled={!tabEnabled.cabeçalho} title={"Cabeçalho do Movimento"}>
                            Cabeçalho
                        </Tab>
                        <Tab disabled={!tabEnabled.financeiro} title={"Informações financeiras do locador"}>
                            Financeiro
                        </Tab>
                        <Tab disabled={!tabEnabled.itens} title={"Imóvel locado"}>
                            Imóvel
                        </Tab>
                        <Tab disabled={!tabEnabled.lancamento} title={"Parcelas geradas"}>
                            Lançamentos
                        </Tab>
                        <Tab disabled={!tabEnabled.observacao} title={"Observações do imóvel locado"}>
                            Observações
                        </Tab>
                        <Tab disabled={!tabEnabled.aditivo} title={"Termos aditivos a contratos"}>
                            Aditivo
                        </Tab>
                    </TabList>
                    <TabPanel>
                        <div>
                            <Input
                                text="Data Cadastro"
                                type="datetime-local"
                                name="createdAt"
                                placeholder="última atualização"
                                title="Data de inserção do cadastro no sistema"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.createdAt && retornaDataHora(dados.createdAt)}
                                readonly={true}
                                inputWidth={"150px"}
                            />
                            <Input
                                text="Data Atualização"
                                type="datetime-local"
                                name="updatedAt"
                                placeholder="última atualização"
                                title="Data da última atualização dos dados"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.updatedAt && retornaDataHora(dados.updatedAt)}
                                readonly={true}
                                inputWidth={"150px"}
                            />
                            <Input
                                text="Data"
                                type="date"
                                name="data"
                                placeholder="Data"
                                title="Data do movimento"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.data && retornaData(dados.data)}
                            />

                            {btnText !== "Cadastrar" && (
                                <>
                                    <Input
                                        text="Número"
                                        type="text"
                                        name="numero"
                                        inputWidth="100px"
                                        placeholder="Número"
                                        title={`Número do ${operacao.descricao}`}
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={dados.numero || ""}
                                        required={false}
                                        readonly={true}
                                        textAlign="center"
                                    />
                                    <Checkbox
                                        title="Contrato assinado o movimento não poderá ser alterado"
                                        text="Assinado"
                                        name="assinado"
                                        type="checkbox"
                                        handleOnChange={handleChange}
                                        value={dados.assinado}
                                        disabled={true}
                                        checked={dados.assinado === true ? "checked" : ""}
                                    />
                                </>
                            )}
                            <Select
                                name="situacaoId"
                                title="Define a situação do movimento"
                                text="Situação"
                                inputWidth="170px"
                                options={OptionsSituacao}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.situacaoId}
                                isEnum={false}
                                notOptNull={true}
                            />
                            <Checkbox
                                title="Provisão gera o lançamento financeiro provisionado"
                                text="Provisão"
                                name="provisao"
                                type="checkbox"
                                handleOnChange={handleChange}
                                value={dados.provisao}
                                checked={dados.provisao === true ? "checked" : ""}
                            />
                            <Select
                                name="centroCustoId"
                                title={`Define o centro de custo do ${operacao.descricao}`}
                                text="Centro de Custo"
                                inputWidth="200px"
                                options={OptionsCC}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.centroCustoId}
                                isEnum={false}
                                notOptNull={false}
                                disabled={true}
                            />
                        </div>
                        <div>
                            <DataList
                                text="Administrador"
                                name="Administrador.descricao"
                                placeholder="Pesquisa administrador"
                                inputWidth="400px"
                                filteredOptions={OptionsAdministrador}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={Administrador?.descricao || ""}
                                required={false}
                            />
                            <DataList
                                text={colaborador}
                                name="Colaborador.descricao"
                                placeholder={`Pesquise o ${colaborador}`}
                                inputWidth="250px"
                                filteredOptions={OptionsColaborador}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={Colaborador?.descricao || ""}
                                required={true}
                                readonly={
                                    !(
                                        parametroPessoa.pessoaColaborador?.gerenteCompras ||
                                        parametroPessoa.pessoaColaborador?.gerenteVendas ||
                                        parametroPessoa.pessoaColaborador?.supervisorCompras ||
                                        parametroPessoa.pessoaColaborador?.supervisorVendas
                                    )
                                }
                            />
                            <InputNumber
                                text={"Comissão R$"}
                                type="text"
                                name="valorComissao"
                                placeholder="Valor R$"
                                inputWidth="100px"
                                title="digite o valor da comissão do contrato para o corretor em R$ (reais), será gerado apenas uma parcela de pagamento da comissão"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.valorComissao ? formatarNumero(dados.valorComissao) : ""}
                            />
                            <Checkbox
                                title="Gera comissão mensal para o corretor, precisa que o imóvel esteja com o percentual da comissão preenchido."
                                text="Comissão"
                                name="geraComissao"
                                type="checkbox"
                                handleOnChange={handleChange}
                                value={dados.geraComissao}
                                //checked={dados.geraComissao === true ? "checked" : ""}
                                checked={dados.valorComissao > 0 ? false : dados.geraComissao === true ? "checked" : ""}
                                disabled={dados.valorComissao > 0 ? true : false}
                            />
                            {/* <InputNumber
                                        text={"Comissão %"}
                                        type="text"
                                        name="percentualComissao"
                                        placeholder="Valor %"
                                        inputWidth="100px"
                                        title="digite o percentual da comissão do contrato para o corretor sobre o aluguel"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={dados.percentualComissao ? formatarNumero(dados.percentualComissao) : ""}
                                        readonly={!dados.geraComissao}
                                    /> */}
                        </div>
                        <div>
                            <DataList
                                text={operacao.sigla === "LIM" ? parceiroTipo : "Parceiro"}
                                name="Parceiro.descricao"
                                placeholder={dados.colaboradorId > 0 ? `Pesquise o ${parceiroTipo}` : `Escolha um ${colaborador}`}
                                inputWidth="400px"
                                filteredOptions={OptionsParceiro}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={Parceiro?.descricao || ""}
                                required={true}
                                //readonly={parseInt(dados.colaboradorId) > 0 ? false : true}
                            />
                            <Select
                                name="formaId"
                                title={`Define a forma do ${operacao.descricao}`}
                                text="Forma"
                                inputWidth="170px"
                                options={OptionsForma}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.formaId}
                                isEnum={false}
                                notOptNull={false}
                            />
                            <Select
                                name="prazoId"
                                title={`Define o prazo do ${operacao.descricao}`}
                                text="Prazo"
                                inputWidth="130px"
                                options={OptionsPrazo}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.prazoId}
                                isEnum={false}
                                notOptNull={false}
                            />
                            <Select
                                name="contaId"
                                title={`Define o conta para`}
                                text="Conta"
                                inputWidth="130px"
                                options={OptionsConta}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.contaId}
                                isEnum={false}
                                notOptNull={false}
                            />
                        </div>
                        <div>
                            <DataList
                                text={"Fiador"}
                                name="ParceiroVinculado.descricao"
                                placeholder={"Escolha um Fiador"}
                                inputWidth="400px"
                                filteredOptions={OptionsParceiroVinculado}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={ParceiroVinculado?.descricao || ""}
                                required={false}
                            />
                            <InputNumber
                                text={"Caução"}
                                type="text"
                                name="caucao"
                                placeholder="Valor R$"
                                inputWidth="100px"
                                title="digite o valor da caução em R$ (reais)"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.caucao ? formatarNumero(dados.caucao) : ""}
                            />
                            <InputNumber
                                text={"Multa"}
                                type="text"
                                name="multa"
                                placeholder="Multa %"
                                inputWidth="60px"
                                title="digite o valor da multa em percentual"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.multa ? formatarNumero(dados.multa) : ""}
                            />
                            <InputNumber
                                text={"Juros"}
                                type="text"
                                name="juros"
                                placeholder="Juros %"
                                inputWidth="60px"
                                title="digite o valor do juros em percentual"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.juros ? formatarNumero(dados.juros) : ""}
                            />
                        </div>
                        <div>
                            <Select
                                name="diaVencimento"
                                title="Dia de vencimento do aluguel"
                                text="Vencimento"
                                inputWidth="90px"
                                options={enumDiaVencimento}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.diaVencimento}
                                isEnum={true}
                                notOptNull={false}
                            />
                            <Select
                                name="condominio"
                                title="Responsabilidade do condomínio"
                                text="Condomínio"
                                inputWidth="120px"
                                options={enumCondominio}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.condominio}
                                isEnum={true}
                                notOptNull={false}
                            />
                            <Select
                                name="iptu"
                                title="Responsabilidade do IPTU"
                                text="IPTU"
                                inputWidth="120px"
                                options={enumIPTU}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.iptu}
                                isEnum={true}
                                notOptNull={false}
                            />
                            <Select
                                name="contrato"
                                title="Contrato impresso em nome da pessoa escolhida"
                                text="Contrato"
                                inputWidth="120px"
                                options={enumContrato}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.contrato}
                                isEnum={true}
                                notOptNull={false}
                            />
                            <Select
                                name="energia"
                                title="Tranferi a conta de energia"
                                text="Energia"
                                inputWidth="120px"
                                options={enumEnergia}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.energia}
                                isEnum={true}
                                notOptNull={false}
                            />
                            <Select
                                name="agua"
                                title="Tranferi a conta de água"
                                text="Água"
                                inputWidth="120px"
                                options={enumAgua}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.agua}
                                isEnum={true}
                                notOptNull={false}
                            />
                            <Checkbox
                                title="O imóvel usa energia solar"
                                text="Solar"
                                name="solar"
                                type="checkbox"
                                handleOnChange={handleChange}
                                value={dados.solar}
                                checked={dados.solar === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Imóvel mobiliado, verifiar inventário"
                                text="Mobiliado"
                                name="mobiliado"
                                type="checkbox"
                                handleOnChange={handleChange}
                                value={dados.mobiliado}
                                checked={dados.mobiliado === true ? "checked" : ""}
                            />
                        </div>
                        <div>
                            <Select
                                name="locacao"
                                title="Tipo de locação do imóvel"
                                text="Locação"
                                inputWidth="120px"
                                options={enumLocacao}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.locacao}
                                isEnum={true}
                                notOptNull={false}
                            />
                            <Input
                                text={"Evento"}
                                type="text"
                                name="evento"
                                placeholder="Evento"
                                inputWidth="400px"
                                title="Caso a locação seja para um evnto digitar a descrição do evento"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.evento}
                                readonly={dados.locacao !== "Eventos" ? true : false}
                            />
                            <Input
                                text="Data Entrada"
                                type="datetime-local"
                                name="entrada"
                                placeholder="Entrada"
                                title="Data de liberação do imóvel ao locador"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.entrada}
                                readonly={false}
                                inputWidth={"150px"}
                            />
                            <Input
                                text="Data Saída"
                                type="datetime-local"
                                name="saida"
                                placeholder="Saída"
                                title="Data de devolução do imóvel ao locatário"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.saida}
                                readonly={false}
                                inputWidth={"150px"}
                            />
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className={formStyles.stiloPadrao}>
                            <div>
                                <Input
                                    text="Total Aberto"
                                    name="totalAberto"
                                    type="text"
                                    title="Valor total dos lançamentos em aberto"
                                    value={lancamentos[0]?.totalAberto ? maskDecimal(lancamentos[0]?.totalAberto) : "0,00"}
                                    readonly={true}
                                    inputWidth={"150px"}
                                />
                            </div>
                            <div>
                                <ThemeProvider theme={theme}>
                                    <MaterialReactTable
                                        displayColumnDefOptions={{
                                            "mrt-row-actions": {
                                                muiTableHeadCellProps: {
                                                    align: "center",
                                                },
                                                size: 100,
                                            },
                                            "mrt-row-numbers": {
                                                enableColumnOrdering: true, //turn on some features that are usually off
                                                enableResizing: true,
                                                muiTableHeadCellProps: {
                                                    sx: {
                                                        fontSize: "1.2rem",
                                                    },
                                                },
                                            },
                                            "mrt-row-select": {
                                                enableColumnActions: true,
                                                enableHiding: true,
                                                size: 50,
                                            },
                                        }}
                                        enableColumnFilterModes
                                        enableGlobalFilterModes
                                        //enableColumnResizing
                                        enableColumnOrdering
                                        enableEditing
                                        renderRowActions={({ row, table }) => (
                                            <Box sx={{ display: "flex", gap: "1rem" }}>
                                                <Tooltip arrow placement="left" title="Editar">
                                                    <span>
                                                        {" "}
                                                        <IconButton
                                                            onClick={() => {
                                                                // handleEditar(row.original, row.index);
                                                            }}
                                                            disabled={localStorage.getItem("editar") === "true" ? false : true}
                                                        >
                                                            <Edit />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                                <Tooltip arrow placement="right" title="Deletar">
                                                    <span>
                                                        <IconButton
                                                            color="error"
                                                            //onClick={() => handleOpenDialog(row.id)}
                                                            //onClick={() => handleDelete(row.original, row.id)}
                                                            disabled={localStorage.getItem("cancelar") === "true" ? false : true}
                                                        >
                                                            <Delete />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                            </Box>
                                        )}
                                        renderTopToolbarCustomActions={() => (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "8px",
                                                }}
                                            >
                                                <Tooltip arrow placement="top-end" title={`Novo Registro`}>
                                                    <div>
                                                        {" "}
                                                        <Button
                                                            color="secondary"
                                                            onClick={() => navigate("/TabelaPrecos/CreateOrEdit")}
                                                            variant="contained"
                                                            disabled={
                                                                true
                                                                // localStorage.getItem("incluir") ===
                                                                // "true"
                                                                //     ? false
                                                                //     : true
                                                            }
                                                        >
                                                            Novo
                                                        </Button>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        )}
                                        columns={columnsFinanceiro}
                                        data={lancamentos}
                                        enableRowSelection
                                        getRowId={(row) => row.uuid}
                                        manualFiltering
                                        manualPagination
                                        manualSorting
                                        muiToolbarAlertBannerProps={
                                            isError
                                                ? {
                                                      color: "error",
                                                      children: "Error loading data",
                                                  }
                                                : undefined
                                        }
                                        onColumnFiltersChange={setColumnFilters}
                                        onColumnFilterFnsChange={setColumnFiltersMode}
                                        onGlobalFilterChange={setGlobalFilter}
                                        onPaginationChange={setPagination}
                                        onSortingChange={setSorting}
                                        rowCount={rowCountFinanceiro}
                                        initialState={{
                                            showColumnFilters: true,
                                            density: "compact",
                                            //columnPinning: { left: ["mrt-row-select"] },
                                        }}
                                        state={{
                                            columnFilterFns,
                                            columnFilters,
                                            globalFilter,
                                            isLoading,
                                            pagination,
                                            showAlertBanner: isError,
                                            showProgressBars: isRefetching,
                                            sorting,
                                        }}
                                        //positionToolbarDropZone={"top"}
                                        positionExpandColumn={"first"}
                                        positionActionsColumn={"first"}
                                        positionToolbarAlertBanner="bottom"
                                        localization={MRT_Localization_PT_BR}
                                    />
                                </ThemeProvider>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div
                            style={{
                                margin: "1em",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "1rem",
                                    flexWrap: "wrap", // Permite que os itens quebrem a linha quando necessário
                                    //justifyContent: "space-between", // Distribui os itens igualmente
                                }}
                            >
                                <Select
                                    name="novoProduto.tabelaPrecoId"
                                    title={`escolha a tabela de preço disponível para operação: ${operacao.descricao}`}
                                    text="Tabela de Preço"
                                    inputWidth="200px"
                                    options={OptionsTabelaPreco}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoProduto.tabelaPrecoId}
                                    isEnum={false}
                                    notOptNull={false}
                                />
                                <DataList
                                    text="Imóvel"
                                    name="novoProduto.descricao"
                                    placeholder={"Pesquise o Produto"}
                                    inputWidth="400px"
                                    filteredOptions={OptionsProdutos}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoProduto?.descricao || ""}
                                    required={false}
                                    readonly={
                                        Boolean(!parseInt(novoProduto.tabelaPrecoId)) ||
                                        (dados.movimentoItems?.length > 0 &&
                                            novoProduto.funcao.toLocaleLowerCase() === "adicionar" &&
                                            operacao.sigla === "LIM")
                                    }
                                />
                                <InputNumber
                                    text={"Aluguel"}
                                    type="text"
                                    name="novoProduto.valorUnitario"
                                    placeholder="Valor R$"
                                    inputWidth="100px"
                                    title="digite o valor do aluguel"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoProduto.valorUnitario ? formatarNumero(novoProduto.valorUnitario) : ""}
                                />
                                <InputNumber
                                    text={"Meses"}
                                    type="text"
                                    name="novoProduto.quantidade"
                                    placeholder="Período em meses"
                                    inputWidth="60px"
                                    title="digite a quantidade de meses do contrato"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoProduto.quantidade ? formatarNumero(novoProduto.quantidade) : ""}
                                    readonly={true}
                                />
                                <InputNumber
                                    text={"Valor Total"}
                                    type="text"
                                    name="novoProduto.valorTotal"
                                    placeholder="Valor R$"
                                    inputWidth="100px"
                                    title="digite o valor da venda"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoProduto.valorTotal ? formatarNumero(novoProduto.valorTotal) : ""}
                                />
                                <Button
                                    variant="contained"
                                    color="inherit"
                                    onClick={() => {
                                        setNovoProduto({
                                            uuid: null,
                                            contaId: dados.id,
                                            tipo: null,
                                            descricao: null,
                                            principal: true,
                                            ativo: true,
                                            funcao: "Adicionar",
                                        });
                                    }}
                                    //style={{ marginLeft: "10px" }}
                                    style={{
                                        marginLeft: "10px",
                                        minWidth: "80px",
                                        fontSize: "14px",
                                        "@media (max-width: 600px)": {
                                            fontSize: "12px", // Ajusta o tamanho da fonte em telas menores
                                            padding: "8px 12px", // Ajusta o padding para manter o botão visível
                                        },
                                    }}
                                >
                                    Limpar
                                </Button>
                                <Button
                                    variant="contained"
                                    color={novoProduto.funcao.toLocaleLowerCase() === "deletar" ? "error" : "info"}
                                    onClick={() => {
                                        handleProduto(novoProduto);
                                    }}
                                    disabled={!novoProduto.descricao || dados.assinado ? true : false}
                                    //style={{ marginLeft: "5px" }}
                                    style={{
                                        marginLeft: "10px",
                                        minWidth: "80px",
                                        fontSize: "14px",
                                        "@media (max-width: 600px)": {
                                            fontSize: "12px", // Ajusta o tamanho da fonte em telas menores
                                            padding: "8px 12px", // Ajusta o padding para manter o botão visível
                                        },
                                    }}
                                >
                                    {novoProduto.funcao}
                                </Button>
                            </div>

                            <ThemeProvider theme={theme}>
                                <MaterialReactTable
                                    displayColumnDefOptions={{
                                        "mrt-row-actions": {
                                            muiTableHeadCellProps: {
                                                align: "center",
                                            },
                                            size: 100,
                                        },
                                        "mrt-row-numbers": {
                                            enableColumnOrdering: true, //turn on some features that are usually off
                                            enableResizing: true,
                                            muiTableHeadCellProps: {
                                                sx: {
                                                    fontSize: "1.2rem",
                                                },
                                            },
                                        },
                                        "mrt-row-select": {
                                            enableColumnActions: true,
                                            enableHiding: true,
                                            size: 50,
                                        },
                                    }}
                                    enableColumnFilterModes
                                    enableGlobalFilterModes
                                    //enableColumnResizing
                                    enableColumnOrdering
                                    enableEditing
                                    renderRowActions={({ row, table }) => (
                                        <Box sx={{ display: "flex", gap: "1rem" }}>
                                            <Tooltip arrow placement="left" title="Editar">
                                                <span>
                                                    {" "}
                                                    <IconButton
                                                        onClick={() => {
                                                            handleEditar(row.original, row.index);
                                                        }}
                                                        disabled={localStorage.getItem("editar") === "true" ? false : true}
                                                    >
                                                        <Edit />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                            <Tooltip arrow placement="right" title="Deletar">
                                                <span>
                                                    <IconButton
                                                        color="error"
                                                        //onClick={() => handleOpenDialog(row.id)}
                                                        onClick={() => handleDelete(row.original, row.id)}
                                                        disabled={localStorage.getItem("cancelar") === "true" ? false : true}
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </Box>
                                    )}
                                    renderTopToolbarCustomActions={() => (
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "8px",
                                            }}
                                        >
                                            <Tooltip arrow placement="top-end" title={`Novo Registro`}>
                                                <div>
                                                    {" "}
                                                    <Button
                                                        color="secondary"
                                                        onClick={() => navigate("/TabelaPrecos/CreateOrEdit")}
                                                        variant="contained"
                                                        disabled={
                                                            true
                                                            // localStorage.getItem("incluir") ===
                                                            // "true"
                                                            //     ? false
                                                            //     : true
                                                        }
                                                    >
                                                        Novo
                                                    </Button>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    )}
                                    columns={columns}
                                    data={itens}
                                    enableRowSelection
                                    getRowId={(row) => row.uuid}
                                    manualFiltering
                                    manualPagination
                                    manualSorting
                                    muiToolbarAlertBannerProps={
                                        isError
                                            ? {
                                                  color: "error",
                                                  children: "Error loading data",
                                              }
                                            : undefined
                                    }
                                    onColumnFiltersChange={setColumnFilters}
                                    onColumnFilterFnsChange={setColumnFiltersMode}
                                    onGlobalFilterChange={setGlobalFilter}
                                    onPaginationChange={setPagination}
                                    onSortingChange={setSorting}
                                    rowCount={rowCount}
                                    initialState={{
                                        showColumnFilters: true,
                                        density: "compact",
                                        //columnPinning: { left: ["mrt-row-select"] },
                                    }}
                                    state={{
                                        columnFilterFns,
                                        columnFilters,
                                        globalFilter,
                                        isLoading,
                                        pagination,
                                        showAlertBanner: isError,
                                        showProgressBars: isRefetching,
                                        sorting,
                                    }}
                                    //positionToolbarDropZone={"top"}
                                    positionExpandColumn={"first"}
                                    positionActionsColumn={"first"}
                                    positionToolbarAlertBanner="bottom"
                                    localization={MRT_Localization_PT_BR}
                                />
                            </ThemeProvider>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div
                            style={{
                                margin: "1em",
                            }}
                        >
                            <div>
                                <Input
                                    text="Total"
                                    name="totalValor"
                                    type="text"
                                    title="Valor total dos lançamentos gerados"
                                    value={dados.totalValor ? maskDecimal(dados.totalValor) : "0,00"}
                                    readonly={true}
                                    inputWidth={"150px"}
                                    textAlign="right"
                                />
                                <Input
                                    text="Número de Parcelas"
                                    name="totalParcela"
                                    type="text"
                                    title="número de parcelas geradas"
                                    value={dados.totalParcela || "0"}
                                    readonly={true}
                                    inputWidth={"150px"}
                                    textAlign="center"
                                />
                                <Input
                                    text="Valor Parcela"
                                    name="valorParcela"
                                    type="text"
                                    title="Valor das parcelas geradas"
                                    value={dados.valorParcela ? maskDecimal(dados.valorParcela) : "0,00"}
                                    readonly={true}
                                    inputWidth={"150px"}
                                    textAlign="right"
                                />
                            </div>
                            <ThemeProvider theme={theme}>
                                <MaterialReactTable
                                    displayColumnDefOptions={{
                                        "mrt-row-actions": {
                                            muiTableHeadCellProps: {
                                                align: "center",
                                            },
                                            size: 100,
                                        },
                                        "mrt-row-numbers": {
                                            enableColumnOrdering: true, //turn on some features that are usually off
                                            enableResizing: true,
                                            muiTableHeadCellProps: {
                                                sx: {
                                                    fontSize: "1.2rem",
                                                },
                                            },
                                        },
                                        "mrt-row-select": {
                                            enableColumnActions: true,
                                            enableHiding: true,
                                            size: 50,
                                        },
                                    }}
                                    enableColumnFilterModes
                                    enableGlobalFilterModes
                                    //enableColumnResizing
                                    enableColumnOrdering
                                    enableEditing
                                    renderRowActions={({ row, table }) => (
                                        <Box sx={{ display: "flex", gap: "1rem" }}>
                                            <Tooltip arrow placement="left" title="Editar">
                                                <span>
                                                    {" "}
                                                    <IconButton
                                                        onClick={() => {
                                                            handleEditar(row.original, row.index);
                                                        }}
                                                        disabled={localStorage.getItem("editar") === "true" ? false : true}
                                                    >
                                                        <Edit />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                            <Tooltip arrow placement="right" title="Deletar">
                                                <span>
                                                    <IconButton
                                                        color="error"
                                                        //onClick={() => handleOpenDialog(row.id)}
                                                        onClick={() => handleDelete(row.original, row.id)}
                                                        disabled={localStorage.getItem("cancelar") === "true" ? false : true}
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </Box>
                                    )}
                                    renderTopToolbarCustomActions={() => (
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "8px",
                                            }}
                                        >
                                            <Tooltip arrow placement="top-end" title={`Novo Registro`}>
                                                <div>
                                                    {" "}
                                                    <Button
                                                        color="secondary"
                                                        onClick={() => navigate("/TabelaPrecos/CreateOrEdit")}
                                                        variant="contained"
                                                        disabled={
                                                            true
                                                            // localStorage.getItem("incluir") ===
                                                            // "true"
                                                            //     ? false
                                                            //     : true
                                                        }
                                                    >
                                                        Novo
                                                    </Button>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    )}
                                    columns={columnsLancamento}
                                    data={itemLancamentos}
                                    enableRowSelection
                                    getRowId={(row) => row.uuid}
                                    manualFiltering
                                    manualPagination
                                    manualSorting
                                    muiToolbarAlertBannerProps={
                                        isError
                                            ? {
                                                  color: "error",
                                                  children: "Error loading data",
                                              }
                                            : undefined
                                    }
                                    onColumnFiltersChange={setColumnFilters}
                                    onColumnFilterFnsChange={setColumnFiltersMode}
                                    onGlobalFilterChange={setGlobalFilter}
                                    onPaginationChange={setPagination}
                                    onSortingChange={setSorting}
                                    rowCount={rowCountLancamento}
                                    initialState={{
                                        showColumnFilters: true,
                                        density: "compact",
                                        //columnPinning: { left: ["mrt-row-select"] },
                                    }}
                                    state={{
                                        columnFilterFns,
                                        columnFilters,
                                        globalFilter,
                                        isLoading,
                                        pagination,
                                        showAlertBanner: isError,
                                        showProgressBars: isRefetching,
                                        sorting,
                                    }}
                                    //positionToolbarDropZone={"top"}
                                    positionExpandColumn={"first"}
                                    positionActionsColumn={"first"}
                                    positionToolbarAlertBanner="bottom"
                                    localization={MRT_Localization_PT_BR}
                                />
                            </ThemeProvider>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div>
                            <TextArea
                                text="Vistória de entrada no imóvel"
                                type="text"
                                name="obsEntrada"
                                placeholder="Digite aqui as observações"
                                title="Digite as observações no momento da entrada do locatário no imóvel"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.obsEntrada || ""}
                                textAlign="left"
                                linhas="4"
                                colunas="120"
                            />
                        </div>
                        <div>
                            <Input
                                text="Data Devolução"
                                type="datetime-local"
                                name="dataSaida"
                                placeholder="Data"
                                title="Data de devolução do imóvel ao locador"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.dataSaida}
                                readonly={false}
                                inputWidth={"150px"}
                            />
                        </div>
                        <div>
                            <TextArea
                                text="Vistoria de devolução do imóvel"
                                type="text"
                                name="obsSaida"
                                placeholder="Digite aqui observações"
                                title="Digite as observações da devolução do imóvel ao locador"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.obsSaida || ""}
                                textAlign="left"
                                linhas="4"
                                colunas="120"
                            />
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div
                            style={{
                                margin: "1em",
                            }}
                        >
                            <div>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginTop: "1rem",
                                        flexWrap: "wrap", // Permite que os itens quebrem a linha quando necessário
                                    }}
                                >
                                    <Select
                                        name="novoAditivo.tipo"
                                        title={`escolha o tipo de termo aditivo ao contrato`}
                                        text="Tipo"
                                        inputWidth="150px"
                                        options={enumTipoAditivo}
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novoAditivo.tipo}
                                        isEnum={true}
                                        notOptNull={false}
                                    />
                                    <Input
                                        text="Data"
                                        type="date"
                                        name="novoAditivo.data"
                                        placeholder="Data"
                                        title="Data do termo aditivo"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novoAditivo.data && retornaData(novoAditivo.data)}
                                    />
                                    <InputNumber
                                        text={"Valor Atual"}
                                        type="text"
                                        name="novoAditivo.valorAnterior"
                                        inputWidth="100px"
                                        title="Valor atual do aluguel"
                                        readonly={true}
                                        value={novoAditivo.valorAnterior ? formatarNumero(novoAditivo.valorAnterior) : null}
                                    />
                                    <InputNumber
                                        text={"Reajuste"}
                                        type="text"
                                        name="novoAditivo.percentual"
                                        placeholder="Valor em %"
                                        inputWidth="100px"
                                        title="digite o percentual de reajuste do aluguel"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novoAditivo.percentual ? formatarNumero(novoAditivo.percentual) : ""}
                                    />
                                    <InputNumber
                                        text={"Aluguel"}
                                        type="text"
                                        name="novoAditivo.valorReajustado"
                                        placeholder="Valor R$"
                                        inputWidth="100px"
                                        title="digite o valor do aluguel"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novoAditivo.valorReajustado ? formatarNumero(novoAditivo.valorReajustado) : ""}
                                    />
                                    <InputNumber
                                        text={"Meses"}
                                        type="text"
                                        name="novoAditivo.quantidade"
                                        placeholder="Período em meses"
                                        inputWidth="100px"
                                        title="digite a quantidade de meses da prorrogação"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={novoAditivo.quantidade ? formatarNumero(novoAditivo.quantidade) : ""}
                                        readonly={novoAditivo.tipo === "Prorrogar" ? false : true}
                                    />
                                    <Button
                                        variant="contained"
                                        color="inherit"
                                        onClick={() => {
                                            limparAditivo();
                                        }}
                                        //style={{ marginLeft: "10px" }}
                                        style={{
                                            marginLeft: "10px",
                                            minWidth: "80px",
                                            fontSize: "14px",
                                            "@media (max-width: 600px)": {
                                                fontSize: "12px", // Ajusta o tamanho da fonte em telas menores
                                                padding: "8px 12px", // Ajusta o padding para manter o botão visível
                                            },
                                        }}
                                    >
                                        Limpar
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color={novoAditivo.funcao.toLocaleLowerCase() === "deletar" ? "error" : "info"}
                                        onClick={() => {
                                            handleAditivo(novoAditivo);
                                        }}
                                        disabled={novoAditivo.tipo ? false : true}
                                        //style={{ marginLeft: "5px" }}
                                        style={{
                                            marginLeft: "10px",
                                            minWidth: "80px",
                                            fontSize: "14px",
                                            "@media (max-width: 600px)": {
                                                fontSize: "12px", // Ajusta o tamanho da fonte em telas menores
                                                padding: "8px 12px", // Ajusta o padding para manter o botão visível
                                            },
                                        }}
                                    >
                                        {novoAditivo.funcao}
                                    </Button>
                                </div>
                            </div>
                            <ThemeProvider theme={theme}>
                                <MaterialReactTable
                                    displayColumnDefOptions={{
                                        "mrt-row-actions": {
                                            muiTableHeadCellProps: {
                                                align: "center",
                                            },
                                            size: 200,
                                        },
                                        "mrt-row-numbers": {
                                            enableColumnOrdering: true, //turn on some features that are usually off
                                            enableResizing: true,
                                            muiTableHeadCellProps: {
                                                sx: {
                                                    fontSize: "1.2rem",
                                                },
                                            },
                                        },
                                        "mrt-row-select": {
                                            enableColumnActions: true,
                                            enableHiding: true,
                                            size: 50,
                                        },
                                    }}
                                    enableColumnFilterModes
                                    enableGlobalFilterModes
                                    //enableColumnResizing
                                    enableColumnOrdering
                                    enableEditing
                                    renderRowActions={({ row, table }) => {
                                        const handleImprimir = async (documento, obj) => {
                                            const caminho = documento.Documento.caminho;
                                            const url = documento.Documento.funcao;

                                            const response = await relatorios(caminho, url, obj);

                                            // Cria um Blob a partir dos dados do arraybuffer
                                            const pdfBlob = new Blob([response.data], {
                                                type: "application/pdf",
                                            });

                                            // Cria um URL temporário para o Blob
                                            const pdfUrl = URL.createObjectURL(pdfBlob);

                                            // Use o URL do PDF para abrir em uma nova janela ou realizar outras ações
                                            window.open(pdfUrl);
                                        };
                                        return (
                                            <Box sx={{ display: "flex", gap: "1rem" }}>
                                                <Tooltip arrow placement="left" title="Editar">
                                                    <span>
                                                        <IconButton
                                                            onClick={() => {
                                                                handleAditivoEditar(row.original, row.index);
                                                            }}
                                                            disabled={localStorage.getItem("editar") === "true" ? false : true}
                                                        >
                                                            <Edit />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                                <Tooltip arrow placement="top" title="Deletar">
                                                    <span>
                                                        <IconButton
                                                            color="error"
                                                            onClick={() => handleAditivoDelete(row.original, row.id)}
                                                            disabled={
                                                                row.original.Lancamentos.some(
                                                                    (lancamento) => lancamento.Situacao.descricao === "Baixado"
                                                                ) || localStorage.getItem("cancelar") !== "true"
                                                            }
                                                        >
                                                            <Delete />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                                {operacaoParametrosDocumentos.map((documento, index) => (
                                                    <Tooltip
                                                        key={index}
                                                        arrow
                                                        placement="top-start"
                                                        title={`Imprimir ${documento.Documento.tipo}`}
                                                    >
                                                        <span>
                                                            <IconButton
                                                                onClick={() => handleImprimir(documento, row.original)}
                                                                disabled={localStorage.getItem("imprimir") !== "true"}
                                                            >
                                                                {documento.Documento.tipo === "aditivo" && <ReceiptLong />}
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                ))}
                                            </Box>
                                        );
                                    }}
                                    renderTopToolbarCustomActions={() => (
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "8px",
                                            }}
                                        >
                                            <Tooltip arrow placement="top-end" title={`Novo Registro`}>
                                                <div>
                                                    {" "}
                                                    <Button
                                                        color="secondary"
                                                        onClick={() => navigate("/TabelaPrecos/CreateOrEdit")}
                                                        variant="contained"
                                                        disabled={
                                                            true
                                                            // localStorage.getItem("incluir") ===
                                                            // "true"
                                                            //     ? false
                                                            //     : true
                                                        }
                                                    >
                                                        Novo
                                                    </Button>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    )}
                                    columns={columnsAditivo}
                                    data={itemAditivos}
                                    enableRowSelection
                                    getRowId={(row) => row.uuid}
                                    manualFiltering
                                    manualPagination
                                    manualSorting
                                    muiToolbarAlertBannerProps={
                                        isError
                                            ? {
                                                  color: "error",
                                                  children: "Error loading data",
                                              }
                                            : undefined
                                    }
                                    onColumnFiltersChange={setColumnFilters}
                                    onColumnFilterFnsChange={setColumnFiltersMode}
                                    onGlobalFilterChange={setGlobalFilter}
                                    onPaginationChange={setPagination}
                                    onSortingChange={setSorting}
                                    rowCount={rowCountAditivo}
                                    initialState={{
                                        showColumnFilters: true,
                                        density: "compact",
                                        //columnPinning: { left: ["mrt-row-select"] },
                                    }}
                                    state={{
                                        columnFilterFns,
                                        columnFilters,
                                        globalFilter,
                                        isLoading,
                                        pagination,
                                        showAlertBanner: isError,
                                        showProgressBars: isRefetching,
                                        sorting,
                                    }}
                                    //positionToolbarDropZone={"top"}
                                    positionExpandColumn={"first"}
                                    positionActionsColumn={"first"}
                                    positionToolbarAlertBanner="bottom"
                                    localization={MRT_Localization_PT_BR}
                                />
                            </ThemeProvider>
                        </div>
                    </TabPanel>

                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "1rem",
                            flexWrap: "wrap", // Permite que os itens quebrem a linha quando necessário
                            justifyContent: "space-between", // Distribui os itens igualmente
                        }}
                    >
                        <Stack direction="row" spacing={1}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() =>
                                    navigate(caminhoVoltar, {
                                        ul: ul,
                                        li: li,
                                        tipo: tipo,
                                        operacao: operacaoUUID,
                                    })
                                }
                                style={{
                                    marginLeft: "10px",
                                    minWidth: "120px",
                                    fontSize: "14px",
                                    "@media (max-width: 600px)": {
                                        fontSize: "12px", // Ajusta o tamanho da fonte em telas menores
                                        padding: "8px 12px", // Ajusta o padding para manter o botão visível
                                    },
                                }}
                            >
                                Voltar
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="success"
                                style={{
                                    marginLeft: "10px",
                                    minWidth: "120px",
                                    fontSize: "14px",
                                    "@media (max-width: 600px)": {
                                        fontSize: "12px", // Ajusta o tamanho da fonte em telas menores
                                        padding: "8px 12px", // Ajusta o padding para manter o botão visível
                                    },
                                }}
                                disabled={isDisabled}
                            >
                                {btnText}
                            </Button>
                        </Stack>
                    </div>
                </form>
            </Tabs>
        </div>
    );
}
export default Form;
