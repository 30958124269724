export const maskMoeda = (valor) => {
    const parsedValue = parseFloat(valor);
    return parsedValue.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
    });
};
export const maskDecimal = (valor) => {
    const parsedValue = parseFloat(valor);
    return parsedValue?.toLocaleString("pt-BR", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        minimumIntegerDigits: 1,
        useGrouping: true,
    });
};
export const formatCurrency = (value) => {
    return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
    }).format(value);
};
export const maskCNPJ = (str) => {
    let v = "";
    v = str.replace(/\D/g, "");

    if (v.length <= 11) {
        v = v.replace(/(\d{3})(\d)/, "$1.$2");
        v = v.replace(/(\d{3})(\d)/, "$1.$2");
        v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    } else {
        v = v.substring(0, 14); // limita em 14 números
        v = v.replace(/^(\d{2})(\d)/, "$1.$2");
        v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
        v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
        v = v.replace(/(\d{4})(\d)/, "$1-$2");
    }

    return v;
};
export const maskCEP = (str) => {
    let v = "";
    if (str) {
        v = str.replace(/\D/g, "");

        if (v.length) {
            v = v.substring(0, 8); // limita em 8 números
            v = v.replace(/^(\d{2})(\d)/, "$1.$2");
            v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2-$3");
        }
    }

    return v;
};
/**
 * Função para formatar o número decimal em tempo de digitação.
 * @param {string} numero - O número a ser formatado.
 * @returns {string} O número formatado.
 * @comentario Servi para mostrar ao usuário o número formatado
 */
export const formatarNumero = (valor) => {
    if (isNaN(valor)) {
        return;
    }

    const vr = valor?.toString().replace(/\D/g, ""); // Remove todos os caracteres não numéricos
    const tam = vr?.length;

    if (tam <= 2) {
        return vr;
    } else if (tam <= 5) {
        return `${vr.substr(0, tam - 2)},${vr.substr(tam - 2, tam)}`;
    } else if (tam <= 8) {
        return `${vr.substr(0, tam - 5)}.${vr.substr(tam - 5, 3)},${vr.substr(tam - 2, tam)}`;
    } else if (tam <= 11) {
        return `${vr.substr(0, tam - 8)}.${vr.substr(tam - 8, 3)}.${vr.substr(tam - 5, 3)},${vr.substr(tam - 2, tam)}`;
    } else if (tam <= 14) {
        return `${vr.substr(0, tam - 11)}.${vr.substr(tam - 11, 3)}.${vr.substr(tam - 8, 3)}.${vr.substr(tam - 5, 3)},${vr.substr(
            tam - 2,
            tam
        )}`;
    } else if (tam <= 17) {
        return `${vr.substr(0, tam - 14)}.${vr.substr(tam - 14, 3)}.${vr.substr(tam - 11, 3)}.${vr.substr(
            tam - 8,
            3
        )}.${vr.substr(tam - 5, 3)},${vr.substr(tam - 2, tam)}`;
    }

    return valor;
};
/**
 * Função para formatar o número decimal sem virgula e agrupamento de milhar.
 * @param {string} numero - O número a ser formatado.
 * @returns {string} O número formatado.
 * @comentario Servi para deixar o número em formato decimal
 */
export const formatarNumeroDecimal = (valor) => {
    const onlyNumbers = (str) => str.replace(/[^0-9]/g, "");
    const sequencia = onlyNumbers(valor);

    let parte1 = sequencia.slice(0, -2); // Obtém todos os caracteres, exceto os dois últimos
    let parte2 = sequencia.slice(-2); // Obtém os dois últimos caracteres
    const sequenciaComPonto = parte1 ? parte1 + "." + parte2 : parte2;

    return sequenciaComPonto;
};

export const formatarNumeroParaDecimal = (numeroStr) => {
    // Remove o ponto que separa os milhares
    let numeroSemMilhar = numeroStr.replace(/\./g, "");

    // Substitui a vírgula por um ponto para separar as casas decimais
    let numeroComPontoDecimal = numeroSemMilhar.replace(",", ".");

    // Retorna o valor como número em ponto flutuante
    return parseFloat(numeroComPontoDecimal);
};

/**
 * limita o texto recebendo o texto e o valor do limitador
 * @param {*} texto
 * @param {int} tamanho
 * @returns
 */
export const limitarTexto = (texto, tamanho) => {
    if (!tamanho) {
        tamanho = 15;
    }
    if (!texto) {
        return;
    }
    return texto.length > tamanho ? `${texto.substr(0, tamanho)}...` : texto;
};
const objetoDeFuncoes = {
    maskMoeda,
    maskDecimal,
    formatCurrency,
    maskCNPJ,
    maskCEP,
    formatarNumero,
    formatarNumeroParaDecimal,
};

export default objetoDeFuncoes;
