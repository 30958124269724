import { useState, useEffect } from "react";
import "react-tabs/style/react-tabs.css";
import "moment/locale/pt-br";

import { Stack, Button } from "@mui/material";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import * as TabelaAPI from "../../../api/tabela";

import Input from "../../form/Input";
import InputNumber from "../../form/InputNumber";
import Select from "../../form/Select";
import Checkbox from "../../form/Checkbox";
import SelectEmpresa from "../../form/SelectEmpresa";

import { formatarNumero, formatarNumeroDecimal } from "../../../utils/masks";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, OptionsEmpresa }) {
    const [centroCusto, setCentroCusto] = useState(formData || {});
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();
    //const [preview, setPreview] = useState([]);

    //para definir campos padrões
    if (centroCusto.empresaId === undefined) {
        centroCusto.empresaId = localStorage.getItem("empresaId");
        centroCusto.ativo = true;
    }

    //Enums
    const [EnumTipo, setEnumTipo] = useState([]);

    const consultaEnum = async (table, field, filter) => {
        const result = await TabelaAPI.enums(table, field, filter);
        if (table === "centroCustos") {
            field === "tipo" && setEnumTipo(result ? result : []);
        }
    };

    useEffect(() => {
        setCentroCusto(formData || {});

        //enum
        consultaEnum("centroCustos", "tipo", "");
    }, [formData]);

    async function handleChange(e) {
        if (
            e.target.name === "ativo" ||
            e.target.name === "combustivel" ||
            e.target.name === "manutencao" ||
            e.target.name === "industria" ||
            e.target.name === "servico"
        ) {
            setCentroCusto({
                ...centroCusto,
                [e.target.name]: e.target.checked,
            });
        } else if (e.target.name === "limitePadrao") {
            const valorFormatado = formatarNumeroDecimal(e.target.value);
            setCentroCusto({
                ...centroCusto,
                [e.target.name]: valorFormatado,
            });
        } else {
            setCentroCusto({ ...centroCusto, [e.target.name]: e.target.value });
        }
    }
    async function handleOnBlur(e) {
        //verifica duplicidade
        if (e.target.name === "descricao" && btnText === "Cadastrar") {
            //verifica se o centroCusto existe na base de dados
            const duplicidade = await TabelaAPI.duplicidadeDescricao(centroCusto.empresaId, "centroCusto", e.target.value);

            if (duplicidade?.uuid) {
                setFlashMessage("CentroCusto já cadastrado, você será redirecionado para o modo edição!", "success");
                return navigate(`/CentroCustos/CreateOrEdit`, {
                    uuid: duplicidade.uuid,
                });
            }
        }
    }
    function handleOnFocus(e) {}
    const submit = (e) => {
        e.preventDefault();
        if (!centroCusto.empresaId) {
            centroCusto.empresaId = parseInt(document.getElementById("empresaId").value);
        }

        handleSubmit(centroCusto);
    };

    return (
        <form onSubmit={submit}>
            <h1>Gestão de Centro de Custos</h1>
            <div>
                <SelectEmpresa
                    text="Selecione a Empresa"
                    name="empresaId"
                    options={OptionsEmpresa}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={centroCusto.empresaId || ""}
                    required
                    notOptNull={true}
                />
                <Input
                    text="CentroCusto"
                    type="text"
                    name="descricao"
                    placeholder="Nome do centroCusto"
                    title="Digite o nome do centroCusto"
                    inputWidth="350px"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={centroCusto.descricao || ""}
                    required
                />
            </div>
            <div>
                <Select
                    name="tipo"
                    title={"Define o tipo do centro de custo"}
                    text="Tipo"
                    options={EnumTipo}
                    handleOnChange={handleChange}
                    value={centroCusto.tipo}
                    isEnum={true}
                    notOptNull={true}
                />
                <InputNumber
                    text="Limite Padrão"
                    type="text"
                    name="limitePadrao"
                    placeholder="Limite"
                    inputWidth="100px"
                    title="digite o limite padrão para acompanhamento do centro de custo"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={centroCusto.limitePadrao ? formatarNumero(centroCusto.limitePadrao) : ""}
                    required={false}
                />
                <Checkbox
                    title="Vincula o centro de custo a frota, direcionando o lançamento ao abastecer"
                    text="Combustível"
                    name="combustivel"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={centroCusto.combustivel}
                    checked={centroCusto.combustivel === true ? "checked" : ""}
                />
                <Checkbox
                    title="Vincula o centro de custo a manutenção, utilizado quando o sistema funciona com o modulo de manutenção ativo"
                    text="Manutenção"
                    name="manutencao"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={centroCusto.manutencao}
                    checked={centroCusto.manutencao === true ? "checked" : ""}
                />
                <Checkbox
                    title="Vincula o centro de custo a industria, utilizado quando o sistema funciona com o modulo de indústria ativo"
                    text="Indústria"
                    name="industria"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={centroCusto.industria}
                    checked={centroCusto.industria === true ? "checked" : ""}
                />
                <Checkbox
                    title="Vincula o centro de custo a um serviço"
                    text="Serviço"
                    name="servico"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={centroCusto.servico}
                    checked={centroCusto.servico === true ? "checked" : ""}
                />
                <Checkbox
                    title="habilita o uso do centro de custo"
                    text="Ativo"
                    name="ativo"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={centroCusto.ativo}
                    checked={centroCusto.ativo === true ? "checked" : ""}
                />
            </div>
            <div>
                <Stack direction="row" spacing={1}>
                    <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                        Voltar
                    </Button>
                    <Button type="submit" variant="contained" color="success">
                        {btnText}
                    </Button>
                </Stack>
            </div>
        </form>
    );
}
export default Form;
