import { useState, useEffect } from "react";
import "react-tabs/style/react-tabs.css";
import "moment/locale/pt-br";

import { Stack, Button } from "@mui/material";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import * as TabelaAPI from "../../../api/tabela";

import Input from "../../form/Input";
import InputNumber from "../../form/InputNumber";
import Checkbox from "../../form/Checkbox";
import SelectEmpresa from "../../form/SelectEmpresa";
import Select from "../../form/Select";

/* utils */
import { formatarNumero, formatarNumeroDecimal } from "../../../utils/masks";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, OptionsEmpresa }) {
    const [dados, setDados] = useState(formData || {});
    const [OptionsCores, setOptionsCores] = useState([]);
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();

    //define o estado inicial do objeto
    if (!dados.empresaId) {
        dados.empresaId = localStorage.getItem("empresaId"); //parseInt(document.getElementById("empresaId").value);
    }

    if (!dados.ativo) {
        dados.ativo = true;
    }

    const consultaTabela = async (empresaId, tabela, order) => {
        const result = await TabelaAPI.tabela(empresaId, tabela, order);
        if (tabela === "Cor") {
            setOptionsCores(result);
        }
    };

    useEffect(() => {
        setDados(formData || {});

        consultaTabela(dados.empresaId, "Cor", "descricao");
    }, [formData]);

    function handleChange(e) {
        const { name, value, checked, type } = e.target;

        const camposBooleanos = ["ativo", "sanfonada", "transparente", "tratada"];
        const camposNumericos = ["comprimento", "diametro"];

        if (camposBooleanos.includes(name)) {
            setDados({ ...dados, [name]: checked });
        } else if (camposNumericos.includes(name)) {
            const valorFormatado = formatarNumeroDecimal(value);
            setDados({
                ...dados,
                [name]: valorFormatado,
            });
        } else {
            setDados({ ...dados, [name]: value });
        }
    }

    async function handleOnBlur(e) {
        const { name, value, checked, type } = e.target;

        //verifica duplicidade
        if (name === "descricao" && btnText === "Cadastrar") {
            //verifica se o centroCusto existe na base de dados
            const duplicidade = await TabelaAPI.duplicidadeDescricao(document.getElementById("empresaId").value, "Bobina", value);

            if (duplicidade?.uuid) {
                setFlashMessage("Registro em duplicidade!", "warning");
                return navigate(`/Bobinas/CreateOrEdit`, {
                    uuid: duplicidade.uuid,
                });
            }
        }
    }
    function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();

        handleSubmit(dados);
    };

    return (
        <form onSubmit={submit}>
            <h1>Bobina</h1>
            <div>
                <SelectEmpresa
                    text="Selecione a Empresa"
                    name="empresaId"
                    options={OptionsEmpresa}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.empresaId || ""}
                    required
                    notOptNull={true}
                />
                <Input
                    text="Descrição"
                    type="text"
                    name="descricao"
                    placeholder="Bobina"
                    title="Digite o nome da Bobina"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.descricao || ""}
                    required={true}
                />
                <InputNumber
                    text="Comprimento"
                    type="text"
                    name="comprimento"
                    placeholder="Comprimento"
                    inputWidth="100px"
                    title="digite o comprimento da bobina"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.comprimento ? formatarNumero(dados.comprimento) : ""}
                    required={true}
                />
                <InputNumber
                    text="Diâmetro"
                    type="text"
                    name="diametro"
                    placeholder="Diâmetro"
                    inputWidth="100px"
                    title="digite o diametro da bobina"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.diametro ? formatarNumero(dados.diametro) : ""}
                    required={true}
                />
                <Select
                    text="Selecione a Cor"
                    name="corId"
                    options={OptionsCores}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.corId || ""}
                    notOptNull={false}
                />
                <Checkbox
                    title="Bobina sanfonada"
                    text="Sanfonada"
                    name="sanfonada"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.sanfonada}
                    checked={dados.sanfonada === true ? "checked" : ""}
                />
                <Checkbox
                    title="Bobina transparente"
                    text="Transparente"
                    name="transparente"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.transparente}
                    checked={dados.transparente === true ? "checked" : ""}
                />
                <Checkbox
                    title="Bobina tratada"
                    text="Tratada"
                    name="tratada"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.tratada}
                    checked={dados.tratada === true ? "checked" : ""}
                />

                <Checkbox
                    title="Ativa quando marcado"
                    text="Ativo"
                    name="ativo"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.ativo}
                    checked={dados.ativo === true ? "checked" : ""}
                />
            </div>
            <div>
                <Stack direction="row" spacing={1}>
                    <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                        Voltar
                    </Button>
                    <Button type="submit" variant="contained" color="success">
                        {btnText}
                    </Button>
                </Stack>
            </div>
        </form>
    );
}
export default Form;
