// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Checkbox_form_control__x3bTV {
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 1em;
}

.Checkbox_form_control__x3bTV label {
    margin-left: 0.7em;
    margin-bottom: 0.3em;
    font-weight: bold;
    font-size: 0.8em;
}

.Checkbox_form_control__x3bTV input {
    padding: 0.7em;
    border: 1px solid #777;
    border-radius: 5px;
    margin-left: 0.7em;
}
.Checkbox_form_control__x3bTV.Checkbox_dark__kQX4w input {
    background-color: #484848;
    border: 1px solid #fff;
}
`, "",{"version":3,"sources":["webpack://./src/components/form/Checkbox.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,sBAAsB;IACtB,kBAAkB;IAClB,kBAAkB;AACtB;AACA;IACI,yBAAyB;IACzB,sBAAsB;AAC1B","sourcesContent":[".form_control {\n    display: inline-flex;\n    flex-direction: column;\n    margin-bottom: 1em;\n}\n\n.form_control label {\n    margin-left: 0.7em;\n    margin-bottom: 0.3em;\n    font-weight: bold;\n    font-size: 0.8em;\n}\n\n.form_control input {\n    padding: 0.7em;\n    border: 1px solid #777;\n    border-radius: 5px;\n    margin-left: 0.7em;\n}\n.form_control.dark input {\n    background-color: #484848;\n    border: 1px solid #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form_control": `Checkbox_form_control__x3bTV`,
	"dark": `Checkbox_dark__kQX4w`
};
export default ___CSS_LOADER_EXPORT___;
