import { useState, useEffect } from "react";
import "react-tabs/style/react-tabs.css";

import moment from "moment";
import "moment/locale/pt-br";

import { Stack, Button } from "@mui/material";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import Input from "../../form/Input";
import InputNumber from "../../form/InputNumber";
import Checkbox from "../../form/Checkbox";
import SelectEmpresa from "../../form/SelectEmpresa";
import Select from "../../form/Select";
import DataList from "../../form/DataList";

/* funções */
import { retornaData } from "../../../utils/retornaDataFormatada";

/* utils */
import { formatarNumero, formatarNumeroDecimal } from "../../../utils/masks";

/* apis */
import * as DataListAPI from "../../../api/dataList";

import * as PessoaAPI from "../../../api/pessoa";
import * as ProdutoAPI from "../../../api/produto";
import * as TabelaAPI from "../../../api/tabela";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, OptionsEmpresa }) {
    const [dados, setDados] = useState({});
    const [pessoa, setPessoa] = useState({});
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();
    const urlParams = new URLSearchParams(window.location.search);

    if (!dados.empresaId) {
        dados.ativo = true;
        dados.empresaId = localStorage.getItem("empresaId"); //parseInt(document.getElementById("empresaId").value);
        dados.data = retornaData(moment());
    }

    //set datalist
    const [OptionsParceiro, setOptionsParceiro] = useState([]);

    //Select
    const [OptionsMotorista, setMotorista] = useState([]);
    const [OptionsVTR, setVTR] = useState([]);

    //Atualiza o Datalist
    const consultaDatalist = async (token, empresaId, tabela, id, descricao, condicao, filtro) => {
        let result = await DataListAPI.dataList(empresaId, tabela, id, descricao, condicao, filtro);
        return result;
    };

    //Consultas no backend
    const consultaParceiro = async (pessoaId) => {
        const result = await PessoaAPI.financeiro("", pessoaId);
        return result;
    };
    const consultaColaborador = async (empresaId, filtro, condicao) => {
        const result = await PessoaAPI.colaborador(empresaId, filtro, condicao);
        setMotorista(result);
        setDados((prevMapaAbastecimento) => ({
            ...prevMapaAbastecimento,
            ativo: true,
            data: dados.data,
            //parceiroId: dados.parceiroId,
            valor: dados.valor,
            empresaId: dados.empresaId,
            pessoaId: result[0]?.id,
        }));

        return result;
    };
    const consultaVTR = async (empresaId, ativo, filtro, condicao) => {
        const motorista = await consultaColaborador(empresaId, null, "motorista");

        const result = await ProdutoAPI.bem(empresaId, ativo, filtro, condicao);

        // Obter a última km da vtr
        const kmAnterior = await ProdutoAPI.ultimoKm(result[0].id, dados.km);

        // Atualizar o estado de VTR e usar um callback opcional para continuar
        setVTR(result);

        setDados((prevMapaAbastecimento) => ({
            ...prevMapaAbastecimento,
            ativo: true,
            data: dados.data,
            valor: dados.valor,
            //empresaId: dados.empresaId,
            produtoId: result[0].id,
            pessoaId: motorista[0]?.id,
            kmAnterior: kmAnterior,
        }));
    };

    useEffect(() => {
        setDados(formData || {});
        setPessoa(formData?.Pessoa || {});
        dados.empresaId && consultaVTR(dados.empresaId, true, null, "Móvel");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const vtr = await TabelaAPI.tabelaById(dados.empresaId, "Produto", dados.produtoId);
                // Faça algo com 'vtr' se necessário
                const km = await ProdutoAPI.ultimoKm(vtr.id, dados.km);

                // Adicione o valor de km ao objeto vtr
                vtr.kmAnterior = km;
                setDados((prevDados) => ({
                    ...prevDados,
                    ativo: true,
                    data: prevDados.data,
                    parceiroId: prevDados.parceiroId,
                    pessoaId: prevDados.pessoaId,
                    valor: prevDados.valor,
                    km: prevDados.km,
                    kmAnterior: vtr.kmAnterior,
                }));
            } catch (error) {
                console.error("Erro ao buscar dados:", error);
            }
        };

        if (dados.produtoId) {
            fetchData(); // Chama a função assíncrona
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dados.produtoId]);

    async function handleChange(e) {
        if (e.target.name === "ativo") {
            setDados({ ...dados, [e.target.name]: e.target.checked });
        } else if (e.target.name.startsWith("Pessoa.")) {
            setPessoa({
                ...pessoa,
                [e.target.name.split(".")[1]]: e.target.value,
            });

            //consulta datalist
            let data = await consultaDatalist(
                token,
                dados.empresaId,
                "Pessoa",
                "id",
                "descricao",
                urlParams.get("tipo"),
                e.target.value,
                "",
                "",
                "Combustível"
            );
            setOptionsParceiro(data);

            const selectedId = document.querySelector(`datalist option[value="${e.target.value}"]`)?.dataset.id;

            if (selectedId) {
                await consultaParceiro(selectedId);

                setDados((prevState) => ({
                    ...prevState,
                    parceiroId: selectedId,
                }));
            } else {
                setDados((prevState) => ({
                    ...prevState,
                    parceiroId: null,
                }));
            }
        } else if (e.target.name === "litros" || e.target.name === "preco") {
            const valorFormatado = formatarNumeroDecimal(e.target.value);
            setDados({
                ...dados,
                [e.target.name]: valorFormatado,
            });
        } else {
            setDados({ ...dados, [e.target.name]: e.target.value });
        }
    }
    async function handleOnBlur(e) {
        if (e.target.name === "preco") {
            dados.valor = parseFloat(dados.litros * dados.preco).toFixed(2);
        }
    }
    function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();
        if (!dados.valor) {
            dados.valor = parseFloat(dados.litros * dados.preco).toFixed(2);
        }

        if (dados.km < dados.kmAnterior) {
            setFlashMessage("Confira as quilometragens!", "error");
            return;
        }
        handleSubmit(dados);
    };

    return (
        <form onSubmit={submit}>
            <h1>Mapa Abastecimento</h1>
            <div>
                <Input
                    text="Data"
                    type="date"
                    name="data"
                    placeholder="Data"
                    title="Data do abastecimento"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.data}
                />
                <SelectEmpresa
                    text="Selecione a Empresa"
                    name="empresaId"
                    options={OptionsEmpresa}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.empresaId || ""}
                    required
                    notOptNull={true}
                />
                <DataList
                    text="Selecione o Parceiro"
                    name="Pessoa.nomePrincipal"
                    inputWidth="400px"
                    filteredOptions={OptionsParceiro}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={pessoa.nomePrincipal || ""}
                    required={true}
                />
            </div>
            <div>
                <Select
                    name="pessoaId"
                    title="Selecione o motorista"
                    text="Motorista"
                    inputWidth="300px"
                    options={OptionsMotorista}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.pessoaId}
                    isEnum={false}
                    notOptNull={true}
                    required={true}
                />

                <Select
                    name="produtoId"
                    title="Selecione o veículo"
                    text="Veículo"
                    inputWidth="300px"
                    options={OptionsVTR}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.produtoId}
                    isEnum={false}
                    notOptNull={true}
                    required={true}
                />
            </div>
            <div>
                <Input
                    text="Km Anterior"
                    type="number"
                    name="kmAnterior"
                    placeholder="Km"
                    inputWidth="100px"
                    title="Km anterior"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.kmAnterior || ""}
                    textAlign="center"
                    readonly={true}
                />
                <Input
                    text="Km Atual"
                    type="number"
                    name="km"
                    placeholder="Km"
                    inputWidth="100px"
                    title="Digite o km no momento do abastecimento"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.km || ""}
                    textAlign="center"
                    readonly={false}
                    required={true}
                />
                <InputNumber
                    text="Litros"
                    type="text"
                    name="litros"
                    placeholder="Litros"
                    inputWidth="150px"
                    title="digite a quantidade de litros no momento do abastecimento"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.litros ? formatarNumero(dados.litros) : ""}
                    required={true}
                />
                <InputNumber
                    text="Preço"
                    type="text"
                    name="preco"
                    placeholder="Preço R$"
                    inputWidth="150px"
                    title="digite o valor do litro"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.preco ? formatarNumero(dados.preco) : ""}
                    required={true}
                />
                <Checkbox
                    title="Ativa quando marcado"
                    text="Ativo"
                    name="ativo"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.ativo}
                    checked={dados.ativo === true ? "checked" : ""}
                />
            </div>
            <div>
                <Stack direction="row" spacing={1}>
                    <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                        Voltar
                    </Button>
                    <Button type="submit" variant="contained" color="success">
                        {btnText}
                    </Button>
                </Stack>
            </div>
        </form>
    );
}
export default Form;
