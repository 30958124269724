import { createTheme } from "@mui/material";

const tableTheme = (globalTheme) =>
    createTheme(
        {
            palette: {
                mode: globalTheme.palette.mode,
                primary: globalTheme.palette.secondary,
                info: {
                    main: "rgb(255,122,0)",
                },
                background: {
                    default: globalTheme.palette.mode === "light" ? "rgb(255,231,231)" : "#1e1e1e",
                },
            },
            typography: {
                button: {
                    textTransform: "none",
                    fontSize: "1.2rem",
                },
            },
            components: {
                MuiTooltip: {
                    styleOverrides: {
                        tooltip: {
                            fontSize: "1.1rem",
                        },
                    },
                },
                MuiSwitch: {
                    styleOverrides: {
                        thumb: {
                            color: "pink",
                        },
                    },
                },
                MuiButton: {
                    styleOverrides: {
                        root: {
                            "&.Mui-disabled": {
                                backgroundColor: "rgba(255, 255, 255, 0.12)",
                                color: "rgba(255, 255, 255, 0.5)",
                                border: "1px solid rgba(255, 255, 255, 0.2)",
                            },
                        },
                    },
                },
            },
        },
        [globalTheme]
    );

export default tableTheme;
