import { useState, useEffect } from "react";
import "react-tabs/style/react-tabs.css";
import "moment/locale/pt-br";

import { Stack, Button } from "@mui/material";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

/* api */
import * as DataListAPI from "../../../api/dataList";
import * as TabelaAPI from "../../../api/tabela";

import Input from "../../form/Input";
import InputNumber from "../../form/InputNumber";
import Checkbox from "../../form/Checkbox";
import DataList from "../../form/DataList";
import Select from "../../form/Select";
import SelectEmpresa from "../../form/SelectEmpresa";

/* utils */
import { formatarNumero, formatarNumeroDecimal } from "../../../utils/masks";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, OptionsEmpresa }) {
    const [dados, setDados] = useState(formData || {});
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();

    const [Fabricante, setFabricante] = useState(formData?.Fabricante || {});
    const [OptionsFabricante, setOptionsFabricante] = useState([]);
    const [EnumCombustivel, setCombustivel] = useState({});

    if (!dados.empresaId) {
        dados.empresaId = parseInt(localStorage.getItem("empresaId"));
        dados.ativo = true;
    }

    const combustivelTipo = async () => {
        const result = await TabelaAPI.enums("produtoModelos", "combustivel");
        setCombustivel(result ? result : []);
    };

    const consultaDatalist = async (empresaId, tabela, id, descricao, condicao, filtro) => {
        let result = await DataListAPI.dataList(empresaId, tabela, id, descricao, condicao, filtro);
        return result;
    };

    useEffect(() => {
        setDados(formData || {});
        setFabricante(formData?.Fabricante || {});

        combustivelTipo();
    }, [formData]);

    async function handleChange(e) {
        const { name, value, checked, type } = e.target;

        if (name === "ativo") {
            setDados({ ...dados, [name]: checked });
        } else if (name === "tanque" || name === "tara") {
            const valorFormatado = formatarNumeroDecimal(value);
            setDados({
                ...dados,
                [name]: valorFormatado,
            });
        } else if (name.startsWith("Fabricante")) {
            setFabricante({
                ...Fabricante,
                [name.split(".")[1]]: value,
            });

            const result = await consultaDatalist(dados.empresaId, "Pessoa", "id", "descricao", "fabricante", value);
            setOptionsFabricante(result);
            const selectedId = document.querySelector(`datalist option[value="${value}"]`)?.dataset.id;

            setDados({
                ...dados,
                fabricanteId: selectedId ?? null,
            });
        } else {
            setDados({ ...dados, [name]: value });
        }
    }

    async function handleOnBlur(e) {
        const { name, value } = e.target;
        //verifica duplicidade
        if (name === "descricao" && btnText === "Cadastrar") {
            const duplicidade = await TabelaAPI.duplicidadeProdutoModelo(
                document.getElementById("empresaId").value,
                dados.fabricanteId,
                value
            );

            if (duplicidade?.uuid) {
                setFlashMessage("Registro em duplicidade!", "warning");
                return navigate(`/produtoModelo/CreateOrEdit`, {
                    uuid: duplicidade.uuid,
                });
            }
        }
    }
    function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();

        handleSubmit(dados);
    };

    return (
        <form onSubmit={submit}>
            <h1>Modelo de veículo</h1>
            <div>
                <SelectEmpresa
                    text="Selecione a Empresa"
                    name="empresaId"
                    options={OptionsEmpresa}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.empresaId || ""}
                    required
                    notOptNull={true}
                />

                <DataList
                    text="Fabricante"
                    title={"Vincula o Fabricante"}
                    name="Fabricante.descricao"
                    inputWidth="450px"
                    filteredOptions={OptionsFabricante}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={Fabricante.descricao || ""}
                />
            </div>
            <div>
                <Input
                    text="Descrição"
                    type="text"
                    name="descricao"
                    placeholder="Modelo"
                    title="Digite o modelo do veículo"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.descricao || ""}
                    required={true}
                    inputWidth="350px"
                />
                <Select
                    name="combustivel"
                    text="Combustível"
                    title="Tipo de combustível"
                    options={EnumCombustivel}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.combustivel}
                    isEnum={true}
                />
                <InputNumber
                    text="Tanque"
                    type="text"
                    name="tanque"
                    placeholder="Litros"
                    inputWidth="100px"
                    title="Capacidade máxima de abastecimento do veículo"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.tanque ? formatarNumero(dados.tanque) : ""}
                    required={true}
                />
                <InputNumber
                    text="Tara"
                    type="text"
                    name="tara"
                    placeholder="Kg"
                    inputWidth="100px"
                    title="Peso do veículo"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.tara ? formatarNumero(dados.tara) : ""}
                    required={true}
                />

                <Checkbox
                    title="Ativa quando marcado"
                    text="Ativo"
                    name="ativo"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.ativo}
                    checked={dados.ativo === true ? "checked" : ""}
                />
            </div>
            <div>
                <Stack direction="row" spacing={1}>
                    <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                        Voltar
                    </Button>
                    <Button type="submit" variant="contained" color="success">
                        {btnText}
                    </Button>
                </Stack>
            </div>
        </form>
    );
}
export default Form;
