import { useState, useEffect, useContext, useMemo } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

/* contexts */
import { Context } from "../../../context/UserContext";

import moment from "moment";
import "moment/locale/pt-br";

import { Stack, Button, Box, Tooltip, IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

import { ThemeProvider } from "@mui/material";
/* tema customizado*/
import tableThemeCustom from "../../form/tableThemeCustom";

//Import Material React Table Translations
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

/* utils */
import { formatarNumero, formatarNumeroDecimal } from "../../../utils/masks";
import { retornaDataHora } from "../../../utils/retornaDataFormatada";

import Input from "../../form/Input";
import InputNumber from "../../form/InputNumber";
import InputFloat from "../../form/InputFloat";
import Select from "../../form/Select";
import Checkbox from "../../form/Checkbox";
import DataList from "../../form/DataList";
import SelectEmpresa from "../../form/SelectEmpresa";

import formStyles from "../../form/Form.module.css";

/* apis */
import * as EmbalagemAPI from "../../../api/embalagem";
import * as DataListAPI from "../../../api/dataList";
import * as TabelaAPI from "../../../api/tabela";
import * as AlmoxarifadoAPI from "../../../api/almoxarifado";
import * as IndustriaAPI from "../../../api/industria";
import * as ProdutoAPI from "../../../api/produto";
/* util */
import { obterPermissoesUsuario } from "../../../utils/funcoes";
import { set } from "date-fns";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, OptionsEmpresa, OptionsEmbalagem }) {
    const [dados, setDados] = useState({});
    //grids
    const [itensEmbalagens, setItensEmbalagens] = useState([]);
    //const [itensCores, setItensCores] = useState([]);

    const [Empresa, setEmpresa] = useState({});
    const [Embalagem, setEmbalagem] = useState({});

    const [preview, setPreview] = useState([]);
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();
    const { parametro } = useContext(Context);

    const urlParams = new URLSearchParams(window.location.search);

    const ul = urlParams.get("ul") || 2;
    const li = urlParams.get("li") || 32;

    //data and fetching state
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [rowCountCor, setRowCountCor] = useState(0);
    const [rowCountComposicoes, setRowCountProdutoComposicoes] = useState(0);

    /* Empresa usa tabela comum ou propria */
    let empresaId = 1;
    if (parametro.tabelasComuns === false) {
        empresaId = localStorage.getItem("empresaId");
    }

    //set datalist
    const [NCM, setNCM] = useState(formData?.NCM);
    const [OptionsNCM, setOptionsNCM] = useState([]);
    const [Fabricante, setFabricante] = useState(formData?.Fabricante || {});
    const [produtoBens, setBem] = useState(formData?.produtoBens || {});
    const [produtoImagems, setProdutoImagems] = useState([]);
    const [OptionsFabricante, setOptionsFabricante] = useState([]);
    const [produtoEmbalagens, setProdutoEmbalagem] = useState([]);

    //industria datalist
    const [Bobina, setBobina] = useState(formData?.Bobina || {});
    const [OptionsBobina, setOptionsBobina] = useState([]);
    const [Cilindro, setCilindro] = useState(formData?.Cilindro || {});
    const [OptionsCilindro, setOptionsCilindro] = useState([]);

    const [produtoCores, setProdutoCor] = useState(formData?.produtoCores || {});
    const [novaCor, setNovaCor] = useState({
        produtoId: dados.id,
        empresaId: dados.empresaId,
        uuid: null,
        ordem: null,
        corId: null,
        aniloxId: null,
        Cor: {},
        Anilox: {},
        ativo: true,
        funcao: "Adicionar",
    });
    const [Cor, setCor] = useState({});
    const [OptionsCores, setOptionsCores] = useState([]);
    const [Anilox, setAnilox] = useState({});
    const [OptionsAnilox, setOptionsAnilox] = useState([]);

    const [produtoComposicoes, setProdutoComposicoes] = useState(formData?.Composicoes || {});
    const [materiaPrima, setMateriaPrima] = useState({});
    const [OptionsMateriaPrima, setOptionsMateriaPrima] = useState([]);
    const [novaMateriaPrima, setNovaMateriaPrima] = useState({
        uuid: null,
        produtoId: dados.id,
        empresaId: dados.empresaId,
        materiaPrimaId: null,
        percentual: null,

        materiaPrima: {},

        ativo: true,
        funcao: "Adicionar",
    });

    //almoxarifado
    const [OptionsAlmoxarifados, setOptionsAlmoxarifado] = useState([]);

    // Estado para rastrear a aba ativa
    const [abaAtiva, setAbaAtiva] = useState(0);
    const [gridAtiva, setGridAtiva] = useState("produto");

    /* identifica a aba atual */
    const handleSelect = (index) => {
        setAbaAtiva(index);

        // Definir qual grid será atualizada com base na aba ativa
        switch (index) {
            case 0:
                setGridAtiva("produto");
                break;
            case 1:
                setGridAtiva("industria");
                break;
            case 2:
                setGridAtiva("materiaPrima");
                break;

            case 3:
                setGridAtiva("embalagens");
                break;
            case 4:
                setGridAtiva("caracteristicas");
                break;
            case 5:
                setGridAtiva("bens");
                break;
            case 6:
                setGridAtiva("imagens");
                break;
            default:
                setGridAtiva("produto");
        }
    };

    //abas
    const [tabEnabled, setTabEnabled] = useState({
        produto: true,
        industria: true,
        materiaPrima: true,
        embalagens: true,
        caracteristicas: true,
        bens: true,
        imagens: true,
    });

    //carrega informações para cadastro inicial
    if (!dados.empresaId) {
        dados.empresaId = localStorage.getItem("empresaId");
        dados.tipo = "Material";
    }

    //set enums
    const [EnumTipo, setTipo] = useState(formData?.OptionsTipo || {});
    const [EnumBemTipo, setBemTipo] = useState(formData?.OptionsBemTipo || {});

    //carregar ENUMS
    const consultaEnum = async (table, field, filter) => {
        const result = await TabelaAPI.enums(table, field, filter);
        if (table === "Produtos") {
            field === "tipo" && setTipo(result ? result : []);
        } else if (table === "produtoBens") {
            field === "tipo" && setBemTipo(result ? result : []);
        }
    };

    //api
    const consultaTabelaById = async (empresaId, tabela, id) => {
        const result = await TabelaAPI.tabelaById(empresaId, tabela, id);

        if (tabela === "Empresas") {
            setEmpresa(result);
        } else if (tabela === "Embalagem") {
            setEmbalagem(result);
        }
        return result;
    };
    const consultaAlmoxarifados = async (empresaId = dados.empresaId) => {
        const result = await AlmoxarifadoAPI.almoxarifados(empresaId);
        setOptionsAlmoxarifado(result);
        return result;
    };
    const consultaBobinaById = async (bobinaId) => {
        const result = await IndustriaAPI.buscaBobinaById(bobinaId);
        return result;
    };

    //Atualiza o Datalist
    const consultaDatalist = async (empresaId, tabela, id, descricao, condicao, filtro) => {
        const result = await DataListAPI.dataList(empresaId, tabela, id, descricao, condicao, filtro);
        return result;
    };

    //rotina para criar objetos de tabelas filhas
    const [novaEmbalagem, setNovaEmbalagem] = useState({
        produtoId: dados.id,
        empresaId: dados.empresaId,
        Empresa: Empresa,
        embalagemId: "",
        Embalagem: Embalagem,
        uuid: "",
        codigoBarras: "",
        ativo: true,
        funcao: "Adicionar",
    });

    useEffect(() => {
        setDados(formData || {});
        setBobina(formData?.Bobina || {});
        setCilindro(formData?.Cilindro || {});
        setFabricante(formData?.Fabricante || {});

        //grids
        setProdutoEmbalagem(formData?.produtoEmbalagens || []);
        setRowCount(formData?.produtoEmbalagens ? formData.produtoEmbalagens.length : 0);
        setItensEmbalagens(formData?.produtoEmbalagens || []);
        setProdutoCor(formData?.produtoCores || []);
        setRowCountCor(formData?.produtoCores ? formData.produtoCores.length : 0);
        setProdutoComposicoes(formData?.produtoComposicoes || []); //mesmo que a matéria-prima
        setRowCountProdutoComposicoes(formData?.produtoComposicoes ? formData.produtoComposicoes.length : 0);

        setNCM(formData.NCM || {});

        setBem(formData?.produtoBens || {});

        setProdutoImagems(formData?.produtoImagems || []);
        setTipo(formData?.OptionsTipo);
        setBemTipo(formData?.OptionsBemTipo);

        setTabEnabled({
            produto: true,
            industria: true,
            materiaPrima: true,
            embalagens: true,
            caracteristicas: true,
            bens: dados.tipo === "Imobilizado" || dados.tipo === "Material" ? true : false,
            imagens: true,
        });

        //enums
        consultaEnum("Produtos", "tipo");
        consultaEnum("produtoBens", "tipo");
        consultaAlmoxarifados(dados.empresaId);
        consultaTabelaById(null, "Empresa", dados.empresaId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    useEffect(() => {
        setNovaEmbalagem((prevEmbalagem) => ({
            ...prevEmbalagem,
            empresaId: dados.empresaId,
            produtoId: dados.id,
        }));
        setNovaCor((prevCor) => ({
            ...prevCor,
            empresaId: dados.empresaId,
            produtoId: dados.id,
        }));
        setNovaMateriaPrima((prev) => ({
            ...prev,
            empresaId: dados.empresaId,
            produtoId: dados.id,
        }));
    }, [dados.id]);
    //confirma configuração da bobina
    useEffect(() => {
        const bobinaId = dados.bobinaId;
        async function fetchBobina(bobinaId) {
            try {
                const response = await consultaBobinaById(bobinaId);

                if (response) {
                    setDados((prev) => ({
                        ...prev,
                        sanfonado: response.data.sanfonada, //ver o que fazer com o nome sanfonada ou sanfonado
                        impressao: response.data.tratada === true ? true : false, //indica que o produto vai ser impresso
                    }));
                }
            } catch (error) {
                console.error("Erro ao buscar os dados:", error);
            }
        }

        bobinaId && fetchBobina(bobinaId);
    }, [dados.bobinaId]);

    //atualiza tabelas filhas
    useEffect(() => {
        setDados((prev) => {
            return {
                ...prev,
                produtoBens: produtoBens,
                produtoComposicoes: produtoComposicoes,
                produtoCores: produtoCores,
                produtoEmbalagens: produtoEmbalagens,
            };
        });
    }, [produtoBens, produtoEmbalagens, produtoComposicoes, produtoCores]);

    useEffect(() => {
        setTabEnabled((prev) => ({
            ...prev,
            bens: dados.tipo === "Imobilizado" || dados.tipo === "Material",
        }));

        if (dados.tipo === "Serviço") {
            setDados((prev) => ({
                ...prev,
                ncmId: 1, //id de serviço
                fabricanteId: parametro?.pessoaId, //pessoa principal
            }));
            setNCM({
                ...NCM,
                ncm: 0,
                descricao: "Serviço",
            });
            setFabricante({
                ...Fabricante,
                descricao: parametro?.pessoaPadrao?.descricao,
            });
        }
    }, [dados.tipo]);

    async function onFileChange(e) {
        setPreview(Array.from(e.target.files));
        setDados({ ...dados, images: [...e.target.files] });
    }
    async function handleChange(e) {
        const { name, value, checked, type } = e.target;

        const camposMonetarios = [
            "comissao",
            "pesoBruto",
            "pesoLiquido",
            "altura",
            "largura",
            "comprimento",
            "volume",
            "estoqueMinimo",
            "producaoMinima",

            "toleranciaMinima",
            "toleranciaMaxima",
        ];
        const camposBooleanos = [
            "ativo",
            "compra",
            "venda",
            "locavel",
            "materiaPrima",
            "embalagem",
            "laminado",
            "sanfonado",
            "separacao",
            "refugo",
            "lavagem",
            "producao",
            "extrusao",
            "finalizaExtrusora",
            "recuperadora",
            "corrugadora",
            "impressao",
            "corteSolda",
        ];
        if (camposBooleanos.includes(name)) {
            setDados({ ...dados, [name]: checked });
        } else if (camposMonetarios.includes(name)) {
            const valorFormatado = formatarNumeroDecimal(value);

            // Atualiza o estado do campo modificado
            const novosDados = { ...dados, [name]: valorFormatado };

            // Calcula o volume se altura, largura ou comprimento forem alterados
            if (["altura", "largura", "comprimento"].includes(name)) {
                const altura = parseFloat(novosDados.altura) || 0;
                const largura = parseFloat(novosDados.largura) || 0;
                const comprimento = parseFloat(novosDados.comprimento) || 0;

                // Calcula o volume em cm³ e converte para m³
                const volumeCm3 = altura * largura * comprimento;
                const volumeM3 = volumeCm3 / 1000000;

                novosDados.volume = volumeM3;
            }
            setDados((prev) => ({ ...prev, novosDados }));
        } else if (name.startsWith("Fabricante")) {
            setFabricante({
                ...Fabricante,
                [name.split(".")[1]]: value,
            });

            const result = await consultaDatalist(dados.empresaId, "Pessoa", "id", "descricao", "fabricante", value);
            setOptionsFabricante(result);

            const selectedId = document.querySelector(`datalist option[value="${value}"]`)?.dataset.id;
            setDados((prev) => ({
                ...prev,
                fabricanteId: selectedId,
            }));
        } else if (name.startsWith("NCM.")) {
            setNCM({
                ...NCM,
                [name.split(".")[1]]: value,
            });

            //carrega o datalist
            const result = await consultaDatalist(empresaId, "NCM", "id", "descricao", "", value);
            setOptionsNCM(result);

            const selectedId = document.querySelector(`datalist option[value="${value}"]`)?.dataset.id || null;

            if (selectedId) {
                setDados((prev) => ({
                    ...prev,
                    ncmId: selectedId,
                }));
                const arr = document.querySelector(`datalist option[value="${value}"]`)?.value?.split(" ");
                setNCM((prev) => ({ ...prev, ncm: arr[0] }));
            }
        } else if (name.startsWith("Bobina")) {
            setBobina({
                ...Bobina,
                [name.split(".")[1]]: value,
            });

            const result = await consultaDatalist(dados.empresaId, "Bobina", "id", "descricao", null, value);

            setOptionsBobina(result);

            const selectedId = document.querySelector(`datalist option[value="${value}"]`)?.dataset.id;
            setDados((prev) => ({
                ...prev,
                bobinaId: selectedId ?? null,
            }));
        } else if (name.startsWith("Cilindro")) {
            setCilindro({
                ...Cilindro,
                [name.split(".")[1]]: value,
            });

            const result = await consultaDatalist(dados.empresaId, "Cilindro", "id", "descricao", null, value);

            setOptionsCilindro(result);

            const selectedId = document.querySelector(`datalist option[value="${value}"]`)?.dataset.id || null;
            setDados((prev) => ({
                ...prev,
                cilindroId: selectedId,
            }));
        } else if (name.startsWith("novaCor.")) {
            if (name === "novaCor.Cor.descricao") {
                setCor((prev) => ({
                    ...prev,
                    [name.split(".")[2]]: value,
                }));

                const result = await consultaDatalist(dados.empresaId, "Cor", "id", "descricao", null, value);

                setOptionsCores(result);
                const selectedId = document.querySelector(`datalist option[value="${value}"]`)?.dataset.id;
                //obter a cor pelo id
                const cor = selectedId ? await TabelaAPI.tabelaById(dados.empresaId, "Cor", selectedId) : null;

                setNovaCor((prev) => ({
                    ...prev,
                    corId: selectedId ?? null,
                    Cor: cor || {},
                }));
            } else if (name === "novaCor.Anilox.descricao") {
                setAnilox((prev) => ({
                    ...prev,
                    [name.split(".")[2]]: value,
                }));

                const result = await consultaDatalist(dados.empresaId, "Anilox", "id", "descricao", null, value);

                setOptionsAnilox(result);

                const selectedId = document.querySelector(`datalist option[value="${value}"]`)?.dataset.id;
                //obter o anilox pelo id
                const anilox = selectedId ? await TabelaAPI.tabelaById(dados.empresaId, "Anilox", selectedId) : null;
                setNovaCor((prev) => ({
                    ...prev,
                    aniloxId: selectedId ?? null,
                    Anilox: anilox || {},
                }));
            } else {
                setNovaCor((prev) => ({
                    ...prev,
                    [name.split(".")[1]]: type === "checkbox" ? checked : value,
                }));
            }
        } else if (name.startsWith("novaMateriaPrima.")) {
            if (name === "novaMateriaPrima.materiaPrima.descricao") {
                setMateriaPrima((prev) => ({
                    ...prev,
                    [name.split(".")[2]]: value,
                }));

                const result = await consultaDatalist(
                    dados.empresaId,
                    "Produto",
                    "id",
                    "descricao",
                    "materiaPrima", //retorna apenas produtos do tipo materiaPrima = true
                    value
                );
                setOptionsMateriaPrima(result);
                const selectedId = document.querySelector(`datalist option[value="${value}"]`)?.dataset.id;

                //obter a matéria-prima selecionada para incluir no objeto
                const produto = await ProdutoAPI.obterProdutoByIdOrUUID(selectedId);

                setNovaMateriaPrima((prev) => ({
                    ...prev,
                    materiaPrimaId: selectedId ?? null,
                    materiaPrima: produto.data || {},
                }));
            } else {
                const valorFormatado = formatarNumeroDecimal(value);

                setNovaMateriaPrima((prev) => ({
                    ...prev,
                    [name.split(".")[1]]: type === "checkbox" ? checked : valorFormatado,
                }));
            }
        } else if (name.startsWith("produtoBens.")) {
            //para checkbox
            let valor = type === "checkbox" ? checked : value;
            setBem({
                ...produtoBens,
                [name.split(".")[1]]: valor,
            });
        } else if (name.startsWith("novaEmbalagem.")) {
            // Verifica se é um checkbox e define o valor correspondente
            let valor = type === "checkbox" ? checked : value;

            setNovaEmbalagem({
                ...novaEmbalagem,
                [name.split(".")[1]]: valor,
                empresa:
                    document.getElementById("novaEmbalagem.empresaId").options[
                        document.getElementById("novaEmbalagem.empresaId").selectedIndex
                    ].text,
                embalagem:
                    document.getElementById("novaEmbalagem.embalagemId").options[
                        document.getElementById("novaEmbalagem.embalagemId").selectedIndex
                    ].text,
            });
        } else {
            setDados((prev) => ({ ...prev, [name]: value }));
        }
    }
    async function handleOnBlur(e) {
        const { name, value, checked, type } = e.target;
        if (name === "descricao") {
            //verifica duplicidade
            if (name === "descricao" && btnText === "Cadastrar") {
                //verifica se o centroCusto existe na base de dados
                const duplicidade = await TabelaAPI.duplicidadeDescricao(dados.empresaId, "Produto", value);

                if (duplicidade?.uuid) {
                    setFlashMessage("Registro em duplicidade!", "warning");
                    return navigate(`/Produtos/CreateOrEdit`, {
                        uuid: duplicidade.uuid,
                        btnText: "Atualizar",
                    });
                }
            }
        }
    }
    async function handleOnFocus(e) {
        const { name, value, checked, type } = e.target;
        if (name === "produtoBens.descricao") {
            setBem({
                ...produtoBens,
                descricao: dados.descricao,
            });
        }
    }

    const submit = async (e) => {
        e.preventDefault();

        if (!dados.empresaId) {
            dados.empresaId = parseInt(document.getElementById("empresaId").value);
        }

        //validações
        if (!dados.ncmId) {
            setFlashMessage("NCM é um campo obrigatório!", "error");
            return;
        }

        handleSubmit(dados);
    };

    //table state
    const [columnFilterFns, setColumnFiltersMode] = useState(
        localStorage.columnFilterFns.length > 2 ? JSON.parse(localStorage.columnFilterFns) : []
    );
    const [columnFilters, setColumnFilters] = useState(
        localStorage.columnFilters.length > 2 ? JSON.parse(localStorage.columnFilters) : []
    );
    const [globalFilter, setGlobalFilter] = useState(
        localStorage.globalFilter.length > 2 ? JSON.parse(localStorage.globalFilter) : ""
    );
    const [sorting, setSorting] = useState(localStorage.sorting.length > 2 ? JSON.parse(localStorage.sorting) : []);
    const [pagination, setPagination] = useState({
        pageIndex: JSON.parse(localStorage.pagination).pageIndex || 0,
        pageSize: JSON.parse(localStorage.pagination).pageSize || 5,
    });

    /* modo tema */
    const theme = tableThemeCustom();

    const columns = useMemo(
        () => [
            {
                id: "embalagem",
                header: "Embalagens do produto",
                columns: [
                    { accessorKey: "Empresa.nomeFantasia", header: "Empresa" },
                    {
                        accessorKey: "Embalagem.descricao",
                        header: "Descrição",
                        //you can access a cell in many callback column definition options like this
                        muiTableBodyCellProps: ({ cell }) => ({
                            onClick: () => {},
                        }),
                    },
                    {
                        accessorKey: "codigoBarras",
                        header: "Código de Barras",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                    },
                    {
                        accessorKey: "ativo",
                        header: "Ativo",
                        columnFilterModeOptions: ["equals"],
                        filterSelectOptions: [
                            { label: "Todos", value: "" },
                            { label: "Ativo", value: 1 },
                            { label: "Inativo", value: 0 },
                        ],
                        filterVariant: "select",
                        muiTableBodyCellProps: ({ row }) => ({
                            align: "center",
                            onClick: () => {},
                        }),
                        Cell: ({ row }) => {
                            return row.original.ativo ? "Ativo" : "Inativo";
                        },
                    },
                ],
            },

            //column definitions...
        ],
        [] //getCommonEditTextFieldProps
    );
    const columnsCores = useMemo(
        () => [
            {
                id: "cores",
                header: "cores e anilox para o processo de produção do produto",
                columns: [
                    {
                        accessorKey: "ordem",
                        header: "Ordem",
                        muiTableBodyCellProps: ({ row }) => ({
                            align: "center",
                            onClick: () => {},
                        }),
                    },
                    {
                        accessorKey: "Cor.descricao",
                        header: "Descrição",
                        //you can access a cell in many callback column definition options like this
                        muiTableBodyCellProps: ({ row }) => ({
                            onClick: () => {},
                        }),
                    },
                    { accessorKey: "Anilox.descricao", header: "Anilox", cell: (info) => info.getValue() ?? "N/A" },
                    {
                        accessorKey: "ativo",
                        header: "Ativo",
                        columnFilterModeOptions: ["equals"],
                        filterSelectOptions: [
                            { label: "Todos", value: "" },
                            { label: "Ativo", value: 1 },
                            { label: "Inativo", value: 0 },
                        ],
                        filterVariant: "select",
                        muiTableBodyCellProps: ({ row }) => ({
                            align: "center",
                            onClick: () => {},
                        }),
                        Cell: ({ row }) => {
                            return row.original.ativo ? "Ativo" : "Inativo";
                        },
                    },
                ],
            },

            //column definitions...
        ],
        [produtoCores] //getCommonEditTextFieldProps
    );
    const columnsProdutoComposicao = useMemo(
        () => [
            {
                id: "produtoComposisao",
                header: "matéria-prima no processo de produção do produto",
                columns: [
                    {
                        accessorKey: "materiaPrima.descricao",
                        header: "Descrição",
                        //you can access a cell in many callback column definition options like this
                        muiTableBodyCellProps: ({ row }) => ({
                            onClick: () => {},
                        }),
                    },
                    {
                        accessorKey: "percentual",
                        header: "Percentual",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.percentual === null
                                ? ""
                                : parseFloat(row.percentual)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }) + " %",
                    },
                    {
                        accessorKey: "ativo",
                        header: "Ativo",
                        columnFilterModeOptions: ["equals"],
                        filterSelectOptions: [
                            { label: "Todos", value: "" },
                            { label: "Ativo", value: 1 },
                            { label: "Inativo", value: 0 },
                        ],
                        filterVariant: "select",
                        muiTableBodyCellProps: ({ row }) => ({
                            align: "center",
                            onClick: () => {},
                        }),
                        Cell: ({ row }) => {
                            return row.original.ativo ? "Ativo" : "Inativo";
                        },
                    },
                ],
            },

            //column definitions...
        ],
        [produtoComposicoes] //getCommonEditTextFieldProps
    );

    //embalagem
    const handleEmbalagem = async () => {
        // Função auxiliar para resetar novaEmbalagem
        const resetNovaEmbalagem = () => ({
            produtoId: dados.id,
            empresaId: novaEmbalagem.empresaId,
            embalagemId: novaEmbalagem.embalagemId,
            uuid: "",
            codigoBarras: "",
            ativo: true,
            funcao: "Adicionar",
        });

        // Verifica e define campos não preenchidos
        novaEmbalagem.produtoId = novaEmbalagem.produtoId || parseInt(dados.id);
        novaEmbalagem.embalagemId =
            novaEmbalagem.embalagemId || parseInt(document.getElementById("novaEmbalagem.embalagemId").value);
        novaEmbalagem.empresaId = novaEmbalagem.empresaId || parseInt(document.getElementById("novaEmbalagem.empresaId").value);

        if (dados.id) {
            const result = await CreateOrEditEmbalagem(novaEmbalagem);

            if (result.status === 202) {
                setFlashMessage("Embalagem já cadastrada!", "info");
            } else {
                setFlashMessage(result.message, result.type);

                if (result.type === "success") {
                    setProdutoEmbalagem(result.resultado);
                }

                setNovaEmbalagem(resetNovaEmbalagem());
            }

            fetchData("embalagens");
        } else {
            const empresa = await consultaTabelaById(null, "Empresa", novaEmbalagem.empresaId);
            const embalagem = await consultaTabelaById(novaEmbalagem.empresaId, "Embalagem", novaEmbalagem.embalagemId);

            if (novaEmbalagem.funcao.toLocaleLowerCase() === "editar") {
                if (novaEmbalagem.uuid) {
                    await CreateOrEditEmbalagem(novaEmbalagem);
                } else {
                    setItensEmbalagens((prevItens) => {
                        const novosItens = [...prevItens];
                        novosItens[novaEmbalagem.index] = { ...prevItens[novaEmbalagem.index], ...novaEmbalagem };
                        return novosItens;
                    });
                    setRowCount(itensEmbalagens.length);
                }
            } else if (novaEmbalagem.funcao.toLocaleLowerCase() === "deletar") {
                if (novaEmbalagem.uuid) {
                    try {
                        const response = await EmbalagemAPI.deletarEmbalagem(novaEmbalagem);
                        setFlashMessage(response.message, response.type);
                    } catch (error) {
                        console.error(error);
                        setFlashMessage(error.response.message, error.data.type);
                    }
                } else {
                    setItensEmbalagens((prevItens) => {
                        const novosItens = [...prevItens];
                        novosItens.splice(novaEmbalagem.index, 1);
                        return novosItens;
                    });
                }
            } else {
                if (dados.id) {
                    novaEmbalagem.produtoId = dados.id;

                    const result = await EmbalagemAPI.duplicidadeCodigobarras(
                        novaEmbalagem.empresaId,
                        novaEmbalagem.codigoBarras
                    );

                    if (result && novaEmbalagem.funcao.toLocaleLowerCase() === "adicionar") {
                        setFlashMessage("Código de barras em duplicidade!", "warning");
                        handleEditarOrDeletarEmbalagem(result, null, "Adicionar");
                        return;
                    }

                    await CreateOrEditEmbalagem(novaEmbalagem);
                } else {
                    const codigoBarrasExiste = itensEmbalagens.some(
                        (item) => item.codigoBarras === novaEmbalagem.codigoBarras && item.empresaId === novaEmbalagem.empresaId
                    );

                    if (codigoBarrasExiste) {
                        setFlashMessage("Código de barras já cadastrado para o produto!", "warning");
                        return;
                    }

                    const result = await EmbalagemAPI.duplicidadeCodigobarras(
                        novaEmbalagem.empresaId,
                        novaEmbalagem.codigoBarras
                    );

                    if (result && novaEmbalagem.funcao.toLocaleLowerCase() === "adicionar") {
                        setFlashMessage("Código de barras em duplicidade!", "warning");
                        return;
                    }

                    const novoItemArray = [
                        ...itensEmbalagens,
                        {
                            uuid: novaEmbalagem.uuid,
                            produtoId: novaEmbalagem.produtoId || dados.Id,
                            empresaId: novaEmbalagem.empresaId,
                            embalagemId: novaEmbalagem.embalagemId,
                            codigoBarras: novaEmbalagem.codigoBarras,
                            ativo: novaEmbalagem.ativo,
                            Empresa: empresa,
                            Embalagem: embalagem,
                        },
                    ];

                    setItensEmbalagens(novoItemArray);
                    setRowCount(novoItemArray.length);
                    setProdutoEmbalagem(novoItemArray);
                    // setDados((prev) => ({
                    //     ...prev,
                    //     produtoEmbalagens: novoItemArray,
                    // }));
                }
            }

            setNovaEmbalagem(resetNovaEmbalagem());
        }
    };
    const CreateOrEditEmbalagem = async (novaEmbalagem) => {
        try {
            const result = await EmbalagemAPI.managerProdutoEmbalagem(novaEmbalagem);
            setFlashMessage(result.message, result.type);
            fetchData("embalagens");
            return result;
        } catch (error) {
            console.error(error);
            if (error.response && error.response.status === 422) {
                // Tratar o erro 422, que é específico da validação no servidor
                setFlashMessage("Erro de validação: " + error.response.data.message, "error");
            } else {
                // Tratar outros erros de rede ou servidor
                setFlashMessage("Ocorreu um erro ao processar a solicitação.", "error");
            }
        }
    };
    const handleEditarOrDeletarEmbalagem = async (originalRow, row, funcao) => {
        // Define os dados para o novo produto
        const newData = {
            uuid: originalRow.uuid,
            Embalagem: originalRow.Embalagem,
            Empresa: originalRow.Empresa,

            produtoId: originalRow.produtoId,
            embalagemId: originalRow.embalagemId,
            codigoBarras: originalRow.codigoBarras,
            descricao: originalRow.descricao,

            ativo: originalRow.ativo,
            funcao: funcao,
            index: row,
        };

        // Atualize o estado do novo produto
        setNovaEmbalagem(newData);

        return;
    };

    //modulo industria tratamento da cor
    const limparCor = () => {
        setNovaCor({
            empresaId: dados.empresaId,
            produtoId: dados.id,
            uuid: null,
            ordem: null,
            corId: null,
            aniloxId: null,
            Cor: {},
            Anilox: {},
            ativo: true,
            funcao: "Adicionar",
        });
        setCor({});
        setAnilox({});
    };
    const handleCor = async () => {
        if (dados.id) {
            const result = await ProdutoAPI.createOrEditCor(novaCor);

            setFlashMessage(result.message, result.type);
            if (result.type === "success") {
                setProdutoCor(result.data);
            }
        } else {
            if (novaCor.funcao.toLocaleLowerCase() === "editar") {
                setProdutoCor((prev) => prev.map((item, i) => (i === novaCor.index ? { ...item, ...novaCor } : item)));
            } else if (novaCor.funcao.toLocaleLowerCase() === "deletar") {
                setProdutoCor((prev) => {
                    const atualizado = prev.filter((item) => item.id !== novaCor.id);
                    return atualizado;
                });
            } else {
                setProdutoCor((prev) => [...prev, novaCor]);
            }
        }
        limparCor();
    };
    const handleEditarOrDeletarCor = async (originalRow, row, funcao) => {
        // Define os dados para o novo produto
        const newData = {
            uuid: originalRow.uuid,
            Cor: originalRow.Cor,
            Anilox: originalRow.Anilox,
            Empresa: originalRow.Empresa,

            empresaId: originalRow.empresaId || dados.empresaId,
            produtoId: originalRow.produtoId || dados.id,
            ordem: originalRow.ordem,
            corId: originalRow.corId,
            aniloxId: originalRow.aniloxId,

            ativo: originalRow.ativo,
            funcao: funcao,
            index: row,
        };

        // Atualize o estado do novo produto
        setNovaCor(newData);
        setCor(newData.Cor);
        setAnilox(newData.Anilox);

        return;
    };

    //matéria-prima
    const limparMateriaPrima = () => {
        //novaMateriaPrima.percentual = null;
        setNovaMateriaPrima((prev) => ({
            ...prev,
            uuid: null,
            empresaId: dados.empresaId,
            produtoId: dados.id,
            materiaPrimaId: null,
            percentual: null,

            materiaPrima: {},

            ativo: true,
            funcao: "Adicionar",
        }));
        setMateriaPrima({});
    };
    const handleMateriaPrima = async () => {
        if (dados.id) {
            const result = await ProdutoAPI.createOrEditMateriaPrima(novaMateriaPrima);

            setFlashMessage(result.message, result.type);

            fetchData("materiaPrima");

            limparMateriaPrima();
            setMateriaPrima({});
        } else {
            if (novaMateriaPrima.funcao.toLocaleLowerCase() === "editar") {
                setProdutoComposicoes((prev) =>
                    prev.map((item, i) => (i === novaMateriaPrima.index ? { ...item, ...novaMateriaPrima } : item))
                );
            } else if (novaMateriaPrima.funcao.toLocaleLowerCase() === "deletar") {
                setProdutoComposicoes((prev) => {
                    const atualizado = prev.filter((item) => item.id !== novaMateriaPrima.id);
                    return atualizado;
                });
            } else {
                setProdutoComposicoes((prev) => [...prev, novaMateriaPrima]);
            }

            limparMateriaPrima();
            setMateriaPrima({});
        }
    };
    const handleEditarDeletarMateriaPrima = async (originalRow, row, funcao) => {
        // Define os dados para o novo registro
        const newData = {
            uuid: originalRow.uuid,
            materiaPrima: originalRow.materiaPrima,

            empresaId: originalRow.empresaId || dados.empresaId,
            produtoId: originalRow.produtoId || dados.id,
            materiaPrimaId: originalRow.materiaPrimaId,
            percentual: originalRow.percentual,

            ativo: originalRow.ativo,
            funcao: funcao,
            index: row,
        };

        // Atualize o estado do novo produto
        setNovaMateriaPrima(newData);
        setMateriaPrima(newData.materiaPrima);

        return;
    };

    useEffect(() => {
        if (!token) {
            return navigate("/Auth", { replace: true });
        }

        //funcao para setar localStorage
        localStorage.setItem(
            "pagination",
            JSON.stringify({
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
            })
        );
        localStorage.setItem("columnFilterFns", JSON.stringify(columnFilterFns));
        localStorage.setItem("columnFilters", JSON.stringify(columnFilters));
        localStorage.setItem("globalFilter", JSON.stringify(globalFilter));
        localStorage.setItem("sorting", JSON.stringify(sorting));

        //permissões do usuario
        obterPermissoesUsuario(localStorage.getItem("pessoaId"), localStorage.getItem("empresaId"), ul, li);

        fetchData(gridAtiva);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, columnFilterFns, columnFilters, globalFilter, pagination.pageIndex, pagination.pageSize, sorting, gridAtiva]);

    const fetchData = async (grid) => {
        if (!itensEmbalagens.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }

        if (dados.id) {
            if (grid === "industria") {
                try {
                    await ProdutoAPI.listarCores(
                        pagination.pageIndex * pagination.pageSize,
                        pagination.pageSize,
                        JSON.stringify(columnFilters ?? []),
                        JSON.stringify(columnFilterFns ?? []),
                        globalFilter ?? "",
                        JSON.stringify(sorting ?? []),
                        dados.id ?? ""
                    ).then((response) => {
                        setProdutoCor(response.data.data);
                        setRowCountCor(response.data.meta.totalRowCount);
                    });
                } catch (error) {
                    setIsError(true);
                    console.error(error);
                    return;
                }
            } else if (grid === "materiaPrima") {
                try {
                    await ProdutoAPI.listarMateriaPrima(
                        pagination.pageIndex * pagination.pageSize,
                        pagination.pageSize,
                        JSON.stringify(columnFilters ?? []),
                        JSON.stringify(columnFilterFns ?? []),
                        globalFilter ?? "",
                        JSON.stringify(sorting ?? []),
                        dados.id ?? ""
                    ).then((response) => {
                        setProdutoComposicoes(response.data.data);
                        setRowCountProdutoComposicoes(response.data.meta.totalRowCount);
                    });
                } catch (error) {
                    setIsError(true);
                    console.error(error);
                    return;
                }
            } else if (grid === "embalagens") {
                try {
                    await EmbalagemAPI.listarEmbalagens(
                        pagination.pageIndex * pagination.pageSize,
                        pagination.pageSize,
                        JSON.stringify(columnFilters ?? []),
                        JSON.stringify(columnFilterFns ?? []),
                        globalFilter ?? "",
                        JSON.stringify(sorting ?? []),
                        dados.id ?? ""
                    ).then((response) => {
                        setItensEmbalagens(response.data.data);
                        setRowCount(response.data.meta.totalRowCount);
                    });
                } catch (error) {
                    setIsError(true);
                    console.error(error);
                    return;
                }
            }
        }

        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
    };

    return (
        <Tabs selectedIndex={abaAtiva} onSelect={handleSelect}>
            <form onSubmit={submit}>
                <h1>Gestão de Produtos</h1>
                <TabList>
                    <Tab disabled={!tabEnabled.produto} title={"Infomações genericas do produto"}>
                        Produto
                    </Tab>
                    <Tab
                        disabled={!tabEnabled.industria}
                        title={"Informações relevantes no processo de industrialização do produto"}
                    >
                        Indústria
                    </Tab>
                    <Tab disabled={!tabEnabled.materiaPrima} title={"Matéria prima da compisção do produto"}>
                        Matéria Prima
                    </Tab>
                    <Tab disabled={!tabEnabled.embalagens} title={"Embalagens do produto"}>
                        Embalagens
                    </Tab>
                    <Tab disabled={!tabEnabled.caracteristicas} title={"Caracteristicas do produto"}>
                        Caracteristicas
                    </Tab>
                    <Tab disabled={!tabEnabled.bens} title={"Informações do bem móvel ou imóvel"}>
                        Bens
                    </Tab>
                    <Tab disabled={!tabEnabled.imagens} title={"Imagens do produto"}>
                        Imagens
                    </Tab>
                </TabList>
                <TabPanel>
                    <div className={formStyles.preview_bandeira}>
                        <h4>Informações principais do Produto</h4>
                    </div>
                    <div>
                        <SelectEmpresa
                            name="empresaId"
                            text="Empresa"
                            options={OptionsEmpresa}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.empresaId}
                            isEnum={false}
                            notOptNull={true}
                        />
                        <Input
                            text="Data Cadastro"
                            type="datetime-local"
                            name="createdAt"
                            placeholder="última atualização"
                            title="Data de inserção do cadastro no sistema"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={(dados.createdAt && retornaDataHora(dados.createdAt)) || retornaDataHora(moment())}
                            readonly={true}
                        />
                        <Input
                            text="Data Atualização"
                            type="datetime-local"
                            name="updatedAt"
                            placeholder="última atualização"
                            title="Data da última atualização dos dados"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={(dados.updatedAt && retornaDataHora(dados.updatedAt)) || retornaDataHora(moment())}
                            readonly={true}
                        />
                        <Checkbox
                            title="Ativa ou Inativa o produto"
                            text="Ativo"
                            name="ativo"
                            type="checkbox"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.ativo}
                            checked={dados.ativo === true ? "checked" : ""}
                        />
                        <Checkbox
                            title="Informa que o produto utiliza a rotina de compras"
                            text="Compra"
                            name="compra"
                            type="checkbox"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.compra}
                            checked={dados.compra === true ? "checked" : ""}
                        />
                        <Checkbox
                            title="Informa que o produto utiliza a rotina de vendas"
                            text="Venda"
                            name="venda"
                            type="checkbox"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.venda}
                            checked={dados.venda === true ? "checked" : ""}
                        />
                        <Checkbox
                            title="Informa que o produto pode ser locado"
                            text="Locavel"
                            name="locavel"
                            type="checkbox"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.locavel}
                            checked={dados.locavel === true ? "checked" : ""}
                        />
                    </div>
                    <div>
                        <Input
                            text="Código"
                            type="text"
                            name="codigo"
                            placeholder="Código"
                            title="O sistema vai gerar um código para o produto"
                            minlength={14}
                            maxlength={18}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.codigo || ""} //posso colocar para gerar um código
                            readonly={true}
                            inputWidth={"150px"}
                        />
                        <Input
                            text="Descrição"
                            type="text"
                            name="descricao"
                            placeholder="Descrição"
                            title="digite a descrição do produto"
                            inputWidth="350px"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.descricao || ""}
                            readonly={false}
                        />
                        <Select
                            name="tipo"
                            text="Tipo"
                            title="Determina o tipo do produto"
                            options={EnumTipo}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.tipo}
                            isEnum={true}
                        />
                    </div>
                    <div>
                        <Input
                            text="referência"
                            type="text"
                            name="referencia"
                            placeholder="Código de referência"
                            title="Digite o código de referência do produto"
                            minlength={14}
                            maxlength={18}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.referencia || ""}
                            inputWidth={"150px"}
                        />
                        <Input
                            text="Código Fabricante"
                            type="text"
                            name="codigoFabricante"
                            placeholder="Código Fabricante"
                            title="Digite o código do fabricante do produto"
                            minlength={14}
                            maxlength={18}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.codigoFabricante || ""}
                            inputWidth={"150px"}
                        />
                        <DataList
                            text="Fabricante"
                            title={"Vincula o Fabricante ao produto"}
                            name="Fabricante.descricao"
                            inputWidth="350px"
                            filteredOptions={OptionsFabricante}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={Fabricante.descricao || ""}
                        />
                    </div>
                    <div>
                        <Input
                            text="NCM"
                            type="text"
                            name="NCM.ncm"
                            placeholder="NCM"
                            inputWidth="150px"
                            title="Código do ncm do produto"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={NCM?.ncm}
                            readonly={true}
                        />
                        <DataList
                            text="NCM Descrição"
                            title={"Vincula o NCM ao produto"}
                            name="NCM.descricao"
                            placeholder={"Digite mais de 3 caracteres para pesquisar"}
                            inputWidth="510px"
                            filteredOptions={OptionsNCM}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={NCM?.descricao}
                            required={true}
                        />
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.stiloPadrao}>
                        <h4>Informações relevantes no processo de industrialização do produto</h4>
                        <div>
                            <InputFloat
                                text="Peso Molecular"
                                type="text"
                                name="pesoMolecular"
                                placeholder="peso molecular"
                                inputWidth="160px"
                                title="digite o peso molecular do produto, utilize como separador o ponto (.)) ex: 0.000000"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.pesoMolecular || ""} //formatação para 6 digitos com sepador de ponto
                                textAlign={"right"}
                            />
                            <InputNumber
                                text="Tolerância mínima"
                                type="text"
                                name="toleranciaMinima"
                                placeholder="peso em gramas"
                                inputWidth="160px"
                                title="digite a tolerância mínima em razão do peso molecular"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.toleranciaMinima ? formatarNumero(dados.toleranciaMinima) : ""}
                            />
                            <InputNumber
                                text="Tolerância máxima"
                                type="text"
                                name="toleranciaMaxima"
                                placeholder="Tolerência máxima"
                                inputWidth="160px"
                                title="digite a tolerância máxima em razão do peso molecular"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.toleranciaMaxima ? formatarNumero(dados.toleranciaMaxima) : ""}
                            />
                            <InputNumber
                                text="Produção Mínima"
                                type="text"
                                name="producaoMinima"
                                placeholder="peso em gramas"
                                inputWidth="160px"
                                title="valor mínimo de produção do produto"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.producaoMinima ? formatarNumero(dados.producaoMinima) : ""}
                            />
                        </div>
                        <div>
                            <Checkbox
                                title="Informa que é uma matéria prima, para composição de outros produtos"
                                text="Matéria Prima"
                                name="materiaPrima"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.materiaPrima}
                                checked={dados.materiaPrima === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Informa que é uma embalagem"
                                text="Embalagem"
                                name="embalagem"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.embalagem}
                                checked={dados.embalagem === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Informa que o produto é laminado"
                                text="Laminado"
                                name="laminado"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.laminado}
                                checked={dados.laminado === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Informa que o produto é sanfonado"
                                text="Sanfonado"
                                name="sanfonado"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.sanfonado}
                                checked={dados.sanfonado === true ? "checked" : ""}
                            />
                        </div>
                        <div>
                            <Checkbox
                                title="Informa que o produto utiliza a rotina de separação"
                                text="Separação"
                                name="separacao"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.separacao}
                                checked={dados.separacao === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Informa que o produto gera refugo"
                                text="Refugo"
                                name="refugo"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.refugo}
                                checked={dados.refugo === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Informa que o produto utiliza a rotina de lavagem"
                                text="Lavagem"
                                name="lavagem"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.lavagem}
                                checked={dados.lavagem === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Informa que o produto utiliza a rotina de produção"
                                text="Produção"
                                name="producao"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.producao}
                                checked={dados.producao === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Informa que o produto utiliza a rotina de extrusão"
                                text="Extrusão"
                                name="extrusao"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.extrusao}
                                checked={dados.extrusao === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Informa que o prcesso de produção do produto finaliza na extrusão"
                                text="Finaliza Extrusão"
                                name="finalizaExtrusora"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.finalizaExtrusora}
                                checked={dados.finalizaExtrusora === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Informa que o produto utiliza da Recuperadora"
                                text="Recuperadora"
                                name="recuperadora"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.recuperadora}
                                checked={dados.recuperadora === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Informa que o produto utiliza da corrugadora"
                                text="Corrugadora"
                                name="corrugadora"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.corrugadora}
                                checked={dados.corrugadora === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Informa que o produto utiliza a rotina da impressora"
                                text="Impressão"
                                name="impressao"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.impressao}
                                checked={dados.impressao === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Informa que o produto utiliza a rotina da corte e solda"
                                text="Corte Solda"
                                name="corteSolda"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={dados.corteSolda}
                                checked={dados.corteSolda === true ? "checked" : ""}
                            />
                        </div>
                        <div>
                            <DataList
                                text="Bobina"
                                title={"Vincula a bobina ao produto"}
                                name="Bobina.descricao"
                                inputWidth="350px"
                                filteredOptions={OptionsBobina}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={Bobina.descricao || ""}
                            />
                            <DataList
                                text="Cilindro"
                                title={"Vincula o cilindro ao produto"}
                                name="Cilindro.descricao"
                                inputWidth="350px"
                                filteredOptions={OptionsCilindro}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={Cilindro.descricao || ""}
                            />
                        </div>
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    //marginTop: "1rem",
                                    flexWrap: "wrap", // Permite que os itens quebrem a linha quando necessário
                                    // justifyContent: "space-between", // Distribui os itens igualmente
                                }}
                            >
                                <InputNumber
                                    text="Ordem"
                                    type="text"
                                    name="novaCor.ordem"
                                    placeholder="Ordem"
                                    inputWidth="50px"
                                    title="digite a ordem da cor"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novaCor.ordem || ""}
                                    textAlign="center"
                                />
                                <DataList
                                    text="Cor"
                                    title={"Vincula a cor para a produção do produto"}
                                    name="novaCor.Cor.descricao"
                                    inputWidth="350px"
                                    filteredOptions={OptionsCores}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={Cor.descricao || ""}
                                />
                                <DataList
                                    text="Anilox"
                                    title={"Vincula o anilox a cor utilizada no cilindro para produção do produto"}
                                    name="novaCor.Anilox.descricao"
                                    inputWidth="350px"
                                    filteredOptions={OptionsAnilox}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={Anilox?.descricao || null}
                                />
                                <Checkbox
                                    title="Ativa a cor"
                                    text="Ativo"
                                    name="novaCor.ativo"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novaCor.ativo}
                                    checked={novaCor.ativo === true ? "checked" : ""}
                                />
                                <Button
                                    variant="contained"
                                    color="inherit"
                                    onClick={() => limparCor()}
                                    style={{ marginLeft: "10px" }}
                                >
                                    Limpar
                                </Button>
                                <Button
                                    variant="contained"
                                    color={novaCor.funcao.toLocaleLowerCase() === "deletar" ? "error" : "info"}
                                    onClick={() => handleCor(novaCor)}
                                    disabled={!novaCor.ordem || !novaCor.corId}
                                    style={{ marginLeft: "5px" }}
                                >
                                    {novaCor.funcao}
                                </Button>
                            </div>
                            <ThemeProvider theme={theme}>
                                <MaterialReactTable
                                    displayColumnDefOptions={{
                                        "mrt-row-actions": {
                                            muiTableHeadCellProps: {
                                                align: "center",
                                            },
                                            size: 100,
                                        },
                                        "mrt-row-numbers": {
                                            enableColumnOrdering: true, //turn on some features that are usually off
                                            enableResizing: true,
                                            muiTableHeadCellProps: {
                                                sx: {
                                                    fontSize: "1.2rem",
                                                },
                                            },
                                        },
                                        "mrt-row-select": {
                                            enableColumnActions: false,
                                            enableHiding: true,
                                            size: 50,
                                        },
                                    }}
                                    enableColumnFilterModes
                                    enableGlobalFilterModes
                                    //enableColumnResizing
                                    enableColumnOrdering
                                    enableEditing
                                    renderRowActions={({ row, table }) => (
                                        <Box sx={{ display: "flex", gap: "1rem" }}>
                                            <Tooltip arrow placement="top" title="Editar">
                                                <span>
                                                    {" "}
                                                    <IconButton
                                                        onClick={() => {
                                                            handleEditarOrDeletarCor(row.original, row.index, "Editar");
                                                        }}
                                                        disabled={localStorage.getItem("editar") === "true" ? false : true}
                                                    >
                                                        <Edit />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                            <Tooltip arrow placement="right" title="Deletar">
                                                <span>
                                                    <IconButton
                                                        color="error"
                                                        onClick={() => handleEditarOrDeletarCor(row.original, row.id, "Deletar")}
                                                        disabled={localStorage.getItem("cancelar") === "true" ? false : true}
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </Box>
                                    )}
                                    renderTopToolbarCustomActions={({ table }) => {
                                        const handleImprimir = async (table) => {};

                                        //const handleReaproveitarArquivoNF = async () => {};
                                        return (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "8px",
                                                }}
                                            >
                                                {/*
                                                <Tooltip arrow placement="top-end" title={`Imprimir`}>
                                                    <div>
                                                        {" "}
                                                        <Button
                                                            color="info"
                                                            onClick={handleImprimir}
                                                            variant="contained"
                                                            disabled={JSON.parse(localStorage.getItem("imprimir")) === false}
                                                        >
                                                            Imprimir
                                                        </Button>
                                                    </div>
                                                </Tooltip>
                                                <Tooltip
                                                arrow
                                                placement="top-end"
                                                title={`Reaproveitar arquivo de nota fiscal para vários itens selecionados, primeiro copie o nome do arquivo na coluna PDF, depois selecione os itens da mesma nota, em seguida click no botão Arquivo NF e depois cole no campo descrição`}
                                            >
                                                <div>
                                                    <Button
                                                        color="success"
                                                        variant="contained"
                                                        disabled={
                                                            JSON.parse(localStorage.getItem("editar")) === false ||
                                                            (!table.getIsSomeRowsSelected() && !table.getIsAllPageRowsSelected())
                                                        }
                                                        onClick={
                                                            !table.getIsSomeRowsSelected() && !table.getIsAllPageRowsSelected()
                                                                ? null
                                                                : handleReaproveitarArquivoNF
                                                        }
                                                    >
                                                        Arquivo NF
                                                    </Button>
                                                </div>
                                            </Tooltip> */}
                                            </div>
                                        );
                                    }}
                                    columns={columnsCores}
                                    data={produtoCores}
                                    rowCount={rowCountCor}
                                    enableRowSelection
                                    getRowId={(row) => row.uuid}
                                    manualFiltering
                                    manualPagination
                                    manualSorting
                                    muiToolbarAlertBannerProps={
                                        isError
                                            ? {
                                                  color: "error",
                                                  children: "Error loading data",
                                              }
                                            : undefined
                                    }
                                    onColumnFiltersChange={setColumnFilters}
                                    onColumnFilterFnsChange={setColumnFiltersMode}
                                    onGlobalFilterChange={setGlobalFilter}
                                    onPaginationChange={setPagination}
                                    onSortingChange={setSorting}
                                    initialState={{
                                        showColumnFilters: true,
                                        density: "compact",
                                        //columnPinning: { left: ["mrt-row-select"] },
                                    }}
                                    state={{
                                        columnFilterFns,
                                        columnFilters,
                                        globalFilter,
                                        isLoading,
                                        pagination,
                                        showAlertBanner: isError,
                                        showProgressBars: isRefetching,
                                        sorting,
                                    }}
                                    //positionToolbarDropZone={"top"}
                                    positionExpandColumn={"first"}
                                    positionActionsColumn={"first"}
                                    positionToolbarAlertBanner="bottom"
                                    localization={MRT_Localization_PT_BR}
                                />
                            </ThemeProvider>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.stiloPadrao}>
                        <h4>Informações da composição produto</h4>
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    //marginTop: "1rem",
                                    flexWrap: "wrap", // Permite que os itens quebrem a linha quando necessário
                                    // justifyContent: "space-between", // Distribui os itens igualmente
                                }}
                            >
                                <DataList
                                    text="Matéria Prima"
                                    title={"Compõe o produto"}
                                    placeholder={"matéria prima do produto"}
                                    name="novaMateriaPrima.materiaPrima.descricao"
                                    inputWidth="400px"
                                    filteredOptions={OptionsMateriaPrima}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={materiaPrima.descricao || ""}
                                />
                                <InputNumber
                                    text="Percentual"
                                    type="text"
                                    name="novaMateriaPrima.percentual"
                                    placeholder="perc %"
                                    inputWidth="80px"
                                    title="digite o percentual aplicado"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novaMateriaPrima.percentual ? formatarNumero(novaMateriaPrima.percentual) : ""}
                                    textAlign="center"
                                />
                                <Checkbox
                                    title="Ativa a cor"
                                    text="Ativo"
                                    name="novaMateriaPrima.ativo"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novaMateriaPrima.ativo}
                                    checked={novaMateriaPrima.ativo === true ? "checked" : ""}
                                />
                                <Button
                                    variant="contained"
                                    color="inherit"
                                    onClick={() => limparMateriaPrima()}
                                    style={{ marginLeft: "10px" }}
                                >
                                    Limpar
                                </Button>
                                <Button
                                    variant="contained"
                                    color={novaMateriaPrima.funcao.toLocaleLowerCase() === "deletar" ? "error" : "info"}
                                    onClick={() => handleMateriaPrima(novaMateriaPrima)}
                                    disabled={!materiaPrima.descricao || !novaMateriaPrima.percentual}
                                    style={{ marginLeft: "5px" }}
                                >
                                    {novaMateriaPrima.funcao}
                                </Button>
                            </div>
                            <ThemeProvider theme={theme}>
                                <MaterialReactTable
                                    displayColumnDefOptions={{
                                        "mrt-row-actions": {
                                            muiTableHeadCellProps: {
                                                align: "center",
                                            },
                                            size: 100,
                                        },
                                        "mrt-row-numbers": {
                                            enableColumnOrdering: true, //turn on some features that are usually off
                                            enableResizing: true,
                                            muiTableHeadCellProps: {
                                                sx: {
                                                    fontSize: "1.2rem",
                                                },
                                            },
                                        },
                                        "mrt-row-select": {
                                            enableColumnActions: false,
                                            enableHiding: true,
                                            size: 50,
                                        },
                                    }}
                                    enableColumnFilterModes
                                    enableGlobalFilterModes
                                    //enableColumnResizing
                                    enableColumnOrdering
                                    enableEditing
                                    renderRowActions={({ row, table }) => (
                                        <Box sx={{ display: "flex", gap: "1rem" }}>
                                            <Tooltip arrow placement="top" title="Editar">
                                                <span>
                                                    {" "}
                                                    <IconButton
                                                        onClick={() => {
                                                            handleEditarDeletarMateriaPrima(row.original, row.index, "Editar");
                                                        }}
                                                        disabled={localStorage.getItem("editar") === "true" ? false : true}
                                                    >
                                                        <Edit />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                            <Tooltip arrow placement="right" title="Deletar">
                                                <span>
                                                    <IconButton
                                                        color="error"
                                                        onClick={() =>
                                                            handleEditarDeletarMateriaPrima(row.original, row.id, "Deletar")
                                                        }
                                                        disabled={localStorage.getItem("cancelar") === "true" ? false : true}
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </Box>
                                    )}
                                    renderTopToolbarCustomActions={({ table }) => {
                                        const handleImprimir = async (table) => {};

                                        //const handleReaproveitarArquivoNF = async () => {};
                                        return (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "8px",
                                                }}
                                            >
                                                {/*
                                                <Tooltip arrow placement="top-end" title={`Imprimir`}>
                                                    <div>
                                                        {" "}
                                                        <Button
                                                            color="info"
                                                            onClick={handleImprimir}
                                                            variant="contained"
                                                            disabled={JSON.parse(localStorage.getItem("imprimir")) === false}
                                                        >
                                                            Imprimir
                                                        </Button>
                                                    </div>
                                                </Tooltip>
                                                <Tooltip
                                                arrow
                                                placement="top-end"
                                                title={`Reaproveitar arquivo de nota fiscal para vários itens selecionados, primeiro copie o nome do arquivo na coluna PDF, depois selecione os itens da mesma nota, em seguida click no botão Arquivo NF e depois cole no campo descrição`}
                                            >
                                                <div>
                                                    <Button
                                                        color="success"
                                                        variant="contained"
                                                        disabled={
                                                            JSON.parse(localStorage.getItem("editar")) === false ||
                                                            (!table.getIsSomeRowsSelected() && !table.getIsAllPageRowsSelected())
                                                        }
                                                        onClick={
                                                            !table.getIsSomeRowsSelected() && !table.getIsAllPageRowsSelected()
                                                                ? null
                                                                : handleReaproveitarArquivoNF
                                                        }
                                                    >
                                                        Arquivo NF
                                                    </Button>
                                                </div>
                                            </Tooltip> */}
                                            </div>
                                        );
                                    }}
                                    columns={columnsProdutoComposicao}
                                    data={produtoComposicoes}
                                    rowCount={rowCountComposicoes}
                                    enableRowSelection
                                    getRowId={(row) => row.uuid}
                                    manualFiltering
                                    manualPagination
                                    manualSorting
                                    muiToolbarAlertBannerProps={
                                        isError
                                            ? {
                                                  color: "error",
                                                  children: "Error loading data",
                                              }
                                            : undefined
                                    }
                                    onColumnFiltersChange={setColumnFilters}
                                    onColumnFilterFnsChange={setColumnFiltersMode}
                                    onGlobalFilterChange={setGlobalFilter}
                                    onPaginationChange={setPagination}
                                    onSortingChange={setSorting}
                                    initialState={{
                                        showColumnFilters: true,
                                        density: "compact",
                                        //columnPinning: { left: ["mrt-row-select"] },
                                    }}
                                    state={{
                                        columnFilterFns,
                                        columnFilters,
                                        globalFilter,
                                        isLoading,
                                        pagination,
                                        showAlertBanner: isError,
                                        showProgressBars: isRefetching,
                                        sorting,
                                    }}
                                    //positionToolbarDropZone={"top"}
                                    positionExpandColumn={"first"}
                                    positionActionsColumn={"first"}
                                    positionToolbarAlertBanner="bottom"
                                    localization={MRT_Localization_PT_BR}
                                />
                            </ThemeProvider>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.preview_bandeira}>
                        <h4>Embalagens do produto</h4>
                    </div>
                    <div>
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "1rem",
                                }}
                            >
                                <SelectEmpresa
                                    name="novaEmbalagem.empresaId"
                                    text="Empresa"
                                    options={OptionsEmpresa}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novaEmbalagem.empresaId}
                                    isEnum={false}
                                    notOptNull={true}
                                />
                                <Select
                                    name="novaEmbalagem.embalagemId"
                                    text="Embalagem"
                                    options={OptionsEmbalagem}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novaEmbalagem.embalagemId}
                                    isEnum={false}
                                    notOptNull={true}
                                />
                                <Input
                                    text="Código de Barras"
                                    type="text"
                                    name="novaEmbalagem.codigoBarras"
                                    placeholder="Código de Barras"
                                    title="digite o código de barras do produto"
                                    inputWidth="200px"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novaEmbalagem.codigoBarras || ""}
                                    readonly={false}
                                />
                                <Checkbox
                                    title="Ativo quando marcado"
                                    text="Ativo"
                                    name="novaEmbalagem.ativo"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novaEmbalagem.ativo}
                                    checked={novaEmbalagem.ativo === true ? "checked" : ""}
                                />{" "}
                                <Button
                                    variant="contained"
                                    color="inherit"
                                    onClick={() =>
                                        setNovaEmbalagem({
                                            uuid: "",
                                            empresaId: dados.empresaId,
                                            produtoId: dados.id,
                                            embalagemId: "",
                                            codigoBarras: "",
                                            ativo: true,
                                            funcao: "Adicionar",
                                        })
                                    }
                                    style={{ marginLeft: "10px" }}
                                >
                                    Limpar
                                </Button>
                                <Button
                                    variant="contained"
                                    color={novaEmbalagem.funcao.toLocaleLowerCase() === "deletar" ? "error" : "info"}
                                    onClick={() => handleEmbalagem(novaEmbalagem)}
                                    disabled={novaEmbalagem.codigoBarras?.length < 1 ? true : false}
                                    style={{ marginLeft: "5px" }}
                                >
                                    {novaEmbalagem.funcao}
                                </Button>
                            </div>
                        </div>
                        <ThemeProvider theme={theme}>
                            <MaterialReactTable
                                displayColumnDefOptions={{
                                    "mrt-row-actions": {
                                        muiTableHeadCellProps: {
                                            align: "center",
                                        },
                                        size: 100,
                                    },
                                    "mrt-row-numbers": {
                                        enableColumnOrdering: true, //turn on some features that are usually off
                                        enableResizing: true,
                                        muiTableHeadCellProps: {
                                            sx: {
                                                fontSize: "1.2rem",
                                            },
                                        },
                                    },
                                    "mrt-row-select": {
                                        enableColumnActions: false,
                                        enableHiding: true,
                                        size: 50,
                                    },
                                }}
                                enableColumnFilterModes
                                enableGlobalFilterModes
                                //enableColumnResizing
                                enableColumnOrdering
                                enableEditing
                                renderRowActions={({ row, table }) => (
                                    <Box sx={{ display: "flex", gap: "1rem" }}>
                                        <Tooltip arrow placement="left" title="Editar">
                                            <span>
                                                {" "}
                                                <IconButton
                                                    onClick={() => {
                                                        handleEditarOrDeletarEmbalagem(row.original, row.index, "Editar");
                                                    }}
                                                    disabled={localStorage.getItem("editar") === "true" ? false : true}
                                                >
                                                    <Edit />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                        <Tooltip arrow placement="right" title="Deletar">
                                            <span>
                                                <IconButton
                                                    color="error"
                                                    //onClick={() => handleOpenDialog(row.id)}
                                                    onClick={() =>
                                                        handleEditarOrDeletarEmbalagem(row.original, row.id, "Deletar")
                                                    }
                                                    disabled={localStorage.getItem("cancelar") === "true" ? false : true}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    </Box>
                                )}
                                renderTopToolbarCustomActions={({ table }) => {
                                    const handleImprimir = async (table) => {};

                                    //const handleReaproveitarArquivoNF = async () => {};
                                    return (
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "8px",
                                            }}
                                        >
                                            <Tooltip arrow placement="top-end" title={`Imprimir`}>
                                                <div>
                                                    {" "}
                                                    <Button
                                                        color="info"
                                                        onClick={handleImprimir}
                                                        variant="contained"
                                                        disabled={JSON.parse(localStorage.getItem("imprimir")) === false}
                                                    >
                                                        Imprimir
                                                    </Button>
                                                </div>
                                            </Tooltip>
                                            {/* <Tooltip
                                                arrow
                                                placement="top-end"
                                                title={`Reaproveitar arquivo de nota fiscal para vários itens selecionados, primeiro copie o nome do arquivo na coluna PDF, depois selecione os itens da mesma nota, em seguida click no botão Arquivo NF e depois cole no campo descrição`}
                                            >
                                                <div>
                                                    <Button
                                                        color="success"
                                                        variant="contained"
                                                        disabled={
                                                            JSON.parse(localStorage.getItem("editar")) === false ||
                                                            (!table.getIsSomeRowsSelected() && !table.getIsAllPageRowsSelected())
                                                        }
                                                        onClick={
                                                            !table.getIsSomeRowsSelected() && !table.getIsAllPageRowsSelected()
                                                                ? null
                                                                : handleReaproveitarArquivoNF
                                                        }
                                                    >
                                                        Arquivo NF
                                                    </Button>
                                                </div>
                                            </Tooltip> */}
                                        </div>
                                    );
                                }}
                                columns={columns}
                                data={itensEmbalagens}
                                enableRowSelection
                                getRowId={(row) => row.uuid}
                                manualFiltering
                                manualPagination
                                manualSorting
                                muiToolbarAlertBannerProps={
                                    isError
                                        ? {
                                              color: "error",
                                              children: "Error loading data",
                                          }
                                        : undefined
                                }
                                onColumnFiltersChange={setColumnFilters}
                                onColumnFilterFnsChange={setColumnFiltersMode}
                                onGlobalFilterChange={setGlobalFilter}
                                onPaginationChange={setPagination}
                                onSortingChange={setSorting}
                                rowCount={rowCount}
                                initialState={{
                                    showColumnFilters: true,
                                    density: "compact",
                                    //columnPinning: { left: ["mrt-row-select"] },
                                }}
                                state={{
                                    columnFilterFns,
                                    columnFilters,
                                    globalFilter,
                                    isLoading,
                                    pagination,
                                    showAlertBanner: isError,
                                    showProgressBars: isRefetching,
                                    sorting,
                                }}
                                //positionToolbarDropZone={"top"}
                                positionExpandColumn={"first"}
                                positionActionsColumn={"first"}
                                positionToolbarAlertBanner="bottom"
                                localization={MRT_Localization_PT_BR}
                            />
                        </ThemeProvider>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.preview_bandeira}>
                        <h4>Informações do produto</h4>
                    </div>
                    <div>
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "1rem",
                                }}
                            >
                                <InputNumber
                                    text="Peso Bruto"
                                    type="text"
                                    name="pesoBruto"
                                    placeholder="peso em g"
                                    inputWidth="100px"
                                    title="digite o peso bruto do produto em gramas"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.pesoBruto ? formatarNumero(dados.pesoBruto) : ""}
                                />
                                <InputNumber
                                    text="Peso Líquido"
                                    type="text"
                                    name="pesoLiquido"
                                    placeholder="peso em g"
                                    inputWidth="100px"
                                    title="digite o peso líquido do produto em gramas"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.pesoLiquido ? formatarNumero(dados.pesoLiquido) : ""}
                                />
                                <InputNumber
                                    text="Estoque Mínimo"
                                    type="text"
                                    name="estoqueMinimo"
                                    placeholder="Estoque mínimo operacional"
                                    inputWidth="100px"
                                    title="digite o valor do estoque mínimo do produto"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.estoqueMinimo ? formatarNumero(dados.estoqueMinimo) : ""}
                                />
                                <InputNumber
                                    text="Largura cm"
                                    type="text"
                                    name="largura"
                                    placeholder="cm"
                                    inputWidth="100px"
                                    title="digite a largura em centímetros"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.largura ? formatarNumero(dados.largura) : ""}
                                />
                                <InputNumber
                                    text="Altura cm"
                                    type="text"
                                    name="altura"
                                    placeholder="cm"
                                    inputWidth="100px"
                                    title="digite a altura em centímetros"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.altura ? formatarNumero(dados.altura) : ""}
                                />
                                <InputNumber
                                    text="Comprimento cm"
                                    type="text"
                                    name="comprimento"
                                    placeholder="cm"
                                    inputWidth="100px"
                                    title="digite a comprimento em centímetros"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.comprimento ? formatarNumero(dados.comprimento) : ""}
                                />
                                <InputNumber
                                    text="Volume m³"
                                    type="text"
                                    name="volume"
                                    placeholder="m³"
                                    inputWidth="100px"
                                    title="volume em metros cúbicos m³"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.volume ? dados.volume : ""}
                                    readonly={true}
                                />
                            </div>
                            <div>
                                <InputNumber
                                    text="Comissão"
                                    type="text"
                                    name="comissao"
                                    placeholder="Comissão do produto no movimento"
                                    inputWidth="100px"
                                    title="digite o valor do comissão do produto"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.comissao ? formatarNumero(dados.comissao) : ""}
                                />
                            </div>
                            <div>
                                <Select
                                    name="almoxarifadoId"
                                    title="Almoxarifado padrão para o produto"
                                    text="Almoxarifado"
                                    inputWidth="200px"
                                    options={OptionsAlmoxarifados}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.almoxarifadoId}
                                    isEnum={false}
                                    notOptNull={false}
                                />
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.stiloPadrao}>
                        <h4>Bem</h4>
                        <div>
                            <Input
                                text="Patrimônio"
                                type="text"
                                name="produtoBens.patrimonio"
                                placeholder=""
                                title="Número do patrimônio"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={produtoBens.patrimonio || ""}
                                inputWidth={"150px"}
                            />
                            <Input
                                text="Descrição do BEM"
                                type="text"
                                name="produtoBens.descricao"
                                placeholder="Descrição"
                                title="digite a descrição do BEM"
                                inputWidth="350px"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={produtoBens.descricao || ""}
                                readonly={false}
                            />
                            <Select
                                name="produtoBens.tipo"
                                text="Tipo"
                                title="Determina o tipo do produto"
                                options={EnumBemTipo}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={produtoBens.tipo}
                                isEnum={true}
                            />
                            <Checkbox
                                title="Ativa ou Inativa o BEM"
                                text="Ativo"
                                name="produtoBens.ativo"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={produtoBens.ativo}
                                checked={produtoBens.ativo === true ? "checked" : ""}
                            />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.preview_bandeira}>
                        <h4>Imagens do Produto</h4>
                    </div>
                    <div className={formStyles.preview_bandeira}>
                        <div>
                            <Input
                                text="Fotos do Produto"
                                type="file"
                                name="images"
                                handleOnChange={onFileChange}
                                inputWidth="400px"
                                multiple={true}
                                accept=".jpg, .jpeg, .png"
                            />
                        </div>
                        {preview.length > 0
                            ? preview.map((image, index) => (
                                  <img
                                      src={URL.createObjectURL(image)}
                                      alt={dados.images}
                                      key={`${dados.images}+${index}`}
                                      style={{
                                          borderColor: "black",
                                          border: "solid 0.05em",
                                      }}
                                      width={60}
                                      height={80}
                                  />
                              ))
                            : produtoImagems &&
                              produtoImagems.map((imagem, index) => (
                                  <img
                                      src={process.env.REACT_APP_API + "imagens/" + imagem.descricao}
                                      alt={`Imagem ${index + 1}`}
                                      key={imagem.uuid || index}
                                      title={process.env.REACT_APP_API + "imagens/" + imagem.descricao}
                                      style={{
                                          borderColor: "black",
                                          border: "solid 0.05em",
                                          margin: "2px",
                                      }}
                                      loading="lazy"
                                      width={60}
                                      height={80}
                                  />
                              ))}
                    </div>
                </TabPanel>
                <div>
                    <Stack direction="row" spacing={1}>
                        <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                            Voltar
                        </Button>
                        <Button type="submit" variant="contained" color="success">
                            {btnText}
                        </Button>
                    </Stack>
                </div>
            </form>
        </Tabs>
    );
}

export default Form;
