import api from ".";

export const retornaEquipesPorTipo = async (empresaId, tipo, ativo) => {
    try {
        const { data } = await api.get("/serviceEquipePorTipo", {
            params: { empresaId, tipo, ativo },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};

export const integrantes = async (novoColaborador) => api.post(`equipe/integrantes`, novoColaborador);

export const retornaIntegrantes = async (equipeId) => {
    try {
        const { data } = await api.get("equipe/retornaIntegrantes", {
            params: { equipeId },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};
