import { useState, useEffect } from "react";
import "react-tabs/style/react-tabs.css";
import "moment/locale/pt-br";

import { Stack, Button } from "@mui/material";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import * as TabelaAPI from "../../../api/tabela";

import TextArea from "../../form/TextArea";
import Checkbox from "../../form/Checkbox";
import Select from "../../form/Select";

/* utils */
import { formatarNumero, formatarNumeroDecimal } from "../../../utils/masks";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, OptionsEmpresa }) {
    const [dados, setDados] = useState({});
    const [menus, setMenus] = useState([]);
    const [menuSubs, setMenuSub] = useState([]);
    const [enumRotina, setEnumRotina] = useState([]);

    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();

    //define o estado inicial do objeto
    if (!dados.ativo) {
        dados.ativo = true;
    }

    const consultaMenu = async () => {
        const result = await TabelaAPI.menu("");
        setMenus(result);
    };
    const consultaMenuSub = async (menuId, descricao) => {
        const result = await TabelaAPI.menuSub(menuId, descricao);
        setMenuSub(result);
    };
    const consultaEnum = async (table, field, filter) => {
        const result = await TabelaAPI.enums(table, field, filter);
        if (table === "Ajuda") {
            field === "rotina" && setEnumRotina(result ? result : []);
        }
    };

    useEffect(() => {
        setDados(formData || {});

        consultaMenu(formData?.Menu?.descricao);
        consultaEnum("Ajuda", "rotina", null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    useEffect(() => {
        dados.menuId && consultaMenuSub(dados.menuId);
    }, [dados.menuId]);

    function handleChange(e) {
        if (e.target.name === "ativo") {
            setDados({ ...dados, [e.target.name]: e.target.checked });
        } else if (e.target.name === "linha" || e.target.name === "bcm") {
            const valorFormatado = formatarNumeroDecimal(e.target.value);
            setDados({
                ...dados,
                [e.target.name]: valorFormatado,
            });
        } else {
            setDados({ ...dados, [e.target.name]: e.target.value });
        }
    }

    async function handleOnBlur(e) {
        //verifica duplicidade
        if (e.target.name === "descricao" && btnText === "Cadastrar") {
            const duplicidade = await TabelaAPI.duplicidadeDescricao(
                document.getElementById("empresaId").value,
                "Ajuda",
                e.target.value
            );

            if (duplicidade?.uuid) {
                setFlashMessage("Registro em duplicidade!", "warning");
                return navigate(`/Ajuda/CreateOrEdit`, {
                    uuid: duplicidade.uuid,
                });
            }
        }
    }
    function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();

        handleSubmit(dados);
    };

    return (
        <form onSubmit={submit}>
            <h1>AJUDA</h1>
            <div>
                <Select
                    text="Selecione o Menu"
                    name="menuId"
                    options={menus}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.menuId || ""}
                    isEnum={false}
                />
                <Select
                    text="Selecione o SubMenu"
                    name="menuSubId"
                    options={menuSubs}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.menuSubId || ""}
                    isEnum={false}
                />
                <Select
                    text="Selecione a rotina"
                    name="rotina"
                    options={enumRotina}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.rotina || ""}
                    isEnum={true}
                    notOptNull={true}
                />
                <Checkbox
                    title="Ativa quando marcado"
                    text="Ativo"
                    name="ativo"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.ativo}
                    checked={dados.ativo === true ? "checked" : ""}
                />
            </div>
            <div>
                <TextArea
                    text="Texto"
                    type="text"
                    name="texto"
                    placeholder="Digite aqui o texto de ajuda ao usuário"
                    title="Digite o texto"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.texto || ""}
                    textAlign="left"
                    linhas="20"
                    colunas="120"
                />
            </div>
            <div>
                <Stack direction="row" spacing={1}>
                    <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                        Voltar
                    </Button>
                    <Button type="submit" variant="contained" color="success">
                        {btnText}
                    </Button>
                </Stack>
            </div>
        </form>
    );
}
export default Form;
