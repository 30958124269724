import { useContext } from "react";
import { Context } from "../../context/UserContext";
import styles from "./Input.module.css";

function Input({
    type,
    text,
    name,
    placeholder,
    handleOnChange,
    handleOnBlur,
    handleOnFocus,
    value,
    title,
    required,
    multiple,
    inputWidth,
    readonly,
    min,
    max,
    textAlign = "right",
    handleOnInput,
}) {
    //configura o tema
    const { parametro } = useContext(Context);

    const validateInput = (e) => {
        let value = e.target.value;

        // Permite apenas números, vírgula, ponto e um único sinal de menos no início
        value = value.replace(/[^0-9.,-]/g, "");

        // Garante que o sinal de menos só apareça no início
        if (value.includes("-") && value.indexOf("-") > 0) {
            value = value.replace("-", ""); // Remove "-" se não for o primeiro caractere
        }

        // Atualiza o valor no input
        e.target.value = value;

        // Se houver uma função de callback para tratar a entrada, chama ela
        if (handleOnInput) {
            handleOnInput(e);
        }
    };

    return (
        <div className={`${styles.form_control} ${styles[parametro.tema]}`}>
            {type !== "hidden" && <label htmlFor={name}>{text}:</label>}

            <input
                type="text"
                name={name}
                id={name}
                placeholder={placeholder}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onFocus={handleOnFocus}
                value={value}
                title={title}
                required={required}
                style={{
                    width: `var(--input-width, ${inputWidth})`,
                    textAlign: textAlign,
                }}
                {...(multiple ? { multiple } : "")}
                readOnly={readonly === true ? true : false}
                pattern="[0-9.,]*"
                min={min}
                max={max}
                onInput={validateInput} // Adiciona validação de entrada
            />
        </div>
    );
}

export default Input;
