import { useState, useEffect } from "react";
import "react-tabs/style/react-tabs.css";
import "moment/locale/pt-br";

import { Stack, Button } from "@mui/material";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import * as DataListAPI from "../../../api/dataList";
import * as TabelaAPI from "../../../api/tabela";

import Input from "../../form/Input";
import InputNumber from "../../form/InputNumber";
import Checkbox from "../../form/Checkbox";
import SelectEmpresa from "../../form/SelectEmpresa";
import DataList from "../../form/DataList";

import { formatarNumero, formatarNumeroDecimal } from "../../../utils/masks";
import { retornaMes } from "../../../utils/retornaDataFormatada";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, OptionsEmpresa, OptionsCentroCustos }) {
    const [dados, setDados] = useState(formData || {});
    const [centroCusto, setCentroCusto] = useState(formData?.centroCusto || {});
    const [OptionsCC, setOptionsCC] = useState(OptionsCentroCustos || []);

    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();
    const urlParams = new URLSearchParams(window.location.search);
    const ul = urlParams.get("ul") || 3;
    const li = urlParams.get("li") || 37;
    const tipo = urlParams.get("tipo") || "Pagar";

    //para definir campos padrões
    if (dados.empresaId === undefined) {
        dados.empresaId = localStorage.getItem("empresaId");
        dados.ativo = true;
        dados.mes = retornaMes();
    }

    const consultaDatalist = async (token, empresaId, tabela, id, descricao, condicao, filtro) => {
        let result = await DataListAPI.dataList(empresaId, tabela, id, descricao, condicao, filtro);
        return result;
    };

    useEffect(() => {
        setDados(formData || {});
        setCentroCusto(formData?.centroCusto || {});
    }, [formData]);

    async function handleChange(e) {
        if (e.target.name === "ativo") {
            setDados({
                ...dados,
                [e.target.name]: e.target.checked,
            });
        } else if (e.target.name === "limite") {
            const valorFormatado = formatarNumeroDecimal(e.target.value);
            setDados({
                ...dados,
                [e.target.name]: valorFormatado,
            });
        } else if (e.target.name.startsWith("centroCusto.")) {
            setCentroCusto({
                ...centroCusto,
                [e.target.name.split(".")[1]]: e.target.value,
            });

            let result = await consultaDatalist(token, dados.empresaId, "centroCusto", "id", "descricao", tipo, e.target.value);
            setOptionsCC(result);
            let CC = "";

            //obtem o valor padrao do limite
            if (result[0]) {
                CC = await TabelaAPI.tabelaById(dados.empresaId, "centroCusto", result[0].id);
            }

            setDados((prevState) => ({
                ...prevState,
                centroCustoId: document.querySelector(`datalist option[value="${e.target.value}"]`)?.dataset.id,
                limite: CC.limitePadrao || "",
            }));
        } else {
            setDados({ ...dados, [e.target.name]: e.target.value });
        }
    }
    async function handleOnBlur(e) {
        //verifica duplicidade
        if (e.target.name === "centroCusto.descricao1" && btnText === "Cadastrar") {
            //verifica duplicidade
            const duplicidade = await TabelaAPI.duplicidadeCentroCustoLimite(dados.empresaId, dados.centroCustoId, dados.mes);

            if (duplicidade?.uuid) {
                setFlashMessage("Registro em duplicidade!", "warning");
                return navigate(`/CentroCustoLimites/CreateOrEdit`, {
                    uuid: duplicidade.uuid,
                    ul: ul,
                    li: li,
                    tipo: tipo,
                });
            }
        }
    }
    function handleOnFocus(e) {}
    const submit = (e) => {
        e.preventDefault();
        if (!dados.empresaId) {
            dados.empresaId = parseInt(document.getElementById("empresaId").value);
        }
        if (!dados.centroCustoId) {
            setFlashMessage("Centro de Custo vazio!", "error");
            return;
        }
        if (!dados.limite || dados.limite <= 0) {
            setFlashMessage("Limite do centro de custo inválido!", "error");
            return;
        }

        handleSubmit(dados);
    };

    return (
        <form onSubmit={submit}>
            <h1>Gestão de limites por centro de custos</h1>
            <div>
                <SelectEmpresa
                    text="Selecione a Empresa"
                    name="empresaId"
                    options={OptionsEmpresa}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.empresaId || ""}
                    required
                    notOptNull={true}
                />
                <DataList
                    text="Centro de Custo"
                    title={"Escolha o centro de custo"}
                    placeholder={"Digite para pesquisar"}
                    name="centroCusto.descricao"
                    inputWidth="310px"
                    filteredOptions={OptionsCC}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={centroCusto?.descricao || ""}
                    required={true}
                />
            </div>
            <div>
                <Input
                    text="Mês"
                    type="month"
                    name="mes"
                    title="Escolha o mês"
                    inputWidth="200px"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.mes || ""}
                    required
                />
                <InputNumber
                    text="Limite"
                    type="text"
                    name="limite"
                    placeholder="Limite em R$ "
                    inputWidth="100px"
                    title="digite o limite do centro de custo"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.limite ? formatarNumero(dados.limite) : ""}
                    required={true}
                />

                <Checkbox
                    title="habilita o uso do limite centro de custo"
                    text="Ativo"
                    name="ativo"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.ativo}
                    checked={dados.ativo === true ? "checked" : ""}
                />
            </div>
            <div>
                <Stack direction="row" spacing={1}>
                    <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                        Voltar
                    </Button>
                    <Button type="submit" variant="contained" color="success">
                        {btnText}
                    </Button>
                </Stack>
            </div>
        </form>
    );
}
export default Form;
