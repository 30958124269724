import React, { useEffect, useMemo, useState, useRef, useContext } from "react";

//Import Material React Table Translations
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";
import CustomDialog from "../../layout/CustomDialog";

import { parseISO, format, isValid } from "date-fns";

import { Context } from "../../../context/UserContext";

import { useDispatch } from "react-redux";
import { selectComponent, clearSelectedComponent } from "../../../store/reducers/actions";

import { useLocation } from "react-router-dom";
import { useNavigateParams } from "../../../hooks/useNavigateParams";
import { MaterialReactTable } from "material-react-table";

import { ThemeProvider } from "@mui/material";
/* tema customizado*/
import tableThemeCustom from "../../form/tableThemeCustom";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";

/* utils */
import { obterPrimeiroEUltimoDiaDoMes, obterInicioEFimDoMes } from "../../../utils/financeiro";

import { Box, Button, IconButton, Tooltip, Typography, Checkbox } from "@mui/material";
import { Delete, Edit, MonetizationOn, MoneyOff, PictureAsPdf } from "@mui/icons-material";

import tratar from "../../../utils/retornaTratamentoURL";
import * as EmpresaAPI from "../../../api/empresa";
import * as LancamentosAPI from "../../../api/lancamentos";
import * as FinanceiroAPI from "../../../api/financeiro";

/* util */
import { obterPermissoesUsuario, corrigirDataColumnFilters, corrigirDataColumnFiltersObjeto } from "../../../utils/funcoes";

const Index = () => {
    const [lancamentos, setLancamentos] = useState([]);

    const [optionsCentroCusto, setCC] = useState([]);
    const [optionsSituacao, setSituacao] = useState([]);

    const [token] = useState(localStorage.getItem("token")) || "";
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();

    const location = useLocation();
    const newUrlParams = new URLSearchParams(location.search);
    const [urlParams, setUrlParams] = useState(new URLSearchParams(location.search));

    const pathname = "/Lancamentos/CreateOrEdit";
    const tipo = urlParams.get("tipo");
    const ul = urlParams.get("ul") || 3;
    const li = urlParams.get("li") || tipo === "Pagar" ? 19 : 20;
    const operacaoUUID = urlParams.get("operacao");
    const uuid = urlParams.get("uuid");
    const btnText = urlParams.get("btnText") || "Cadastrar";
    const [hasChanged, setHasChanged] = useState(urlParams.get("hasChanged") === "true");

    const params = new URLSearchParams({
        ul: ul,
        li: li,
        tipo: tipo,
        operacao: operacaoUUID,
        uuid: uuid,
        btnText: btnText,
    });

    let titulo = "";
    let campo = "lancamento";
    if (tipo === "Pagar") {
        titulo = "Lançamentos à Pagar";
    } else if (tipo === "Receber") {
        titulo = "Lançamentos à Receber";
    }

    //novas rotinas date-fns
    const hoje = new Date(); // Hoje
    const { primeiroDia, ultimoDia } = obterInicioEFimDoMes(hoje);

    const { parametro } = useContext(Context);

    //dialog customizado
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});

    //baixar lancamentos
    const dispatch = useDispatch();

    //data and fetching state
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    //tratar filtros se vier pela navbar
    if (localStorage.path.replace(/["]/g, "") !== window.location.pathname) {
        tratar.LimparFiltros();
        localStorage.setItem("path", JSON.stringify(window.location.pathname));
    }

    //table state
    const [columnFilterFns, setColumnFiltersMode] = useState(
        localStorage.columnFilterFns.length > 2
            ? JSON.parse(localStorage.columnFilterFns)
            : { dataVencimento: "between" /*provisao: "equals"*/ }
    );

    const [columnFilters, setColumnFilters] = useState(
        localStorage.columnFilters.length > 2
            ? JSON.parse(localStorage.columnFilters)
            : [
                  {
                      id: "dataVencimento",
                      value: [primeiroDia, ultimoDia],
                  },
                  { id: "provisao", value: "0" },
              ]
    );
    const prevColumnFilters = useRef(columnFilters || {});

    const [globalFilter, setGlobalFilter] = useState(
        localStorage.globalFilter.length > 2 ? JSON.parse(localStorage.globalFilter) : ""
    );

    const [sorting, setSorting] = useState(
        localStorage.sorting.length > 2 ? JSON.parse(localStorage.sorting) : [{ id: "dataVencimento", desc: true }]
    );

    const [pagination, setPagination] = useState({
        pageIndex: JSON.parse(localStorage.pagination).pageIndex || 0,
        pageSize: JSON.parse(localStorage.pagination).pageSize || 5,
    });

    const fetchData = async () => {
        if (!lancamentos.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }

        try {
            LancamentosAPI.listar(
                pagination.pageIndex * pagination.pageSize,
                pagination.pageSize,
                JSON.stringify(columnFilters ?? []),
                JSON.stringify(columnFilterFns ?? []),
                globalFilter ?? "",
                JSON.stringify(sorting ?? []),
                tipo
            ).then((response) => {
                setLancamentos(response.data.data);
                setRowCount(response.data.meta.totalRowCount);
            });
        } catch (error) {
            setIsError(true);
            console.error(error);
            return;
        }
        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
    };

    //Consultas no backend
    const consultaSituacao = async (empresaId, campo) => {
        const result = await FinanceiroAPI.situacao(empresaId, campo);

        const mappedResult = result.map((item) => ({
            text: item.descricao,
            value: item.descricao,
        }));

        setSituacao(mappedResult);
    };

    const consultaCC = async () => {
        const result = await EmpresaAPI.centroCustos(localStorage.getItem("empresaId"), null, tipo, null, null, null);
        // Mapear o array para extrair apenas 'id' e 'descricao'
        const mappedResult = result.map((item) => ({
            text: item.descricao,
            value: item.descricao,
        }));

        setCC(mappedResult);
    };

    //funções do dialog
    const handleOpenDialog = (row) => {
        setOpenDialog(true);
        setSelectedRow(row);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmDialog = async (row) => {
        // Verifique se há uma linha selecionada
        let msgType = "success";
        if (selectedRow) {
            try {
                const response = await LancamentosAPI.deletar(selectedRow);

                setFlashMessage(response.data.message, msgType);

                fetchData();
            } catch (error) {
                msgType = "error";
                console.error(error);
                setFlashMessage(error.response.data.message, msgType);
            }
        }
        setOpenDialog(false);
    };

    const handleCancelarBaixas = async (row) => {
        params.set("uuid", row.id);
        navigate("/LancamentosBaixas", params);
    };

    /* Rotina dos comprovantes */
    const handleComprovantes = async (row) => {
        params.set("uuid", row.id);
        navigate("CreateOrEditComprovante", params);
    };

    useEffect(() => {
        consultaCC();
        //consultaSituacao(1, campo);

        // Formata os filtros de datas apenas se necessário
        const formattedFilters = corrigirDataColumnFiltersObjeto(columnFilters);

        if (hasChanged) {
            setColumnFilters(formattedFilters);
            prevColumnFilters.current = formattedFilters;
            setHasChanged(false); // Usa setHasChanged para evitar loops
        }

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnFilterFns, columnFilters, globalFilter, pagination.pageIndex, pagination.pageSize, sorting, urlParams]);

    useEffect(() => {
        if (!token) {
            return navigate("/Auth", { replace: true });
        }

        //funcao para setar localStorage
        localStorage.setItem(
            "pagination",
            JSON.stringify({
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
            })
        );
        localStorage.setItem("columnFilterFns", JSON.stringify(columnFilterFns));
        localStorage.setItem("columnFilters", JSON.stringify(columnFilters));
        localStorage.setItem("globalFilter", JSON.stringify(globalFilter));
        localStorage.setItem("sorting", JSON.stringify(sorting));

        //permissões do usuario
        obterPermissoesUsuario(localStorage.getItem("pessoaId"), localStorage.getItem("empresaId"), ul, li);

        /* Inicio da função para renderizar a página */
        // Verifica se os urlParams atuais são diferentes dos novos urlParams
        const areUrlParamsDifferent = () => {
            const currentKeys = Array.from(urlParams.keys());
            const newKeys = Array.from(newUrlParams.keys());

            // Verifica se o número de chaves é diferente
            if (currentKeys.length !== newKeys.length) {
                return true;
            }

            // Verifica se as chaves são diferentes
            for (let i = 0; i < currentKeys.length; i++) {
                if (currentKeys[i] !== newKeys[i]) {
                    return true;
                }
            }

            // Verifica se os valores são diferentes
            for (const key of currentKeys) {
                const currentValue = urlParams.get(key);
                const newValue = newUrlParams.get(key);

                if (currentValue !== newValue) {
                    return true;
                }
            }

            return false;
        };

        if (areUrlParamsDifferent()) {
            setUrlParams(newUrlParams);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        token,
        columnFilterFns,
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
        //função para renderizar
        location.search,
        urlParams,
        //selectedUUIDs,
        ul,
        li,
    ]);

    /* Obtem a paginação padrão */
    useEffect(() => {
        if (parametro && parametro.paginacao) {
            setPagination({
                pageIndex: pagination.pageIndex || 0,
                pageSize: parametro.paginacao,
            });
        }
        consultaSituacao((parametro.tabelasComuns = true ? 1 : localStorage.getItem("empresaId")), campo);
    }, [parametro]);

    // useEffect(() => {
    //     //rotina para resolver o erro offset .x
    //     if (lancamentos && lancamentos.x) {
    //         console.info(lancamentos.x);
    //     } else {
    //         console.error("Objeto está indefinido ou não possui 'x'", lancamentos);
    //         console.info("Detalhes dos objetos:", JSON.stringify(lancamentos, null, 2));
    //     }
    // }, [lancamentos]);

    //Rotina para montar o título correto
    if (!!columnFilters?.length) {
        columnFilters.map((filters) => {
            const { id: columnId, value: filterValue } = filters;

            if (columnId === "dataVencimento") {
                if (columnFilterFns[columnId] === "equals") {
                    titulo = titulo + ` em ${format(filterValue, "dd/MM/yyyy")}`;
                } else if (columnFilterFns[columnId] === "greaterThanOrEqualTo") {
                    titulo = titulo + ` maior ou igual a ${format(filterValue, "dd/MM/yyyy")}`;
                } else if (columnFilterFns[columnId] === "lessThanOrEqualTo") {
                    titulo = titulo + ` menor ou igual a ${format(filterValue, "dd/MM/yyyy")}`;
                } else if (columnFilterFns[columnId] === "between") {
                    const [start, end] = filterValue;

                    if (start || end) {
                        titulo =
                            titulo +
                            ` no período de ${isValid(start) ? format(start, "dd/MM/yyyy") : "início inválido"} à ${
                                isValid(end) ? format(end, "dd/MM/yyyy") : "fim inválido"
                            }`;
                    }
                } else if (columnFilterFns[columnId] === "betweenInclusive") {
                    const [start, end] = filterValue;
                    if (start || end) {
                        titulo =
                            titulo +
                            ` no período diferente de ${isValid(start) ? format(start, "dd/MM/yyyy") : "início inválido"} à ${
                                isValid(end) ? format(end, "dd/MM/yyyy") : "fim inválido"
                            }`;
                    }
                }
            }
            return titulo;
        });
    }

    const columns = useMemo(
        () => [
            {
                id: "Lancamento",
                header: titulo,
                columns: [
                    {
                        accessorKey: "Pessoa.CPForCNPJ",
                        header: "CPF ou CNPJ",
                    },
                    {
                        accessorKey: "Pessoa.nomePrincipal",
                        header: "Parceiro",
                    },
                    {
                        accessorKey: "valorOriginal",
                        header: "Valor Original",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) => {
                            const valorOriginal = row.valorOriginal;

                            // Verifica se o valor é um número válido
                            const valorFormatado =
                                !isNaN(parseFloat(valorOriginal)) && valorOriginal !== null && valorOriginal !== undefined
                                    ? parseFloat(valorOriginal).toLocaleString("pt-BR", {
                                          style: "decimal",
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                          minimumIntegerDigits: 1,
                                          useGrouping: true,
                                      })
                                    : "R$ 0,00"; // Valor de fallback para valores inválidos

                            return "R$ " + valorFormatado;
                        },
                    },
                    {
                        accessorKey: "parcelas",
                        header: "Parcela",
                        muiTableBodyCellProps: {
                            align: "center",
                        },
                    },
                    {
                        accessorKey: "valor",
                        header: "Valor Parcela",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) => {
                            const valor = row.valor;

                            // Verifica se o valor é um número válido
                            const valorFormatado =
                                !isNaN(parseFloat(valor)) && valor !== null && valor !== undefined
                                    ? parseFloat(valor).toLocaleString("pt-BR", {
                                          style: "decimal",
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                          minimumIntegerDigits: 1,
                                          useGrouping: true,
                                      })
                                    : "R$ 0,00"; // Valor de fallback para valores inválidos

                            return "R$ " + valorFormatado;
                        },
                    },
                    {
                        accessorKey: "valorBaixado",
                        header: "Valor Baixado",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) => {
                            const valorBaixado = row.valorBaixado;

                            // Verifica se o valor é um número válido
                            const valorFormatado =
                                !isNaN(parseFloat(valorBaixado)) && valorBaixado !== null && valorBaixado !== undefined
                                    ? parseFloat(valorBaixado).toLocaleString("pt-BR", {
                                          style: "decimal",
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                          minimumIntegerDigits: 1,
                                          useGrouping: true,
                                      })
                                    : "R$ 0,00"; // Valor de fallback para valores inválidos

                            return "R$ " + valorFormatado;
                        },
                    },
                    {
                        accessorKey: "valorAberto",
                        header: "Saldo",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) => {
                            const valorAberto = row.valorAberto;

                            // Verifica se o valor é um número válido
                            const valorFormatado =
                                !isNaN(parseFloat(valorAberto)) && valorAberto !== null && valorAberto !== undefined
                                    ? parseFloat(valorAberto).toLocaleString("pt-BR", {
                                          style: "decimal",
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                          minimumIntegerDigits: 1,
                                          useGrouping: true,
                                      })
                                    : "R$ 0,00"; // Valor de fallback para valores inválidos

                            return "R$ " + valorFormatado;
                        },
                    },
                    {
                        accessorFn: (row) => new Date(row),
                        accessorKey: "dataVencimento",
                        header: "Vencimento",
                        muiFilterTextFieldProps: {
                            sx: {
                                minWidth: "250px",
                            },
                        },

                        filterVariant: "date",
                        sortingFn: "date",

                        columnFilterModeOptions: [
                            "fuzzy",
                            "equals",
                            "between",
                            "betweenInclusive",
                            "greaterThanOrEqualTo",
                            "lessThanOrEqualTo",
                        ],
                        muiTableBodyCellProps: {
                            align: "center",
                        },
                        muiTableHeadCellFilterTextFieldProps: {
                            type: "date",
                            InputLabelProps: {
                                shrink: true,
                            },
                            sx: {
                                minWidth: "200px",
                            },
                        },
                        Cell: ({ renderedCellValue, row }) => {
                            return (
                                <span>
                                    {row.original?.dataVencimento && row.original?.dataVencimento
                                        ? isValid(parseISO(row.original?.dataVencimento))
                                            ? format(parseISO(row.original?.dataVencimento), "dd/MM/yyyy")
                                            : null
                                        : null}
                                </span>
                            );
                        },
                    },
                    {
                        accessorKey: "Situacao.descricao",
                        header: "Situação",
                        filterFn: "equals",
                        columnFilterModeOptions: ["fuzzy", "equals", "notEquals"],
                        filterVariant: "select",
                        filterSelectOptions: optionsSituacao,
                    },
                    {
                        accessorFn: (row) => new Date(row),
                        accessorKey: "lancamentoBaixa.dataBaixa",
                        header: "Baixa",
                        filterVariant: "date",
                        sortingFn: "date",
                        columnFilterModeOptions: [
                            "fuzzy",
                            "equals",
                            "between",
                            "betweenInclusive",
                            "greaterThanOrEqualTo",
                            "lessThanOrEqualTo",
                        ],
                        muiTableHeadCellFilterTextFieldProps: {
                            type: "date",
                            InputLabelProps: {
                                shrink: true,
                            },
                            sx: {
                                minWidth: "200px",
                            },
                        },
                        muiTableBodyCellProps: ({ row }) => ({
                            align: "center",
                            onClick: () => {},
                        }),
                        Cell: ({ renderedCellValue, row }) => {
                            return (
                                <span>
                                    {row.original?.lancamentoBaixas[0] && row.original?.lancamentoBaixas[0]?.dataBaixa
                                        ? isValid(parseISO(row.original?.lancamentoBaixas[0]?.dataBaixa))
                                            ? format(parseISO(row.original?.lancamentoBaixas[0]?.dataBaixa), "dd/MM/yyyy")
                                            : null
                                        : null}
                                </span>
                            );
                        },
                    },
                    {
                        accessorKey: "centroCusto.descricao",
                        header: "Centro Custo",
                        filterVariant: "select",
                        filterSelectOptions: optionsCentroCusto,
                    },
                    {
                        accessorKey: "pagamentoForma.descricao",
                        header: "Forma",
                    },
                    {
                        accessorKey: "pagamentoPrazo.descricao",
                        header: "Prazo",
                    },
                    {
                        accessorKey: "Conta.descricao",
                        header: "Conta",
                    },
                    {
                        accessorFn: (row) => new Date(row),
                        accessorKey: "dataLancamento",
                        header: "Lançamento",
                        Header: ({ column }) => <em>{column.columnDef.header}</em>,
                        muiFilterTextFieldProps: {
                            sx: {
                                minWidth: "350px",
                            },
                        },
                        filterVariant: "date",
                        sortingFn: "date",
                        columnFilterModeOptions: [
                            "fuzzy",
                            "equals",
                            "between",
                            "betweenInclusive",
                            "greaterThanOrEqualTo",
                            "lessThanOrEqualTo",
                        ],
                        muiTableBodyCellProps: ({ row }) => ({
                            align: "center",
                            onClick: () => {},
                        }),
                        muiTableHeadCellFilterTextFieldProps: {
                            type: "date",
                            InputLabelProps: {
                                shrink: true,
                            },
                            sx: {
                                minWidth: "200px",
                            },
                        },
                        Cell: ({ renderedCellValue, row }) => {
                            return (
                                <span>
                                    {row.original?.dataLancamento
                                        ? isValid(parseISO(row.original?.dataLancamento))
                                            ? format(parseISO(row.original?.dataLancamento), "dd/MM/yyyy")
                                            : null
                                        : null}
                                </span>
                            );
                        },
                    },
                    // {
                    //     accessorFn: (row) => row.Pessoa?.pessoaJuridica?.nomeFantasia || null, // Substitui "N/A" se undefined
                    //     id: "Pessoa.pessoaJuridica.nomeFantasia",
                    //     header: "Nome Fantasia",
                    // },
                    {
                        accessorKey: "provisao",
                        header: "Provisão",
                        filterVariant: "select",
                        columnFilterModeOptions: ["equals"],
                        filterSelectOptions: [
                            { label: "Todos", value: "" },
                            { label: "Marcado", value: 1 },
                            { label: "Desmarcado", value: 0 },
                        ],
                        muiTableBodyCellProps: ({ row }) => ({
                            align: "center",
                            onClick: () => {},
                        }),
                        Cell: ({ renderedCellValue, row }) => {
                            return (
                                <Checkbox
                                    checked={row.original?.provisao}
                                    sx={{
                                        "& .MuiSvgIcon-root": { fontSize: 28 },
                                    }}
                                    inputProps={{ "aria-label": "controlled" }}
                                />
                            );
                        },
                    },
                    {
                        accessorKey: "Empresa.nomeFantasia",
                        header: "Empresa",
                    },
                ],
            },

            //column definitions...
        ],
        [titulo, optionsCentroCusto, optionsSituacao] //getCommonEditTextFieldProps
    );

    /* modo tema */
    const theme = tableThemeCustom();

    return (
        <div>
            <div>
                {openDialog && (
                    <CustomDialog
                        title={`Delete lançamento a ${tipo}!`}
                        content="Você tem certeza?"
                        onClose={handleCloseDialog}
                        onConfirm={handleConfirmDialog}
                    />
                )}
            </div>
            <ThemeProvider theme={theme}>
                <MaterialReactTable
                    enableColumnFilterModes
                    enableGlobalFilterModes
                    //enableColumnResizing
                    enableColumnOrdering
                    enableEditing
                    enableGrouping
                    enableRowActions
                    enableRowSelection
                    //enableRowNumbers
                    displayColumnDefOptions={{
                        "mrt-row-actions": {
                            muiTableHeadCellProps: {
                                align: "center",
                            },
                            size: 200,
                        },
                        "mrt-row-numbers": {
                            enableColumnOrdering: true, //turn on some features that are usually off
                            enableResizing: true,
                            muiTableHeadCellProps: {
                                sx: {
                                    fontSize: "1.2rem",
                                },
                            },
                        },
                        "mrt-row-select": {
                            enableColumnActions: true,
                            enableHiding: true,
                            size: 50,
                        },
                    }}
                    renderRowActions={({ row, table }) => (
                        // <Box sx={{ display: "flex", gap: "1rem" }}>
                        <Box
                            sx={{
                                display: "flex",
                                flexWrap: "nowrap",
                                gap: "1px",
                            }}
                        >
                            <Tooltip arrow placement="top-start" title="Editar">
                                <span>
                                    {" "}
                                    <IconButton
                                        onClick={() => {
                                            params.set("uuid", row.id);
                                            navigate(pathname, params);
                                        }}
                                        disabled={
                                            localStorage.getItem("editar") === "true" &&
                                            row.original?.empresaId === parseInt(localStorage.getItem("empresaId")) &&
                                            row.original?.situacaoId === 1
                                                ? false
                                                : true
                                        }
                                    >
                                        <Edit />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip arrow placement="top-start" title="Baixar Lançamento">
                                <span>
                                    <IconButton
                                        color="error"
                                        onClick={() => {
                                            params.set("uuid", row.id);
                                            params.set("btnText", "Baixar");
                                            navigate(pathname, params);
                                        }}
                                        disabled={
                                            localStorage.getItem("baixar") === "true" &&
                                            row.original?.empresaId === parseInt(localStorage.getItem("empresaId")) &&
                                            row.original?.valorAberto > 0
                                                ? false
                                                : true
                                        }
                                    >
                                        <Typography variant="body1" component="span"></Typography>
                                        <MonetizationOn />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip arrow placement="top-start" title="Estornar Baixa">
                                <span>
                                    <IconButton
                                        color="error"
                                        onClick={() => handleCancelarBaixas(row)}
                                        disabled={
                                            localStorage.getItem("cancelarBaixa") === "true" && row.original?.valorBaixado > 0
                                                ? false
                                                : true
                                        }
                                    >
                                        <MoneyOff />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip
                                arrow
                                placement="top-start"
                                title={`${
                                    row.original.situacaoId === 1
                                        ? "Comprovante não pode ser anexado lançamento em Aberto"
                                        : "Anexar ou visualizar comprovante de pagamento do lançamento"
                                }`}
                            >
                                <span>
                                    <IconButton
                                        color="error"
                                        onClick={() => {
                                            handleComprovantes(row);
                                        }}
                                        disabled={
                                            localStorage.getItem("editar") === "true" &&
                                            parseFloat(row.original?.valorBaixado) > 0
                                                ? false
                                                : true
                                        }
                                    >
                                        <PictureAsPdf />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip arrow placement="right" title="Deletar">
                                <span>
                                    <IconButton
                                        color="error"
                                        onClick={() => {
                                            handleOpenDialog(row);
                                        }}
                                        disabled={
                                            localStorage.getItem("cancelar") === "true" &&
                                            parseFloat(row.original?.valorBaixado) === 0
                                                ? false
                                                : true
                                        }
                                    >
                                        <Delete />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Box>
                    )}
                    renderTopToolbarCustomActions={({ table }) => {
                        const handleBaixaLancamento = async () => {
                            dispatch(clearSelectedComponent()); // Limpa os valores selecionados
                            const uuids = table.getSelectedRowModel().flatRows.map((row) => row.id);

                            dispatch(selectComponent(uuids)); // Preenche com os novos valores

                            navigate("/Lancamentos/BaixaLancamento", {
                                ul: urlParams.get("ul"),
                                li: urlParams.get("li"),
                                tipo: urlParams.get("li") === "19" ? "Pagar" : "Receber",
                            });
                        };

                        const handleImprimirLancamento = async () => {
                            dispatch(clearSelectedComponent()); // Limpa os valores selecionados
                            const uuids = table.getSelectedRowModel().flatRows.map((row) => row.id);
                            dispatch(selectComponent(uuids)); // Preenche com os novos valores

                            // Filtrar os lançamentos com base nos UUIDs ou manter a lista original
                            const lancamentosFiltrados =
                                uuids.length > 0
                                    ? lancamentos.filter((lancamento) => uuids.includes(lancamento.uuid))
                                    : lancamentos;

                            const urlSearchParams = new URLSearchParams({
                                start: `${pagination.pageIndex * pagination.pageSize}`,
                                size: `${pagination.pageSize}`,
                                filters: JSON.stringify(columnFilters ?? []),
                                filtersMode: JSON.stringify(columnFilterFns ?? []),
                                globalFilter: globalFilter ?? "",
                                sorting: JSON.stringify(sorting ?? []),
                                tipo: urlParams.get("tipo") ?? "",
                            });

                            const response = await LancamentosAPI.relatorio(urlSearchParams, lancamentosFiltrados);

                            // Cria um Blob a partir do objeto do arraybuffer
                            const pdfBlob = new Blob([response.data], {
                                type: "application/pdf",
                            });

                            // Cria um URL temporário para o Blob
                            const pdfUrl = URL.createObjectURL(pdfBlob);

                            // Use o URL do PDF para abrir em uma nova janela ou realizar outras ações
                            window.open(pdfUrl);
                        };

                        const handleImprimirRecibos = async () => {
                            // Limpa os valores selecionados
                            dispatch(clearSelectedComponent());
                            const uuids = table.getSelectedRowModel().flatRows.map((row) => row.id);

                            // Preenche com os novos valores
                            dispatch(selectComponent(uuids));
                            // Filtrar os lançamentos com base nos UUIDs ou manter a lista original
                            const lancamentosFiltrados =
                                uuids.length > 0
                                    ? lancamentos.filter((lancamento) => uuids.includes(lancamento.uuid))
                                    : lancamentos;

                            const urlSearchParams = new URLSearchParams({
                                start: `${pagination.pageIndex * pagination.pageSize}`,
                                size: `${pagination.pageSize}`,
                                filters: JSON.stringify(columnFilters ?? []),
                                filtersMode: JSON.stringify(columnFilterFns ?? []),
                                globalFilter: globalFilter ?? "",
                                sorting: JSON.stringify(sorting ?? []),
                                tipo: urlParams.get("tipo") ?? "",
                            });

                            const response = await LancamentosAPI.recibos(urlSearchParams, lancamentosFiltrados);

                            // Cria um Blob a partir do objeto do arraybuffer
                            const pdfBlob = new Blob([response.data], {
                                type: "application/pdf",
                            });

                            // Cria um URL temporário para o Blob
                            const pdfUrl = URL.createObjectURL(pdfBlob);

                            // Use o URL do PDF para abrir em uma nova janela ou realizar outras ações
                            window.open(pdfUrl);
                        };
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    gap: "8px",
                                }}
                            >
                                <Tooltip arrow placement="top-end" title={`Novo Registro à ${urlParams.get("tipo")}`}>
                                    <div>
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            disabled={JSON.parse(localStorage.getItem("incluir")) === true ? false : true}
                                            onClick={() =>
                                                navigate(pathname, {
                                                    ul: urlParams.get("ul"),
                                                    li: urlParams.get("li"),
                                                    tipo: urlParams.get("li") === "19" ? "Pagar" : "Receber",
                                                })
                                            }
                                        >
                                            Novo
                                        </Button>
                                    </div>
                                </Tooltip>
                                <Tooltip
                                    arrow
                                    placement="top-end"
                                    title={`Baixar lançamentos à ${urlParams.get("tipo")} selecionados`}
                                >
                                    <div>
                                        <Button
                                            color="success"
                                            variant="contained"
                                            disabled={
                                                JSON.parse(localStorage.getItem("baixar")) === false ||
                                                (!table.getIsSomeRowsSelected() && !table.getIsAllPageRowsSelected())
                                            }
                                            onClick={
                                                !table.getIsSomeRowsSelected() && !table.getIsAllPageRowsSelected()
                                                    ? null
                                                    : handleBaixaLancamento
                                            }
                                        >
                                            Baixar
                                        </Button>
                                    </div>
                                </Tooltip>
                                <Tooltip arrow placement="top-end" title={`Imprimir lançamentos à ${urlParams.get("tipo")}`}>
                                    <div>
                                        <span>
                                            <Button
                                                color="info"
                                                variant="contained"
                                                disabled={JSON.parse(localStorage.getItem("imprimir")) === true ? false : true}
                                                onClick={handleImprimirLancamento}
                                            >
                                                Imprimir
                                            </Button>
                                        </span>
                                    </div>
                                </Tooltip>
                                <Tooltip
                                    arrow
                                    placement="top-end"
                                    title={`Emitir recibos à ${urlParams.get("tipo")} selecionados`}
                                >
                                    <div>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            disabled={
                                                JSON.parse(localStorage.getItem("imprimir")) === false ||
                                                (!table.getIsSomeRowsSelected() && !table.getIsAllPageRowsSelected())
                                            }
                                            onClick={
                                                !table.getIsSomeRowsSelected() && !table.getIsAllPageRowsSelected()
                                                    ? null
                                                    : handleImprimirRecibos
                                            }
                                        >
                                            Recibos
                                        </Button>
                                    </div>
                                </Tooltip>
                            </div>
                        );
                    }}
                    columns={columns}
                    data={lancamentos}
                    getRowId={(row) => row.uuid}
                    manualFiltering
                    manualPagination
                    manualSorting
                    muiToolbarAlertBannerProps={
                        isError
                            ? {
                                  color: "error",
                                  children: "Error loading data",
                              }
                            : undefined
                    }
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setColumnFiltersMode}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    rowCount={rowCount}
                    initialState={{
                        showColumnFilters: true,
                        density: "compact",
                        //columnPinning: { left: ["mrt-row-select"] },
                    }}
                    state={{
                        columnFilterFns,
                        columnFilters,
                        globalFilter,
                        isLoading,
                        pagination,
                        showAlertBanner: isError,
                        showProgressBars: isRefetching,
                        sorting,
                    }}
                    positionToolbarDropZone={"bottom"}
                    positionExpandColumn={"first"}
                    positionActionsColumn={"first"}
                    positionToolbarAlertBanner="bottom"
                    localization={MRT_Localization_PT_BR}
                />
            </ThemeProvider>
        </div>
    );
};
export default Index;
