import React, { useEffect, useMemo, useState, useContext } from "react";
import "react-tabs/style/react-tabs.css";
import "moment/locale/pt-br";

/* contexts */
import { Context } from "../../../context/UserContext";

import { Stack, Button, Box, Tooltip, IconButton, Typography } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { MaterialReactTable } from "material-react-table";

import { ThemeProvider } from "@mui/material";
/* tema customizado*/
import tableThemeCustom from "../../form/tableThemeCustom";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

//Import Material React Table Translations
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";
import CustomDialog from "../../layout/CustomDialog";
import Input from "../../form/Input";
import Checkbox from "../../form/Checkbox";
import formStyles from "../../form/Form.module.css";

/* utils */
//import tratar from "../../../utils/retornaTratamentoURL";

/* apis */
import * as LancamentoAPI from "../../../api/lancamentos";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, params }) {
    const [dados, setDados] = useState(formData || {});
    const [preview, setPreview] = useState([]);
    const [itens, setItens] = useState([]);

    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();

    const urlParams = new URLSearchParams(window.location.search);
    const uuid = urlParams.get("uuid");

    const [novoComprovante, setNovoComprovante] = useState({
        uuid: null,
        lancamentoId: dados?.id,
        ativo: true,
        funcao: "Adicionar",
    });

    //data and fetching state
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);

    //dialog customizado
    const [openDialog, setOpenDialog] = useState(false);

    const [rowCount, setRowCount] = useState(0);

    //tratar filtros se vier pela navbar
    if (localStorage.path.replace(/["]/g, "") !== window.location.pathname) {
        //tratar.LimparFiltros();
        //localStorage.setItem("path", JSON.stringify(window.location.pathname));
    }

    //table state
    const [columnFilterFns, setColumnFiltersMode] = useState(
        localStorage.columnFilterFns?.length > 2 ? JSON.parse(localStorage.columnFilterFns) : []
    );
    const [columnFilters, setColumnFilters] = useState(
        localStorage.columnFilters?.length > 2 ? JSON.parse(localStorage.columnFilters) : []
    );
    const [globalFilter, setGlobalFilter] = useState(
        localStorage.globalFilter?.length > 2 ? JSON.parse(localStorage.globalFilter) : ""
    );
    const [sorting, setSorting] = useState(localStorage.sorting?.length > 2 ? JSON.parse(localStorage.sorting) : []);
    const [pagination, setPagination] = useState({
        pageIndex: JSON.parse(localStorage.pagination).pageIndex || 0,
        pageSize: JSON.parse(localStorage.pagination).pageSize || 5,
    });

    //consulta a API
    const fetchData = async () => {
        if (!itens?.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }

        try {
            LancamentoAPI.listarComprovantes(
                pagination.pageIndex * pagination.pageSize,
                pagination.pageSize,
                JSON.stringify(columnFilters ?? []),
                JSON.stringify(columnFilterFns ?? []),
                globalFilter ?? "",
                JSON.stringify(sorting ?? []),
                uuid
            ).then((response) => {
                setDados(response.data?.data[0] || {});
                setItens(response.data?.data[0]?.lancamentoComprovantes || []);
                setRowCount(response.data.meta.totalRowCount);
            });
        } catch (error) {
            setIsError(true);
            console.error(error);
            return;
        }

        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
    };

    const limparComprovante = () => {
        setNovoComprovante({
            lancamentoId: dados?.id,
            arquivo: null,
            ativo: true,
            funcao: "Adicionar",
        });
    };

    /* COMPROVANTES */
    const handleComprovante = async (uuid) => {
        //cadastrar ou deletar
        try {
            const response = await LancamentoAPI.createOrEditComprovante(novoComprovante);
            setFlashMessage(response.message, response.type);
        } catch (error) {
            console.error(error);
            if (error.response && error.response.status === 422) {
                // Tratar o erro 422, que é específico da validação no servidor
                setFlashMessage("Erro de validação: " + error.response.data.message);
            } else {
                // Tratar outros erros de rede ou servidor
                setFlashMessage("Ocorreu um erro ao processar a solicitação.", "error");
            }
        }

        limparComprovante();

        fetchData();
    };

    //funções do dialog
    const handleOpenDialog = (row) => {
        setOpenDialog(true);
        row.funcao = "Deletar";
        setNovoComprovante(row);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        limparComprovante();
    };

    const handleConfirmDialog = async (row) => {
        handleComprovante(novoComprovante);
        setOpenDialog(false);
    };

    useEffect(() => {
        setDados(formData || {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData, token]);

    useEffect(() => {
        setNovoComprovante((prev) => ({ ...prev, lancamentoId: dados?.id }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dados]);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnFilterFns, columnFilters, globalFilter, pagination.pageIndex, pagination.pageSize, sorting]);

    async function onFileChange(e) {
        const { name, value, checked, type, files } = e.target;
        setPreview(Array.from(files));
        setNovoComprovante({ ...novoComprovante, [name.split(".")[1]]: [...files] });
    }
    async function handleChange(e) {
        const { name, value, checked, type } = e.target;

        setDados({ ...dados, [name]: type === "checkbox" ? checked : value });
    }

    const submit = (e) => {
        e.preventDefault();

        handleSubmit(dados);
    };

    /* código da grid */
    const columns = useMemo(
        () => [
            {
                id: "Comprovantes",
                header: "Comprovantes de lançamentos baixados",
                columns: [
                    {
                        accessorKey: "arquivo",
                        header: "Arquivo",

                        Cell: ({ row }) => (
                            <span
                                style={{
                                    cursor: "pointer",
                                    color: "blue",
                                    textDecoration: "underline",
                                }}
                                onClick={() => {
                                    const url = `${process.env.REACT_APP_API}pdf/${row.original.arquivo}`;
                                    window.open(url, "_blank");
                                }}
                            >
                                {"Visualizar PDF => " + row.original.arquivo}
                            </span>
                        ),
                    },
                ],
            },

            //column definitions...
        ],
        [] //getCommonEditTextFieldProps
    );

    /* modo tema */
    const theme = tableThemeCustom();
    return (
        <form onSubmit={submit}>
            <div className={formStyles.stiloPadrao}>
                <h1>Comprovantes de Lançamentos Baixados</h1>
                <h4>Comprovantes</h4>
                <div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "1rem",
                        }}
                    >
                        <Input
                            text="PDF do compronvante"
                            type="file"
                            name="novoComprovante.arquivo"
                            handleOnChange={onFileChange}
                            inputWidth="400px"
                            multiple={true}
                            accept="application/pdf"
                        />
                        <Checkbox
                            title="Ativo quando marcado"
                            text="Ativo"
                            name="ativo"
                            type="checkbox"
                            handleOnChange={handleChange}
                            value={novoComprovante.ativo}
                            checked={novoComprovante.ativo === true ? "checked" : ""}
                        />
                        <Button
                            variant="contained"
                            color="inherit"
                            onClick={() => {
                                setNovoComprovante({
                                    uuid: null,
                                    lancamento: dados?.id,
                                    arquivo: null,
                                    ativo: true,
                                    funcao: "Adicionar",
                                });
                            }}
                            style={{ marginLeft: "10px" }}
                        >
                            Limpar
                        </Button>
                        <Button
                            variant="contained"
                            color={novoComprovante.funcao.toLocaleLowerCase() === "deletar" ? "error" : "info"}
                            onClick={() => handleComprovante(novoComprovante)}
                            disabled={novoComprovante.arquivo === null || novoComprovante.arquivo === undefined ? true : false}
                            style={{ marginLeft: "5px" }}
                        >
                            {novoComprovante.funcao}
                        </Button>
                    </div>
                </div>
                <div>
                    {openDialog && (
                        <CustomDialog
                            title={`Delete COMPROVANTE!`}
                            content="Você tem certeza?"
                            onClose={handleCloseDialog}
                            onConfirm={handleConfirmDialog}
                        />
                    )}
                </div>
                <div>
                    <ThemeProvider theme={theme}>
                        <MaterialReactTable
                            displayColumnDefOptions={{
                                "mrt-row-actions": {
                                    muiTableHeadCellProps: {
                                        align: "center",
                                    },
                                    size: 100,
                                },
                                "mrt-row-numbers": {
                                    enableColumnOrdering: true, //turn on some features that are usually off
                                    enableResizing: true,
                                    muiTableHeadCellProps: {
                                        sx: {
                                            fontSize: "1.2rem",
                                        },
                                    },
                                },
                                "mrt-row-select": {
                                    enableColumnActions: true,
                                    enableHiding: true,
                                    size: 50,
                                },
                            }}
                            enableColumnFilterModes
                            enableGlobalFilterModes
                            //enableColumnResizing
                            enableColumnOrdering
                            enableEditing
                            renderRowActions={({ row, table }) => (
                                <Box sx={{ display: "flex", gap: "1rem" }}>
                                    <Tooltip arrow placement="right" title="Deletar">
                                        <span>
                                            <IconButton
                                                color="error"
                                                //onClick={() => handleOpenDialog(row.id)}
                                                onClick={() => handleOpenDialog(row.original)}
                                                disabled={localStorage.getItem("cancelar") === "true" ? false : true}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Box>
                            )}
                            renderDetailPanel={({ row }) => (
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                        alignItems: "center",
                                    }}
                                >
                                    {row.original.images !== null && (
                                        <div
                                            style={{
                                                display: "inline-block", // Para que os elementos fiquem lado a lado
                                                cursor: "pointer", // Muda o cursor para indicar que é clicável
                                                margin: "0 10px 0 0", // Espaçamento direito e inferior
                                            }}
                                            onClick={() => {
                                                const url = `${process.env.REACT_APP_API}pdf/${row.original.arquivo}`;
                                                window.open(url, "_blank");
                                            }} // Abre o PDF em uma nova aba
                                            title="Clique para abrir em uma nova aba" // Mensagem exibida ao passar o mouse
                                        >
                                            <embed
                                                type="application/pdf"
                                                src={process.env.REACT_APP_API + "pdf/" + row.original.arquivo}
                                                width={60}
                                                height={80}
                                                style={{
                                                    borderColor: "black",
                                                    border: "solid 0.05em",
                                                }}
                                            />
                                        </div>
                                    )}
                                    <Box sx={{ textAlign: "left" }}>
                                        <Typography variant="h4">Comprovante</Typography>
                                    </Box>
                                </Box>
                            )}
                            columns={columns}
                            data={itens}
                            enableRowSelection
                            getRowId={(row) => row.uuid}
                            manualFiltering
                            manualPagination
                            manualSorting
                            muiToolbarAlertBannerProps={
                                isError
                                    ? {
                                          color: "error",
                                          children: "Error loading data",
                                      }
                                    : undefined
                            }
                            onColumnFiltersChange={setColumnFilters}
                            onColumnFilterFnsChange={setColumnFiltersMode}
                            onGlobalFilterChange={setGlobalFilter}
                            onPaginationChange={setPagination}
                            onSortingChange={setSorting}
                            rowCount={rowCount}
                            initialState={{
                                showColumnFilters: true,
                                density: "compact",
                                //columnPinning: { left: ["mrt-row-select"] },
                            }}
                            state={{
                                columnFilterFns,
                                columnFilters,
                                globalFilter,
                                isLoading,
                                pagination,
                                showAlertBanner: isError,
                                showProgressBars: isRefetching,
                                sorting,
                            }}
                            //positionToolbarDropZone={"top"}
                            positionExpandColumn={"first"}
                            positionActionsColumn={"first"}
                            positionToolbarAlertBanner="bottom"
                            localization={MRT_Localization_PT_BR}
                        />
                    </ThemeProvider>
                </div>
            </div>
            <div>
                <Stack direction="row" spacing={1}>
                    <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar, params)}>
                        Voltar
                    </Button>
                </Stack>
            </div>
        </form>
    );
}
export default Form;
