import { useState, useEffect } from "react";
import "react-tabs/style/react-tabs.css";

import moment from "moment";
import "moment/locale/pt-br";

import { Stack, Button } from "@mui/material";

/* hooks */
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import Input from "../../form/Input";
import InputNumber from "../../form/InputNumber";
import Checkbox from "../../form/Checkbox";
import Select from "../../form/Select";
import TextArea from "../../form/TextArea";

/* utils */
import { formatarNumero } from "../../../utils/masks";
import { retornaData } from "../../../utils/retornaDataFormatada";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, OptionsConta }) {
    const [dados, setDados] = useState(formData || {});

    const navigate = useNavigateParams();

    useEffect(() => {
        setDados(formData || {});
    }, [formData]);

    function handleChange(e) {
        if (e.target.name === "historico" || e.target.name === "dataBaixa" || e.target.name === "contaId") {
            setDados({ ...dados, [e.target.name]: e.target.value });
        }
    }

    async function handleOnBlur(e) {}
    function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();
        handleSubmit(dados);
    };

    return (
        <form onSubmit={submit}>
            <h1>Lançamento no extrato da conta</h1>
            <div>
                <Select
                    text="Conta do lançamento"
                    name="contaId"
                    options={OptionsConta}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.contaId || ""}
                    required
                    inputWidth={"210px"}
                    notOptNull={true}
                />
                <Input
                    text="Data"
                    type="date"
                    name="dataBaixa"
                    placeholder="Data"
                    title="Data da baixa no sistema financeiro"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={(dados.dataBaixa && retornaData(dados.dataBaixa)) || retornaData(moment())}
                />
                <InputNumber
                    text="Valor"
                    type="text"
                    name="valor"
                    inputWidth="100px"
                    title=""
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.valor ? formatarNumero(dados.valor) : ""}
                    required={true}
                />

                <Checkbox
                    title="Ativa quando marcado"
                    text="Ativo"
                    name="ativo"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.ativo}
                    checked={dados.ativo === true ? "checked" : ""}
                />
            </div>
            <div>
                <TextArea
                    text="Histórico"
                    type="text"
                    name="historico"
                    placeholder="Histórico do lançamento"
                    title="Digite o histórico do lançamento"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.historico || ""}
                    textAlign="left"
                    linhas="3"
                    colunas="66"
                />
            </div>
            <div>
                <Stack direction="row" spacing={1}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                            navigate(caminhoVoltar, {
                                hasChanged: true,
                            })
                        }
                    >
                        Voltar
                    </Button>
                    <Button type="submit" variant="contained" color="success">
                        {btnText}
                    </Button>
                </Stack>
            </div>
        </form>
    );
}
export default Form;
