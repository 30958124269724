import { useState, useEffect } from "react";
import "react-tabs/style/react-tabs.css";
import "moment/locale/pt-br";

import { Stack, Button } from "@mui/material";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import * as DataListAPI from "../../../api/dataList";
import * as TabelaAPI from "../../../api/tabela";

import Input from "../../form/Input";
import InputNumber from "../../form/InputNumber";
import Checkbox from "../../form/Checkbox";
import SelectEmpresa from "../../form/SelectEmpresa";
import DataList from "../../form/DataList";

import { formatarNumero, formatarNumeroDecimal } from "../../../utils/masks";
import { retornaMes } from "../../../utils/retornaDataFormatada";

function Form({ handleSubmit, formData, caminhoVoltar, OptionsEmpresa, OptionsCentroCustos }) {
    const [dados, setDados] = useState(formData || {});
    const [centroCusto, setCentroCusto] = useState(formData?.centroCusto || {});
    const [OptionsCC, setOptionsCC] = useState(OptionsCentroCustos || []);

    const [CC, setCC] = useState({});

    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();
    const urlParams = new URLSearchParams(window.location.search);
    const ul = urlParams.get("ul") || 3;
    const li = urlParams.get("li") || 37;
    const tipo = urlParams.get("tipo") || "Pagar";
    const btnText = urlParams.get("btnText") || "Cadastrar";
    //para definir campos padrões
    if (!dados.empresaId) {
        dados.empresaId = localStorage.getItem("empresaId");
        dados.ativo = true;
        dados.mes = retornaMes();
    }

    const consultaDatalist = async (empresaId, tabela, id, descricao, condicao, filtro) => {
        const result = await DataListAPI.dataList(empresaId, tabela, id, descricao, condicao, filtro);
        return result;
    };

    useEffect(() => {
        setDados(formData || {});
        setCentroCusto(formData?.centroCusto || {});
    }, [formData]);

    useEffect(() => {
        setDados((prevState) => ({
            ...prevState,
            centroCustoId: CC?.id ?? null,
            limite: CC?.limitePadrao ?? null,
        }));
    }, [CC]);

    async function handleChange(e) {
        const { name, value, checked, type } = e.target;

        if (name === "limite") {
            const valorFormatado = formatarNumeroDecimal(value);
            setDados({
                ...dados,
                [name]: valorFormatado,
            });
        } else if (name.startsWith("centroCusto.")) {
            setCentroCusto({
                ...centroCusto,
                [name.split(".")[1]]: value,
            });

            const result = await consultaDatalist(dados.empresaId, "centroCusto", "id", "descricao", tipo, value);
            setOptionsCC(result);

            const selectedId = document.querySelector(`datalist option[value="${value}"]`)?.dataset.id;
            const resultCC = selectedId ? await TabelaAPI.tabelaById(dados.empresaId, "centroCusto", selectedId) : null;

            setCC(resultCC);

            //verifica duplicidade
            if (selectedId && btnText.toLowerCase() === "cadastrar") {
                //verifica duplicidade
                const duplicidade = await TabelaAPI.duplicidadeCentroCustoLimite(dados.empresaId, selectedId, dados.mes);
                if (duplicidade?.uuid) {
                    setFlashMessage("Registro em duplicidade!", "warning");
                    return navigate("/CentroCustoLimites/CreateOrEdit", {
                        uuid: duplicidade.uuid,
                        ul: ul,
                        li: li,
                        tipo: tipo,
                        btnText: "Atualizar",
                    });
                }
            }
        } else {
            setDados({ ...dados, [name]: type === "checkbox" ? checked : value });
        }
    }
    async function handleOnBlur(e) {}
    function handleOnFocus(e) {}
    const submit = (e) => {
        e.preventDefault();
        if (!dados.empresaId) {
            dados.empresaId = parseInt(document.getElementById("empresaId").value);
        }
        if (!dados.centroCustoId) {
            setFlashMessage("Centro de Custo vazio!", "error");
            return;
        }
        if (!dados.limite || dados.limite <= 0) {
            setFlashMessage("Limite do centro de custo inválido!", "error");
            return;
        }

        handleSubmit(dados);
    };

    return (
        <form onSubmit={submit}>
            <h1>Gestão de limites por centro de custos</h1>
            <div>
                <SelectEmpresa
                    text="Selecione a Empresa"
                    name="empresaId"
                    options={OptionsEmpresa}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.empresaId || ""}
                    required
                    notOptNull={true}
                />
                <DataList
                    text="Centro de Custo"
                    title={"Escolha o centro de custo"}
                    placeholder={"Digite para pesquisar"}
                    name="centroCusto.descricao"
                    inputWidth="310px"
                    filteredOptions={OptionsCC}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={centroCusto?.descricao || ""}
                    required={true}
                />
            </div>
            <div>
                <Input
                    text="Mês"
                    type="month"
                    name="mes"
                    title="Escolha o mês"
                    inputWidth="200px"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.mes || null}
                    required
                />
                <InputNumber
                    text="Limite"
                    type="text"
                    name="limite"
                    placeholder="Limite em R$ "
                    inputWidth="100px"
                    title="digite o limite do centro de custo"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.limite ? formatarNumero(dados.limite) : null}
                    required={true}
                />

                <Checkbox
                    title="habilita o uso do limite centro de custo"
                    text="Ativo"
                    name="ativo"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.ativo}
                    checked={dados.ativo === true ? "checked" : ""}
                />
            </div>
            <div>
                <Stack direction="row" spacing={1}>
                    <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                        Voltar
                    </Button>
                    <Button type="submit" variant="contained" color="success">
                        {btnText}
                    </Button>
                </Stack>
            </div>
        </form>
    );
}
export default Form;
