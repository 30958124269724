//import moment from "moment";
import moment from "moment-timezone";
//import "moment/locale/pt-br";
//moment.tz.setDefault("America/Sao_Paulo");

const hoje = moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"); // Obtém a data atual com a hora definida como 00:00:00

export const retornaDataHora = (data) => {
    if (!data) {
        return moment().format("YYYY-MM-DD HH:mm:ss");
    }

    return moment(data).format("YYYY-MM-DD HH:mm:ss");
};

export const retornaData = (data) => {
    if (!data) {
        return moment().startOf("day").format("YYYY-MM-DD HH:mm:ss");
    }
    return moment(data, "YYYY-MM-DD").startOf("day").format("YYYY-MM-DD");
    //return moment(data, "YYYY-MM-DD").format("YYYY-MM-DD");
};

export const retornaDataBr = (data) => {
    return moment(data, "YYYY-MM-DD").format("DD/MM/YYYY");
};

export const retornaDataBrToBancoDados = (data) => {
    return moment(data, "DD/MM/YYYY").format("YYYY-MM-DD");
};

/**
 * Função para comparar dia especifico com o dia de hoje.
 * @param {int} dia - O dia a ser comparado.
 * @returns {int} 0 para menor, 1 para igual e 2 para maior.
 * @comentario Servi para definar a data de vencimento de pagamentos com cartões de crédito
 */
export const comparaDia = (dia) => {
    if (dia === hoje.date()) {
        return 1; //"igual";
    } else if (dia < hoje.date()) {
        return 0; //"menor";
    } else {
        return 2; //"maior";
    }
};

export const retornaMes = () => {
    return moment().format("YYYY-MM");
};

export const retornaMesAnoPorExtenso = (data) => {
    return moment().format("YYYY-MM");
};

export const recebeAnoMesRetornaPorExtenso = (data) => {
    const dataMoment = moment(data + "-01");
    const dataFormatada = dataMoment.format("MMMM [de] YYYY");

    // Ajuste para garantir que a primeira letra do mês seja maiúscula
    const dataFormatadaComMaiuscula = dataFormatada.replace(/^\w/, (letra) => letra.toUpperCase());

    return dataFormatadaComMaiuscula;
};

// Retorna a diferença entre datas em dias, considerando 1 dia de atraso se a diferença for positiva
export const retornaDiasAtrasoVencimento = (dataInicial, dataFinal) => {
    // Se dataInicial não for fornecida, usamos a data de hoje às 00:00:00
    dataInicial = dataInicial ? moment(dataInicial).startOf("day") : moment().startOf("day");
    dataFinal = moment(dataFinal).startOf("day");

    // Calcula a diferença em dias
    let diferencaEmDias = dataInicial.diff(dataFinal, "days");

    return diferencaEmDias;
};
