import { useState, useContext, useEffect } from "react";
import { useNavigateParams } from "../../../hooks/useNavigateParams";
import useFlashMessage from "../../../hooks/useFlashMessage";

import Input from "./Input";

import styles from "./Form.module.css";
import message from "../../layout/Message.module.css";

/* contexts */
import { Context } from "../../../context/UserContext";

/* funções */
import maskCPForCNPJ from "../../../utils/maskCPForCNPJ";

function Login() {
    const [user, setUser] = useState({});
    const { login, parametro } = useContext(Context);
    const [token] = useState(localStorage.getItem("token")) || "";
    const navigate = useNavigateParams();
    const { setFlashMessage } = useFlashMessage();

    const onlyNumbers = (str) => str.replace(/[^0-9]/g, "");

    useEffect(() => {
        if (token) {
            setFlashMessage(`Existe usuário ${localStorage.getItem("nome")} está logado!`, "success", 5);
            return navigate("/");
        }
    }, [token]);

    function handleChange(e) {
        setUser({ ...user, [e.target.name]: e.target.value });

        if (user.cnpj) {
            user.cnpj = onlyNumbers(user.cnpj);
        }
    }

    function handleSubmit(e) {
        e.preventDefault();

        if (user.cnpj) {
            user.cnpj = onlyNumbers(user.cnpj);
        }

        login(user)
            .then((result) => {})
            .catch((error) => {
                console.error(error);
            });
    }

    return (
        <section className={`${styles.form_container} ${styles[parametro.tema]} ${message}`}>
            <h1> Login</h1>
            <form onSubmit={handleSubmit}>
                <Input
                    text="Empresa"
                    type="text"
                    name="cnpj"
                    placeholder="Digite o CPF ou CNPJ de acesso"
                    handleOnChange={handleChange}
                    value={user.cnpj ? maskCPForCNPJ(user.cnpj) : ""}
                    required
                />
                <Input
                    text="Usuário"
                    type="text"
                    name="usuario"
                    placeholder="Digite o usuário de acesso"
                    handleOnChange={handleChange}
                    required
                />
                <Input
                    text="Senha"
                    type="password"
                    name="senha"
                    placeholder="Digite a senha de acesso"
                    handleOnChange={handleChange}
                    required
                />
                <input
                    type="submit"
                    value={!user.usuario || !user.senha || user.senha.length < 1 ? "Preencha os dados" : "Acessar"}
                    disabled={!user.usuario || !user.senha || user.senha.length < 1}
                />
            </form>
        </section>
    );
}

export default Login;
