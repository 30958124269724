// store/reducers/actions.js

export const SELECT_COMPONENT = "SELECT_COMPONENT";
export const UPDATE_SELECTED_COMPONENTS = "UPDATE_SELECTED_COMPONENTS";
export const CLEAR_SELECTED_COMPONENT = "CLEAR_SELECTED_COMPONENT";

export const selectComponent = (uuid) => ({
    type: SELECT_COMPONENT,
    payload: uuid,
});

export const updateSelectedComponents = (components) => ({
    type: UPDATE_SELECTED_COMPONENTS,
    payload: components,
});

// actions.js
export const clearSelectedComponent = () => ({
    type: CLEAR_SELECTED_COMPONENT,
});
