import { useState, useEffect, useContext, useCallback } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

/* contexts */
import { Context } from "../../../context/UserContext";

import moment from "moment";
import "moment/locale/pt-br";

import { Stack, Button } from "@mui/material";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

/* utils */
import { formatarNumeroDecimal } from "../../../utils/masks";
import { retornaDataHora } from "../../../utils/retornaDataFormatada";

import Input from "../../form/Input";

import Select from "../../form/Select";
import Checkbox from "../../form/Checkbox";
import DataList from "../../form/DataList";
import SelectEmpresa from "../../form/SelectEmpresa";

import formStyles from "../../form/Form.module.css";

/* apis */
import * as DataListAPI from "../../../api/dataList";
import * as ProdutoAPI from "../../../api/produto";
import * as TabelaAPI from "../../../api/tabela";
import * as NCMAPI from "../../../api/NCM";
import * as AlmoxarifadoAPI from "../../../api/almoxarifado";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, OptionsEmpresa, OptionsEmbalagem }) {
    const onlyNumbers = (str) => str.replace(/[^0-9]/g, "");

    const [dados, setDados] = useState({});

    const [preview, setPreview] = useState([]);
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();
    const { parametro } = useContext(Context);

    /* Empresa usa tabela comum ou propria */
    let empresaId = 1;
    if (parametro.tabelasComuns === false) {
        empresaId = localStorage.getItem("empresaId");
    }

    if (!dados.empresaId) {
        dados.ativo = true;
        dados.empresaId = parseInt(localStorage.getItem("empresaId"));
    }
    //set datalist
    const [NCM, setNCM] = useState(formData?.NCM);
    const [OptionsNCM, setOptionsNCM] = useState([]);
    const [Fabricante, setFabricante] = useState({});
    const [produtoBens, setBem] = useState({});

    const [produtoModelo, setProdutoModelo] = useState({});

    const [Proprietario, setProprietario] = useState({});
    const [OptionsProprietario, setOptionsProprietario] = useState([]);

    const [produtoImagems, setProdutoImagems] = useState([]);

    const [OptionsFabricante, setOptionsFabricante] = useState([]);
    const [OptionsModelo, setOptionsModelo] = useState([]);
    const [OptionsCores, setOptionsCores] = useState([]);
    const [OptionsAlmoxarifados, setOptionsAlmoxarifado] = useState([]);
    const [produtoEmbalagens, setEmbalagem] = useState([]);

    //abas
    const [tabEnabled, setTabEnabled] = useState({
        produto: true,
        caracteristicas: true,
        bens: true,
        embalagens: true,
        imagens: true,
    });

    //set enums
    const [EnumTipo, setTipo] = useState(formData?.OptionsTipo || {});
    const [EnumBemTipo, setBemTipo] = useState(formData?.OptionsBemTipo || {});

    //carregar ENUMS
    const consultaEnum = async (table, field, filter) => {
        const result = await TabelaAPI.enums(table, field, filter);
        if (table === "Produtos") {
            const filteredResult = result ? result.filter((item) => item === "Imobilizado" || item === "Material") : []; //filtra os resutados
            field === "tipo" && setTipo(result ? filteredResult : []);
        } else if (table === "produtoBens") {
            const filteredResults = result ? result.filter((item) => item === "Móvel") : []; //filtra os resutados
            field === "tipo" && setBemTipo(result ? filteredResults : []);
        }
    };

    //carrega APIs
    const consultaTabela = async (empresaId, tabela, order) => {
        const result = await TabelaAPI.tabela(empresaId, tabela, order);
        if (tabela === "Cor") {
            setOptionsCores(result);
        }
    };
    const consultaAlmoxarifados = async (empresaId = dados.empresaId) => {
        const result = await AlmoxarifadoAPI.almoxarifados(empresaId);
        setOptionsAlmoxarifado(result);
        return result;
    };
    const consultaNCMById = useCallback(
        async (id) => {
            const result = await NCMAPI.ncmById(id);

            setNCM(result);
            setDados((prevDados) => ({
                ...prevDados,
                NCM: result,
            }));
        },
        [setNCM, setDados]
    );

    //Atualiza o Datalist
    const consultaDatalist = async (empresaId, tabela, id, descricao, condicao, filtro) => {
        let result = await DataListAPI.dataList(empresaId, tabela, id, descricao, condicao, filtro);
        return result;
    };

    const consultaDatalistProprietario = async (empresaId, filtro, locador, locatario, fiador, funcionarioId) => {
        const result = await DataListAPI.dataListParceiroImovel(empresaId, filtro, locador, locatario, fiador, funcionarioId);
        return result;
    };

    //rotina para criar objetos de tabelas filhas
    const [novaEmbalagem, setNovaEmbalagem] = useState({
        produtoId: dados.id,
        empresaId: dados.empresaId,
        empresa: "",
        embalagemId: "",
        embalagem: "",
        uuid: "",
        codigoBarras: "",
        ativo: true,
        funcao: "adicionar",
    });

    useEffect(() => {
        setDados(formData || {});
        setFabricante(formData?.Fabricante || {});
        setEmbalagem(formData?.produtoEmbalagens || []);

        setNCM(formData.NCM || {});

        setBem(formData?.produtoBens || { ...produtoBens, tipo: "Móvel" });
        setProdutoModelo(formData?.produtoBens?.produtoModelo || {});
        setProdutoImagems(formData?.produtoImagems || []);
        setTipo(formData?.OptionsTipo);
        setBemTipo(formData?.OptionsBemTipo);
        setProprietario(formData?.produtoBens?.Proprietario);

        setTabEnabled({
            produto: true,
            bens: true,
            caracteristicas: true,
            embalagens: true,
            imagens: true,
        });

        //enums
        consultaEnum("Produtos", "tipo");
        consultaEnum("produtoBens", "tipo");

        //select
        consultaTabela(dados.empresaId, "Cor", "descricao");
        consultaTabela(parametro.tabelasComuns === true ? 1 : dados.empresaId, "Ambiente", "descricao");

        consultaAlmoxarifados(dados.empresaId);
        btnText === "Cadastrar" && (dados.ncmId = 3378); //ncm padrão para obras
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData, btnText]);

    useEffect(() => {
        setNovaEmbalagem((prevEmbalagem) => ({
            ...prevEmbalagem,
            produtoId: dados.id,
        }));
    }, [dados.id]);

    useEffect(() => {
        if (dados.ncmId) {
            consultaNCMById(dados.ncmId);
        }
    }, [dados.ncmId, consultaNCMById]);

    //atualiza tabelas filhas
    useEffect(() => {
        setDados((prev) => {
            return {
                ...prev,
                produtoBens: produtoBens,
                produtoEmbalagens: produtoEmbalagens,
            };
        });
    }, [produtoBens, produtoEmbalagens]);

    const handleEmbalagem = async () => {
        /* grava no banco de dados o sistema entende que esta no modo EDIT */
        if (!novaEmbalagem.produtoId) {
            novaEmbalagem.produtoId = parseInt(dados.id);
        }
        if (!novaEmbalagem.embalagemId) {
            novaEmbalagem.embalagemId = parseInt(document.getElementById("novaEmbalagem.embalagemId").value);
        }
        if (!novaEmbalagem.empresaId) {
            novaEmbalagem.empresaId = parseInt(document.getElementById("novaEmbalagem.empresaId").value);
        }

        if (dados.id) {
            const result = await ProdutoAPI.embalagem(novaEmbalagem);

            if (result.status === 202) {
                const mensagem = `Embalagem já cadastrada!`;
                setFlashMessage(mensagem, "info");
            } else {
                setFlashMessage(result.message, result.type);

                if (result.type === "success") {
                    setEmbalagem(result.resultado);
                }
                setNovaEmbalagem({
                    produtoId: dados.id,
                    empresaId: dados.empresaId || localStorage.getItem("empresaId"),
                    empresa: "",
                    embalagemId: "",
                    embalagem: "",
                    uuid: "",
                    codigoBarras: "",
                    ativo: false,
                    funcao: "adicionar",
                });
            }
        } else {
            if (novaEmbalagem.funcao === "editar") {
                produtoEmbalagens[novaEmbalagem.index] = {
                    ...produtoEmbalagens[novaEmbalagem.index],
                    ...novaEmbalagem,
                };
            } else if (novaEmbalagem.funcao === "deletar") {
                produtoEmbalagens.splice(novaEmbalagem.index, 1);
            } else {
                setEmbalagem([...produtoEmbalagens, novaEmbalagem]);
                setDados({ ...dados, produtoEmbalagens: produtoEmbalagens });
            }
            setNovaEmbalagem({
                produtoId: dados.id,
                empresaId: dados.empresaId,
                empresa: "",
                embalagemId: "",
                embalagem: "",
                uuid: "",
                codigoBarras: "",
                ativo: false,
                funcao: "adicionar",
            });
        }
    };

    async function onFileChange(e) {
        setPreview(Array.from(e.target.files));
        setDados({ ...dados, images: [...e.target.files] });
    }
    async function handleChange(e) {
        const { name, value, checked, type } = e.target;
        const camposMonetarios = [
            "comissao",
            "pesoBruto",
            "pesoLiquido",
            "estoqueMinimo",
            "producaoMinima",
            "pesoMolecular",
            "toleranciaMinima",
            "toleranciaMaxima",
        ];
        const camposBooleanos = [
            "ativo",
            "compra",
            "venda",
            "locavel",
            "embalagem",
            "laminado",
            "sanfonado",
            "separacao",
            "refugo",
            "lavagem",
            "producao",
            "extrusao",
            "finalizaExtrusora",
            "recuperadora",
            "corrugadora",
            "impressora",
            "corteSolda",
        ];
        if (name.startsWith("produtoModelo.")) {
            setProdutoModelo({
                ...produtoModelo,
                [name.split(".")[1]]: value,
            });

            const result = await consultaDatalist(dados.empresaId, "produtoModelo", "id", "descricao", dados.fabricanteId, value);
            setOptionsModelo(result);
            const selectedId = document.querySelector(`datalist option[value="${value}"]`)?.dataset.id;
            setBem({
                ...produtoBens,
                modeloId: selectedId ?? null,
            });
        } else if (name.startsWith("Proprietario.")) {
            setProprietario({
                ...Proprietario,
                [name.split(".")[1]]: value,
            });
            if (name === "Proprietario.descricao") {
                //carrega o datalist
                const result = await consultaDatalistProprietario(dados.empresaId, value, true, null, null, null);
                setOptionsProprietario(result);

                const selectedId = document.querySelector(`datalist option[value="${value}"]`)?.dataset.id;

                setBem((prev) => ({ ...prev, proprietarioId: selectedId ?? null }));
            }
        } else if (name === "tipo") {
            setDados({ ...dados, [name]: value });

            if (value === "Imobilizado" || value === "Material") {
                setTabEnabled({
                    ...tabEnabled,
                    bens: true,
                });
            } else {
                setTabEnabled({
                    ...tabEnabled,
                    bens: false,
                });
            }
        } else if (camposBooleanos.includes(name)) {
            setDados({ ...dados, [name]: checked });
        } else if (name.startsWith("NCM")) {
            setNCM({
                ...NCM,
                [name.split(".")[1]]: value,
            });
            if (name.startsWith("NCM.descricao")) {
                //carrega o datalist

                const result = await consultaDatalist(empresaId, "NCM", "id", "descricao", "", value);
                setOptionsNCM(result);

                const selectedId = document.querySelector(`datalist option[value="${value}"]`)?.dataset.id;
                setDados({
                    ...dados,
                    ncmId: selectedId ?? null,
                });
            }
        } else if (name.startsWith("Fabricante")) {
            setFabricante({
                ...Fabricante,
                [name.split(".")[1]]: value,
            });

            const result = await consultaDatalist(dados.empresaId, "Pessoa", "id", "descricao", "fabricante", value);
            setOptionsFabricante(result);
            const selectedId = document.querySelector(`datalist option[value="${value}"]`)?.dataset.id;

            setDados({
                ...dados,
                fabricanteId: selectedId ?? null,
            });
        } else if (camposMonetarios.includes(name)) {
            const valorFormatado = formatarNumeroDecimal(value);
            setDados({ ...dados, [name]: valorFormatado });
        } else if (name.startsWith("produtoBens.")) {
            if (name === "produtoBens.cep") {
                setBem({
                    ...produtoBens,
                    [name.split(".")[1]]: onlyNumbers(value),
                });
            }
            //para checkbox
            let valor = type === "checkbox" ? checked : value;
            setBem({
                ...produtoBens,
                [name.split(".")[1]]: valor,
            });

            // setDados({ ...dados, produtoBens: produtoBens });
        } else if (name.startsWith("novaEmbalagem.")) {
            // Verifica se é um checkbox e define o valor correspondente
            let valor = type === "checkbox" ? checked : value;

            setNovaEmbalagem({
                ...novaEmbalagem,
                [name.split(".")[1]]: valor,
                empresa:
                    document.getElementById("novaEmbalagem.empresaId").options[
                        document.getElementById("novaEmbalagem.empresaId").selectedIndex
                    ].text,
                embalagem:
                    document.getElementById("novaEmbalagem.embalagemId").options[
                        document.getElementById("novaEmbalagem.embalagemId").selectedIndex
                    ].text,
            });
        } else {
            setDados({ ...dados, [name]: value });
        }
    }
    async function handleOnBlur(e) {
        const { name, value, checked, type } = e.target;
        if (name === "descricao") {
            //verifica duplicidade
            if (name === "descricao" && btnText === "Cadastrar") {
                //verifica se o centroCusto existe na base de dados
                const duplicidade = await TabelaAPI.duplicidadeDescricao(
                    document.getElementById("empresaId").value,
                    "Produto",
                    value
                );

                if (duplicidade?.uuid) {
                    setFlashMessage("Registro em duplicidade!", "warning");
                    return navigate(`/Produtos/CreateOrEdit`, {
                        uuid: duplicidade.uuid,
                    });
                }
            }
        }
    }
    async function handleOnFocus(e) {
        if (e.target.name === "produtoBens.descricao") {
            setBem({
                ...produtoBens,
                descricao: dados.descricao,
            });
        }
    }

    const submit = async (e) => {
        e.preventDefault();
        if (!produtoBens.tipo) {
            produtoBens.tipo = document.getElementById("produtBens.tipo").value;
        }
        if (!dados.empresaId) {
            dados.empresaId = parseInt(localStorage.getItem("empresaId"));
        }
        if (!dados.tipo) {
            const tipoElement = document.getElementById("tipo");
            dados.tipo = tipoElement ? tipoElement.value : "Imobilizado";
        }
        //validações
        if (!dados.descricao) {
            setFlashMessage("A descrição do veículo é um campo obrigatório!", "error");
            return;
        }
        if (!dados.ncmId) {
            setFlashMessage("NCM é um campo obrigatório!", "error");
            return;
        }

        handleSubmit(dados);
    };

    return (
        <Tabs>
            <form onSubmit={submit}>
                <h1>Gestão de Veículos</h1>
                <TabList>
                    <Tab disabled={!tabEnabled.produto} title={"Infomações genericas do veículo"}>
                        Informações
                    </Tab>
                    <Tab disabled={!tabEnabled.bens} title={"Informações do veículo"}>
                        Veículo
                    </Tab>
                    <Tab disabled={!tabEnabled.caracteristicas} title={""}>
                        Características
                    </Tab>
                    <Tab disabled={!tabEnabled.embalagens} title={"Embalagens do produto"}>
                        Embalagens
                    </Tab>
                    <Tab disabled={!tabEnabled.imagens} title={"Imagens do produto"}>
                        Imagens
                    </Tab>
                </TabList>
                <TabPanel>
                    <div className={formStyles.preview_bandeira}>
                        <h4>Informações gerais do veículo</h4>
                    </div>
                    <div>
                        <SelectEmpresa
                            name="empresaId"
                            text="Empresa"
                            options={OptionsEmpresa}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.empresaId}
                            isEnum={false}
                            notOptNull={true}
                        />
                        <Input
                            text="Data Cadastro"
                            type="datetime-local"
                            name="createdAt"
                            placeholder="última atualização"
                            title="Data de inserção do cadastro no sistema"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={(dados.createdAt && retornaDataHora(dados.createdAt)) || retornaDataHora(moment())}
                            readonly={true}
                        />
                        <Input
                            text="Data Atualização"
                            type="datetime-local"
                            name="updatedAt"
                            placeholder="última atualização"
                            title="Data da última atualização dos dados"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={(dados.updatedAt && retornaDataHora(dados.updatedAt)) || retornaDataHora(moment())}
                            readonly={true}
                        />
                        <Checkbox
                            title="Ativa ou Inativa o produto"
                            text="Ativo"
                            name="ativo"
                            type="checkbox"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.ativo}
                            checked={dados.ativo === true ? "checked" : ""}
                        />
                        <Checkbox
                            title="Informa que o produto utiliza a rotina de compras"
                            text="Compra"
                            name="compra"
                            type="checkbox"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.compra}
                            checked={dados.compra === true ? "checked" : ""}
                        />
                        <Checkbox
                            title="Informa que o produto utiliza a rotina de vendas"
                            text="Venda"
                            name="venda"
                            type="checkbox"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.venda}
                            checked={dados.venda === true ? "checked" : ""}
                        />
                        <Checkbox
                            title="Informa que o produto pode ser locado"
                            text="Locavel"
                            name="locavel"
                            type="checkbox"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.locavel}
                            checked={dados.locavel === true ? "checked" : ""}
                        />
                    </div>
                    <div>
                        <Input
                            text="Código"
                            type="text"
                            name="codigo"
                            placeholder="Código"
                            title="O sistema vai gerar um código para o produto"
                            minlength={14}
                            maxlength={18}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.codigo || ""} //posso colocar para gerar um código
                            readonly={true}
                            inputWidth={"150px"}
                        />
                        <Input
                            text="Descrição"
                            type="text"
                            name="descricao"
                            placeholder="Descrição"
                            title="digite a descrição do produto"
                            inputWidth="350px"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.descricao || ""}
                            readonly={false}
                        />
                        <Select
                            name="tipo"
                            text="Tipo"
                            title="Determina o tipo do produto"
                            options={EnumTipo}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.tipo}
                            isEnum={true}
                        />
                    </div>
                    <div>
                        <Input
                            text="referência"
                            type="text"
                            name="referencia"
                            placeholder="Código de referência"
                            title="Digite o código de referência do produto"
                            minlength={14}
                            maxlength={18}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.referencia || ""}
                            inputWidth={"150px"}
                        />
                        <Input
                            text="Código Fabricante"
                            type="text"
                            name="codigoFabricante"
                            placeholder="Código Fabricante"
                            title="Digite o código do fabricante do produto"
                            minlength={14}
                            maxlength={18}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.codigoFabricante || ""}
                            inputWidth={"150px"}
                        />
                        <DataList
                            text="Fabricante"
                            title={"Vincula o Fabricante ao produto"}
                            name="Fabricante.descricao"
                            inputWidth="350px"
                            filteredOptions={OptionsFabricante}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={Fabricante.descricao || ""}
                        />
                    </div>
                    <div>
                        <Input
                            text="NCM"
                            type="text"
                            name="NCM.ncm"
                            placeholder="NCM"
                            inputWidth="150px"
                            title="Código do ncm do produto"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={NCM?.ncm}
                            readonly={true}
                        />
                        <DataList
                            text="NCM Descrição"
                            title={"Vincula o NCM ao produto"}
                            name="NCM.descricao"
                            placeholder={"Digite mais de 3 caracteres para pesquisar"}
                            inputWidth="510px"
                            filteredOptions={OptionsNCM}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={NCM?.descricao}
                            required={true}
                        />
                    </div>
                </TabPanel>
                <TabPanel>
                    <h4>Informações específicas do veículo</h4>
                    <div>
                        <Input
                            text="Patrimônio"
                            type="text"
                            name="produtoBens.patrimonio"
                            placeholder=""
                            title="Número do patrimônio"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={produtoBens.patrimonio || ""}
                            inputWidth={"150px"}
                        />
                        <Input
                            text="Descrição do BEM"
                            type="text"
                            name="produtoBens.descricao"
                            placeholder="Descrição"
                            title="digite a descrição do BEM"
                            inputWidth="350px"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={produtoBens.descricao || ""}
                            readonly={false}
                        />
                        <Select
                            name="produtoBens.tipo"
                            text="Tipo"
                            title="Determina o tipo do produto"
                            options={EnumBemTipo}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={produtoBens.tipo}
                            isEnum={true}
                        />
                        <Checkbox
                            title="Ativa ou Inativa o BEM"
                            text="Ativo"
                            name="produtoBens.ativo"
                            type="checkbox"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={produtoBens.ativo}
                            checked={produtoBens.ativo === true ? "checked" : ""}
                        />
                    </div>
                    <div>
                        <DataList
                            text="Proprietário"
                            name="Proprietario.descricao"
                            placeholder={"Proprietário do bem"}
                            title={
                                "Proprietário do veículo é campo obrigatório, em PESSOAS seleciona a opção LOCADOR na aba financeira"
                            }
                            inputWidth="400px"
                            filteredOptions={OptionsProprietario}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={Proprietario?.descricao || ""}
                        />
                    </div>

                    <div id="divMovel">
                        <h4>Veículo</h4>
                        <div>
                            <DataList
                                text="Modelo"
                                title={"Vincula o modelo do BEM"}
                                name="produtoModelo.descricao"
                                inputWidth="350px"
                                filteredOptions={OptionsModelo}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={produtoModelo.descricao || ""}
                            />
                            <Select
                                name="produtoBens.corId"
                                text="Cor"
                                options={OptionsCores}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={produtoBens.corId}
                                isEnum={false}
                                notOptNull={true}
                            />
                            <Input
                                text="Ano Fabricação"
                                type="number"
                                name="produtoBens.anoFabricacao"
                                placeholder="Ano"
                                title=""
                                inputWidth="100px"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={produtoBens.anoFabricacao || ""}
                            />
                            <Input
                                text="Ano Modelo"
                                type="number"
                                name="produtoBens.anoModelo"
                                placeholder="Ano"
                                title=""
                                inputWidth="100px"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={produtoBens.anoModelo || ""}
                            />
                            <Input
                                text="Km inicial"
                                type="number"
                                name="produtoBens.kmInicial"
                                placeholder="Km"
                                title=""
                                inputWidth="100px"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={produtoBens.kmInicial || ""}
                            />
                        </div>
                        <div>
                            <Input
                                text="Placa"
                                type="text"
                                name="produtoBens.placa"
                                placeholder="Placa"
                                title="digite a descrição do produto"
                                inputWidth="150px"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={produtoBens.placa || ""}
                            />
                            <Input
                                text="Chassi"
                                type="text"
                                name="produtoBens.chassi"
                                placeholder="CHASSI"
                                title="digite a descrição do produto"
                                inputWidth="200px"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={produtoBens.chassi || ""}
                                readonly={false}
                            />
                            <Input
                                text="Renavan"
                                type="number"
                                name="produtoBens.renavan"
                                placeholder="RENAVAN"
                                title="digite a descrição do produto"
                                inputWidth="150px"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={produtoBens.renavan || ""}
                                readonly={false}
                            />
                            <Input
                                text="CRV"
                                type="number"
                                name="produtoBens.crv"
                                placeholder="CRV"
                                title="Número do CRV "
                                inputWidth="150px"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={produtoBens.crv || ""}
                                readonly={false}
                            />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div>
                        <h4>Informações específicas do veículo</h4>
                        <Select
                            name="almoxarifadoId"
                            title="Almoxarifado padrão para o veículo"
                            text="Almoxarifado"
                            inputWidth="200px"
                            options={OptionsAlmoxarifados}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.almoxarifadoId}
                            isEnum={false}
                            notOptNull={false}
                        />
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.preview_bandeira}>
                        <h4>Embalagens do produto</h4>
                    </div>
                    <div>
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "1rem",
                                }}
                            >
                                <SelectEmpresa
                                    name="novaEmbalagem.empresaId"
                                    text="Empresa"
                                    options={OptionsEmpresa}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novaEmbalagem.empresaId}
                                    isEnum={false}
                                    notOptNull={true}
                                />
                                <Select
                                    name="novaEmbalagem.embalagemId"
                                    text="Embalagem"
                                    options={OptionsEmbalagem}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novaEmbalagem.embalagemId}
                                    isEnum={false}
                                    notOptNull={true}
                                />
                                <Input
                                    text="Código de Barras"
                                    type="text"
                                    name="novaEmbalagem.codigoBarras"
                                    placeholder="Código de Barras"
                                    title="digite o código de barras do produto"
                                    inputWidth="200px"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novaEmbalagem.codigoBarras || ""}
                                    readonly={false}
                                />
                                <Checkbox
                                    title="Ativo quando marcado"
                                    text="Ativo"
                                    name="novaEmbalagem.ativo"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novaEmbalagem.ativo}
                                    checked={novaEmbalagem.ativo === true ? "checked" : ""}
                                />{" "}
                                <Button
                                    variant="contained"
                                    color="inherit"
                                    onClick={() =>
                                        setNovaEmbalagem({
                                            uuid: "",
                                            empresaId: dados.empresaId,
                                            produtoId: dados.id,
                                            embalagemId: "",
                                            codigoBarras: "",
                                            ativo: false,
                                            funcao: "adicionar",
                                        })
                                    }
                                    style={{ marginLeft: "10px" }}
                                >
                                    Limpar
                                </Button>
                                <Button
                                    variant="contained"
                                    color={novaEmbalagem.funcao === "deletar" ? "error" : "info"}
                                    onClick={() => handleEmbalagem(novaEmbalagem)}
                                    disabled={novaEmbalagem.codigoBarras?.length < 1 ? true : false}
                                    style={{ marginLeft: "5px" }}
                                >
                                    {novaEmbalagem.funcao}
                                </Button>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "1rem",
                                }}
                            ></div>
                        </div>
                        <div>
                            <table className={`${formStyles.table} ${formStyles[parametro.tema]}`}>
                                <thead>
                                    <tr>
                                        <th className={formStyles.th} align="left">
                                            Empresa
                                        </th>
                                        <th className={formStyles.th} align="left">
                                            Embalagem
                                        </th>
                                        <th className={formStyles.th} align="left">
                                            Código de Barra
                                        </th>
                                        <th className={formStyles.th} align="center">
                                            Ações
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className={formStyles.tbody}>
                                    <>
                                        {Object?.keys(produtoEmbalagens)?.map((key, index, array) => (
                                            <tr className={formStyles.tr} key={produtoEmbalagens[key].uuid || index}>
                                                <td
                                                    className={formStyles.td}
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >{`${
                                                    produtoEmbalagens[key]?.empresa ||
                                                    produtoEmbalagens[key]?.Empresa?.nomeFantasia
                                                }`}</td>
                                                <td
                                                    className={formStyles.td}
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >{`${
                                                    produtoEmbalagens[key]?.embalagem ||
                                                    produtoEmbalagens[key]?.Embalagem?.descricao
                                                }`}</td>
                                                <td
                                                    className={formStyles.td}
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >{`${produtoEmbalagens[key]?.codigoBarras}`}</td>
                                                <td className={formStyles.td}>
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() =>
                                                            setNovaEmbalagem({
                                                                index: index,
                                                                uuid: produtoEmbalagens[key].uuid,
                                                                produtoId: produtoEmbalagens[key].produtoId,

                                                                empresaId: produtoEmbalagens[key].empresaId,
                                                                empresa:
                                                                    document.getElementById("novaEmbalagem.empresaId").options[
                                                                        document.getElementById("novaEmbalagem.empresaId")
                                                                            .selectedIndex
                                                                    ].text,
                                                                embalagemId: produtoEmbalagens[key].embalagemId,
                                                                embalagem: produtoEmbalagens[key].embalagem,
                                                                codigoBarras: produtoEmbalagens[key].codigoBarras,
                                                                ativo: produtoEmbalagens[key].ativo,
                                                                funcao: "editar",
                                                            })
                                                        }
                                                    >
                                                        Editar
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        onClick={() =>
                                                            setNovaEmbalagem({
                                                                index: index,
                                                                uuid: produtoEmbalagens[key].uuid,
                                                                produtoId: produtoEmbalagens[key].produtoId,

                                                                empresaId: produtoEmbalagens[key].empresaId,
                                                                embalagemId: produtoEmbalagens[key].embalagemId,
                                                                codigoBarras: produtoEmbalagens[key].codigoBarras,
                                                                ativo: produtoEmbalagens[key].ativo,
                                                                funcao: "deletar",
                                                            })
                                                        }
                                                        style={{
                                                            marginLeft: "5px",
                                                        }}
                                                    >
                                                        Excluir
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </TabPanel>

                <TabPanel>
                    <div className={formStyles.preview_bandeira}>
                        <h4>Imagens do Produto</h4>
                    </div>
                    <div className={formStyles.preview_bandeira}>
                        <div>
                            <Input
                                text="Fotos do veículo"
                                type="file"
                                name="images"
                                handleOnChange={onFileChange}
                                inputWidth="400px"
                                multiple={true}
                                accept=".jpg, .jpeg, .png"
                            />
                        </div>
                        {preview.length > 0
                            ? preview.map((image, index) => (
                                  <img
                                      src={URL.createObjectURL(image)}
                                      alt={dados.images}
                                      key={`${dados.images}+${index}`}
                                      style={{
                                          borderColor: "black",
                                          border: "solid 0.05em",
                                      }}
                                      width={60}
                                      height={80}
                                  />
                              ))
                            : produtoImagems &&
                              produtoImagems.map((imagem, index) => (
                                  <img
                                      src={process.env.REACT_APP_API + "imagens/" + imagem.descricao}
                                      alt={`Imagem ${index + 1}`}
                                      key={imagem.uuid || index}
                                      title={process.env.REACT_APP_API + "imagens/" + imagem.descricao}
                                      style={{
                                          borderColor: "black",
                                          border: "solid 0.05em",
                                          margin: "2px",
                                      }}
                                      loading="lazy"
                                      width={60}
                                      height={80}
                                  />
                              ))}
                    </div>
                </TabPanel>
                <div>
                    <Stack direction="row" spacing={1}>
                        <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                            Voltar
                        </Button>
                        <Button type="submit" variant="contained" color="success">
                            {btnText}
                        </Button>
                    </Stack>
                </div>
            </form>
        </Tabs>
    );
}

export default Form;
