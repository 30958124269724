import { useContext } from "react";
import { Context } from "../../context/UserContext";
import styles from "./DataList.module.css";

function DataList({
    type,
    text,
    name,
    handleOnChange,
    handleOnBlur,
    handleOnFocus,
    value,
    filteredOptions,
    inputWidth,
    title,
    readonly,
    placeholder,
    required,
}) {
    //configura o tema
    const { parametro } = useContext(Context);

    const Options =
        filteredOptions && filteredOptions.length > 0
            ? filteredOptions.filter((option) => option.descricao?.toLowerCase().includes(value?.toString().toLowerCase()))
            : [];

    return (
        <div className={`${styles.form_control} ${styles[parametro.tema]}`}>
            {type !== "hidden" && (
                <label htmlFor={name} title={title}>
                    {text}:
                </label>
            )}

            <input
                type={type}
                name={name}
                list={name}
                placeholder={placeholder}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onFocus={handleOnFocus}
                value={value}
                style={{
                    width: `var(--input-width, ${inputWidth})`,
                }}
                readOnly={readonly === true ? true : false}
                required={required}
            />
            <datalist id={name}>
                {Options.map((option) => (
                    <option key={option.id} data-id={option.id} id={option.id} value={option.descricao} />
                ))}
            </datalist>
        </div>
    );
}

export default DataList;
