import { useContext } from "react";
import styles from "./Container.module.css";
import { Context } from "../../context/UserContext";

function Container({ children }) {
    const { parametro } = useContext(Context); // Obtenha o parâmetro do contexto

    // Determine a cor de fundo com base no parâmetro
    const backgroundColor = parametro.tema === "dark" || !parametro.tema ? "#1e1e1e" : "#ffe7e7";

    // Combine as classes CSS dinamicamente usando o operador spread
    const containerClasses = [styles.container, parametro.tema === "dark" ? styles.customBackground : ""].join(" ");

    return (
        <main className={containerClasses} style={{ backgroundColor }}>
            {children}
        </main>
    );
}

export default Container;
