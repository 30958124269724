import { createContext } from "react";
import { Provider } from "react-redux";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import ptBR from "date-fns/locale/pt-BR";

import { ThemeProvider } from "@mui/material";
import tableThemeCustom from "../components/form/tableThemeCustom";

import store from "../store";
import useAuth from "../hooks/useAuth";
const Context = createContext();

function UserProvider({ children }) {
    const { login, logout, authenticated, permissaoUsuario, parametro } = useAuth();
    const theme = tableThemeCustom(parametro?.tema ?? "light"); // Define o tema de acordo com o parâmetro

    return (
        <Provider store={store}>
            <Context.Provider
                value={{
                    login,
                    logout,
                    permissaoUsuario,
                    authenticated,
                    parametro,
                }}
            >
                <ThemeProvider theme={theme}>
                    {/* Adiciona o LocalizationProvider com o adaptador correto */}
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                        {children}
                    </LocalizationProvider>
                </ThemeProvider>
            </Context.Provider>
        </Provider>
    );
}

export { Context, UserProvider };
