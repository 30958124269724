import { useContext } from "react";
import { Context } from "../../context/UserContext";
import styles from "./Input.module.css";

function Input({
    type,
    text,
    name,
    placeholder,
    handleOnChange,
    handleOnBlur,
    handleOnFocus,
    value,
    title,
    required,
    multiple,
    inputWidth = "150px",
    readonly,
    min,
    max,
    textAlign,
    accept,
}) {
    //configura o tema
    const { parametro } = useContext(Context);

    const isColorInput = type === "color";

    return (
        <div className={`${styles.form_control} ${styles[parametro.tema]}`}>
            {type !== "hidden" && <label htmlFor={name}>{text}:</label>}
            {isColorInput ? (
                <div>
                    {" "}
                    <input
                        type={type}
                        name={name}
                        id={name}
                        onChange={handleOnChange}
                        onBlur={handleOnBlur}
                        onFocus={handleOnFocus}
                        value={value}
                        title={title}
                        style={{
                            width: `var(--input-width, ${inputWidth})`,
                            textAlign: textAlign,
                            height: `37px`,
                            backgroundColor: value,
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                        }}
                    />
                </div>
            ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                        type={type}
                        name={name}
                        id={name}
                        placeholder={placeholder}
                        onChange={handleOnChange}
                        onBlur={handleOnBlur}
                        onFocus={handleOnFocus}
                        value={value}
                        title={title}
                        required={required}
                        style={{
                            width: `var(--input-width, ${inputWidth})`,
                            textAlign: textAlign,
                        }}
                        {...(multiple ? { multiple } : "")}
                        readOnly={readonly === true ? true : false}
                        min={min}
                        max={max}
                        accept={accept}
                    />
                    {type === "range" && <span style={{ marginLeft: "10px" }}>{value}</span>}
                </div>
            )}
        </div>
    );
}

export default Input;
