import { useState, useEffect } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Stack, Button } from "@mui/material";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import * as EquipeAPI from "../../../api/equipe";
import * as PessoaAPI from "../../../api/pessoa";
import * as TabelaAPI from "../../../api/tabela";
import * as DataListAPI from "../../../api/dataList";

import formStyles from "../../form/Form.module.css";

import DataList from "../../form/DataList";
import Input from "../../form/Input";
import Checkbox from "../../form/Checkbox";
import SelectEmpresa from "../../form/SelectEmpresa";
import Select from "../../form/Select";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, OptionsEmpresa }) {
    const [dados, setDados] = useState({});
    const [pessoa, setPessoa] = useState({});
    const [EquipeIntegrantes, setEquipeIntegrantes] = useState([]);
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();

    if (!dados.empresaId) {
        dados.ativo = true;
        dados.empresaId = localStorage.getItem("empresaId"); //parseInt(document.getElementById("empresaId").value);
        dados.tipo = "Produção";
    }

    const [novoColaborador, setNovoColaborador] = useState({});

    //abas
    const tabEnabled = {
        cabeçalho: true,
        integrantes: true,
    };

    //set datalist
    const [OptionsColaborador, setOptionsColaborador] = useState([]);
    const [EnumCargo, setEnumCargo] = useState([]);

    //Select
    const [OptionsTipo, setOptionsTipo] = useState([]);

    //Atualiza o Datalist
    const consultaDatalistColaborador = async (empresaId, filtro, ativo, tipo) => {
        let result = await DataListAPI.dataListColaborador(empresaId, filtro, ativo, tipo);
        return result;
    };

    //Consultas
    const consultaEnum = async (table, field, filter) => {
        const result = await TabelaAPI.enums(table, field, filter);
        if (table === "EquipeIntegrantes") {
            setEnumCargo(result ? result : []);
        } else if (table === "Equipes") {
            setOptionsTipo(result ? result : []);
        }
    };

    useEffect(() => {
        setDados(formData || {});
        setEquipeIntegrantes(formData?.EquipeIntegrantes || []);
        setNovoColaborador({
            uuid: null,
            equipeId: dados.id,
            pessoaId: null,
            cargo: null,
            funcao: "adicionar",
            Pessoa: { CPForCNPJ: null, descricao: null },
            ativo: false,
        });

        consultaEnum("Equipes", "tipo");
        consultaEnum("EquipeIntegrantes", "cargo", dados.tipo);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    useEffect(() => {
        consultaEnum("EquipeIntegrantes", "cargo", dados.tipo);
    }, [dados.tipo]);

    useEffect(() => {
        //atualizar a tabela pessoa com as tabels filhas
        setDados((prev) => {
            return {
                ...prev,
                EquipeIntegrantes: EquipeIntegrantes,
            };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [EquipeIntegrantes]);

    useEffect(() => {
        const fetchData = async () => {
            const result = await PessoaAPI.pessoaById(novoColaborador.pessoaId);

            setNovoColaborador({
                ...novoColaborador,
                Pessoa: { CPForCNPJ: result.data.CPForCNPJ, descricao: result.data.descricao },
            });
        };

        novoColaborador.pessoaId && fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [novoColaborador.pessoaId]);

    //funções
    const handleColaborador = async () => {
        if (dados.id) {
            const result = await EquipeAPI.integrantes(novoColaborador);
            if (result.status === 202) {
                setNovoColaborador({
                    ...novoColaborador,
                    uuid: result.data.uuid,
                    funcao: "editar",
                });
                setFlashMessage("Integrante já consta na EQUIPE?", "error");
                return;
            }
            setFlashMessage(result.data.message, result.data.type);
            if (result.data.type === "success") {
                setEquipeIntegrantes(result.data.integrantes);
            }
        } else {
            if (novoColaborador.funcao === "editar") {
                EquipeIntegrantes[novoColaborador.index] = {
                    ...EquipeIntegrantes[novoColaborador.index],
                    ...novoColaborador,
                };
            } else if (novoColaborador.funcao === "deletar") {
                EquipeIntegrantes.splice(novoColaborador.index, 1);
            } else {
                try {
                    setEquipeIntegrantes((prevEquipeIntegrantes) => [...prevEquipeIntegrantes, novoColaborador]);
                } catch (error) {
                    console.error("Erro ao adicionar novo colaborador:", error);
                }
            }
        }
        setNovoColaborador({
            uuid: null,
            equipeId: dados.id,
            pessoaId: null,
            ativo: false,
            cargo: "Extrusor",
            funcao: "adicionar",
        });

        setPessoa({});
    };
    async function handleChange(e) {
        const { name, value, checked, type, options, selectedIndex } = e.target;

        if (name === "ativo") {
            setDados({ ...dados, [name]: checked });
        } else if (name.startsWith("Pessoa")) {
            setPessoa({
                ...pessoa,
                [name.split(".")[1]]: value,
            });

            const result = await consultaDatalistColaborador(dados.empresaId, value, true, null);
            setOptionsColaborador(result);

            const selectedId = document.querySelector(`datalist option[value="${value}"]`)?.dataset.id;
            setNovoColaborador((prevState) => ({
                ...prevState,
                pessoaId: selectedId,
            }));
        } else if (name.startsWith("novoColaborador")) {
            if (name === "novoColaborador.cargo") {
                setNovoColaborador({
                    ...novoColaborador,
                    equipeId: dados.id,
                    cargo: options[selectedIndex].text,
                });
            } else if (name === "novoColaborador.ativo") {
                setNovoColaborador({
                    ...novoColaborador,
                    equipeId: dados.id,
                    ativo: checked,
                });
            }

            setDados({ ...dados, EquipeIntegrantes: EquipeIntegrantes });
        } else {
            setDados({ ...dados, [e.target.name]: e.target.value });
        }
    }
    async function handleOnBlur(e) {
        if (e.target.name === "descricao" && btnText === "Cadastrar") {
            //verifica duplicidade
            const duplicidade = await TabelaAPI.duplicidadeDescricao(
                document.getElementById("empresaId").value,
                "Equipe",
                e.target.value
            );

            if (duplicidade?.uuid) {
                setFlashMessage("Registro em duplicidade!", "warning");
                return navigate(`/Equipes/CreateOrEdit`, {
                    uuid: duplicidade.uuid,
                });
            }
        }
    }
    async function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();
        handleSubmit(dados);
    };

    return (
        <Tabs>
            <form onSubmit={submit}>
                <h1>Equipe</h1>
                <TabList>
                    <Tab disabled={!tabEnabled.cabeçalho} title={"Cabeçalho da equipe"}>
                        Cabeçalho
                    </Tab>
                    <Tab disabled={!tabEnabled.integrantes} title={"Integrantes da equipe"}>
                        Integrantes
                    </Tab>
                </TabList>
                <TabPanel>
                    <div>
                        <SelectEmpresa
                            text="Selecione a Empresa"
                            name="empresaId"
                            options={OptionsEmpresa}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.empresaId || ""}
                            required
                            notOptNull={true}
                        />
                        <Input
                            text="Descrição"
                            type="text"
                            name="descricao"
                            placeholder="Equipe"
                            title="Digite o nome da equipe"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.descricao || ""}
                            required={true}
                        />
                        <Select
                            name="tipo"
                            title="Selecione o tipo"
                            text="Tipo"
                            inputWidth="150px"
                            options={OptionsTipo}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.tipo}
                            isEnum={true}
                            notOptNull={true}
                            required={true}
                        />
                        <Checkbox
                            title="Ativa quando marcado"
                            text="Ativo"
                            name="ativo"
                            type="checkbox"
                            handleOnChange={handleChange}
                            value={dados.ativo}
                            checked={dados.ativo === true ? "checked" : ""}
                        />
                    </div>
                </TabPanel>
                <TabPanel>
                    <div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "1rem",
                            }}
                        >
                            <DataList
                                text="Colaborador"
                                name="Pessoa.descricao"
                                inputWidth="300px"
                                filteredOptions={OptionsColaborador}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoa.descricao || ""}
                            />
                            <Select
                                name="novoColaborador.cargo"
                                text="Cargo"
                                options={EnumCargo}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={novoColaborador.cargo}
                                isEnum={true}
                            />
                            <Checkbox
                                title="Ativa"
                                text="Ativo"
                                name="novoColaborador.ativo"
                                type="checkbox"
                                handleOnChange={handleChange}
                                value={novoColaborador.ativo}
                                checked={novoColaborador.ativo === true ? "checked" : ""}
                            />
                            <Button
                                variant="contained"
                                color="inherit"
                                onClick={() => {
                                    setNovoColaborador({
                                        uuid: null,
                                        pessoaId: null,
                                        equipeId: dados.id,
                                        funcao: "adicionar",
                                    });
                                    setOptionsColaborador({
                                        id: null,
                                        descricao: null,
                                    });
                                }}
                                style={{ marginLeft: "10px" }}
                            >
                                Limpar
                            </Button>
                            <Button
                                variant="contained"
                                color={novoColaborador.funcao === "deletar" ? "error" : "info"}
                                onClick={() => handleColaborador(novoColaborador)}
                                disabled={!novoColaborador.pessoaId ? true : false}
                                style={{ marginLeft: "5px" }}
                            >
                                {novoColaborador.funcao}
                            </Button>
                        </div>
                        <div>
                            <table className={formStyles.table}>
                                <thead>
                                    <tr>
                                        <th className={formStyles.th} align="left">
                                            CPF
                                        </th>
                                        <th className={formStyles.th} align="left">
                                            Colaborador
                                        </th>
                                        <th className={formStyles.th} align="left">
                                            Cargo
                                        </th>
                                        <th className={formStyles.th} align="left">
                                            Ativo
                                        </th>
                                        <th className={formStyles.th} align="center">
                                            Ações
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className={formStyles.tbody}>
                                    <>
                                        {Object.keys(EquipeIntegrantes).map((key, index, array) => (
                                            <tr className={formStyles.tr} key={EquipeIntegrantes[key].uuid || index}>
                                                <td
                                                    className={formStyles.td}
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    {EquipeIntegrantes[key].Pessoa?.CPForCNPJ}
                                                </td>
                                                <td
                                                    className={formStyles.td}
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    {EquipeIntegrantes[key].Pessoa?.descricao}
                                                </td>
                                                <td className={formStyles.td}>{EquipeIntegrantes[key].cargo}</td>
                                                <td className={formStyles.td}>{EquipeIntegrantes[key].ativo ? "Sim" : "Não"}</td>
                                                <td className={formStyles.td}>
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() => {
                                                            setNovoColaborador({
                                                                index: index,
                                                                uuid: EquipeIntegrantes[key].uuid,
                                                                pessoaId: EquipeIntegrantes[key].pessoaId,
                                                                equipeId: EquipeIntegrantes[key].equipeId,
                                                                cargo: EquipeIntegrantes[key].cargo,
                                                                ativo: EquipeIntegrantes[key].ativo,
                                                                funcao: "editar",
                                                            });
                                                            setPessoa({
                                                                id: EquipeIntegrantes[key].Pessoa.id,
                                                                descricao: EquipeIntegrantes[key].Pessoa.descricao,
                                                            });
                                                        }}
                                                    >
                                                        Editar
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        onClick={() => {
                                                            setNovoColaborador({
                                                                index: index,
                                                                uuid: EquipeIntegrantes[key].uuid,
                                                                pessoaId: EquipeIntegrantes[key].pessoaId,
                                                                equipeId: EquipeIntegrantes[key].equipeId,
                                                                cargo: EquipeIntegrantes[key].cargo,
                                                                ativo: EquipeIntegrantes[key].ativo,
                                                                funcao: "deletar",
                                                            });
                                                            setPessoa({
                                                                id: EquipeIntegrantes[key].Pessoa.id,
                                                                descricao: EquipeIntegrantes[key].Pessoa.descricao,
                                                            });
                                                        }}
                                                        style={{
                                                            marginLeft: "5px",
                                                        }}
                                                    >
                                                        Excluir
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </TabPanel>
                <div>
                    <Stack direction="row" spacing={1}>
                        <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                            Voltar
                        </Button>
                        <Button type="submit" variant="contained" color="success">
                            {btnText}
                        </Button>
                    </Stack>
                </div>
            </form>
        </Tabs>
    );
}
export default Form;
