import { useState, useEffect, useCallback } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import moment from "moment";
import "moment/locale/pt-br";

import { Stack, Button } from "@mui/material";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

/* API's */
import * as DataListAPI from "../../../api/dataList";
import * as FinanceiroAPI from "../../../api/financeiro";
import * as PessoaAPI from "../../../api/pessoa";
import * as TabelaAPI from "../../../api/tabela";
import * as VerificadoresAPI from "../../../api/verificadores";

import Input from "../../form/Input";
import InputNumber from "../../form/InputNumber";
import Select from "../../form/Select";
import Checkbox from "../../form/Checkbox";
import DataList from "../../form/DataList";
import SelectEmpresa from "../../form/SelectEmpresa";

import formStyles from "../../form/Form.module.css";
//import formTabsStyles from "../../form/FormTabs.module.css";
import { ValidaEmail } from "../../../utils/validacoes";
/* funções */
import { retornaDataHora, retornaData, retornaDataBr, retornaDataBrToBancoDados } from "../../../utils/retornaDataFormatada";

/* utils */
import { formatarNumero, formatarNumeroDecimal } from "../../../utils/masks";
import maskCPForCNPJ from "../../../utils/maskCPForCNPJ";
import maskCEP from "../../../utils/maskCEP";
//import { json } from "react-router-dom";
const onlyNumbers = (str) => str.replace(/[^0-9]/g, "");

//variavel para constrolar os acessoa a API receitaws, só permite 3 consultas por minuto
let lastQueryTime = 0;
let totalConsultasAPI = 0;

function Form({
    token,
    handleSubmit,
    formData,
    btnText,
    caminhoVoltar,
    EnumTipo,
    EnumVinculoFinanceiro,
    EnumOrigem,
    EnumSexo,
    EnumTipoSanguineo,
    EnumEstadoCivil,
    EnumCnhCategoria,
    EnumRamoAtividade,
    EnumPJTipo,
    EnumTipoEndereco,
    EnumTipoEmail,
    EnumTipoTelefone,
    ufs,
    municipios,
    OptionsEmpresa,
    OptionsFormaFornecedor,
    OptionsFormaCliente,
    OptionsConta,
}) {
    const [pessoa, setPessoa] = useState({});
    const [OptionsPCliente, setPCliente] = useState({});
    const [OptionsPFornecedor, setPFornecedor] = useState({});
    const [pessoaFisica, setPessoaFisica] = useState({});
    const [pessoaJuridica, setPessoaJuridica] = useState({});
    const [pessoaEnderecos, setPessoaEndereco] = useState([]);
    const [pessoaEmails, setPessoaEmail] = useState([]);
    const [pessoaTelefones, setPessoaTelefone] = useState([]);
    const [pessoaNomes, setPessoaNome] = useState([]);
    const [pessoaUsuario, setPessoaUsuario] = useState({});
    const [pessoaColaborador, setPessoaColaborador] = useState({});
    const [pessoaFuncionarios, setPessoaFuncionarios] = useState([]);
    const [pessoaTabelaPrecos, setPessoaTabelaPrecos] = useState([]);
    const [pessoaEmpresas, setPessoaEmpresas] = useState([]);
    const [Funcionarios, setFuncionarios] = useState({});

    // obter o nome para preencher na lista
    const [Pessoa, setPessoas] = useState({});
    const [tabelaPreco, setTabelaPrecos] = useState({ descricao: null });

    //set datalist
    const [centroCustoCliente, setCCC] = useState({});
    const [OptionsCCC, setOptionsCCC] = useState([]);
    const [centroCustoFornecedor, setCCF] = useState({});
    const [OptionsCCF, setOptionsCCF] = useState([]);
    const [OptionsPessoa, setOptionsPessoa] = useState([]);
    const [OptionsTabelaPreco, setOptionsTabelaPreco] = useState([]);

    //enums
    const [Optionsmunicipios, setMunicipio] = useState();
    //para pessoa vazio preenche valores pré definidos
    if (!pessoa.empresaId) {
        pessoa.empresaId = localStorage.getItem("empresaId");
        pessoa.ativo = true;
    }
    //rotina para criar objetos de tabelas filhas
    const [novoNome, setNovoNome] = useState({
        pessoaId: pessoa.id || "",
        uuid: "",
        nome: "",
        origem: "",
        data: "", //retornaData(moment()),
        ativo: false,
        funcao: "adicionar",
    });
    const [novoEndereco, setNovoEndereco] = useState({
        pessoaId: pessoa.id,
        uuid: "",
        cep: "",
        latitude: null,
        longitude: null,
        ufId: "",
        municipioId: "",
        bairro: "",
        logradouro: "",
        numero: "",
        complemento: "",
        tipo: "Comercial",
        principal: false,
        municipio: "",
        uf: "",
        ativo: false,
        funcao: "adicionar",
    });
    const [novoEmail, setNovoEmail] = useState({
        pessoaId: pessoa.id,
        uuid: "",
        tipo: "Outros",
        email: "",
        principal: false,
        ativo: false,
        funcao: "adicionar",
    });
    const [novoTelefone, setNovoTelefone] = useState({
        pessoaId: pessoa.id,
        uuid: "",
        tipo: "Pessoal",
        numero: "",
        zap: false,
        telegram: false,
        ativo: false,
        funcao: "adicionar",
    });
    const [novaFilial, setNovaFilial] = useState({
        uuid: null,
        pessoaId: pessoa.id,
        empresaId: null,
        ativo: false,
        funcao: "adicionar",
    });
    const [novoFuncionario, setNovoFuncionario] = useState({
        uuid: null,
        Pessoa,
        pessoaId: pessoa.id,
        funcionarioId: null,
        descricao: null,
        ativo: false,
        funcao: "adicionar",
    });
    const [novaTabelaPreco, setNovaTabelaPreco] = useState({
        uuid: null,
        tabelaPreco,
        pessoaId: pessoa.id,
        tabelaPrecoId: null,
        descricao: null,
        ativo: false,
        funcao: "adicionar",
    });

    const CCC_Descricao = useCallback(() => {
        // Definição da função CCC_Descricao
        if (!pessoa.centroCustoClienteId) {
            return;
        }

        TabelaAPI.tabelaById(pessoa.empresaId, "centroCusto", pessoa.centroCustoClienteId)
            .then((dado) => {
                // Trate o resultado aqui
                if (dado) {
                    setCCC({
                        ...centroCustoCliente,
                        id: dado.id,
                        descricao: dado.descricao,
                    });
                }
            })
            .catch((error) => {
                // Trate o erro aqui
                console.error(error);
            });
    }, [centroCustoCliente, pessoa.empresaId, pessoa.centroCustoClienteId]);
    const CCF_Descricao = useCallback(() => {
        // Definição da função CCC_Descricao
        if (!pessoa.centroCustoFornecedorId) {
            return;
        }

        TabelaAPI.tabelaById(pessoa.empresaId, "centroCusto", pessoa?.centroCustoFornecedorId)
            .then((dado) => {
                if (dado) {
                    // Trate o resultado aqui
                    setCCF({
                        ...centroCustoFornecedor,
                        id: dado.id,
                        descricao: dado.descricao,
                    });
                }
            })
            .catch((error) => {
                // Trate o erro aqui
                console.error(error);
            });
    }, [centroCustoFornecedor, pessoa.empresaId, pessoa.centroCustoFornecedorId]);
    const [preview, setPreview] = useState([]);
    //consulta o prazo de pagamentos
    const consultaPrazo = useCallback(
        async (tipo, formaId, nivel, prazo, empresa = pessoa.empresaId) => {
            const dados = await FinanceiroAPI.prazo(tipo, formaId, nivel, prazo, empresa);

            if (tipo === "Pagar") {
                setPFornecedor(dados);
            } else {
                setPCliente(dados);
            }

            return dados;
        },
        [pessoa.empresaId, setPFornecedor, setPCliente]
    );

    //Atualiza o Datalist
    const consultaDatalist = async (token, empresaId, tabela, id, descricao, condicao, filtro) => {
        let result = await DataListAPI.dataList(empresaId, tabela, id, descricao, condicao, filtro);
        return result;
    };

    //abas
    const [tabEnabled, setTabEnabled] = useState({});

    useEffect(() => {
        setPessoa(formData || {});

        setPessoaFisica(formData?.pessoaFisica || {});
        setPessoaJuridica(formData?.pessoaJuridica || {});
        setPessoaUsuario(formData?.pessoaUsuario || {});
        setPessoaColaborador(formData?.pessoaColaborador || {});

        setPessoaEndereco(formData?.pessoaEnderecos || []);
        setPessoaEmpresas(formData?.pessoaEmpresas || []);
        setPessoaFuncionarios(formData?.pessoaFuncionarios || []);
        setPessoaTabelaPrecos(formData?.pessoaTabelaPrecos || []);
        setPessoaEmail(formData?.pessoaEmails || []);
        setPessoaTelefone(formData?.pessoaTelefones || []);
        setPessoaNome(formData?.pessoaNomes || []);

        setPessoas({ descricao: null });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    //inseri a descricao dos datalist quando a pessoa muda
    useEffect(() => {
        CCC_Descricao();
        CCF_Descricao();

        const tamanhoCPForCNPJ = pessoa.CPForCNPJ ? pessoa.CPForCNPJ.replace(/\D/g, "").length : 0;

        setTabEnabled({
            pessoa: true,
            fisica: tamanhoCPForCNPJ <= 11 ? true : false,
            juridica: tamanhoCPForCNPJ > 11 ? true : false,
            financeiro: true,
            endereco: true,
            email: true,
            telefone: true,
            nome: localStorage.getItem("ramoAtividade") === "Governo" ? true : false,
            usuario: true,
            colaborador: tamanhoCPForCNPJ > 11 ? false : true,
            funcionario: true,
            filial: tamanhoCPForCNPJ > 11 ? false : true,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pessoa]);

    useEffect(() => {
        consultaPrazo("Pagar");
        consultaPrazo("Receber");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pessoa.prazoClienteId, pessoa.prazoFornecedorId]);

    useEffect(() => {
        setPessoa((prev) => {
            return {
                ...prev,
                pessoaFuncionarios: pessoaFuncionarios,
            };
        });
    }, [pessoaFuncionarios]);
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();

    /* API Externa */
    /* 
        existe um problema com o código de limitação de requisições por minuto, 
        é o caso de vários usuários estarem fazendo as solicitações
    */
    const receitaWS = async (cnpj) => {
        const currentTime = Date.now();
        if (currentTime - lastQueryTime >= 60000 || totalConsultasAPI < 3) {
            lastQueryTime = Date.now();
            totalConsultasAPI = totalConsultasAPI + 1;
            const result = await VerificadoresAPI.cnpj(cnpj);

            if (result.status === "ERROR") {
                setFlashMessage(result.message, "error");
                //navigate("/Pessoa");
            } else {
                setPessoaJuridica({
                    ...pessoaJuridica,
                    razaoSocial: result.nome,
                    nomeFantasia: result.fantasia,
                    abertura: retornaDataBrToBancoDados(result.abertura),
                    tipo: result.tipo,
                    ramoAtividade: "Industria",
                });

                const newEndereco = {
                    uuid: "",
                    pessoaId: "",
                    cep: result.cep,
                    ufId: result.ufId,
                    municipioId: result.municipioId,
                    bairro: result.bairro,
                    logradouro: result.logradouro,
                    numero: result.numero,
                    complemento: result.complemento,
                    tipo: "Comercial",
                    principal: true,
                    ativo: true,
                    funcao: "adicionar",
                };
                const newEmail = {
                    email: result.email,
                    tipo: "Comercial",
                    principal: false,
                    ativo: false,
                };
                //telefones vindos do site da receitaws sendo tratados
                const telefonesSeparados = result.telefone?.split("/");
                const newTelefone = telefonesSeparados?.map((telefone) => ({
                    numero: onlyNumbers(telefone),
                    tipo: "Trabalho",
                    zap: false,
                    telegram: false,
                    ativo: true,
                }));

                setPessoa({
                    ...pessoa,
                    //RGorIE: null,
                    nomePrincipal: result.nome,
                    tipo: "Jurídica",
                    vinculoFinanceiro: "Ambos",
                    pessoaJuridica: {
                        razaoSocial: result.nome,
                        nomeFantasia: result.fantasia,
                        abertura: retornaDataBrToBancoDados(result.abertura),
                        tipo: result.tipo,
                        ramoAtividade: "Industria",
                    },
                    pessoaEnderecos: [...pessoaEnderecos, newEndereco],
                    pessoaEmails: [...pessoaEmails, newEmail],
                    pessoaTelefones: newTelefone,
                });
                /* fim do tratamento */
                setPessoaEndereco([...pessoaEnderecos, newEndereco]);
                if (result.email) {
                    setPessoaEmail([...pessoaEmails, newEmail]);
                }
                if (result.telefone) {
                    setPessoaTelefone(newTelefone);
                }
            }
        } else {
            alert(
                "Você precisa aguardar mais um pouco antes de fazer outra consulta. O limite do site são de 3 consultas por minuto"
            );
        }
    };
    const consultaCEP = async (cep) => {
        const result = await VerificadoresAPI.cep(cep);
        if (result.type === "error") {
            setFlashMessage(result.message, result.type);
        } else {
            //atualiza o municipio se estiver em outra uf
            const cidades = await VerificadoresAPI.municipio(result.ufId, "", "");
            setMunicipio(cidades);

            setNovoEndereco({
                ...novoEndereco,
                ufId: result.ufId,
                municipioId: result.municipioId,
                bairro: result.neighborhood,
                logradouro: result.street,
            });
        }
        //consultaMUNICIPIO(result.ufId);
    };

    //API's do backend
    /*enderecos*/
    const handleEndereco = async () => {
        /* grava no banco de dados o sistema entende que esta no modo EDIT */
        if (pessoa.id) {
            //setNovoEndereco({ ...novoEndereco, pessoaId: pessoa.id });
            novoEndereco.pessoaId = pessoa.id;
            const result = await PessoaAPI.enderecos(novoEndereco);
            setFlashMessage(result.data.message, result.data.type);
            if (result.data.type === "success") {
                setPessoaEndereco(result.data.resultado);
            } else {
                setPessoaEndereco(result.data.message);
            }
            setNovoEndereco({
                pessoaId: pessoa.id,
                uuid: "",
                cep: "",
                latitude: null,
                longitude: null,
                ufId: "",
                municipioId: "",
                bairro: "",
                logradouro: "",
                numero: "",
                complemento: "",
                tipo: "",
                principal: false,
                ativo: false,
                funcao: "adicionar",
            });
        } else {
            if (novoEndereco.funcao === "editar") {
                pessoaEnderecos[novoEndereco.index] = {
                    ...pessoaEnderecos[novoEndereco.index],
                    ...novoEndereco,
                };
            } else if (novoEndereco.funcao === "deletar") {
                pessoaEnderecos.splice(novoEndereco.index, 1);
            } else {
                setPessoaEndereco([...pessoaEnderecos, novoEndereco]);
            }
            setNovoEndereco({
                pessoaId: "",
                uuid: "",
                cep: "",
                latitude: null,
                longitude: null,
                ufId: "",
                municipioId: "",
                bairro: "",
                logradouro: "",
                numero: "",
                complemento: "",
                principal: false,
                ativo: false,
                funcao: "Adicionar",
            });
        }
    };
    /*emails*/
    const handleEmail = async () => {
        if (ValidaEmail(novoEmail.email) === false) {
            setFlashMessage("email inválido!", "error");
            return;
        }

        if (pessoa.id) {
            const result = await PessoaAPI.emails(novoEmail);
            setFlashMessage(result.data.message, result.data.type);
            if (result.data.type === "success") {
                setPessoaEmail(result.data.resultado);
            }

            setNovoEmail({
                pessoaId: pessoa.id,
                uuid: "",
                tipo: "",
                email: "",
                principal: false,
                ativo: false,
                funcao: "adicionar",
            });
        } else {
            if (novoEmail.funcao === "editar") {
                pessoaEmails[novoEmail.index] = {
                    ...pessoaEmails[novoEmail.index],
                    ...novoEmail,
                };
            } else if (novoEmail.funcao === "deletar") {
                pessoaEmails.splice(novoEmail.index, 1);
            } else {
                setPessoaEmail([...pessoaEmails, novoEmail]);
            }
            setNovoEmail({
                tipo: "",
                email: "",
                principal: false,
                ativo: false,
                funcao: "Adicionar",
            });
        }
    };
    /*telefones*/
    const handleTelefone = async () => {
        if (pessoa.id) {
            //garante que a pessoa vai ser relacionada
            if (!novoTelefone.pessoaId) {
                novoTelefone.pessoaId = pessoa.id;
            }

            const result = await PessoaAPI.telefones(novoTelefone);
            setFlashMessage(result.data.message, result.data.type);
            if (result.data.type === "success") {
                setPessoaTelefone(result.data.resultado);
            }

            setNovoTelefone({
                pessoaId: pessoa.id,
                uuid: "",
                tipo: "Pessoal",
                numero: "",
                principal: false,
                zap: false,
                telegram: false,
                ativo: false,
                funcao: "adicionar",
            });
        } else {
            if (novoTelefone.funcao === "editar") {
                pessoaTelefones[novoTelefone.index] = {
                    ...pessoaTelefones[novoTelefone.index],
                    ...novoTelefone,
                };
            } else if (novoTelefone.funcao === "deletar") {
                pessoaTelefones.splice(novoTelefone.index, 1);
            } else {
                setPessoaTelefone([...pessoaTelefones, novoTelefone]);
            }
            setNovoTelefone({
                uuid: "",
                tipo: "Pessoal",
                numero: "",
                principal: false,
                zap: false,
                telegram: false,
                ativo: false,
                funcao: "adicionar",
            });
        }
    };
    /*nomes*/
    const handleNome = async () => {
        if (pessoa.id) {
            const result = await PessoaAPI.nomes(novoNome);
            setFlashMessage(result.data.message, result.data.type);
            if (result.data.type === "success") {
                setPessoaNome(result.data.resultado);
            }

            setNovoNome({
                pessoaId: pessoa.id,
                uuid: "",
                nome: "",
                origem: "",
                data: "",
                ativo: false,
                funcao: "adicionar",
            });
        } else {
            if (novoNome.funcao === "editar") {
                pessoaNomes[novoNome.index] = {
                    ...pessoaNomes[novoNome.index],
                    ...novoNome,
                };
            } else if (novoNome.funcao === "deletar") {
                pessoaNomes.splice(novoNome.index, 1);
            } else {
                setPessoaNome([...pessoaNomes, novoNome]);
            }
            setNovoNome({
                pessoaId: pessoa.id,
                uuid: "",
                nome: "",
                origem: "",
                data: "",
                ativo: false,
                funcao: "adicionar",
            });
        }
    };
    /*Filiais*/
    const handleFilial = async () => {
        if (pessoa.id) {
            const result = await PessoaAPI.filial(novaFilial);
            setFlashMessage(result.data.message, result.data.type);
            if (result.data.type === "success") {
                setPessoaEmpresas(result.data.resultado);
            }

            setNovaFilial({
                uuid: null,
                pessoaId: pessoa.id,
                empresaId: null,
                ativo: false,
                funcao: "adicionar",
            });
        } else {
            if (novaFilial.funcao === "editar") {
                pessoaEmpresas[novaFilial.index] = {
                    ...pessoaEmpresas[novaFilial.index],
                    ...novaFilial,
                };
            } else if (novaFilial.funcao === "deletar") {
                pessoaEmpresas.splice(novaFilial.index, 1);
            } else {
                setPessoaEmpresas([...pessoaEmpresas, novaFilial]);
            }
            setNovaFilial({
                uuid: null,
                empresaId: null,
                ativo: false,
                funcao: "Adicionar",
            });
        }
    };
    /*Funcionario refere-se a colaboradores*/
    const handleFuncionario = async () => {
        if (pessoa.id) {
            const result = await PessoaAPI.funcionarios(novoFuncionario);
            setFlashMessage(result.data.message, result.data.type);
            if (result.data.type === "success") {
                setPessoaFuncionarios(result.data.resultado);
            }
        } else {
            if (novoFuncionario.funcao === "editar") {
                pessoaFuncionarios[novoFuncionario.index] = {
                    ...pessoaFuncionarios[novoFuncionario.index],
                    ...novoFuncionario,
                };
            } else if (novoFuncionario.funcao === "deletar") {
                pessoaFuncionarios.splice(novoFuncionario.index, 1);
            } else {
                //obter a descricao da pessoa
                const d = await TabelaAPI.tabelaById(pessoa.empresaId, "Pessoa", novoFuncionario.funcionarioId);
                novoFuncionario["descricao"] = d.descricao;

                setPessoaFuncionarios([...pessoaFuncionarios, novoFuncionario]);
            }
        }
        setNovoFuncionario({
            uuid: null,
            funcionarioId: null,
            ativo: false,
            funcao: "Adicionar",
        });

        setFuncionarios({
            ...Funcionarios,
            id: null,
            descricao: null,
        });
    };
    /*Tabela de Preços*/
    const handleTabelaPreco = async () => {
        if (pessoa.id) {
            const result = await PessoaAPI.tabelaPreco(novaTabelaPreco);
            setFlashMessage(result.data.message, result.data.type);
            if (result.data.type === "success") {
                setPessoaTabelaPrecos(result.data.resultado);
            }

            setNovaTabelaPreco({
                uuid: null,
                pessoaId: pessoa.id,
                tabelaPrecoIdId: null,
                descricao: null,
                ativo: false,
                funcao: "adicionar",
            });

            setTabelaPrecos({
                ...tabelaPreco,
                id: null,
                descricao: null,
            });
        } else {
            if (novaTabelaPreco.funcao === "editar") {
                pessoaTabelaPrecos[novaTabelaPreco.index] = {
                    ...pessoaTabelaPrecos[novaTabelaPreco.index],
                    ...novaTabelaPreco,
                };
            } else if (novaTabelaPreco.funcao === "deletar") {
                pessoaTabelaPrecos.splice(novaTabelaPreco.index, 1);
            } else {
                //obter a descricao da tabela
                const d = await TabelaAPI.tabelaById(pessoa.empresaId, "tabelaPreco", novaTabelaPreco.tabelaPrecoId);
                novaTabelaPreco["descricao"] = d.descricao;

                setPessoaTabelaPrecos((prev) => [...prev, novaTabelaPreco]);
            }
            setNovaTabelaPreco({
                uuid: null,
                tabelaPrecoId: null,
                descricao: null,
                ativo: false,
                funcao: "Adicionar",
            });

            setTabelaPrecos({
                ...tabelaPreco,
                id: null,
                descricao: null,
            });
        }
    };
    async function onFileChange(e) {
        setPreview(Array.from(e.target.files));
        setPessoa({ ...pessoa, images: [...e.target.files] });
    }
    async function handleChange(e) {
        //atualiza as opções dos objetos filhos
        if (e.target.name.startsWith("pessoaFisica.")) {
            if (
                e.target.name.split(".")[1] === "sexo" ||
                e.target.name.split(".")[1] === "tipoSanguineo" ||
                e.target.name.split(".")[1] === "estadoCivil"
            ) {
                setPessoaFisica({
                    ...pessoaFisica,
                    [e.target.name.split(".")[1]]: e.target.options[e.target.selectedIndex].text,
                });
            } else {
                setPessoaFisica({
                    ...pessoaFisica,
                    [e.target.name.split(".")[1]]: e.target.value,
                });
            }
        } else if (e.target.name.startsWith("pessoaJuridica.")) {
            if (e.target.name.split(".")[1] === "tipo" || e.target.name.split(".")[1] === "ramoAtividade") {
                setPessoaJuridica({
                    ...pessoaJuridica,
                    [e.target.name.split(".")[1]]: e.target.options[e.target.selectedIndex].text,
                });
            } else {
                setPessoaJuridica({
                    ...pessoaJuridica,
                    [e.target.name.split(".")[1]]: e.target.value,
                });
            }
        } else if (e.target.name.startsWith("pessoaUsuario.")) {
            if (
                e.target.name.split(".")[1] === "ativo" ||
                e.target.name.split(".")[1] === "isAdmin" ||
                e.target.name.split(".")[1] === "plantaoAlteraData" ||
                e.target.name.split(".")[1] === "plantaoAssinatura" ||
                e.target.name.split(".")[1] === "plantaoPermiteConcluir"
            ) {
                setPessoaUsuario({
                    ...pessoaUsuario,
                    [e.target.name.split(".")[1]]: e.target.checked,
                });
            } else {
                setPessoaUsuario({
                    ...pessoaUsuario,
                    [e.target.name.split(".")[1]]: e.target.value,
                });
            }
        } else if (e.target.name.startsWith("pessoaColaborador.")) {
            if (
                e.target.name.split(".")[1] === "ativo" ||
                e.target.name.split(".")[1] === "balanco" ||
                e.target.name.split(".")[1] === "gerenteCompras" ||
                e.target.name.split(".")[1] === "supervisorCompras" ||
                e.target.name.split(".")[1] === "compras" ||
                e.target.name.split(".")[1] === "ordemProducao" ||
                e.target.name.split(".")[1] === "gerenteVendas" ||
                e.target.name.split(".")[1] === "supervisorVendas" ||
                e.target.name.split(".")[1] === "vendaInterna" ||
                e.target.name.split(".")[1] === "vendaExterna" ||
                e.target.name.split(".")[1] === "motorista" ||
                e.target.name.split(".")[1] === "extrusor" ||
                e.target.name.split(".")[1] === "corteSolda" ||
                e.target.name.split(".")[1] === "recuperador" ||
                e.target.name.split(".")[1] === "impressor" ||
                e.target.name.split(".")[1] === "pazinha" ||
                e.target.name.split(".")[1] === "corrugadora" ||
                e.target.name.split(".")[1] === "picotadeira" ||
                e.target.name.split(".")[1] === "sacoleira" ||
                e.target.name.split(".")[1] === "manutencaoPredial" ||
                e.target.name.split(".")[1] === "manutencaoIndustrial" ||
                e.target.name.split(".")[1] === "manutencaoEletrica" ||
                e.target.name.split(".")[1] === "manutencaoVeiculos" ||
                e.target.name.split(".")[1] === "dashboardFinanceiro" ||
                e.target.name.split(".")[1] === "dashboardIndustria"
            ) {
                setPessoaColaborador({
                    ...pessoaColaborador,
                    [e.target.name.split(".")[1]]: e.target.checked,
                });
            } else if (
                e.target.name.split(".")[1] === "comissao" ||
                e.target.name.split(".")[1] === "comissaoInterna" ||
                e.target.name.split(".")[1] === "comissaoExterna"
            ) {
                const valorFormatado = formatarNumeroDecimal(e.target.value);
                setPessoaColaborador({
                    ...pessoaColaborador,
                    [e.target.name.split(".")[1]]: valorFormatado,
                });
            } else {
                setPessoaColaborador({
                    ...pessoaColaborador,
                    [e.target.name.split(".")[1]]: e.target.value,
                });
            }
        } else if (e.target.name.startsWith("novoEndereco")) {
            if (e.target.name === "novoEndereco.tipo") {
                setNovoEndereco({
                    ...novoEndereco,
                    tipo: e.target.options[e.target.selectedIndex].text,
                });
            } else if (
                //colocar essa funcao no onblur e popular municipio
                e.target.name === "novoEndereco.ufId" ||
                e.target.name === "novoEndereco.municipioId"
            ) {
                setNovoEndereco({
                    ...novoEndereco,
                    [e.target.name.split(".")[1]]: e.target.value,
                    [e.target.name.split(".")[1].slice(0, -2)]: e.target.options[e.target.selectedIndex].innerText,
                });

                //popula municipios
                if (e.target.name === "novoEndereco.ufId") {
                    const cidades = await VerificadoresAPI.municipio(e.target.value, "", "");
                    setMunicipio(cidades);

                    setNovoEndereco({
                        ...novoEndereco,
                        [e.target.name.split(".")[1]]: e.target.value,
                    });
                }
            } else if (e.target.name === "novoEndereco.principal" || e.target.name === "novoEndereco.ativo") {
                //set os dados dos checkbox
                setNovoEndereco({
                    ...novoEndereco,
                    [e.target.name.split(".")[1]]: e.target.checked,
                });
            } else {
                setNovoEndereco({
                    ...novoEndereco,
                    [e.target.name.split(".")[1]]: e.target.value,
                });
            }
        } else if (e.target.name.startsWith("novaFilial")) {
            if (e.target.name === "novaFilial.empresaId") {
                setNovaFilial({
                    ...novaFilial,
                    pessoaId: pessoa.id,
                    empresaId: isNaN(e.target.value) ? null : e.target.value,
                });
            } else {
                //set os dados dos checkbox
                setNovaFilial({
                    ...novaFilial,
                    pessoaId: pessoa.id,
                    [e.target.name.split(".")[1]]: e.target.checked,
                });
            }
        } else if (e.target.name.startsWith("novoEmail")) {
            if (e.target.name === "novoEmail.tipo") {
                setNovoEmail({
                    ...novoEmail,
                    tipo: e.target.options[e.target.selectedIndex].text,
                });
            } else if (e.target.name === "novoEmail.principal" || e.target.name === "novoEmail.ativo") {
                //set os dados dos checkbox
                setNovoEmail({
                    ...novoEmail,
                    pessoaId: pessoa.id,
                    [e.target.name.split(".")[1]]: e.target.checked,
                });
            } else {
                setNovoEmail({
                    ...novoEmail,
                    pessoaId: pessoa.id,
                    [e.target.name.split(".")[1]]: e.target.value,
                });
            }
        } else if (e.target.name.startsWith("novoTelefone")) {
            if (e.target.name === "novoTelefone.tipo") {
                setNovoTelefone({
                    ...novoTelefone,
                    tipo: e.target.options[e.target.selectedIndex].text,
                });
            } else if (
                e.target.name === "novoTelefone.zap" ||
                e.target.name === "novoTelefone.telegram" ||
                e.target.name === "novoTelefone.ativo" ||
                e.target.name === "novoTelefone.principal"
            ) {
                //set os dados dos checkbox
                setNovoTelefone({
                    ...novoTelefone,
                    [e.target.name.split(".")[1]]: e.target.checked,
                });
            } else {
                setNovoTelefone({
                    ...novoTelefone,
                    [e.target.name.split(".")[1]]: e.target.value,
                });
            }
        } else if (e.target.name.startsWith("novoNome")) {
            if (e.target.name === "novoNome.origem") {
                setNovoNome({
                    ...novoNome,
                    origem: e.target.options[e.target.selectedIndex].text,
                });
            } else if (e.target.name === "novoNome.ativo") {
                //set os dados dos checkbox
                setNovoNome({
                    ...novoNome,
                    [e.target.name.split(".")[1]]: e.target.checked,
                });
            } else {
                setNovoNome({
                    ...novoNome,
                    [e.target.name.split(".")[1]]: e.target.value,
                });
            }
        } else if (e.target.name.startsWith("centroCustoCliente")) {
            setCCC({
                ...centroCustoCliente,
                [e.target.name.split(".")[1]]: e.target.value,
            });

            //carrega o datalist
            if (e.target.value?.length > 0) {
                let result = await consultaDatalist(
                    token,
                    pessoa.empresaId,
                    "centroCusto",
                    "id",
                    "descricao",
                    "Receber",
                    e.target.value
                );
                setOptionsCCC(result);
            }
            const valor = document.querySelector(`datalist option[value="${e.target.value}"]`)?.dataset.id;
            setPessoa({
                ...pessoa,
                centroCustoClienteId: valor === undefined ? null : valor,
            });
        } else if (e.target.name.startsWith("centroCustoFornecedor")) {
            setCCF({
                ...centroCustoFornecedor,
                [e.target.name.split(".")[1]]: e.target.value,
            });

            //carrega o datalist
            if (e.target.value?.length > 0) {
                let result = await consultaDatalist(
                    token,
                    pessoa.empresaId,
                    "centroCusto",
                    "id",
                    "descricao",
                    "Pagar",
                    e.target.value
                );
                setOptionsCCF(result);
            }
            const valor = document.querySelector(`datalist option[value="${e.target.value}"]`)?.dataset.id;
            setPessoa({
                ...pessoa,
                centroCustoFornecedorId: valor === undefined ? null : valor,
            });
        } else if (e.target.name.startsWith("novoFuncionario")) {
            setFuncionarios({
                ...Funcionarios,
                [e.target.name.split(".")[1]]: e.target.value,
            });

            if (e.target.name.startsWith("novoFuncionario.descricao")) {
                //carrega o datalist
                if (e.target.value?.length > 0) {
                    let result = await consultaDatalist(
                        token,
                        pessoa.empresaId,
                        "Pessoa",
                        "id",
                        "descricao",
                        "colaborador",
                        e.target.value
                    );
                    setOptionsPessoa(result);
                }

                setNovoFuncionario({
                    ...novoFuncionario,
                    pessoaId: pessoa.id,
                    funcionarioId: document.querySelector(`datalist option[value="${e.target.value}"]`)?.dataset.id,
                });
            } else {
                //set os dados dos checkbox
                setNovoFuncionario({
                    ...novoFuncionario,
                    pessoaId: pessoa.id,
                    [e.target.name.split(".")[1]]: e.target.checked,
                });
            }
        } else if (e.target.name.startsWith("novaTabelaPreco")) {
            setTabelaPrecos({
                ...tabelaPreco,
                [e.target.name.split(".")[1]]: e.target.value,
            });

            if (e.target.name.startsWith("novaTabelaPreco.descricao")) {
                //carrega o datalist
                if (e.target.value?.length > 0) {
                    let result = await consultaDatalist(
                        token,
                        pessoa.empresaId,
                        "tabelaPreco",
                        "id",
                        "descricao",
                        null,
                        e.target.value
                    );
                    setOptionsTabelaPreco(result);
                }
                setNovaTabelaPreco({
                    ...novaTabelaPreco,
                    pessoaId: pessoa.id,
                    tabelaPrecoId: document.querySelector(`datalist option[value="${e.target.value}"]`)?.dataset.id,
                });
            } else {
                //set os dados dos checkbox
                setNovaTabelaPreco({
                    ...novaTabelaPreco,
                    pessoaId: pessoa.id,
                    [e.target.name.split(".")[1]]: e.target.checked,
                });
            }
        } else {
            //atualiza as opções de pessoas pelo CPForCNPJ
            if (e.target.name === "CPForCNPJ") {
                if (e.target.value.length >= 18) {
                    setPessoa({
                        ...pessoa,
                        [e.target.name]: e.target.value,
                        tipo: "Jurídica",
                        funcionario: false,
                    });
                    setTabEnabled({
                        ...tabEnabled,
                        fisica: false,
                        juridica: true,
                        colaborador: false,
                        filial: false,
                    });
                } else {
                    setPessoa({
                        ...pessoa,
                        [e.target.name]: e.target.value,
                        tipo: "Física",
                    });

                    setTabEnabled({
                        ...tabEnabled,
                        fisica: true,
                        juridica: false,
                        colaborador: true,
                        filial: true,
                    });
                }
            } else if (e.target.name === "tipo") {
                //enums
                setPessoa({
                    ...pessoa,
                    tipo: e.target.options[e.target.selectedIndex].text,
                });
                e.target.options[e.target.selectedIndex].text === "Física"
                    ? setTabEnabled({
                          ...tabEnabled,
                          fisica: true,
                          juridica: false,
                          colaborador: true,
                          filial: true,
                      })
                    : setTabEnabled({
                          ...tabEnabled,
                          fisica: false,
                          juridica: true,
                          colaborador: false,
                          filial: false,
                      });
            } else if (
                e.target.name === "ativo" ||
                e.target.name === "administrador" ||
                e.target.name === "locador" ||
                e.target.name === "locatario" ||
                e.target.name === "fiador" ||
                e.target.name === "fabricante" ||
                e.target.name === "funcionario" ||
                e.target.name === "simplesNacional"
            ) {
                setPessoa({ ...pessoa, [e.target.name]: e.target.checked });
            } else if (e.target.name === "formaClienteId") {
                setPessoa({ ...pessoa, [e.target.name]: e.target.value });
                await consultaPrazo("Receber", e.target.value, "");
            } else if (e.target.name === "formaFornecedorId") {
                setPessoa({ ...pessoa, [e.target.name]: e.target.value });
                await consultaPrazo("Pagar", e.target.value, "");
            } else {
                setPessoa({ ...pessoa, [e.target.name]: e.target.value });
            }
        }
    }
    async function handleOnBlur(e) {
        //atualiza tabelas filhas
        if (e.target.name.startsWith("novoEndereco.")) {
            if (e.target.name === "novoEndereco.cep") {
                if (onlyNumbers(e.target.value)?.length === 8) {
                    consultaCEP(onlyNumbers(e.target.value));
                } else if (e.target.value?.length > 0 && e.target.value?.length !== 8) {
                    setFlashMessage("CEP inválido!!", "error");
                }
            } else if (e.target.name === "novoEndereco.ufId") {
                const cidades = await VerificadoresAPI.municipio(e.target.value, "", "");
                setMunicipio(cidades);
            }
            setPessoa({ ...pessoa, pessoaEnderecos: pessoaEnderecos });
        }
        if (e.target.name.startsWith("pessoaFisica.")) {
            //Atualiza o objeto pessoaFisica
            setPessoa({ ...pessoa, pessoaFisica: pessoaFisica });
        }
        if (e.target.name.startsWith("pessoaJuridica.")) {
            //Atualiza o objeto pessoaJuridica
            setPessoa({ ...pessoa, pessoaJuridica: pessoaJuridica });
        }
        if (e.target.name.startsWith("pessoaUsuario.")) {
            //Atualiza o objeto pessoaUsuario
            setPessoa({ ...pessoa, pessoaUsuario: pessoaUsuario });
        }
        if (e.target.name.startsWith("pessoaColaborador.")) {
            //Atualiza o objeto pessoaColaborador
            setPessoa({ ...pessoa, pessoaColaborador: pessoaColaborador });
        }
        //atualiza as opções de pessoas pelo CPForCNPJ
        if (e.target.name === "CPForCNPJ") {
            if (e.target.value.length >= 18) {
                setTabEnabled({
                    ...tabEnabled,
                    fisica: false,
                    juridica: true,
                    colaborador: false,
                    filial: false,
                });
                //verifica primeiro se existe no banco de dados
                if (btnText === "Cadastrar") {
                    const result = await PessoaAPI.pessoa(
                        onlyNumbers(e.target.value),
                        parseInt(document.getElementById("empresaId").value)
                    );

                    if (!result) {
                        //verifica no site da receita
                        receitaWS(onlyNumbers(e.target.value), token);
                    } else if (result.uuid) {
                        setPessoaJuridica({
                            ...pessoaJuridica,
                            razaoSocial: result.pessoaJuridica.razaoSocial,
                            nomeFantasia: result.pessoaJuridica.nomeFantasia,
                            abertura: result.pessoaJuridica.abertura,
                            tipo: result.pessoaJuridica.tipo,
                        });

                        Object?.keys(result.pessoaEnderecos)?.map(
                            (key, index, array) =>
                                (pessoaEnderecos[key] = {
                                    ...pessoaEnderecos[key],
                                    cep: result?.pessoaEnderecos[key].cep,
                                    ufId: result?.pessoaEnderecos[key].ufId,
                                    municipioId: result?.pessoaEnderecos[key].municipioId,
                                    bairro: result?.pessoaEnderecos[key].bairro,
                                    logradouro: result?.pessoaEnderecos[key].logradouro,
                                    numero: result?.pessoaEnderecos[key].numero,
                                    complemento: result?.pessoaEnderecos[key].complemento,
                                })
                        );
                        Object?.keys(result.pessoaEmails)?.map(
                            (key, index, array) =>
                                (pessoaEmails[key] = {
                                    ...pessoaEmails[key],
                                    email: result?.pessoaEmails[key].email,
                                    tipo: result?.pessoaEmails[key].tipo,
                                    principal: result?.pessoaEmails[key].principal,
                                    ativo: result?.pessoaEmails[key].ativo,
                                })
                        );
                        Object?.keys(result.pessoaTelefones)?.map(
                            (key, index, array) =>
                                (pessoaTelefones[key] = {
                                    ...pessoaTelefones[key],
                                    numero: result?.pessoaTelefones[key].numero,
                                    zap: result?.pessoaTelefones[key].zap,
                                    telegram: result?.pessoaTelefones[key].telegram,
                                    tipo: result?.pessoaTelefones[key].tipo,
                                    ativo: result?.pessoaTelefones[key].ativo,
                                })
                        );
                        Object?.keys(result.pessoaNomes)?.map(
                            (key, index, array) =>
                                (pessoaNomes[key] = {
                                    ...pessoaNomes[key],
                                    nome: result?.pessoaNomes[key].nome,
                                    data: result?.pessoaNomes[key].data,
                                    origem: result?.pessoaNomes[key].origem,
                                    ativo: result?.pessoaNomes[key].ativo,
                                })
                        );
                        //grava os dados na pessoa
                        setPessoa({
                            ...pessoa,
                            images: result.images,
                            RGorIE: result.RGorIE,
                            inscricaoEstadualST: result.inscricaoEstadualST,
                            nomePrincipal: result.nomePrincipal,
                            tipo: result.tipo,
                            vinculoFinanceiro: result.vinculoFinanceiro,
                            pessoaJuridica: {
                                ...pessoaJuridica,
                                razaoSocial: result.pessoaJuridica.razaoSocial,
                                nomeFantasia: result.pessoaJuridica.nomeFantasia,
                                abertura: result.pessoaJuridica.abertura,
                                tipo: result.pessoaJuridica.tipo,
                            },
                        });
                    } else if (result?.response.status === 500) {
                        setFlashMessage(result.response.data.message, "error");
                        return navigate(`/Pessoa/CreateOrEdit`, {
                            uuid: result.response.data.uuid,
                        });
                    } else {
                        //verifica no site da receita
                        receitaWS(onlyNumbers(e.target.value), token);
                    }
                }
            } else {
                setTabEnabled({
                    ...tabEnabled,
                    fisica: true,
                    juridica: false,
                    colaborador: true,
                    filial: true,
                });
                //consultar se a pessoa existe no banco de dados
                if (btnText === "Cadastrar") {
                    const result = await PessoaAPI.pessoa(
                        onlyNumbers(e.target.value),
                        parseInt(document.getElementById("empresaId").value)
                    );

                    if (result?.uuid) {
                        if (result.pessoaFisica !== null) {
                            setPessoaFisica({
                                ...pessoaFisica,
                                dataNascimento: result.pessoaFisica?.dataNascimento,
                                sexo: result.pessoaFisica.sexo,
                                tipoSanguineo: result.pessoaFisica.tipoSanguineo,
                                estadoCivil: result.pessoaFisica.estadoCivil,
                                pai: result.pessoaFisica.pai,
                                mae: result.pessoaFisica.mae,
                                cnh: result.pessoaFisica.cnh,
                                cnhCategoria: result.pessoaFisica.cnhCategoria,
                                cnhPrimeira: result.pessoaFisica.cnhPrimeira,
                                cnhValidade: result.pessoaFisica.cnhValidade,

                                pis: result.pessoaFisica.pis,
                                titulo: result.pessoaFisica.titulo,
                                secao: result.pessoaFisica.secao,
                                zona: result.pessoaFisica.zona,
                            });
                        }
                        Object?.keys(result.pessoaEnderecos)?.map(
                            (key, index, array) =>
                                (pessoaEnderecos[key] = {
                                    ...pessoaEnderecos[key],
                                    cep: result?.pessoaEnderecos[key].cep,
                                    ufId: result?.pessoaEnderecos[key].ufId,
                                    municipioId: result?.pessoaEnderecos[key].municipioId,
                                    bairro: result?.pessoaEnderecos[key].bairro,
                                    logradouro: result?.pessoaEnderecos[key].logradouro,
                                    numero: result?.pessoaEnderecos[key].numero,
                                    complemento: result?.pessoaEnderecos[key].complemento,
                                })
                        );
                        Object?.keys(result.pessoaEmails)?.map(
                            (key, index, array) =>
                                (pessoaEmails[key] = {
                                    ...pessoaEmails[key],
                                    email: result?.pessoaEmails[key].email,
                                    tipo: result?.pessoaEmails[key].tipo,
                                    principal: result?.pessoaEmails[key].principal,
                                    ativo: result?.pessoaEmails[key].ativo,
                                })
                        );
                        Object?.keys(result.pessoaTelefones)?.map(
                            (key, index, array) =>
                                (pessoaTelefones[key] = {
                                    ...pessoaTelefones[key],
                                    numero: result?.pessoaTelefones[key].numero,
                                    zap: result?.pessoaTelefones[key].zap,
                                    telegram: result?.pessoaTelefones[key].telegram,
                                    tipo: result?.pessoaTelefones[key].tipo,
                                    ativo: result?.pessoaTelefones[key].ativo,
                                })
                        );
                        Object?.keys(result.pessoaNomes)?.map(
                            (key, index, array) =>
                                (pessoaNomes[key] = {
                                    ...pessoaNomes[key],
                                    nome: result?.pessoaNomes[key].nome,
                                    data: result?.pessoaNomes[key].data,
                                    origem: result?.pessoaNomes[key].origem,
                                    ativo: result?.pessoaNomes[key].ativo,
                                })
                        );
                        //grava os dados na pessoa
                        if (result.pessoaFisica !== null) {
                            setPessoa({
                                ...pessoa,
                                images: result.images,
                                RGorIE: result.RGorIE,
                                inscricaoEstadualST: result.inscricaoEstadualST,
                                nomePrincipal: result.nomePrincipal,
                                tipo: result.tipo,
                                vinculoFinanceiro: result.vinculoFinanceiro,
                                pessoaFisica: {
                                    ...pessoaFisica,
                                    dataNascimento: result?.pessoaFisica?.dataNascimento || "",
                                    sexo: result.pessoaFisica.sexo,
                                    tipoSanguineo: result.pessoaFisica.tipoSanguineo,
                                    estadoCivil: result.pessoaFisica.estadoCivil,
                                    pai: result.pessoaFisica.pai,
                                    mae: result.pessoaFisica.mae,
                                    cnh: result.pessoaFisica.cnh,
                                    cnhCategoria: result.pessoaFisica.cnhCategoria,
                                    cnhPrimeira: result.pessoaFisica.cnhPrimeira,
                                    cnhValidade: result.pessoaFisica.cnhValidade,

                                    pis: result.pessoaFisica.pis,
                                    titulo: result.pessoaFisica.titulo,
                                    secao: result.pessoaFisica.secao,
                                    zona: result.pessoaFisica.zona,
                                },
                            });
                        } else {
                            setPessoa({
                                ...pessoa,
                                images: result.images,
                                RGorIE: result.RGorIE,
                                inscricaoEstadualST: result.inscricaoEstadualST,
                                nomePrincipal: result.nomePrincipal,
                                tipo: result.tipo,
                                vinculoFinanceiro: result.vinculoFinanceiro,
                            });
                        }
                    } else if (result?.response.status === 500) {
                        setFlashMessage(result.response.data.message, "error");
                        return navigate(`/Pessoa/CreateOrEdit`, {
                            uuid: result.response.data.uuid,
                        });
                    }
                } else {
                    setTabEnabled({
                        ...tabEnabled,
                        fisica: true,
                        juridica: false,
                    });
                }
            }
        }
    }
    async function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();
        if (!pessoa.vinculoFinanceiro) {
            const selectElement = document.getElementById("vinculoFinanceiro");
            pessoa.vinculoFinanceiro = selectElement.value;
        }
        if (pessoa.CPForCNPJ) {
            pessoa.CPForCNPJ = onlyNumbers(pessoa.CPForCNPJ);
        }

        handleSubmit(pessoa);
    };

    return (
        <Tabs>
            <form onSubmit={submit}>
                <h1>Gestão de Pessoas</h1>
                <TabList>
                    <Tab disabled={!tabEnabled.pessoa} title={"Infomações genericas da pessoa"}>
                        Pessoa
                    </Tab>
                    <Tab disabled={!tabEnabled.fisica} title={"Informações da pessoa fisíca"}>
                        Física
                    </Tab>
                    <Tab
                        disabled={!tabEnabled.nome}
                        title={
                            "Informações dos nomes da pessoa, quando a mesma efetua mudança de nome, utilizado no modulo de Governo"
                        }
                    >
                        Nomes
                    </Tab>
                    <Tab disabled={!tabEnabled.juridica} title={"Informações da pessoa jurídica"}>
                        Jurídica
                    </Tab>
                    <Tab disabled={!tabEnabled.financeiro} title={"Vinculos financeiros da pessoa com o sistema e seus modulos"}>
                        Financeiro
                    </Tab>
                    <Tab disabled={!tabEnabled.endereco} title={"Informações dos endereços da pessoa"}>
                        Endereços
                    </Tab>
                    <Tab disabled={!tabEnabled.email} itle={"Informações dos emails da pessoa"}>
                        e-mails
                    </Tab>
                    <Tab disabled={!tabEnabled.telefone} title={"Informações dos telefones da pessoa"}>
                        Telefones
                    </Tab>

                    <Tab disabled={!tabEnabled.usuario} title={"Víncula a pessoa a um usuário do sistema"}>
                        Usuário
                    </Tab>
                    <Tab disabled={!tabEnabled.colaborador} title={"Permite a pessoa acessar a sistema específicos da empresa"}>
                        Colaborador
                    </Tab>
                    <Tab
                        disabled={!tabEnabled.funcionario}
                        title={"Vincula colaboradores a pessoa, vendedores, compradores etc..."}
                    >
                        Funcionários
                    </Tab>
                    <Tab disabled={!tabEnabled.filial} title={"Permite a pessoa utilizar filiais"}>
                        Filiais
                    </Tab>
                </TabList>
                <TabPanel>
                    <div className={formStyles.stiloPadrao}>
                        <h4>Informações principais da Pessoa</h4>

                        <div className={formStyles.preview_bandeira}>
                            <div>
                                <Input
                                    text="Fotos da Pessoa"
                                    type="file"
                                    name="images"
                                    accept=".jpg, .jpeg, .png"
                                    handleOnChange={onFileChange}
                                    multiple={false}
                                    inputWidth="350px"
                                />
                            </div>
                            {preview.length > 0
                                ? preview.map((image, index) => (
                                      <img
                                          src={URL.createObjectURL(image)}
                                          alt={pessoa.images}
                                          key={`${pessoa.images}+${index}`}
                                          style={{
                                              borderColor: "black",
                                              border: "solid 0.05em",
                                          }}
                                          width={60}
                                          height={80}
                                      />
                                  ))
                                : pessoa.images && (
                                      <img
                                          src={process.env.REACT_APP_API + "imagens/" + pessoa.images}
                                          alt={pessoa.uuid || "Foto da Pessoa"}
                                          style={{
                                              borderColor: "black",
                                              border: "solid 0.05em",
                                          }}
                                          width={60}
                                          height={80}
                                      />
                                  )}
                        </div>
                        <div>
                            <SelectEmpresa
                                name="empresaId"
                                text="Empresa"
                                options={OptionsEmpresa}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoa.empresaId}
                                isEnum={false}
                                notOptNull={true}
                            />
                            <Input
                                text="Data Cadastro"
                                type="datetime-local"
                                name="createdAt"
                                placeholder="última atualização"
                                title="Data de inserção do cadastro no sistema"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={(pessoa.createdAt && retornaDataHora(pessoa.createdAt)) || retornaDataHora(moment())}
                                readonly={true}
                            />
                            <Input
                                text="Data Atualização"
                                type="datetime-local"
                                name="updatedAt"
                                placeholder="última atualização"
                                title="Data da última atualização dos dados"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={(pessoa.updatedAt && retornaDataHora(pessoa.updatedAt)) || retornaDataHora(moment())}
                                readonly={true}
                            />
                            <Checkbox
                                title="Funcionário da empresa"
                                text="Funcionário"
                                name="funcionario"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoa.funcionario}
                                checked={pessoa.funcionario === true ? "checked" : ""}
                            />
                        </div>
                        <div>
                            <Input
                                text="CPF ou CNPJ"
                                type="text"
                                name="CPForCNPJ"
                                placeholder="CPF ou CNPJ da Pessoa"
                                title="Digite o CPF ou CNPJ da pessoa"
                                minlength={14}
                                maxlength={18}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoa.CPForCNPJ ? maskCPForCNPJ(pessoa.CPForCNPJ) : ""}
                                required
                                readonly={false}
                            />
                            <Input
                                text="RG ou IE"
                                type="text"
                                name="RGorIE"
                                placeholder="RG ou IE da Pessoa"
                                title="Digite o Registro Geral ou Inscrição Estadual da pessoa"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoa.RGorIE ?? ""}
                                required={false}
                                readonly={false}
                            />
                            <Select
                                name="tipo"
                                text="Pessoa"
                                title="Identifica se a pessoa é física ou jurídica"
                                options={EnumTipo}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoa.tipo || "Jurídica"}
                                isEnum={true}
                            />
                            <Input
                                text="IE ST"
                                type="text"
                                name="inscricaoEstadualST"
                                placeholder="IE ST"
                                title="Inscrição Estadual do Substituto Tributário"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoa.inscricaoEstadualST ?? ""}
                                required={false}
                                readonly={false}
                            />
                            <Checkbox
                                title="Empresa cadastrada no Simples Nacional"
                                text="Simples Nacional"
                                name="simplesNacional"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoa.simplesNacional}
                                checked={pessoa.simplesNacional === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Ativa ou Inativa a pessoa"
                                text="Ativo"
                                name="ativo"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoa.ativo}
                                checked={pessoa.ativo === true ? "checked" : ""}
                            />
                        </div>
                        <div>
                            <Input
                                text="Nome Principal"
                                type="text"
                                name="nomePrincipal"
                                placeholder="Nome"
                                title="nome atual da pessoa"
                                inputWidth="350px"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoa.nomePrincipal || ""}
                                readonly={false}
                            />

                            <Select
                                name="vinculoFinanceiro"
                                text="Tipo"
                                title="Determina o vínculo financeiro com a empresa, Cliente, 
                            Fornecedor ou Ambos, caso vazio a pessoa não aparecerá no modulo 
                            financeiro nem em movimentos"
                                options={EnumVinculoFinanceiro}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoa.vinculoFinanceiro || "AMBOS"}
                                isEnum={true}
                            />
                            <Checkbox
                                title="Quando marcado torna a pessoa fabricante"
                                text="Fabricante"
                                name="fabricante"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoa.fabricante}
                                checked={pessoa.fabricante === true ? "checked" : ""}
                            />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.stiloPadrao}>
                        <h4>Informações de Pessoa Física</h4>

                        <div>
                            <Input
                                text="Data Nascimento"
                                type="date"
                                name="pessoaFisica.dataNascimento"
                                placeholder="Nascimento"
                                title="Data de nascimento da pessoa"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={
                                    (pessoaFisica.dataNascimento && retornaData(pessoaFisica.dataNascimento)) ||
                                    retornaData(moment())
                                }
                                readonly={false}
                            />
                            <Select
                                name="pessoaFisica.sexo"
                                text="Sexo"
                                title="Sexo da pessoa"
                                options={EnumSexo}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaFisica?.sexo || ""}
                                isEnum={true}
                            />
                            <Select
                                name="pessoaFisica.tipoSanguineo"
                                text="Tipo Sanguíneo"
                                title="Tipo de Sangue da pessoa"
                                options={EnumTipoSanguineo}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaFisica?.tipoSanguineo || ""}
                                isEnum={true}
                            />
                            <Select
                                name="pessoaFisica.estadoCivil"
                                text="estado civil"
                                title="estado civil pessoa"
                                options={EnumEstadoCivil}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaFisica.estadoCivil || ""}
                                isEnum={true}
                            />
                        </div>
                        <div>
                            <Input
                                text="Nome do Pai"
                                type="text"
                                name="pessoaFisica.pai"
                                placeholder="Nome"
                                title="nome do Pai da pessoa"
                                inputWidth="350px"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaFisica.pai || ""}
                                readonly={false}
                            />

                            <Input
                                text="Nome da Mãe"
                                type="text"
                                name="pessoaFisica.mae"
                                placeholder="Nome"
                                title="nome da Mãe da pessoa"
                                inputWidth="350px"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaFisica.mae || ""}
                                readonly={false}
                            />
                        </div>
                        <div>
                            <Input
                                text="Número da CNH"
                                type="text"
                                name="pessoaFisica.cnh"
                                placeholder="CNH"
                                title="número da CNH da pessoa"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaFisica.cnh || ""}
                                readonly={false}
                            />
                            <Select
                                name="pessoaFisica.cnhCategoria"
                                text="Categoria"
                                title="Tipo de Sangue da pessoa"
                                options={EnumCnhCategoria}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaFisica.cnhCategoria || ""}
                                isEnum={true}
                            />
                            <Input
                                text="Primeira CNH"
                                type="date"
                                name="pessoaFisica.cnhPrimeira"
                                placeholder="Primeira"
                                title="Data da primeira CNH da pessoa"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={(pessoaFisica.cnhPrimeira && retornaData(pessoaFisica.cnhPrimeira)) || ""}
                                readonly={false}
                            />
                            <Input
                                text="Validade da CNH"
                                type="date"
                                name="pessoaFisica.cnhValidade"
                                placeholder="Validade"
                                title="Data de vencimento da CNH da pessoa"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={(pessoaFisica.cnhValidade && retornaData(pessoaFisica.cnhValidade)) || ""}
                                readonly={false}
                            />
                        </div>
                        <div>
                            <Input
                                text="Número do PIS"
                                type="text"
                                name="pessoaFisica.pis"
                                placeholder="PIS"
                                title="número do PIS da pessoa"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaFisica.pis || ""}
                                readonly={false}
                            />
                            <Input
                                text="Número do Título"
                                type="text"
                                name="pessoaFisica.titulo"
                                placeholder="Título Eleitor"
                                title="número do Título de eleitor da pessoa"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaFisica.titulo || ""}
                                readonly={false}
                            />
                            <Input
                                text="Seção"
                                type="text"
                                name="pessoaFisica.secao"
                                placeholder="Seção"
                                title="número da eleitoral"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaFisica.secao || ""}
                                readonly={false}
                            />
                            <Input
                                text="Zona"
                                type="text"
                                name="pessoaFisica.zona"
                                placeholder="PIS"
                                title="número da zona eleitoral"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaFisica.zona || ""}
                                readonly={false}
                            />
                        </div>
                        <div>
                            <Input
                                text="CRECI"
                                type="text"
                                name="pessoaFisica.creci"
                                placeholder="CRECI"
                                title="número do CRECI"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaFisica.creci || ""}
                                readonly={false}
                            />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.stiloPadrao}>
                        <h4>Informações de mudanças de nomes Pessoa</h4>
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "1rem",
                                }}
                            >
                                <Input
                                    text="Novo Nome"
                                    type="text"
                                    name="novoNome.nome"
                                    placeholder="Nome"
                                    inputWidth="300px"
                                    title="Digite o novo nome da pessoa"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoNome.nome || ""}
                                />

                                <Select
                                    name="novoNome.origem"
                                    text="Tipo"
                                    options={EnumOrigem}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoNome.origem || ""}
                                    isEnum={true}
                                />

                                <Input
                                    text="Data Nome"
                                    type="date"
                                    name="novoNome.data"
                                    placeholder="última atualização"
                                    title="Data do nome da pessoa, essa mudança pode ocorrer por determinação judicial ou casamento, pessos jurídicas datas de criação ou alterações no cadastro"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoNome.data || ""}
                                    readonly={false}
                                />
                                <Checkbox
                                    title="Quando marcado torna o nome ativo"
                                    text="Ativo"
                                    name="novoNome.ativo"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoNome.ativo}
                                    checked={novoNome.ativo === true ? "checked" : ""}
                                />

                                <Button
                                    variant="contained"
                                    color="inherit"
                                    onClick={() =>
                                        setNovoNome({
                                            pessoaId: pessoa.id,
                                            uuid: "",
                                            nome: "",
                                            origem: "",
                                            data: "",
                                            ativo: false,
                                            funcao: "adicionar",
                                        })
                                    }
                                    style={{ marginLeft: "10px" }}
                                >
                                    Limpar
                                </Button>
                                <Button
                                    variant="contained"
                                    color={novoNome.funcao === "deletar" ? "error" : "info"}
                                    onClick={() => handleNome(novoNome)}
                                    disabled={novoNome.nome.length < 1 ? true : false}
                                    style={{ marginLeft: "5px" }}
                                >
                                    {novoNome.funcao}
                                </Button>
                            </div>
                        </div>
                        <div>
                            <table className={formStyles.table}>
                                <thead>
                                    <tr>
                                        <th className={formStyles.th} align="left">
                                            Nome
                                        </th>
                                        <th className={formStyles.th} align="left">
                                            Origem
                                        </th>
                                        <th className={formStyles.th} align="left">
                                            Data
                                        </th>
                                        <th className={formStyles.th} align="left">
                                            Ativo
                                        </th>
                                        <th className={formStyles.th} align="center">
                                            Ações
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className={formStyles.tbody}>
                                    <>
                                        {" "}
                                        {Object?.keys(pessoaNomes)?.map((key, index, array) => (
                                            <tr className={formStyles.tr} key={pessoaNomes[key].uuid || index}>
                                                <td className={formStyles.td}>{pessoaNomes[key].nome || ""}</td>
                                                <td className={formStyles.td}>{pessoaNomes[key].origem || ""}</td>
                                                <td className={formStyles.td}>
                                                    {pessoaNomes[key].data ? retornaDataBr(pessoaNomes[key].data) : ""}
                                                </td>
                                                <td className={formStyles.td}>{pessoaNomes[key].ativo ? "Sim" : "Não"}</td>
                                                <td className={formStyles.td}>
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() =>
                                                            setNovoNome({
                                                                index: index,
                                                                uuid: pessoaNomes[key].uuid,
                                                                pessoaId: pessoaNomes[key].pessoaId,
                                                                nome: pessoaNomes[key].nome,
                                                                origem: pessoaNomes[key].origem,
                                                                data: pessoaNomes[key].data,
                                                                ativo: pessoaNomes[key].ativo,
                                                                funcao: "editar",
                                                            })
                                                        }
                                                    >
                                                        Editar
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        onClick={() =>
                                                            setNovoNome({
                                                                index: index,
                                                                uuid: pessoaNomes[key].uuid,
                                                                pessoaId: pessoaNomes[key].pessoaId,
                                                                nome: pessoaNomes[key].nome,
                                                                origem: pessoaNomes[key].origem,
                                                                data: pessoaNomes[key].data,
                                                                ativo: pessoaNomes[key].ativo,
                                                                funcao: "deletar",
                                                            })
                                                        }
                                                        style={{
                                                            marginLeft: "5px",
                                                        }}
                                                    >
                                                        Excluir
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.stiloPadrao}>
                        <h4>Informações de Pessoa Juridica</h4>
                        <div>
                            <Input
                                text="Razão Social"
                                type="text"
                                name="pessoaJuridica.razaoSocial"
                                placeholder="Razão Social"
                                title="Razão Social da pessoajurídica"
                                inputWidth="350px"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaJuridica.razaoSocial || pessoaNomes.nome}
                                readonly={false}
                            />
                            <Input
                                text="Nome Fantasia"
                                type="text"
                                name="pessoaJuridica.nomeFantasia"
                                placeholder="Nome Fantasia"
                                title="Nome Fantasia da pessoajurídica"
                                inputWidth="350px"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaJuridica.nomeFantasia || ""}
                                readonly={false}
                            />
                        </div>
                        <div>
                            <Input
                                text="Abertura"
                                type="date"
                                name="pessoaJuridica.abertura"
                                placeholder="Abertua"
                                title="Data de abertura da empresa"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={(pessoaJuridica.abertura && retornaData(pessoaJuridica.abertura)) || retornaData(moment())}
                            />
                            <Input
                                text="última atualização"
                                type="datetime-local"
                                name="pessoaJuridica.ultima_atualizacao"
                                placeholder="última atualização"
                                title="Data da última atualização dos dados com a Receita Federal"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={
                                    (pessoaJuridica.ultima_atualizacao && retornaDataHora(pessoaJuridica.ultima_atualizacao)) ||
                                    retornaDataHora(moment())
                                }
                            />
                        </div>
                        <div>
                            <Select
                                name="pessoaJuridica.ramoAtividade"
                                text="Selecione o ramo de atividade"
                                options={EnumRamoAtividade}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaJuridica.ramoAtividade || "Industria"}
                                isEnum={true}
                            />
                            <Select
                                name="pessoaJuridica.tipo"
                                text="Selecione Matriz ou Filial"
                                options={EnumPJTipo}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaJuridica.tipo || "MATRIZ"}
                                isEnum={true}
                            />
                            {/* <Checkbox
                            title="Quando marcado vincula a empresa a FILIAL"
                            text="Possui Filial"
                            name="possuiFilial"
                            type="checkbox"
                            handleOnChange={handleChange}
                            value={pessoaJuridica.possuiFilial}
                            checked={
                                pessoaJuridica.possuiFilial === true ? "checked" : ""
                            }
                        />
                        <SelectEmpresa
                            name="matriz"
                            text="Selecione Matriz"
                            options={OptionsMatriz}
                            handleOnChange={handleChange}
                            value={empresa.matriz || empresa.id}
                            isEnum={false}
                        /> */}
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.stiloPadrao}>
                        <h4>Modulo Clientes</h4>
                        <div>
                            <div>
                                <Select
                                    name="contaClienteId"
                                    title={"Define a conta padrão que o cliente vai pagar"}
                                    text="Conta"
                                    options={OptionsConta}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={pessoa.contaClienteId}
                                    isEnum={false}
                                    notOptNull={false}
                                />
                                <Select
                                    name="formaClienteId"
                                    title={"Define a forma que o cliente vai pagar"}
                                    text="Forma"
                                    options={OptionsFormaCliente}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={pessoa.formaClienteId}
                                    isEnum={false}
                                    notOptNull={false}
                                />
                                <Select
                                    name="prazoClienteId"
                                    title={"Define o prazo que o cliente vai pagar"}
                                    text="Prazo"
                                    options={OptionsPCliente} //options={OptionsPrazoCliente}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={pessoa.prazoClienteId}
                                    isEnum={false}
                                    notOptNull={false}
                                />

                                <DataList
                                    text="Centro de Custo"
                                    title={"Define o centro de custo padrão do cliente"}
                                    placeholder="Digite para pesquisar"
                                    name="centroCustoCliente.descricao"
                                    inputWidth="200px"
                                    filteredOptions={OptionsCCC}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={centroCustoCliente.descricao || ""}
                                />
                            </div>
                        </div>
                        <h4>Modulo Fornecedores</h4>
                        <div>
                            <div>
                                <Select
                                    name="contaFornecedorId"
                                    title={"Define a conta padrão que o fornecedor vai receber"}
                                    text="Conta"
                                    options={OptionsConta}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={pessoa.contaFornecedorId}
                                    isEnum={false}
                                    notOptNull={false}
                                />
                                <Select
                                    name="formaFornecedorId"
                                    title={"Define a forma que a emrpesa vai pagar ao fornecedor"}
                                    text="Forma"
                                    options={OptionsFormaFornecedor}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={pessoa.formaFornecedorId}
                                    isEnum={false}
                                    notOptNull={false}
                                />

                                <Select
                                    name="prazoFornecedorId"
                                    title={"Define o prazo que a empresa vai pagar ao fornecedor"}
                                    text="Prazo"
                                    options={OptionsPFornecedor}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={pessoa.prazoFornecedorId}
                                    isEnum={false}
                                    notOptNull={false}
                                />
                                <DataList
                                    text="Centro de Custo"
                                    title={"Define o centro de custo padrão do fornecedor"}
                                    placeholder="Digite para pesquisar"
                                    name="centroCustoFornecedor.descricao"
                                    inputWidth="200px"
                                    filteredOptions={OptionsCCF}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={centroCustoFornecedor.descricao || ""}
                                />
                            </div>
                        </div>
                        <h4>Modulo Locação de Imóveis</h4>
                        <div>
                            <div>
                                <Checkbox
                                    title="Quando marcado torna a pessoa administrador de imóveis"
                                    text="Administrador"
                                    name="administrador"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={pessoa.administrador}
                                    checked={pessoa.administrador === true ? "checked" : ""}
                                />
                                <Checkbox
                                    title="Quando marcado torna a pessoa locador de imóveis"
                                    text="Locador"
                                    name="locador"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={pessoa.locador}
                                    checked={pessoa.locador === true ? "checked" : ""}
                                />
                                <Checkbox
                                    title="Quando marcado torna a pessoa locatário de imóveis"
                                    text="Locatário"
                                    name="locatario"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={pessoa.locatario}
                                    checked={pessoa.locatario === true ? "checked" : ""}
                                />
                                <Checkbox
                                    title="Quando marcado torna a pessoa locador de imóveis"
                                    text="Fiador"
                                    name="fiador"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={pessoa.fiador}
                                    checked={pessoa.fiador === true ? "checked" : ""}
                                />
                            </div>
                        </div>
                        <h4>Tabelas de Preços</h4>
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <DataList
                                    text="Tabela de Preços"
                                    title={"Digite para selecionar a tabela"}
                                    name="novaTabelaPreco.descricao"
                                    inputWidth="350px"
                                    filteredOptions={OptionsTabelaPreco}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={tabelaPreco?.descricao || ""}
                                />
                                <Checkbox
                                    title="Ativo quando marcado"
                                    text="Ativo"
                                    name="novaTabelaPreco.ativo"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novaTabelaPreco.ativo}
                                    checked={novaTabelaPreco.ativo === true ? "checked" : ""}
                                />
                                <Button
                                    variant="contained"
                                    color="inherit"
                                    onClick={() => {
                                        setNovaTabelaPreco({
                                            uuid: null,
                                            pessoaId: pessoa.id,
                                            tabelaPrecoId: null,
                                            descricao: null,
                                            ativo: false,
                                            funcao: "adicionar",
                                        });
                                        setTabelaPrecos({
                                            ...tabelaPreco,
                                            id: null,
                                            descricao: null,
                                        });
                                    }}
                                    style={{ marginLeft: "10px" }}
                                >
                                    Limpar
                                </Button>
                                <Button
                                    variant="contained"
                                    color={novaTabelaPreco.funcao === "deletar" ? "error" : "info"}
                                    onClick={() => handleTabelaPreco(novaTabelaPreco)}
                                    disabled={
                                        novaTabelaPreco.tabelaPrecoId === null || novaTabelaPreco.tabelaPrecoId === undefined
                                            ? true
                                            : false
                                    }
                                    style={{ marginLeft: "5px" }}
                                >
                                    {novaTabelaPreco.funcao}
                                </Button>
                            </div>
                            <div>
                                <table className={formStyles.table}>
                                    <thead>
                                        <tr>
                                            <th className={formStyles.th} align="left">
                                                Tabela
                                            </th>
                                            <th className={formStyles.th}>Liberada</th>
                                            <th className={formStyles.th}>Ativo</th>
                                            <th className={formStyles.th} align="center">
                                                Ações
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className={formStyles.tbody}>
                                        <>
                                            {" "}
                                            {Object.keys(pessoaTabelaPrecos).map((key, index, array) => (
                                                <tr className={formStyles.tr} key={pessoaTabelaPrecos[key].uuid || index}>
                                                    <td
                                                        className={formStyles.td}
                                                        style={{
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        {pessoaTabelaPrecos[key].tabelaPreco.descricao ||
                                                            pessoaTabelaPrecos[key].descricao}
                                                    </td>
                                                    <td className={formStyles.td}>
                                                        {pessoaTabelaPrecos[key].tabelaPreco.liberada ? "Sim" : "Não"}
                                                    </td>
                                                    <td className={formStyles.td}>
                                                        {pessoaTabelaPrecos[key].ativo ? "Sim" : "Não"}
                                                    </td>
                                                    <td className={formStyles.td}>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            onClick={() => {
                                                                setNovaTabelaPreco({
                                                                    index: index,
                                                                    uuid: pessoaTabelaPrecos[key].uuid,
                                                                    pessoaId: pessoaTabelaPrecos[key].pessoaId,
                                                                    tabelaPrecoId: pessoaTabelaPrecos[key].tabelaPrecoId,
                                                                    ativo: pessoaTabelaPrecos[key].ativo,
                                                                    descricao:
                                                                        pessoaTabelaPrecos[key].tabelaPreco.descricao ||
                                                                        pessoaTabelaPrecos[key].descricao,
                                                                    funcao: "editar",
                                                                });

                                                                // Defina o novo estado
                                                                setTabelaPrecos({
                                                                    ...tabelaPreco,
                                                                    id: pessoaTabelaPrecos[key].pessoaId,
                                                                    descricao:
                                                                        pessoaTabelaPrecos[key].tabelaPreco.descricao ||
                                                                        pessoaTabelaPrecos[key].descricao,
                                                                });
                                                            }}
                                                        >
                                                            Editar
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            color="error"
                                                            onClick={() => {
                                                                setNovaTabelaPreco({
                                                                    index: index,
                                                                    uuid: pessoaTabelaPrecos[key].uuid,
                                                                    pessoaId: pessoaTabelaPrecos[key].pessoaId,
                                                                    tabelaPrecoId: pessoaTabelaPrecos[key].tabelaPrecoId,
                                                                    ativo: pessoaTabelaPrecos[key].ativo,
                                                                    descricao:
                                                                        pessoaTabelaPrecos[key]?.tabelaPreco.descricao ||
                                                                        pessoaTabelaPrecos[key].descricao,
                                                                    funcao: "deletar",
                                                                });

                                                                // Defina o novo estado
                                                                setTabelaPrecos({
                                                                    ...tabelaPreco,
                                                                    id: pessoaTabelaPrecos[key].pessoaId,
                                                                    descricao:
                                                                        pessoaTabelaPrecos[key]?.tabelaPreco.descricao ||
                                                                        pessoaTabelaPrecos[key].descricao,
                                                                });
                                                            }}
                                                            style={{
                                                                marginLeft: "5px",
                                                            }}
                                                        >
                                                            Excluir
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.stiloPadrao}>
                        <h4>Informações do endereço da Pessoa</h4>
                        <div>
                            <div>
                                <Input
                                    text="CEP"
                                    type="text"
                                    name="novoEndereco.cep"
                                    maxlength={10}
                                    inputWidth={"100px"}
                                    placeholder="CEP do endereço"
                                    title="Digite o CEP do endereço da pessoa"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoEndereco.cep ? maskCEP(novoEndereco.cep) : ""}
                                />
                                <Select
                                    text="Estado"
                                    name="novoEndereco.ufId"
                                    options={ufs}
                                    title="Escolha o Estado do endereço da pessoa"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoEndereco.ufId || ""}
                                    isEnum={false}
                                />
                                <Select
                                    text="Município"
                                    name="novoEndereco.municipioId"
                                    options={Optionsmunicipios || municipios}
                                    title="Escolha o município do endereço da pessoa"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoEndereco.municipioId || ""}
                                    isEnum={false}
                                />
                                <Input
                                    text="Bairro"
                                    type="text"
                                    name="novoEndereco.bairro"
                                    placeholder="bairro"
                                    title="Digite o bairro da pessoa"
                                    inputWidth="300px"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoEndereco.bairro || ""}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Input
                                    text="Logradouro"
                                    type="text"
                                    name="novoEndereco.logradouro"
                                    placeholder="digite o logradouro"
                                    title="Digite o endereço da pessoa"
                                    inputWidth="420px"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoEndereco.logradouro || ""}
                                />
                                <Input
                                    text="Número"
                                    type="text"
                                    name="novoEndereco.numero"
                                    placeholder="numero"
                                    title="Digite o numero da pessoa"
                                    inputWidth="80px"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoEndereco.numero || ""}
                                />
                                <Input
                                    text="Latitude"
                                    type="text"
                                    name="novoEndereco.latitude"
                                    maxlength={10}
                                    placeholder="Latitude"
                                    title="Digite a latitude do endereço"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoEndereco.latitude || ""}
                                />
                                <Input
                                    text="Longitude"
                                    type="text"
                                    name="novoEndereco.longitude"
                                    maxlength={10}
                                    placeholder="Longitude"
                                    title="Digite a Longitude do endereço"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoEndereco.longitude || ""}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Input
                                    text="Complemento"
                                    type="text"
                                    name="novoEndereco.complemento"
                                    placeholder="complemento"
                                    title="Digite o complemento da pessoa"
                                    inputWidth="350px"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoEndereco.complemento || ""}
                                />
                                <Select
                                    name="novoEndereco.tipo"
                                    text="Tipo do endereço"
                                    options={EnumTipoEndereco}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={!novoEndereco.tipo ? "Outros" : novoEndereco.tipo}
                                    isEnum={true}
                                />
                                <Checkbox
                                    title="Quando marcado torna o endereço como principal da pessoa"
                                    text="Principal"
                                    name="novoEndereco.principal"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoEndereco.principal}
                                    checked={novoEndereco.principal === true ? "checked" : ""}
                                />
                                <Checkbox
                                    title="Quando marcado torna o endereço ativo, caso desmarcado o mesmo ficará sem utilização nos modulos de envio automáticos"
                                    text="Ativo"
                                    name="novoEndereco.ativo"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoEndereco.ativo}
                                    checked={novoEndereco.ativo === true ? "checked" : ""}
                                />{" "}
                                <Button
                                    variant="contained"
                                    color="inherit"
                                    onClick={() =>
                                        setNovoEndereco({
                                            pessoaId: pessoa.id,
                                            uuid: "",
                                            cep: "",
                                            ufId: "",
                                            municipioId: "",
                                            bairro: "",
                                            logradouro: "",
                                            numero: "",
                                            latitude: null,
                                            longitude: null,
                                            complemento: "",
                                            tipo: "",
                                            municipio: "",
                                            uf: "",
                                            principal: false,
                                            funcao: "adicionar",
                                        })
                                    }
                                    style={{ marginLeft: "10px" }}
                                >
                                    Limpar
                                </Button>
                                <Button
                                    variant="contained"
                                    color={novoEndereco.funcao === "deletar" ? "error" : "info"}
                                    onClick={() => handleEndereco(novoEndereco)}
                                    disabled={novoEndereco.cep.length < 1 ? true : false}
                                    style={{ marginLeft: "5px" }}
                                >
                                    {novoEndereco.funcao}
                                </Button>
                            </div>
                            <div>
                                <table className={formStyles.table}>
                                    <thead>
                                        <tr>
                                            <th className={formStyles.th} align="left">
                                                Endereços Cadastrados
                                            </th>
                                            <th className={formStyles.th} align="center">
                                                Ações
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className={formStyles.tbody}>
                                        <>
                                            {Object?.keys(pessoaEnderecos)?.map((key, index, array) => (
                                                <tr className={formStyles.tr} key={pessoaEnderecos[key].uuid || index}>
                                                    <td
                                                        className={formStyles.td}
                                                        style={{
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        {`${pessoaEnderecos[key]?.logradouro}, ${
                                                            pessoaEnderecos[key]?.numero
                                                        }, CEP: ${maskCEP(pessoaEnderecos[key]?.cep)}, ${
                                                            pessoaEnderecos[key].bairro
                                                        }, ${pessoaEnderecos[key].Municipio?.descricao || ""} - ${
                                                            pessoaEnderecos[key].UF?.uf || ""
                                                        }`}
                                                    </td>
                                                    <td className={formStyles.td}>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            onClick={() =>
                                                                setNovoEndereco({
                                                                    index: index,
                                                                    pessoaId: pessoaEnderecos[key].pessoaId,
                                                                    uuid: pessoaEnderecos[key].uuid,
                                                                    cep: pessoaEnderecos[key].cep,
                                                                    ufId: pessoaEnderecos[key].ufId,
                                                                    municipioId: pessoaEnderecos[key].municipioId,
                                                                    bairro: pessoaEnderecos[key].bairro,
                                                                    logradouro: pessoaEnderecos[key].logradouro,
                                                                    numero: pessoaEnderecos[key].numero,
                                                                    latitude: pessoaEnderecos[key].latitude,
                                                                    longitude: pessoaEnderecos[key].longitude,
                                                                    complemento: pessoaEnderecos[key].complemento,
                                                                    tipo: pessoaEnderecos[key].tipo,
                                                                    principal: pessoaEnderecos[key].principal,
                                                                    ativo: pessoaEnderecos[key].ativo,
                                                                    funcao: "editar",
                                                                })
                                                            }
                                                        >
                                                            Editar
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            color="error"
                                                            onClick={() =>
                                                                setNovoEndereco({
                                                                    index: index,
                                                                    pessoaId: pessoaEnderecos[key].pessoaId,
                                                                    uuid: pessoaEnderecos[key].uuid,
                                                                    cep: pessoaEnderecos[key].cep,
                                                                    ufId: pessoaEnderecos[key].ufId,
                                                                    municipioId: pessoaEnderecos[key].municipioId,
                                                                    bairro: pessoaEnderecos[key].bairro,
                                                                    logradouro: pessoaEnderecos[key].logradouro,
                                                                    numero: pessoaEnderecos[key].numero,
                                                                    complemento: pessoaEnderecos[key].complemento,
                                                                    tipo: pessoaEnderecos[key].tipo,
                                                                    principal: pessoaEnderecos[key].principal,
                                                                    ativo: pessoaEnderecos[key].ativo,
                                                                    funcao: "deletar",
                                                                })
                                                            }
                                                            style={{
                                                                marginLeft: "5px",
                                                            }}
                                                        >
                                                            Excluir
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.stiloPadrao}>
                        <h4>Informações dos e-mails da Pessoa</h4>
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Input
                                    text="Novo email"
                                    type="email"
                                    name="novoEmail.email"
                                    placeholder="email"
                                    title="Digite o novo email da pessoa"
                                    inputWidth="350px"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoEmail.email || ""}
                                />

                                <Select
                                    name="novoEmail.tipo"
                                    text="Tipo de email"
                                    options={EnumTipoEmail}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoEmail.tipo || "Outros"}
                                    isEnum={true}
                                />

                                <Checkbox
                                    title="Quando marcado torna o email como principal da pessoa"
                                    text="Principal"
                                    name="novoEmail.principal"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoEmail.principal}
                                    checked={novoEmail.principal === true ? "checked" : ""}
                                />
                                <Checkbox
                                    title="Quando marcado torna o email ativo, caso desmarcado o mesmo ficará sem utilização nos modulos de envio automáticos"
                                    text="Ativo"
                                    name="novoEmail.ativo"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoEmail.ativo}
                                    checked={novoEmail.ativo === true ? "checked" : ""}
                                />

                                <Button
                                    variant="contained"
                                    color="inherit"
                                    onClick={() =>
                                        setNovoEmail({
                                            pessoaId: pessoa.id,
                                            uuid: "",
                                            tipo: "",
                                            email: "",
                                            principal: false,
                                            funcao: "adicionar",
                                        })
                                    }
                                    style={{ marginLeft: "10px" }}
                                >
                                    Limpar
                                </Button>
                                <Button
                                    variant="contained"
                                    color={novoEmail.funcao === "deletar" ? "error" : "info"}
                                    onClick={() => handleEmail(novoEmail)}
                                    disabled={novoEmail.email.length < 1 ? true : false}
                                    style={{ marginLeft: "5px" }}
                                >
                                    {novoEmail.funcao}
                                </Button>
                            </div>
                            <div>
                                <table className={formStyles.table}>
                                    <thead>
                                        <tr>
                                            <th className={formStyles.th} align="left">
                                                Email
                                            </th>
                                            <th className={formStyles.th}>Tipo</th>
                                            <th className={formStyles.th}>Principal</th>
                                            <th className={formStyles.th}>Ativo</th>
                                            <th className={formStyles.th} align="center">
                                                Ações
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className={formStyles.tbody}>
                                        <>
                                            {Object.keys(pessoaEmails).map((key, index, array) => (
                                                <tr className={formStyles.tr} key={pessoaEmails[key].uuid || index}>
                                                    <td
                                                        className={formStyles.td}
                                                        style={{
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        {pessoaEmails[key].email}
                                                    </td>
                                                    <td className={formStyles.td}>{pessoaEmails[key].tipo}</td>
                                                    <td className={formStyles.td}>
                                                        {pessoaEmails[key].principal ? "Sim" : "Não"}
                                                    </td>
                                                    <td className={formStyles.td}>{pessoaEmails[key].ativo ? "Sim" : "Não"}</td>
                                                    <td className={formStyles.td}>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            onClick={() =>
                                                                setNovoEmail({
                                                                    index: index,
                                                                    pessoaId: pessoaEmails[key].pessoaId,
                                                                    uuid: pessoaEmails[key].uuid,
                                                                    email: pessoaEmails[key].email,
                                                                    tipo: pessoaEmails[key].tipo,
                                                                    principal: pessoaEmails[key].principal,
                                                                    ativo: pessoaEmails[key].ativo,
                                                                    funcao: "editar",
                                                                })
                                                            }
                                                        >
                                                            Editar
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            color="error"
                                                            onClick={() =>
                                                                setNovoEmail({
                                                                    index: index,
                                                                    uuid: pessoaEmails[key].uuid,
                                                                    pessoaId: pessoaEmails[key].pessoaId,
                                                                    email: pessoaEmails[key].email,
                                                                    tipo: pessoaEmails[key].tipo,
                                                                    principal: pessoaEmails[key].principal,
                                                                    ativo: pessoaEmails[key].ativo,
                                                                    funcao: "deletar",
                                                                })
                                                            }
                                                            style={{
                                                                marginLeft: "5px",
                                                            }}
                                                        >
                                                            Excluir
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.stiloPadrao}>
                        <h4>Informações dos telefones da Pessoa</h4>
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Input
                                    text="Novo Telefone"
                                    type="numero"
                                    name="novoTelefone.numero"
                                    placeholder="Número"
                                    title="Digite o novo telefone da pessoa"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoTelefone.numero || ""}
                                />

                                <Select
                                    name="novoTelefone.tipo"
                                    text="Tipo"
                                    options={EnumTipoTelefone}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoTelefone.tipo || "Pessoal"}
                                    isEnum={true}
                                />
                                <Checkbox
                                    title="Quando marcado torna o telefone como principal da pessoa"
                                    text="Principal"
                                    name="novoTelefone.principal"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoTelefone.principal}
                                    checked={novoTelefone.principal === true ? "checked" : ""}
                                />
                                <Checkbox
                                    title="víncula o número ao WhatsApp"
                                    text="WhatsApp"
                                    name="novoTelefone.zap"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoTelefone.zap}
                                    checked={novoTelefone.zap === true ? "checked" : ""}
                                />

                                <Checkbox
                                    title="Vincula o número ao TELEGRAM"
                                    text="Telegram"
                                    name="novoTelefone.telegram"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoTelefone.telegram}
                                    checked={novoTelefone.telegram === true ? "checked" : ""}
                                />
                                <Checkbox
                                    title="Quando marcado torna o telefone ativo, caso desmarcado o mesmo ficará sem utilização nos modulos de envio automáticos"
                                    text="Ativo"
                                    name="novoTelefone.ativo"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoTelefone.ativo}
                                    checked={novoTelefone.ativo === true ? "checked" : ""}
                                />

                                <Button
                                    variant="contained"
                                    color="inherit"
                                    onClick={() =>
                                        setNovoTelefone({
                                            pessoaId: pessoa.id,
                                            uuid: "",
                                            tipo: "Pessoal",
                                            numero: "",
                                            zap: false,
                                            telegram: false,
                                            ativo: false,
                                            funcao: "adicionar",
                                        })
                                    }
                                    style={{ marginLeft: "10px" }}
                                >
                                    Limpar
                                </Button>
                                <Button
                                    variant="contained"
                                    color={novoTelefone.funcao === "deletar" ? "error" : "info"}
                                    onClick={() => handleTelefone(novoTelefone)}
                                    disabled={novoTelefone.numero.length < 1 ? true : false}
                                    style={{ marginLeft: "5px" }}
                                >
                                    {novoTelefone.funcao}
                                </Button>
                            </div>
                            <div>
                                <table className={formStyles.table}>
                                    <thead>
                                        <tr>
                                            <th className={formStyles.th} align="left">
                                                Número
                                            </th>
                                            <th className={formStyles.th}>Tipo</th>
                                            <th className={formStyles.th}>Principal</th>
                                            <th className={formStyles.th}>WhatsApp</th>
                                            <th className={formStyles.th}>Telegram</th>
                                            <th className={formStyles.th}>Ativo</th>
                                            <th className={formStyles.th} align="center">
                                                Ações
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className={formStyles.tbody}>
                                        <>
                                            {Object.keys(pessoaTelefones).map((key, index, array) => (
                                                <tr className={formStyles.tr} key={pessoaTelefones[key].uuid || index}>
                                                    <td
                                                        className={formStyles.td}
                                                        style={{
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        {pessoaTelefones[key].numero}
                                                    </td>
                                                    <td className={formStyles.td}>{pessoaTelefones[key].tipo}</td>
                                                    <td className={formStyles.td}>
                                                        {pessoaTelefones[key].principal ? "Sim" : "Não"}
                                                    </td>
                                                    <td className={formStyles.td}>{pessoaTelefones[key].zap ? "Sim" : "Não"}</td>
                                                    <td className={formStyles.td}>
                                                        {pessoaTelefones[key].telegram ? "Sim" : "Não"}
                                                    </td>
                                                    <td className={formStyles.td}>
                                                        {pessoaTelefones[key].ativo ? "Sim" : "Não"}
                                                    </td>
                                                    <td className={formStyles.td}>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            onClick={() =>
                                                                setNovoTelefone({
                                                                    index: index,
                                                                    uuid: pessoaTelefones[key].uuid,
                                                                    pessoaId: pessoaTelefones[key].pessoaId,
                                                                    numero: pessoaTelefones[key].numero,
                                                                    tipo: pessoaTelefones[key].tipo,
                                                                    principal: pessoaTelefones[key].principal,
                                                                    zap: pessoaTelefones[key].zap,
                                                                    telegram: pessoaTelefones[key].telegram,
                                                                    ativo: pessoaTelefones[key].ativo,
                                                                    funcao: "editar",
                                                                })
                                                            }
                                                        >
                                                            Editar
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            color="error"
                                                            onClick={() =>
                                                                setNovoTelefone({
                                                                    index: index,
                                                                    uuid: pessoaTelefones[key].uuid,
                                                                    pessoaId: pessoaTelefones[key].pessoaId,
                                                                    numero: pessoaTelefones[key].numero,
                                                                    tipo: pessoaTelefones[key].tipo,
                                                                    principal: pessoaTelefones[key].principal,
                                                                    zap: pessoaTelefones[key].zap,
                                                                    telegram: pessoaTelefones[key].telegram,
                                                                    ativo: pessoaTelefones[key].ativo,
                                                                    funcao: "deletar",
                                                                })
                                                            }
                                                            style={{
                                                                marginLeft: "5px",
                                                            }}
                                                        >
                                                            Excluir
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.formPai}>
                        <h4>Configura usuário do sistema e sua permissões</h4>
                        <div className={formStyles.formFilho}>
                            <h4>Usuário</h4>
                            <Input
                                text="Usuário"
                                type="text"
                                name="pessoaUsuario.usuario"
                                placeholder="Usuário"
                                title="nome do usuário"
                                //inputWidth="350px"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaUsuario.usuario}
                                readonly={false}
                            />
                            <Checkbox
                                title="Caso desmarcado o usuário não poderá acessar o sistema"
                                text="Ativo"
                                name="pessoaUsuario.ativo"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaUsuario.ativo}
                                checked={pessoaUsuario.ativo === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Ativado para administradores do sistema"
                                text="Administrador"
                                name="pessoaUsuario.isAdmin"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaUsuario.isAdmin}
                                disabled={JSON.parse(localStorage.getItem("isAdmin")) === true ? false : true}
                                checked={pessoaUsuario.isAdmin === true ? "checked" : ""}
                            />
                            <Input
                                text="Senha"
                                type="password"
                                name="pessoaUsuario.senha"
                                placeholder="Senha"
                                title="Senha do usuário"
                                //inputWidth="350px"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaUsuario.senha}
                                readonly={false}
                            />
                            <Input
                                text="Confirme a senha"
                                type="password"
                                name="pessoaUsuario.confirmeSenha"
                                placeholder="Conrfirme a senha"
                                title="confirmação de senha do usuário"
                                //inputWidth="350px"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaUsuario.confirmeSenha}
                                readonly={false}
                            />
                        </div>
                        <div className={formStyles.formFilho}>
                            <h4>Administrativo</h4>
                            <div className={formStyles.formFilho}>
                                <h4>Plantão</h4>
                                <Checkbox
                                    title="Permite o usuário alterar a data do plantão"
                                    text="Data"
                                    name="pessoaUsuario.plantaoAlteraData"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={pessoaUsuario.plantaoAlteraData}
                                    checked={pessoaUsuario.plantaoAlteraData === true ? "checked" : ""}
                                />
                                <Checkbox
                                    title="Permite o usuário concluir o plantão"
                                    text="Conclui"
                                    name="pessoaUsuario.plantaoPermiteConcluir"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={pessoaUsuario.plantaoPermiteConcluir}
                                    checked={pessoaUsuario.plantaoPermiteConcluir === true ? "checked" : ""}
                                />
                                <Checkbox
                                    title="Permite o usuário assinar o plantão"
                                    text="Assina"
                                    name="pessoaUsuario.plantaoAssinatura"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={pessoaUsuario.plantaoAssinatura}
                                    checked={pessoaUsuario.plantaoAssinatura === true ? "checked" : ""}
                                />
                            </div>
                        </div>
                        <div className={formStyles.formFilho}>
                            <h4>Cadastros</h4>
                            <div className={formStyles.formFilho}>
                                <h4>---</h4>
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.stiloPadrao}>
                        <h4>Sistemas da Empresa</h4>
                        <h4>Administrativo</h4>
                        <div>
                            <Checkbox
                                title="Gerente de Compras"
                                text="Gerente Compras"
                                name="pessoaColaborador.gerenteCompras"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaColaborador.gerenteCompras}
                                checked={pessoaColaborador.gerenteCompras === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Supervisor de Compras"
                                text="Supervisor Compras"
                                name="pessoaColaborador.supervisorCompras"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaColaborador.supervisorCompras}
                                checked={pessoaColaborador.supervisorCompras === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Colaborador tem acesso ao sistema de compras"
                                text="Compras"
                                name="pessoaColaborador.compras"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaColaborador.compras}
                                checked={pessoaColaborador.compras === true ? "checked" : ""}
                            />
                        </div>
                        <div>
                            <Checkbox
                                title="Gerente de Vendas"
                                text="Gerente Vendas"
                                name="pessoaColaborador.gerenteVendas"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaColaborador.gerenteVendas}
                                checked={pessoaColaborador.gerenteVendas === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Supervisor de Vendas"
                                text="Supervisor Vendas"
                                name="pessoaColaborador.supervisorVendas"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaColaborador.supervisorVendas}
                                checked={pessoaColaborador.supervisorVendas === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Colaborador tem acesso ao sistema de vendas internas"
                                text="Venda Interna"
                                name="pessoaColaborador.vendaInterna"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaColaborador.vendaInterna}
                                checked={pessoaColaborador.vendaInterna === true ? "checked" : ""}
                            />
                            <InputNumber
                                text="Comissão Interna"
                                type="text"
                                name="pessoaColaborador.comissaoInterna"
                                placeholder="Valor %"
                                inputWidth="100px"
                                title="Digite o percentual de comissão sobre as vendas internas"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaColaborador.comissaoInterna ? formatarNumero(pessoaColaborador.comissaoInterna) : ""}
                                readonly={pessoaColaborador.vendaInterna === false}
                            />
                            <Checkbox
                                title="Colaborador tem acesso ao sistema de vendas externas"
                                text="Venda Externa"
                                name="pessoaColaborador.vendaExterna"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaColaborador.vendaExterna}
                                checked={pessoaColaborador.vendaExterna === true ? "checked" : ""}
                            />
                            <InputNumber
                                text="Comissão Externa"
                                type="text"
                                name="pessoaColaborador.comissaoExterna"
                                placeholder="Valor %"
                                inputWidth="100px"
                                title="Digite o percentual de comissão sobre as vendas externas"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaColaborador.comissaoExterna ? formatarNumero(pessoaColaborador.comissaoExterna) : ""}
                                readonly={pessoaColaborador.vendaExterna === false}
                            />{" "}
                        </div>
                        <div>
                            <Checkbox
                                title="Colaborador tem acesso ao sistema de balanço"
                                text="Balanço"
                                name="pessoaColaborador.balanco"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaColaborador.balanco}
                                checked={pessoaColaborador.balanco === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Colaborador é motorista, aparece na logística"
                                text="Motorista"
                                name="pessoaColaborador.motorista"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaColaborador.motorista}
                                checked={pessoaColaborador.motorista === true ? "checked" : ""}
                            />
                        </div>
                        <h4>Industria</h4>
                        <div>
                            <Checkbox
                                title="Colaborador tem acesso ao sistema de ordem de produção"
                                text="Ordem Produção"
                                name="pessoaColaborador.ordemProducao"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaColaborador.ordemProducao}
                                checked={pessoaColaborador.ordemProducao === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Colaborador tem acesso a máquina recuperadora"
                                text="Recuperadora"
                                name="pessoaColaborador.recuperador"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaColaborador.recuperador}
                                checked={pessoaColaborador.recuperador === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Colaborador tem acesso a extrusora"
                                text="Extrusor"
                                name="pessoaColaborador.extrusor"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaColaborador.extrusor}
                                checked={pessoaColaborador.extrusor === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Colaborador tem acesso a máquina impressora"
                                text="Impressor"
                                name="pessoaColaborador.impressor"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaColaborador.impressor}
                                checked={pessoaColaborador.impressor === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Colaborador tem acesso a máquina de corte e solda"
                                text="Corte Solda"
                                name="pessoaColaborador.corteSolda"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaColaborador.corteSolda}
                                checked={pessoaColaborador.corteSolda === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Colaborador tem acesso a máquina corrugadora"
                                text="Corrugadora"
                                name="pessoaColaborador.corrugadora"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaColaborador.corrugadora}
                                checked={pessoaColaborador.corrugadora === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Colaborador tem acesso a máquina pazinha"
                                text="Pazinha"
                                name="pessoaColaborador.pazinha"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaColaborador.pazinha}
                                checked={pessoaColaborador.pazinha === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Colaborador tem acesso a máquina picotadeira"
                                text="Picotadeira"
                                name="pessoaColaborador.picotadeira"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaColaborador.picotadeira}
                                checked={pessoaColaborador.picotadeira === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Colaborador tem acesso a máquina sacoleira"
                                text="Sacoleira"
                                name="pessoaColaborador.sacoleira"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaColaborador.sacoleira}
                                checked={pessoaColaborador.sacoleira === true ? "checked" : ""}
                            />
                        </div>
                        <h4>Manutenção</h4>
                        <div>
                            <Checkbox
                                title="Colaborador tem acesso ao sistema de manutenção predial"
                                text="Predial"
                                name="pessoaColaborador.manutencaoPredial"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaColaborador.manutencaoPredial}
                                checked={pessoaColaborador.manutencaoPredial === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Colaborador tem acesso ao sistema de manutenção industrial"
                                text="Industrial"
                                name="pessoaColaborador.manutencaoIndustrial"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaColaborador.manutencaoIndustrial}
                                checked={pessoaColaborador.manutencaoIndustrial === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Colaborador tem acesso ao sistema de manutenção elétrica"
                                text="Elétrica"
                                name="pessoaColaborador.manutencaoEletrica"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaColaborador.manutencaoEletrica}
                                checked={pessoaColaborador.manutencaoEletrica === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Colaborador tem acesso ao sistema de manutenção de veículos"
                                text="Veículos"
                                name="pessoaColaborador.manutencaoVeiculos"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaColaborador.manutencaoVeiculos}
                                checked={pessoaColaborador.manutencaoVeiculos === true ? "checked" : ""}
                            />
                        </div>
                        <h4>DashBoards</h4>
                        <div>
                            <Checkbox
                                title="Acesso ao DashBoard financeiro"
                                text="Financeiro"
                                name="pessoaColaborador.dashboardFinanceiro"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaColaborador.dashboardFinanceiro}
                                checked={pessoaColaborador.dashboardFinanceiro === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Acesso ao DashBoard industrial"
                                text="Indústria"
                                name="pessoaColaborador.dashboardIndustria"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaColaborador.dashboardIndustria}
                                checked={pessoaColaborador.dashboardIndustria === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Caso desmarcado o colaborador não poderá acessar os sistemas"
                                text="Ativo"
                                name="pessoaColaborador.ativo"
                                type="checkbox"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoaColaborador.ativo}
                                checked={pessoaColaborador.ativo === true ? "checked" : ""}
                            />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.stiloPadrao}>
                        <h4>Colaboradores vinculados a pessoa</h4>
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <DataList
                                    text="Funcionário / Colaborador"
                                    title={"Seleciona a pessoa funcionário / colaborador"}
                                    name="novoFuncionario.descricao"
                                    inputWidth="200px"
                                    filteredOptions={OptionsPessoa}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={Funcionarios?.descricao || ""}
                                />
                                <Checkbox
                                    title="Ativo quando marcado"
                                    text="Ativo"
                                    name="novoFuncionario.ativo"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novoFuncionario.ativo}
                                    checked={novoFuncionario.ativo === true ? "checked" : ""}
                                />
                                <Button
                                    variant="contained"
                                    color="inherit"
                                    onClick={() => {
                                        setNovoFuncionario({
                                            uuid: null,
                                            pessoaId: pessoa.id,
                                            funcionarioId: null,
                                            funcao: "adicionar",
                                        });
                                        setFuncionarios({
                                            ...Funcionarios,
                                            id: null,
                                            descricao: null,
                                        });
                                    }}
                                    style={{ marginLeft: "10px" }}
                                >
                                    Limpar
                                </Button>
                                <Button
                                    variant="contained"
                                    color={novoFuncionario.funcao === "deletar" ? "error" : "info"}
                                    onClick={() => handleFuncionario(novoFuncionario)}
                                    disabled={
                                        novoFuncionario.funcionarioId === null || novoFuncionario.funcionarioId === undefined
                                            ? true
                                            : false
                                    }
                                    style={{ marginLeft: "5px" }}
                                >
                                    {novoFuncionario.funcao}
                                </Button>
                            </div>
                            <div>
                                <table className={formStyles.table}>
                                    <thead>
                                        <tr>
                                            <th className={formStyles.th} align="left">
                                                Funcionário / Colaborador
                                            </th>
                                            <th className={formStyles.th}>Ativo</th>
                                            <th className={formStyles.th} align="center">
                                                Ações
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className={formStyles.tbody}>
                                        <>
                                            {Object.keys(pessoaFuncionarios).map((key, index, array) => (
                                                <tr className={formStyles.tr} key={pessoaFuncionarios[key].uuid || index}>
                                                    <td
                                                        className={formStyles.td}
                                                        style={{
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        {pessoaFuncionarios[key].Pessoa?.descricao
                                                            ? pessoaFuncionarios[key].Pessoa.descricao
                                                            : pessoaFuncionarios[key]?.descricao}
                                                    </td>
                                                    <td className={formStyles.td}>
                                                        {pessoaFuncionarios[key].ativo ? "Sim" : "Não"}
                                                    </td>
                                                    <td className={formStyles.td}>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            onClick={() => {
                                                                setNovoFuncionario({
                                                                    index: index,
                                                                    uuid: pessoaFuncionarios[key].uuid,
                                                                    pessoaId: pessoaFuncionarios[key].pessoaId,
                                                                    funcionarioId: pessoaFuncionarios[key].funcionarioId,
                                                                    ativo: pessoaFuncionarios[key].ativo,
                                                                    descricao:
                                                                        pessoaFuncionarios[key].Pessoa.descricao ||
                                                                        pessoaFuncionarios[key].descricao,
                                                                    funcao: "editar",
                                                                });

                                                                // Defina o novo estado
                                                                setFuncionarios({
                                                                    ...Funcionarios,
                                                                    id: pessoaFuncionarios[key].pessoaId,
                                                                    descricao:
                                                                        pessoaFuncionarios[key].Pessoa.descricao ||
                                                                        pessoaFuncionarios[key].descricao,
                                                                });
                                                            }}
                                                        >
                                                            Editar
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            color="error"
                                                            onClick={() => {
                                                                setNovoFuncionario({
                                                                    index: index,
                                                                    uuid: pessoaFuncionarios[key].uuid,
                                                                    pessoaId: pessoaFuncionarios[key].pessoaId,
                                                                    funcionarioId: pessoaFuncionarios[key].funcionarioId,
                                                                    ativo: pessoaFuncionarios[key].ativo,
                                                                    descricao:
                                                                        pessoaFuncionarios[key].Pessoa.descricao ||
                                                                        pessoaFuncionarios[key].descricao,
                                                                    funcao: "deletar",
                                                                });

                                                                // Defina o novo estado
                                                                setFuncionarios({
                                                                    ...Funcionarios,
                                                                    id: pessoaFuncionarios[key].pessoaId,
                                                                    descricao:
                                                                        pessoaFuncionarios[key].Pessoa.descricao ||
                                                                        pessoaFuncionarios[key].descricao,
                                                                });
                                                            }}
                                                            style={{
                                                                marginLeft: "5px",
                                                            }}
                                                        >
                                                            Excluir
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={formStyles.stiloPadrao}>
                        <h4>Filiais as quais o usuário tem permissão</h4>
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <SelectEmpresa
                                    name="novaFilial.empresaId"
                                    text="Empresa"
                                    inputWidth="400px"
                                    options={OptionsEmpresa}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novaFilial.empresaId}
                                    isEnum={false}
                                    notOptNull={false}
                                />

                                <Checkbox
                                    title="Ativa ou Inativa"
                                    text="Ativo"
                                    name="novaFilial.ativo"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={novaFilial.ativo}
                                    checked={novaFilial.ativo === true ? "checked" : ""}
                                />

                                <Button
                                    variant="contained"
                                    color="inherit"
                                    onClick={() =>
                                        setNovaFilial({
                                            uuid: null,
                                            pessoaId: pessoa.id,
                                            empresaId: null,
                                            ativo: false,
                                            funcao: "adicionar",
                                        })
                                    }
                                    style={{ marginLeft: "10px" }}
                                >
                                    Limpar
                                </Button>
                                <Button
                                    variant="contained"
                                    color={novaFilial.funcao === "deletar" ? "error" : "info"}
                                    onClick={() => handleFilial(novaFilial)}
                                    disabled={novaFilial.empresaId === null || novaFilial.empresaId === undefined ? true : false}
                                    style={{ marginLeft: "5px" }}
                                >
                                    {novaFilial.funcao}
                                </Button>
                            </div>
                            <div>
                                <table className={formStyles.table}>
                                    <thead>
                                        <tr>
                                            <th className={formStyles.th} align="left">
                                                CNPJ
                                            </th>
                                            <th className={formStyles.th} align="left">
                                                Razão Social
                                            </th>
                                            <th className={formStyles.th}>Ativo</th>
                                            <th className={formStyles.th} align="center">
                                                Ações
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className={formStyles.tbody}>
                                        <>
                                            {Object.keys(pessoaEmpresas).map((key, index, array) => (
                                                <tr className={formStyles.tr} key={pessoaEmpresas[key].uuid || index}>
                                                    <td
                                                        className={formStyles.td}
                                                        style={{
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        {pessoaEmpresas[key].Empresa.cnpj}
                                                    </td>
                                                    <td
                                                        className={formStyles.td}
                                                        style={{
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        {pessoaEmpresas[key].Empresa.razaoSocial}
                                                    </td>
                                                    <td className={formStyles.td}>{pessoaEmpresas[key].ativo ? "Sim" : "Não"}</td>
                                                    <td className={formStyles.td}>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            onClick={() =>
                                                                setNovaFilial({
                                                                    index: index,
                                                                    pessoaId: pessoaEmpresas[key].pessoaId,
                                                                    uuid: pessoaEmpresas[key].uuid,
                                                                    empresaId: pessoaEmpresas[key].empresaId,

                                                                    ativo: pessoaEmpresas[key].ativo,
                                                                    funcao: "editar",
                                                                })
                                                            }
                                                        >
                                                            Editar
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            color="error"
                                                            onClick={() =>
                                                                setNovaFilial({
                                                                    index: index,
                                                                    uuid: pessoaEmpresas[key].uuid,
                                                                    pessoaId: pessoaEmpresas[key].pessoaId,
                                                                    empresaId: pessoaEmpresas[key].empresaId,
                                                                    ativo: pessoaEmpresas[key].ativo,
                                                                    funcao: "deletar",
                                                                })
                                                            }
                                                            style={{
                                                                marginLeft: "5px",
                                                            }}
                                                        >
                                                            Excluir
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <div>
                    <Stack direction="row" spacing={1}>
                        <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                            Voltar
                        </Button>
                        <Button type="submit" variant="contained" color="success" disabled={!pessoa.nomePrincipal}>
                            {btnText}
                        </Button>
                    </Stack>
                </div>
            </form>
        </Tabs>
    );
}

export default Form;
