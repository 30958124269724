import React, { useEffect, useMemo, useState, useContext } from "react";
import "react-tabs/style/react-tabs.css";
import "moment/locale/pt-br";

/* contexts */
import { Context } from "../../../context/UserContext";

import { Stack, Button, Box, Tooltip, IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import MaterialReactTable from "material-react-table";

import { ThemeProvider } from "@mui/material";
/* tema customizado*/
import tableThemeCustom from "../../form/tableThemeCustom";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

//Import Material React Table Translations
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";

import Input from "../../form/Input";
import DataList from "../../form/DataList";
import InputNumber from "../../form/InputNumber";
import Select from "../../form/Select";
import Checkbox from "../../form/Checkbox";
import SelectEmpresa from "../../form/SelectEmpresa";

import formStyles from "../../form/Form.module.css";

/* utils */
import { formatarNumero, formatarNumeroDecimal } from "../../../utils/masks";
import tratar from "../../../utils/retornaTratamentoURL";

/* api */
import * as DataListAPI from "../../../api/dataList";
import * as EmbalagemAPI from "../../../api/embalagem";
import * as TabelaAPI from "../../../api/tabela";
import * as TabelaPrecoAPI from "../../../api/tabelaPreco";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, OptionsEmpresa, EnumTipo }) {
    const [dados, setDados] = useState(formData || {});
    const [itens, setItens] = useState([]);
    const [OptionsEmbalagem, setOptionsEmbalagem] = useState([]);
    const [OptionsOperacao, setOptionsOperacao] = useState([]);
    const [Operacao, setOperacao] = useState({ descricao: null });
    const [OptionsProduto, setOptionsProduto] = useState([]);
    const [Produto, setProduto] = useState({ descricao: null });
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();

    //obtem os parametros da empresa
    const { parametro } = useContext(Context);

    //para definir campos padrões
    if (!dados.empresaId) {
        dados.empresaId = localStorage.getItem("empresaId");
        dados.ativo = true;
    }

    //data and fetching state
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    const [novoProduto, setNovoProduto] = useState({
        uuid: null,
        Produto,
        empresaId: dados.empresaId,
        tabelaPrecoId: dados.id,
        produtoId: null,
        embalagemId: null,
        fator: 1,
        preco: null,
        precoUnitario: null,
        descricao: null,
        ativo: true,
        funcao: "adicionar",
    });

    const consultaEmbalagemByProdutoId = async (empresaId, produtoId) => {
        const result = await TabelaAPI.embalagemByProdutoId(empresaId, produtoId);

        if (result && result.request && result.request.status === 404) {
            setFlashMessage(result.response.data.message, "error");
        } else {
            setOptionsEmbalagem(result);
        }
    };

    const consultaFator = async (token, empresaId, embalagemId) => {
        const result = await TabelaAPI.fatorByEmbalagemId(empresaId, embalagemId);
        setNovoProduto({
            ...novoProduto,
            empresaId: empresaId,
            embalagemId: embalagemId,
            fator: result?.fator || 1,
        });
    };

    //tratar filtros se vier pela navbar
    if (localStorage.path.replace(/["]/g, "") !== window.location.pathname) {
        tratar.LimparFiltros();
        localStorage.setItem("path", JSON.stringify(window.location.pathname));
    }

    //Atualiza o Datalist
    const consultaDatalist = async (token, empresaId, tabela, id, descricao, condicao, filtro) => {
        let result = await DataListAPI.dataList(empresaId, tabela, id, descricao, condicao, filtro);
        return result;
    };

    //table state
    const [columnFilterFns, setColumnFiltersMode] = useState(
        localStorage.columnFilterFns.length > 2 ? JSON.parse(localStorage.columnFilterFns) : []
    );
    const [columnFilters, setColumnFilters] = useState(
        localStorage.columnFilters.length > 2 ? JSON.parse(localStorage.columnFilters) : []
    );
    const [globalFilter, setGlobalFilter] = useState(
        localStorage.globalFilter.length > 2 ? JSON.parse(localStorage.globalFilter) : ""
    );
    const [sorting, setSorting] = useState(localStorage.sorting.length > 2 ? JSON.parse(localStorage.sorting) : []);
    const [pagination, setPagination] = useState({
        pageIndex: JSON.parse(localStorage.pagination).pageIndex || 0,
        pageSize: JSON.parse(localStorage.pagination).pageSize || 10,
    });

    const tabelaPrecoId = dados.id ? dados.id : null;

    const fetchData = async () => {
        if (!itens.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }

        if (dados.id) {
            try {
                TabelaPrecoAPI.listarProdutos(
                    pagination.pageIndex * pagination.pageSize,
                    pagination.pageSize,
                    JSON.stringify(columnFilters ?? []),
                    JSON.stringify(columnFilterFns ?? []),
                    globalFilter ?? "",
                    JSON.stringify(sorting ?? []),
                    tabelaPrecoId ?? ""
                ).then((response) => {
                    setItens(response.data.data);
                    setRowCount(response.data.meta.totalRowCount);
                });
            } catch (error) {
                setIsError(true);
                console.error(error);
                return;
            }
        }

        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
    };

    const limparProduto = () => {
        setNovoProduto({
            empresaId: dados.empresaId,
            embalagemId: null,
            preco: null,
            precoUnitario: null,
            ativo: true,
            fator: 1,
            funcao: "adicionar",
        });
        setProduto({
            id: null,
            descricao: null,
        });
        setOptionsEmbalagem([]);
    };

    const CreateOrEditProduto = async (novoProduto) => {
        try {
            const result = await TabelaPrecoAPI.tabelaPrecoProduto(novoProduto);
            setFlashMessage(result.message, result.type);

            if (result?.status === 200 || result?.type === "success") {
                limparProduto();
                fetchData();
            }
        } catch (error) {
            console.error(error);
            if (error.response && error.response.status === 422) {
                // Tratar o erro 422, que é específico da validação no servidor
                setFlashMessage("Erro de validação: " + error.response.data.message);
            } else {
                // Tratar outros erros de rede ou servidor
                setFlashMessage("Ocorreu um erro ao processar a solicitação.", "error");
            }
        }
    };

    /*Tabela de Preços Produtos*/
    const handleProduto = async () => {
        if (novoProduto.funcao === "editar") {
            if (novoProduto.uuid) {
                CreateOrEditProduto(novoProduto);
            } else {
                setItens((prevItens) => {
                    const novosItens = [...prevItens];
                    novosItens[novoProduto.index] = {
                        ...prevItens[novoProduto.index],
                        ...novoProduto,
                    };
                    return novosItens;
                });
            }
        } else if (novoProduto.funcao === "deletar") {
            if (novoProduto.uuid) {
                try {
                    const response = await TabelaPrecoAPI.deletarProduto(novoProduto.uuid);
                    setFlashMessage(response.data.message, response.data.type);
                } catch (error) {
                    console.error(error);
                    setFlashMessage(error.response.data.message, error.response.data.type);
                }
            } else {
                setItens((prevItens) => {
                    const novosItens = [...prevItens];
                    novosItens.splice(novoProduto.index, 1);
                    return novosItens;
                });
            }
        } else {
            //cadastrar
            if (dados.id) {
                //verificar duplicidade
                const result = await TabelaPrecoAPI.duplicidade(
                    dados.empresaId,
                    novoProduto.tabelaPrecoId,
                    novoProduto.produtoId,
                    novoProduto.embalagemId
                );

                if (result && novoProduto.funcao === "adicionar") {
                    setFlashMessage("Registo em duplicidade!", "warning");
                    handleEditar(result);
                    return;
                }

                CreateOrEditProduto(novoProduto);
            } else {
                //montar um novo item, Produto, Embalagem, Empresa
                const Embalagem = await EmbalagemAPI.retornaEmbalagem(novoProduto.embalagemId);
                const Empresa = await TabelaAPI.tabelaById(dados.empresaId, "Empresa", dados.empresaId);
                const Produto = await TabelaAPI.tabelaById(dados.empresaId, "Produto", novoProduto.produtoId);

                const novoItemArray = [
                    ...itens,
                    {
                        uuid: novoProduto.uuid,
                        empresaId: novoProduto.empresaId,
                        tabelaPrecoId: novoProduto.tabelaPrecoId,
                        produtoId: novoProduto.produtoId,
                        embalagemId: novoProduto.embalagemId,
                        fator: novoProduto.fator,
                        preco: novoProduto.preco,
                        precoUnitario: novoProduto.precoUnitario,
                        descricao: novoProduto.Produto.descricao,
                        ativo: novoProduto.ativo,

                        Embalagem: Embalagem,
                        Empresa: Empresa,
                        Produto: Produto,
                    },
                ];

                setItens(novoItemArray);
                setRowCount(novoItemArray.length);

                setDados((prev) => ({
                    ...prev, // Mantém as outras propriedades do objeto
                    tabelaPrecoItens: novoItemArray, //[...(prev.tabelaPrecoItems || []), ...novoItemArray], // Adiciona o novo array ou atualiza o existente
                }));
            }
        }

        limparProduto();

        fetchData();
    };

    const handleEditar = (originalRow, row) => {
        // Define os dados para o novo produto
        const novoProdutoData = {
            uuid: originalRow.uuid,
            Produto: originalRow.Produto,
            Embalagem: originalRow.Embalagem,
            Empresa: originalRow.Empresa,
            empresaId: originalRow.empresaId,
            tabelaPrecoId: originalRow.tabelaPrecoId,
            produtoId: originalRow.produtoId,
            embalagemId: originalRow.embalagemId,
            fator: originalRow.Embalagem.Unidade.fator,
            preco: originalRow.preco,
            precoUnitario: originalRow.precoUnitario,
            descricao: originalRow.Produto.descricao,
            ativo: originalRow.ativo,
            funcao: "editar",
            index: row,
        };

        // Atualize o estado do novo produto
        setNovoProduto(novoProdutoData);

        // Atualize o estado do Produto com a descrição
        setProduto({ ...Produto, descricao: originalRow.Produto.descricao });

        //Atualiza o estado da Embalagem
        setOptionsEmbalagem([originalRow.Embalagem]);

        return;
    };

    const handleDelete = (originalRow, row) => {
        const novoProdutoData = {
            uuid: originalRow.uuid,
            Produto: originalRow.Produto,
            Embalagem: originalRow.Embalagem,
            Empresa: originalRow.Empresa,
            empresaId: originalRow.empresaId,
            tabelaPrecoId: originalRow.tabelaPrecoId,
            produtoId: originalRow.produtoId,
            embalagemId: originalRow.embalagemId,
            fator: originalRow.Embalagem.Unidade.fator,
            preco: originalRow.preco,
            precoUnitario: originalRow.precoUnitario,
            descricao: originalRow.Produto.descricao,
            ativo: originalRow.ativo,
            funcao: "deletar",
            index: row,
        };

        // Atualize o estado do novo produto
        setNovoProduto(novoProdutoData);
        // Atualize o estado do Produto com a descrição
        setProduto({ ...Produto, descricao: originalRow.Produto.descricao });

        //Atualiza o estado da Embalagem
        setOptionsEmbalagem([originalRow.Embalagem]);
    };

    useEffect(() => {
        setDados(formData || {});
        setOperacao(formData.Operacao || {});

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData, token]);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnFilterFns, columnFilters, globalFilter, pagination.pageIndex, pagination.pageSize, sorting, tabelaPrecoId]);

    async function handleChange(e) {
        const camposBooleanos = ["liberada", "servico", "entrada", "saida", "ativo"];
        if (camposBooleanos.includes(e.target.name)) {
            setDados({ ...dados, [e.target.name]: e.target.checked });
        } else if (e.target.name.startsWith("Operacao.")) {
            setOperacao({
                ...Operacao,
                [e.target.name.split(".")[1]]: e.target.value,
            });

            //carrega o datalist
            if (e.target.value?.length > 0) {
                let result = await consultaDatalist(
                    token,
                    parametro.tabelasComuns === true ? 1 : dados.empresaId,
                    "Operacao",
                    "id",
                    "descricao",
                    null,
                    e.target.value
                );
                setOptionsOperacao(result);
            }
            setDados({
                ...dados,
                operacaoId: document.querySelector(`datalist option[value="${e.target.value}"]`)?.dataset.id,
                Operacao: { descricao: e.target.value },
            });
        } else if (e.target.name.startsWith("novoProduto")) {
            if (e.target.name.startsWith("novoProduto.descricao")) {
                setProduto({
                    ...Produto,
                    [e.target.name.split(".")[1]]: e.target.value,
                });
                //carrega o datalist
                if (e.target.value?.length > 0) {
                    let result = await consultaDatalist(
                        token,
                        dados.empresaId,
                        "Produto",
                        "id",
                        "descricao",
                        null,
                        e.target.value
                    );

                    setOptionsProduto(result);
                }
                setNovoProduto({
                    ...novoProduto,
                    empresaId: dados.empresaId,
                    tabelaPrecoId: dados.id,
                    produtoId: document.querySelector(`datalist option[value="${e.target.value}"]`)?.dataset.id,
                    Produto: { descricao: e.target.value },
                });
            } else if (e.target.name === "novoProduto.embalagemId") {
                setNovoProduto({
                    ...novoProduto,
                    [e.target.name.split(".")[1]]: e.target.value,
                });
                if (e.target.value) {
                    await consultaFator(token, dados.empresaId, e.target.value);
                }
            } else if (e.target.name === "novoProduto.preco" || e.target.name === "novoProduto.precoUnitario") {
                //calcula valor unitario
                if (e.target.name === "novoProduto.preco") {
                    const valorFormatado = formatarNumeroDecimal(e.target.value);

                    novoProduto.precoUnitario = parseFloat(valorFormatado / novoProduto.fator).toFixed(2);
                }
                //calcula valor
                if (e.target.name === "novoProduto.precoUnitario") {
                    const valorFormatado = formatarNumeroDecimal(e.target.value);
                    novoProduto.preco = parseFloat(valorFormatado * novoProduto.fator).toFixed(2);
                }
                setNovoProduto({
                    ...novoProduto,
                    [e.target.name.split(".")[1]]: formatarNumeroDecimal(e.target.value),
                });
            } else {
                //set os dados dos checkbox
                setNovoProduto({
                    ...novoProduto,
                    empresaId: dados.empresaId,
                    tabelaPrecoId: dados.id,
                    [e.target.name.split(".")[1]]: e.target.checked,
                });
            }
        } else {
            setDados({ ...dados, [e.target.name]: e.target.value });
        }
    }
    async function handleOnBlur(e) {
        //verifica duplicidade
        if (e.target.name === "descricao" && btnText === "Cadastrar") {
            const duplicidade = await TabelaAPI.duplicidadeDescricao(dados.empresaId, "tabelaPreco", e.target.value);

            if (duplicidade && duplicidade.uuid) {
                setFlashMessage("Registro em duplicidade!", "warning");
                return navigate(`/TabelaPrecos/CreateOrEdit`, {
                    uuid: duplicidade.uuid,
                });
            }
        }

        //ao escolher um produto retorna as embalagens
        if (e.target.name.startsWith("novoProduto.descricao")) {
            await consultaEmbalagemByProdutoId(dados.empresaId, novoProduto.produtoId);
        }
    }
    async function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();
        if (!dados.empresaId) {
            dados.empresaId = parseInt(document.getElementById("empresaId").value);
        }
        handleSubmit(dados);
    };

    /* código da grid */
    const columns = useMemo(
        () => [
            {
                id: "Produtos",
                header: "Produtos da Tabela de Preços",
                columns: [
                    {
                        accessorKey: "Produto.descricao",
                        header: "Descrição",
                        //you can access a cell in many callback column definition options like this
                        muiTableBodyCellProps: ({ cell }) => ({
                            onClick: () => {},
                        }),
                    },
                    {
                        accessorKey: "Embalagem.descricao",
                        header: "Embalagem",
                        //you can access a cell in many callback column definition options like this
                        muiTableBodyCellProps: ({ cell }) => ({
                            onClick: () => {},
                        }),
                    },
                    {
                        accessorKey: "precoUnitario",
                        header: "Preço Unitário",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.precoUnitario === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.precoUnitario)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "preco",
                        header: "Preço Total",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.preco === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.preco)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "Empresa.nomeFantasia",
                        header: "Empresa",
                    },
                ],
            },

            //column definitions...
        ],
        [] //getCommonEditTextFieldProps
    );

    /* modo tema */
    const theme = tableThemeCustom();
    return (
        <form onSubmit={submit}>
            <div className={formStyles.stiloPadrao}>
                <h1>Tabela de Preços</h1>
                <div>
                    <SelectEmpresa
                        text="Selecione a Empresa"
                        name="empresaId"
                        options={OptionsEmpresa}
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        value={dados.empresaId || ""}
                        required
                        notOptNull={true}
                    />
                    <DataList
                        text="Operação"
                        title={"Digite para selecionar a operação"}
                        name="Operacao.descricao"
                        inputWidth="250px"
                        filteredOptions={OptionsOperacao}
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        value={Operacao?.descricao || ""}
                    />
                    <Input
                        text="Referência"
                        type="text"
                        name="referencia"
                        placeholder="nome simplificado"
                        title="Digite a referência da tabela de preço, nome simplificado"
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        value={dados.referencia || ""}
                        required
                    />
                    <Input
                        text="Descrição"
                        type="text"
                        name="descricao"
                        placeholder="Nome da Tabela"
                        title="Digite o nome da tabela de preços"
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        inputWidth="250px"
                        value={dados.descricao || ""}
                        required
                    />
                    <Checkbox
                        title="Quando marcada a tabela de preço fica disponível para uso"
                        text="Liberada"
                        name="liberada"
                        type="checkbox"
                        handleOnChange={handleChange}
                        value={dados.liberada}
                        checked={dados.liberada === true ? "checked" : ""}
                    />
                    <Checkbox
                        title="Tabela para movimentos de serviço"
                        text="Serviço"
                        name="servico"
                        type="checkbox"
                        handleOnChange={handleChange}
                        value={dados.servico}
                        checked={dados.servico === true ? "checked" : ""}
                    />
                    <Checkbox
                        title="Tabela para movimentos de Entrada"
                        text="Entrada"
                        name="entrada"
                        type="checkbox"
                        handleOnChange={handleChange}
                        value={dados.entrada}
                        checked={dados.entrada === true ? "checked" : ""}
                    />

                    <Checkbox
                        title="Tabela para movimentos de Saída"
                        text="Saída"
                        name="saida"
                        type="checkbox"
                        handleOnChange={handleChange}
                        value={dados.saida}
                        checked={dados.saida === true ? "checked" : ""}
                    />
                    <Checkbox
                        title="Quando marcada a opção ATIVA"
                        text="Ativo"
                        name="ativo"
                        type="checkbox"
                        handleOnChange={handleChange}
                        value={dados.ativo}
                        checked={dados.ativo === true ? "checked" : ""}
                    />
                </div>
                <h4>Novos produtos na tabela de preço</h4>
                <div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "1rem",
                        }}
                    >
                        <DataList
                            text="Produto"
                            title={"Digite para selecionar o produto"}
                            name="novoProduto.descricao"
                            inputWidth="350px"
                            filteredOptions={OptionsProduto}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={Produto?.descricao || ""}
                        />
                        <Select
                            name="novoProduto.embalagemId"
                            title={"Escolha a embalagem do produto"}
                            text="Embalagem"
                            options={OptionsEmbalagem}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={novoProduto.embalagemId}
                            isEnum={false}
                            notOptNull={false}
                        />
                        <InputNumber
                            text="Valor Total"
                            type="text"
                            name="novoProduto.preco"
                            placeholder="Valor R$"
                            inputWidth="100px"
                            title="digite o valor total do produto para a embalagem"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={novoProduto.preco ? formatarNumero(novoProduto.preco) : ""}
                            readonly={!novoProduto.embalagemId}
                        />
                        <InputNumber
                            text="Valor Unitário"
                            type="text"
                            name="novoProduto.precoUnitario"
                            placeholder="Valor R$"
                            inputWidth="100px"
                            title="digite o valor unitário do produto"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={novoProduto.precoUnitario ? formatarNumero(novoProduto.precoUnitario) : ""}
                            readonly={!novoProduto.embalagemId}
                        />
                        <Checkbox
                            title="Ativo quando marcado"
                            text="Ativo"
                            name="novoProduto.ativo"
                            type="checkbox"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={novoProduto.ativo}
                            checked={novoProduto.ativo === true ? "checked" : ""}
                        />
                        <Button
                            variant="contained"
                            color="inherit"
                            onClick={() => {
                                setNovoProduto({
                                    uuid: null,
                                    empresaId: dados.empresaId,
                                    tabelaPrecoId: dados.id,
                                    produtoId: null,
                                    embalagemId: null,
                                    fator: 0,
                                    valor: null,
                                    valorUnitario: null,
                                    descricao: null,
                                    ativo: true,
                                    funcao: "adicionar",
                                });
                                setProduto({
                                    ...Produto,
                                    id: null,
                                    descricao: null,
                                });
                            }}
                            style={{ marginLeft: "10px" }}
                        >
                            Limpar
                        </Button>
                        <Button
                            variant="contained"
                            color={novoProduto.funcao === "deletar" ? "error" : "info"}
                            onClick={() => handleProduto(novoProduto)}
                            disabled={novoProduto.produtoId === null || novoProduto.produtoId === undefined ? true : false}
                            style={{ marginLeft: "5px" }}
                        >
                            {novoProduto.funcao}
                        </Button>
                    </div>
                </div>
            </div>

            <ThemeProvider theme={theme}>
                <MaterialReactTable
                    displayColumnDefOptions={{
                        "mrt-row-actions": {
                            muiTableHeadCellProps: {
                                align: "center",
                            },
                            size: 100,
                        },
                        "mrt-row-numbers": {
                            enableColumnOrdering: true, //turn on some features that are usually off
                            enableResizing: true,
                            muiTableHeadCellProps: {
                                sx: {
                                    fontSize: "1.2rem",
                                },
                            },
                        },
                        "mrt-row-select": {
                            enableColumnActions: true,
                            enableHiding: true,
                            size: 50,
                        },
                    }}
                    enableColumnFilterModes
                    enableGlobalFilterModes
                    enableColumnResizing
                    enableColumnOrdering
                    enableEditing
                    renderRowActions={({ row, table }) => (
                        <Box sx={{ display: "flex", gap: "1rem" }}>
                            <Tooltip arrow placement="left" title="Editar">
                                <span>
                                    {" "}
                                    <IconButton
                                        onClick={() => handleEditar(row.original, row.id)}
                                        disabled={localStorage.getItem("editar") === "true" ? false : true}
                                    >
                                        <Edit />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip arrow placement="right" title="Deletar">
                                <span>
                                    <IconButton
                                        color="error"
                                        //onClick={() => handleOpenDialog(row.id)}
                                        onClick={() => handleDelete(row.original, row.id)}
                                        disabled={localStorage.getItem("cancelar") === "true" ? false : true}
                                    >
                                        <Delete />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Box>
                    )}
                    renderTopToolbarCustomActions={() => (
                        <div
                            style={{
                                display: "flex",
                                gap: "8px",
                            }}
                        >
                            <Tooltip arrow placement="top-end" title={`Novo Registro`}>
                                <div>
                                    {" "}
                                    <Button
                                        color="secondary"
                                        onClick={() => navigate("/TabelaPrecos/CreateOrEdit")}
                                        variant="contained"
                                        disabled={
                                            true
                                            // localStorage.getItem("incluir") ===
                                            // "true"
                                            //     ? false
                                            //     : true
                                        }
                                    >
                                        Novo
                                    </Button>
                                </div>
                            </Tooltip>
                        </div>
                    )}
                    columns={columns}
                    data={itens}
                    enableRowSelection
                    getRowId={(row) => row.uuid}
                    manualFiltering
                    manualPagination
                    manualSorting
                    muiToolbarAlertBannerProps={
                        isError
                            ? {
                                  color: "error",
                                  children: "Error loading data",
                              }
                            : undefined
                    }
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setColumnFiltersMode}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    rowCount={rowCount}
                    initialState={{
                        showColumnFilters: true,
                        density: "compact",
                        //columnPinning: { left: ["mrt-row-select"] },
                    }}
                    state={{
                        columnFilterFns,
                        columnFilters,
                        globalFilter,
                        isLoading,
                        pagination,
                        showAlertBanner: isError,
                        showProgressBars: isRefetching,
                        sorting,
                    }}
                    //positionToolbarDropZone={"top"}
                    positionExpandColumn={"first"}
                    positionActionsColumn={"first"}
                    positionToolbarAlertBanner="bottom"
                    localization={MRT_Localization_PT_BR}
                />
            </ThemeProvider>

            <div>
                <Stack direction="row" spacing={1}>
                    <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                        Voltar
                    </Button>
                    <Button type="submit" variant="contained" color="success">
                        {btnText}
                    </Button>
                </Stack>
            </div>
        </form>
    );
}
export default Form;
