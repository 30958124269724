import api from ".";

export const embalagem = async (novaEmbalagem) => {
    try {
        const { data } = await api.post("/produto/embalagem", novaEmbalagem);
        return data;
    } catch (error) {
        return error;
    }
};

export const ncm = async (pesquisa, empresaId = 1) => {
    try {
        const { data } = await api.get("/serviceNCM", {
            params: { pesquisa, empresaId },
        });
        return data;
    } catch (error) {
        return error;
    }
};

export const operacao = async (entrada, saida, industria, balanco, manutencao) => {
    try {
        const { data } = await api.get("/serviceOPERACAO", {
            params: { entrada, saida, industria, balanco, manutencao },
        });
        return data;
    } catch (error) {
        return error;
    }
};

export const cadastrar = (dados) =>
    api.post("/produto/cadastrar/", dados, {
        headers: { "Content-Type": "multipart/form-data" },
    });

export const consulta = (uuid) => api.get(`/produto/editar/${uuid}`);

export const editar = (dados) =>
    api.post("/produto/editar/", dados, {
        headers: { "Content-Type": "multipart/form-data" },
    });

export const deletar = (selectedRow) => api.post("/produto/deletar/", selectedRow);

export const listar = (start, size, filters, filtersMode, globalFilter, sorting) =>
    api.get("/produto/listar/", {
        params: { start, size, filters, filtersMode, globalFilter, sorting },
    });

export const listarInventario = (start, size, filters, filtersMode, globalFilter, sorting, produtoBensId) =>
    api.get("/produto/listarInventario/", {
        params: { start, size, filters, filtersMode, globalFilter, sorting, produtoBensId },
    });

export const listarEmbalagens = (start, size, filters, filtersMode, globalFilter, sorting, produtoId) =>
    api.get("/produto/listarEmbalagens/", {
        params: { start, size, filters, filtersMode, globalFilter, sorting, produtoId },
    });

export const relatorio = (dadosFiltrados, params) =>
    api.post(
        "/produto/relatorio/",
        {
            params: { ...params },
            dadosFiltrados,
        },
        {
            responseType: "arraybuffer", // Define o tipo de resposta como arraybuffer
        }
    );

/**
 * UUIDs selecionados para incluir nota fiscal
 * @param {*} selectedUUIDs
 * @returns
 */
export const itensSelecionadosNF = (selectedUUIDs) => api.post("/produto/itensSelecionadosNF", selectedUUIDs);

//mobilia de inventario
export const mobilia = async (novaMobilia) => {
    try {
        const { data } = await api.post("/produto/mobilia", novaMobilia, {
            headers: { "Content-Type": "multipart/form-data" },
        });
        return data;
    } catch (error) {
        return error;
    }
};

export const deletarMobilia = async (novaMobilia) => {
    try {
        const { data } = await api.post("/produto/deletarMobilia", novaMobilia);
        return data;
    } catch (error) {
        return error;
    }
};

//tratamento de imagens e pdf de not fiscal do inventario e do produto
export const editarInventarioBemImagem = (image) => api.post("/produto/editarInventarioBemImagem/", image);
export const editarInventarioBemPDF = (image) => api.post("/produto/editarInventarioBemPDF/", image);
export const deletarInventarioBemImagem = (image) => api.post("/produto/deletarInventarioBemImagem/", image);
export const deletarInventarioBemPDF = (image) => api.post("/produto/deletarInventarioBemPDF/", image);

export const editarImagem = (image) => api.post("/produto/editarImagem/", image);
export const listarImagem = (start, size, filters, filtersMode, globalFilter, sorting, produtoId) =>
    api.get("/produto/listarImagem/", {
        params: { start, size, filters, filtersMode, globalFilter, sorting, produtoId },
    });
export const deletarImagem = (image) => api.post("/produto/deletarImagem/", image);

//serviços
export const bem = async (empresaId, ativo, filtro, condicao) => {
    try {
        const { data } = await api.get("/serviceBem", {
            params: {
                empresaId,
                ativo,
                filtro,
                condicao,
            },
        });
        return data;
    } catch (error) {
        return error;
    }
};

export const ultimoKm = async (produtoId, km) => {
    try {
        const { data } = await api.get("/serviceUltimoKm", {
            params: {
                produtoId,
                km,
            },
        });
        return data;
    } catch (error) {
        return error;
    }
};
