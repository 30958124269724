import { useState, useEffect } from "react";
import "react-tabs/style/react-tabs.css";
import "moment/locale/pt-br";

import { Stack, Button } from "@mui/material";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";
import * as TabelaAPI from "../../../api/tabela";

import Input from "../../form/Input";
import Select from "../../form/Select";
import Checkbox from "../../form/Checkbox";
import SelectEmpresa from "../../form/SelectEmpresa";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, OptionsEmpresa, OptionsForma, EnumTipo }) {
    const [dados, setDados] = useState(formData || {});
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();

    useEffect(() => {
        setDados(formData || {});

        //define o estado inicial do objeto
        if (!dados.empresaId) {
            dados.ativo = true;
            dados.empresaId = parseInt(document.getElementById("empresaId").value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    function handleChange(e) {
        if (
            e.target.name === "ativo" ||
            e.target.name === "baixaLancamento" ||
            e.target.name === "credito" ||
            e.target.name === "dependente" ||
            e.target.name === "geraFinanceiro"
        ) {
            setDados({ ...dados, [e.target.name]: e.target.checked });
        } else {
            setDados({ ...dados, [e.target.name]: e.target.value });
        }
    }

    async function handleOnBlur(e) {
        //verifica duplicidade
        if (e.target.name === "descricao" && btnText === "Cadastrar") {
            const duplicidade = await TabelaAPI.duplicidadeDescricao(
                document.getElementById("empresaId").value,
                "pagamentoForma",
                e.target.value
            );

            if (duplicidade?.uuid) {
                setFlashMessage("Registro em duplicidade!", "warning");
                return navigate(`/Forma/CreateOrEdit`, {
                    uuid: duplicidade.uuid,
                });
            }
        }
    }
    function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();

        if (!dados.empresaId) {
            dados.empresaId = parseInt(document.getElementById("empresaId").value);
        }

        handleSubmit(dados);
    };

    return (
        <form onSubmit={submit}>
            <h1>Formulário de forma de pagamentos ou recebimentos</h1>
            <div>
                <SelectEmpresa
                    text="Selecione a Empresa"
                    name="empresaId"
                    options={OptionsEmpresa}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.empresaId || ""}
                    required
                    notOptNull={true}
                />
                <Input
                    text="Forma"
                    type="text"
                    name="descricao"
                    placeholder="nome da forma"
                    title="Digite o nome da forma de pagamento ou recebimento"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.descricao || ""}
                    required
                />
                <Input
                    text="Nível"
                    type="number"
                    name="nivelVenda"
                    placeholder="nível da forma"
                    title="Digite o nível da forma de pagamento ou recebimento"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.nivelVenda || ""}
                    required
                />
                <Select
                    name="tipo"
                    title={"Define o tipo da forma de pagamento, recebimento ou ambos"}
                    text="Tipo"
                    options={EnumTipo}
                    handleOnChange={handleChange}
                    value={dados.tipo}
                    isEnum={true}
                    notOptNull={true}
                />
            </div>
            <div>
                <Checkbox
                    title="Informa se a forma é um cartão de crédito"
                    text="Crédito"
                    name="credito"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.credito}
                    checked={dados.credito === true ? "checked" : ""}
                />
                <Input
                    text="Dia do Vencimento"
                    type="number"
                    name="diaVencimento"
                    placeholder="dia de vencimento"
                    title="Digite o dia do vencimento do cartão para que o lançamento no sistema financeiro, seja gravado corretamente"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.diaVencimento || ""}
                    max={31}
                    required={false}
                />
                <Input
                    text="Melhor dia"
                    type="number"
                    name="melhorDiaCompra"
                    placeholder="melhor dia de compra"
                    title="Digite o melhor dia de compra, indica que o valor do lançamento será para a próxima fatura do cartão"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.melhorDiaCompra || ""}
                    max={31}
                    required={false}
                />
                <Checkbox
                    title="Informa se o cartão de crédito é de dependente"
                    text="Dependente"
                    name="dependente"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.dependente}
                    checked={dados.dependente === true ? "checked" : ""}
                />
                <Select
                    name="titular"
                    title={
                        "Define o titular do cartão de crédito, facilita no sistema financeiro contas a pagar, pois vai unir a fatura com os depentes"
                    }
                    text="Titular"
                    options={OptionsForma}
                    handleOnChange={handleChange}
                    value={dados.titular}
                    isEnum={false}
                    notOptNull={false}
                />
            </div>
            {dados.credito && (
                <div>
                    <Input
                        text="Bandeira"
                        type="text"
                        name="cartaoBandeira"
                        placeholder="Bandeira"
                        title="Digite a bandeira do cartão"
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        value={dados.cartaoBandeira || ""}
                        inputWidth="200px"
                        required={false}
                    />
                    <Input
                        text="Número Cartão"
                        type="number"
                        name="cartaoNumero"
                        placeholder="Cartão"
                        title="Digite número do cartão"
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        value={dados.cartaoNumero || ""}
                        inputWidth="200px"
                        required={false}
                    />
                    <Input
                        text="Validade"
                        type="number"
                        name="cartaoValidade"
                        placeholder="Validade"
                        title="Digite a validade do cartão"
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        value={dados.cartaoValidade || ""}
                        required={false}
                    />
                    <Input
                        text="CVV"
                        type="number"
                        name="cartaoCVV"
                        placeholder="CVV"
                        title="Digite código CVV"
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        value={dados.cartaoCVV || ""}
                        required={false}
                    />
                </div>
            )}

            <div>
                <Checkbox
                    title="Quando marcado gera lançamento no sistema financeiro"
                    text="Gera Financeiro"
                    name="geraFinanceiro"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.geraFinanceiro}
                    checked={dados.geraFinanceiro === true ? "checked" : ""}
                />
                <Checkbox
                    title="Quando marcado baixa o lançamento no sistema financeiro"
                    text="Baixa Lançamento"
                    name="baixaLancamento"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.baixaLancamento}
                    checked={dados.baixaLancamento === true ? "checked" : ""}
                />
                <Checkbox
                    title="Quando marcado a forma de pagamento ou recebimento esta ATIVO"
                    text="Ativo"
                    name="ativo"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.ativo}
                    checked={dados.ativo === true ? "checked" : ""}
                />
            </div>
            <div>
                <Stack direction="row" spacing={1}>
                    <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                        Voltar
                    </Button>
                    <Button type="submit" variant="contained" color="success">
                        {btnText}
                    </Button>
                </Stack>
            </div>
        </form>
    );
}
export default Form;
