import { useContext } from "react";
import { Context } from "../../context/UserContext";
import styles from "./Select.module.css";

function Select({
    text,
    name,
    options,
    handleOnChange,
    handleOnBlur,
    handleOnFocus,
    value,
    isEnum,
    notOptNull,
    inputWidth = "150px",
}) {
    //configura o tema
    const { parametro } = useContext(Context);

    return (
        <div className={`${styles.form_control} ${styles[parametro.tema]}`}>
            <label htmlFor={name}>{text}:</label>
            <select
                name={name}
                id={name}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onFocus={handleOnFocus}
                value={value || ""}
                style={{
                    width: `var(--input-width, ${inputWidth})`,
                }}
            >
                {notOptNull !== true && <option>Selecione uma Empresa</option>}
                {options?.map((opt) => (
                    <option key={opt.id} value={opt.id} title={"Razão Social: " + opt.razaoSocial}>
                        {" "}
                        {opt.nomeFantasia}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default Select;
