import React, { useEffect, useState, useContext } from "react";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import { Context } from "../../../context/UserContext";
import Form from "./Form";
import styles from "../../form/Form.module.css";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";

import * as EmpresaAPI from "../../../api/empresa";
import * as TabelaAPI from "../../../api/tabela";
import * as FinanceiroAPI from "../../../api/financeiro";
import CrudsAPI from "../../../api/CRUDs";

const CreateOrEdit = () => {
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();

    const [dados, setDados] = useState({});
    const [token] = useState(localStorage.getItem("token")) || "";
    const { parametro } = useContext(Context);

    const urlParams = new URLSearchParams(window.location.search);

    const pathname = "/Forma";
    const ul = urlParams.get("ul");
    const li = urlParams.get("li");
    const tipo = urlParams.get("tipo");
    const operacaoUUID = urlParams.get("operacao");
    const uuid = urlParams.get("uuid");
    const btnText = urlParams.get("btnText");

    const params = new URLSearchParams({
        ul: ul,
        li: li,
        tipo: tipo,
        operacao: operacaoUUID,
        uuid: uuid,
        btnText: btnText,
    });

    if (!dados.empresaId) {
        dados.empresaId = parseInt(localStorage.getItem("empresaId"));
    }

    //enums e tabelas
    const [enumTipo, setEnumTipo] = useState([]);

    const [optionsEmpresa, setEmpresa] = useState([]);
    const [optionsForma, setOptionsForma] = useState([]);

    //enums
    const consultaEnum = async (table, field, filter) => {
        const result = await TabelaAPI.enums(table, field, filter);
        if (table === "pagamentoFormas") {
            field === "tipo" && setEnumTipo(result ? result : []);
        }
    };

    //carregar API's Externas
    const consultaEmpresa = async () => {
        const result = await EmpresaAPI.empresa(localStorage.getItem("empresaId"));
        setEmpresa(result);
    };

    const consultaForma = async (tipo, credito, empresaId, descricao, dependente, nivel, formaId) => {
        const result = await FinanceiroAPI.forma(tipo, credito, empresaId, descricao, dependente, nivel, formaId);
        setOptionsForma(result);
    };

    useEffect(() => {
        if (!token) {
            return navigate("/Auth", { replace: true });
        }
        //enums e apis
        consultaEnum("pagamentoFormas", "tipo");

        consultaEmpresa();

        //consulta formas dependente somente credito
        consultaForma(null, true, dados.empresaId, null, false, null, null);

        if (uuid && uuid !== "null") {
            CrudsAPI.forma
                .consulta(uuid)
                .then((response) => {
                    setDados(response.data);
                })
                .catch((error) => {
                    console.error(error);
                    setFlashMessage(error.response.data.message, error.response.data.type);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, uuid]);

    async function managerDados(dados) {
        let urlNavigate = pathname;
        try {
            let response;

            if (uuid && uuid !== "null") {
                response = await CrudsAPI.forma.editar(dados);
            } else {
                response = await CrudsAPI.forma.cadastrar(dados);

                if (response.status === 202) {
                    setDados(response.data);
                    response.data.message = "Registro em duplicidade!";
                    response.data.type = "warning";
                    urlNavigate = pathname + "/CreateOrEdit";
                    params.set("uuid", response.data.uuid);
                }
            }

            setFlashMessage(response.data.message, response.data.type);

            setTimeout(() => {
                navigate(urlNavigate, params);
            }, parametro.msgTempo * 1000);
        } catch (error) {
            console.error(error);
            setFlashMessage(error.response.data.message, error.response.data.type);
        }
    }

    return (
        <section className={`${styles.form_container} ${styles[parametro.tema]}`}>
            <Form
                token={token}
                handleSubmit={managerDados}
                formData={dados}
                btnText={btnText}
                optionsEmpresa={optionsEmpresa}
                optionsForma={optionsForma}
                enumTipo={enumTipo}
                caminhoVoltar={pathname}
            />
        </section>
    );
};
export default CreateOrEdit;
