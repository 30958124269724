import api from ".";

export const ncmById = async (id) => {
    try {
        const { data } = await api.get(`/ncm/retornaNCMById/${id}`);
        return data;
    } catch (error) {
        return error;
    }
};
