export const maskDecimal = (valor) => {
    const parsedValue = parseFloat(valor);
    return parsedValue?.toLocaleString("pt-BR", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        minimumIntegerDigits: 1,
        useGrouping: true,
    });
};
export const maskCNPJ = (str) => {
    let v = "";
    v = str.replace(/\D/g, "");

    if (v.length <= 11) {
        v = v.replace(/(\d{3})(\d)/, "$1.$2");
        v = v.replace(/(\d{3})(\d)/, "$1.$2");
        v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    } else {
        v = v.substring(0, 14); // limita em 14 números
        v = v.replace(/^(\d{2})(\d)/, "$1.$2");
        v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
        v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
        v = v.replace(/(\d{4})(\d)/, "$1-$2");
    }

    return v;
};
export const maskCEP = (str) => {
    let v = "";
    if (str) {
        v = str.replace(/\D/g, "");

        if (v.length) {
            v = v.substring(0, 8); // limita em 8 números
            v = v.replace(/^(\d{2})(\d)/, "$1.$2");
            v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2-$3");
        }
    }

    return v;
};
/**
 * Função para formatar o número decimal em tempo de digitação.
 * @param {string} numero - O número a ser formatado.
 * @returns {string} O número formatado.
 * @comentario Servi para mostrar ao usuário o número formatado
 */
export const formatarNumero = (valor) => {
    if (isNaN(valor)) {
        return;
    }

    const isNegative = Number(valor) < 0; // Verifica se o número é negativo
    const vr = valor.toString().replace(/\D/g, ""); // Remove todos os caracteres não numéricos
    const tam = vr.length;

    let formattedValue = "";

    if (tam <= 2) {
        formattedValue = vr;
    } else if (tam <= 5) {
        formattedValue = `${vr.substr(0, tam - 2)},${vr.substr(tam - 2)}`;
    } else if (tam <= 8) {
        formattedValue = `${vr.substr(0, tam - 5)}.${vr.substr(tam - 5, 3)},${vr.substr(tam - 2)}`;
    } else if (tam <= 11) {
        formattedValue = `${vr.substr(0, tam - 8)}.${vr.substr(tam - 8, 3)}.${vr.substr(tam - 5, 3)},${vr.substr(tam - 2)}`;
    } else if (tam <= 14) {
        formattedValue = `${vr.substr(0, tam - 11)}.${vr.substr(tam - 11, 3)}.${vr.substr(tam - 8, 3)}.${vr.substr(
            tam - 5,
            3
        )},${vr.substr(tam - 2)}`;
    } else if (tam <= 17) {
        formattedValue = `${vr.substr(0, tam - 14)}.${vr.substr(tam - 14, 3)}.${vr.substr(tam - 11, 3)}.${vr.substr(
            tam - 8,
            3
        )}.${vr.substr(tam - 5, 3)},${vr.substr(tam - 2)}`;
    } else {
        return valor; // Retorna o valor original se for muito grande
    }

    return isNegative ? `-${formattedValue}` : formattedValue; // Adiciona o sinal negativo se necessário
};

/**
 *
 * @param {*} valor
 * @returns
 */
export const formatarNumeroDecimal = (valor) => {
    if (typeof valor !== "string") {
        valor = valor.toString();
    }

    // Permite apenas números e mantém o "-" no início
    const onlyNumbers = valor.replace(/[^\d-]/g, "");

    // Remove "-" se não estiver no início
    const sequencia = onlyNumbers.startsWith("-") ? "-" + onlyNumbers.slice(1).replace(/-/g, "") : onlyNumbers.replace(/-/g, "");

    // Se o número for muito curto, apenas retorna o que o usuário digitou
    if (sequencia.length < 3) return sequencia;

    // Obtém parte antes e depois das casas decimais
    let parte1 = sequencia.slice(0, -2); // Todos os caracteres, exceto os dois últimos
    let parte2 = sequencia.slice(-2); // Últimos dois caracteres

    // Se parte1 estiver vazia, retorna apenas os dois últimos caracteres com "0."
    return parte1 ? parte1 + "." + parte2 : "0." + parte2;
};

/**
 * limita o texto recebendo o texto e o valor do limitador
 * @param {*} texto
 * @param {int} tamanho
 * @returns
 */
export const limitarTexto = (texto, tamanho) => {
    if (!tamanho) {
        tamanho = 15;
    }
    if (!texto) {
        return;
    }
    return texto.length > tamanho ? `${texto.substr(0, tamanho)}...` : texto;
};
const objetoDeFuncoes = {
    maskDecimal,
    maskCNPJ,
    maskCEP,
    formatarNumero,
};

export default objetoDeFuncoes;
