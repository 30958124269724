import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import "moment/locale/pt-br";

import { Stack, Button } from "@mui/material";

/* hooks */
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import Input from "../../form/Input";
import Select from "../../form/Select";

import formStyles from "../../form/Form.module.css";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, ufs }) {
    const [dados, setDados] = useState({});
    const [preview, setPreview] = useState([]);

    const navigate = useNavigateParams();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    useEffect(() => {
        setDados(formData || {});
    }, [formData]);

    function onFileChange(e) {
        setPreview(Array.from(e.target.files));
        setDados({ ...dados, bandeira: [...e.target.files] });
    }

    function handleChange(e) {
        setDados({ ...dados, [e.target.name]: e.target.value });
    }
    function handleOnBlur(e) {}
    function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();

        handleSubmit(dados);
    };

    return (
        <form onSubmit={submit}>
            <h1>Munícipios</h1>
            <div className={formStyles.preview_bandeira}>
                <div>
                    <Input
                        text="Bandeira do Munícipio"
                        type="file"
                        name="bandeira"
                        handleOnChange={onFileChange}
                        multiple={false}
                    />
                </div>
                {preview.length > 0
                    ? preview.map((image, index) => (
                          <img
                              src={URL.createObjectURL(image)}
                              alt={dados.bandeira}
                              key={`${dados.bandeira}+${index}`}
                              style={{
                                  borderColor: "black",
                                  border: "solid 0.05em",
                              }}
                              width={150}
                              height={100}
                          />
                      ))
                    : dados.bandeira && (
                          <img
                              src={process.env.REACT_APP_API + "imagens/" + dados.bandeira}
                              alt={dados.descricao}
                              style={{
                                  borderColor: "black",
                                  border: "solid 0.05em",
                              }}
                              width={150}
                              height={100}
                          />
                      )}
            </div>
            <div>
                <Input
                    text="Código"
                    type="text"
                    name="id"
                    placeholder="Código do Estado"
                    title="Digite o código do Estado confome IBGE"
                    minlength={6}
                    maxlength={18}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.id || ""}
                    required
                />
                <Input
                    text="Municipio"
                    type="text"
                    name="descricao"
                    placeholder="Munícipio"
                    title="Digite o nome do Munícipio"
                    inputWidth="350px"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.descricao || ""}
                    required
                />
                <Select
                    text="Selecione o Estado"
                    name="ufId"
                    options={ufs}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.ufId || ""}
                    isEnum={false}
                />
            </div>

            <div>
                <Stack direction="row" spacing={1}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                            navigate(caminhoVoltar, {
                                ul: urlParams.get("ul"),
                                li: urlParams.get("li"),
                            })
                        }
                    >
                        Voltar
                    </Button>
                    <Button type="submit" variant="contained" color="success">
                        {btnText}
                    </Button>
                </Stack>
            </div>
        </form>
    );
}

export default Form;
