import React, { useEffect, useMemo, useState, useContext } from "react";
import { Context } from "../../../context/UserContext";

import { useDispatch } from "react-redux";

import { selectComponent, clearSelectedComponent } from "../../../store/reducers/actions";

import { useNavigateParams } from "../../../hooks/useNavigateParams";
import MaterialReactTable from "material-react-table";
import { ThemeProvider } from "@mui/material";
/* tema customizado*/
import tableThemeCustom from "../../form/tableThemeCustom";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";

//Import Material React Table Translations
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";
import CustomDialog from "../../layout/CustomDialog";

import { Box, Button, IconButton, Tooltip, Typography, Checkbox } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";

import tratar from "../../../utils/retornaTratamentoURL";
import * as PessoaAPI from "../../../api/pessoa";

/* util */
import { obterPermissoesUsuario } from "../../../utils/funcoes";

const Index = () => {
    const [pessoas, setPessoas] = useState([]);
    const [token] = useState(localStorage.getItem("token")) || "";
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();

    const urlParams = new URLSearchParams(window.location.search);
    const ul = urlParams.get("ul") || 2;
    const li = urlParams.get("li") || 3;

    const { parametro } = useContext(Context);
    /* Obtem a paginação padrão */
    useEffect(() => {
        if (parametro && parametro.paginacao) {
            setPagination({
                pageIndex: 0,
                pageSize: parametro.paginacao,
            });
        }
    }, [parametro]);

    //dialog customizado
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});

    //funções da impressão e envio para o backend
    const dispatch = useDispatch();

    //data and fetching state
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    //tratar filtros se vier pela navbar
    if (localStorage.path.replace(/["]/g, "") !== window.location.pathname) {
        tratar.LimparFiltros();

        localStorage.setItem("path", JSON.stringify(window.location.pathname));
    }

    //table state
    const [columnFilterFns, setColumnFiltersMode] = useState(
        localStorage.columnFilterFns.length > 2 ? JSON.parse(localStorage.columnFilterFns) : [{ ativo: "equals" }]
    );

    const [columnFilters, setColumnFilters] = useState(
        localStorage.columnFilters.length > 2
            ? JSON.parse(localStorage.columnFilters)
            : [
                  {
                      id: "ativo",
                      value: 1,
                  },
              ]
    );

    const [globalFilter, setGlobalFilter] = useState(
        localStorage.globalFilter.length > 2 ? JSON.parse(localStorage.globalFilter) : ""
    );

    const [sorting, setSorting] = useState(localStorage.sorting.length > 2 ? JSON.parse(localStorage.sorting) : []);

    const [pagination, setPagination] = useState({
        pageIndex: JSON.parse(localStorage.pagination).pageIndex || 0,
        pageSize: JSON.parse(localStorage.pagination).pageSize || 10,
    });

    //enums
    //const [EnumTipoNome, setEnumTipoNome] = useState([]);

    //modo edit
    //const [createModalOpen, setCreateModalOpen] = useState(false);
    //const [tableData, setTableData] = useState(() => pessoas);
    //const [validationErrors, setValidationErrors] = useState({});

    const fetchData = async () => {
        if (!pessoas.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }

        try {
            PessoaAPI.listar(
                pagination.pageIndex * pagination.pageSize,
                pagination.pageSize,
                JSON.stringify(columnFilters ?? []),
                JSON.stringify(columnFilterFns ?? []),
                globalFilter ?? "",
                JSON.stringify(sorting ?? [])
            ).then((response) => {
                setPessoas(response.data.data);
                setRowCount(response.data.meta.totalRowCount);
            });
        } catch (error) {
            setIsError(true);
            console.error(error);
            return;
        }
        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
    };

    //funções do dialog
    const handleOpenDialog = (row) => {
        setOpenDialog(true);
        setSelectedRow(row);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmDialog = async (row) => {
        // Verifique se há uma linha selecionada
        let msgType = "success";
        if (selectedRow) {
            try {
                const response = await PessoaAPI.deletar(selectedRow);

                setFlashMessage(response.data.message, msgType);

                // Após a exclusão, chame a função fetchData() novamente para atualizar as unidades
                fetchData();
            } catch (error) {
                msgType = "error";
                console.error(error);
                setFlashMessage(error.response.data.message, msgType);
            }
        }
        setOpenDialog(false);
    };

    useEffect(() => {
        if (!token) {
            return navigate("/Auth", { replace: true });
        }

        //funcao para setar localStorage
        localStorage.setItem(
            "pagination",
            JSON.stringify({
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
            })
        );
        localStorage.setItem("columnFilterFns", JSON.stringify(columnFilterFns));
        localStorage.setItem("columnFilters", JSON.stringify(columnFilters));
        localStorage.setItem("globalFilter", JSON.stringify(globalFilter));
        localStorage.setItem("sorting", JSON.stringify(sorting));

        //permissões do usuario
        obterPermissoesUsuario(localStorage.getItem("pessoaId"), localStorage.getItem("empresaId"), ul, li);

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, columnFilterFns, columnFilters, globalFilter, pagination.pageIndex, pagination.pageSize, sorting, ul, li]);

    const columns = useMemo(
        () => [
            //column definitions...
            {
                id: "Pessoa",
                header: "PESSOAS",
                columns: [
                    {
                        accessorKey: "CPForCNPJ",
                        header: "CPF ou CNPJ",
                        //you can access a cell in many callback column definition options like this
                        muiTableBodyCellProps: ({ cell }) => ({
                            onClick: () => {},
                        }),
                    },
                    {
                        accessorKey: "nomePrincipal", //id is still required when using accessorFn instead of accessorKey
                        header: "Parceiro",
                        size: 250,
                        Cell: ({ renderedCellValue, row }) => (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "1rem",
                                }}
                            >
                                {row.original.images !== null && (
                                    <img
                                        alt=""
                                        height={30}
                                        src={process.env.REACT_APP_API + "imagens/" + row.original.images}
                                        loading="lazy"
                                        style={{ borderRadius: "50%" }}
                                    />
                                )}
                                {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                                <span>{row.original.nomePrincipal}</span>
                            </Box>
                        ),
                    },
                    {
                        accessorKey: "ativo",
                        header: "Ativo",
                        columnFilterModeOptions: ["equals"],
                        filterSelectOptions: [
                            { text: "Todos", value: "" },
                            { text: "Marcado", value: 1 },
                            { text: "Desmarcado", value: 0 },
                        ],
                        filterVariant: "select",
                        muiTableBodyCellProps: ({ row }) => ({
                            align: "center",
                            onClick: () => {},
                        }),
                        Cell: ({ renderedCellValue, row }) => {
                            return (
                                <Checkbox
                                    checked={row.original.ativo}
                                    sx={{
                                        "& .MuiSvgIcon-root": { fontSize: 28 },
                                    }}
                                    inputProps={{ "aria-label": "controlled" }}
                                />
                            );
                        },
                    },
                    {
                        accessorKey: "Empresa.nomeFantasia",
                        header: "Empresa",
                    },
                ],
            },
        ],
        [] //getCommonEditTextFieldProps
    );

    /* modo tema */
    const theme = tableThemeCustom();

    return (
        <div>
            {" "}
            <div>
                {openDialog && (
                    <CustomDialog
                        title="Delete PESSOA!"
                        content="Você tem certeza?"
                        onClose={handleCloseDialog}
                        onConfirm={handleConfirmDialog}
                    />
                )}
            </div>
            <ThemeProvider theme={theme}>
                <MaterialReactTable
                    displayColumnDefOptions={{
                        "mrt-row-actions": {
                            muiTableHeadCellProps: {
                                align: "center",
                            },
                            size: 80,
                        },
                        "mrt-row-numbers": {
                            enableColumnOrdering: true, //turn on some features that are usually off
                            enableResizing: true,
                            muiTableHeadCellProps: {
                                sx: {
                                    fontSize: "1.2rem",
                                },
                            },
                        },
                        "mrt-row-select": {
                            enableColumnActions: true,
                            enableHiding: true,
                            size: 50,
                        },
                    }}
                    enableColumnFilterModes
                    enableGlobalFilterModes
                    //enableColumnResizing
                    enableColumnOrdering
                    //editingMode="modal" //default ver metodos'modal' | 'cell' | 'row' | 'table' ===> https://www.material-react-table.com/docs/guides/editing
                    enableEditing
                    //onEditingRowSave={handleSaveRowEdits}
                    //onEditingRowCancel={handleCancelRowEdits}
                    renderRowActions={({ row, table }) => (
                        <Box sx={{ display: "flex", gap: "1rem" }}>
                            <Tooltip arrow placement="left" title="Editar">
                                <span>
                                    {" "}
                                    <IconButton
                                        onClick={() =>
                                            navigate(`/Pessoa/CreateOrEdit`, {
                                                ul: urlParams.get("ul"),
                                                li: urlParams.get("li"),
                                                uuid: row.id,
                                            })
                                        }
                                        disabled={localStorage.getItem("editar") === "true" ? false : true}
                                    >
                                        <Edit />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip arrow placement="right" title="Deletar">
                                <span>
                                    <IconButton
                                        color="error"
                                        onClick={() => handleOpenDialog(row)}
                                        disabled={localStorage.getItem("cancelar") === "true" ? false : true}
                                    >
                                        <Delete />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Box>
                    )}
                    renderTopToolbarCustomActions={({ table }) => {
                        const handleImpimirParceiro = async () => {
                            dispatch(clearSelectedComponent()); // Limpa os valores selecionados
                            const uuids = table.getSelectedRowModel().flatRows.map((row) => row.id);
                            dispatch(selectComponent(uuids)); // Preenche com os novos valores

                            // Filtrar os lançamentos com base nos UUIDs ou manter a lista original
                            const pessoasFiltrados =
                                uuids.length > 0 ? pessoas.filter((pessoa) => uuids.includes(pessoa.uuid)) : pessoas;

                            const urlSearchParams = new URLSearchParams({
                                start: `${pagination.pageIndex * pagination.pageSize}`,
                                size: `${pagination.pageSize}`,
                                filters: JSON.stringify(columnFilters ?? []),
                                filtersMode: JSON.stringify(columnFilterFns ?? []),
                                globalFilter: globalFilter ?? "",
                                sorting: JSON.stringify(sorting ?? []),
                                tipo: urlParams.get("tipo") ?? "",
                            });

                            const response = await PessoaAPI.relatorio(urlSearchParams, pessoasFiltrados);

                            // Cria um Blob a partir dos dados do arraybuffer
                            const pdfBlob = new Blob([response.data], {
                                type: "application/pdf",
                            });

                            // Cria um URL temporário para o Blob
                            const pdfUrl = URL.createObjectURL(pdfBlob);

                            // Use o URL do PDF para abrir em uma nova janela ou realizar outras ações
                            window.open(pdfUrl);
                        };

                        return (
                            <div
                                style={{
                                    display: "flex",
                                    gap: "8px",
                                }}
                            >
                                <Tooltip arrow placement="top-end" title={`Novo Registro`}>
                                    <div>
                                        <Button
                                            color="secondary"
                                            onClick={() => navigate("/Pessoa/CreateOrEdit")}
                                            variant="contained"
                                            disabled={localStorage.getItem("incluir") === "true" ? false : true}
                                        >
                                            Novo
                                        </Button>
                                    </div>
                                </Tooltip>
                                <Tooltip arrow placement="top-end" title={`Imprimir Parceiro`}>
                                    <div>
                                        <span>
                                            <Button
                                                color="info"
                                                variant="contained"
                                                disabled={JSON.parse(localStorage.getItem("imprimir")) === true ? false : true}
                                                onClick={handleImpimirParceiro}
                                            >
                                                Imprimir
                                            </Button>
                                        </span>
                                    </div>
                                </Tooltip>
                            </div>
                        );
                    }}
                    renderDetailPanel={({ row }) => (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-around",
                                alignItems: "center",
                            }}
                        >
                            {row.original.images !== null && (
                                <img
                                    alt="images"
                                    height={200}
                                    src={process.env.REACT_APP_API + "imagens/" + row.original.images}
                                    loading="lazy"
                                    style={{ borderRadius: "50%" }}
                                />
                            )}
                            <Box sx={{ textAlign: "left" }}>
                                <Typography variant="h4">Parceiro:</Typography>
                                <Typography variant="h4">{row.original.nomePrincipal}</Typography>
                            </Box>
                        </Box>
                    )}
                    columns={columns}
                    data={pessoas}
                    enableRowSelection
                    getRowId={(row) => row.uuid}
                    manualFiltering
                    manualPagination
                    manualSorting
                    muiToolbarAlertBannerProps={
                        isError
                            ? {
                                  color: "error",
                                  children: "Error loading data",
                              }
                            : undefined
                    }
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setColumnFiltersMode}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    rowCount={rowCount}
                    initialState={{
                        showColumnFilters: true,
                        density: "compact",
                        //columnPinning: { left: ["mrt-row-select"] },
                    }}
                    state={{
                        columnFilterFns,
                        columnFilters,
                        globalFilter,
                        isLoading,
                        pagination,
                        showAlertBanner: isError,
                        showProgressBars: isRefetching,
                        sorting,
                    }}
                    //positionToolbarDropZone={"top"}
                    positionExpandColumn={"first"}
                    positionActionsColumn={"first"}
                    positionToolbarAlertBanner="bottom"
                    localization={MRT_Localization_PT_BR}
                />
            </ThemeProvider>
        </div>
    );
};

export default Index;
