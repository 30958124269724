import { useState, useEffect, useContext } from "react";
import "react-tabs/style/react-tabs.css";

import moment from "moment";
import "moment/locale/pt-br";

import { Stack, Button, Tooltip } from "@mui/material";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import { Context } from "../../../context/UserContext";

import Input from "../../form/Input";
import TextArea from "../../form/TextArea";
import InputNumber from "../../form/InputNumber";
import Select from "../../form/Select";
import DataList from "../../form/DataList";
import Checkbox from "../../form/Checkbox";
import SelectEmpresa from "../../form/SelectEmpresa";

/* funções */
import { retornaData, retornaDiasAtrasoVencimento } from "../../../utils/retornaDataFormatada";

/* utils */
import { formatarNumero, formatarNumeroDecimal } from "../../../utils/masks";
import { calculaDataVencimento } from "../../../utils/financeiro";

import * as DataListAPI from "../../../api/dataList";
import * as FinanceiroAPI from "../../../api/financeiro";
import * as PessoaAPI from "../../../api/pessoa";
import * as ProdutoAPI from "../../../api/produto";
import * as TabelaAPI from "../../../api/tabela";

function Form({
    token,
    handleSubmit,
    formData,
    btnText,
    caminhoVoltar,
    OptionsEmpresa,
    OptionsCentroCustos,
    OptionsForma,
    OptionsConta,
}) {
    const [lancamento, setLancamento] = useState(formData || {});
    const [pessoa, setPessoa] = useState(formData?.Pessoa || {});
    const [centroCusto, setCentroCusto] = useState(formData?.centroCusto || {});
    const [OptionsPrazos, setPrazo] = useState([]);

    const [contasDisponiveis, setContasDisponiveis] = useState(OptionsConta);

    const [divBaixaLancamento, setDivBaixaLancamento] = useState(false);
    const [divMapaAbastecimento, setDivMapaAbastecimento] = useState(false);
    const [divCalculaJuros, setDivBCalculaJuros] = useState(true);
    const [readOnlyCalculaJuros, setReadOnlyCalculaJuros] = useState(false);

    const [lancamentoBaixa, setlancamentoBaixa] = useState({});
    const [mapaAbastecimento, setMapaAbastecimento] = useState({});

    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();
    const urlParams = new URLSearchParams(window.location.search);

    const tipo = urlParams.get("tipo");

    //set datalist
    const [OptionsParceiro, setOptionsParceiro] = useState([]);
    const [OptionsCC, setOptionsCC] = useState(OptionsCentroCustos || []);

    //mapa abastecimento
    const [OptionsMotorista, setMotorista] = useState([]);
    const [OptionsVTR, setVTR] = useState([]);

    const { parametro } = useContext(Context);

    //para lancamento vazio preenche valores pré definidos
    if (!lancamento.empresaId) {
        lancamento.empresaId = localStorage.getItem("empresaId");
        lancamento.parcela = 1;
        lancamento.dataLancamento = moment().startOf("day").format("YYYY-MM-DD HH:mm:ss");

        lancamento.dataVencimento = moment().startOf("day").format("YYYY-MM-DD HH:mm:ss");

        lancamento.tipo = urlParams.get("tipo");
        lancamento.calculaJuros = false;
    }

    //Atualiza o Datalist
    const consultaDatalist = async (token, empresaId, tabela, id, descricao, condicao, filtro) => {
        let result = await DataListAPI.dataList(empresaId, tabela, id, descricao, condicao, filtro);
        return result;
    };

    //consulta o prazo de pagamentos
    const consultaPrazo = async (
        tipo = urlParams.get("tipo"),
        formaId,
        nivel,
        empresaId = lancamento.empresaId,
        prazoMedio = null
    ) => {
        let dados = await FinanceiroAPI.prazo(tipo, formaId, nivel, "", empresaId, prazoMedio);
        setPrazo(dados);

        return dados;
    };

    //recebe forma e centro de custo para moldar a tela
    const retornaForma = async (empresaId, formaId) => {
        const forma = await TabelaAPI.tabelaById(empresaId, "pagamentoForma", formaId);

        if (forma?.baixaLancamento === true) {
            setDivBaixaLancamento(true);
            setlancamentoBaixa({
                baixaLancamento: true,
                baixa: lancamentoBaixa.baixa ? lancamentoBaixa.baixa : lancamento.valorOriginal,
                dataBaixa: lancamentoBaixa.dataBaixa ? lancamentoBaixa.dataBaixa : lancamento.dataLancamento,
                btnText: btnText,
            });
        } else {
            setDivBaixaLancamento(false);
            setlancamentoBaixa({
                baixaLancamento: false,
                baixa: null,
                dataBaixa: null,
                btnText: btnText,
            });
        }
    };

    const retornaCC = async (empresaId, centroCustoId) => {
        const cc = await TabelaAPI.tabelaById(empresaId, "centroCusto", centroCustoId);

        if (cc?.combustivel === true) {
            await consultaVTR(lancamento.empresaId, true, null, "Móvel");
            btnText === "Cadastrar" && setDivMapaAbastecimento(true);
        } else {
            setDivMapaAbastecimento(false);
            setMapaAbastecimento({
                mapa: false,
                data: lancamento.dataLancamento,
                empresaId: lancamento.empresaId,
                produtoId: null,
                pessoaId: null,
                parceiroId: lancamento.pessoaId,
                kmAnterior: null,
                km: null,
                preco: null,
                litros: null,
                valor: null,
            });
        }

        return cc;
    };

    useEffect(() => {
        setLancamento(formData || {});
        setPessoa(formData?.Pessoa || {});
        setCentroCusto(formData?.centroCusto || {});
        setMapaAbastecimento(formData?.mapaAbastecimento || {});
        consultaPrazo();

        if (parametro.calculaJuros === true) {
            setDivBCalculaJuros(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    //função para calcular juros e multa
    const atualizaLancamento = () => {
        let valorAtual = lancamento.valorAberto;

        if (lancamento.calculaJuros === true) {
            // Atualiza o estado de leitura com base no cálculo de juros
            setReadOnlyCalculaJuros(!lancamento.calculaJuros);

            // Calcula dias em atraso
            lancamento.diasAtraso = Number(retornaDiasAtrasoVencimento(null, lancamento.dataVencimento));

            // Aplica cálculo de multa e juros
            if (lancamento.diasAtraso <= 0) {
                lancamento.juros = 0;
                lancamento.multa = 0;
            } else if (lancamento.diasAtraso > 0) {
                const multa = (lancamento.valorAberto * lancamento.multaPercentual) / 100;
                const juros = lancamento.valorAberto * (lancamento.jurosPercentual / 100 / 30) * lancamento.diasAtraso;

                lancamento.multa = parseFloat(multa).toFixed(2);
                lancamento.juros = parseFloat(juros).toFixed(2);

                const valAberto = Number(lancamento.valorAberto || 0);
                const valMulta = Number(lancamento.multa || 0);
                const valJuros = Number(lancamento.juros || 0);

                valorAtual = parseFloat(valAberto - valMulta - valJuros).toFixed(2);
            } else {
                valorAtual = lancamento.valorAberto;
            }

            setLancamento({
                ...lancamento,
                valorAberto: valorAtual,
            });
        } else {
            const valAberto = Number(lancamento.valorAberto || 0);
            const valMulta = Number(lancamento.multa || 0);
            const valJuros = Number(lancamento.juros || 0);

            valorAtual = parseFloat(valAberto - valMulta - valJuros).toFixed(2);

            setLancamento({
                ...lancamento,
                multa: 0,
                juros: 0,
                valorAberto: valorAtual,
            });
        }
    };

    useEffect(() => {
        atualizaLancamento();
        if (lancamento.calculaJuros === true) {
            setReadOnlyCalculaJuros(false);
        } else {
            setReadOnlyCalculaJuros(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lancamento.calculaJuros]);

    useEffect(() => {
        if (lancamento.formaId && btnText !== "Baixar") {
            retornaForma(lancamento.empresaId, lancamento.formaId);
        }
        if (lancamento.centroCustoId && btnText !== "Baixar") {
            retornaCC(lancamento.empresaId, lancamento.centroCustoId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lancamento.formaId, lancamento.centroCustoId]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const vtr = await TabelaAPI.tabelaById(lancamento.empresaId, "Produto", mapaAbastecimento.produtoId);
                // Faça algo com 'vtr' se necessário
                const km = await ProdutoAPI.ultimoKm(vtr.id);

                // Adicione o valor de km ao objeto vtr
                vtr.kmAnterior = km;

                setMapaAbastecimento({
                    ...mapaAbastecimento,
                    mapa: true,
                    data: lancamento.dataLancamento,
                    parceiroId: lancamento.pessoaId,
                    valor: lancamento.valorOriginal,
                    kmAnterior: vtr.kmAnterior,
                });

                setLancamento({
                    ...lancamento,
                    mapaAbastecimento: {
                        ...mapaAbastecimento,
                        data: lancamento.dataLancamento,
                        parceiroId: lancamento.pessoaId,
                        valor: lancamento.valorOriginal,
                        kmAnterior: vtr.kmAnterior,
                    },
                });
            } catch (error) {
                console.error("Erro ao buscar dados:", error);
            }
        };

        if (mapaAbastecimento.produtoId) {
            fetchData(); // Chama a função assíncrona
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapaAbastecimento.produtoId]);

    useEffect(() => {
        const fetchData = async () => {
            if (lancamento.contaId) {
                const novasOpcoesContaDestino = OptionsConta.filter((opcao) => {
                    return opcao.id !== parseInt(lancamento.contaId);
                });
                setContasDisponiveis(novasOpcoesContaDestino);
            } else {
                setContasDisponiveis(OptionsConta);
            }

            const resultPessoa = await consultaParceiro(parametro.pessoaId);
            setPessoa(resultPessoa);
            setOptionsParceiro(resultPessoa);

            const resultCC = await retornaCC(lancamento.empresaId, parametro.centroCustoTransferencia);
            setCentroCusto({
                ...centroCusto,
                descricao: resultCC.descricao,
            });

            setLancamento({
                ...lancamento,
                pessoaId: parametro.pessoaId,
                centroCustoId: parametro.centroCustoTransferencia,
                formaId: parametro.transferenciaForma,
                prazoId: parametro.transferenciaPrazo,
                historico: "TRF Contas",
            });
        };

        if (lancamento.transferencia === true) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lancamento.transferencia, lancamento.contaId]);

    //Consultas no backend
    const consultaParceiro = async (pessoaId) => {
        let result = await PessoaAPI.financeiro("", pessoaId);
        return result;
    };

    const consultaColaborador = async (empresaId, filtro, condicao) => {
        const result = await PessoaAPI.colaborador(empresaId, filtro, condicao);
        setMotorista(result);
        setMapaAbastecimento((prevMapaAbastecimento) => ({
            ...prevMapaAbastecimento,
            mapa: true,
            data: lancamento.dataLancamento,
            parceiroId: lancamento.pessoaId,
            valor: lancamento.valorOriginal,
            empresaId: lancamento.empresaId,
            pessoaId: result[0]?.id,
        }));

        setLancamento({
            ...lancamento,
            mapaAbastecimento: {
                ...mapaAbastecimento,
                mapa: true,
                data: lancamento.dataLancamento,
                parceiroId: lancamento.pessoaId,
                valor: lancamento.valorOriginal,
                empresaId: lancamento.empresaId,
                pessoaId: result[0]?.id,
            },
        });

        return result;
    };

    const consultaVTR = async (empresaId, ativo, filtro, condicao) => {
        const motorista = await consultaColaborador(empresaId, null, "motorista");

        const result = await ProdutoAPI.bem(empresaId, ativo, filtro, condicao);

        // Obter a última km da vtr
        const kmAnterior = await ProdutoAPI.ultimoKm(result[0].id);

        // Atualizar o estado de VTR e usar um callback opcional para continuar
        setVTR(result);

        setMapaAbastecimento((prevMapaAbastecimento) => ({
            ...prevMapaAbastecimento,
            mapa: true,
            data: lancamento.dataLancamento,
            parceiroId: lancamento.pessoaId,
            valor: lancamento.valorOriginal,
            empresaId: lancamento.empresaId,
            produtoId: result[0].id,
            pessoaId: motorista[0]?.id,
            kmAnterior: kmAnterior,
        }));

        setLancamento({
            ...lancamento,
            mapaAbastecimento: {
                ...mapaAbastecimento,
                mapa: true,
                data: lancamento.dataLancamento,
                parceiroId: lancamento.pessoaId,
                valor: lancamento.valorOriginal,
                empresaId: lancamento.empresaId,
                produtoId: result[0].id,
                kmAnterior: kmAnterior,
            },
        });
    };

    async function handleChange(e) {
        const camposBooleanos = [
            "ativo",
            "provisao",
            "fixaDiaVencimento",
            "transferencia",
            "tabelasComuns",
            "msgCoresDefault",
            "calculaJuros",
        ];
        const camposMonetarios = [
            "valorOriginal",
            "valor",
            "descontoPercentual",
            "descontoValor",
            "acrescimoPercentual",
            "acrescimoValor",
            "baixa",
            "valorAberto",
            "multa",
            "multaPercentual",
            "juros",
            "jurosPercentual",
        ];
        if (camposMonetarios.includes(e.target.name)) {
            //calcular o valor da parcela ao digitar o valorOriginal
            if (e.target.name === "valorOriginal") {
                const valorFormatado = formatarNumeroDecimal(e.target.value);
                const valorParcela = parseFloat(valorFormatado / lancamento.parcela).toFixed(2);

                //calcula o valor em aberto
                const descontoReal = Number(lancamento.descontoValor || 0);
                const acrescimoReal = Number(lancamento.acrescimoValor || 0);
                const valorAberto = Number(valorFormatado + acrescimoReal - descontoReal).toFixed(2);

                setlancamentoBaixa({
                    ...lancamentoBaixa,
                    baixa: valorFormatado,
                    dataBaixa: lancamento.dataVencimento,
                });

                setLancamento({
                    ...lancamento,
                    [e.target.name]: valorFormatado,
                    valorAberto: valorAberto,
                    valor: valorParcela,
                    lancamentoBaixa: lancamentoBaixa,
                });
            } else if (e.target.name === "descontoPercentual") {
                const valorAtual = parseFloat(lancamento.valor);
                const acrescimoValor = parseFloat(lancamento.acrescimoValor) || parseFloat(0);
                let descontoReal = parseFloat(0);
                let valorBaixa = parseFloat(0);

                const valorFormatado = formatarNumeroDecimal(e.target.value);

                if (valorFormatado > 0) {
                    descontoReal = parseFloat((valorAtual + acrescimoValor) * (valorFormatado / 100)).toFixed(2);
                }
                valorBaixa = (valorAtual - descontoReal + acrescimoValor).toFixed(2);

                setlancamentoBaixa((prevState) => ({
                    ...prevState,
                    baixa: valorBaixa,
                }));

                setLancamento({
                    ...lancamento,
                    [e.target.name]: formatarNumeroDecimal(e.target.value),
                    descontoValor: descontoReal,
                    baixa: valorBaixa,
                    valorAberto: valorBaixa,
                    lancamentoBaixa: lancamentoBaixa ? lancamentoBaixa : null,
                });
            } else if (e.target.name === "descontoValor") {
                const valorAtual = parseFloat(lancamento.valor);
                const acrescimoValor = parseFloat(lancamento.acrescimoValor) || parseFloat(0);
                let descontoPercentual = parseFloat(0);
                let valorBaixa = parseFloat(0);

                const valorFormatado = formatarNumeroDecimal(e.target.value);
                if (valorFormatado > 0) {
                    descontoPercentual = parseFloat((valorFormatado / (valorAtual + acrescimoValor)) * 100).toFixed(2);
                }
                valorBaixa = (lancamento.valor - valorFormatado + acrescimoValor).toFixed(2);

                setlancamentoBaixa((prevState) => ({
                    ...prevState,
                    baixa: valorBaixa,
                }));

                setLancamento({
                    ...lancamento,
                    [e.target.name]: formatarNumeroDecimal(e.target.value),
                    descontoPercentual: descontoPercentual,
                    baixa: valorBaixa,
                    valorAberto: valorBaixa,
                    lancamentoBaixa: lancamentoBaixa ? lancamentoBaixa : null,
                });
            } else if (e.target.name === "acrescimoPercentual") {
                const valorAtual = parseFloat(lancamento.valor);
                const descontoValor = parseFloat(lancamento.descontoValor) || parseFloat(0);

                let acrescimoReal = parseFloat(0);
                let valorBaixa = parseFloat(0);

                const valorFormatado = formatarNumeroDecimal(e.target.value);
                if (!isNaN(valorAtual) && !isNaN(descontoValor) && !isNaN(valorFormatado)) {
                    if (valorFormatado > 0) {
                        acrescimoReal = parseFloat((valorAtual - descontoValor) * (valorFormatado / 100)).toFixed(2);
                    }
                    valorBaixa = (parseFloat(valorAtual) + parseFloat(acrescimoReal) - parseFloat(descontoValor)).toFixed(2);
                }

                setlancamentoBaixa((prevState) => ({
                    ...prevState,
                    baixa: valorBaixa,
                }));

                setLancamento({
                    ...lancamento,
                    [e.target.name]: formatarNumeroDecimal(e.target.value),
                    acrescimoValor: acrescimoReal,
                    baixa: valorBaixa,
                    valorAberto: valorBaixa,
                    lancamentoBaixa: lancamentoBaixa ? lancamentoBaixa : null,
                });
            } else if (e.target.name === "acrescimoValor") {
                const valorAtual = parseFloat(lancamento.valor);
                const descontoValor = parseFloat(lancamento.descontoValor) || parseFloat(0);

                let acrescimoPercentual = parseFloat(0);
                let valorBaixa = parseFloat(0);
                const valorFormatado = formatarNumeroDecimal(e.target.value);

                if (!isNaN(valorAtual) && !isNaN(acrescimoPercentual) && !isNaN(valorFormatado)) {
                    if (valorFormatado > 0) {
                        acrescimoPercentual = parseFloat((valorFormatado / (valorAtual - descontoValor)) * 100).toFixed(2);
                    }
                    valorBaixa = (parseFloat(valorAtual) + parseFloat(valorFormatado) - parseFloat(descontoValor)).toFixed(2);
                }

                setlancamentoBaixa((prevState) => ({
                    ...prevState,
                    baixa: valorBaixa,
                }));
                setLancamento({
                    ...lancamento,
                    [e.target.name]: formatarNumeroDecimal(e.target.value),
                    acrescimoPercentual: acrescimoPercentual,
                    baixa: valorBaixa,
                    valorAberto: valorBaixa,
                    lancamentoBaixa: lancamentoBaixa ? lancamentoBaixa : null,
                });
            } else if (e.target.name === "valor") {
                const valorFormatado = formatarNumeroDecimal(e.target.value);
                const valorOriginal = parseFloat(valorFormatado * lancamento.parcela).toFixed(2);

                setLancamento({
                    ...lancamento,
                    [e.target.name]: formatarNumeroDecimal(e.target.value),
                    valorOriginal: valorOriginal,
                });
            } else {
                //quando digitar o valor da baixa
                setLancamento({
                    ...lancamento,
                    [e.target.name]: formatarNumeroDecimal(e.target.value),
                });
            }
        } else if (camposBooleanos.includes(e.target.name)) {
            setLancamento({ ...lancamento, [e.target.name]: e.target.checked });
        } else if (e.target.name === "parcela") {
            const valorParcela = parseFloat(lancamento.valorOriginal / e.target.value).toFixed(2);

            setLancamento({
                ...lancamento,
                [e.target.name]: e.target.value,
                valor: valorParcela,
                valorAberto: valorParcela,
            });
        } else if (e.target.name.startsWith("centroCusto.")) {
            setCentroCusto({
                ...centroCusto,
                [e.target.name.split(".")[1]]: e.target.value,
            });
            let result = await consultaDatalist(
                token,
                lancamento.empresaId,
                "centroCusto",
                "id",
                "descricao",
                urlParams.get("tipo"),
                e.target.value
            );

            setOptionsCC(result);

            setLancamento((prevState) => ({
                ...prevState,
                centroCustoId: document.querySelector(`datalist option[value="${e.target.value}"]`)?.dataset.id,
            }));
        } else if (e.target.name === "dataLancamento") {
            setLancamento({ ...lancamento, [e.target.name]: e.target.value });
            const condicoes = await calculaDataVencimento(token, e.target.value, lancamento.formaId, lancamento.prazoId);

            setLancamento((prevState) => ({
                ...prevState,
                parcela: condicoes.parcela,
                dataVencimento: condicoes.vencimento ?? retornaData(moment()),
            }));
        } else if (e.target.name.startsWith("Pessoa.")) {
            setPessoa({
                ...pessoa,
                [e.target.name.split(".")[1]]: e.target.value,
            });

            //consulta datalist
            let result = await consultaDatalist(
                token,
                lancamento.empresaId,
                "Pessoa",
                "id",
                "descricao",
                urlParams.get("tipo"),
                e.target.value
            );
            setOptionsParceiro(result);

            const selectedId = document.querySelector(`datalist option[value="${e.target.value}"]`)?.dataset.id;

            if (selectedId) {
                let resultado = await consultaParceiro(selectedId);
                //setar o prazo para o lancamento
                await consultaPrazo(
                    tipo,
                    tipo === "Pagar" ? resultado.formaFornecedorId : resultado.formaClienteId,
                    "",
                    lancamento.empresaId,
                    tipo === "Pagar"
                        ? resultado.prazoFornecedor
                            ? resultado.prazoFornecedor.prazoMedio
                            : null
                        : resultado.prazoCliente
                        ? resultado.prazoCliente.prazoMedio
                        : null
                );

                setLancamento((prevState) => ({
                    ...prevState,
                    pessoaId: selectedId,
                    centroCustoId: tipo === "Pagar" ? resultado.centroCustoFornecedorId : resultado.centroCustoClienteId,
                    contaId: tipo === "Pagar" ? resultado.contaFornecedorId : resultado.contaClienteId,
                    formaId: tipo === "Pagar" ? resultado.formaFornecedorId : resultado.formaClienteId,
                    prazoId: tipo === "Pagar" ? resultado.prazoFornecedorId : resultado.prazoClienteId,
                    dataLancamento: lancamento.dataLancamento || retornaData(),
                    parcela:
                        tipo === "Pagar"
                            ? resultado.prazoFornecedor
                                ? resultado.prazoFornecedor.parcela
                                : 1
                            : resultado.prazoCliente
                            ? resultado.prazoCliente.parcela
                            : 1,
                }));

                //pesquisa no backend
                const centroCustoSelecionado = await TabelaAPI.tabelaById(
                    lancamento.empresaId,
                    "centroCusto",
                    tipo === "Pagar" ? resultado.centroCustoFornecedorId : resultado.centroCustoClienteId
                );

                if (centroCustoSelecionado) {
                    setCentroCusto({
                        ...centroCusto,
                        descricao: centroCustoSelecionado.descricao,
                    });
                }

                //obter o padrão de prazo e forma para calcular o vencimento e as parcelas
                const condicoes = await calculaDataVencimento(
                    token,
                    lancamento.dataLancamento,
                    urlParams.get("tipo") === "Pagar" ? resultado.formaFornecedorId : resultado.formaClienteId,
                    ""
                );

                setLancamento((prevState) => ({
                    ...prevState,
                    dataVencimento: condicoes.vencimento ?? retornaData(moment()),
                }));
            } else {
                setLancamento({
                    ...lancamento,
                    pessoaId: "",
                    centroCustoId: "",
                    contaId: "",
                    formaId: "",
                    prazoId: "",
                });
                setCentroCusto({ ...centroCusto, descricao: "" });
            }
        } else if (e.target.name === "formaId") {
            setLancamento({ ...lancamento, [e.target.name]: e.target.value });
            await consultaPrazo(urlParams.get("tipo"), e.target.value, null, lancamento.empresaId, null);

            //calcula data vencimento
            const condicoes = await calculaDataVencimento(token, lancamento.dataLancamento, e.target.value, "");

            setLancamento((prevState) => ({
                ...prevState,
                dataVencimento: condicoes.vencimento ?? retornaData(moment()),
                lancamentoBaixa: {
                    baixaLancamento: condicoes.baixaLancamento,
                    baixa: lancamentoBaixa.baixa ? lancamentoBaixa.baixa : lancamento.valorAberto,
                    dataBaixa: lancamentoBaixa.dataBaixa
                        ? lancamentoBaixa.dataBaixa
                        : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
                },
            }));
        } else if (e.target.name === "prazoId") {
            setLancamento({ ...lancamento, [e.target.name]: e.target.value });
            const condicoes = await calculaDataVencimento(token, lancamento.dataLancamento, lancamento.formaId, e.target.value);

            setLancamento((prevState) => ({
                ...prevState,
                parcela: condicoes.parcela,
                dataVencimento: condicoes.vencimento ?? retornaData(moment()),
            }));
        } else if (e.target.name.startsWith("lancamentoBaixa.")) {
            let value = null;

            // Se o elemento for uma caixa de seleção relacionada a "baixaLancamento", utiliza o valor booleano de checked.
            if (e.target.name === "lancamentoBaixa.baixaLancamento") {
                value = e.target.checked;
            }
            // Se o elemento for relacionado à propriedade "baixa", formata o valor como número decimal.
            else if (e.target.name === "lancamentoBaixa.baixa") {
                value = formatarNumeroDecimal(e.target.value);
            }
            // Caso contrário, utiliza o valor do elemento.
            else {
                value = e.target.value;
            }
            // Atualiza o estado "lancamentoBaixa" com a função de atualização, preservando as propriedades anteriores e atualizando a propriedade correspondente.
            setLancamento((prevState) => ({
                ...prevState,
                lancamentoBaixa: {
                    ...lancamentoBaixa,
                    [e.target.name.split(".")[1]]: value, // Atualiza a propriedade correspondente, excluindo o prefixo "lancamentoBaixa."
                },
            }));

            setlancamentoBaixa((prevState) => ({ ...prevState, [e.target.name.split(".")[1]]: value }));
        } else if (e.target.name.startsWith("mapaAbastecimento.")) {
            const inputName = e.target.name.split(".")[1];
            if (inputName === "mapa") {
                setMapaAbastecimento({
                    ...mapaAbastecimento,
                    [e.target.name.split(".")[1]]: e.target.checked,
                });
            } else if (inputName === "litros" || inputName === "preco") {
                const valorFormatado = formatarNumeroDecimal(e.target.value);
                setMapaAbastecimento({
                    ...mapaAbastecimento,
                    [e.target.name.split(".")[1]]: valorFormatado,
                });
            } else {
                setMapaAbastecimento({
                    ...mapaAbastecimento,
                    [e.target.name.split(".")[1]]: e.target.value,
                });
            }
        } else {
            setLancamento({ ...lancamento, [e.target.name]: e.target.value });
        }
    }
    async function handleOnBlur(e) {
        const camposMonetarios = [
            "valorOriginal",
            "valor",
            "descontoPercentual",
            "descontoValor",
            "acrescimoPercentual",
            "acrescimoValor",
            "baixa",
            "valorAberto",
            "multa",
            "multaPercentual",
            "juros",
            "jurosPercentual",
        ];
        if (camposMonetarios.includes(e.target.name)) {
            if (e.target.name === "valorOriginal") {
                setlancamentoBaixa({
                    ...lancamentoBaixa,
                    baixa: formatarNumeroDecimal(e.target.value),
                    dataBaixa: lancamento.dataVencimento,
                });
                setLancamento({
                    ...lancamento,
                    lancamentoBaixa: lancamentoBaixa,
                });
            }

            btnText === "Atualizar" && atualizaLancamento();
        } else if (e.target.name === "dataLancamento") {
            const condicoes = await calculaDataVencimento(token, e.target.value, lancamento.formaId, lancamento.prazoId);

            setLancamento((prevState) => ({
                ...prevState,
                parcela: condicoes.parcela,
                dataVencimento: condicoes.vencimento ?? retornaData(moment()),
            }));
        } else if (e.target.name.startsWith("Pessoa.")) {
        } else if (e.target.name === "formaId") {
            const condicoes = await calculaDataVencimento(
                token,
                lancamento.dataLancamento,
                e.target.value,
                lancamento.prazoId || null
            );

            setLancamento((prevState) => ({
                ...prevState,
                parcela: condicoes.parcela,
                dataVencimento: condicoes.vencimento ?? retornaData(moment()),
            }));
        } else if (e.target.name === "prazoId") {
            if (e.target.value !== "Selecione uma opção") {
                const condicoes = await calculaDataVencimento(
                    token,
                    lancamento.dataLancamento,
                    lancamento.formaId,
                    e.target.value
                );
                setLancamento((prevState) => ({
                    ...prevState,
                    parcela: condicoes.parcela,
                }));
            }
        } else if (e.target.name === "valorOriginal" && lancamentoBaixa.baixaLancamento === true) {
            setLancamento({ ...lancamento, lancamentoBaixa: { ...lancamentoBaixa, baixa: lancamento.valorOriginal } });
        } else if (e.target.name.startsWith("mapaAbastecimento.")) {
            const inputName = e.target.name.split(".")[1];
            if (inputName === "km") {
                if (parseInt(mapaAbastecimento.kmAnterior) > parseInt(e.target.value)) {
                    setFlashMessage("Km atual é menor que o km anterior?", "error");
                }
            } else if (inputName === "preco") {
                const valorAbastecimento = parseFloat(mapaAbastecimento.litros * mapaAbastecimento.preco).toFixed(2);
                const valorLancamento = parseFloat(lancamento.valorOriginal).toFixed(2);
                if (valorAbastecimento !== valorLancamento) {
                    setFlashMessage(
                        `O valor do lançamento é ${formatarNumero(valorLancamento)} e o valor do abastecimento é ${formatarNumero(
                            valorAbastecimento
                        )}?`,
                        "warning"
                    );
                }
            }

            setLancamento({
                ...lancamento,
                mapaAbastecimento: {
                    ...mapaAbastecimento,
                    valor: lancamento.valorOriginal,
                },
            });
        }
    }
    async function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();

        handleSubmit(lancamento);
    };

    return (
        <form onSubmit={submit}>
            <h1>Lançamentos {urlParams.get("tipo")}</h1>
            <div>
                {btnText === "Cadastrar" && (
                    <Checkbox
                        title="Transferência de valores entre contas"
                        text="Transferência"
                        name="transferencia"
                        type="checkbox"
                        handleOnChange={handleChange}
                        value={lancamento.transferencia}
                        checked={lancamento.transferencia === true ? "checked" : ""}
                    />
                )}
                <SelectEmpresa
                    text="Selecione a Empresa"
                    name="empresaId"
                    options={OptionsEmpresa}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={lancamento.empresaId || localStorage.getItem("empresaId")}
                    required
                    notOptNull={true}
                />
                <Input
                    text="Data"
                    type="date"
                    name="dataLancamento"
                    placeholder="Data"
                    title="Data de lançamento no sistema financeiro"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={(lancamento.dataLancamento && retornaData(lancamento.dataLancamento)) || retornaData(moment())}
                />
                <DataList
                    text="Selecione o Parceiro"
                    name="Pessoa.nomePrincipal"
                    inputWidth="600px"
                    filteredOptions={OptionsParceiro}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={pessoa.nomePrincipal || ""}
                    readonly={btnText === "Baixar"}
                />
            </div>
            <div>
                <DataList
                    text="Centro de Custo"
                    title={"Escolha o centro de custo"}
                    placeholder={"Digite para pesquisar"}
                    name="centroCusto.descricao"
                    inputWidth="310px"
                    filteredOptions={OptionsCC}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={centroCusto?.descricao || ""}
                />

                <Select
                    name="contaId"
                    title={`Define a conta para ${urlParams.get("tipo")}`}
                    text="Conta"
                    inputWidth="180px"
                    options={OptionsConta}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={lancamento.contaId}
                    isEnum={false}
                    notOptNull={false}
                />
                {lancamento.transferencia === true && (
                    <Select
                        name="contaDestinoId"
                        title={`Define a conta destino da transferência`}
                        text="Conta Destino"
                        inputWidth="180px"
                        options={contasDisponiveis}
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        value={lancamento.contaDestinoId}
                        isEnum={false}
                        notOptNull={false}
                    />
                )}
                <Select
                    name="formaId"
                    title={`Define a forma para ${urlParams.get("tipo")}`}
                    text="Forma"
                    inputWidth="240px"
                    options={OptionsForma}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={lancamento.formaId}
                    isEnum={false}
                    notOptNull={false}
                    disabled={btnText === "Baixar"}
                />
                <Select
                    name="prazoId"
                    title={`Define o prazo para ${urlParams.get("tipo")}`}
                    text="Prazo"
                    inputWidth="180px"
                    options={OptionsPrazos}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={lancamento.prazoId}
                    isEnum={false}
                    notOptNull={false}
                    disabled={btnText === "Baixar"}
                />
            </div>
            <div>
                <Input
                    text="Vencimento"
                    type="date"
                    name="dataVencimento"
                    placeholder="Data"
                    title="Data de lançamento no sistema financeiro da primeira parcela"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={
                        //lancamento.dataVencimento
                        (lancamento.dataVencimento && retornaData(lancamento.dataVencimento)) || retornaData(moment())
                    }
                />
                <Checkbox
                    title="Mantém fixo o dia do vencimento, funciona para lançamentos parcelados"
                    text="Fixa Venc."
                    name="fixaDiaVencimento"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={lancamento.fixaDiaVencimento}
                    checked={lancamento.fixaDiaVencimento === true ? "checked" : ""}
                />
                <Checkbox
                    title="Informa se o lançamento vai ficar provisionado ou não"
                    text="Provisão"
                    name="provisao"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={lancamento.provisao}
                    checked={lancamento.provisao === true ? "checked" : ""}
                />
                <InputNumber
                    text="Valor Título"
                    type="text"
                    name="valorOriginal"
                    placeholder="Valor R$"
                    inputWidth="150px"
                    title="digite o valor total do título"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={lancamento.valorOriginal ? formatarNumero(lancamento.valorOriginal) : ""}
                    readonly={btnText === "Baixar"}
                    required={btnText !== "Baixar" ? true : false}
                />
                <Input
                    text="Parcelas"
                    type="number"
                    name="parcela"
                    placeholder="parcelas"
                    inputWidth="100px"
                    title="Digite número de parcelas"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={lancamento.parcela || ""}
                    textAlign="center"
                    min={1}
                    readonly={btnText === "Baixar"}
                />
                <InputNumber
                    text={btnText === "Atualizar" ? `Valor da Parcela ${lancamento.parcelas}` : "Valor da Parcela"}
                    type="text"
                    name="valor"
                    placeholder="Valor R$"
                    inputWidth="160px"
                    title="digite o valor do lançamento"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={lancamento.valor ? formatarNumero(lancamento.valor) : ""}
                    readonly={btnText === "Baixar"}
                />
                <InputNumber
                    text="Valor em Aberto"
                    type="text"
                    name="valorAberto"
                    placeholder="Valor R$"
                    inputWidth="160px"
                    title="digite o valor em aberto do lançamento"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={lancamento.valorAberto ? formatarNumero(lancamento.valorAberto) : ""}
                    readonly={btnText === "Baixar"}
                />
            </div>
            <div>
                <InputNumber
                    text="Desconto em %"
                    type="text"
                    name="descontoPercentual"
                    placeholder="Valor Percentual"
                    inputWidth="140px"
                    title="digite o valor do desconto em percentual"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={lancamento.descontoPercentual ? formatarNumero(lancamento.descontoPercentual) : ""}
                />
                <InputNumber
                    text="Desconto em R$"
                    type="text"
                    name="descontoValor"
                    placeholder="Valor R$"
                    inputWidth="160px"
                    title="digite o valor do desconto em reais"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={lancamento.descontoValor ? formatarNumero(lancamento.descontoValor) : ""}
                />
                <InputNumber
                    text="Acréscimo em %"
                    type="text"
                    name="acrescimoPercentual"
                    placeholder="Valor percentual"
                    inputWidth="140px"
                    title="digite o valor do acréscimo em percentual"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={lancamento.acrescimoPercentual ? formatarNumero(lancamento.acrescimoPercentual) : ""}
                />
                <InputNumber
                    text="Acréscimo em R$"
                    type="text"
                    name="acrescimoValor"
                    placeholder="Valor R$"
                    inputWidth="160px"
                    title="digite o valor do acréscimo em reais"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={lancamento.acrescimoValor ? formatarNumero(lancamento.acrescimoValor) : ""}
                />
                {btnText === "Baixar" && (
                    <>
                        <InputNumber
                            text="Valor da Baixa"
                            type="text"
                            name="baixa"
                            placeholder="Valor R$"
                            inputWidth="160px"
                            title="digite o valor da baixa do lançamento"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={lancamento.baixa ? formatarNumero(lancamento.baixa) : ""}
                        />

                        <Input
                            text="Data da Baixa"
                            type="date"
                            name="dataBaixa"
                            placeholder="Data da Baixa"
                            title="Data da baixa do lançamento no sistema financeiro"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={(lancamento.dataBaixa && retornaData(lancamento.dataBaixa)) || retornaData(moment())}
                        />
                    </>
                )}
            </div>

            {divBaixaLancamento && (
                <div>
                    <Checkbox
                        title="Baixa o lançamento automaticamente"
                        text="Baixa"
                        name="lancamentoBaixa.baixaLancamento"
                        type="checkbox"
                        handleOnChange={handleChange}
                        value={lancamentoBaixa.baixaLancamento}
                        checked={lancamentoBaixa.baixaLancamento === true ? "checked" : ""}
                    />
                    <InputNumber
                        text="Valor da Baixa"
                        type="text"
                        name="lancamentoBaixa.baixa"
                        placeholder="Valor R$"
                        inputWidth="160px"
                        title="digite o valor da baixa do lançamento"
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        value={lancamentoBaixa.baixa ? formatarNumero(lancamentoBaixa.baixa) : ""}
                    />

                    <Input
                        text="Data da Baixa"
                        type="date"
                        name="lancamentoBaixa.dataBaixa"
                        placeholder="Data da Baixa"
                        title="Data da baixa do lançamento no sistema financeiro"
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        value={(lancamentoBaixa.dataBaixa && retornaData(lancamentoBaixa.dataBaixa)) || retornaData(moment())}
                    />
                </div>
            )}
            {divCalculaJuros && (
                <div>
                    <Checkbox
                        title="Quando marcado o sistema executa a função de calcular juros, aplicando os valores automaticamente durante a madrugada, isso afeta os lançamentos em atraso"
                        text="Calcula Juros"
                        name="calculaJuros"
                        type="checkbox"
                        handleOnChange={handleChange}
                        value={lancamento.calculaJuros}
                        checked={lancamento.calculaJuros === true ? "checked" : ""}
                    />
                    <Input
                        text="Atraso"
                        type="number"
                        name="diasAtraso"
                        placeholder="Atraso"
                        inputWidth="100px"
                        title="dias de atraso com relação ao vencimento"
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        value={lancamento.diasAtraso || ""}
                        textAlign="center"
                        min={1}
                        readonly={true /*readOnlyCalculaJuros*/}
                    />
                    <InputNumber
                        text="Percentual Multa"
                        type="text"
                        name="multaPercentual"
                        placeholder="Valor %"
                        inputWidth="160px"
                        title="digite o percentual a ser aplicado"
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        value={lancamento.multaPercentual ? formatarNumero(lancamento.multaPercentual) : ""}
                        readonly={readOnlyCalculaJuros}
                    />
                    <InputNumber
                        text="Multa"
                        type="text"
                        name="multa"
                        placeholder="Valor R$"
                        inputWidth="160px"
                        title="valor da multa"
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        value={lancamento.multa ? formatarNumero(lancamento.multa) : ""}
                        readonly={readOnlyCalculaJuros || btnText === "Cadastrar"}
                    />
                    <InputNumber
                        text="Percentual Juros"
                        type="text"
                        name="jurosPercentual"
                        placeholder="Valor %"
                        inputWidth="160px"
                        title="digite o percentual a ser aplicado"
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        value={lancamento.jurosPercentual ? formatarNumero(lancamento.jurosPercentual) : ""}
                        readonly={readOnlyCalculaJuros}
                    />
                    <InputNumber
                        text="Juros"
                        type="text"
                        name="juros"
                        placeholder="Valor R$"
                        inputWidth="160px"
                        title="valor dos juros"
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        value={lancamento.juros ? formatarNumero(lancamento.juros) : ""}
                        readonly={readOnlyCalculaJuros || btnText === "Cadastrar"}
                    />
                </div>
            )}
            {divMapaAbastecimento && (
                <div>
                    <Checkbox
                        title="Grava mapa de abastecimento"
                        text="Mapa"
                        name="mapaAbastecimento.mapa"
                        type="checkbox"
                        handleOnChange={handleChange}
                        value={mapaAbastecimento.mapa}
                        checked={mapaAbastecimento.mapa === true ? "checked" : ""}
                    />

                    <Select
                        name="mapaAbastecimento.pessoaId"
                        title="Selecione o motorista"
                        text="Motorista"
                        inputWidth="180px"
                        options={OptionsMotorista}
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        value={mapaAbastecimento.pessoaId}
                        isEnum={false}
                        notOptNull={true}
                        required={mapaAbastecimento.mapa === true ? true : false}
                    />

                    <Select
                        name="mapaAbastecimento.produtoId"
                        title="Selecione o veículo"
                        text="Veículo"
                        inputWidth="180px"
                        options={OptionsVTR}
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        value={mapaAbastecimento.produtoId}
                        isEnum={false}
                        notOptNull={true}
                        required={mapaAbastecimento.mapa === true ? true : false}
                    />
                    <Input
                        text="Km Anterior"
                        type="number"
                        name="mapaAbastecimento.kmAnterior"
                        placeholder="Km"
                        inputWidth="100px"
                        title="Km anterior"
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        value={mapaAbastecimento.kmAnterior || ""}
                        textAlign="center"
                        readonly={true}
                    />
                    <Input
                        text="Km Atual"
                        type="number"
                        name="mapaAbastecimento.km"
                        placeholder="Km"
                        inputWidth="100px"
                        title="Digite o km no momento do abastecimento"
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        value={mapaAbastecimento.km || ""}
                        textAlign="center"
                        readonly={false}
                        required={mapaAbastecimento.mapa === true ? true : false}
                    />
                    <InputNumber
                        text="Litros"
                        type="text"
                        name="mapaAbastecimento.litros"
                        placeholder="Litros"
                        inputWidth="150px"
                        title="digite a quantidade de litros no momento do abastecimento"
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        value={mapaAbastecimento.litros ? formatarNumero(mapaAbastecimento.litros) : ""}
                        required={mapaAbastecimento.mapa === true ? true : false}
                    />
                    <InputNumber
                        text="Preço"
                        type="text"
                        name="mapaAbastecimento.preco"
                        placeholder="Preço R$"
                        inputWidth="150px"
                        title="digite o valor do litro"
                        handleOnChange={handleChange}
                        handleOnFocus={handleOnFocus}
                        handleOnBlur={handleOnBlur}
                        value={mapaAbastecimento.preco ? formatarNumero(mapaAbastecimento.preco) : ""}
                        required={mapaAbastecimento.mapa === true ? true : false}
                    />
                </div>
            )}
            <div>
                <Input
                    text="Documento"
                    type="text"
                    name="documento"
                    placeholder="número documento"
                    inputWidth="160px"
                    title="Digite número do documento"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={lancamento.documento || ""}
                    textAlign="center"
                />
                <Input
                    text="Nosso Número"
                    type="text"
                    name="nossoNumero"
                    placeholder="Nosso Número"
                    inputWidth="160px"
                    title="Digite o nosso número"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={lancamento.nossoNumero || ""}
                    textAlign="center"
                />
                <Input
                    text="Boleto"
                    type="text"
                    name="numeroBoleto"
                    placeholder="número boleto"
                    inputWidth="160px"
                    title="Digite número do boleto"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={lancamento.numeroBoleto || ""}
                    textAlign="center"
                />
                <Input
                    text="Código de Barras / Chave  PIX"
                    type="text"
                    name="codBarraPIX"
                    placeholder="código de barras ou chave PIX"
                    inputWidth="380px"
                    title="Digite o código de barras ou chave PIX"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={lancamento.codBarraPIX || ""}
                    textAlign="center"
                />
            </div>
            <div>
                <TextArea
                    text="Histórico"
                    type="text"
                    name="historico"
                    placeholder="Digite aqui o histórico do lançamento"
                    title="Digite o histórico do lançamento"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={lancamento.historico || ""}
                    textAlign="left"
                    linhas="4"
                    colunas="120"
                />
            </div>
            <div>
                <Stack direction="row" spacing={1}>
                    <Tooltip arrow placement="top-start" title="Voltar">
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() =>
                                navigate(caminhoVoltar, {
                                    ul: urlParams.get("ul"),
                                    li: urlParams.get("li"),
                                    tipo: urlParams.get("tipo"),
                                })
                            }
                        >
                            Voltar
                        </Button>
                    </Tooltip>
                    <Button
                        type="submit"
                        variant="contained"
                        color="success"
                        disabled={btnText === "Baixar" && lancamento.baixa <= 0}
                    >
                        {btnText}
                    </Button>
                </Stack>
            </div>
        </form>
    );
}
export default Form;
