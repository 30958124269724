export const ParamsURL = (search) => {
    let row = {};
    var parts = search.substr(1).split("&");

    for (let i = 0; i < parts.length; i++) {
        let arr = parts[i].split("=");
        row[arr[0]] = arr[1];
    }

    return row;
};

export const ParamsFiltros = (
    columnFilterFns,
    columnFilters,
    globalFilter,
    sorting
) => {
    let dadosFilters = "";
    let dadosFilterFns = "";
    let dadosSorting = "";

    if (!!columnFilters?.length) {
        columnFilters.map((filters) => {
            const { id: columnId, value: filterValue } = filters;
            dadosFilters += `${columnId}=${filterValue}&`;

            if (
                filterValue.length > 0 &&
                columnFilterFns.length !== 0 &&
                columnFilterFns.length !== "undefined"
            ) {
                dadosFilterFns += `${columnId}=${columnFilterFns[columnId]}&`;
            }

            return null; // Adicione esse retorno
        });
    }

    if (sorting.length > 0) {
        const sort = sorting[0];
        const { id, desc } = sort;
        dadosSorting = `${id}=${desc}&`;
    }

    return {
        columnFilters: dadosFilters,
        columnFilterFns: dadosFilterFns,
        globalFilter: globalFilter,
        sorting: dadosSorting,
    };
};

export const ParamsFiltrosObj = (filters, filterFns) => {
    let row = {};
    let parts = filters
        ?.replace(/%253D/g, "=")
        .replace(/%2526/g, "&")
        .split("&");

    for (let i = 0; i < parts.length - 1; i++) {
        let arr = parts[i].split("=");
        row[arr[0]] = arr[1];
    }

    return row;
};

export const LimparFiltros = () => {
    localStorage.setItem("columnFilterFns", JSON.stringify({}));
    localStorage.setItem("columnFilters", JSON.stringify({}));
    localStorage.setItem("globalFilter", JSON.stringify({}));
    localStorage.setItem("sorting", JSON.stringify({}));
    localStorage.setItem(
        "pagination",
        JSON.stringify({ pageIndex: 0, pageSize: 10 })
    );
    return true;
};
//export default { ParamsURL, ParamsFiltros, ParamsFiltrosObj, LimparFiltros };
const objetoDeFuncoes = {
    ParamsURL,
    ParamsFiltros,
    ParamsFiltrosObj,
    LimparFiltros,
};

export default objetoDeFuncoes;
