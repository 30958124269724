// selectedUUIDsReducer.js

import {
    SELECT_COMPONENT,
    UPDATE_SELECTED_COMPONENTS,
    CLEAR_SELECTED_COMPONENT,
} from "./actions";

const initialState = [];

const selectedUUIDsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SELECT_COMPONENT:
            return [...state, action.payload];
        case UPDATE_SELECTED_COMPONENTS:
            return action.payload;
        case CLEAR_SELECTED_COMPONENT:
            return []; // Retorne null diretamente
        default:
            return state;
    }
};

export default selectedUUIDsReducer;
