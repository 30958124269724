import { useContext } from "react";
import { Context } from "../../context/UserContext";
import styles from "./Select.module.css";

function Select({
    text,
    title,
    name,
    options,
    handleOnChange,
    handleOnBlur,
    handleOnFocus,
    value,
    isEnum,
    notOptNull,
    inputWidth = "150px",
    disabled,
}) {
    //configura o tema
    const { parametro } = useContext(Context);

    return (
        <div className={`${styles.form_control} ${styles[parametro.tema]}`}>
            <label htmlFor={name} title={title}>
                {text}:
            </label>
            <select
                name={name}
                id={name}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onFocus={handleOnFocus}
                value={value || ""}
                style={{
                    width: `var(--input-width, ${inputWidth})`,
                }}
                disabled={disabled}
            >
                {/* {isEnum === false && notOptNull === false && <option value="">Selecione uma opção</option>} */}
                {notOptNull === false && <option value="">Selecione uma opção</option>}
                {Array.isArray(options) &&
                    options?.map((option) => (
                        <option
                            value={isEnum ? option : option.id}
                            key={isEnum ? option : option.id}
                            title={isEnum ? title : option?.titulo}
                        >
                            {isEnum ? option : option.descricao}
                        </option>
                    ))}
            </select>
        </div>
    );
}

export default Select;
