import { useContext } from "react";
import { Context } from "../../context/UserContext";
import styles from "./Checkbox.module.css";

function Checkbox({
    type,
    text,
    name,
    handleOnChange,
    handleOnBlur,
    handleOnFocus,
    value,
    checked,
    title,
    disabled,
    inputWidth = "35px",
    inputheight = "35px",
}) {
    //configura o tema
    const { parametro } = useContext(Context);

    return (
        <div className={`${styles.form_control} ${styles[parametro.tema]}`}>
            <label htmlFor={name}>{text}:</label>
            <input
                type={type}
                name={name}
                id={name}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onFocus={handleOnFocus}
                value={value}
                checked={checked}
                title={title}
                disabled={disabled}
                style={{
                    width: `var(--input-width, ${inputWidth})`,
                    height: `var(--input-height, ${inputheight})`,
                }}
            />
        </div>
    );
}

export default Checkbox;
