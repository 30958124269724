// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Input_form_control__YVXkh {
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
}

.Input_form_control__YVXkh label {
    margin-bottom: 0.3em;
    font-weight: bold;
    font-size: 0.8em;
}

.Input_form_control__YVXkh input {
    padding: 0.7em;
    border: 1px solid #777;
    border-radius: 5px;
    background-color: #484848;
    color: #ffe7e7;
}

.Input_form_control__YVXkh input::placeholder {
    color: #7b7b7b;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/Auth/Input.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,oBAAoB;IACpB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,sBAAsB;IACtB,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".form_control {\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 1em;\n}\n\n.form_control label {\n    margin-bottom: 0.3em;\n    font-weight: bold;\n    font-size: 0.8em;\n}\n\n.form_control input {\n    padding: 0.7em;\n    border: 1px solid #777;\n    border-radius: 5px;\n    background-color: #484848;\n    color: #ffe7e7;\n}\n\n.form_control input::placeholder {\n    color: #7b7b7b;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form_control": `Input_form_control__YVXkh`
};
export default ___CSS_LOADER_EXPORT___;
