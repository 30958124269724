import { useState, useEffect } from "react";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import { Stack, Button } from "@mui/material";

/* hooks */

import Input from "../../form/Input";
import Select from "../../form/Select";
import Checkbox from "../../form/Checkbox";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, OptionsMenu, OptionsOperacao }) {
    const [dados, setDados] = useState(formData || {});
    const navigate = useNavigateParams();

    useEffect(() => {
        setDados(formData || {});
    }, [formData]);

    async function handleChange(e) {
        const { name, value, checked, type } = e.target;

        setDados({ ...dados, [name]: type === "checkbox" ? checked : value });
    }

    async function handleOnBlur(e) {}
    async function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();
        //rotina pera obter o valor mesmo sem o usuario clicar no input
        if (!dados.menuId && document.getElementById("menuId").value !== undefined) {
            dados.menuId = parseInt(document.getElementById("menuId").value);
        }
        handleSubmit(dados);
    };

    return (
        <form onSubmit={submit}>
            <h1>Gestão de SubMenus</h1>
            <div>
                <Select
                    text="Selecione o Menu"
                    name="menuId"
                    options={OptionsMenu}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.menuId || ""}
                    isEnum={false}
                    inputWidth={"170px"}
                />
                <Input
                    text="Sub-Menu"
                    type="text"
                    name="descricao"
                    placeholder="nome do Menu"
                    title="Digite o nome do Menu"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.descricao || ""}
                    required
                />
                <Input
                    text="Título"
                    type="text"
                    name="title"
                    placeholder="Título"
                    title="Digite o título que aparecerá quando o mouse estiver no submenu"
                    inputWidth="350px"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.title || ""}
                />

                <Input
                    text="URL"
                    type="text"
                    name="url"
                    placeholder="URL"
                    title="Digite a URL"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.url || ""}
                />
                <Input
                    text="Target"
                    type="text"
                    name="target"
                    placeholder="target"
                    title="Digite o target, _self, _blank, _parent, _top"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.target || ""}
                />
                <Input
                    text="Tipo"
                    type="text"
                    name="tipo"
                    placeholder="Tipo"
                    title="Digite o tipo Pagar, Receber ou outro conforme uso no sistema"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.tipo || ""}
                />
                <Select
                    text="Operação"
                    name="operacaoId"
                    options={OptionsOperacao}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.operacaoId || ""}
                    isEnum={false}
                    notOptNull={false}
                    required={false}
                    inputWidth={"170px"}
                />
            </div>
            <div>
                <Checkbox
                    title="Quando marcado indica que o menu é apenas para administrador"
                    text="Admin"
                    name="apenasAdmin"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.apenasAdmin}
                    checked={dados.apenasAdmin === true ? "checked" : ""}
                />
                <Checkbox
                    title="Quando marcado indica que o menu é de tabela comum"
                    text="Comun"
                    name="comum"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.comum}
                    checked={dados.comum === true ? "checked" : ""}
                />
                <Checkbox
                    title="Quando marcado o submenu esta ATIVO"
                    text="Ativo"
                    name="ativo"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.ativo}
                    checked={dados.ativo === true ? "checked" : ""}
                />
            </div>
            <div>
                <Checkbox
                    title="Quando marcado permitirá INCLUIR"
                    text="Incluir"
                    name="incluir"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.incluir}
                    checked={dados.incluir === true ? "checked" : ""}
                />
                <Checkbox
                    title="Quando marcado permitirá EDITAR"
                    text="Editar"
                    name="editar"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.editar}
                    checked={dados.editar === true ? "checked" : ""}
                />
                <Checkbox
                    title="Quando marcado permitirá IMPRIMIR"
                    text="Imprimir"
                    name="imprimir"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.imprimir}
                    checked={dados.imprimir === true ? "checked" : ""}
                />
                <Checkbox
                    title="Quando marcado permitirá CANCELAR"
                    text="Cancelar"
                    name="cancelar"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.cancelar}
                    checked={dados.cancelar === true ? "checked" : ""}
                />
                <Checkbox
                    title="Quando marcado permitirá BAIXAR, funciona apenas no modulo FINANCEIRO"
                    text="Baixar"
                    name="baixar"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.baixar}
                    checked={dados.baixar === true ? "checked" : ""}
                />
                <Checkbox
                    title="Quando marcado permitirá CANCELAR BAIXAR, funciona apenas no modulo FINANCEIRO"
                    text="Cancelar Baixa"
                    name="cancelarBaixa"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.cancelarBaixa}
                    checked={dados.cancelarBaixa === true ? "checked" : ""}
                />
                <Checkbox
                    title="Quando marcado permitirá TRANSFORMAR"
                    text="Transformar"
                    name="transformar"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.transformar}
                    checked={dados.transformar === true ? "checked" : ""}
                />
                <Checkbox
                    title="Quando marcado permitirá visualizar custos"
                    text="Custos"
                    name="custos"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.custos}
                    checked={dados.custos === true ? "checked" : ""}
                />
                <Checkbox
                    title="Permite cadastrar ou editar usuário"
                    text="Usuário"
                    name="usuario"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.usuario}
                    checked={dados.usuario === true ? "checked" : ""}
                />
            </div>
            <div>
                <Stack direction="row" spacing={1}>
                    <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                        Voltar
                    </Button>
                    <Button type="submit" variant="contained" color="success">
                        {btnText}
                    </Button>
                </Stack>
            </div>
        </form>
    );
}

export default Form;
