import api from "./";

/**
 * Consulta formas de pagamento no backend.
 */
export const forma = async (tipo, credito = false, empresaId, descricao, dependente = false, nivel = null, formaId = null) => {
    try {
        const { data } = await api.get("/serviceFORMA", {
            params: { tipo, credito, empresaId, descricao, dependente, nivel, formaId },
        });
        return data;
    } catch (error) {
        return error.response?.data || error.message;
    }
};

/**
 * Função para obter do backend os prazos.
 * @param {string} tipo - Define se é Pagar ou Receber.
 * @param {string} formaId - Obtem os prazos pelo nivelVenda da forma vinculada.
 * @param {string} prazoId - Retorna o prazo especifico pelo id.
 * @param {string} empresaId - Retorna os prazos da empresa.
 * @param {string} prazoMedio - Retorna todos os prazos que a média de dias seja igual o menor que o valor passado.
 * @returns {arrya} array.
 * @comentario Servi para manter os prazos especificados no cadastro da pessoa
 */
export const prazo = async (tipo, formaId, nivel, prazoId, empresaId, prazoMedio) => {
    try {
        const { data } = await api.get("/servicePRAZO", { params: { tipo, formaId, nivel, prazoId, empresaId, prazoMedio } });
        return data;
    } catch (error) {
        return error;
    }
};

export const lancamentosEmAberto = async (pessoaId, tipo) => {
    try {
        const { data } = await api.get("/serviceLancamentosEmAberto", { params: { pessoaId, tipo } });
        return data;
    } catch (error) {
        return error;
    }
};
/**
 *
 * @param {*} empresaId id da empresa ou padrão 1
 * @param {*} campo true para o campo informado
 * @returns
 */
export const situacao = async (empresaId = 1, campo) => {
    try {
        const { data } = await api.get("/serviceSituacao", { params: { empresaId, campo } });
        return data;
    } catch (error) {
        return error;
    }
};

export const calcularParcelas = async (
    totalParcelas,
    primeiraDataVencimento,
    dadosLancamento,
    credito = false,
    fixaDiaVencimento = false,
    diasEntreParcelas,
    valorOriginal,
    valor,
    diaVencimento
) => {
    try {
        const { data } = await api.get("/serviceCalcularParcelas", {
            params: {
                totalParcelas,
                primeiraDataVencimento,
                dadosLancamento,
                credito,
                fixaDiaVencimento,
                diasEntreParcelas,
                valorOriginal,
                valor,
                diaVencimento,
            },
        });
        return data;
    } catch (error) {
        return error;
    }
};

export const buscaSaldoByContaId = async (contaId) => {
    if (!contaId) {
        return;
    }
    try {
        const response = await api.get("/financeiro/buscaSaldoByContaId", {
            params: { contaId },
        });

        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
};

//novas consultas por causa do multiempresa
export const buscaContaById = async (contaId) => {
    if (!contaId) {
        return;
    }
    try {
        const response = await api.get("/financeiro/buscaContaById", {
            params: { contaId },
        });

        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
};
export const buscaFormaById = async (formaId) => {
    if (!formaId) {
        return;
    }
    try {
        const { data } = await api.get("/financeiro/buscaFormaById", {
            params: { formaId },
        });

        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};
export const buscaPrazoById = async (prazoId) => {
    if (!prazoId) {
        return;
    }
    try {
        const { data } = await api.get("/financeiro/buscaPrazoById", {
            params: { prazoId },
        });

        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};
export const buscaCentroCustoById = async (id) => {
    if (!id) {
        return;
    }
    try {
        const { data } = await api.get("/financeiro/buscaCentroCustoById", {
            params: { id },
        });

        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};

/* consultas voltadas ao modo multiempresa
/**
 *
 * @param {*} empresaIds retorna as contas das empresas por um array
 * @returns
 */
export const buscaContaByEmpresaIds = async (empresaIds, caixa) => {
    if (!Array.isArray(empresaIds) || empresaIds.length === 0) {
        console.warn("Parâmetro empresaIds inválido:", empresaIds);
        return null;
    }

    try {
        const { data } = await api.get("/financeiro/buscaContaByEmpresaIds", { params: { empresaIds, caixa } });
        return data;
    } catch (error) {
        console.error("Erro ao buscar contas por empresaIds:", error);
        throw error; // Lança o erro para tratamento externo
    }
};
/**
 *
 * @param {*} empresaIds retorna as contas das empresas por um array
 * @returns
 */
export const buscaFormaByEmpresaIds = async (empresaIds) => {
    if (!Array.isArray(empresaIds) || empresaIds.length === 0) {
        console.warn("Parâmetro empresaIds inválido:", empresaIds);
        return null;
    }

    try {
        const { data } = await api.get("/financeiro/buscaFormaByEmpresaIds", { params: { empresaIds } });
        return data;
    } catch (error) {
        console.error("Erro ao buscar contas por empresaIds:", error);
        throw error; // Lança o erro para tratamento externo
    }
};
export const buscaPrazoByEmpresaIds = async (empresaIds) => {
    if (!Array.isArray(empresaIds) || empresaIds.length === 0) {
        console.warn("Parâmetro empresaIds inválido:", empresaIds);
        return null;
    }

    try {
        const { data } = await api.get("/financeiro/buscaPrazoByEmpresaIds", { params: { empresaIds } });
        return data;
    } catch (error) {
        console.error("Erro ao buscar contas por empresaIds:", error);
        throw error; // Lança o erro para tratamento externo
    }
};
export const buscaCentroCustoByEmpresaIds = async (empresaIds, tipo) => {
    if (!Array.isArray(empresaIds) || empresaIds.length === 0) {
        console.warn("Parâmetro empresaIds inválido:", empresaIds);
        return null;
    }

    try {
        const { data } = await api.get("/financeiro/buscaCentroCustoByEmpresaIds", { params: { empresaIds, tipo } });
        return data;
    } catch (error) {
        console.error("Erro ao buscar contas por empresaIds:", error);
        throw error; // Lança o erro para tratamento externo
    }
};

//situações envolvendo o movimento de lancamentos no CAIXA
export const managerCaixaLancamento = async (novoLancamento) => {
    try {
        const { data } = await api.post("/caixa/CreateOrEditLancamento", novoLancamento);
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};
export const listarCaixaLancamento = (start, size, filters, filtersMode, globalFilter, sorting, caixaId) =>
    api.get("/caixa/listarLancamento", {
        params: {
            start,
            size,
            filters,
            filtersMode,
            globalFilter,
            sorting,
            caixaId,
        },
    });
export const deletarLancamentoCaixa = async (uuid) => api.post("/caixa/deletarLancamentoCaixa", { uuid });

export const caixaBaixarOrEstornar = async (obj) => {
    try {
        const { data } = await api.post("/financeiro/caixaBaixarOrEstornar", obj);
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};
