import { useState, useEffect } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import moment from "moment";
import "moment/locale/pt-br";

import { Stack, Button, Tooltip } from "@mui/material";

/* utils */
import { retornaData } from "../../../utils/retornaDataFormatada";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import * as DataListAPI from "../../../api/dataList";
import * as EquipeAPI from "../../../api/equipe";
import * as PessoaAPI from "../../../api/pessoa";
import * as PlantaoAPI from "../../../api/plantao";
import * as TabelaAPI from "../../../api/tabela";
import * as UsuarioAPI from "../../../api/usuario";

import formStyles from "../../form/Form.module.css";

import DataList from "../../form/DataList";
import Input from "../../form/Input";
import Checkbox from "../../form/Checkbox";

import Select from "../../form/Select";
import TextArea from "../../form/TextArea";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, OptionsEmpresa }) {
    const [dados, setDados] = useState({});
    const [pessoaConcluido, setPessoaConcluido] = useState({});
    const [pessoaAssinatura, setPessoaAssinatura] = useState({});
    const [pessoa, setPessoa] = useState({});
    const [parametroUsuario, setParametroUsuario] = useState({});
    const [Plantonistas, setPlantonistas] = useState([]);
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();

    if (!dados.empresaId && btnText === "Cadastrar") {
        dados.ativo = true;
        dados.empresaId = localStorage.getItem("empresaId");
        dados.tipo = "Produção";
        dados.concluido = false;
        dados.concluidoData = null;

        const horaAtual = moment().format("HH:mm");
        if (horaAtual > "07:00" && horaAtual <= "17:00") {
            dados.inicio = moment().format("YYYY-MM-DD 07:00:00");
            dados.termino = moment().format("YYYY-MM-DD 17:00:00");
        } else {
            dados.inicio = moment().format("YYYY-MM-DD 21:00:00");
            dados.termino = moment().add(1, "days").format("YYYY-MM-DD 07:00:00");
        }
    }

    const [novoColaborador, setNovoColaborador] = useState({});
    //Atualiza o Datalist
    const [OptionsColaborador, setOptionsColaborador] = useState([]);

    const consultaDatalistColaborador = async (empresaId, filtro, ativo, tipo) => {
        let result = await DataListAPI.dataListColaborador(empresaId, filtro, ativo, tipo);
        return result;
    };
    //abas
    const [tabEnabled, setTabEnabled] = useState({
        cabeçalho: true,
        integrantes: true,
        observacao: true,
        assinatura: true,
    });

    //Select
    const [EnumTipo, setEnumTipo] = useState([]);
    const [EnumCargo, setEnumCargo] = useState([]);
    const [OptionsEquipe, setOptionsEquipe] = useState({});

    //Consultas
    const consultaEnum = async (table, field, filter) => {
        const result = await TabelaAPI.enums(table, field, filter);
        if (table === "Equipes") {
            field === "tipo" && setEnumTipo(result ? result : []);
        } else if ((table = "Plantonistas")) {
            field === "cargo" && setEnumCargo(result ? result : []);
        }
    };

    const consultaEquipe = async (empresaId, tipo, ativo) => {
        const result = await EquipeAPI.retornaEquipesPorTipo(empresaId, tipo, ativo);

        if (!dados.equipeId && btnText === "Cadastrar") {
            setDados((prevDados) => ({ ...prevDados, equipeId: result[0].id }));
            setNovoColaborador((prev) => ({ ...prev, plantaoId: dados.id, equipeId: result[0].id }));
        } else {
            setNovoColaborador((prev) => ({ ...prev, plantaoId: dados.id, equipeId: dados.equipeId }));
        }
        setOptionsEquipe(result);
    };

    const parametrosUsuario = async (pessoaId) => {
        const result = await PessoaAPI.parametroUsuario(pessoaId);
        setParametroUsuario(result);
    };
    useEffect(() => {
        setDados(formData || {});
        setPessoaAssinatura(formData?.pessoaAssinatura || {});
        setPlantonistas(formData?.Plantonistas || []);

        consultaEnum("Equipes", "tipo");
        consultaEnum("Plantonistas", "cargo");

        setNovoColaborador({
            uuid: null,
            plantaoId: dados.id,
            pessoaId: dados.equipeId,
            equipeId: null,
            Pessoa: {
                CPForCNPJ: null,
                descricao: null,
            },
            cargo: null,
            falta: false,
            ferias: false,
            funcao: "adicionar",
            ativo: false,
        });

        parametrosUsuario(localStorage.getItem("pessoaId"));
        // eslint-disable-next-line
    }, [formData]);

    useEffect(() => {
        setTabEnabled((prevTabEnabled) => ({ ...prevTabEnabled, assinatura: parametroUsuario.plantaoAssinatura }));
    }, [parametroUsuario.plantaoAssinatura]);

    useEffect(() => {
        //atualizar a tabela pessoa com as tabels filhas
        setDados((prev) => {
            return {
                ...prev,
                Plantonistas: Plantonistas,
            };
        });
    }, [Plantonistas]);

    useEffect(() => {
        consultaEquipe(dados.empresaId, dados.tipo, null);
        consultaEnum("Plantonistas", "cargo", dados.tipo);

        // eslint-disable-next-line
    }, [dados.empresaId, dados.tipo]);

    useEffect(() => {
        const fetchData = async () => {
            const result = await PessoaAPI.pessoaById(novoColaborador.pessoaId);

            setNovoColaborador((prevColaborador) => ({
                ...prevColaborador,
                Pessoa: { CPForCNPJ: result.data.CPForCNPJ, descricao: result.data.descricao },
            }));
        };
        fetchData();
    }, [novoColaborador.pessoaId]);

    useEffect(() => {
        if (btnText === "Cadastrar" && dados.equipeId) {
            // Resgatar a equipe padrão
            const fetchData = async () => {
                try {
                    const data = await EquipeAPI.retornaIntegrantes(dados.equipeId);

                    if (data?.message) {
                        setFlashMessage(data.message, data.type);

                        let integrantes;

                        if (data.data && data.data.length > 0) {
                            integrantes = data.data.map((item) => ({
                                equipeId: item?.equipeId,
                                Pessoa: {
                                    CPForCNPJ: item?.Pessoa.CPForCNPJ,
                                    descricao: item?.Pessoa.nomePrincipal,
                                },
                                pessoaId: item?.pessoaId,
                                cargo: item?.cargo,
                                ferias: false,
                                falta: false,
                                ativo: item?.ativo,
                            }));
                        } else {
                            integrantes = [];
                        }

                        // Se desejar inserir todos os arrays em plantonistas, você pode utilizar spread operator
                        setPlantonistas([...integrantes]);
                    }
                } catch (error) {
                    console.error(error);
                    setFlashMessage(error.response.data.message, error.response.data.type);
                }
            };

            fetchData();
        }
        setNovoColaborador((prevColaborador) => ({
            ...prevColaborador,
            equipeId: dados.equipeId,
            funcao: "adicionar",
        }));
        // eslint-disable-next-line
    }, [btnText, dados.equipeId]);

    useEffect(() => {
        setDados((prevDados) => ({
            ...prevDados,
            concluidoData: dados.concluido === true ? moment().format("YYYY-MM-DD HH:mm:ss") : null,
            pessoaId: dados.concluido === true ? localStorage.getItem("pessoaId") : null,
        }));
        setPessoaConcluido((prevPessoaConcluido) => ({
            ...prevPessoaConcluido,
            descricao: dados.concluido === true ? localStorage.getItem("nome") : null,
        }));
    }, [dados.concluido]);

    //funções
    const handleColaborador = async () => {
        if (!novoColaborador.equipeId) {
            setNovoColaborador({ ...novoColaborador, equipeId: dados.equipeId });
        }
        if (!novoColaborador.cargo) {
            setFlashMessage("Cargo não selecionado", "waning");
            return;
        }
        if (dados.id) {
            const result = await PlantaoAPI.integrantes(novoColaborador);

            if (result.status === 202) {
                setFlashMessage(result.message, result.type);
            } else {
                setFlashMessage(result.data.message, result.data.type);
            }

            setPlantonistas(result.data.integrantes);
        } else {
            if (novoColaborador.funcao === "editar") {
                Plantonistas[novoColaborador.index] = {
                    ...Plantonistas[novoColaborador.index],
                    ...novoColaborador,
                };
            } else if (novoColaborador.funcao === "deletar") {
                Plantonistas.splice(novoColaborador.index, 1);
            } else {
                try {
                    setPlantonistas((prevPlantonistas) => [...prevPlantonistas, novoColaborador]);
                } catch (error) {
                    console.error("Erro ao adicionar novo colaborador:", error);
                }
            }
        }
        setNovoColaborador({
            uuid: null,
            plantaoId: dados.id,
            pessoaId: null,
            equipeId: dados.equipeId,
            falta: false,
            ferias: false,
            ativo: false,
            cargo: null,
            funcao: "adicionar",
        });

        setPessoa({});
    };
    const confirmarAssinatura = async () => {
        const result = await UsuarioAPI.assinatura(dados.empresaId, dados.usuario, dados.senha, dados.uuid, "Plantao");
        if (result.type === "success") {
            setFlashMessage(result.message, result.type);

            setDados((prevDados) => {
                return {
                    ...prevDados,
                    assinado: result.data.tabelaAssinada.assinado,
                    dataAssinatura: moment(result.data.tabelaAssinada.dataAssinatura).format("YYYY-MM-DD HH:mm:ss"),
                    pessoaIdAssinatura: result.data.tabelaAssinada.pessoaIdAssinatura,
                    usuarioAssinado: result.data.descricao,
                    usuario: null,
                    senha: null,
                };
            });
        } else if (result.type === "warning") {
            setFlashMessage(result.message, result.type);
        } else {
            setFlashMessage(result.response.data.message, result.response.data.type);
        }
    };
    async function handleChange(e) {
        const { name, value, checked, type, options, selectedIndex } = e.target;

        if (name === "ativo" || name === "concluido") {
            setDados({ ...dados, [name]: checked });
        } else if (name === "tipo") {
            setDados({
                ...dados,
                [name]: options[selectedIndex].text,
            });
        } else if (name.startsWith("novoColaborador")) {
            if (name.split(".")[1] === "ativo" || name.split(".")[1] === "falta" || name.split(".")[1] === "ferias") {
                setNovoColaborador({ ...novoColaborador, [name.split(".")[1]]: checked });
            } else if (name.split(".")[1] === "cargo") {
                setNovoColaborador({
                    ...novoColaborador,
                    cargo: options[selectedIndex].text,
                });
            }
        } else if (name.startsWith("Pessoa")) {
            setPessoa({
                ...pessoa,
                [name.split(".")[1]]: value,
            });

            const result = await consultaDatalistColaborador(dados.empresaId, value, true, null);
            setOptionsColaborador(result);

            const selectedId = document.querySelector(`datalist option[value="${value}"]`)?.dataset.id;
            //verifica se existe
            setNovoColaborador((prevState) => ({
                ...prevState,
                pessoaId: selectedId ?? null,
                cargo: document.getElementById("novoColaborador.cargo").value,
            }));
        } else {
            setDados({ ...dados, [name]: value });
        }
    }
    async function handleOnBlur(e) {
        const { name, value, checked, type, options, selectedIndex } = e.target;
        //verifica duplicidade
        if (name.startsWith("Pessoa.")) {
            if (name.split(".")[1] === "descricao") {
                Plantonistas.forEach((pl, index) => {
                    if (parseInt(pl.pessoaId) === parseInt(novoColaborador.pessoaId)) {
                        setNovoColaborador((prevState) => ({
                            ...prevState,
                            index: index,
                            uuid: pl.uuid,
                            cargo: pl.cargo,
                            ferias: pl.ferias,
                            falta: pl.falta,
                            ativo: pl.ativo,
                            funcao: "editar",
                        }));
                    }
                });
            }
        }
    }

    async function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();
        handleSubmit(dados);
    };

    return (
        <Tabs>
            <form onSubmit={submit}>
                <h1>Plantão nº {dados.codigo ? dados.codigo : null}</h1>
                <TabList>
                    <Tab disabled={!tabEnabled.cabeçalho} title={"Cabeçalho do Plantão"}>
                        Cabeçalho
                    </Tab>
                    <Tab disabled={!tabEnabled.integrantes} title={"Integrantes do Plantão"}>
                        Integrantes
                    </Tab>
                    <Tab disabled={!tabEnabled.observacao} title={"Observações durante o Plantão"}>
                        Observações
                    </Tab>
                    <Tab disabled={!tabEnabled.assinatura} title={"Assinatura do Plantão"}>
                        Assinatura
                    </Tab>
                </TabList>
                <TabPanel>
                    {btnText !== "Cadastrar" && (
                        <div>
                            <Input
                                text="Data Cadastro"
                                type="datetime-local"
                                name="createdAt"
                                placeholder="última atualização"
                                title="Data de inserção do cadastro no sistema"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={moment(dados.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                                readonly={true}
                                inputWidth={"150px"}
                            />
                            <Input
                                text="Data Atualização"
                                type="datetime-local"
                                name="updatedAt"
                                placeholder="última atualização"
                                title="Data da última atualização dos dados"
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={moment(dados.updatedAt).format("YYYY-MM-DD HH:mm:ss")}
                                readonly={true}
                                inputWidth={"150px"}
                            />
                            {parametroUsuario.plantaoPermiteConcluir === true && (
                                <>
                                    <Checkbox
                                        title="Informa que o plantão foi concluído"
                                        text="Concluído"
                                        name="concluido"
                                        type="checkbox"
                                        handleOnChange={handleChange}
                                        value={dados.concluido}
                                        disabled={!parametroUsuario.plantaoPermiteConcluir}
                                        checked={dados.concluido === true ? "checked" : ""}
                                    />
                                    <Input
                                        text="Data Conclusão"
                                        type="datetime-local"
                                        name="concluidoData"
                                        placeholder=""
                                        title="Data de concusão do plantão"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        value={moment(dados.concluidoData).format("YYYY-MM-DD HH:mm:ss")}
                                        readonly={true}
                                        inputWidth={"150px"}
                                    />
                                    <Input
                                        text="Usuário"
                                        type="text"
                                        name="pessoaConcluido.descricao"
                                        placeholder="nome do usuário"
                                        title="Digite o nome do prazo de pagamento ou recebimento"
                                        handleOnChange={handleChange}
                                        handleOnFocus={handleOnFocus}
                                        handleOnBlur={handleOnBlur}
                                        inputWidth="300px"
                                        value={pessoaConcluido.descricao || ""}
                                        required={false}
                                        readonly={true}
                                    />
                                </>
                            )}
                        </div>
                    )}
                    <div>
                        <Input
                            text="Data"
                            type="date"
                            name="data"
                            placeholder="Data"
                            title="Data de abertura do Plantão"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            readonly={!parametroUsuario.plantaoAlteraData}
                            value={(dados.data && retornaData(dados.data)) || retornaData(moment())}
                        />

                        <Input
                            text="Início"
                            type="datetime-local"
                            name="inicio"
                            placeholder=""
                            title="Início do Plantão"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={moment(dados.inicio).format("YYYY-MM-DD HH:mm:ss")}
                        />
                        <Input
                            text="Término"
                            type="datetime-local"
                            name="termino"
                            placeholder=""
                            title="Término do Plantão"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={moment(dados.termino).format("YYYY-MM-DD HH:mm:ss")}
                        />
                        <Select
                            name="tipo"
                            title="Selecione o tipo"
                            text="Tipo"
                            inputWidth="150px"
                            options={EnumTipo}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.tipo}
                            isEnum={true}
                            notOptNull={true}
                            required={true}
                        />
                        <Select
                            name="equipeId"
                            title="Selecione a equipe"
                            text="Equipe"
                            inputWidth="150px"
                            options={OptionsEquipe}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.equipeId}
                            isEnum={false}
                            notOptNull={true}
                            required={true}
                        />
                    </div>
                </TabPanel>
                <TabPanel>
                    <div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "1rem",
                                padding: " 0.7em",
                            }}
                        >
                            {" "}
                            <DataList
                                text="Colaborador"
                                name="Pessoa.descricao"
                                inputWidth="300px"
                                filteredOptions={OptionsColaborador}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={pessoa.descricao || ""}
                            />
                            <Select
                                name="novoColaborador.cargo"
                                text="Cargo"
                                options={EnumCargo}
                                handleOnChange={handleChange}
                                handleOnFocus={handleOnFocus}
                                handleOnBlur={handleOnBlur}
                                value={novoColaborador.cargo}
                                isEnum={true}
                            />
                            <Checkbox
                                title="Faltou ao plantão"
                                text="Falta"
                                name="novoColaborador.falta"
                                type="checkbox"
                                handleOnChange={handleChange}
                                value={novoColaborador.falta}
                                checked={novoColaborador.falta === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Férias"
                                text="Férias"
                                name="novoColaborador.ferias"
                                type="checkbox"
                                handleOnChange={handleChange}
                                value={novoColaborador.ferias}
                                checked={novoColaborador.ferias === true ? "checked" : ""}
                            />
                            <Checkbox
                                title="Ativa"
                                text="Ativo"
                                name="novoColaborador.ativo"
                                type="checkbox"
                                handleOnChange={handleChange}
                                value={novoColaborador.ativo}
                                checked={novoColaborador.ativo === true ? "checked" : ""}
                            />
                            <Stack direction="row" spacing={1}>
                                <Button
                                    variant="contained"
                                    color="inherit"
                                    onClick={() => {
                                        setNovoColaborador({
                                            uuid: null,
                                            pessoaId: null,
                                            plantaoId: dados.id,
                                            equipeId: dados.equipeId,
                                            falta: false,
                                            ferias: false,
                                            cargo: null,
                                            funcao: "adicionar",
                                        });
                                        setOptionsColaborador({
                                            id: null,
                                            descricao: null,
                                        });
                                        setPessoa({});
                                    }}
                                    style={{ marginLeft: "10px" }}
                                >
                                    Limpar
                                </Button>
                                <Button
                                    variant="contained"
                                    color={novoColaborador.funcao === "deletar" ? "error" : "info"}
                                    onClick={() => handleColaborador(novoColaborador)}
                                    disabled={!novoColaborador.pessoaId ? true : false}
                                    style={{ marginLeft: "5px" }}
                                >
                                    {novoColaborador.funcao}
                                </Button>
                            </Stack>
                        </div>
                        <div>
                            <table className={formStyles.table}>
                                <thead>
                                    <tr>
                                        <th className={formStyles.th} align="left">
                                            CPF
                                        </th>
                                        <th className={formStyles.th} align="left">
                                            Colaborador
                                        </th>
                                        <th className={formStyles.th} align="left">
                                            Cargo
                                        </th>
                                        <th className={formStyles.th} align="left">
                                            Falta
                                        </th>
                                        <th className={formStyles.th} align="left">
                                            Ferias
                                        </th>
                                        <th className={formStyles.th} align="left">
                                            Ativo
                                        </th>
                                        <th className={formStyles.th} align="center">
                                            Ações
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className={formStyles.tbody}>
                                    <>
                                        {Object.keys(Plantonistas).map((key, index, array) => (
                                            <tr className={formStyles.tr} key={Plantonistas[key]?.uuid || index}>
                                                <td
                                                    className={formStyles.td}
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    {Plantonistas[key].Pessoa?.CPForCNPJ}
                                                </td>
                                                <td
                                                    className={formStyles.td}
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    {Plantonistas[key].Pessoa?.descricao}
                                                </td>
                                                <td className={formStyles.td}>{Plantonistas[key].cargo}</td>
                                                <td className={formStyles.td}>{Plantonistas[key].falta ? "Sim" : "Não"}</td>
                                                <td className={formStyles.td}>{Plantonistas[key].ferias ? "Sim" : "Não"}</td>
                                                <td className={formStyles.td}>{Plantonistas[key].ativo ? "Sim" : "Não"}</td>
                                                <td className={formStyles.td}>
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() => {
                                                            setNovoColaborador({
                                                                index: index,
                                                                uuid: Plantonistas[key]?.uuid,
                                                                pessoaId: Plantonistas[key].pessoaId,
                                                                plantaoId: Plantonistas[key].plantaoId || dados.id,
                                                                equipeId: Plantonistas[key].equipeId,
                                                                cargo: Plantonistas[key].cargo,
                                                                falta: Plantonistas[key].falta,
                                                                ferias: Plantonistas[key].ferias,
                                                                ativo: Plantonistas[key].ativo,
                                                                funcao: "editar",
                                                            });
                                                            setPessoa({
                                                                id: Plantonistas[key].Pessoa.id,
                                                                descricao: Plantonistas[key].Pessoa.nomePrincipal,
                                                            });
                                                        }}
                                                    >
                                                        Editar
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        onClick={() => {
                                                            setNovoColaborador({
                                                                index: index,
                                                                uuid: Plantonistas[key].uuid,
                                                                pessoaId: Plantonistas[key].pessoaId,
                                                                plantaoId: Plantonistas[key].plantaoId || dados.id,
                                                                equipeId: Plantonistas[key].equipeId,
                                                                cargo: Plantonistas[key].cargo,
                                                                falta: Plantonistas[key].falta,
                                                                ferias: Plantonistas[key].ferias,
                                                                ativo: Plantonistas[key].ativo,
                                                                funcao: "deletar",
                                                            });
                                                            setPessoa({
                                                                id: Plantonistas[key].Pessoa.id,
                                                                descricao: Plantonistas[key].Pessoa.nomePrincipal,
                                                            });
                                                        }}
                                                        style={{
                                                            marginLeft: "5px",
                                                        }}
                                                    >
                                                        Excluir
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div>
                        <TextArea
                            text="Observação"
                            type="text"
                            name="observacao"
                            placeholder="Observações durante o plantão"
                            title="Observações ocorridas durante o plantão"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.observacao || ""}
                            textAlign="left"
                            linhas="4"
                            colunas="120"
                        />
                    </div>
                </TabPanel>
                <TabPanel>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "1rem",
                            padding: " 0.7em",
                        }}
                    >
                        <Checkbox
                            title="Plantão assinado"
                            text="Assinado"
                            name="assinado"
                            type="checkbox"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.assinado}
                            disabled={true}
                            checked={dados.assinado === true ? "checked" : ""}
                        />
                        {dados.assinado ? (
                            <>
                                <Input
                                    text="Usuário"
                                    type="text"
                                    name="pessoaAssinatura.descricao"
                                    inputWidth="350px"
                                    readonly={true}
                                    value={pessoaAssinatura.descricao || null}
                                />
                                <Input
                                    text="Data"
                                    type="datetime"
                                    name="pessoaAssinatura.descricao"
                                    readonly={true}
                                    value={
                                        dados.dataAssinatura ? moment(dados.dataAssinatura).format("DD/MM/YYYY HH:mm:ss") : null
                                    }
                                />
                            </>
                        ) : (
                            <>
                                <Input
                                    text="Usuário"
                                    type="text"
                                    name="usuario"
                                    title={
                                        parametroUsuario.plantaoAssinatura
                                            ? "Digite o nome do usuário"
                                            : "Usuário não tem permissão para assinar o plantão"
                                    }
                                    placeholder={parametroUsuario.plantaoAssinatura ? "Digite o usuário" : "Sem Permissão"}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    disabled={!parametroUsuario.plantaoAssinatura}
                                    value={dados.usuario || null}
                                />
                                <Input
                                    text="Senha"
                                    type="password"
                                    name="senha"
                                    title={
                                        parametroUsuario.plantaoAssinatura
                                            ? "Digite a senha"
                                            : "Usuário não tem permissão para assinar o plantão"
                                    }
                                    placeholder={parametroUsuario.plantaoAssinatura ? "Digite a senha" : "Sem Permissão"}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    disabled={!parametroUsuario.plantaoAssinatura}
                                    value={dados.senha || null}
                                />

                                <Stack
                                    direction="row"
                                    spacing={2}
                                    style={{
                                        marginLeft: " 0.7em",
                                    }}
                                >
                                    <Tooltip arrow placement="top-end" title={`Confirmar assinatura`}>
                                        <Button
                                            variant="contained"
                                            color="info"
                                            disabled={
                                                !dados.usuario || (!dados.senha && true) || !parametroUsuario.plantaoAssinatura
                                            }
                                            onClick={() => confirmarAssinatura()}
                                        >
                                            Confirmar
                                        </Button>
                                    </Tooltip>
                                </Stack>
                            </>
                        )}
                    </div>
                </TabPanel>
                <div
                    style={{
                        padding: " 0.7em",
                    }}
                >
                    <Stack direction="row" spacing={1}>
                        <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                            Voltar
                        </Button>
                        <Button type="submit" variant="contained" color="success">
                            {btnText}
                        </Button>
                    </Stack>
                </div>
            </form>
        </Tabs>
    );
}
export default Form;
