// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Select_form_control__7zgkc {
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 1em;
}

.Select_form_control__7zgkc label {
    margin-left: 0.7em;
    margin-bottom: 0.3em;
    font-weight: bold;
    font-size: 0.8em;
}

.Select_form_control__7zgkc select {
    padding: 0.7em;
    border: 1px solid #777;
    border-radius: 5px;
    margin-left: 0.7em;
    background-color: #ffe7e7;
}

.Select_form_control__7zgkc.Select_dark__\\+dkxD select {
    background-color: #484848;
    color: #ffe7e7;
}
`, "",{"version":3,"sources":["webpack://./src/components/form/Select.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,sBAAsB;IACtB,kBAAkB;IAClB,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB","sourcesContent":[".form_control {\n    display: inline-flex;\n    flex-direction: column;\n    margin-bottom: 1em;\n}\n\n.form_control label {\n    margin-left: 0.7em;\n    margin-bottom: 0.3em;\n    font-weight: bold;\n    font-size: 0.8em;\n}\n\n.form_control select {\n    padding: 0.7em;\n    border: 1px solid #777;\n    border-radius: 5px;\n    margin-left: 0.7em;\n    background-color: #ffe7e7;\n}\n\n.form_control.dark select {\n    background-color: #484848;\n    color: #ffe7e7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form_control": `Select_form_control__7zgkc`,
	"dark": `Select_dark__+dkxD`
};
export default ___CSS_LOADER_EXPORT___;
