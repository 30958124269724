import api from ".";

export const listarProdutos = (start, size, filters, filtersMode, globalFilter, sorting, tabelaPrecoId) =>
    api.get("/tabelaPreco/listarProdutos", {
        params: {
            start,
            size,
            filters,
            filtersMode,
            globalFilter,
            sorting,
            tabelaPrecoId,
        },
    });

export const tabelaPrecoProduto = async (novoProduto) => {
    try {
        const { data } = await api.post("/tabelaPreco/produto", novoProduto);
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};

export const duplicidade = async (empresaId, tabelaId, produtoId, embalagemId) => {
    try {
        const { data } = await api.get("/tabelaPreco/duplicidade/", {
            params: { empresaId, tabelaId, produtoId, embalagemId },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};

export const deletarProduto = (uuid) => api.post("/tabelaPreco/deletarProdutos", { uuid });
