import { useState, useEffect } from "react";
import "react-tabs/style/react-tabs.css";
import "moment/locale/pt-br";

import { Stack, Button } from "@mui/material";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";
import * as TabelaAPI from "../../../api/tabela";

import Input from "../../form/Input";
import InputNumber from "../../form/InputNumber";
import Select from "../../form/Select";
import Checkbox from "../../form/Checkbox";
import SelectEmpresa from "../../form/SelectEmpresa";

/* utils */
import { calculaPrazoMedio } from "../../../utils/financeiro";
import { formatarNumero, formatarNumeroDecimal } from "../../../utils/masks";

// import * as FinanceiroAPI from "../../../api/financeiro";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, OptionsEmpresa, EnumTipo }) {
    const [dados, setDados] = useState({});
    const navigate = useNavigateParams();
    const { setFlashMessage } = useFlashMessage();
    //const [preview, setPreview] = useState([]);

    //define o estado inicial do objeto
    if (!dados.empresaId) {
        dados.empresaId = localStorage.getItem("empresaId");
        dados.ativo = true;
    }

    useEffect(() => {
        setDados(formData || {});
    }, [formData]);

    function handleChange(e) {
        if (e.target.name === "ativo" || e.target.name === "entrada") {
            setDados({ ...dados, [e.target.name]: e.target.checked });
        } else if (e.target.name === "tipo") {
            setDados({
                ...dados,
                [e.target.name]: e.target.options[e.target.selectedIndex].text,
            });
        } else if (e.target.name === "percentualMinimoEntrada") {
            setDados({
                ...dados,
                [e.target.name]: formatarNumeroDecimal(e.target.value),
            });
        } else {
            if (e.target.name === "parcela" || e.target.name === "primeiraParcela" || e.target.name === "diasEntreParcelas") {
                if (parseInt(document.getElementById("parcela").value) > 0) {
                    if (parseInt(document.getElementById("parcela").value) === 1) {
                        dados.diasEntreParcelas = 0;
                    }

                    dados.prazoMedio = calculaPrazoMedio(
                        parseInt(document.getElementById("parcela").value),
                        parseInt(document.getElementById("primeiraParcela").value),
                        parseInt(document.getElementById("diasEntreParcelas").value)
                    );
                }
            }

            setDados({ ...dados, [e.target.name]: e.target.value });
        }
    }

    async function handleOnBlur(e) {
        if (e.target.name === "descricao" && btnText === "Cadastrar") {
            //verifica duplicidade
            const duplicidade = await TabelaAPI.duplicidadeDescricao(document.getElementById("empresaId").value, "pagamentoPrazo", e.target.value);

            if (duplicidade?.uuid) {
                setFlashMessage("Registro em duplicidade!", "warning");
                return navigate(`/Prazo/CreateOrEdit`, {
                    uuid: duplicidade.uuid,
                });
            }
        }
    }
    function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();
        handleSubmit(dados);
    };

    return (
        <form onSubmit={submit}>
            <h1>Prazo de pagamentos ou recebimentos</h1>
            <div>
                <SelectEmpresa
                    text="Selecione a Empresa"
                    name="empresaId"
                    options={OptionsEmpresa}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.empresaId || ""}
                    required
                    notOptNull={true}
                />
                <Input
                    text="Prazo"
                    type="text"
                    name="descricao"
                    placeholder="nome do prazo"
                    title="Digite o nome do prazo de pagamento ou recebimento"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.descricao || ""}
                    required
                />
                <Input
                    text="Nível"
                    type="number"
                    name="nivelVenda"
                    placeholder="nível do prazo"
                    title="Digite o nível do prazo de pagamento ou recebimento, esse nível faz referência ao nível da forma de pagamento, será permitido niveis iguais ou maior que o estabelecido na forma de pagamento ou recebimento"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.nivelVenda || ""}
                    required
                />
                <Select
                    name="tipo"
                    title={"Define o tipo do prazo de pagamento, recebimento ou ambos"}
                    text="Tipo"
                    options={EnumTipo}
                    handleOnChange={handleChange}
                    value={dados.tipo}
                    isEnum={true}
                    notOptNull={true}
                />
            </div>
            <div>
                <Input
                    text="Parcelas"
                    type="number"
                    name="parcela"
                    placeholder="parcelas"
                    inputWidth="100px"
                    title="Digite número de parcelas"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.parcela || ""}
                    min={1}
                />
                <Input
                    text="1ª Parcela"
                    type="number"
                    name="primeiraParcela"
                    placeholder="dias"
                    inputWidth="100px"
                    title="informe a quantidade de dias da primeira parcela"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.primeiraParcela || ""}
                />
                <Input
                    text="Dias entre parcelas"
                    type="number"
                    name="diasEntreParcelas"
                    placeholder="dias"
                    inputWidth="100px"
                    title="informe a quantidade de dias entre as parcelas"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.diasEntreParcelas || ""}
                />
                <Input
                    text="Prazo Médio"
                    type="number"
                    name="prazoMedio"
                    placeholder="média"
                    inputWidth="100px"
                    title="prazo médio em dias das parcelas"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.prazoMedio || ""}
                    readonly={true}
                />
                <Checkbox
                    title="Quando marcado a forma de pagamento ou recebimento esta ATIVO"
                    text="Ativo"
                    name="ativo"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.ativo}
                    checked={dados.ativo === true ? "checked" : ""}
                />
            </div>
            <div>
                <Checkbox
                    title="Informa que haverá um valor de entrada, e que as parcelas serão calculadas através da diferença entre o valor total menos a entrada"
                    text="Entrada"
                    name="entrada"
                    type="checkbox"
                    handleOnChange={handleChange}
                    value={dados.entrada}
                    checked={dados.entrada === true ? "checked" : ""}
                />
                <InputNumber
                    text="Percentual"
                    type="number"
                    name="percentualMinimoEntrada"
                    placeholder="entrada %"
                    inputWidth="100px"
                    title="informe o percentual mínimo de entrada"
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={dados.percentualMinimoEntrada ? formatarNumero(dados.percentualMinimoEntrada) : ""}
                />
            </div>
            <div>
                <Stack direction="row" spacing={1}>
                    <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar)}>
                        Voltar
                    </Button>
                    <Button type="submit" variant="contained" color="success">
                        {btnText}
                    </Button>
                </Stack>
            </div>
        </form>
    );
}
export default Form;
