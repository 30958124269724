import api from ".";

const metodos = (tipo) => {
    return {
        consulta: (uuid) => api.get(`/${tipo}/editar/${uuid}`),
        editar: (dados) => api.post(`/${tipo}/editar/`, dados),
        editarComPatch: (dados) => api.patch(`/${tipo}/editar/`, dados),
        editarComFormData: (dados) =>
            api.post(`/${tipo}/editar/`, dados, {
                headers: { "Content-Type": "multipart/form-data" },
            }),
        cadastrarComFormData: (dados) =>
            api.post(`/${tipo}/cadastrar/`, dados, {
                headers: { "Content-Type": "multipart/form-data" },
            }),
        cadastrar: (dados) => api.post(`/${tipo}/cadastrar/`, dados),
        deletar: (selectedRow) => api.post(`/${tipo}/deletar/`, selectedRow),
        listar: (start, size, filters, filtersMode, globalFilter, sorting, tipoConsulta, operacaoId) =>
            api.get(`/${tipo}/listar/`, {
                params: {
                    start,
                    size,
                    filters,
                    filtersMode,
                    globalFilter,
                    sorting,
                    tipoConsulta,
                    operacaoId,
                },
            }),
        edit: (uuid, operacaoId) =>
            api.get(`/${tipo}/edit/`, {
                params: {
                    uuid,
                    operacaoId,
                },
            }),

        duplicidade: (descricao) =>
            api.get(`/${tipo}/duplicidade/`, {
                params: {
                    descricao,
                },
            }),

        /*
            Esse { responseType: "arraybuffer" } deve ser o terceiro argumento da requisição post, 
            que é onde as configurações da requisição são passadas. 
        */
        imprimir: (url, obj = {}) => api.post(`/${tipo}/imprimir/?${url.toString()}`, obj, { responseType: "arraybuffer" }),
        imprimirCustos: (url, obj = {}) =>
            api.post(`/${tipo}/imprimirCustos/?${url.toString()}`, obj, { responseType: "arraybuffer" }),
    };
};

const CRUDs = {
    ajuda: { ...metodos("ajuda") },
    ambiente: { ...metodos("ambiente") },
    almoxarifado: { ...metodos("almoxarifado") },
    anilox: { ...metodos("anilox") },
    assinatura: { ...metodos("assinatura") },
    balancete: { ...metodos("balancete") },
    bobina: { ...metodos("bobina") },
    caixa: { ...metodos("caixa") },
    centroCusto: { ...metodos("centroCusto") },
    centroCustoLimite: { ...metodos("centroCustoLimite") },
    cfop: { ...metodos("cfop") },
    cilindro: { ...metodos("cilindro") },
    conta: { ...metodos("conta") },
    cor: { ...metodos("cor") },
    documento: { ...metodos("documento") },
    empresa: { ...metodos("empresa") },
    embalagem: { ...metodos("embalagem") },
    equipe: { ...metodos("equipe") },
    forma: { ...metodos("forma") },
    lancamentoConta: { ...metodos("lancamentoConta") },
    mapaAbastecimento: { ...metodos("mapaAbastecimento") },
    maquina: { ...metodos("maquina") },
    menu: { ...metodos("menu") },
    menuSub: { ...metodos("menuSub") },

    movimento: { ...metodos("movimento") },
    movimentoEntradas: { ...metodos("movimentoEntradas") },
    movimentoLIM: { ...metodos("movimentoLIM") },
    movimentoSaidas: { ...metodos("movimentoSaidas") },
    movimentoSER: { ...metodos("movimentoSER") },

    municipio: { ...metodos("municipio") },
    ncm: { ...metodos("ncm") },
    operacao: { ...metodos("operacao") },
    operacaoParametro: { ...metodos("operacaoParametro") },
    pais: { ...metodos("pais") },
    parametro: { ...metodos("parametro") },
    pdc: { ...metodos("PDC") },
    permissoes: { ...metodos("permissoes") },
    pessoaEmpresa: { ...metodos("pessoaEmpresa") },
    produto: { ...metodos("Produto") },
    produtoLIM: { ...metodos("ProdutoLIM") },
    produtoVTR: { ...metodos("ProdutoVTR") },
    plantao: { ...metodos("plantao") },
    prazo: { ...metodos("prazo") },
    produtoModelo: { ...metodos("produtoModelo") },
    tabelaPreco: { ...metodos("tabelaPreco") },
    tributacao: { ...metodos("tributacao") },
    situacao: { ...metodos("situacao") },
    uf: { ...metodos("uf") },
    unidade: { ...metodos("unidade") },
    usuarioPermitido: { ...metodos("usuarioPermitido") },
};
export default CRUDs;
