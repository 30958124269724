import { useState, useEffect, useContext, useMemo } from "react";
import "react-tabs/style/react-tabs.css";
import "moment/locale/pt-br";

/* contexts */
import { Context } from "../../../context/UserContext";

import moment from "moment";
import "moment/locale/pt-br";

import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Stack, Button, Box, Tooltip, IconButton } from "@mui/material";
import {
    Delete,
    Edit,
    Print,
    AddShoppingCart,
    RemoveShoppingCart,
    ShoppingCart,
    ShoppingCartCheckout,
} from "@mui/icons-material";
import { ThemeProvider } from "@mui/material";
/* tema customizado*/
import tableThemeCustom from "../../form/tableThemeCustom";

import formStyles from "../../form/Form.module.css";

//Import Material React Table Translations
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import * as DataListAPI from "../../../api/dataList";
import * as FinanceiroAPI from "../../../api/financeiro";
import * as PessoaAPI from "../../../api/pessoa";
import * as SituacaoAPI from "../../../api/situacao";
import * as TabelaAPI from "../../../api/tabela";

import Input from "../../form/Input";
import TextArea from "../../form/TextArea";
import InputNumber from "../../form/InputNumber";
import Checkbox from "../../form/Checkbox";
import SelectEmpresa from "../../form/SelectEmpresa";
import Select from "../../form/Select";
import DataList from "../../form/DataList";

/* utils */
import { formatarNumero, formatarNumeroDecimal } from "../../../utils/masks";
import { retornaDataHora, retornaData } from "../../../utils/retornaDataFormatada";
import { obterPermissoesUsuario, corrigirDataColumnFilters, corrigirDataColumnFiltersObjeto } from "../../../utils/funcoes";

function Form({ token, handleSubmit, formData, btnText, caminhoVoltar, optionsEmpresa, optionsTipo }) {
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();
    const urlParams = new URLSearchParams(window.location.search);

    const params = new URLSearchParams({
        ul: urlParams.get("ul"),
        li: urlParams.get("li"),
        tipo: urlParams.get("tipo"),
        operacao: urlParams.get("operacao"),
        uuid: urlParams.get("uuid"),
        btnText: urlParams.get("btnText"),
    });
    //data atual
    const dataAtual = new Date();

    //datalist pessoa
    const consultaDatalist = async (separador, empresaId, tabela, id, descricao, condicao, filtro, ativo, tipo) => {
        let result = [];
        if (separador === "colaborador") {
            result = await DataListAPI.dataListColaborador(empresaId, filtro, ativo, tipo);
        } else if (separador === "parceiro") {
            result = await DataListAPI.dataList(empresaId, tabela, id, descricao, condicao, filtro);
        } else if (separador === "centroCusto") {
            result = await DataListAPI.dataList(empresaId, tabela, id, descricao, condicao, filtro);
        }
        return result;
    };
    const consultaSituacao = async (empresaId = 1, campo) => {
        const result = await SituacaoAPI.situacoesByCampo(empresaId, campo);
        setOptionsSituacao(result);
    };
    const consultaForma = async (empresaIds) => {
        const result = await FinanceiroAPI.buscaFormaByEmpresaIds(empresaIds);
        setForma(result);
    };
    const consultaFormaById = async (id) => {
        const result = await FinanceiroAPI.buscaFormaById(id);
        setNovoLancamento((prev) => ({
            ...prev,
            pagamentoForma: result,
            contaId: result.contaId || prev.contaId,
        }));
        return result;
    };
    const consultaCentroCustoById = async (id) => {
        const result = await FinanceiroAPI.buscaCentroCustoById(id);
        setCentroCusto(result);
        return result;
    };
    const consultaPrazo = async (empresaIds) => {
        const result = await FinanceiroAPI.buscaPrazoByEmpresaIds(empresaIds);
        setPrazo(result);
    };
    const consultaPrazoById = async (prazoId) => {
        const result = await FinanceiroAPI.buscaPrazoById(prazoId);
        setPagamentoPrazo(result);
        setNovoLancamento((prev) => ({
            ...prev,
            pagamentoPrazo: result,
        }));
        return result;
    };
    const consultaConta = async (empresaIds, caixa) => {
        const result = await FinanceiroAPI.buscaContaByEmpresaIds(empresaIds, caixa);
        caixa === true ? setContasCaixa(result) : setContas(result);
        return result;
    };
    const consultaContaById = async (contaId) => {
        const result = await FinanceiroAPI.buscaContaById(contaId);
        setConta(result.data);
        setNovoLancamento((prev) => ({
            ...prev,
            Conta: result.data,
        }));
        return result;
    };

    const buscaSaldoByContaId = async (contaId) => {
        if (!contaId) {
            return null;
        }
        const result = await FinanceiroAPI.buscaSaldoByContaId(contaId);

        return result;
    };
    const consultaCentroCusto = async (empresaIds, tipo) => {
        const result = await FinanceiroAPI.buscaCentroCustoByEmpresaIds(empresaIds, tipo);
        setCentroCusto(result);
        return result;
    };

    //variaveis
    const [dados, setDados] = useState(formData || {});
    const [caixaLancamentos, setCaixaLancamento] = useState([]);
    const { parametro } = useContext(Context);

    //select
    const [optionsSituacao, setOptionsSituacao] = useState([]);
    const [optionsConta, setContas] = useState([]);
    const [optionsContaCaixa, setContasCaixa] = useState([]);
    const [optionsForma, setForma] = useState({});
    const [optionsPrazo, setPrazo] = useState({});

    const [pessoa, setPessoa] = useState({});
    const [pagamentoPrazo, setPagamentoPrazo] = useState(formData?.pagamentoPrazo);
    const [centroCusto, setCentroCusto] = useState(formData?.centroCusto);
    const [conta, setConta] = useState(formData?.Conta);
    const pessoaId = parametro.pessoaId || localStorage.getItem("pessoaId");

    const [novoLancamento, setNovoLancamento] = useState({
        uuid: null,

        caixaId: dados.id,
        pessoaId: parametro.pessoaId,
        centroCustoId: parametro.centroCustoPDV,
        contaId: null,
        formaId: null,
        prazoId: parametro.transferenciaPrazo,
        situacaoId: 1,

        tipo: "Receber",
        valorOriginal: null,
        historico: null,
        dataLancamento: dados.data,
        dataVencimento: dados.data,
        //objetos
        Pessoa: pessoa,
        centroCusto: centroCusto,
        Conta: {},
        pagamentoForma: {},
        pagamentoPrazo: {},
        Situacao: {},

        ativo: true,
        funcao: "Adicionar",
    });

    //define o estado inicial do objeto
    if (!dados.empresaId) {
        dados.supervisorId = localStorage.getItem("pessoaId"); //informa a pessoa que esta abrindo o caixa

        dados.empresaId = localStorage.getItem("empresaId");
        dados.data = moment(dataAtual).startOf("day").format("YYYY-MM-DD"); //obtem a data atual
        dados.situacaoId = 1; //isso por ser abertura do caixa
        dados.abertura = dataAtual;
        dados.ativo = true;

        novoLancamento.situacaoId = 1; //o lancamento será Aberto
        novoLancamento.pessoaId = pessoaId;
        novoLancamento.tipo = "Receber";
        novoLancamento.prazoId = parametro.transferenciaPrazo;
        novoLancamento.centroCustoId = parametro.centroCustoPDV;
    }
    //obtem o array de empresas vinculadas ou coloca a empresaId no array
    const empresaIds = (() => {
        const ids = localStorage.getItem("empresaIds");
        if (ids) {
            try {
                const parsedIds = JSON.parse(ids);
                return Array.isArray(parsedIds) && parsedIds.length > 0 ? parsedIds : [localStorage.getItem("empresaId")];
            } catch (error) {
                console.warn("Erro ao analisar empresaIds, usando empresaId:", error);
                return [localStorage.getItem("empresaId")];
            }
        }
        return [localStorage.getItem("empresaId")];
    })();

    //configurações
    const [operacao, setOperacao] = useState({});
    const [parametroPessoa, setParametroPessoa] = useState({});
    const [operacaoParametros, setOperacaoParametros] = useState({});

    //datalist
    const [Colaborador, setColaborador] = useState(formData?.Colaborador);
    const [OptionsColaborador, setOptionsColaborador] = useState([]);
    const [Parceiro, setParceiro] = useState(formData?.Pessoa);
    const [OptionsParceiro, setOptionsParceiro] = useState([]);

    const [optionsCentroCusto, setOptionsCentroCusto] = useState([]);

    useEffect(() => {
        setDados(formData || {});
        setCaixaLancamento(formData?.caixaLancamentos || []);
        setRowCount(formData?.caixaLancamentos?.length);
        setColaborador(formData?.Colaborador);

        consultaForma(empresaIds);
        consultaPrazo(empresaIds);
        //consultaCentroCusto(empresaIds, novoLancamento.tipo);
        consultaSituacao(1, "caixa");
    }, [formData]);
    useEffect(() => {
        const fetchData = async () => {
            const result = await PessoaAPI.pessoaById(pessoaId);
            setParceiro(result.data);
            setPessoa(result.data);
            setNovoLancamento((prev) => ({ ...prev, pessoaId: pessoaId, Pessoa: result.data }));
        };

        pessoaId && fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pessoaId]);
    useEffect(() => {
        setDados((prev) => ({
            ...prev, // Mantém as outras propriedades do objeto
            caixaLancamentos: caixaLancamentos, // Adiciona o novo array ou atualiza o existente
        }));
    }, [caixaLancamentos]);
    useEffect(() => {
        consultaConta([dados.empresaId], false);
        consultaConta([dados.empresaId], true);
    }, [dados.empresaId]);

    useEffect(() => {
        if (novoLancamento.formaId) {
            consultaFormaById(novoLancamento.formaId);
        }
    }, [novoLancamento.formaId]);
    useEffect(() => {
        if (novoLancamento.prazoId) {
            consultaPrazoById(novoLancamento.prazoId);
        }
    }, [novoLancamento.prazoId]);
    useEffect(() => {
        if (novoLancamento.centroCustoId) {
            consultaCentroCustoById(novoLancamento.centroCustoId);
        }
    }, [novoLancamento.centroCustoId]);
    useEffect(() => {
        if (novoLancamento.contaId && !dados.id) {
            consultaContaById(novoLancamento.contaId);
        }
    }, [novoLancamento.contaId]);

    useEffect(() => {
        setNovoLancamento((prev) => ({
            ...prev,
            pessoaId: pessoaId,
            Pessoa: pessoa,
            centroCustoId: parametro.centroCustoPDV,
            prazoId: parametro.transferenciaPrazo,
        }));
    }, [parametro]);

    const fetchData = async (grid) => {
        if (!caixaLancamentos.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }
        if (dados.id) {
            try {
                FinanceiroAPI.listarCaixaLancamento(
                    pagination.pageIndex * pagination.pageSize,
                    pagination.pageSize,
                    JSON.stringify(columnFilters ?? []),
                    JSON.stringify(columnFilterFns ?? []),
                    globalFilter ?? "",
                    JSON.stringify(sorting ?? []),
                    dados.id ?? ""
                ).then((response) => {
                    setCaixaLancamento(response.data.data);
                    //setRowCount(response.data.meta.totalRowCount);
                });
            } catch (error) {
                setIsError(true);
                console.error(error);
                return;
            }
        }

        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
    };

    async function handleChange(e) {
        const { name, value, checked, type } = e.target;

        const camposBooleanos = ["ativo", "conferido"];
        const camposNumericos = ["troco", "saldoAbertura", "saldoFechamento"];

        if (camposBooleanos.includes(name)) {
            setDados({ ...dados, [name]: checked });
        } else if (camposNumericos.includes(name)) {
            const valorFormatado = formatarNumeroDecimal(value);
            setDados((prev) => ({
                ...prev,
                [name]: valorFormatado,
            }));
        } else if (name.startsWith("Colaborador.")) {
            setColaborador({
                ...Colaborador,
                [name.split(".")[1]]: value,
            });

            //carrega o datalist
            const result = await consultaDatalist(
                "colaborador",
                dados.empresaId,
                null,
                null,
                null,
                null,
                value,
                true,
                "vendaInterna"
            );
            setOptionsColaborador(result);

            const selectedId = document.querySelector(`datalist option[value="${value}"]`)?.dataset.id;
            setDados((prev) => ({
                ...prev,
                colaboradorId: selectedId ?? null,
            }));
        } else if (name.startsWith("Parceiro.")) {
            setParceiro({
                ...Parceiro,
                [name.split(".")[1]]: value,
            });

            //carrega o datalist
            const result = await consultaDatalist(
                "parceiro",
                dados.empresaId,
                "Pessoa",
                null,
                "descricao",
                dados.tipo,
                value,
                true,
                null
            );
            setOptionsParceiro(result);

            const selectedId = document.querySelector(`datalist option[value="${value}"]`)?.dataset.id;
            const data = selectedId ? await PessoaAPI.pessoaById(selectedId) : null;

            setNovoLancamento((prev) => ({
                ...prev,
                pessoaId: selectedId ?? pessoaId,
                Pessoa: data,
            }));
        } else if (name.startsWith("centroCusto.")) {
            setCentroCusto({
                ...centroCusto,
                [name.split(".")[1]]: value,
            });

            //carrega o datalist
            const result = await consultaDatalist(
                "centroCusto",
                dados.empresaId,
                "centroCusto",
                "id",
                "descricao",
                novoLancamento.tipo,
                value,
                true,
                null
            );
            setOptionsCentroCusto(result);
            const selectedId = document.querySelector(`datalist option[value="${value}"]`)?.dataset.id;
            const data = selectedId ? await FinanceiroAPI.buscaCentroCustoById(selectedId) : null;

            setNovoLancamento((prev) => ({
                ...prev,
                centroCustoId: selectedId ?? parametro.centroCustoPDV,
                centroCusto: data,
            }));
        } else if (name.startsWith("novoLancamento.")) {
            if (name.split(".")[1] === "valorOriginal") {
                const valorFormatado = formatarNumeroDecimal(value);
                setNovoLancamento((prev) => ({ ...prev, [name.split(".")[1]]: valorFormatado }));
            } else {
                setNovoLancamento((prev) => ({ ...prev, [name.split(".")[1]]: value }));
            }
        } else if (name === "contaId") {
            const saldo = await buscaSaldoByContaId(value);
            setDados({ ...dados, [name]: value });

            setDados((prev) => ({
                ...prev,
                [name]: value,
                saldoAbertura: parseFloat(saldo?.data?.saldo).toFixed(2),
            }));
        } else if (name === "situacaoId") {
            const saldo = await buscaSaldoByContaId(dados.contaId);

            setDados((prev) => ({
                ...prev,
                saldoFechamento: parseFloat(saldo?.data?.saldo).toFixed(2),
                fechamento: dataAtual,
                [name]: value,
            }));
        } else {
            setDados({ ...dados, [name]: value });
        }
    }
    async function handleOnBlur(e) {
        const { name, value, checked, type } = e.target;

        //verifica duplicidade
        if (name === "descricao" && btnText === "Cadastrar") {
            //verifica se o operador de caixa já esta em outro caixa aberto VOU CONSTRUIR
            const duplicidade = await TabelaAPI.duplicidadeDescricao(document.getElementById("empresaId").value, "Bobina", value);

            if (duplicidade?.uuid) {
                setFlashMessage("Registro em duplicidade!", "warning");
                return navigate(`/Bobinas/CreateOrEdit`, {
                    uuid: duplicidade.uuid,
                });
            }
        }
    }
    function handleOnFocus(e) {}

    const submit = (e) => {
        e.preventDefault();
        handleSubmit(dados);
    };
    const handleLancamentos = async () => {
        if (novoLancamento.funcao.toLowerCase() === "editar") {
            if (novoLancamento.uuid) {
                CreateOrEditLancamento(novoLancamento);
            } else {
                setCaixaLancamento((prev) => {
                    const novosItens = [...prev];
                    novosItens[novoLancamento.index] = {
                        ...prev[novoLancamento.index],
                        ...novoLancamento,
                    };
                    return novosItens;
                });
            }
        } else if (novoLancamento.funcao.toLowerCase() === "deletar") {
            if (novoLancamento.uuid) {
                try {
                    const response = await FinanceiroAPI.deletarLancamentoCaixa(novoLancamento.uuid);
                    setFlashMessage(response.data.message, response.data.type);
                    fetchData();
                } catch (error) {
                    console.error(error);
                    setFlashMessage(error.response.data.message, error.response.data.type);
                }
            } else {
                setCaixaLancamento((prevItens) => {
                    const novosItens = [...prevItens];
                    novosItens.splice(novoLancamento.index, 1);
                    return novosItens;
                });
            }
        } else {
            //cadastrar
            if (dados.id) {
                //incluir o numero do movimento ao objeto
                novoLancamento.caixaId = dados.id;
                CreateOrEditLancamento(novoLancamento);
            } else {
                setNovoLancamento((prev) => {
                    const novoEstado = {
                        ...prev,
                        centroCusto: { ...centroCusto }, // Garante que centroCusto seja um novo objeto
                        pagamentoPrazo: { ...pagamentoPrazo },
                    };

                    managerLancamento(novoEstado); // Chama a função com o estado atualizado
                    return novoEstado;
                });
            }
        }

        limparLancamento();
    };
    const managerLancamento = async (novoLancamento) => {
        setCaixaLancamento([...caixaLancamentos, novoLancamento]);
        limparLancamento();
    };
    const CreateOrEditLancamento = async (novoLancamento) => {
        try {
            const result = await FinanceiroAPI.managerCaixaLancamento(novoLancamento);
            setFlashMessage(result.message, result.type);
            //deve atualizar a grid
            dados.id && fetchData();
            limparLancamento();
        } catch (error) {
            console.error(error);
            if (error.response && error.response.status === 422) {
                // Tratar o erro 422, que é específico da validação no servidor
                setFlashMessage("Erro de validação: " + error.response.data.message, "error");
            } else {
                // Tratar outros erros de rede ou servidor
                setFlashMessage("Ocorreu um erro ao processar a solicitação.", "error");
            }
        }
    };
    const handleLancamento = async (originalRow, row, funcao) => {
        // Define os dados para o novo objeto
        const novoLancamentoData = {
            uuid: originalRow.uuid,
            ativo: originalRow.ativo,
            funcao: funcao,
            index: row,
            //objetos
            pagamentoForma: originalRow.pagamentoForma,
            Pessoa: originalRow.Pessoa,
            //campos vinculados
            caixaId: originalRow.caixaId,
            centroCustoId: originalRow.centroCustoId,
            contaId: originalRow.contaId,
            formaId: originalRow.formaId,
            prazoId: originalRow.prazoId,
            situacaoId: originalRow.situacaoId,

            //lancamento
            tipo: originalRow.tipo,
            valorOriginal: originalRow.valorOriginal,
            dataLancamento: originalRow.dataLancamento,
            dataVencimento: originalRow.dataVencimento,
            historico: originalRow.historico,
        };
        // Atualize o estado do novo produto
        setNovoLancamento(novoLancamentoData);

        // Atualize o estado do Parceiro com a descrição
        setParceiro(originalRow.Pessoa);

        return;
    };
    const limparLancamento = () => {
        setNovoLancamento({
            uuid: null,

            caixaId: dados.id,
            pessoaId: parametro.pessoaId,
            centroCustoId: parametro.centroCustoPDV,
            contaId: null,
            formaId: null,
            prazoId: parametro.transferenciaPrazo,
            situacaoId: 1,

            tipo: "Receber",
            valorOriginal: null,
            historico: null,
            dataVencimento: dados.data,
            dataLancamento: dados.data,
            //objetos
            Pessoa: pessoa,
            centroCusto: {},
            Conta: {},
            pagamentoForma: {},
            pagamentoPrazo: {},
            Situacao: {},

            ativo: true,
            funcao: "Adicionar",
        });
        setParceiro(pessoa);
    };

    //data and fetching state
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);

    const [rowCount, setRowCount] = useState(0);

    //table state
    const [columnFilterFns, setColumnFiltersMode] = useState(
        localStorage.columnFilterFns.length > 2 ? JSON.parse(localStorage.columnFilterFns) : []
    );
    const [columnFilters, setColumnFilters] = useState(
        localStorage.columnFilters.length > 2 ? JSON.parse(localStorage.columnFilters) : []
    );
    const [globalFilter, setGlobalFilter] = useState(
        localStorage.globalFilter.length > 2 ? JSON.parse(localStorage.globalFilter) : ""
    );
    const [sorting, setSorting] = useState(localStorage.sorting.length > 2 ? JSON.parse(localStorage.sorting) : []);
    const [pagination, setPagination] = useState({
        pageIndex: JSON.parse(localStorage.pagination).pageIndex || 0,
        pageSize: JSON.parse(localStorage.pagination).pageSize || 5,
    });

    useEffect(() => {
        //funcao para setar localStorage
        localStorage.setItem(
            "pagination",
            JSON.stringify({
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
            })
        );
        localStorage.setItem("columnFilterFns", JSON.stringify(columnFilterFns));
        localStorage.setItem("columnFilters", JSON.stringify(columnFilters));
        localStorage.setItem("globalFilter", JSON.stringify(globalFilter));
        localStorage.setItem("sorting", JSON.stringify(sorting));

        // Formata os filtros de datas apenas se necessário
        const formattedFilters = corrigirDataColumnFilters(columnFilters);

        // Verifica se há mudanças nos filtros formatados
        if (JSON.stringify(formattedFilters) !== JSON.stringify(columnFilters)) {
            setColumnFilters(formattedFilters);
        }

        fetchData();
    }, [columnFilterFns, columnFilters, globalFilter, pagination.pageIndex, pagination.pageSize, sorting]);
    useEffect(() => {
        setRowCount(caixaLancamentos.length);
    }, [caixaLancamentos]);

    const columnsLancamento = useMemo(
        () => [
            {
                id: "lancamento",
                header: "Lançamentos do caixa",
                columns: [
                    {
                        accessorKey: "Pessoa.descricao",
                        header: "Parceiro",
                        muiTableBodyCellProps: ({ row }) => ({
                            onClick: () => {
                                console.info(row.original);
                            },
                        }),
                    },
                    {
                        accessorKey: "tipo",
                        header: "Tipo",
                    },
                    {
                        accessorKey: "pagamentoForma.descricao",
                        header: "Forma",
                    },

                    {
                        accessorKey: "Conta.descricao",
                        header: "Conta",
                    },
                    {
                        accessorKey: "valorOriginal",
                        header: "Valor",
                        muiTableBodyCellProps: {
                            align: "right",
                        },
                        accessorFn: (row) =>
                            row.valor === null
                                ? ""
                                : "R$ " +
                                  parseFloat(row.valorOriginal)?.toLocaleString("pt-BR", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      minimumIntegerDigits: 1,
                                      useGrouping: true,
                                  }),
                    },
                    {
                        accessorKey: "historico",
                        header: "Historico",
                    },
                    {
                        accessorFn: (row) => new Date(row),
                        accessorKey: "dataVencimento",
                        header: "Vencimento",

                        filterVariant: "date",
                        sortingFn: "date",
                        columnFilterModeOptions: [
                            "fuzzy",
                            "equals",
                            "between",
                            "betweenInclusive",
                            "greaterThanOrEqualTo",
                            "lessThanOrEqualTo",
                        ],
                        muiTableBodyCellProps: ({ row }) => ({
                            align: "center",
                            onClick: () => {},
                        }),
                        muiTableHeadCellFilterTextFieldProps: {
                            type: "date",
                            InputLabelProps: {
                                shrink: true,
                            },
                            sx: {
                                minWidth: "200px",
                            },
                        },
                        Cell: ({ renderedCellValue, row }) => {
                            return (
                                <span>
                                    {row.original.dataVencimento
                                        ? moment.utc(row.original.dataVencimento).format("DD/MM/YYYY")
                                        : null}
                                </span>
                            );
                        },
                    },
                    {
                        accessorKey: "pagamentoPrazo.descricao",
                        header: "Prazo",
                    },
                    {
                        accessorKey: "centroCusto.descricao",
                        header: "Centro de Custo",
                    },
                ],
            },

            //column definitions...
        ],
        [] //getCommonEditTextFieldProps
    );

    /* modo tema */
    const theme = tableThemeCustom();
    return (
        <div>
            <form onSubmit={submit}>
                <div className={formStyles.stiloPadrao}>
                    {JSON.parse(localStorage.getItem("incluir")) === true && (
                        <>
                            <h1>Caixa</h1>
                            <div>
                                <SelectEmpresa
                                    text="Selecione a Empresa"
                                    name="empresaId"
                                    options={optionsEmpresa}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.empresaId || ""}
                                    required
                                    notOptNull={true}
                                />
                                <Select
                                    name="contaId"
                                    title="Selecione a conta caixa"
                                    text="Conta"
                                    inputWidth="180px"
                                    options={optionsContaCaixa}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.contaId}
                                    isEnum={false}
                                    notOptNull={false}
                                />
                                <Input
                                    text="Data"
                                    type="date"
                                    name="data"
                                    placeholder="Data"
                                    title="Data"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.data}
                                />

                                {btnText !== "Cadastrar" && (
                                    <>
                                        <Input
                                            text="Número"
                                            type="text"
                                            name="contador"
                                            inputWidth="80px"
                                            placeholder="Número"
                                            title={`Número do caixa`}
                                            handleOnChange={handleChange}
                                            handleOnFocus={handleOnFocus}
                                            handleOnBlur={handleOnBlur}
                                            value={dados.contador || ""}
                                            required={false}
                                            readonly={true}
                                            textAlign="center"
                                        />
                                    </>
                                )}

                                <Select
                                    name="situacaoId"
                                    title="Define a situação do movimento"
                                    text="Situação"
                                    inputWidth="170px"
                                    options={optionsSituacao}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.situacaoId}
                                    isEnum={false}
                                    notOptNull={true}
                                />

                                {/* <InputNumber
                            text="Troco"
                            type="text"
                            name="troco"
                            placeholder="Troco R$"
                            inputWidth="100px"
                            title="digite o valor de troco do caixa em R$ (reais)"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={dados.troco ? formatarNumero(dados.troco) : ""}
                        /> */}
                                <Checkbox
                                    title="quando o caixa estiver fechado e devidamente conferido"
                                    text="Conferido"
                                    name="conferido"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    value={dados.conferido}
                                    checked={dados.conferido === true ? "checked" : ""}
                                    disabled={parseInt(dados.situacaoId) === 1}
                                />
                                <Checkbox
                                    title="Ativa quando marcado"
                                    text="Ativo"
                                    name="ativo"
                                    type="checkbox"
                                    handleOnChange={handleChange}
                                    value={dados.ativo}
                                    checked={dados.ativo === true ? "checked" : ""}
                                />
                            </div>
                            <div>
                                <DataList
                                    text="Colaborador operador do caixa"
                                    name="Colaborador.descricao"
                                    placeholder={`Pesquise o colaborador operador do caixa`}
                                    inputWidth="350px"
                                    filteredOptions={OptionsColaborador}
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={Colaborador?.descricao || ""}
                                    required={true}
                                />
                                <Input
                                    text="Abertura"
                                    type="datetime-local"
                                    name="abertura"
                                    placeholder=""
                                    title="momento de abertura do caixa"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.abertura && retornaDataHora(dados.abertura)}
                                    inputWidth={"150px"}
                                    readonly={parseInt(dados.situacaoId) !== 1}
                                />
                                <InputNumber
                                    text="Saldo Abertura"
                                    type="text"
                                    name="saldoAbertura"
                                    placeholder="Saldo R$"
                                    inputWidth="150px"
                                    title="digite o saldo da conta na abertura do caixa em R$ (reais)"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.saldoAbertura ? formatarNumero(dados.saldoAbertura) : ""}
                                    readonly={parseInt(dados.situacaoId) !== 1}
                                />
                                <Input
                                    text="Fechamento"
                                    type="datetime-local"
                                    name="fechamento"
                                    placeholder=""
                                    title="momento de fechamento do caixa"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.fechamento && retornaDataHora(dados.fechamento)}
                                    inputWidth={"150px"}
                                    readonly={parseInt(dados.situacaoId) === 1}
                                />
                                <InputNumber
                                    text="Saldo Fechamento"
                                    type="text"
                                    name="saldoFechamento"
                                    placeholder="Saldo R$"
                                    inputWidth="150px"
                                    title="digite o saldo da conta no fechamento do caixa em R$ (reais)"
                                    handleOnChange={handleChange}
                                    handleOnFocus={handleOnFocus}
                                    handleOnBlur={handleOnBlur}
                                    value={dados.saldoFechamento ? formatarNumero(dados.saldoFechamento) : ""}
                                />
                            </div>
                        </>
                    )}

                    <h1>Lançamentos no Caixa</h1>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            //marginTop: "1rem",
                            flexWrap: "wrap", // Permite que os itens quebrem a linha quando necessário
                            // justifyContent: "space-between", // Distribui os itens igualmente
                        }}
                    >
                        <Select
                            name="novoLancamento.tipo"
                            title={`Escolha o tipo do lançamento`}
                            text="Tipo"
                            inputWidth="100px"
                            options={optionsTipo}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={novoLancamento.tipo}
                            isEnum={true}
                            notOptNull={true}
                        />
                        <DataList
                            text="Parceiro para o Lançamento"
                            name="Parceiro.descricao"
                            placeholder={`Pesquise o parceiro para o lançamento`}
                            inputWidth="350px"
                            filteredOptions={OptionsParceiro}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={Parceiro?.descricao || ""}
                        />
                        <Select
                            name="novoLancamento.formaId"
                            title="Selecione a forma do pagamento ou recebimento"
                            text="Forma"
                            inputWidth="180px"
                            options={optionsForma}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={novoLancamento.formaId}
                            isEnum={false}
                            notOptNull={false}
                        />
                        {/* <Select
                    name="novoLancamento.prazoId"
                    title="Selecione o prazo de pagamento ou recebimento"
                    text="Prazo"
                    inputWidth="180px"
                    options={optionsPrazo}
                    handleOnChange={handleChange}
                    handleOnFocus={handleOnFocus}
                    handleOnBlur={handleOnBlur}
                    value={novoLancamento.prazoId}
                    isEnum={false}
                    notOptNull={false}
                /> */}
                        <Select
                            name="novoLancamento.contaId"
                            title="Selecione a conta"
                            text="Conta"
                            inputWidth="180px"
                            options={optionsConta}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={novoLancamento.contaId}
                            isEnum={false}
                            notOptNull={false}
                        />
                        <DataList
                            text="Centro de Custo"
                            title="Escolha o centro de custo"
                            placeholder={"Digite para pesquisar"}
                            name="centroCusto.descricao"
                            inputWidth="150px"
                            filteredOptions={optionsCentroCusto}
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={centroCusto?.descricao || ""}
                        />
                    </div>
                    <div>
                        <TextArea
                            text="Histórico"
                            type="text"
                            name="novoLancamento.historico"
                            placeholder="Digite aqui o histórico do lançamento"
                            title="Digite o histórico do lançamento"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={novoLancamento.historico || ""}
                            textAlign="left"
                            linhas="4"
                            colunas="120"
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            //marginTop: "1rem",
                            flexWrap: "wrap", // Permite que os itens quebrem a linha quando necessário
                            // justifyContent: "space-between", // Distribui os itens igualmente
                        }}
                    >
                        <InputNumber
                            text="Valor"
                            type="text"
                            name="novoLancamento.valorOriginal"
                            placeholder="Valor R$"
                            inputWidth="100px"
                            title="digite o valor do lançamento em R$ (reais)"
                            handleOnChange={handleChange}
                            handleOnFocus={handleOnFocus}
                            handleOnBlur={handleOnBlur}
                            value={novoLancamento.valorOriginal ? formatarNumero(novoLancamento.valorOriginal) : ""}
                        />
                        <Button
                            variant="contained"
                            color={novoLancamento.funcao === "cancelar" ? "error" : "info"}
                            onClick={() => {
                                handleLancamentos(novoLancamento);
                            }}
                            disabled={!novoLancamento.formaId || !novoLancamento.contaId || !novoLancamento.valorOriginal}
                            style={{
                                marginLeft: "10px",
                                minWidth: "80px",
                                fontSize: "14px",
                                "@media (max-width: 600px)": {
                                    fontSize: "12px", // Ajusta o tamanho da fonte em telas menores
                                    padding: "8px 12px", // Ajusta o padding para manter o botão visível
                                },
                            }}
                        >
                            {novoLancamento.funcao}
                        </Button>
                    </div>
                    <div>
                        <ThemeProvider theme={theme}>
                            <MaterialReactTable
                                displayColumnDefOptions={{
                                    "mrt-row-actions": {
                                        muiTableHeadCellProps: {
                                            align: "center",
                                        },
                                        size: 100,
                                    },
                                    "mrt-row-numbers": {
                                        enableColumnOrdering: true, //turn on some features that are usually off
                                        enableResizing: true,
                                        muiTableHeadCellProps: {
                                            sx: {
                                                fontSize: "1.2rem",
                                            },
                                        },
                                    },
                                    "mrt-row-select": {
                                        enableColumnActions: true,
                                        enableHiding: true,
                                        size: 50,
                                    },
                                }}
                                enableColumnFilterModes
                                enableGlobalFilterModes
                                //enableColumnResizing
                                enableColumnOrdering
                                enableEditing
                                renderRowActions={({ row, table }) => (
                                    <Box sx={{ display: "flex", gap: "1rem" }}>
                                        <Tooltip arrow placement="top" title="Editar">
                                            <span>
                                                {" "}
                                                <IconButton
                                                    onClick={() => {
                                                        handleLancamento(row.original, row.index, "Editar");
                                                    }}
                                                    disabled={localStorage.getItem("editar") === "true" ? false : true}
                                                >
                                                    <Edit />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                        <Tooltip arrow placement="right" title="Deletar">
                                            <span>
                                                <IconButton
                                                    color="error"
                                                    //onClick={() => handleOpenDialog(row.id)}
                                                    onClick={() => handleLancamento(row.original, row.id, "Deletar")}
                                                    disabled={localStorage.getItem("cancelar") === "true" ? false : true}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    </Box>
                                )}
                                renderTopToolbarCustomActions={() => (
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "8px",
                                        }}
                                    >
                                        <Tooltip arrow placement="top-end" title={`Novo Registro`}>
                                            <div>
                                                {" "}
                                                <Button
                                                    color="secondary"
                                                    onClick={() => navigate("/TabelaPrecos/CreateOrEdit")}
                                                    variant="contained"
                                                    disabled={true}
                                                >
                                                    Novo
                                                </Button>
                                            </div>
                                        </Tooltip>
                                    </div>
                                )}
                                columns={columnsLancamento}
                                data={caixaLancamentos}
                                enableRowSelection
                                getRowId={(row) => row.uuid}
                                manualFiltering
                                manualPagination
                                manualSorting
                                muiToolbarAlertBannerProps={
                                    isError
                                        ? {
                                              color: "error",
                                              children: "Error loading data",
                                          }
                                        : undefined
                                }
                                onColumnFiltersChange={setColumnFilters}
                                onColumnFilterFnsChange={setColumnFiltersMode}
                                onGlobalFilterChange={setGlobalFilter}
                                onPaginationChange={setPagination}
                                onSortingChange={setSorting}
                                rowCount={rowCount}
                                initialState={{
                                    showColumnFilters: true,
                                    density: "compact",
                                    //columnPinning: { left: ["mrt-row-select"] },
                                }}
                                state={{
                                    columnFilterFns,
                                    columnFilters,
                                    globalFilter,
                                    isLoading,
                                    pagination,
                                    showAlertBanner: isError,
                                    showProgressBars: isRefetching,
                                    sorting,
                                }}
                                //positionToolbarDropZone={"top"}
                                positionExpandColumn={"first"}
                                positionActionsColumn={"first"}
                                positionToolbarAlertBanner="bottom"
                                localization={MRT_Localization_PT_BR}
                            />
                        </ThemeProvider>
                    </div>
                    <div>
                        <Stack direction="row" spacing={1}>
                            <Button variant="contained" color="secondary" onClick={() => navigate(caminhoVoltar, params)}>
                                Voltar
                            </Button>
                            <Button type="submit" variant="contained" color="success">
                                {btnText}
                            </Button>
                        </Stack>
                    </div>
                </div>
            </form>
        </div>
    );
}
export default Form;
