import api from ".";

export const operacao = async (uuid) => {
    try {
        const { data } = await api.get(`/operacao/editar/${uuid}`);
        return data;
    } catch (error) {
        return error;
    }
};

export const retornaOperacoes = async (entrada, saida, industria, balanco, manutencao, servico) => {
    try {
        const { data } = await api.get("/serviceOPERACAO", {
            params: { entrada, saida, industria, balanco, manutencao, servico },
        });
        return data;
    } catch (error) {
        return error;
    }
};

export const retornaOperacaoParametros = async (operacaoId, empresaId) => {
    try {
        const { data } = await api.get("/operacaoParametro/operacaoParametros", {
            params: { operacaoId, empresaId },
        });
        return data;
    } catch (error) {
        return error;
    }
};
