import { useContext } from "react";
import { Context } from "../../context/UserContext";
import styles from "./Input.module.css";

function Input({
    type,
    text,
    name,
    placeholder,
    handleOnChange,
    handleOnBlur,
    handleOnFocus,
    value,
    title,
    required,
    multiple,
    inputWidth,
    readonly,
    min,
    max,
    textAlign = "right",
    handleOnInput,
}) {
    //configura o tema
    const { parametro } = useContext(Context);

    const validateInput = (e) => {
        const value = e.target.value; // Obtém o valor atual do campo de entrada
        // Substitui todos os caracteres não permitidos (tudo exceto 0-9, ponto e hífen) por uma string vazia
        e.target.value = value.replace(/[^0-9.-]/g, "");
        if (handleOnInput) {
            handleOnInput(e); // Chama o manipulador de evento handleOnInput, se fornecido
        }
    };

    return (
        <div className={`${styles.form_control} ${styles[parametro.tema]}`}>
            {type !== "hidden" && <label htmlFor={name}>{text}:</label>}

            <input
                type="text"
                name={name}
                id={name}
                placeholder={placeholder}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onFocus={handleOnFocus}
                value={value}
                title={title}
                required={required}
                style={{
                    width: `var(--input-width, ${inputWidth})`,
                    textAlign: textAlign,
                }}
                {...(multiple ? { multiple } : "")}
                readOnly={readonly === true ? true : false}
                pattern="[0-9.,]*"
                min={min}
                max={max}
                onInput={validateInput} // Adiciona validação de entrada
            />
        </div>
    );
}

export default Input;
