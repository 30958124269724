import api from ".";
/**
 * Retorna os valores da tabela especifica
 * @param {*} empresaId
 * @param {*} tabela
 * @param {*} id
 * @param {*} descricao
 * @param {*} condicao
 * @param {*} filtro
 * @param {*} operacao
 * @param {*} funcionarioId
 * @returns
 */
export const dataList = async (empresaId, tabela, id, descricao, condicao, filtro, operacao, funcionarioId) => {
    try {
        const { data } = await api.get("/serviceDataList", {
            params: {
                empresaId,
                tabela,
                condicao,
                id,
                descricao,
                filtro,
                operacao,
                funcionarioId,
            },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};

/**
 * Retorna os colaboradores
 * @param {*} empresaId
 * @param {*} filtro
 * @param {*} ativo
 * @param {*} tipo compras,extrusor,etc
 * @returns
 */
export const dataListColaborador = async (empresaId, filtro, ativo, tipo) => {
    try {
        const { data } = await api.get("/serviceDataListColaborador", {
            params: {
                empresaId,
                filtro,
                ativo,
                tipo,
            },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};
/**
 * Retorna a lista de pessoas
 * @param {*} empresaId
 * @param {*} filtro comando digitado no datalist
 * @param {*} ativo true ou false
 * @param {*} tipo Ambos, Cliente ou Fornecedor
 * @param {*} funcionarioId pessoa vinculada do tipo funcionario
 * @returns
 */
export const dataListParceiro = async (empresaId, filtro, ativo, tipo, funcionarioId) => {
    try {
        const { data } = await api.get("/serviceDataListParceiro", {
            params: {
                empresaId,
                filtro,
                ativo,
                tipo,
                funcionarioId,
            },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};
/**
 * Retorna a lista de produtos
 * @param {*} empresaId
 * @param {*} filtro comando digitado no datalist
 * @param {*} ativo true ou false
 * @param {*} tipo Ambos, Cliente ou Fornecedor
 * @param {*} tabelaPrecoId tabela de preço vinculada
 * @returns
 */
export const dataListTabelaPrecoItemProduto = async (empresaId, tabelaPrecoId, filtro) => {
    try {
        const { data } = await api.get("/serviceDataListTabelaPrecoItemProduto", {
            params: {
                empresaId,
                tabelaPrecoId,
                filtro,
            },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};
/**
 * Retorna a lista de pessoa por vincula de locador, locatario ou fiador
 * @param {*} empresaId
 * @param {*} filtro comando digitado no datalist
 * @param {*} locador true ou false
 * @param {*} locatario true ou false
 * @param {*} fiador true ou false
 * @param {*} funcionarioId id do colaborador vinculado
 * @returns
 */
export const dataListParceiroImovel = async (empresaId, filtro, locador, locatario, fiador, funcionarioId, administrador) => {
    try {
        const { data } = await api.get("/serviceDataListParceiroImovel", {
            params: {
                empresaId,
                filtro,
                locador,
                locatario,
                fiador,
                funcionarioId,
                administrador,
            },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};
