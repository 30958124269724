import api from ".";

export const buscaBobinaById = async (bobinaId) => {
    try {
        const response = await api.get("/industria/buscaBobinaById", {
            params: { bobinaId },
        });

        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
};
export const buscaCilindroById = async (cilindroId) => {
    try {
        const response = await api.get("/industria/buscaCilindroById", {
            params: { cilindroId },
        });

        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
};
