import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useNavigateParams } from "../../../hooks/useNavigateParams";

import { Context } from "../../../context/UserContext";
import Form from "./Form";
import styles from "../../form/Form.module.css";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";

//utils
import * as TabelaAPI from "../../../api/tabela";
import * as VerificadoresAPI from "../../../api/verificadores";
import * as EmpresaAPI from "../../../api/empresa";

import { newBuildFormData } from "../../../utils/FormDataBuilder";

const onlyNumbers = (str) => str.replace(/[^0-9]/g, "");

const CreateOrEdit = () => {
    const [dados, setDados] = useState({});
    const [token] = useState(localStorage.getItem("token")) || "";
    const { parametro } = useContext(Context);

    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    const pathname = "/Empresa";
    const ul = urlParams.get("ul");
    const li = urlParams.get("li");
    const tipo = urlParams.get("tipo");
    const operacaoUUID = urlParams.get("operacao");
    const uuid = urlParams.get("uuid");
    const btnText = urlParams.get("btnText");

    const params = new URLSearchParams({
        ul: ul,
        li: li,
        tipo: tipo,
        operacao: operacaoUUID,
        uuid: uuid,
        btnText: btnText,
    });

    //enums
    const [EnumTipo, setEnumTipo] = useState([]);
    const [EnumRamoAtividade, setEnumRamoAtividade] = useState([]);
    const [ufs, setUfs] = useState([]);
    const [municipios, setMUNICIPIO] = useState([]);
    const [OptionsMatriz, setMatriz] = useState([]);

    //carregar API's Externas
    const consultaUF = async () => {
        let data = await VerificadoresAPI.uf("");
        setUfs(data);
    };
    const consultaMUNICIPIO = async () => {
        if (!dados.ufId) {
            dados.ufId = parseInt(localStorage.getItem("ufId"));
        }
        const data = await VerificadoresAPI.municipio(dados.ufId, dados.uf, dados.descricao);
        setMUNICIPIO(data);
    };
    const consultaEmpresa = async () => {
        let data = await EmpresaAPI.empresa(localStorage.getItem("empresaId"));
        setMatriz(data);
    };

    //carregar ENUMS
    const consultaEnum = async (table, field, filter) => {
        const data = await TabelaAPI.enums(table, field, filter);
        if (table === "Empresas") {
            field === "tipo" && setEnumTipo(data ? data : []);
            field === "ramoAtividade" && setEnumRamoAtividade(data ? data : []);
        }
    };

    useEffect(() => {
        if (!token) {
            navigate("/Auth", { replace: true });
        }
    }, [token, navigate]);

    useEffect(() => {
        //enums
        consultaEnum("Empresas", "tipo");
        consultaEnum("Empresas", "ramoAtividade");

        consultaUF();
        consultaMUNICIPIO();
        consultaEmpresa();

        if (uuid && uuid !== "null") {
            EmpresaAPI.consulta(uuid)
                .then((response) => {
                    setDados(response.data);
                    consultaMUNICIPIO();
                })
                .catch((error) => {
                    console.error(error);
                    setFlashMessage(error.response.data.message, error.response.data.type);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid]);

    async function managerEmpresa(obj) {
        let urlNavigate = pathname;

        if (obj.cnpj) {
            obj.cnpj = onlyNumbers(obj.cnpj);
        }
        if (obj.cep) {
            obj.cep = onlyNumbers(obj.cep);
        }

        const formData = new FormData();
        newBuildFormData(obj, formData);

        let response = {};

        try {
            if (uuid && uuid !== "null") {
                response = await EmpresaAPI.editar(formData);
            } else {
                response = await EmpresaAPI.cadastrar(formData);
            }
            if (response.status === 202) {
                setDados(response.data);
                response.data.message = "Registro em duplicidade!";
                response.data.type = "warning";
                urlNavigate = pathname + "/CreateOrEdit";
                params.set("uuid", response.data.uuid);
            }

            setFlashMessage(response.data.message, response.data.type);

            setTimeout(() => {
                navigate(urlNavigate, params);
            }, parametro.msgTempo * 1000);
        } catch (error) {
            console.error(error);
            setFlashMessage(error.response.data.message, error.response.data.type);
        }
    }

    return (
        <section className={`${styles.form_container} ${styles[parametro.tema]}`}>
            <Form
                token={token}
                formData={dados}
                handleSubmit={managerEmpresa}
                btnText={btnText}
                caminhoVoltar={pathname}
                EnumTipo={EnumTipo}
                EnumRamoAtividade={EnumRamoAtividade}
                ufs={ufs}
                municipios={municipios}
                OptionsMatriz={OptionsMatriz}
            />
        </section>
    );
};
export default CreateOrEdit;
