import api from ".";

export const integrantes = async (novoColaborador) => api.post(`plantao/integrantes`, novoColaborador);

export const retornaIntegrantes = async (plantaoId) => {
    try {
        const { data } = await api.get("plantao/retornaIntegrantes", {
            params: { plantaoId },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};
export const duplicidade = async (plantaoId, pessoaId) => {
    try {
        const { data } = await api.get("plantao/duplicidade", {
            params: { plantaoId, pessoaId },
        });
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};
export const imprimirPlantao = (url, dadosFiltrados) =>
    api.post(`/plantao/imprimirPlantao/?${url.toString()}`, dadosFiltrados, {
        responseType: "arraybuffer",
    });
