import React, { useEffect, useMemo, useState, useContext } from "react";
import { Context } from "../../../context/UserContext";

import { useNavigateParams } from "../../../hooks/useNavigateParams";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

import { ThemeProvider } from "@mui/material";
/* tema customizado*/
import tableThemeCustom from "../../form/tableThemeCustom";

/* hooks */
import useFlashMessage from "../../../hooks/useFlashMessage";

//Import Material React Table Translations
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";
import CustomDialog from "../../layout/CustomDialog";

import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { Delete, Edit, Print } from "@mui/icons-material";
import tratar from "../../../utils/retornaTratamentoURL";
import CrudsAPI from "../../../api/CRUDs";

/* util */
import { obterPermissoesUsuario } from "../../../utils/funcoes";
import * as TabelaAPI from "../../../api/tabela";

const Index = () => {
    const [dados, setDados] = useState([]);
    const [token] = useState(localStorage.getItem("token")) || "";
    const { setFlashMessage } = useFlashMessage();
    const navigate = useNavigateParams();
    const urlParams = new URLSearchParams(window.location.search);

    const pathname = "/Ajuda/CreateOrEdit";
    const ul = urlParams.get("ul") || 1;
    const li = urlParams.get("li") || 54;
    const tipo = urlParams.get("tipo");
    const operacaoUUID = urlParams.get("operacao");
    const uuid = urlParams.get("uuid");
    const btnText = urlParams.get("btnText") || "Cadastrar";

    const params = new URLSearchParams({
        ul: ul,
        li: li,
        tipo: tipo,
        operacao: operacaoUUID,
        uuid: uuid,
        btnText: btnText,
    });

    const { parametro } = useContext(Context);
    /* Obtem a paginação padrão */
    useEffect(() => {
        if (parametro && parametro.paginacao) {
            setPagination({
                pageIndex: pagination.pageIndex || 0,
                pageSize: parametro.paginacao,
            });
        }
    }, [parametro]);

    //Enums / options
    const [menus, setMenus] = useState([]);
    const [allMenus, setAllMenus] = useState([]);
    const [menuSubs, setMenuSub] = useState([]);
    const [enumRotina, setEnumRotina] = useState([]);

    const consultaMenu = async () => {
        const result = await TabelaAPI.menu("");

        const mappedResult = result.map((item) => ({
            text: item.descricao,
            value: item.descricao,
        }));

        setMenus(mappedResult);

        setAllMenus(result);
    };

    const consultaEnum = async (table, field, filter) => {
        const result = await TabelaAPI.enums(table, field, filter);
        if (table === "Ajuda") {
            field === "rotina" && setEnumRotina(result ? result : []);
        }
    };

    //dialog customizado
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});

    //data and fetching state
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    //tratar filtros se vier pela navbar
    if (localStorage.path.replace(/["]/g, "") !== window.location.pathname) {
        tratar.LimparFiltros();
        localStorage.setItem("path", JSON.stringify(window.location.pathname));
    }

    //table state
    const [columnFilterFns, setColumnFiltersMode] = useState(
        localStorage.columnFilterFns.length > 2 ? JSON.parse(localStorage.columnFilterFns) : []
    );

    const [columnFilters, setColumnFilters] = useState(
        localStorage.columnFilters.length > 2 ? JSON.parse(localStorage.columnFilters) : []
    );

    const [globalFilter, setGlobalFilter] = useState(
        localStorage.globalFilter.length > 2 ? JSON.parse(localStorage.globalFilter) : ""
    );

    const [sorting, setSorting] = useState(localStorage.sorting.length > 2 ? JSON.parse(localStorage.sorting) : []);

    const [pagination, setPagination] = useState({
        pageIndex: JSON.parse(localStorage.pagination).pageIndex || 0,
        pageSize: JSON.parse(localStorage.pagination).pageSize || 5,
    });

    const fetchData = async () => {
        if (!dados.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }

        try {
            const { data } = await CrudsAPI.ajuda.listar(
                pagination.pageIndex * pagination.pageSize,
                pagination.pageSize,
                JSON.stringify(columnFilters ?? []),
                JSON.stringify(columnFilterFns ?? []),
                globalFilter ?? "",
                JSON.stringify(sorting ?? [])
            );
            setDados(data.data);
            setRowCount(data.meta.totalRowCount);
        } catch (error) {
            setIsError(true);
            console.error(error);
            return;
        }
        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
    };

    //funções do dialog
    const handleOpenDialog = (row) => {
        setOpenDialog(true);
        setSelectedRow(row);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmDialog = async () => {
        // Verifique se há uma linha selecionada
        let msgType = "success";
        if (selectedRow) {
            // Lógica de confirmação
            try {
                const { data } = await CrudsAPI.ajuda.deletar(selectedRow);
                setFlashMessage(data.message, msgType);

                // Após a exclusão, chame a função fetchData() novamente para atualizar as unidades
                fetchData();
            } catch (error) {
                msgType = "error";
                console.error(error);
                setFlashMessage(error.response.data.message, msgType);
            }
        }
        setOpenDialog(false);
    };

    useEffect(() => {
        if (!token) {
            return navigate("/Auth", { replace: true });
        }

        //funcao para setar localStorage
        localStorage.setItem(
            "pagination",
            JSON.stringify({
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
            })
        );
        localStorage.setItem("columnFilterFns", JSON.stringify(columnFilterFns));
        localStorage.setItem("columnFilters", JSON.stringify(columnFilters));
        localStorage.setItem("globalFilter", JSON.stringify(globalFilter));
        localStorage.setItem("sorting", JSON.stringify(sorting));

        //permissões do usuario
        obterPermissoesUsuario(localStorage.getItem("pessoaId"), localStorage.getItem("empresaId"), ul, li);

        consultaMenu();
        consultaEnum("Ajuda", "rotina", null);

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, columnFilterFns, columnFilters, globalFilter, pagination.pageIndex, pagination.pageSize, sorting]);

    useEffect(() => {
        const consultaMenuSub = async (menuId, descricao) => {
            const result = await TabelaAPI.menuSub(menuId, descricao);
            const mappedResult = result.map((item) => ({
                text: item.descricao,
                value: item.descricao,
            }));
            setMenuSub(mappedResult);
        };
        const filtroMenu = columnFilters.find((filter) => filter.id === "Menu.descricao");
        if (filtroMenu && filtroMenu.value) {
            const valor = filtroMenu.value;
            // Encontrar o ID correspondente ao valor selecionado
            const menuEncontrado = allMenus.find((menu) => menu.descricao === valor);
            if (menuEncontrado) {
                consultaMenuSub(menuEncontrado.id, null);
            }
        }
    }, [columnFilters, allMenus]);

    const columns = useMemo(
        () => [
            {
                id: "Ajuda",
                header: "Ajudas nas rotinas do sistema",
                columns: [
                    {
                        accessorKey: "Menu.descricao",
                        header: "Menu",
                        columnFilterModeOptions: ["fuzzy", "equals", "notEquals"],
                        filterVariant: "select",
                        filterSelectOptions: menus,
                    },
                    {
                        accessorKey: "menuSub.descricao",
                        header: "Sub-Menu",
                        columnFilterModeOptions: ["fuzzy", "equals", "notEquals"],
                        filterVariant: "select",
                        filterSelectOptions: menuSubs,
                    },
                    {
                        accessorKey: "rotina",
                        header: "Rotina",
                        filterFn: "equals",
                        columnFilterModeOptions: ["fuzzy", "equals", "notEquals"],
                        filterVariant: "select",
                        filterSelectOptions: enumRotina,
                    },
                ],
            },

            //column definitions...
        ],
        [menus, menuSubs] //getCommonEditTextFieldProps
    );

    /* modo tema */
    const theme = tableThemeCustom();

    return (
        <div>
            <div>
                {openDialog && (
                    <CustomDialog
                        title="Delete AJUDA!"
                        content="Você tem certeza?"
                        onClose={handleCloseDialog}
                        onConfirm={handleConfirmDialog}
                    />
                )}
            </div>
            <ThemeProvider theme={theme}>
                <MaterialReactTable
                    displayColumnDefOptions={{
                        "mrt-row-actions": {
                            muiTableHeadCellProps: {
                                align: "center",
                            },
                            size: 100,
                        },
                        "mrt-row-numbers": {
                            enableColumnOrdering: true, //turn on some features that are usually off
                            enableResizing: true,
                            muiTableHeadCellProps: {
                                sx: {
                                    fontSize: "1.2rem",
                                },
                            },
                        },
                        "mrt-row-select": {
                            enableColumnActions: true,
                            enableHiding: true,
                            size: 50,
                        },
                    }}
                    enableColumnFilterModes
                    enableGlobalFilterModes
                    //enableColumnResizing
                    enableColumnOrdering
                    enableEditing
                    renderRowActions={({ row, table }) => {
                        const handleImprimir = async (obj) => {
                            const urlSearchParams = new URLSearchParams({
                                start: `${pagination.pageIndex * pagination.pageSize}`,
                                size: `${pagination.pageSize}`,
                                filters: JSON.stringify(columnFilters ?? []),
                                filtersMode: JSON.stringify(columnFilterFns ?? []),
                                globalFilter: globalFilter ?? "",
                                sorting: JSON.stringify(sorting ?? []),
                                tipo: urlParams.get("tipo") ?? "",
                                movimentoId: row.id,
                            });

                            const response = await CrudsAPI.ajuda.imprimir(urlSearchParams, obj);

                            // Cria um Blob a partir dos dados do arraybuffer
                            const pdfBlob = new Blob([response.data], {
                                type: "application/pdf",
                            });

                            // Cria um URL temporário para o Blob
                            const pdfUrl = URL.createObjectURL(pdfBlob);

                            // Use o URL do PDF para abrir em uma nova janela ou realizar outras ações
                            window.open(pdfUrl);
                        };
                        return (
                            <Box sx={{ display: "flex", gap: "1rem" }}>
                                <Tooltip arrow placement="top" title="Editar">
                                    <span>
                                        {" "}
                                        <IconButton
                                            onClick={() => {
                                                params.set("uuid", row.id);
                                                params.set("btnText", "Atualizar");
                                                navigate(pathname, params);
                                            }}
                                            disabled={JSON.parse(localStorage.getItem("editar")) === false}
                                        >
                                            <Edit />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Tooltip arrow placement="bottom" title="Deletar">
                                    <span>
                                        <IconButton
                                            color="error"
                                            onClick={() => handleOpenDialog(row)}
                                            disabled={JSON.parse(localStorage.getItem("cancelar")) === false}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Tooltip arrow placement="top" title="Imprimir">
                                    <span>
                                        {" "}
                                        <IconButton
                                            onClick={() => handleImprimir(row.original)}
                                            disabled={localStorage.getItem("imprimir") === "true" ? false : true}
                                        >
                                            <Print />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </Box>
                        );
                    }}
                    renderTopToolbarCustomActions={() => (
                        <div
                            style={{
                                display: "flex",
                                gap: "8px",
                            }}
                        >
                            <Tooltip arrow placement="top-end" title={`Novo Registro`}>
                                <div>
                                    {" "}
                                    <Button
                                        color="secondary"
                                        onClick={() => {
                                            params.delete("uuid");
                                            navigate(pathname, params);
                                        }}
                                        variant="contained"
                                        disabled={JSON.parse(localStorage.getItem("incluir")) === false}
                                    >
                                        Novo
                                    </Button>
                                </div>
                            </Tooltip>
                        </div>
                    )}
                    columns={columns}
                    data={dados}
                    enableRowSelection
                    getRowId={(row) => row.uuid}
                    manualFiltering
                    manualPagination
                    manualSorting
                    muiToolbarAlertBannerProps={
                        isError
                            ? {
                                  color: "error",
                                  children: "Error loading data",
                              }
                            : undefined
                    }
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setColumnFiltersMode}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    rowCount={rowCount}
                    initialState={{
                        showColumnFilters: true,
                        density: "compact",
                        //columnPinning: { left: ["mrt-row-select"] },
                    }}
                    state={{
                        columnFilterFns,
                        columnFilters,
                        globalFilter,
                        isLoading,
                        pagination,
                        showAlertBanner: isError,
                        showProgressBars: isRefetching,
                        sorting,
                    }}
                    //positionToolbarDropZone={"top"}
                    positionExpandColumn={"first"}
                    positionActionsColumn={"first"}
                    positionToolbarAlertBanner="bottom"
                    localization={MRT_Localization_PT_BR}
                />
            </ThemeProvider>
        </div>
    );
};
export default Index;
