import * as FinanceiroAPI from "../api/financeiro";

import { startOfDay, startOfMonth, endOfMonth, format } from "date-fns";

const moment = require("moment");
const dataAtual = moment().format("YYYY-MM-DD");

export const calculaPrazoMedio = (parcela, primeiraParcela, diasEntreParcelas) => {
    let prazoMedio = 0;
    if (parcela > 1) {
        let k = 0;
        while (k <= parcela - 1) {
            prazoMedio = prazoMedio + (primeiraParcela + diasEntreParcelas * k);
            k++;
        }
        prazoMedio = prazoMedio / parcela;
    }
    if (parcela === 1) {
        prazoMedio = primeiraParcela / parcela;
    }

    return prazoMedio;
};

export const calculaDataVencimento = async (token, dataLancamento = dataAtual, formaId = null, prazoId = null) => {
    const dataAtual = moment().startOf("day");
    const newData = moment(dataLancamento).startOf("day"); // Data de lançamento atual
    let novaData = moment(dataLancamento).startOf("day");
    let lancamentoData = moment(dataLancamento).startOf("day");

    let obj = {
        credito: false,
    };

    //obtem dados da forma
    if (formaId) {
        const result = await FinanceiroAPI.buscaFormaById(formaId);
        obj = result;
    }

    if (obj.credito === true) {
        const melhorDia = moment().date(obj.melhorDiaCompra).startOf("day");
        const melhorDiaAnterior = moment().date(obj.melhorDiaCompra).startOf("day").subtract(1, "months");

        const mesAtual = dataAtual.format("M");
        const mesLancamento = lancamentoData.format("M");

        //observar se a data de lançamento é no mesmo mês
        if (mesLancamento === mesAtual) {
            /* dia do vencimento é menor que o melhor dia de compra */
            if (obj.diaVencimento < obj.melhorDiaCompra) {
                /* vencimento no mesmo mês */

                /* vencimento acrescenta um mês */
                if (lancamentoData < melhorDia) {
                    novaData = newData.date(obj.diaVencimento).add(1, "months");
                }
                /* vencimento acrescenta dois mês */
                if (lancamentoData >= melhorDia) {
                    novaData = newData.date(obj.diaVencimento).add(2, "months");
                }
            } else {
                /* vencimento no mesmo mês */
                if (lancamentoData < melhorDia) {
                    novaData = newData.date(obj.diaVencimento);
                }
                /* vencimento acrescenta um mês */
                if (lancamentoData >= melhorDia) {
                    novaData = newData.date(obj.diaVencimento).add(1, "months");
                }
                /* vencimento acrescenta dois mês */
            }
        } else {
            /* dia do vencimento é menor que o melhor dia de compra */
            if (obj.diaVencimento < obj.melhorDiaCompra) {
                /* vencimento no mesmo mês */

                /* vencimento acrescenta um mês */
                if (lancamentoData < melhorDiaAnterior) {
                    novaData = newData.date(obj.diaVencimento).add(1, "months");
                }
                /* vencimento acrescenta dois mês */
                if (lancamentoData >= melhorDiaAnterior) {
                    novaData = newData.date(obj.diaVencimento).add(2, "months");
                }
            } else {
                /* vencimento no mesmo mês */
                if (lancamentoData < melhorDiaAnterior) {
                    novaData = newData.date(obj.diaVencimento); //no mes anterior
                }
                /* vencimento acrescenta um mês */
                if (lancamentoData >= melhorDiaAnterior) {
                    novaData = newData.date(obj.diaVencimento).add(1, "months");
                }
                /* vencimento acrescenta dois mês */
            }
        }

        obj["vencimento"] = novaData.format("YYYY-MM-DD");
    }

    if (prazoId) {
        //obtem dados do prazo
        const prazo = await FinanceiroAPI.buscaPrazoById(prazoId);

        novaData = newData.add(prazo.primeiraParcela, "day").date(dataLancamento);
        obj["parcela"] = prazo.parcela;
        if (obj.credito === false) {
            obj["vencimento"] = novaData.format("YYYY-MM-DD");
        }
    }

    return obj;
};

export const ultimoDiaDoMes = (mes) => {
    const hoje = new Date();
    const ultimoDia = new Date(hoje.getFullYear(), hoje.getMonth() + 1, 0);
    return ultimoDia.getDate();
};
/**
 *
 * @param {*} ano
 * @param {*} mes
 * @param {*} formato exemplo: "YYYY-MM-DD"
 * @returns
 */
export const obterPrimeiroEUltimoDiaDoMes = (ano, mes, formato) => {
    // Define o ano e o mês atuais se não forem passados como parâmetros
    const hoje = moment();

    if (!ano) {
        ano = hoje.year();
    }
    if (!mes) {
        mes = hoje.month() + 1; // 'month' retorna o mês em base zero, então adicionamos 1
    }

    // Obter o primeiro dia do mês
    let primeiroDia = moment(`${ano}-${mes}`, "YYYY-MM").startOf("month");

    // Obter o último dia do mês
    let ultimoDia = moment(`${ano}-${mes}`, "YYYY-MM").endOf("month");

    // Formatar se um formato foi passado
    if (formato) {
        primeiroDia = primeiroDia.format(formato);
        ultimoDia = ultimoDia.format(formato);
    } else {
        primeiroDia = primeiroDia.toISOString();
        ultimoDia = ultimoDia.toISOString();
    }

    return [primeiroDia, ultimoDia];
};

export const obterPrimeiroEUltimoDiaDoMesSemHoras = (ano, mes) => {
    const hoje = new Date();
    if (!ano) {
        ano = hoje.getFullYear();
    }
    if (!mes) {
        mes = hoje.getMonth() + 1;
    }

    const primeiroDia = new Date(Date.UTC(ano, mes - 1, 1, 0, 0, 0));
    const p = moment.utc(primeiroDia).format("YYYY-MM-DD");

    const ultimoDia = new Date(Date.UTC(ano, mes, 0, 23, 59, 59));
    const u = moment.utc(ultimoDia).format("YYYY-MM-DD");

    return [p, u];
};

export const recebeAnoMesRetornaPorExtenso = (data) => {
    const dataMoment = moment(data + "-01");
    const dataFormatada = dataMoment.format("MMMM [de] YYYY");

    // Ajuste para garantir que a primeira letra do mês seja maiúscula
    const dataFormatadaComMaiuscula = dataFormatada.replace(/^\w/, (letra) => letra.toUpperCase());

    return dataFormatadaComMaiuscula;
};

export const adicionaMesAdataAnoMes = (data, mesAdicionar = 1) => {
    const dataMoment = moment(data + "-01");
    // Adiciona um mês
    const novoMes = dataMoment.add(mesAdicionar, "months");
    // Formata o novo mês de volta para o formato desejado
    const novoMesFormatado = novoMes.format("YYYY-MM");

    return novoMesFormatado;
};
//date-fns vou fazer a conversão aos poucos
export const obterInicioEFimDoMes = (dataReferencia) => {
    // Converte para os extremos do mês
    const primeiroDia = startOfDay(startOfMonth(dataReferencia));
    const ultimoDia = startOfDay(endOfMonth(dataReferencia));

    // Retorna como Date, mas pode formatar se necessário
    return {
        primeiroDia,
        ultimoDia,
        primeiroDiaFormatado: format(primeiroDia, "yyyy-MM-dd"),
        ultimoDiaFormatado: format(ultimoDia, "yyyy-MM-dd"),
        primeiroDiaFormatadoPadrao: primeiroDia.toString(), // Formato padrão do Date
        ultimoDiaFormatadoPadrao: ultimoDia.toString(), // Formato padrão do Date
    };
};

const objetoDeFuncoes = {
    calculaPrazoMedio,
    calculaDataVencimento,
    ultimoDiaDoMes,
    obterPrimeiroEUltimoDiaDoMes,
    obterPrimeiroEUltimoDiaDoMesSemHoras,
    recebeAnoMesRetornaPorExtenso,
    adicionaMesAdataAnoMes,
    obterInicioEFimDoMes,
};

export default objetoDeFuncoes;
