import api from ".";

export const parametroByEmpresaId = async (empresaId) => {
    if (!empresaId) {
        return;
    }
    try {
        const { data } = await api.get("/parametro/parametroByEmpresaId", {
            params: { empresaId },
        });
        return data;
    } catch (error) {
        return error;
    }
};
