import api from ".";

export const operacao = async (uuid) => {
    try {
        const { data } = await api.get(`/operacao/editar/${uuid}`);
        return data;
    } catch (error) {
        return error;
    }
};
